import { useState } from "react"
import { KTCard } from "../../../../_metronic/helpers"
import { PageTitle } from "../../../../_metronic/layout/core"
import { DocumentsReportContext, detDocument } from "./DocumentsReportContext"
import { DocumentsReportTable } from "./main/DocumentsReportTable"
import { DocumentsReportTableHeader } from "./main/DocumentsReportTableHeader"
import { Doc } from "../../configuracion/enviodocumento/enviodocumentInterface"
import { ShowFileModal } from "./show-file-modal/ShowFileModal"

const documentsReportBreadcrumbs = [
    {
      title: 'Reportes',
      path: '/reportes/documentos',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

const InspectorDocumentsReportPage = () => {

    // const [empresaId, setEmpresaId] = useState('')
    const [dateRange, setDateRange] = useState<string[]>([])
    const [documentName,setDocument] = useState('')
    const [state, setState] = useState('')
    const [data, setData] = useState<any>([])
    const [empresaId, setEmpresaId] = useState<any[]>([])
    const [showFileModal,setShowFileModal] = useState(false)
    const [viewDocumento, setViewDocumento] = useState<Doc[]>([])
    const [detalleDocument, setDetalleDocument] = useState<detDocument[]>([])

    const [isLoading, setLoadingStatus] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [selectedDocuments, setSelectedDocuments] = useState<string[]>([])
    const [sortBy, setSortBy] = useState<{ columnName: string, sortDirection: string }>({ columnName: '', sortDirection: '' });

    const [indexes, setIndexes] = useState<string[]>([])

    const documentsReportValue = {
        empresaId,
        setEmpresaId,
        dateRange,
        setDateRange,
        documentName,
        setDocument,
        state,
        setState,
        data,
        setData,
        indexes,
        setIndexes,
        isLoading,
        setLoadingStatus,
        currentPage,
        setCurrentPage,
        totalPages,
        setTotalPages,
        selectedDocuments,
        setSelectedDocuments,
        showFileModal,
        setShowFileModal,
        viewDocumento, 
        setViewDocumento,
        detalleDocument,
        setDetalleDocument,
        sortBy,
        setSortBy
    }

    return (
    <DocumentsReportContext.Provider value={documentsReportValue}>
        {/* <PageTitle breadcrumbs={documentsReportBreadcrumbs}>Reporte de Documentos</PageTitle> */}
        <KTCard className="overflow-visible">
            <DocumentsReportTableHeader />
            <DocumentsReportTable />
        </KTCard>
        <ShowFileModal />
    </DocumentsReportContext.Provider>
    )
}

export { InspectorDocumentsReportPage }