import { createContext } from 'react';
import { Menu } from './components/MenuInterface';

interface PartnerContextProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  menuData: Menu;
  setMenuData: (value: Menu) => void;
  edit: boolean;
  setEdit: (value: boolean) => void;
  reloadTable: boolean;
  setReloadTable: (value: boolean) => void;
}
const defaultMenu: Menu = {
  _id: '',
  titulo: '',
  url: '',
  icono: '',
  tipo: '',
  order: 0,
  id_padre: '',
  id_hijo: '',
  hijos: []
};

const PartnerContext = createContext<PartnerContextProps>({
  searchTerm: '',
  setSearchTerm: () => {},
  menuData: defaultMenu,
  setMenuData: () => {},
  edit: false,
  setEdit: () => {},
  reloadTable: false,
  setReloadTable: () => {},
});

export { PartnerContext };

	