import {useContext, useEffect, useState} from 'react'
import {Row, Form, Button} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import FichaPlantillaGroup from './FichaPlantillaGroup'
import CheckboxTree from 'react-checkbox-tree'
import { v4 } from 'uuid'
import { PartnerContext } from '../PartnerContexts'
import { getCompleteSheet } from '../../../../services/fichas'
import { Field, Section, Sheet } from '../../fichas/Sheet'
import { Campos, Seccion } from '../../../documentacion/MiFicha/FIchaInterface'
import { FieldsExport, ListFieldsExport } from '../../documento/documentInterface'


const FichaPlantillaModalForm = () => {
  const {sheet_id,sheet,setSheet, setLoadingFichaContent} = useContext(PartnerContext)
  const [listFieldsExpor,setListFieldsExpor] = useState<ListFieldsExport[]>([])
  useEffect(()=> {
    console.log("sheet_id")
    console.log(sheet_id)
    if(sheet_id){
      getProcessSheet(sheet_id)
    }
  },[sheet_id])

  useEffect(()=>{
    showTask()
  },[sheet])

  const getProcessSheet = (id:string) =>{
    setLoadingFichaContent(true)
    getCompleteSheet(id)
    .then((response) => {
      console.log(response)
      setSheet(response.data)
    })
    .catch((error) => console.log(error))
    .finally(() => setLoadingFichaContent(false))
  }
  
  const showTask = () =>{
    const listFieldAux : ListFieldsExport[] = []    
    sheet.sections?.map((section:Section)=>{
      if(!section.dynamic){
        const fieldExport : FieldsExport[] = [] 
        section.fields?.map((field:Field)=>{
          fieldExport.push({identifier:field.id,check:field.checkExport?field.checkExport:false,label:field.name,type:"string",obligatorio:field.required})
        })
        listFieldAux.push({label:section.name,fields:fieldExport})
      }
    })
    setListFieldsExpor(listFieldAux)
  }

  return (
    <>
      <Form >
        <Row className='mb-3'>
          <FichaPlantillaGroup key={"idFichaPlantillaGroup1"} List={listFieldsExpor} />
        </Row>
      </Form>
    </>
  )
}

export {FichaPlantillaModalForm}
