import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Dropdown, Stack } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import { KTCardBody, KTIcon } from '../../../../../_metronic/helpers'
import GetToken from '../../../../config/ApiClassToken'
import { formatDateToDMY } from '../../../../helpers/dateFunctions'
import { deletePartner, getAllPartners, updatePartner } from '../../../../services/colaboradores'
import {
  busquedaficha,
  deleteCompleteSheet,
  ficha,
  generatePDF
} from '../../../../services/fichas'
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from '../../../../services/menu'
import { changeStatusFicha } from '../../../../services/registroFicha'
import {
  showCancellationMessage,
  showDeleteMessage,
  showMessage,
  showReactivationMessage
} from '../../../../shared/Alerts'
import Pagination from '../../../../shared/Pagination'
import SortableTableHeader from '../../../../shared/SortableTableHeader'
import { UsersListLoading } from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import { useQueryResponseData } from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import { usersColumns } from '../../../apps/user-management/users-list/table/columns/_columns'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import { ListaSecciones } from '../../usuario/pages/secciones/ListaSecciones'
import { PartnerContext } from '../PartnerContexts'

const PartnersTable = () => {
  const access_token = GetToken();
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  const [isLoading, setLoadingStatus] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [fetchStatus, setFechStatus] = useState('')
  const [fetchMessage, setFetchMessage] = useState('')

  const [lista, setLista] = useState([])
  const [selecto, setSelecto] = useState(0)
  const [show, setShow] = useState(false)
  const [meliminar, setMeliminar] = useState(false)
  const [eliminar, setEliminar] = useState('')
  const [mactualizar, setMactualizar] = useState(false)
  const [actualizar, setActualizar] = useState('')
  const [mnuevo, setMnuevo] = useState(false)
  const [nuevo, setNuevo] = useState('')
  const [sortBy, setSortBy] = useState({ columnName: '', sortDirection: '' });

  const { filtroData, filtrosKeys, query, activeOnly, state, searchTerm, entryDateFrom, entryDateTo, refresh, setRefresh, processId, unidadId } =
    useContext(PartnerContext)

  const navigate = useNavigate()

  const iconsData = [
    {
      iconName: 'time',
      title: 'Pendiente por Colaborador',
      stateName: 'pendientePorColaborador',
      color: '#3498db', // Blue
    },
    {
      iconName: 'check-square',
      title: 'Completado por Colaborador',
      stateName: 'completadoPorColaborador',
      color: '#9b59b6', // Purple
    },
    {
      iconName: 'verify',
      title: 'Aprobado con Pendiente',
      stateName: 'aprobadoConPendiente',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Aprobado por Administrador',
      stateName: 'aprobadoPorAdmin',
      color: '#2ecc71', // Green
    },
    {
      iconName: 'security-user',
      title: 'Historico',
      stateName: 'historico',
      color: '#2ecccc', // Green
    },
    {
      iconName: 'notepad-edit',
      title: 'Ficha Siendo Editada',
      stateName: 'fichaSiendoEditada',
      color: '#000000', // Purple
    },
    {
      iconName: 'exclamation-triangle', // Adjust the icon name
      title: 'Proceso Anulado',
      stateName: 'procesoAnulado',
      color: '#e74c3c', // Red
    },
    {
      iconName: 'eye',
      title: 'Ficha Observada',
      stateName: 'fichaObservada',
      color: '#d35400',
    },
  ]
  const location = useLocation()
  const { selectedRole } = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        console.log("currentpath"+ JSON.stringify(currentPath))
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          console.log("PERMISOS"+ JSON.stringify(subMenuResponse))
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu = response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  useEffect(() => {
    fetchData()
    console.log("unidadId")
    console.log(unidadId)
  }, [activeOnly, state, searchTerm, entryDateFrom, entryDateTo, currentPage, processId, unidadId, sortBy, query])

  const fetchData = useCallback(() => {
    if(processId && unidadId){
    setLoadingStatus(true)
    getAllPartners({
      activeOnly,
      sheetStates: state,
      searchQuery: searchTerm,
      entryDateFrom,
      entryDateTo,
      currentPage,
      limitAux: 10,
      processId,
      unidadId,
      sortBy: sortBy.columnName,
      sortOrder: sortBy.sortDirection,
      match: query?.match ?? '',
      campo: query?.campo ?? '',
      values: query?.values ?? ''
    },access_token)
      .then((response) => {
        if (response.success) {
          setLoadingStatus(false);
          console.log(response?.data)
          setLista(response?.data);
          setTotalPages(response?.totalPages);
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }, [activeOnly, state, searchTerm, entryDateFrom, entryDateTo, currentPage, processId, unidadId, sortBy, query])

  const handleObservar = (id: any, namecolaborador: any) => {
    navigate(`/seguimiento/colaborador/observacion/${id}/${namecolaborador}`)
  }

  const handleValidation = (id: any, namecolaborador: any) => {
    navigate(`/configuracion/validar/actions/${id}/${namecolaborador}`)
  }

  const changePartnerStatus = (id: string, activeState: boolean) => {
    console.log(id, '  ', state)
    if (activeState === false) {
      showDeleteMessage().then((result) => {
        if (result.isConfirmed) {
          deletePartner(id)
            .then((response) => {
              if (response.success) {
                showMessage('success', '¡Buen trabajo!', response.message)
              } else {
                showMessage('error', 'Hubo un error', response.error)
              }
              fetchData()
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    } else {
      updatePartner(id, { estado: true })
        .then((response) => {
          fetchData()
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  // Utility function to generate pagination links based on the current page and totalPages values
  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    // Generate "Previous" link if not on the first page
    if (currentPage > 1) {
      links.push({ page: currentPage - 1, label: 'Anterior' })
    }

    // Generate numbered pages
    for (let i = 1; i <= totalPages; i++) {
      links.push({ page: i, label: String(i) })
    }

    // Generate "Next" link if not on the last page
    if (currentPage < totalPages) {
      links.push({ page: currentPage + 1, label: 'Siguiente' })
    }

    return links
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    fetchData()
  }

  const handleSelecto = (opt: any) => {
    navigate(`/crafted/pages/configuracion/usuario/pages/fichas/plantilla/Ficha/:${opt}`)
  }

  const handleEliminar = (id: any) => {
    setMeliminar(!meliminar)
    setEliminar(id)
  }

  const handleDelete = (id: string) => {
    showDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        deleteCompleteSheet(id)
          .then((response) => {
            if (response.success) {
              console.log(response)
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              console.log(response)
              showMessage('error', 'Hubo un error', response.error)
            }
            fetchData()
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message))
      }
    })
  }

  const handleActualizar = (id: any) => {
    ficha(id)
      .then((response) => {
        if (response.success) {
          setActualizar(response?.data)
        } else {
          console.log(response.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
    setTimeout(() => {
      setMactualizar(!mactualizar)
    }, 1000)
  }

  const handleNuevaSeccion = (idnuevo: any) => {
    setMnuevo(!mnuevo)
    setNuevo(idnuevo)
  }

  const handleSearch = (e: any) => {
    e.preventDefault()
    if (e.key === 'Enter') {
      console.log('llego aqui mira')
      e.preventDefault()
      e.target.blur()
    }
    const valorbuscado = e.target.value
    if (valorbuscado?.length > 2) {
      if (valorbuscado) {
        busquedaficha(valorbuscado).then((response) => {
          if (response.success) {
            setLista(response?.data)
          } else {
            fetchData()
          }
        })
      } else {
        fetchData()
      }
    } else {
      fetchData()
    }
  }

  const handleCancellation = (idRegistroFicha: string, id: string) => {
    showCancellationMessage().then((result) => {
      if (result.isConfirmed) {

        deletePartner(id)
          .then((response) => {
            console.log(response)
          })
          .catch((error) => {
            console.log(error)
          })

        changeStatusRegistroFicha(idRegistroFicha, 'procesoAnulado')
      }
    })
  }

  const handleReactivation = (id: string) => {
    showReactivationMessage().then((result) => {
      if (result.isConfirmed) {
        changeStatusRegistroFicha(id, 'completadoPorColaborador')
      }
    })
  }

  const changeStatusRegistroFicha = (id: string, estado: string) => {
    changeStatusFicha(id, { estado })
      .then((response) => {
        if (response.success) {
          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          showMessage('error', 'Hubo un error', response.error)
        }
        fetchData()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleViewForm = (opt: any) => {
    navigate(`/crafted/pages/configuracion/usuario/pages/fichas/plantilla/Formulario/:${opt}`)
  }

  const handleUpdate = (id: any) => {
    navigate(`/seguimiento/colaboradores/edit/${id}`)
  }

  const handleView = (id: any) => {
    navigate(`/seguimiento/colaboradores/view/${id}`)
  }

  const handleExport = (id: any) => {
    generatePDF()
      .then((response) => console.log(response))
      .catch((error) => console.log(error))
  }

  const handleSwitchChange = (e: any) => {
    console.log(e)
    changePartnerStatus(e.target.id, e.target.checked)
  }

  // Custom Toggle Component
  const CustomToggle = React.forwardRef(({ onClick }: any, ref: any) => (
    <a
      ref={ref}
      href='#'
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
      title='Visualizar ficha'
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <KTIcon iconName='dots-square-vertical' className='fs-3' />
    </a>
  ))

  const handleSort = (columnName: string, sortDirection: string) => {
    console.log("Sorting column:", columnName, "Direction:", sortDirection);
    setSortBy({ columnName, sortDirection });
    console.log("SortBy state updated:", sortBy);
  };

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {
                  filtrosKeys.map((filtro) => (
                    <th key={filtro} className='text-start min-w-100px'>{filtro.toUpperCase()}</th>
                  ))
                }
                <th className=''>DNI</th>
                <SortableTableHeader label='Nombres' columnName='nombres' handleSort={handleSort} activeColumn={sortBy.columnName} />
                <SortableTableHeader label='Apellidos' columnName='apellidos' handleSort={handleSort} activeColumn={sortBy.columnName}/>
                <th className='text-start'>Correo</th>
                <SortableTableHeader label='Fec. Ingreso' columnName='fechaIngreso' handleSort={handleSort} activeColumn={sortBy.columnName}/>
                <th className='text-start'>Cargo</th>
                <th className='text-start'>Estado Proceso</th>
                <th className='text-end'>Opciones</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {lista.length > 0 ? (
                lista?.map((item: any, index: any) => (
                  <Fragment key={item._id}>
                    <tr>
                      {
                        filtrosKeys.length > 0 && filtrosKeys.map(filtro => {
                          const data = filtroData.find((f) => f.campo === filtro)
                          let value: any= {}
                          console.log(data)
                          if (data?.isUnidad === true) {
                            value.name = item?.unidad?.DesUnid || 'Vacio'
                          } else {
                            value = data && data.values && data.values.find(v => v.id === (item.filtro ? item.filtro[filtro] : ''))
                          }
                          
                          const text = value
                            ? value.name
                            : item.filtro
                              ? item.filtro[filtro] || 'Vacio'
                              : 'No aplica'

                          return (
                            <th key={filtro} className='text-start min-w-100px'>
                              {text}
                            </th>
                          )
                        })
                      }
                      <td>{item.dni}</td>
                      <td className='text-start min-w-100px'>{item.nombres?.toUpperCase()}</td>

                      <td className='text-start min-w-100px'>{item.apellidos?.toUpperCase()}</td>

                      <td className='text-start min-w-100px'>{item.correo?.toUpperCase()}</td>

                      <td className='text-start min-w-100px'>{formatDateToDMY(item.fechaIngreso)}</td>

                      <td className='text-start min-w-100px'>{item.cargo?.toUpperCase()}</td>

                      <td className='text-start min-w-100px'>
                        <span
                          style={{
                            backgroundColor: `${iconsData.find((e) => e.stateName === item.estadoFicha)?.color
                              }`,
                            color: 'white',
                            padding: '0.2em 0.5em',
                            borderRadius: '0.25rem',
                            marginRight: '0.5em',
                            fontSize: '0.8em',
                          }}
                        >
                          {iconsData.find((e) => e.stateName === item.estadoFicha)?.title?.toUpperCase()}
                        </span>
                      </td>

                      {/* <td className='text-end min-w-100px'>
                        <Form.Check
                          type='switch'
                          id={item._id}
                          defaultChecked={item.estado}
                          onChange={handleSwitchChange}
                          className='custom-secondary-switch'
                        />
                      </td> */}

<td className='text-end d-flex align-self-end justify-content-end'>
  <Stack direction='horizontal' gap={1}>
    {permissions.includes('Visualizar Ficha') && !item.estadoFicha.includes("null") && (
      <a
        href='#'
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        title='Visualizar ficha'
        onClick={() => handleView(item.idRegistroFicha)}
      >
        <KTIcon iconName='eye' className='fs-3' />
      </a>
    )}

    {permissions.includes('Editar Ficha') && !item.estadoFicha.includes("null") &&
    (item.estadoFicha === 'completadoPorColaborador' ||
      item.estadoFicha === 'aprobadoPorAdmin' ||
      item.estadoFicha === 'historico' ||
      item.estadoFicha === 'aprobadoConPendiente') && (
      <a
        href='#'
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        title='Editar ficha'
        onClick={() => handleUpdate(item.idRegistroFicha)}
      >
        <KTIcon iconName='pencil' className='fs-3' />
      </a>
    )}
      {permissions.includes('Opciones Ficha') && !item.estadoFicha.includes("null") &&  (

    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu className='fs-7'>
          <Dropdown.Item
            onClick={() => handleView(item.idRegistroFicha)}
          >
            Visualizar Ficha
          </Dropdown.Item>
              {item.estadoFicha !== 'pendientePorColaborador' &&
          item.estadoFicha !== 'fichaSiendoEditada' && (
          <Dropdown.Item
            onClick={() =>
              handleObservar(
                item.idRegistroFicha,
                `${item.apellidos} ${item.nombres}`
              )
            }
          >
            Observaciones
          </Dropdown.Item>
        )}

        {item.estadoFicha === 'aprobadoPorAdmin' ||
          item.estadoFicha === 'aprobadConPendiente' ? (
          <></>
        ) : (
          <></>
        )}

        {item.estadoFicha === 'completadoPorColaborador' && (
          <Dropdown.Item
            onClick={() =>
              handleValidation(
                item.idRegistroFicha,
                `${item.apellidos} ${item.nombres}`
              )
            }
          >
            Validar Enrolamiento
          </Dropdown.Item>
        )}

        {item.estadoFicha !== 'procesoAnulado' && (
          <Dropdown.Item
            onClick={() => handleCancellation(item.idRegistroFicha, item._id)}
          >
            Anular Proceso
          </Dropdown.Item>
        )}

        {item.estadoFicha === 'aprobadoPorAdmin' && (
          <Dropdown.Item
            onClick={() => handleReactivation(item.idRegistroFicha)}
          >
            Reactivar Proceso
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    
    </Dropdown>  )}
  </Stack>
</td>
                    </tr>
                    {item._id === selecto ? (
                      <ListaSecciones
                        id={selecto}
                        mnuevo={mnuevo}
                        setMnuevo={setMnuevo}
                        nuevo={nuevo}
                      />
                    ) : null}
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <UsersListPagination /> */}
        <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export { PartnersTable }

