import {registerLicense} from '@syncfusion/ej2-base'
import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {GlobalOverlayLoding} from './modules/generales_component/GlobalOverlayLoding'
import {GlobalOverlayProvider} from './modules/generales_component/GlobalOverlayProvider'
import 'react-datepicker/dist/react-datepicker.css'
import './global.css'
registerLicense(
  'Ngo9BigBOggjHTQxAR8/V1NGaF1cWGhAYVFxWmFZfV1gc19GZlZVR2Y/P1ZhSXxQdk1hUX5XcHJRQmFeV0Y='
)
// import {AuthInit} from './modules/auth'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <GlobalOverlayProvider>
          <LayoutProvider>
            {/* <AuthInit> */}
            <Outlet />
            <MasterInit />
            {/* </AuthInit> */}
          </LayoutProvider>
          <GlobalOverlayLoding />
        </GlobalOverlayProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
