import React, { useEffect, useState } from 'react'
import { cantidadCampos } from '../../../../../../services/campos'

export const CantidadCampos = ({idseccion}) => {

    const [cantidad, setCantidad] = useState(null)

    useEffect(() => {
        cantidadCampos(idseccion).then((response) => {
            if (response?.success) {
                setCantidad(response?.data)
            } else {
              console.log(response?.message)
            }
          })
          .catch((error) => {
            console.log(error)
          })
    }, [])
    
  return (
    <>
    {cantidad !== null && (
        <div className='symbol symbol-45px me-2 pr-2'>
        <span className='symbol-label'>
          <button type='button' className='btn btn-sm btn-success'>
            {cantidad}
          </button>
        </span>
      </div>)
    }
    </>
  )
}
