import { Dispatch, createContext } from "react";

interface DocumentsReportContextProps {
    processId: string,
    setProcessId: Dispatch<string>,
    dateRange: any[],
    setDateRange: Dispatch<any[]>,
    documentName: string,
    setDocument: Dispatch<string>,
    state: string,
    setState: Dispatch<string>,
    data: any,
    setData: Dispatch<any>,
    indexes: string[],
    setIndexes: Dispatch<string[]>
    isLoading: boolean,
    setLoadingStatus: Dispatch<boolean>,
    currentPage: number,
    setCurrentPage: Dispatch<number>,
    totalPages: number,
    setTotalPages: Dispatch<number>,
    selectedDocuments: string[],
    setSelectedDocuments: Dispatch<string[]>,
    sortBy: { columnName: string, sortDirection: string }, 
    setSortBy: Dispatch<{ columnName: string, sortDirection: string }> 
}

const DocumentsReportContext = createContext<DocumentsReportContextProps>({
    processId: '',
    setProcessId: () => { },
    dateRange: [],
    setDateRange: () => { },
    documentName: '',
    setDocument: () => { },
    state: '',
    setState: () => { },
    data: {},
    setData: () => { },
    indexes: [],
    setIndexes: () => { },
    isLoading: false,
    setLoadingStatus: () => { },
    currentPage: 1,
    setCurrentPage: () => { },
    totalPages: 0,
    setTotalPages: () => { },
    selectedDocuments: [],
    setSelectedDocuments: () => { },
    sortBy: { columnName: '', sortDirection: '' }, // Añadido
    setSortBy: () => { } // Añadido
})

export { DocumentsReportContext }