import { useMemo, useState, useContext, useEffect, Fragment } from 'react'
import { Badge, Button, Col, Container, Dropdown, Form, Row, Stack } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import { KTCardBody, KTIcon } from '../../../../../_metronic/helpers'
import { useQueryResponseData } from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import { usersColumns } from '../../../apps/user-management/users-list/table/columns/_columns'
import { showMessage } from '../../../../shared/Alerts'
import { PartnerContext } from '../PartnerContexts'
import { Perfil } from '../perfilInterface'
import { crearRolData, updateRolData } from '../../../../services/rol'

const DataPerfil:Perfil = {
  _id:'', 
  codigo_perfil: '',
  nombre_perfil: '',
  rol: '',
  estado: true
}

const PerfilFomulario = () => {
  const {perfilData,setPerfilData, edit,setEdit,setReloadTable} = useContext(PartnerContext)
  const [loading, setLoading] = useState(false)
  const [editButton, setEditButton] = useState(false); 
  const [perfil, setPerfil] = useState<Perfil>(perfilData)
  
  const location = useLocation()

  useEffect(() => {
    setPerfil(perfilData)
  }, [perfilData])

  
  useEffect(() => {
    setEditButton(edit)
  }, [edit])
  

  const handleChange = (e: any) => {
    if(editButton){
      if(e.target.name === "nombre_perfil") setPerfil({...perfil, [e.target.name]: e.target.value})
    }else {
      setPerfil({...perfil, [e.target.name]: e.target.value})
    }
  }

  const clear = ()=>{
    setEdit(false)
    setPerfilData(DataPerfil)
  }


  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (perfil._id === "") {
      setLoading(true)
      crearRolData(perfil)
      .then((response) => {
        if (response.success) {          
          setReloadTable(true)
          showMessage("success","Agregado",response.message)
          setPerfilData(DataPerfil)
          setLoading(false)
        } else {
          setLoading(false)
          console.log(response)
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
    }else{
      setLoading(true)
      updateRolData(perfil._id,perfil)
      .then((response) => {
        if (response.success) {
          setReloadTable(true)
          showMessage("success","Actualizado",response.message)
          setPerfilData(DataPerfil)
          setEdit(false)
          setLoading(false)
        } else {
          setLoading(false)
          console.log(response)
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
    }
  }

  return (
    <>
      <KTCardBody className='py-4'>
        <div className="card-header">
            <h3 className="card-title">Perfil</h3>
        </div>
        <Container>          
          <Row className='row mb-3 pt-3 px-3' >
            <Form onSubmit={handleSubmit}>
                <Col sm className='mb-2'>
                  <Form.Group controlId='formNombre'>
                    <Form.Label>Codigo</Form.Label>
                    <Form.Control
                      type='text'
                      disabled={editButton}
                      className='form-control-sm'                      
                      required
                      value={perfil.codigo_perfil}
                      placeholder='Ingrese un codigo'
                      onChange={handleChange}  
                      name='codigo_perfil'
                    />
                  </Form.Group>
                </Col>
                <Col sm className='mb-2'>
                  <Form.Group controlId='formNombre'>
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-sm'                      
                      required
                      value={perfil.nombre_perfil}
                      placeholder='Ingrese un nombre'
                      onChange={handleChange}  
                      name='nombre_perfil'
                    />
                  </Form.Group>
                </Col>
                <Col sm className='mb-2'>
                  <Form.Group controlId='formNombre'>
                    <Form.Label>Rol</Form.Label>
                    <Form.Control
                      type='text'
                      disabled={editButton}
                      className='form-control-sm'                      
                      required
                      value={perfil.rol}
                      placeholder='Ingrese un rol'
                      onChange={handleChange}  
                      name='rol'
                    />
                  </Form.Group>
                </Col>
              <Row>                
                <Col>
                  <div className='d-flex justify-content-end'>
                    {!loading && editButton &&                       
                      <Button className='mx-2' variant='light' 
                      type='reset' 
                      onClick={() => {
                        clear()
                      }}>
                        Cancelar
                      </Button>
                    }
                    <Button variant='success' type='submit'>
                      {!loading && !editButton && 'Registrar'}
                      {!loading && editButton && 'Actualizar'}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Subiendo...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </KTCardBody>
    </>
  )
}

export { PerfilFomulario }
