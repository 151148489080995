import {useContext} from 'react'
import {DocumentLoadDetailContext} from './DocumentLoadDetailContext'
import { DocumentDetailModalHeader } from './document-detail-modal/DocumentDetailModalHeader'
import { DocumentDetailModalFormWrapper } from './document-detail-modal/DocumentDetailModalFormWrapper'

const DocumentDetailModal = () => {
  const {isDocumentDetailModalOpen} = useContext(DocumentLoadDetailContext)
  return (
    <>
      {isDocumentDetailModalOpen && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              <div className='modal-dialog modal-xl modal-dialog-centered mw-800px'>
                <div className='modal-content'>
                  <DocumentDetailModalHeader />
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <DocumentDetailModalFormWrapper />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  )
}

export {DocumentDetailModal}
