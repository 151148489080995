import {useContext, useState} from 'react'
import {Droppable, Draggable} from 'react-beautiful-dnd'
import {useAccordionButton} from 'react-bootstrap/AccordionButton'
import {Card, Accordion, Badge} from 'react-bootstrap'
import {title} from 'process'
import Collapsible from 'react-collapsible'
import {KTIcon} from '../../../../../_metronic/helpers'
import {SheetEditContext} from '../SheetContexts'
import {DraggableSectionList} from './DraggableSectionList'
import {DraggableFieldList} from './DraggableFieldList'

const grid = 8

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // Some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // Change background color if dragging
  background: isDragging ? '#18c073' : 'white',
  borderRadius: 10,
  // Styles we need to apply on draggables
  ...draggableStyle,
})

const CollapsibleSectionItem = ({item, index}: any) => {
  const {openFieldModal, openSectionModal, sheetData, setData, setSectionData, setFieldData} =
    useContext(SheetEditContext)

  const deleteSection = () => {
    setData({...sheetData, sections: (sheetData.sections || []).filter((n) => n.id !== item.id)})
  }

  const editSection = () => {
    setSectionData(item)
    openSectionModal()
  }

  const addField = () => {
    setSectionData(item)
    openFieldModal()
  }

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <div style={{position: 'relative'}}>
            <Collapsible transitionTime={200} easing='ease' trigger={item.name} triggerStyle={{marginLeft: 30, fontWeight:'bolder'}}>
              {/* <div>Something</div>
              <div>Another</div>
              <div>Another</div>
              <div>Another</div>
              <div>Another</div> */}
              <div className='mt-6 mx-10'>
                <DraggableFieldList sectionItem={item} />
              </div>

              {/* {!isDraggable ? (
                <div>
                  <div>Something</div>
                  <div>Another</div>
                  <div>Another</div>
                  <div>Another</div>
                  <div>Another</div>
                </div>
              ) : (
                <DraggableSeccionList />
              )} */}
            </Collapsible>

            <div {...provided.dragHandleProps} style={{position: 'absolute', top: '0', left: '0'}}>
              <Badge
                bg='success-active'
                className='me-2'

                style={{transform: 'translateY(-2px)', color: 'white'}}
              >
                {index + 1}
              </Badge>
            </div>

            <div style={{position: 'absolute', top: '0', right: '0'}}>
              <Badge
                bg='primary-active'
                className='me-2'
                style={{transform: 'translateY(-8px)', color: 'white'}}
              >
                {item.fields?.length || 0}
              </Badge>
              <a
                href='#!'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                style={{transform: 'translateY(-8px)'}}
                title='Agregar Campo'
                onClick={() => addField()}
              >
                <KTIcon iconName='plus-circle' className='fs-3' />
              </a>
              <a
                href='#!'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                style={{transform: 'translateY(-8px)'}}
                title='Editar Sección'
                onClick={() => editSection()}
              >
                <KTIcon iconName='pencil' className='fs-3' />
              </a>
              <a
                href='#!'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                style={{transform: 'translateY(-8px)'}}
                title='Eliminar Sección'
                onClick={() => deleteSection()}
              >
                <KTIcon iconName='trash' className='fs-3' />
              </a>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export {CollapsibleSectionItem}
