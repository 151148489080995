import { Dispatch, createContext } from "react";
import { Doc } from "../../configuracion/enviodocumento/enviodocumentInterface"
export interface detDocument {
    createdAt: string;
    status: string;
    motivo: string;
    id_user_creador: string;
    name: string;
    email: string;
    token: string;
    ip: string;
    userAgent: string;
}

export const initialDetDocument = {
    createdAt: '',
    status: '',
    motivo: '',
    id_user_creador: '',
    name: '',
    email: '',
    token: '',
    ip: '',
    userAgent: ''
}

interface DocumentsReportContextProps {
    empresaId: any[],
    setEmpresaId: (value: any[]) => void,
    dateRange: any[],
    setDateRange: Dispatch<any[]>,
    documentName: string,
    setDocument: Dispatch<string>,
    state: string,
    setState: Dispatch<string>,
    data: any,
    setData: Dispatch<any>,
    indexes: string[],
    setIndexes: Dispatch<string[]>
    isLoading: boolean,
    setLoadingStatus: Dispatch<boolean>,
    currentPage: number,
    setCurrentPage: Dispatch<number>,
    totalPages: number,
    setTotalPages: Dispatch<number>,
    selectedDocuments: string[],
    setSelectedDocuments: Dispatch<string[]>,
    showFileModal: boolean,
    setShowFileModal: (value: boolean) => void,
    viewDocumento: Doc[],
    setViewDocumento: (value: Doc[]) => void,
    detalleDocument: detDocument[],
    setDetalleDocument: (value: detDocument[]) => void,
    sortBy: { columnName: string, sortDirection: string }, 
    setSortBy: Dispatch<{ columnName: string, sortDirection: string }> 
}

const DocumentsReportContext = createContext<DocumentsReportContextProps>({
    empresaId: [],
    setEmpresaId: (value) => {},
    dateRange: [],
    setDateRange: () => { },
    documentName: '',
    setDocument: () => { },
    state: '',
    setState: () => { },
    data: {},
    setData: () => { },
    indexes: [],
    setIndexes: () => { },
    isLoading: false,
    setLoadingStatus: () => { },
    currentPage: 1,
    setCurrentPage: () => { },
    totalPages: 0,
    setTotalPages: () => { },
    selectedDocuments: [],
    setSelectedDocuments: () => { },
    showFileModal: false,
    setShowFileModal: (value) => {},
    viewDocumento: [],
    setViewDocumento: (value) => {},
    detalleDocument: [],
    setDetalleDocument: (value) => {},
    sortBy: { columnName: '', sortDirection: '' }, // Añadido
    setSortBy: () => { } // Añadido
})

export { DocumentsReportContext }