import {useContext} from 'react'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {ColaboradorObservacionContext} from '../ColaboradorObservacionContexts'

const ShowFileModalHeader = () => {
  const {setShowFileModal} = useContext(ColaboradorObservacionContext)

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>Documento</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        onClick={() => setShowFileModal(false)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
    </div>
  )
}

export {ShowFileModalHeader}
