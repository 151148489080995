import React, {createContext, useCallback, useEffect, useState} from 'react'
import {getAllAprovedPartners} from '../../../services/colaboradores'
import {getProceso} from '../../../services/procesos'
import {useAuth2} from '../../authv2/Auth2Provider'
import {FieldsSelected, SheetFields, Status} from './types/GenTypes'

interface AltaContextType {
  fetchPartnersData: () => Promise<any>
  fichasColaboradores: any[]
  setFichasColaboradores: Function
  procesos: any[]
  setProcesos: Function
  processId: string
  setProcessId: Function
  unidadId: string
  setUnidadId: Function
  loading: boolean
  setLoading: Function
  usersSelected: Record<string, boolean>
  setUsersSelected: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  sheetFields: SheetFields[]
  setSheetFields: React.Dispatch<React.SetStateAction<SheetFields[]>>
  isOpenFieldModal: boolean
  onCloseFieldModal: () => void
  handleOpenFieldModal: () => void
  fieldSelected: FieldsSelected
  setFieldSelected: React.Dispatch<React.SetStateAction<FieldsSelected>>
  sheetId: string
  setSheetId: React.Dispatch<React.SetStateAction<string>>
  status: Status
  setStatus: React.Dispatch<React.SetStateAction<Status>>
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
}

export const AltaContext = createContext<AltaContextType>({
  fichasColaboradores: [],
  setFichasColaboradores: () => {},
  procesos: [],
  setProcesos: () => {},
  processId: '',
  setProcessId: () => {},
  unidadId: '',
  setUnidadId: () => {},
  loading: false,
  setLoading: () => {},
  usersSelected: {},
  setUsersSelected: () => {},
  sheetFields: [],
  setSheetFields: () => {},
  isOpenFieldModal: false,
  onCloseFieldModal: () => {},
  handleOpenFieldModal: () => {},
  fieldSelected: {},
  setFieldSelected: () => {},
  sheetId: '',
  setSheetId: () => {},
  status: 'pendiente',
  setStatus: () => {},
  fetchPartnersData: async () => {},
  searchValue: '',
  setSearchValue: () => {},
})

export const AltaContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [fichasColaboradores, setFichasColaboradores] = useState<any[]>([])
  const [procesos, setProcesos] = useState<any[]>([])
  const [processId, setProcessId] = useState<string>('')
  const [unidadId, setUnidadId] = useState<string>('')
  const {userData} = useAuth2()
  const [loading, setLoading] = useState(false)
  const [usersSelected, setUsersSelected] = useState<Record<string, boolean>>({})
  const [sheetFields, setSheetFields] = useState<SheetFields[]>([])
  const [isOpenFieldModal, setIsOpenFieldModal] = useState(false)
  const [fieldSelected, setFieldSelected] = useState<FieldsSelected>({})
  const [sheetId, setSheetId] = useState('')
  const [status, setStatus] = useState<Status>('pendiente')
  const [searchValue, setSearchValue] = useState('')

  const onCloseFieldModal = () => {
    setIsOpenFieldModal(false)
  }

  const handleOpenFieldModal = () => {
    setIsOpenFieldModal(true)
  }

  const fetchPartnersData = useCallback(async () => {
    return await getAllAprovedPartners({
      processId,
      unidadId,
      status,
      searchValue
    }).then((res: any) => {
      if (res.success) {
        setFichasColaboradores(res.data)

        const mapUsers = res.data.reduce((ac: any, ficha: any) => {
          ac[ficha.id_user_creador.dni] = false
          return ac
        }, {})

        setUsersSelected(mapUsers)
      }
    })
  }, [processId, unidadId, status, searchValue])

  useEffect(() => {
    getProceso(1, 100, {
      estado: 'A',
      responsables: userData?.userId,
      isOnboarding: true
    }).then((res) => {
      if (res.success) {
        const data = res.data
        setProcesos(data.map((x: any) => ({label: x.nombre, value: x._id})))
        setProcessId(data[0]._id)
      }
    })
  }, [])

  useEffect(() => {
    if (processId) {
      setLoading(true)
      fetchPartnersData().finally(() => {
        setLoading(false)
      })
    }
  }, [processId, unidadId, status, searchValue])

  return (
    <AltaContext.Provider
      value={{
        fichasColaboradores,
        setFichasColaboradores,
        procesos,
        setProcesos,
        processId,
        setProcessId,
        unidadId,
        setUnidadId,
        loading,
        setLoading,
        usersSelected,
        setUsersSelected,
        setSheetFields,
        sheetFields,
        isOpenFieldModal,
        onCloseFieldModal,
        handleOpenFieldModal,
        setFieldSelected,
        fieldSelected,
        sheetId,
        setSheetId,
        status,
        setStatus,
        fetchPartnersData,
        searchValue,
        setSearchValue,
      }}
    >
      {children}
    </AltaContext.Provider>
  )
}
