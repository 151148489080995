import {useContext, useEffect, useState} from 'react'
import {Row, Form, Button} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import TagsListGroup from './TagsListGroup'
import CheckboxTree from 'react-checkbox-tree'
import { v4 } from 'uuid'
import { PartnerEditContext } from '../PartnerEditContext'
import { getProcessSheetTags } from '../../../../../services/procesos'
import { Field, ListTags, ProcessSheet, Section, Sheet, Tag } from '../../documentInterface'


const TagsEditModalForm = () => {
  const {process_id,processSheetData,setProcessSheetData} = useContext(PartnerEditContext)
  const [listTags,setListTags] = useState<ListTags[]>([])
  useEffect(()=> {
    if(process_id){
      getProcessSheet(process_id)
    }
  },[])

  useEffect(()=>{
    showTask()
  },[processSheetData])

  const getProcessSheet = (id:string) =>{
    getProcessSheetTags(id)
    .then((response) => {
      setProcessSheetData(response.data)
    })
    .catch((error) => console.log(error))
  }
  
  const showTask = () =>{
    const listTagsAux : ListTags[] = [] 
    
    processSheetData.map((process_sheet:ProcessSheet) => {
      process_sheet.fichas.map((sheet:Sheet)=>{
        sheet.seccion.map((section:Section)=>{
          const tags : Tag[] = [] 
          section.campos.map((field:Field)=>{
            tags.push({identifier:field._id,tags:field.tag?field.tag:"",label:field.titulo,type:"string"})
          })
          listTagsAux.push({label:section.nombre,fields:tags})
        })
      })
    })
    setListTags(listTagsAux)
  }

  return (
    <>
      <Form >
        <Row className='mb-3'>
          <TagsListGroup List={listTags} />
        </Row>
      </Form>
    </>
  )
}

export {TagsEditModalForm}
