import {useContext, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {PartnerContext} from '../PartnerContexts'

const CargarDocumentModalHeader = () => {
  const {closeCargarDocumentModal, setIndividualData,individualData} = useContext(PartnerContext)
  const [origin, setOrigin] = useState('')

  const hanleChangeOrigin = (e: any) => {
    setOrigin(e.target.value)
    setIndividualData({...individualData, origin: e.target.value})
  }

  return (
    <>
      <div className='modal-header'>
        <h2 className='fw-bolder'>Cargar Nuevos Documentos</h2>
        <div
          className='btn btn-icon btn-sm btn-active-icon-primary'
          onClick={() => closeCargarDocumentModal()}
          style={{cursor: 'pointer'}}
        >
          <KTIcon iconName='cross' className='fs-1' />
        </div>
      </div>
      <div
        style={{
          paddingLeft: '1.75rem',
          textTransform: 'uppercase',
          paddingTop: '1rem',
          fontWeight: 'bolder',
        }}
      >
        {individualData.username}
      </div>
      <div
        style={{
          paddingLeft: '1.75rem',
          textTransform: 'uppercase',
          paddingTop: '1rem',
        }}
      >
        <input
          type='text'
          value={origin}
          onChange={hanleChangeOrigin}
          className='form-control form-control-sm'
          placeholder='Nombre de carga'
        />
      </div>
    </>
  )
}

export {CargarDocumentModalHeader}
