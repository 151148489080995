import {  fetchConToken } from "../helpers/fetch"

const getAllVistasPag = async (currentPage:any,numberData:any,options: any) => {
  const queryString = new URLSearchParams(options).toString();

  const resp = await fetchConToken(
    `vista/paginado/${currentPage}/${numberData}?${queryString}`,
    {},
    'GET'
  );

  const body = await resp.json();

  return body;
};

const crearVistaData = async (data: any) => {
  const resp = await fetchConToken(
      `vista/add`,
      data,
      'POST'
  );
  const body = await resp.json();

  return body;
}

const updateVistaData = async (id: string, updatedData: any) => {
  const resp = await fetchConToken(
      `vista/${id}`,
      updatedData,
      'PUT'
  );
  const body = await resp.json();

  return body;
}

const getVistaes = async () => {
  const resp = await fetchConToken(
      `vista/getvistaes/`,
      {},
      'GET'
  );
  const body = await resp.json();

  return body;
}

const getVistaById = async (url: string) => {

  const resp = await fetchConToken(
      `vista/getvistabyurl/${url}`,
      {},
      'GET'
  );

  const body = await resp.json();

  return body;
};

const deleteVista = async (id:string) => {
  const resp = await fetchConToken(`vista/${id}`, {}, 'DELETE')

  const body = await resp.json()
  return body
}

const activarVista = async (id:string) => {
  const resp = await fetchConToken(`vista/activar/${id}`, {}, 'PUT')

  const body = await resp.json()
  return body
}
  
export {
    getAllVistasPag,
    deleteVista,
    activarVista,
    crearVistaData,
    updateVistaData,
    getVistaes,
    getVistaById
};