import { useState } from "react"
import { KTCard } from "../../../../_metronic/helpers"
import { PageTitle } from "../../../../_metronic/layout/core"
import { DocumentsReportContext } from "./DocumentsReportContext"
import { DocumentsReportTable } from "./main/DocumentsReportTable"
import { DocumentsReportTableHeader } from "./main/DocumentsReportTableHeader"

const documentsReportBreadcrumbs = [
    {
      title: 'Reportes',
      path: '/reportes/documentos',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

const DocumentsReportPage = () => {

    const [processId, setProcessId] = useState('')
    const [dateRange, setDateRange] = useState<string[]>([])
    const [documentName,setDocument] = useState('')
    const [state, setState] = useState('')
    const [data, setData] = useState<any>([])

    const [isLoading, setLoadingStatus] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [selectedDocuments, setSelectedDocuments] = useState<string[]>([])
    const [sortBy, setSortBy] = useState<{ columnName: string, sortDirection: string }>({ columnName: '', sortDirection: '' });
    const [indexes, setIndexes] = useState<string[]>([])

    const documentsReportValue = {
        processId,
        setProcessId,
        dateRange,
        setDateRange,
        documentName,
        setDocument,
        state,
        setState,
        data,
        setData,
        indexes,
        setIndexes,
        isLoading,
        setLoadingStatus,
        currentPage,
        setCurrentPage,
        totalPages,
        setTotalPages,
        selectedDocuments,
        setSelectedDocuments,
        sortBy,
        setSortBy,
    }

    return (
    <DocumentsReportContext.Provider value={documentsReportValue}>
        <PageTitle breadcrumbs={documentsReportBreadcrumbs}>Reporte de Documentos</PageTitle>
        <KTCard className="overflow-visible">
            <DocumentsReportTableHeader />
            <DocumentsReportTable />
        </KTCard>
    </DocumentsReportContext.Provider>
    )
}

export { DocumentsReportPage }