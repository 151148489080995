import React from 'react'
import {FieldTitle} from './FieldTitle'
import {FieldValue} from './FieldValue'

interface ModernOptionFieldProps {
  title: string
  label: string
}

const ModernOptionField: React.FC<ModernOptionFieldProps> = ({title, label}) => {
  return (
    <div className='d-flex flex-column gap-3 p-5'>
      <FieldTitle>{title.toLocaleUpperCase()}</FieldTitle>
      <FieldValue>{label.toLocaleUpperCase() || 'Sin valor'}</FieldValue>
    </div>
  )
}

export {ModernOptionField}
