import {useContext, useEffect, useState} from 'react'
import {Col, Dropdown, Form, Row, Stack} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import Select from 'react-select'
import {KTIcon} from '../../../../../_metronic/helpers'
import {getAllPartnersPag} from '../../../../services/colaboradores'
import {
  downloadMultipleDocumentsByState,
  downloadMultipleDocumentsByUserSelected,
} from '../../../../services/documentSent'
import {fetchMenuActionsByRole, fetchSubMenuByUrl} from '../../../../services/menu'
import {getProcessesForDropdown} from '../../../../services/procesos'
import {showMessage} from '../../../../shared/Alerts'
import {useUnidades} from '../../../../shared/hooks/useUnidades'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {DatePickerBusiness} from '../../../generales_component/DatePickerBusiness'
import OverlayLoading from '../../../generales_component/OverlayLoading'
import {UsersReportContext} from '../UsersReportContext'

type ValuePiece = Date | null

type Value = [ValuePiece, ValuePiece]

const UsersReportTableHeader = () => {
  const [value, onChange] = useState<Value>([null, null])
  const [processList, setProcessList] = useState<any[]>([])
  const [isDownloading, setDownloadingStatus] = useState<boolean>(false)
  const [unidad, setUnidad] = useState<string>('')
  const {toOptions, misUnidades} = useUnidades()

  const {
    setProcessId,
    setDateRange,
    setUserName,
    setDocumentNumber,
    setData,
    processId,
    dateRange,
    userName,
    documentNumber,
    setLoadingStatus,
    setTotalPages,
    currentPage,
    selectedUsers,
    setSelectedUsers,
    sortBy,
  } = useContext(UsersReportContext)

  const location = useLocation()
  const {selectedRole} = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu =
            response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])
  useEffect(() => {
    handleSearch()
  }, [currentPage, sortBy])

  useEffect(() => {
    getProcesses()
  }, [])

  const getProcesses = () => {
    getProcessesForDropdown()
      .then((response) => {
        if (response.success) {
          setProcessList(response.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleSearch = () => {
    if (validateFilters()) {
      getReport({
        entryDateFrom: value?.toString().split(',')[0],
        entryDateTo: value?.toString().split(',')[1],
        searchQuery: documentNumber || userName,
        activeOnly: true,
        sheetStates: '',
        id_process: processId,
        sortBy: sortBy.columnName,
        sortOrder: sortBy.sortDirection,
        unidad: unidad,
      })
    }
  }

  const getReport = (params: any) => {
    getAllPartnersPag(currentPage, 10, params)
      .then((response) => {
        if (response.success) {
          setLoadingStatus(false)
          setData(response.data)
          setTotalPages(response.totalPages)
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const validateFilters = () => {
    /* if (value === undefined) {
            showMessage('info', 'Importante', 'Seleccione un rango de fechas')
            return false
        } */

    if (processId === '') {
      showMessage('info', 'Importante', 'Seleccione un proceso')
      return false
    }

    return true
  }

  const handleDocumentsDownloadByState = (state: string) => {
    if (validateFilters()) {
      downloadDocumentsByState(state)
    }
  }

  const handleDocumentsSelectDownloadByState = (state: string) => {
    downloadDocumentsUserSelected({
      state,
      processId,
      dateRange: value,
      documentNumber,
      userName,
      selectedUsers,
    })
  }

  const downloadDocumentsUserSelected = (params: any) => {
    setDownloadingStatus(true)
    console.log(params)
    downloadMultipleDocumentsByUserSelected(params)
      .then((response) => {
        if (response.success) {
          const link = document.createElement('a')
          link.href = response.data
          link.download = 'multiple_documents'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          setDownloadingStatus(false)
          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          console.log(response)
          showMessage('error', 'Hubo un error', response.error)
        }
      })
      .catch((error) => {
        console.log(error)
        setDownloadingStatus(false)
        showMessage('error', 'Hubo un error', error.message)
      })
      .finally(() => setDownloadingStatus(false))
  }

  const downloadDocumentsByState = (state: string) => {
    setDownloadingStatus(true)

    downloadMultipleDocumentsByState({
      state,
      processId,
      dateRange: value,
      documentNumber,
      userName,
    })
      .then((response) => {
        if (response.success) {
          const link = document.createElement('a')
          link.href = response.data
          link.download = 'multiple_documents'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          setDownloadingStatus(false)
          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          console.log(response)
          showMessage('error', 'Hubo un error', response.error)
        }
      })
      .catch((error) => {
        console.log(error)
        setDownloadingStatus(false)
        showMessage('error', 'Hubo un error', error.message)
      })
  }

  const handleChangeUnidad = (e: any) => {
    setUnidad(e.value)
  }

  return (
    <div className='mx-8 mt-8 mb-2'>
      <Row>
        <Col className='d-flex align-self-end justify-content-start'>
          <Stack direction='vertical' gap={1}>
            <span className='fw-bold'>Unidad:</span>
            <Select
              options={toOptions()}
              onChange={handleChangeUnidad}
              isDisabled={misUnidades.length === 0}
            />
          </Stack>
        </Col>
        <Col className='d-flex align-self-end justify-content-start'>
          <Stack direction='vertical' gap={1}>
            <span className='fw-bold'>Proceso:</span>
            <Form.Control as='select' onChange={(e) => setProcessId(e.target.value)}>
              <option value=''>Seleccione un proceso</option>
              {processList.map((process) => (
                <option value={process.id} key={process.id}>
                  {process.name}
                </option>
              ))}
            </Form.Control>
          </Stack>
        </Col>
        <Col className='d-flex align-self-end justify-content-start'>
          <Stack direction='vertical' gap={1}>
            <span className='fw-bold'>Rango de Fecha de ingreso</span>
            <DatePickerBusiness value={value} onChange={onChange} />
          </Stack>
        </Col>
        <Col className='d-flex align-self-end justify-content-start'>
          <Stack direction='vertical' gap={1}>
            <span className='fw-bold'>Nro Documento:</span>
            <Form.Control
              type='text'
              // value={description}
              required
              placeholder='Ingrese un número de documento'
              name='documentNumber'
              onChange={(e) => setDocumentNumber(e.target.value)}
            />
          </Stack>
        </Col>
        <Col className='d-flex align-self-end justify-content-start'>
          <Stack direction='vertical' gap={1}>
            <span className='fw-bold'>Nombres o Apellidos</span>
            <Form.Control
              type='text'
              // value={description}
              required
              placeholder='Ingrese nombres o apellidos'
              name='userName'
              onChange={(e) => setUserName(e.target.value)}
            />
          </Stack>
        </Col>

        {/*           <Col className='d-flex align-self-end justify-content-end'>
                <Stack direction='horizontal' gap={2}>
                    <Dropdown>
                        <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                            <KTIcon iconName='folder-down' className='fs-2' />
                            Descargar
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href='#' onClick={() => handleDocumentsDownloadByState('')}>Todos los documentos</Dropdown.Item>
                            <Dropdown.Item href='#' onClick={() => handleDocumentsDownloadByState('signed')}>Documentos firmados</Dropdown.Item>
                            <Dropdown.Item href='#' onClick={() => handleDocumentsDownloadByState('pending')}>Documentos pendientes</Dropdown.Item>
                            <Dropdown.Item href='#' onClick={() => handleDocumentsDownloadByState('rejected')}>Documentos rechazados</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Stack>
            </Col>
 */}
      </Row>
      <Row className='mt-4'>
        <Col className='d-flex align-self-end justify-content-start'>
          <button type='button' className='btn btn-primary' onClick={handleSearch}>
            <KTIcon iconName='magnifier' className='fs-2' />
            Buscar
          </button>
        </Col>
        <Col className='d-flex align-self-end justify-content-end'>
          <Stack direction='horizontal' gap={2}>
            {permissions.includes('Seleccionar documentos descarga') && (
              <Dropdown>
                <Dropdown.Toggle variant='success' id='dropdown-basic'>
                  <KTIcon iconName='folder-down' className='fs-2' />
                  Selecionados
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='#' onClick={() => handleDocumentsSelectDownloadByState('')}>
                    Todos los documentos
                  </Dropdown.Item>
                  <Dropdown.Item
                    href='#'
                    onClick={() => handleDocumentsSelectDownloadByState('signed')}
                  >
                    Documentos firmados
                  </Dropdown.Item>
                  <Dropdown.Item
                    href='#'
                    onClick={() => handleDocumentsSelectDownloadByState('pending')}
                  >
                    Documentos pendientes
                  </Dropdown.Item>
                  <Dropdown.Item
                    href='#'
                    onClick={() => handleDocumentsSelectDownloadByState('rejected')}
                  >
                    Documentos rechazados
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Stack>
        </Col>
      </Row>
      {isDownloading && <OverlayLoading />}
    </div>
  )
}

export {UsersReportTableHeader}
