import { useEffect, useState } from 'react'
import { KTCard, KTIcon } from '../../../../../_metronic/helpers'
import { PageTitle } from '../../../../../_metronic/layout/core'
import {
  Campos,
  DataTabla,
  Ficha,
  Historial,
  ProcesoFichas,
  Seccion,
  Tabla,
  tablaData,
} from '../../../documentacion/MiFicha/FIchaInterface'
import RegistroFichaPage from '../../../documentacion/MiFicha/FichaPage'
import { InfoRequerida } from '../../../documentacion/MiFicha/components/InfoRequerida'
import { getProcesoFichas } from '../../../../services/procesos'
import { PartnerSheet } from '../../../documentacion/MiFicha/components/PartnerSheet'
import { UsuarioHeader } from '../../../documentacion/MiFicha/FichaHeader'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getRegistroFichaOne } from '../../../../services/registroFicha'
import { HistorialMessageModel } from '../components/observacion/HistorialMessageModel'
import { PartnerViewer } from '../../../documentacion/MiFicha/components/PartnerViewer'
import { generatePDF } from '../../../../services/fichas'
import { Button } from 'react-bootstrap'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import { GoBackButton } from '../../../generales_component/GoBackButton'

const fichasBreadcrumbs = [
  {
    title: 'Configuración',
    path: '/seguimiento/colaboradores',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PartnerEdit = ({ editable }: any) => {
  const [isLoading, setLoadingStatus] = useState(false)
  const [procesos, setProcesos] = useState<ProcesoFichas[]>([])
  const [activeTab, setActiveTab] = useState(0)
  const [historialData, setHistorialData] = useState<Historial[]>([])
  const [historialMessage, setHistorialMessage] = useState<HistorialMessageModel[]>([])
  const [idUsuarioRegistro, setIdUsuarioRegistro] = useState<string>('')
  const [procesoFichaData, setProcesoFichaData] = useState<ProcesoFichas[]>([])
  const [estatusFicha, setEstatusFicha] = useState<string>('')
  const [filtro, setFiltro] = useState<Record<string, any> | null>({})
  const [unidad, setUnidad] = useState<Record<string, any> | null>(null)
  const navigate = useNavigate()
  const {selectedRole} = useAuth2()

  const { id } = useParams()

  const Ruta = [
    {
      path: 'inforequerida',
      link: '/documentos/ficha/inforequerida',
    },
    {
      path: 'documentacionrequerida',
      link: '/documentos/ficha/documentacionrequerida',
    },
  ]

  useEffect(() => {
    setLoadingStatus(true)
    if (id) {
      refetchData(id)
    }
  }, [])

  const refetchData = (idRegistroFicha: string) => {
    setLoadingStatus(true)
    getRegistroFichaOne(idRegistroFicha)
      .then((response) => {
        if (response.success) {
          setLoadingStatus(false)

          const dataWithValuesAndFiles: ProcesoFichas[] = response?.data[0]?.procesoficha.map((procesoFicha: ProcesoFichas, item: any) => ({
            ...procesoFicha,
            fichas: procesoFicha.fichas.map((ficha: Ficha) => ({
              ...ficha,
              seccion: ficha.seccion.map((seccion: Seccion) => ({
                ...seccion,
                campos: seccion.campos.map((campo: Campos) => ({
                  ...campo,
                  values: campo.values ? campo.values : '',
                  observacion: campo.observacion ? campo.observacion : {},
                  observado: campo.observado ? campo.observado : false,
                  valuesFiles: campo.valuesFiles ? campo.valuesFiles : [],
                  selectOpion: campo.selectOpion ? campo.selectOpion : null,
                  hasError: campo.hasError ? campo.hasError : false,
                  dependiente: campo.dependiente ? campo.dependiente : false,
                  campoDependiente: campo.campoDependiente ? campo.campoDependiente : '',
                  respuestaCampoDependiente: campo.respuestaCampoDependiente ? campo.respuestaCampoDependiente : '',
                  mostrar: campo.mostrar ? campo.mostrar : campo.dependiente ? false : true,
                  tablaData: campo.tablaData.map((tablaData: tablaData) => ({
                    ...tablaData,
                    option: tablaData.value.map((val: any) => ({
                      value: val.id ? val.id : '',
                      label: val.name ? val.name : ''
                    }))
                  }))
                })),

                secciones: seccion.secciones ? seccion.secciones.map((tabla: Tabla) => ({
                  ...tabla,
                  data: tabla.data.map((dattabla: DataTabla) => ({
                    ...dattabla,
                    values: dattabla.values ? dattabla.values : '',
                    valuesFiles: dattabla.valuesFiles ? dattabla.valuesFiles : [],
                    selectOpion: dattabla.selectOpion ? dattabla.selectOpion : null,
                    hasError: dattabla.hasError ? dattabla.hasError : false,
                    tablaData: dattabla.tablaData ? dattabla.tablaData.map((dat: tablaData) => ({
                      ...dat,
                      option: dat.value.map((val: any) => ({
                        value: val.id ? val.id : '',
                        label: val.name ? val.name : ''
                      }))
                    })) : []
                  }))
                })) : [],


              })),
            })),
            proceso: response?.data[0]?.proceso

          }))

          setIdUsuarioRegistro(response?.data[0]?.id_user_creador)

          const historial: Historial[] = response?.data[0]?.historial
          setFiltro(response?.data[0]?.filtro ?? null)
          setUnidad(response?.data[0]?.unidad ?? null)
          setHistorialData(historial)
          setProcesoFichaData(dataWithValuesAndFiles)
          setEstatusFicha(response?.data[0]?.estado)

          const historialMensaje: HistorialMessageModel[] = []
          response?.data[0]?.historial?.map((dat: Historial) => {
            if (dat.motivo === 'OBSERVADO' && dat.data) {
              historialMensaje.push(dat.data)
            }
          })
          setHistorialMessage(historialMensaje)

        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleExport = (id: any) => {
    generatePDF(id)
      .then((response) => console.log(response))
      .catch((error) => console.log(error))
  }

  const goBack = () => {
    const link = selectedRole === 'POST' ? '/documentos/proceso' : '/seguimiento/colaboradores'
    navigate(link)
  }

  return (
    <>
      <PageTitle breadcrumbs={fichasBreadcrumbs}>
        {editable ? 'Editar Ficha' : 'Visualizar Ficha'}
      </PageTitle>
      <GoBackButton goBack={goBack} />

      <KTCard>
        {/* <RegistroFichaPage /> */}

        <div className='card mb-5 '>
          <div className='card-body pt-5 pb-0'>
            <div className='d-flex mb-2 justify-content-between'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                {procesoFichaData.map((procesoficha, i) => (
                  <li className='nav-item' key={i}>
                    <Link
                      className={
                        'nav-link text-active-primary me-6 ' + (i === activeTab && 'active')
                      }
                      to={''}
                      onClick={() => setActiveTab(i)}
                    >
                      {procesoficha.fichas[0].nombre}
                    </Link>
                  </li>
                ))}
              </ul>
              <button type='button' className='btn btn-success' onClick={() => handleExport(id)}>
                <KTIcon iconName='document' className='fs-2' />
                Exportar
              </button>
            </div>
          </div>
        </div>

        {editable ? (
          <InfoRequerida
            num={activeTab}
            idRegistroFicha={String(id)}
            urlRef={'/seguimiento/colaboradores'}
            procesoFicha={procesoFichaData}
            estatusFicha={estatusFicha}
            historial={historialData}
            onUpdate={function (updatedData: any): void {
              setActiveTab(updatedData)
            }}
            filtro={filtro}
            unidad={unidad}
            setUnidad={setUnidad}
          />
        ) : (
          // <PartnerSheet
          //   num={activeTab}
          //   procesoFicha={procesoFichaData}
          //   estatusFicha={''}
          //   historial={[]}
          //   onUpdate={function (updatedData: any): void {
          //     setActiveTab(updatedData)
          //   }}
          // />
          <PartnerViewer data={procesoFichaData} activeTab={activeTab} />
        )}
      </KTCard>
    </>
  )
}

export { PartnerEdit }
