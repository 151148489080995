import React from 'react'

interface FieldTitleProps {
  children: React.ReactNode
}

const FieldTitle: React.FC<FieldTitleProps> = ({children}) => {
  return <p className='mb-0 fs-6 fw-bold'>{children}</p>
}

export {FieldTitle}
