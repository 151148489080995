import { Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import styled from "styled-components";
import { nuevaFicha } from '../../../../../../services/fichas';
import { nuevaSeccion } from '../../../../../../services/secciones';

export const ValidatioError =  styled.p`
  color: red;
  margin-top: 5px;
`;

export const ModalNuevo = ({show, setShow, setUpdated, updated, datanuevo }) => {
    const {register, setValue, handleSubmit, formState, clearErrors} = useForm();

    const {errors} = formState;
    
    const onSubmit = (datasend) => {            
        const {nombre, descripcion} = datasend;
        const dataO = {
          idficha: datanuevo,
          nombre: nombre,
          descripcion: descripcion,
          orden: 0,
          estado: "A"
        }
                     
        nuevaSeccion(dataO).then((response) => {
            if(response?.success){
                console.log("Seccion Actualizada")
                setUpdated(!updated)
            } else {
                console.log(response?.message)
            }
        }).catch(error => {
            console.log(error)
        })
        setShow(false)
        setValue("nombre", '');
        setValue("descripcion", '');
    }

    const handleCerrar = () => {
        setShow(false)
        setValue("nombre", '');
        setValue("descripcion", '');
    }

  return (
    <Modal
          id='kt_modal_create_app'
          tabIndex={-2}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-900px'
          show={show}
          onHide={() => handleCerrar()}
        >
          <div className='modal-header'>
            <h2>Nueva Seccion</h2>
            {/* begin::Close */}
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => handleCerrar()}
            >
              X
            </div>
            {/* end::Close */}
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
          <div className='modal-body py-lg-10 px-lg-10'>
          <div className='current' data-kt-stepper-element='content'>
              <div className='w-100'>
                {/*begin::Form Group */}
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Nombre de Seccion</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Titulo del campo a llenar'
                    ></i>
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='titulo'
                    placeholder=''
                    {...register("nombre", {required: true})}
                    autoComplete='off'
                  />
                  {errors?.nombre && (
                    <ValidatioError>Nombre requerido</ValidatioError>
                  )}
                </div>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Descripción</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Tipo de campo'
                    ></i>
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='titulo'
                    placeholder=''
                    {...register("descripcion", {required: true})}
                    autoComplete='off'
                  />
                  {errors?.descripcion && (
                    <ValidatioError>Descripcion requerida</ValidatioError>
                  )}
                </div>          
              </div>
            </div>
            <button
              type='button'
              className='btn btn-lg btn-danger'
              data-kt-stepper-action='next'
              onClick={() => handleCerrar()}
              style={{marginRight: 20}}
            >
              Cancelar              
            </button>
            <button
              type='submit'
              className='btn btn-lg btn-primary'
              data-kt-stepper-action='next'
            >
              Crear              
            </button>
          </div>
          </Form>
        </Modal>
  )
}
