import {useState} from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {UsersReportContext} from './UsersReportContext'
import {UsersReportTable} from './main/UsersReportTable'
import {UsersReportTableHeader} from './main/UsersReportTableHeader'

const usersReportBreadcrumbs = [
  {
    title: 'Reportes',
    path: '/reportes/usuarios',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UsersReportPage = () => {
  const [processId, setProcessId] = useState('')
  const [dateRange, setDateRange] = useState<string[]>([])
  const [documentNumber, setDocumentNumber] = useState('')
  const [userName, setUserName] = useState('')
  const [data, setData] = useState<any>([])
  const [isLoading, setLoadingStatus] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [sortBy, setSortBy] = useState<{columnName: string; sortDirection: string}>({
    columnName: '',
    sortDirection: '',
  })

  const usersReportValue = {
    processId,
    setProcessId,
    dateRange,
    setDateRange,
    documentNumber,
    setDocumentNumber,
    userName,
    setUserName,
    data,
    setData,
    isLoading,
    setLoadingStatus,
    currentPage,
    setCurrentPage,
    totalPages,
    setTotalPages,
    selectedUsers,
    setSelectedUsers,
    sortBy,
    setSortBy, // Añadido
  }

  return (
    <UsersReportContext.Provider value={usersReportValue}>
      <PageTitle breadcrumbs={usersReportBreadcrumbs}>Reporte de Usuarios</PageTitle>
      <KTCard className='overflow-visible'>
        <UsersReportTableHeader />
        <UsersReportTable />
      </KTCard>
    </UsersReportContext.Provider>
  )
}

export {UsersReportPage}
