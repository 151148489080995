import React from 'react';

function OverlayLoading() {
    return (
        <div className="overlay">
        <   div className="loader"></div>
        </div>
    );
}

export default OverlayLoading;