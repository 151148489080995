import moment from 'moment'
import {Fragment, useEffect, useState} from 'react'
import {Accordion, Badge, Button, Col, Container, Row, Table} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import {KTCard, KTIcon} from '../../../../../_metronic/helpers'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  changeLevelStatus,
  getDocumentById,
  sendDocumentsWhenApprove,
  showFilePlantillaMatchS3,
} from '../../../../services/documentos'
import {downloadFileS3, getFileId, getFileInPDF} from '../../../../services/files'
import {showMessage, showRejectionMessage} from '../../../../shared/Alerts'
import {useGlobalOverlayLoading} from '../../../../shared/hooks/useGlobalOverlayLoading'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {CustomTooltip} from '../../../generales_component/CustomTooltip'
import OverlayLoading from '../../../generales_component/OverlayLoading'
import {PdfLoadingSpinner} from '../../../generales_component/PdfLoadingSpinner'
import {Doc} from '../../enviodocumento/enviodocumentInterface'
import {ShowFileModal} from '../show-file-modal/ShowFileModal'
import {DetalleNivelModal} from './detalle-nivel-modal/DetalleNivelModal'
import {DocumentDetailModal} from './DocumentDetailModal'
import {DocumentLoadDetailContext} from './DocumentLoadDetailContext'
import {DocumentQuickViewModal} from './DocumentQuickViewModal'
import {ShowFile2Modal} from './show-file2-modal/ShowFile2Modal'
import { GoBackButton } from '../../../generales_component/GoBackButton'

const documentosBreadcrumbs = [
  {
    title: 'Documentos',
    path: '/seguimiento/documentos',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DocumentDetail = () => {
  const [isDocumentDetailModalOpen, setDocumentDetailModalStatus] = useState(false)
  const [isDocumentQuickViewModalOpen, setDocumentQuickViewModalStatus] = useState(false)
  const [quickViewModalData, setQuickViewModalData] = useState<any>()
  const [selectedPlantillaId, setSelectedPlantillaId] = useState('')
  const [viewDocumentoArray, setViewDocumentoArray] = useState<Doc[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<any>()
  const [showFileModal, setShowFileModal] = useState(false)
  const [showFile2Modal, setShowFile2Modal] = useState(false)
  const [loadingNewPdf, setLoadingNewPdf] = useState(false)
  const [viewDocumento, setViewDocumento] = useState<Doc>({
    uri: '',
    fileType: '',
    fileName: '',
    keyName: '',
  })
  const [pdfData, setPdfData] = useState<Doc[]>([])
  const {userData} = useAuth2()
  const [fileStatus, setFileStatus] = useState<any>(null)
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()
  const [levelModalStatus, setLevelModalStatus] = useState(false)
  const [levelModalData, setLevelModalData] = useState<any>(null)

  const openDocumentDetailModal = () => {
    setDocumentDetailModalStatus(true)
  }

  const closeDocumentDetailModal = () => {
    setDocumentDetailModalStatus(false)
  }

  const openDocumentQuickViewModal = () => {
    setDocumentQuickViewModalStatus(true)
  }

  const closeDocumentQuickViewModal = () => {
    setDocumentQuickViewModalStatus(false)
  }

  const navigate = useNavigate()

  const {id} = useParams()

  const goBack = () => {
    navigate('/seguimiento/documentos')
  }

  useEffect(() => {
    if (id) {
      getDocument(id)
    }
  }, [])

  const getDocument = (id: string) => {
    getDocumentById(id)
      .then((response) => {
        if (response.success) {
          console.log('Datos obtenidos:', response.data)
          setData(response.data)
        } else {
          console.error(response.message)
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleDocumentQuickViewModal = async (file: any, estatuSend: boolean) => {
    setLoadingNewPdf(true)
    if (estatuSend) {
      setQuickViewModalData(file)
      openDocumentQuickViewModal()
      setLoadingNewPdf(false)
    } else {
      if (file.type === 'PLANTILLA') {
        const idUser = data.idUsers[0]._id
        setSelectedPlantillaId(file._id)
        const response = await showFilePlantillaMatchS3({
          idUser,
          idFile: file._id,
          tagsRelated:
            file.tags?.find((tag: any) => tag.user === data.idUsers[0].dni)?.tags ?? null,
        })

        setViewDocumentoArray([
          {
            uri: response.data.data,
            fileType: 'pdf',
            fileName: `${data.idUsers[0].nombre} - ${response.data.filename}`,
            keyName: response.data.keyname,
          },
        ])
        setShowFile2Modal(true)
        setLoadingNewPdf(false)
      } else {
        const filedat = await getFileId(file._id)
        const fileInPdf = await getFileInPDF(filedat.data.keyname)
        console.log({fileInPdf, file})
        setViewDocumentoArray([
          {
            uri: fileInPdf.data,
            fileType: 'pdf',
            fileName: filedat.data.filename,
            keyName: filedat.data.keyname,
          },
        ])
        setShowFile2Modal(true)
        setLoadingNewPdf(false)
      }
    }
  }

  const handleDocumentDetailModal = (file: any) => {
    setQuickViewModalData(file)
    openDocumentDetailModal()
  }

  const handleDownload = async (keyname: string, filename: string) => {
    console.log(keyname)
    console.log(filename)
    const partes = filename.split('.')
    const extension = partes[partes.length - 1]

    downloadFileS3(keyname, extension)
      .then((response) => {
        if (response.success) {
          const link = document.createElement('a')
          link.href = response.data
          link.download = filename
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          console.log(response)
          showMessage('error', 'Hubo un error', response.error)
        }
      })
      .catch((error) => {
        console.log(error)
        showMessage('error', 'Hubo un error', error.message)
      })
  }

  const handleCustomizedViewModal = (item: any, id_proceso: string) => {
    console.log(item)
    const filePer = {
      type: 'PDF',
      idproceso: id_proceso,
      _id: item._id,
      name: item.name,
      keyname: item.keyname,
    }
    setQuickViewModalData(filePer)
    openDocumentQuickViewModal()
  }

  const handleLevelAction = (action: 'approve' | 'deny', idFile: string, idLevel: string) => {
    const actions = {
      deny: 'rechazar',
      approve: 'aprobar',
    }

    if (action === 'deny') {
      showRejectionMessage().then(async (result) => {
        if (result.isConfirmed) {
          setGlobalOverlayLoading(true)
          const resp = await changeLevelStatus({
            idFile,
            idLevel,
            status: action,
            idDoc: id,
            motivo: result.value,
          })

          if (resp.success) {
            showMessage('success', '¡Buen trabajo!', resp.message)
            getDocument(id as string)
          } else {
            showMessage('error', 'Hubo un error', resp.message)
          }
          setGlobalOverlayLoading(false)
        }
      })
      return
    }

    Swal.fire({
      title: `¿Está seguro que desea ${actions[action]} este nivel?`,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setGlobalOverlayLoading(true)
        const resp = await changeLevelStatus({
          idFile,
          idLevel,
          status: action,
          idDoc: id,
        })

        if (resp.success) {
          showMessage('success', '¡Buen trabajo!', resp.message)
          getDocument(id as string)
        } else {
          showMessage('error', 'Hubo un error', resp.message)
        }
        setGlobalOverlayLoading(false)
      }
    })
  }

  const handleSendDocuments = () => {
    Swal.fire({
      title: '¿Está seguro que desea enviar estos documentos?',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setGlobalOverlayLoading(true)
        const resp = await sendDocumentsWhenApprove({idDoc: id})
        setGlobalOverlayLoading(false)

        if (resp.success) {
          showMessage('success', '¡Buen trabajo!', resp.message)
          getDocument(id as string)
        } else {
          showMessage('error', 'Hubo un error', resp.message)
        }
      }
    })
  }

  const handleNextLevelMyAuthDocsToApprove = async (docsToAppove: any[]) => {
    setGlobalOverlayLoading(true)
    const responses: boolean[] = []

    // SYNC porque hay un error de versiones usando asyncronia
    for (const doc of docsToAppove) {
      const response = await changeLevelStatus({
        status: doc.status,
        idFile: doc.idFile,
        idLevel: doc.idLevel,
        idDoc: id,
      })

      responses.push(response.success)
    }

    setGlobalOverlayLoading(false)

    const allSuccess = responses.every((response) => response)

    if (allSuccess) {
      showMessage('success', '¡Buen trabajo!', '')
      getDocument(id as string)
    } else {
      showMessage('error', 'Hubo un error', '')
    }
  }

  const enableSendDocuments =
    data?.idFiles?.every((file: any) => {
      if (file.detalle_niveles == null || file.detalle_niveles?.length === 0) {
        return true
      } else {
        return file.detalle_niveles?.every((level: any) => level.detalle?.estado === 'aprobado')
      }
    }) ?? false

  const isNotSending = data?.estadoCarga === 'pendiente'

  const docsToAppove =
    data?.idFiles
      ?.map((file: any) => {
        if (file.isSendByLevel === true) {
          return null
        }

        if (file.currentLevel == null) {
          return null
        }

        const level = file.detalle_niveles?.find((level: any) => level._id === file.currentLevel)

        if (level == null) {
          return null
        }

        if (!level.autorizado === true || level.detalle.estado === 'rechazado') {
          return null
        }

        if (
          level.detalle.aprobaciones_hechas?.map((d: any) => d.idUser)?.includes(userData?.userId)
        ) {
          return null
        }

        return {
          idDoc: id,
          idFile: data._id,
          idLevel: level._id,
          status: 'approve',
        }
      })
      ?.filter((x: any) => x != null) ?? []

  const handleOpenLevelModal = (file: any) => {
    setLevelModalData(file)
    setLevelModalStatus(true)
  }

  return (
    <DocumentLoadDetailContext.Provider
      value={{
        isDocumentDetailModalOpen,
        openDocumentDetailModal,
        closeDocumentDetailModal,
        isDocumentQuickViewModalOpen,
        openDocumentQuickViewModal,
        closeDocumentQuickViewModal,
        quickViewModalData,
        data,
        showFileModal,
        setShowFileModal,
        viewDocumento,
        setViewDocumento,
        selectedPlantillaId,
        setSelectedPlantillaId,
        viewDocumentoArray,
        setViewDocumentoArray,
        showFile2Modal,
        setShowFile2Modal,
        loadingNewPdf,
        setLoadingNewPdf,
      }}
    >
      <PageTitle breadcrumbs={documentosBreadcrumbs}>Detalle de Carga</PageTitle>
      <GoBackButton goBack={goBack} />
      <KTCard>
        {loading ? (
          <PdfLoadingSpinner />
        ) : (
          <div className='pt-12 pb-6'>
            <Container>
              <Row className='mb-5'>
                <Col sm>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <h3>{data?.nombre}</h3>
                      {docsToAppove.length > 0 && (
                        <Button onClick={() => handleNextLevelMyAuthDocsToApprove(docsToAppove)}>
                          Aprobar todos los niveles correspondientes
                        </Button>
                      )}
                    </div>
                    {/*enableSendDocuments && isNotSending && (
                      <Button
                        variant='success'
                        title='Enviar documentos'
                        onClick={() => handleSendDocuments()}
                      >
                        <KTIcon iconName='check-circle' className='fs-2' />
                        Enviar
                      </Button>
                    )*/}
                  </div>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col sm>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th className='text-center'>Documentos</th>
                        <th className='text-center'>Tipo</th>
                        <th className='text-center'>Objetivo</th>
                        <th className='text-center'>Niveles aprobados</th>
                        <th className='text-center'>Detalle grupo</th>
                        {/* <th className='text-center'>Aprobaciones necesarias</th> */}
                        <th className='text-center'>Opciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.idFiles?.map((file: any, index: any) => {
                        const hasLevels =
                          file.detalle_niveles != null && file.detalle_niveles.length > 0
                        const pendingLevels = file.detalle_niveles?.filter(
                          (x: any) =>
                            ['pendiente', 'rechazado'].includes(x.detalle.estado) ||
                            x.detalle.estado == null
                        )

                        const currentLevel = pendingLevels?.find(
                          (x: any) => x._id === file.currentLevel
                        )

                        const aprobacionesHechas =
                          currentLevel?.detalle?.aprobaciones_hechas?.length ?? 0
                        const cantidadAprobaciones =
                          currentLevel?.detalle?.cantidadaprobaciones ?? 0

                        const imAuthorized =
                          currentLevel?.autorizado === true &&
                          aprobacionesHechas < cantidadAprobaciones &&
                          !currentLevel?.detalle?.aprobaciones_hechas
                            ?.map((d: any) => d.idUser)
                            ?.includes(userData?.userId) &&
                          (currentLevel?.detalle?.estado == null ||
                            currentLevel?.detalle?.estado === 'pendiente') &&
                          file.isSendByLevel !== true

                        const nivelesAprobados =
                          file.detalle_niveles?.filter((x: any) => x.detalle.estado === 'aprobado')
                            ?.length ?? 0

                        const currentLevelNumber =
                          nivelesAprobados + 1 > (file.detalle_niveles?.length ?? 0)
                            ? nivelesAprobados
                            : nivelesAprobados + 1

                        return (
                          <Fragment key={file._id}>
                            {['Configurado', 'CPD'].includes(file.typeSelected) && (
                              <tr>
                                <td className='text-start fw-bold'>
                                  <Badge bg='success-active' className='me-2'>
                                    {index + 1}
                                  </Badge>
                                  {file.name}
                                </td>
                                <td className='text-center fw-bold'>{file.type}</td>
                                <td className='text-center fw-bold'>{file.typeSelected}</td>
                                <td
                                  className={`text-center ${
                                    imAuthorized
                                      ? 'd-flex align-items-center justify-content-between'
                                      : ''
                                  }`}
                                >
                                  <CustomTooltip
                                    variant='light'
                                    className='btn-not-hover bg-transparent w-100 h-100 text-black text-hover-black'
                                    label={
                                      <>
                                        <span>
                                          {hasLevels
                                            ? `${nivelesAprobados} de ${file.detalle_niveles?.length}`
                                            : 'Sin nivel'}
                                        </span>
                                        {hasLevels && imAuthorized && (
                                          <div>
                                            <button
                                              title='Aprobar documento'
                                              className='btn btn-icon btn-sm'
                                              disabled={loading}
                                              onClick={() =>
                                                handleLevelAction(
                                                  'approve',
                                                  file._id,
                                                  file.currentLevel
                                                )
                                              }
                                            >
                                              <KTIcon
                                                iconName='like'
                                                className='fs-3 text-success'
                                              />
                                            </button>
                                            <button
                                              title='Rechazar documento'
                                              className='btn btn-icon btn-sm'
                                              disabled={loading}
                                              onClick={() =>
                                                handleLevelAction(
                                                  'deny',
                                                  file._id,
                                                  file.currentLevel
                                                )
                                              }
                                            >
                                              <KTIcon
                                                iconName='dislike'
                                                className='fs-3 text-danger'
                                              />
                                            </button>
                                          </div>
                                        )}
                                      </>
                                    }
                                  >
                                    {file.historial_niveles != null
                                      ? file.historial_niveles.length > 0
                                        ? file.historial_niveles.map(
                                            (historial: any, index: number) => (
                                              <div
                                                key={index}
                                                className='p-3 border-bottom border-black d-flex flex-column gap-1 align-items-start'
                                                style={{fontSize: '0.8rem'}}
                                              >
                                                <p className='mb-0 text-start'>
                                                  Nivel{' '}
                                                  {file.detalle_niveles.findIndex(
                                                    (n: any) => n._id === historial.idLevel
                                                  ) + 1}
                                                </p>
                                                <p
                                                  className={`mb-0 text-start ${
                                                    historial.estado === 'rechazado'
                                                      ? 'text-danger'
                                                      : 'text-success'
                                                  }`}
                                                >
                                                  Estado: {historial.estado}
                                                </p>
                                                <p className='mb-0 text-start'>
                                                  {historial.estado === 'rechazado'
                                                    ? 'Rechazado por: '
                                                    : 'Aprobado por: '}{' '}
                                                  {historial.username}
                                                </p>
                                                <p className='mb-0 text-start'>
                                                  Fecha:{' '}
                                                  {moment(historial.fecha).format(
                                                    'DD/MM/YYYY H:mm'
                                                  )}
                                                </p>
                                                {historial.motivo != null && (
                                                  <p className='mb-0 text-start'>
                                                    {historial.motivo}
                                                  </p>
                                                )}
                                              </div>
                                            )
                                          )
                                        : 'Sin historial'
                                      : 'Sin historial'}
                                  </CustomTooltip>
                                </td>
                                <td className='text-center fw-bold'>
                                  {hasLevels ? (
                                    <CustomTooltip
                                      variant='light'
                                      className='btn-not-hover bg-transparent w-100 h-100 text-black text-hover-black'
                                      label='Ver'
                                      icon='eye'
                                      onClick={() => handleOpenLevelModal(file)}
                                    >
                                      {/* {file.detalle_niveles.map((n: any, index: number) => (
                                        <div
                                          key={index}
                                          className='p-3 border-bottom border-black d-flex flex-column gap-1 align-items-start'
                                          style={{fontSize: '0.8rem'}}
                                        >
                                          <p className='mb-0 text-start'>Nivel {index + 1}.</p>
                                          {n.detalle.responsables.map((u: any) => (
                                            <p key={u._id} className='mb-0 text-start'>{u.nombre}</p>
                                          ))}
                                        </div>
                                      ))} */}
                                      Ver detalle
                                    </CustomTooltip>
                                  ) : (
                                    'Ninguno'
                                  )}
                                </td>
                                {/* <td className='text-center fw-bold'>
                                  cantidadAprobaciones > 0 ? (
                                    <> */}
                                {/* <p>Nivel {currentLevelNumber}</p> */}
                                {/* {cantidadAprobaciones > 1 ? (
                                        <span>
                                          {`${aprobacionesHechas} de ${cantidadAprobaciones}`}
                                        </span>
                                      ) : (
                                        <span>{cantidadAprobaciones} aprobación</span>
                                      )} */}
                                {/* </>
                                  ) : (
                                    'No requiere'
                                  )
                                </td> */}
                                <td className='text-end'>
                                  <button
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    title='Visualización rápida'
                                    onClick={() =>
                                      handleDocumentQuickViewModal(
                                        file,
                                        hasLevels
                                          ? nivelesAprobados == file.detalle_niveles.length
                                            ? true
                                            : false
                                          : true
                                      )
                                    }
                                  >
                                    <KTIcon iconName='eye' className='fs-3' />
                                  </button>

                                  <button
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    title='Descargar Documentos'
                                    onClick={() => handleDownload(file.keyname, file.filename)}
                                  >
                                    <KTIcon iconName='arrow-down' className='fs-3' />
                                  </button>
                                  <button
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                    title='Cancelar envío de documentos'
                                    // onClick={() => deleteSection()}
                                  >
                                    <KTIcon iconName='cross-square' className='fs-3' />
                                  </button>
                                  {file.typeSelected === 'Configurado' && (
                                    <button
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                      title='Detalle del documento'
                                      onClick={() => handleDocumentDetailModal(file)}
                                    >
                                      <KTIcon iconName='information' className='fs-3' />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            )}
                            {file.typeSelected === 'Personalizado' && (
                              <tr>
                                <td colSpan={4}>
                                  <Accordion>
                                    <Accordion.Item eventKey='0'>
                                      <Accordion.Header>
                                        <span className='fw-bold'>
                                          <Badge bg='success-active' className='me-2'>
                                            Personalizado
                                          </Badge>{' '}
                                          {file.name}
                                        </span>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <table className='w-100'>
                                          <thead>
                                            <tr>
                                              <th className='text-start'>Documentos</th>
                                              <th className='text-center'>Tipo</th>
                                              <th className='text-center'>Objetivo</th>
                                              <th className='text-end'>Opciones</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {data?.filesChildren
                                              ?.filter((n: any) =>
                                                data?.filesChildren
                                                  ?.map((x: any) =>
                                                    x.name.replace(/^[\d_]+|\.pdf$/g, '')
                                                  )
                                                  .includes(n.name.replace(/^[\d_]+|\.pdf$/g, ''))
                                              )
                                              .map((item: any, index: number) => (
                                                <Fragment key={item._id}>
                                                  <tr>
                                                    <td className='text-start fw-bold'>
                                                      <Badge bg='success-active' className='me-2'>
                                                        {index + 1}
                                                      </Badge>
                                                      {item.name.replace(/^[\d_]+|\.pdf$/g, '')}
                                                    </td>
                                                    <td className='text-center fw-bold'>PDF</td>
                                                    <td className='text-center fw-bold'>
                                                      Personalizado
                                                    </td>
                                                    <td className='text-end'>
                                                      <button
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        title='Visualización rápida'
                                                        onClick={() =>
                                                          handleCustomizedViewModal(
                                                            item,
                                                            data?.idProceso
                                                          )
                                                        }
                                                        // onClick={() => handleDocumentQuickViewModal(file)}
                                                      >
                                                        <KTIcon iconName='eye' className='fs-3' />
                                                      </button>

                                                      <button
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        title='Descargar Documentos'
                                                        onClick={() =>
                                                          handleDownload(item.keyname, item.name)
                                                        }
                                                      >
                                                        <KTIcon
                                                          iconName='arrow-down'
                                                          className='fs-3'
                                                        />
                                                      </button>
                                                      <button
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                        title='Cancelar envío de documentos'
                                                        // onClick={() => deleteSection()}
                                                      >
                                                        <KTIcon
                                                          iconName='cross-square'
                                                          className='fs-3'
                                                        />
                                                      </button>
                                                      <button
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                        title='Detalle del documento'
                                                        onClick={() =>
                                                          handleDocumentDetailModal({
                                                            ...file,
                                                            type: 'PDF',
                                                            keyname: item.keyname,
                                                            filename: item.name,
                                                            _id: data?.filesChildren?.find(
                                                              (n: any) => n.name === item.name
                                                            )._id,
                                                            idproceso: data?.idProceso,
                                                          })
                                                        }
                                                      >
                                                        <KTIcon
                                                          iconName='information'
                                                          className='fs-3'
                                                        />
                                                      </button>
                                                    </td>
                                                  </tr>
                                                </Fragment>
                                              ))}
                                          </tbody>
                                        </table>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </td>
                              </tr>
                            )}
                          </Fragment>
                        )
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {/* <Row>
              <div className='d-flex justify-content-end'>
                <Button
                  variant='light'
                  className='mx-3'
                  type='reset'
                  onClick={() => {
                    goBack()
                  }}
                >
                  Cancelar
                </Button>
                <Button variant='primary' type='submit'>
                  {!false && 'Guardar'}
                  {false && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Guardando...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </Button>
              </div>
            </Row> */}
            </Container>
          </div>
        )}
      </KTCard>
      <DocumentDetailModal />
      <DocumentQuickViewModal />
      <ShowFileModal />
      <ShowFile2Modal />
      {loadingNewPdf && <OverlayLoading />}
      {levelModalStatus && (
        <DetalleNivelModal
          grupos={levelModalData.detalle_niveles}
          filename={levelModalData.name}
          historial={levelModalData.historial_niveles}
          closeModal={() => setLevelModalStatus(false)}
        />
      )}
    </DocumentLoadDetailContext.Provider>
  )
}

export {DocumentDetail}
