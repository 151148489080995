import { createContext, Dispatch, SetStateAction } from 'react';
import { Menu } from './components/MenuInterface';

interface PartnerContextProps {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  menuData: Menu;
  setMenuData: Dispatch<SetStateAction<Menu>>;
  edit: boolean;
  setEdit: Dispatch<SetStateAction<boolean>>;
  reloadTable: boolean;
  setReloadTable: Dispatch<SetStateAction<boolean>>;
  role: string;
  setRole: Dispatch<SetStateAction<string>>;
  menus: Menu[];
  setMenus: Dispatch<SetStateAction<Menu[]>>;
}

const defaultMenu: Menu = {
  _id: '',
  titulo: '',
  url: '',
  icono: '',
  tipo: '',
  order: 0,
  id_padre: '',
  id_hijo: '',
  hijos: []
};

const PartnerContext = createContext<PartnerContextProps>({
  searchTerm: '',
  setSearchTerm: () => {},
  menuData: defaultMenu,
  setMenuData: () => {},
  edit: false,
  setEdit: () => {},
  reloadTable: false,
  setReloadTable: () => {},
  role: 'ADMIN',  // Default role
  setRole: () => {},
  menus: [],
  setMenus: () => {}
});

export { PartnerContext };

