import {useContext, useState} from 'react'
import {Form, Row} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {bulkLoad} from '../../../../services/documentos'
import {showInfoHtml, showMessage} from '../../../../shared/Alerts'
import {useUnidades} from '../../../../shared/hooks/useUnidades'
import DropzoneArchivo2 from '../../../generales_component/DropzoneArchivo2'
import {ListFieldsExport} from '../../documento/documentInterface'
import {PartnerContext} from '../PartnerContexts'

const CargarDocumentModalForm = () => {
  const {
    setShowFileModal,
    setViewDocumento,
    closeCargarDocumentModal,
    individualData,
    setSearchSend,
    setSelectIdUnidad,
    setTree
  } = useContext(PartnerContext)
  const [listFieldsExpor, setListFieldsExpor] = useState<ListFieldsExport[]>([])
  const {misUnidades} = useUnidades()
  const [rawFiles, setRawFiles] = useState<Record<string, any>[]>([])
  const [loading, setLoading] = useState(false)
  /* useEffect(()=> {
    console.log("sheet_id")
    console.log(sheet_id)
    if(sheet_id){
      getProcessSheet(sheet_id)
    }
  },[])

  useEffect(()=>{
    showTask()
  },[sheet])

  const getProcessSheet = (id:string) =>{
    getCompleteSheet(id)
    .then((response) => {
      console.log(response)
      setSheet(response.data)
    })
    .catch((error) => console.log(error))
  }
  
  const showTask = () =>{
    const listFieldAux : ListFieldsExport[] = []    
    sheet.sections?.map((section:Section)=>{
      if(!section.dynamic){
        const fieldExport : FieldsExport[] = [] 
        section.fields?.map((field:Field)=>{
          fieldExport.push({identifier:field.id,check:field.checkExport?field.checkExport:false,label:field.name,type:"string",obligatorio:field.required})
        })
        listFieldAux.push({label:section.name,fields:fieldExport})
      }
    })
    setListFieldsExpor(listFieldAux)
  } */

  const fileToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        resolve(reader.result)
      }

      reader.onerror = () => {
        reject(new Error('Error al leer el archivo.'))
      }

      reader.readAsDataURL(file)
    })
  }

  // Función para convertir un array de archivos a Base64
  const filesToBase64 = async (files: any) => {
    const base64Array = []

    for (const file of files) {
      try {
        const base64 = await fileToBase64(file.file)
        base64Array.push({
          raw: base64,
          name: file.file.name,
          visibleToPartner: file.visibleToPartner,
          ext: file.ext,
        })
      } catch (error) {
        console.error(error)
      }
    }

    return base64Array
  }

  const handleFilesSelected = async (fileAuxSec: any[]) => {
    // const infoFiless: {url: string; name: string; size: string}[] = []
    // const newValuesFiles: string[] = []

    // infoArchivo.map((res) => {
    //   if (res.url !== '') {
    //     infoFiless.push(res)
    //     newValuesFiles.push(res.url)
    //   }
    // })

    // files.forEach((res) => {
    //   infoFiless.push({
    //     url: '',
    //     name: res.name,
    //     size: String(res.size),
    //   })
    // })

    // const filesSend: Record<string, any>[] = []
    try {
      const resp = await Promise.all(
        fileAuxSec.map(async (file) => {
          // Esto es porque el archivo no se puede leer 2 veces
          if (!file.raw) {
            file.raw = await fileToBase64(file.file)
          }

          return {
            raw: file.raw,
            name: file.filename,
            visibleToPartner: file.visibleToPartner,
            ext: file.ext,
          }
        })
      )

      setRawFiles(resp)
    } catch (error) {
      console.log(error)
    }
  }

  const onSuccess = () => {

    if (!individualData.origin) {
      showMessage('error', 'Error', 'Debe seleccionar un nombre de carga')
      return
    }

    const rawFilesToUppercase = rawFiles.map((file) => {
      file.name = file.name.toUpperCase()
      return file
    })

    const countRepeatedFiles: Record<string, number>[] = []
    rawFiles.forEach((file) => {
      const countFile = countRepeatedFiles.find((x) => x.key === file.name)
      if (countFile == null) {
        countRepeatedFiles.push({key: file.name, value: 1})
      } else {
        countRepeatedFiles.push({key: file.name, value: countFile.value + 1})
      }
    })

    const existRepeatedFiles = countRepeatedFiles.filter((file) => file.value > 1)

    if (existRepeatedFiles.length > 0) {
      showInfoHtml(
        'error',
        'Existen archivos repetidos, por favor, elimine o cambie el nombre uno de los archivos seleccionados',
        existRepeatedFiles.map((file) => file.key).join('<br/>')
      )
      return
    }

    const unidad = misUnidades.find((u) => u.DesUnid.toLowerCase() === individualData.unidad.toLowerCase())
    const params = {
      processId: individualData.idproceso,
      dni: individualData.dni,
      unidad: unidad?.codUnidad ?? '',
      loadName: individualData.origin,
      files: rawFilesToUppercase,
    }
    console.log(params)
    setSelectIdUnidad('')
    setLoading(true)
    bulkLoad(params)
      .then(async (response) => {
        if (response.success) {
          setSearchSend(true)
          console.log(response.messages)
          if (response.messages.length > 0) {
            await showInfoHtml('error', 'Archivos repetidos no cargados', response.messages.join('<br/>'))
          }

          showMessage('success', '¡Buen trabajo!', response.message)
            .then(() => {
              setTree(individualData.tree)
              setSelectIdUnidad(individualData.unidad === '' ? 'SIN UNIDAD' : individualData.unidad)
            })
        } else {
          throw new Error(response.message)
        }
      })
      .catch((error) => {
        console.log(error)
        showMessage('error', 'Hubo un error', error.message)
      })
      .finally(() => {
        setLoading(false)
        closeCargarDocumentModal()
      })
  }

  return (
    <>
      <Form>
        <Row className='mb-3'>
          <DropzoneArchivo2
            key={'123'}
            onFilesSelected={(fileAuxSec) => handleFilesSelected(fileAuxSec)}
            idx={'123'}
            setShowFileModal={setShowFileModal}
            setViewDocumento={setViewDocumento}
            accept={{zips: ['.zip']}}
            onSuccess={onSuccess}
            loading={loading}
          />
        </Row>
      </Form>
    </>
  )
}

export {CargarDocumentModalForm}
