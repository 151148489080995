import { createContext } from "react"

export interface Enterprice {
  _id: string
  nombre: string
  descripcion: string
  estado: string
  createdAt: string
  updatedAt: string
}

interface PartnerContextProps {
    activeOnly: boolean,
    state: string[],
    searchTerm: string,
    entryDateFrom: any,
    entryDateTo: any,
    setActiveOnly: (value: boolean) => void, 
    setState: (value: string[]) => void,
    setSearchTerm: (value: string) => void,
    setEntryDateFrom: (value: any) => void,
    setEntryDateTo: (value: any) => void,
    isEditUserModalOpen: boolean,
    openEditUserModal: () => void,
    closeEditUserModal: () => void, 
    userData: Record<string, any>,
    setUserData: (value: {}) => void,
    updateUser: boolean,
    setUpdateUser: (value: boolean) => void,
    enterprices: Enterprice[]
    setEnterprices: (values: Enterprice[]) => void
}

const PartnerContext = createContext<PartnerContextProps>({
    activeOnly: true,
    state: [],
    searchTerm: '',
    entryDateFrom: null,
    entryDateTo: null,
    setActiveOnly: (value) => {},
    setState: (value) => {},
    setSearchTerm: (value) => {},
    setEntryDateFrom: (value: any) => {},
    setEntryDateTo: (value: any) => {},
    isEditUserModalOpen: false,
    openEditUserModal: () => {},
    closeEditUserModal: () => {},
    userData:{},
    setUserData: (value) => {},
    updateUser: false,
    setUpdateUser: (value:boolean) => {},
    enterprices: [],
    setEnterprices: () => {}
})

export {PartnerContext}