import { useContext, useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import { KTIcon } from '../../../../../_metronic/helpers'
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from "../../../../services/menu"
import { TableSearch } from "../../../../shared/TableSearch"
import { useAuth2 } from "../../../authv2/Auth2Provider"
import { AlertsContext } from "../AlertsContexts"

const AlertsTableHeader = () => {

    const location = useLocation()
    const currentPath = location.pathname
    const { setSearchTerm } = useContext(AlertsContext)

    const relativeRoute = `${currentPath}/create`

    const handleSearch = (e: string) => {
        setSearchTerm(e)
    }
    const { selectedRole } = useAuth2()
    const [permissions, setPermissions] = useState<string[]>([])
    useEffect(() => {
      const fetchPermissions = async () => {
        const response = await fetchMenuActionsByRole(selectedRole)
        if (response.success) {
          const currentPath = location.pathname
          const subMenuResponse = await fetchSubMenuByUrl(currentPath)
          if (subMenuResponse.success) {
            const subMenuId = subMenuResponse.data.id_hijo
            const permissionsForSubMenu = response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
            setPermissions(permissionsForSubMenu)
          }
        }
      }
  
      fetchPermissions()
    }, [selectedRole])
  
    return (
        <>
            <div className='mx-8 mt-8 mb-6'>
                <Row>
                    <Col>
                        <span className='fw-bold'>Proceso:</span>
                        <Form.Select>
                            <option value='1'>OnBoarding ed</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <span className='fw-bold'>Nombre:</span>
                        <TableSearch onSearch={handleSearch} design={true} placeholderSearch={'Buscar por nombre'} />
                    </Col>
                    <Col className='d-flex align-self-end justify-content-end'>
                        <Link to={relativeRoute}>
                        {permissions.includes('Agregar Alerta') && (
                            <button type='button' className='btn btn-primary'>
                                <KTIcon iconName='plus' className='fs-2' />
                                Alerta
                            </button>
                        )}
                        </Link>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export { AlertsTableHeader }
