import {useContext, useEffect, useState} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import { PartnerEditContext } from '../PartnerEditContext'

const LeyendaModalHeader = () => {
  const {setShowLeyenda} = useContext(PartnerEditContext)
  const [title, setTitle] = useState('')

  useEffect(() => {
    setTitle('Agregar')
  }, [])

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>Información de tipo de flujo del documento</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        onClick={() => setShowLeyenda(false)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
    </div>
  )
}

export {LeyendaModalHeader}
