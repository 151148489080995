import React, {Fragment, useEffect, useState,useContext} from 'react'
import { getFilexIdproceso, getFilexIdprocesoColaborador} from '../../../../services/files'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {downloadFileS3, deleteFile} from '../../../../services/files'
import {showDeleteMessage, showMessage} from '../../../../shared/Alerts'
import {useNavigate} from 'react-router-dom'
import { PartnerContext } from '../PartnerContexts'


export const ListDocuments = ({id}) => {
  
  const { openEditFileModal,setFileData,updateFile,setUpdateFile, setDocuments, setDocumentsAux, documents  } = useContext(PartnerContext)
  const [documento, setDocumento] = useState('')
  const [documentoaux, setDocumentoaux] = useState(documents)
  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    refetchData()
  }, [])

  useEffect(() => {
    refetchData()
  }, [updated])

  useEffect(() => {
    setDocumento(documents)
  }, [documents])

  useEffect(() => {
    if(updateFile){
      refetchData()
      setUpdateFile(false)
    }
  }, [updateFile])

  const refetchData = () => {
    getFilexIdprocesoColaborador(id)
      .then((response) => {
        if (response.success) {
          setDocumentsAux(response?.data)
          setDocuments(response?.data)
        } else {
          console.log(response.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }


  const handleDownload = async (keyname,filename) => {    
    const partes = filename.split('.');
    const extension = partes[partes.length - 1];

    downloadFileS3(keyname,extension)
        .then((response) => {
          if (response.success) {
            const link = document.createElement('a');
            link.href = response.data;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            showMessage('success', '¡Buen trabajo!', response.message)
          } else {
            console.log(response)
            showMessage('error', 'Hubo un error', response.error)
          }
        })
        .catch((error) => {
          console.log(error)
          showMessage('error', 'Hubo un error', error.message)
        })
  }

  const handleView = (id,keyname,filename) => {
    const partes = filename.split('.');
    const extension = partes[partes.length - 1];
    // if(extension==="pdf"){
      const url = `/configuracion/documentos/view/${id}`;
      window.open(url, '_blank');
    /* }else{
      downloadFileS3(keyname,extension)
        .then((response) => {
            if (response.success) {
                const link = document.createElement('a');
                link.style.display = 'none'; 
                link.href = response.data;
                link.download = filename;
                document.body.appendChild(link);
                link.click();                
                document.body.removeChild(link);
                link.addEventListener('load', () => {
                  window.open(response.data, '_blank');
                });
            }

        })
        .catch((error) => {
            console.log(error)
        })
    } */
  }

  const handleActualizar = (file) => {
    setFileData({...file,type_document:file.type_document?file.type_document:'KIT DE INGRESO'})
    openEditFileModal()
  }

  const handleDelete = (id)=>{
    showDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        deleteFile(id)
        .then((response) => {
            if (response.success) {
              refetchData()
              showMessage('success', '¡Buen trabajo!', response.message)
            }
        })
        .catch((error) => {
            console.log(error)
        })
      }
    })

    
  }


  return (
    <>
      {documento && (
        <>
        {Object.keys(documento).map(key => ( 
          <Fragment key={key}>
          <tr >
            <td>
            </td>
            <td className='text-start ' > 

              <a href='# ' className='text-dark fw-bold text-hover-primary mx-7 mb-1 fs-6'>
                {key}
              </a>     
            </td>
            <td>
            </td>
          </tr>
          { documento[key].map((item, index) => (
            <Fragment key={item._id}>
              <tr >
                <td></td>
                <td className='text-start'>
                    {item.type === "PLANTILLA"?
                      <img
                        src={toAbsoluteUrl('/media/svg/files/doc.svg')}
                        className='h-20px mx-10'
                      />
                    :
                      <img
                        src={toAbsoluteUrl('/media/svg/files/pdf.svg')}
                        className='h-20px mx-10'
                      />
                    }
                    
                  <a href='# ' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    {item.name}
                  </a>
                </td>
                <td>
                  <a href='# ' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    {item.type}
                  </a>
                </td>
                <td>
                  <a href='# ' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    {item.type_document}
                  </a>
                </td>
                <td className='text-end text-muted fw-semibold'>
                <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      onClick={() => handleDownload(item.keyname,item.filename)}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTIcon iconName='cloud-download' className='fs-3' />
                    </a>
                    <a
                      href='#'
                      onClick={() => handleView(item._id,item.keyname,item.filename)}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTIcon iconName='eye' className='fs-3' />
                    </a>
                  </div>  
                </td>
              </tr>
            </Fragment>
          ))}
          </Fragment>
          ))}
          
          
        </>
      )}
    </>
  )
}
