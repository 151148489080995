import React, {useContext, useEffect, useState} from 'react'
import {ListGroup, Badge, Form} from 'react-bootstrap'
import { PartnerContext } from '../../../PartnerContexts'

const AssigmentListGroup = ({onChange, elementSelected}: any) => {
  const {externalData} = useContext(PartnerContext)
  const [searchQuery, setSearchQuery] = useState('')

  const [selectedItemId, setSelectedItemId] = useState('')

  useEffect(() => {
    if (elementSelected !== '') {
      setSelectedItemId(elementSelected)
    }
  }, [])

  const handleItemClick = (itemId: any) => {
    setSelectedItemId(itemId === selectedItemId ? null : itemId)
    onChange(itemId)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  // Set a maximum height for the ListGroup container
  const maxListHeight = '300px'

  return (
    <>
      <Form.Group>
        <Form.Control
          type='text'
          placeholder='Buscar...'
          value={searchQuery}
          onChange={handleSearchChange}
          className='my-2'
        />
      </Form.Group>
      <div style={{maxHeight: maxListHeight, overflowY: 'scroll'}}>
        { externalData.fields && (
          externalData.fields.map((group) => {
            const filteredFields = group.fields.filter((item:any) =>
              item.label.toLowerCase().includes(searchQuery.toLowerCase())
            )
  
            if (filteredFields.length === 0) {
              return null
            }
  
            return (
              <>
                <p className='my-3 ms-2 fw-bold'>{group.label}</p>
                <ListGroup>
                  {filteredFields.map((item:any) => (
                    <ListGroup.Item
                      key={item.identifier}
                      active={item.identifier === selectedItemId}
                      onClick={() => handleItemClick(item.identifier)}
                      style={{cursor: 'pointer'}}
                    >
                      {item.label}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )
          })
        )}
      </div>
    </>
  )
}

export default AssigmentListGroup
