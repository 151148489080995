import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { KTCard } from '../../../../_metronic/helpers';
import { PageTitle } from '../../../../_metronic/layout/core';
import { PartnerContext } from './PartnerContexts';
import ActionAsignacionForm from './components/ActionAsignacionForm';
import MenuAsignacionForm from './components/MenuAsignacionForm';
import { Menu } from './components/MenuInterface';

const Breadcrumbs = [
  {
    title: 'Seguridad',
    path: '/seguridad/asignacionmenu',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const DataMenu: Menu = {
  _id: '',
  titulo: '',
  url: '',
  icono: '',
  tipo: '',
  order: 0,
  id_padre: '',
  id_hijo: '',
  hijos: []
};

const AsignacionMenuPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [menuData, setMenuData] = useState<Menu>(DataMenu);
  const [edit, setEdit] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [role, setRole] = useState('ADMIN');
  const [menus, setMenus] = useState<Menu[]>([]);

  return (
    <>
      <PartnerContext.Provider
        value={{
          searchTerm,
          setSearchTerm,
          menuData,
          setMenuData,
          edit,
          setEdit,
          reloadTable,
          setReloadTable,
          role,
          setRole,
          menus,
          setMenus,
        }}
      >
        <PageTitle breadcrumbs={Breadcrumbs}>Asignación de Menús y Acciones</PageTitle>
        <Container fluid>
          <Row>
            <Col xs={12} md={6}>
              <KTCard>
                <MenuAsignacionForm />
              </KTCard>
            </Col>
            <Col xs={12} md={6}>
              <KTCard>
                <ActionAsignacionForm />
              </KTCard>
            </Col>
          </Row>
        </Container>
      </PartnerContext.Provider>
    </>
  );
};

export { AsignacionMenuPage };

