import {useContext} from 'react'
import {GlobalOverlayContext} from './GlobalOverlayProvider'
import OverlayLoading from './OverlayLoading'

const GlobalOverlayLoding = () => {
  const {globalOverlayLoading} = useContext(GlobalOverlayContext)

  return <>{globalOverlayLoading && <OverlayLoading />}</>
}

export {GlobalOverlayLoding}
