import { useEffect, useState } from "react";
import { Col, Form, ListGroup, Row } from "react-bootstrap";
import { v4 } from 'uuid';
import { KTIcon } from "../../../../../_metronic/helpers";
import { AlertDropdown, AlertFilter } from "./Alert.interface";
import { FilterListItem } from "./FilterListItem";



const FilterList = ({ list, onChange, data }: any) => {

  const [isUpdate, setUpdateStatus] = useState<boolean>(false)
  const [filterItem, setFilterItem] = useState<AlertFilter>({
    id: v4(),
    field: '',
    operator: '',
    value: ''
  })
  const [filterList, setFilterList] = useState<AlertFilter[]>([])
  const [fieldList, setFieldList] = useState<AlertDropdown[]>([])

  useEffect(()=>{
    if (data) {
      setFilterList(data)
    }
  },[])

  useEffect(() => {
    onChange(filterList)
  }, [
    filterList
  ])

  useEffect(() => {
    console.log("AQUIII")
    setFieldList(list)
  }, [list])

  const handleSelectChange = (e: any) => {
    setFilterItem({ ...filterItem, [e.target.name]: e.target.value })
  }

  const handleNewFilter = () => {
    setFilterList([...filterList, filterItem])
    setFilterItem({
      id: v4(),
      field: '',
      operator: '',
      value: ''
    })

  }

  const handleUpdate = () => {
    setFilterList(filterList.map(x => {
      if (x.id === filterItem.id) {
        return { id: x.id, field: filterItem.field, operator: filterItem.operator, value: filterItem.value };
      } else {
        return x;
      }
    }))
    setFilterItem({
      id: v4(),
      field: '',
      operator: '',
      value: ''
    })
    setUpdateStatus(false)
  }

  const handleCancel = () => {
    setFilterItem({
      id: v4(),
      field: '',
      operator: '',
      value: ''
    })

    setUpdateStatus(false)
  }

  const handleFilterDelete = (id: string) => {
    setFilterList(filterList.filter(n => n.id !== id))
    setUpdateStatus(false)

    setFilterItem({
      id: v4(),
      field: '',
      operator: '',
      value: ''
    })
  }

  const handleFilterEdit = (id: string) => {

    setUpdateStatus(true)

    const filterToUpdate = filterList.find(n => n.id === id)

    if (filterToUpdate) {
      setFilterItem(filterToUpdate)
    }
  }

  return (
    <>
      <Row className='mt-3'>
        <Col sm>
          <Form.Group controlId='formField'>
            <Form.Label>Campo</Form.Label>
            <Form.Control
              as='select'
              value={filterItem.field}
              name='field'
              onChange={handleSelectChange}
            >
              <option value=''>Seleccione un campo</option>
              {
                fieldList?.map((section: any) => (
                  <optgroup key={section.id} label={section.name}>
                    {section.fields.map((field: any) => (
                      <option key={field.id} value={field.id}>
                        {field.name}
                      </option>
                    ))}
                  </optgroup>
                ))
              }
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Seleccione un campo.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm>
          <Form.Group controlId='formOperator'>
            <Form.Label>Operador</Form.Label>
            <Form.Control
              as='select'
              value={filterItem.operator}
              name='operator'
              onChange={handleSelectChange}
            >
              <option value=''>Seleccione un operador</option>
              <option value='equals'>Es igual a</option>
              <option value='notEquals'>Es diferente a</option>
              <option value='contains'>Contiene</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Seleccione un operador.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col sm>

          <Form.Group controlId='formValue'>
            <Form.Label>Valor</Form.Label>
            <Form.Control
              type='text'
              value={filterItem.value}
              placeholder='Ingrese un valor'
              name='value'
              onChange={handleSelectChange}
            />
            <Form.Control.Feedback type="invalid">
              Escriba una valor.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          {isUpdate ? <>
            <button
              type='button'
              className='btn btn-success mt-8'
              onClick={() => handleUpdate()}
            >
              Actualizar
            </button>
            <button
              type='button'
              className='btn btn-secondary mt-8 ms-2'
              onClick={() => handleCancel()}
            >
              Cancelar
            </button>
          </> : <button
            type='button'
            className='btn btn-success mt-8'
            onClick={() => handleNewFilter()}
          >
            <KTIcon iconName='plus' className='fs-2' />
            Filtro
          </button>}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          {
            filterList.length > 0 ? <ListGroup>
              {filterList.map((x, i) => (
                <FilterListItem key={x.id} item={x} index={i + 1} onEditClick={(id: string) => { handleFilterEdit(id) }} onDeleteClick={(id: string) => { handleFilterDelete(id) }} />
              ))}
            </ListGroup> :
              <span className="fw-bold text-primary">Aun no se ha agregado ningun filtro.</span>
          }
        </Col>
        <Col></Col>
      </Row>
    </>
  )
}


export { FilterList };

