/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import clsx from 'clsx';

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Anterior') {
    return 'Anterior';
  }

  if (label === 'Siguiente &raquo;') {
    return 'Siguiente';
  }

  return label;
};

interface Link {
  page: number;
  label: string;
}

interface PaginationProps {
  links: Link[];
  currentPage: number;
  isLoading: boolean;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ links, currentPage, isLoading, onPageChange }) => {
  const updatePage = (page: number) => {
    if (isLoading || currentPage === page) {
      return;
    }

    onPageChange(page);
  };

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {links
              ?.map((link) => {
                return { ...link, label: mappedLabel(link.label) };
              })
              .map((link) => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: currentPage === link.page,
                    disabled: isLoading,
                    previous: link.label === 'Anterior',
                    next: link.label === 'Siguiente',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Anterior' || link.label === 'Siguiente',
                      'me-5': link.label === 'Anterior',
                    })}
                    onClick={() => updatePage(link.page)}
                    style={{ cursor: 'pointer' }}
                  >
                    {mappedLabel(link.label)}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
