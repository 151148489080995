import { useEffect, useState } from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {PartnerContext} from './BandejaProcesoContexts'
import {PartnersListHeader} from './components/PartnersListHeader'
import {PartnersTable} from './components/PartnersTable'
import { Campos, DataTabla, Ficha, ProcesoFichas, Seccion, Tabla } from '../../documentacion/MiFicha/FIchaInterface'
import { Sheet } from '../../configuracion/fichas/Sheet'
import { useAuth2 } from '../../authv2/Auth2Provider'
import { useLocation } from 'react-router-dom'
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from '../../../services/menu'


const bandejaProcesoBreadcrumbs = [
  {
    title: 'Mis Documentos',
    path: '/documentos/bandejaproceso',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BandejaProcesoPage = () => {

  const [activeOnly, setActiveOnly] = useState(true)
  const [state, setState] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [entryDateFrom, setEntryDateFrom] = useState()
  const [entryDateTo, setEntryDateTo] = useState()
  const [idRegistroFicha, setIdRegistroFicha] = useState('')
  const [isObservacionModalOpen, setObservacionModalStatus] = useState(false)
  const [procesoFichaData, setProcesoFichaData] = useState<ProcesoFichas[]>([])
  const [sheet, setSheet] = useState<Sheet>({name: "",type: "",description: "",sections: []})
  const [fichas, setFichas] = useState<Ficha[]>([])
  const [seccion, setSeccion] = useState<Seccion[]>([])
  const [campos, setCampos] = useState<Campos[]>([])
  const [secciones, setSecciones] = useState<Tabla[]>([])
  const [dataSecciones, setDataSecciones] = useState<DataTabla[]>([])
  const [isFichaPlantillaModalOpen, setFichaPlantillaModalStatus] = useState(false)
  const [sheet_id, setSheetId] = useState('')
  const [showCargaExcelModal,setShowCargaExcelModal] = useState(false)
  const [refresh,setRefresh] = useState(false)
  const [permissions, setPermissions] = useState<string[]>([])
  const {selectedRole} = useAuth2()
  const location = useLocation()
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu =
            response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])


  const openFichaPlantillaModal = () => {
    setFichaPlantillaModalStatus(true)
  }

  const closeFichaPlantillaModal = () => {
    setFichaPlantillaModalStatus(false)
  }

  const openObservacionModal = (id:string) => {
    setIdRegistroFicha(id)
    setObservacionModalStatus(true)
  }

  const handleInputObservacionChange = (id:string) => {
  }

  const closObservacionModal = () => {
    setObservacionModalStatus(false)
    //clearSection()
  }

  return (
    <>
      {permissions.includes('Lista') && (  
        <PartnerContext.Provider
        value={{
          activeOnly,
          setActiveOnly,
          state,
          setState,
          isObservacionModalOpen,openObservacionModal,
          closObservacionModal,idRegistroFicha,procesoFichaData,setProcesoFichaData,
          sheet, 
          setSheet,
          fichas,
          setFichas,
          seccion,
          setSeccion,
          campos,
          setCampos,
          secciones,
          setSecciones,
          dataSecciones,
          setDataSecciones,
          handleInputObservacionChange,
          searchTerm,
          setSearchTerm,
          entryDateFrom,
          setEntryDateFrom,
          entryDateTo,
          setEntryDateTo,
          isFichaPlantillaModalOpen,
          openFichaPlantillaModal,
          closeFichaPlantillaModal,
          sheet_id,
          setSheetId,
          showCargaExcelModal,
          setShowCargaExcelModal,
          refresh,
          setRefresh
        }}>
          <PageTitle breadcrumbs={bandejaProcesoBreadcrumbs}>Bandeja de Procesos</PageTitle>
          <KTCard>
            <PartnersListHeader />
            <PartnersTable />
          </KTCard>
        </PartnerContext.Provider>
      )}
      
    </>
  )
}

export {BandejaProcesoPage}
