import {Doc} from './PreviewDocument'
import {PreviewDocumentModalForm} from './PreviewDocumentModalForm'
import {PreviewDocumentModalHeader} from './PreviewDocumentModalHeader'

interface PreviewDocumentModalProps {
  isPreviewDocumentModalOpen: boolean
  setIsPreviewDocumentModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  documentos: Doc[]
}

const PreviewDocumentModal: React.FC<PreviewDocumentModalProps> = ({
  isPreviewDocumentModalOpen,
  setIsPreviewDocumentModalOpen,
  title,
  documentos,
}) => {
  return (
    <>
      {isPreviewDocumentModalOpen && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              <div className='modal-dialog modal-xl modal-dialog-centered' style={{minWidth: '90%'}}>
                <div className='modal-content'>
                  <PreviewDocumentModalHeader
                    setIsPreviewDocumentModalOpen={setIsPreviewDocumentModalOpen}
                  />
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <PreviewDocumentModalForm
                      setIsPreviewDocumentModalOpen={setIsPreviewDocumentModalOpen}
                      documentos={documentos}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  )
}

export {PreviewDocumentModal}
