import {Fragment, useContext, useEffect, useMemo, useState} from 'react'
import {Form, Stack} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {useTable} from 'react-table'
import {KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import {deletePartner, updatePartner} from '../../../../services/colaboradores'
import {getAllCompaniesByPais} from '../../../../services/companies'
import {changeStatusFicha} from '../../../../services/registroFicha'
import {activarUser, deleteUser, getAllUsersPag} from '../../../../services/users'
import {showDeleteMessage, showMessage, showReactivationMessage} from '../../../../shared/Alerts'
import Pagination from '../../../../shared/Pagination'
import {UsersListLoading} from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import {useQueryResponseData} from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import {usersColumns} from '../../../apps/user-management/users-list/table/columns/_columns'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {PartnerContext} from '../PartnerContexts'

const UsersTable = () => {
  const users = useQueryResponseData()
  const {selectedCompanyId} = useAuth2()
  // const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const [isLoading, setLoadingStatus] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [fetchStatus, setFechStatus] = useState('')
  const [fetchMessage, setFetchMessage] = useState('')

  const [lista, setLista] = useState([])
  const [selecto, setSelecto] = useState(0)
  const [show, setShow] = useState(false)
  const [meliminar, setMeliminar] = useState(false)
  const [eliminar, setEliminar] = useState('')
  const [mactualizar, setMactualizar] = useState(false)
  const [actualizar, setActualizar] = useState('')
  const [mnuevo, setMnuevo] = useState(false)
  const [nuevo, setNuevo] = useState('')

  const [userEmpresa, setUserEmpresa] = useState<Record<string, string>>({})

  // const {searchTerm} = useContext(SheetContext)
  const {
    activeOnly,
    state,
    searchTerm,
    entryDateFrom,
    entryDateTo,
    updateUser,
    setUpdateUser,
    setUserData,
    openEditUserModal,
    enterprices,
    setEnterprices,
  } = useContext(PartnerContext)

  const navigate = useNavigate()

  const iconsData = [
    {
      iconName: 'verify',
      title: 'Borrador',
      stateName: 'borrador',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Completado',
      stateName: 'completado',
      color: '#2ecc71', // Green
    },
  ]

  useEffect(() => {
    setLoadingStatus(true)
    getAllCompaniesByPais().then((data) => {
      setEnterprices(data.data)
    })

    refetchData({
      activeOnly: activeOnly,
      sheetStates: state,
      searchQuery: searchTerm,
      entryDateFrom: entryDateFrom,
      entryDateTo: entryDateTo,
    })
  }, [])

  useEffect(() => {
    setLoadingStatus(true)
    refetchData({
      activeOnly: activeOnly,
      sheetStates: state,
      searchQuery: searchTerm,
    })
  }, [searchTerm])

  useEffect(() => {
    if (updateUser) {
      setUpdateUser(false)
      setLoadingStatus(true)
      refetchData({
        activeOnly: activeOnly,
        sheetStates: state,
        searchQuery: searchTerm,
      })
    }
  }, [updateUser])

  useEffect(() => {
    setLoadingStatus(true)
    refetchData({
      activeOnly: activeOnly,
      sheetStates: state,
      searchQuery: searchTerm,
      entryDateFrom: entryDateFrom,
      entryDateTo: entryDateTo,
    })
  }, [activeOnly, state, entryDateFrom, entryDateTo, currentPage])

  const refetchData = (options: any) => {
    setLoadingStatus(true)
    getAllUsersPag(currentPage, 10, options)
      .then((response) => {
        if (response.success) {
          console.log(response)
          setLoadingStatus(false)
          const auxTotalPage = response.length ? Math.ceil(response.length / 10) : totalPages
          setTotalPages(auxTotalPage)
          setLista(response?.data)

          const ue = response?.data.reduce((acc: any, user: any) => {
            acc[user.dni] = user.empresa[0]
            return acc
          }, {})

          setUserEmpresa(ue)
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleObservar = (id: any, namecolaborador: any) => {
    navigate(`/seguimiento/colaborador/observacion/${id}/${namecolaborador}`)
  }

  const handleValidation = (id: any, namecolaborador: any) => {
    navigate(`/configuracion/validar/actions/${id}/${namecolaborador}`)
  }

  const changePartnerStatus = (id: string, activeState: boolean) => {
    if (activeState === false) {
      showDeleteMessage().then((result) => {
        if (result.isConfirmed) {
          deletePartner(id)
            .then((response) => {
              if (response.success) {
                showMessage('success', '¡Buen trabajo!', response.message)
              } else {
                showMessage('error', 'Hubo un error', response.error)
              }
              refetchData({
                activeOnly: activeOnly,
                sheetStates: state,
                searchQuery: searchTerm,
                entryDateFrom: entryDateFrom,
                entryDateTo: entryDateTo,
              })
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    } else {
      updatePartner(id, {estado: true})
        .then((response) => {
          refetchData({
            activeOnly: activeOnly,
            sheetStates: state,
            searchQuery: searchTerm,
            entryDateFrom: entryDateFrom,
            entryDateTo: entryDateTo,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  // Utility function to generate pagination links based on the current page and totalPages values
  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    // Generate "Previous" link if not on the first page
    if (currentPage > 1) {
      links.push({page: currentPage - 1, label: 'Anterior'})
    }

    // Generate numbered pages
    for (let i = 1; i <= totalPages; i++) {
      links.push({page: i, label: String(i)})
    }

    // Generate "Next" link if not on the last page
    if (currentPage < totalPages) {
      links.push({page: currentPage + 1, label: 'Siguiente'})
    }

    return links
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleDelete = (id: string) => {
    showDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        deleteUser(id)
          .then((response) => {
            if (response.success) {
              console.log(response)
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              console.log(response)
              showMessage('error', 'Hubo un error', response.error)
            }
            refetchData({
              activeOnly: activeOnly,
              searchQuery: searchTerm,
            })
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message))
      }
    })
  }

  const handleReactivation = (id: string) => {
    showReactivationMessage().then((result) => {
      if (result.isConfirmed) {
        activarUser(id)
          .then((response) => {
            if (response.success) {
              console.log(response)
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              console.log(response)
              showMessage('error', 'Hubo un error', response.error)
            }
            refetchData({
              activeOnly: activeOnly,
              searchQuery: searchTerm,
            })
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message))
      }
    })
  }

  const changeStatusRegistroFicha = (id: string, estado: string) => {
    changeStatusFicha(id, {estado})
      .then((response) => {
        if (response.success) {
          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          showMessage('error', 'Hubo un error', response.error)
        }
        refetchData({
          activeOnly: activeOnly,
          sheetStates: state,
          searchQuery: searchTerm,
          entryDateFrom: entryDateFrom,
          entryDateTo: entryDateTo,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleActualizar = (userData: any) => {
    setUserData(userData)
    openEditUserModal()
  }

  const showInComboBox = (rols: string[]) => {
    return rols.length > 1
  }

  // Custom Toggle Component
  // const CustomToggle = React.forwardRef(({onClick}: any, ref: any) => (
  //   <a
  //     ref={ref}
  //     href='#'
  //     className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
  //     title='Visualizar ficha'
  //     onClick={(e) => {
  //       e.preventDefault()
  //       onClick(e)
  //     }}
  //   >
  //     <KTIcon iconName='dots-square-vertical' className='fs-3' />
  //   </a>
  // ))

  const getEnterpricesName = (ids: string[]): {nombre: string; id: string}[] => {
    return enterprices
      .filter((enterprice) => ids.includes(enterprice._id))
      .map((e) => ({nombre: e.nombre, id: e._id}))
  }

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-4 table-hover'>
            <thead>
              <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-primary text-white'>
                <th className='text-center'>DNI</th>
                <th className='text-center'>Nombres</th>
                <th className='text-center'>Apellidos</th>
                <th className='text-center'>Correo</th>
                <th className='text-center'>Empresa (s)</th>
                <th className='text-center'>Rol</th>
                <th className='text-center'>Opciones</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {lista.length > 0 ? (
                lista?.map((item: any) => {
                  const rolesByEmpresa = item.roles?.find(
                    (rol: any) => rol.empresa === userEmpresa[item.dni]
                  )
                  return (
                    <Fragment key={item._id}>
                      <tr>
                        <td className='text-center'>{item.dni}</td>
                        <td className='text-center min-w-100px'>
                          {item.nombres ? item.nombres : ''}
                        </td>
                        <td className='text-center min-w-100px'>
                          {item.apellido_paterno ? item.apellido_paterno : ''}{' '}
                          {item.apellido_materno ? item.apellido_materno : ''}
                        </td>
                        <td className='text-center min-w-100px'>{item.email}</td>
                        <td className='text-start min-w-200px'>
                          {showInComboBox(item.empresa) ? (
                            <Form.Select
                              onChange={(e) => {
                                setUserEmpresa((prev) => ({...prev, [item.dni]: e.target.value}))
                              }}
                            >
                              {getEnterpricesName(item.empresa).map((e) => (
                                <option
                                  selected={e.id === selectedCompanyId}
                                  key={e.id}
                                  value={e.id}
                                >
                                  {e.nombre}
                                </option>
                              ))}
                            </Form.Select>
                          ) : (
                            getEnterpricesName(item.empresa)[0].nombre
                          )}
                        </td>
                        <td className='text-start min-w-100px'>
                          {rolesByEmpresa?.rol?.length > 0 ? (
                            <Form.Select>
                              {rolesByEmpresa?.rol?.map((rol: any) => (
                                <option>{rol}</option>
                              ))}
                            </Form.Select>
                          ) : (
                            'Sin roles en la empresa seleccionada'
                          )}
                        </td>
                        <td className='text-center d-flex align-self-end justify-content-end'>
                          <Stack direction='horizontal' gap={1}>
                            <a
                              href='#up'
                              className='btn btn-icon btn-bg-light btn-light-info btn-sm'
                              title='Editar'
                              onClick={() => handleActualizar(item)}
                            >
                              <KTIcon iconName='pencil' className='fs-3' />
                            </a>
                            {item.estado ? (
                              <a
                                href='#del'
                                className='btn btn-icon btn-bg-light btn-light-danger btn-sm'
                                title='ver detalle'
                                onClick={() => handleDelete(item._id)}
                              >
                                <KTIcon iconName='cross' className='fs-3' />
                              </a>
                            ) : (
                              <a
                                href='#rea'
                                className='btn btn-icon btn-bg-light btn-light-success btn-sm'
                                title='ver detalle'
                                onClick={() => handleReactivation(item._id)}
                              >
                                <KTIcon iconName='check' className='fs-3' />
                              </a>
                            )}
                          </Stack>
                        </td>
                      </tr>
                    </Fragment>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export {UsersTable}
