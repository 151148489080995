import { createContext } from "react"
import { Doc } from "../../enviodocumento/enviodocumentInterface"

interface DocumentLoadDetailContextProps {
    isHistorialDocumentDetailModalOpen: boolean,
    openHistorialDocumentDetailModal: () => void,
    closeHistorialDocumentDetailModal: () => void
    isDocumentQuickViewModalOpen: boolean,
    openDocumentQuickViewModal: () => void,
    closeDocumentQuickViewModal: () => void,
    quickViewModalData: any,
    data: any,
    showFileModal: boolean,
    setShowFileModal: (value: boolean) => void,
    viewDocumento: Doc[],
    setViewDocumento: (value: Doc[]) => void,
    indiceDocumento: number,
    setIndiceDocumento: (value: number) => void
    

}

const DocumentLoadDetailContext = createContext<DocumentLoadDetailContextProps>({
    isHistorialDocumentDetailModalOpen: false,
    openHistorialDocumentDetailModal: () => {},
    closeHistorialDocumentDetailModal: () => {},
    isDocumentQuickViewModalOpen: false,
    openDocumentQuickViewModal: () => {},
    closeDocumentQuickViewModal: () => {},
    quickViewModalData: {},
    data: {},
    showFileModal: false,
    setShowFileModal: (value) => {},
    viewDocumento: [],
    setViewDocumento: (value) => {},
    indiceDocumento: 0,
    setIndiceDocumento: (value) => {},
})

export { DocumentLoadDetailContext }