import { createContext } from "react"
import { Vista } from "./vistasadicionalesInterface"

interface PartnerContextProps {
    searchTerm: string,
    setSearchTerm: (value: string) => void,
    vistaData:Vista,
    setVistaData: (value: Vista) => void,
    edit: boolean,
    setEdit: (value: boolean) => void,
    reloadTable: boolean,
    setReloadTable: (value: boolean) => void
}

const PartnerContext = createContext<PartnerContextProps>({
    searchTerm: '',
    setSearchTerm: (value) => {},
    vistaData:{_id:'',nombre: '',url: '',contenido: '',id_empresa: '',estado: true},
    setVistaData: (value) => {},
    edit: false,
    setEdit: (value) => {},
    reloadTable: false,
    setReloadTable: (value) => {},
})

export {PartnerContext}