import { KTCard } from "../../../../_metronic/helpers"
import { PageTitle } from "../../../../_metronic/layout/core"
import { ValidationListHeader } from "./components/ValidationListHeader"
import { ValidationTable } from "./components/ValidationTable"

const partnersBreadcrumbs = [
    {
      title: 'Configuración',
      path: '/configuracion/validar',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

const ValidationPage = () => {
    return (<>
        <PageTitle breadcrumbs={partnersBreadcrumbs}>Validar Enrolamiento</PageTitle>
        <KTCard>
            <ValidationListHeader />
            <ValidationTable />
        </KTCard>
    </>)
}

export { ValidationPage }