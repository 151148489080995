import { fetchConToken } from "../helpers/fetch"

const getAllGrupoAprobaciónsPag = async (currentPage:any,numberData:any,options: any) => {
  const queryString = new URLSearchParams(options).toString();

  const resp = await fetchConToken(
    `grupoaprobacion/${currentPage}/${numberData}?${queryString}`,
    {},
    'GET'
  );

  const body = await resp.json();

  return body;
};

const getAllGrupoAprobacións = async () => {
  const resp = await fetchConToken(
    `grupoaprobacion`,
    {},
    'GET'
  );

  const body = await resp.json();

  return body;
};

const crearGrupoAprobaciónData = async (data: any) => {
  const resp = await fetchConToken(
      `grupoaprobacion/add`,
      data,
      'POST'
  );
  const body = await resp.json();

  return body;
}

const updateGrupoAprobaciónData = async (id: string, updatedData: any) => {
  const resp = await fetchConToken(
      `grupoaprobacion/${id}`,
      updatedData,
      'PUT'
  );
  const body = await resp.json();

  return body;
}


const deleteGrupoAprobación = async (id:string) => {
  const resp = await fetchConToken(`grupoaprobacion/${id}`, {}, 'DELETE')

  const body = await resp.json()
  return body
}

const activarGrupoAprobación = async (id:string) => {
  const resp = await fetchConToken(`grupoaprobacion/activar/${id}`, {}, 'PUT')

  const body = await resp.json()
  return body
}
  
export {
    getAllGrupoAprobaciónsPag,
    getAllGrupoAprobacións,
    deleteGrupoAprobación,
    activarGrupoAprobación,
    crearGrupoAprobaciónData,
    updateGrupoAprobaciónData
};