import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTable } from 'react-table';
import { KTCardBody, KTIcon } from '../../../../../_metronic/helpers';
import { fetchConToken } from '../../../../helpers/fetch';
import { activarUser, deleteUser, getAllUsersPag } from '../../../../services/users';
import { showDeleteMessage, showMessage, showReactivationMessage } from '../../../../shared/Alerts';
import Pagination from '../../../../shared/Pagination';
import { UsersListLoading } from '../../../apps/user-management/users-list/components/loading/UsersListLoading';
import { useQueryResponseData } from '../../../apps/user-management/users-list/core/QueryResponseProvider';
import { usersColumns } from '../../../apps/user-management/users-list/table/columns/_columns';
import { useAuth2 } from '../../../authv2/Auth2Provider';
import { PartnerContext } from '../PartnerContexts';

type SelectedCompanyType = {
  [key: string]: string;
};

const AsignacionUnidadTable = () => {
  const users = useQueryResponseData();
  const { selectedCompanyId } = useAuth2();
  const data = useMemo(() => users, [users]);
  const columns = useMemo(() => usersColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const [isLoading, setLoadingStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [lista, setLista] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<SelectedCompanyType>({}); 

  const {
    activeOnly,
    state,
    searchTerm,
    entryDateFrom,
    entryDateTo,
    updateUser,
    setUpdateUser,
    setUserData,
    openEditUserModal,
    enterprices,
    setEnterprices,
  } = useContext(PartnerContext);

  const navigate = useNavigate();

  useEffect(() => {
    setLoadingStatus(true);
    fetchConToken('empresa/getAll')
      .then((res) => res.json())
      .then((data) => {
        setEnterprices(data.data);
      });

    refetchData({
      activeOnly: activeOnly,
      sheetStates: state,
      searchQuery: searchTerm,
      entryDateFrom: entryDateFrom,
      entryDateTo: entryDateTo,
    });
  }, []);

  useEffect(() => {
    setLoadingStatus(true);
    refetchData({
      activeOnly: activeOnly,
      sheetStates: state,
      searchQuery: searchTerm,
    });
  }, [searchTerm]);

  useEffect(() => {
    if (updateUser) {
      setUpdateUser(false);
      setLoadingStatus(true);
      refetchData({
        activeOnly: activeOnly,
        sheetStates: state,
        searchQuery: searchTerm,
      });
    }
  }, [updateUser]);

  useEffect(() => {
    setLoadingStatus(true);
    refetchData({
      activeOnly: activeOnly,
      sheetStates: state,
      searchQuery: searchTerm,
      entryDateFrom: entryDateFrom,
      entryDateTo: entryDateTo,
    });
  }, [activeOnly, state, entryDateFrom, entryDateTo, currentPage]);

  const refetchData = (options: any) => {
    setLoadingStatus(true);
    const updatedOptions = { ...options, rolUsuarios: 'ADMINISTRADOR' };
    getAllUsersPag(currentPage, 10, updatedOptions)
      .then((response) => {
        if (response.success) {
          setLoadingStatus(false);
          const auxTotalPage = response.length ? Math.ceil(response.length / 10) : totalPages;
          setTotalPages(auxTotalPage);
          setLista(response.data);

          const initialSelectedCompany: SelectedCompanyType = {};
          response.data.forEach((item: any) => {
            if (item.empresa && item.empresa.length > 0) {
              initialSelectedCompany[item._id] = item.empresa[0];
            }
          });
          setSelectedCompany(initialSelectedCompany);
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleDelete = (id: string) => {
    showDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        deleteUser(id)
          .then((response) => {
            if (response.success) {
              showMessage('success', '¡Buen trabajo!', response.message);
            } else {
              showMessage('error', 'Hubo un error', response.error);
            }
            refetchData({
              activeOnly: activeOnly,
              searchQuery: searchTerm,
            });
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message));
      }
    });
  };

  const handleReactivation = (id: string) => {
    showReactivationMessage().then((result) => {
      if (result.isConfirmed) {
        activarUser(id)
          .then((response) => {
            if (response.success) {
              showMessage('success', '¡Buen trabajo!', response.message);
            } else {
              showMessage('error', 'Hubo un error', response.error);
            }
            refetchData({
              activeOnly: activeOnly,
              searchQuery: searchTerm,
            });
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message));
      }
    });
  };

  const handleActualizar = (userData: any) => {
    setUserData(userData);
    openEditUserModal();
  };

  const handleCompanyChange = (userId: string, companyId: string) => {
    setSelectedCompany((prevState) => ({
      ...prevState,
      [userId]: companyId,
    }));
  };

  const getEnterpricesName = (ids: string[]): { nombre: string; id: string }[] => {
    return enterprices.filter((enterprice) => ids.includes(enterprice._id)).map((e) => ({ nombre: e.nombre, id: e._id }));
  };

  const getUnitsByCompany = (units: any[], companyId: string) => {
    if (!units) {
      return [];
    }
    return units.filter((unit) => unit.codEmpresa === companyId);
  };

  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = [];

    if (currentPage > 1) {
      links.push({ page: currentPage - 1, label: 'Anterior' });
    }

    for (let i = 1; i <= totalPages; i++) {
      links.push({ page: i, label: String(i) });
    }

    if (currentPage < totalPages) {
      links.push({ page: currentPage + 1, label: 'Siguiente' });
    }

    return links;
  };

  const showInComboBox = (rols: string[]) => {
    return rols.length > 1;
  };

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th>DNI</th>
                <th className='text-start'>Nombres</th>
                <th className='text-start'>Apellidos</th>
                <th className='text-start'>Correo</th>
                <th className='text-start'>Empresa (s)</th>
                <th className='text-start'>Unidades</th>
                <th className='text-end'>Opciones</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {lista.length > 0 ? (
                lista.map((item: any) => (
                  <Fragment key={item._id}>
                    <tr>
                      <td>{item.dni}</td>
                      <td className='text-start min-w-100px'>{item.nombres ? item.nombres : ''}</td>
                      <td className='text-start min-w-100px'>
                        {item.apellido_paterno ? item.apellido_paterno : ''}{' '}
                        {item.apellido_materno ? item.apellido_materno : ''}
                      </td>
                      <td className='text-start min-w-100px'>{item.email}</td>
                      <td className='text-start min-w-200px'>
                        {showInComboBox(item.empresa) ? (
                          <Form.Select value={selectedCompany[item._id] || item.empresa[0]} onChange={(e) => handleCompanyChange(item._id, e.target.value)}>
                            {getEnterpricesName(item.empresa).map((e) => (
                              <option key={e.id} value={e.id}>
                                {e.nombre}
                              </option>
                            ))}
                          </Form.Select>
                        ) : (
                          getEnterpricesName(item.empresa)[0].nombre
                        )}
                      </td>
                      <td className='text-start min-w-200px'>
                        {getUnitsByCompany(item.unidad, selectedCompany[item._id] || item.empresa[0]).length > 0 ? (
                          <Form.Select>
                            {getUnitsByCompany(item.unidad, selectedCompany[item._id] || item.empresa[0]).map((unit: any) => (
                              <option key={unit._id} value={unit.codUnid}>
                                {unit.DesUnid}
                              </option>
                            ))}
                          </Form.Select>
                        ) : (
                         "Sin unidades"
                        )}
                      </td>
                      <td className='text-end d-flex align-self-end justify-content-end'>
                        <Stack direction='horizontal' gap={1}>
                          <a
                            href='#up'
                            className='btn btn-icon btn-bg-light btn-light-info btn-sm'
                            title='Editar'
                            onClick={() => handleActualizar(item)}
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </a>
                          {item.estado ? (
                            <a
                              href='#del'
                              className='btn btn-icon btn-bg-light btn-light-danger btn-sm'
                              title='ver detalle'
                              onClick={() => handleDelete(item._id)}
                            >
                              <KTIcon iconName='cross' className='fs-3' />
                            </a>
                          ) : (
                            <a
                              href='#rea'
                              className='btn btn-icon btn-bg-light btn-light-success btn-sm'
                              title='ver detalle'
                              onClick={() => handleReactivation(item._id)}
                            >
                              <KTIcon iconName='check' className='fs-3' />
                            </a>
                          )}
                        </Stack>
                      </td>
                    </tr>
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  );
};

export { AsignacionUnidadTable };

