import {useContext, useEffect, useState} from 'react'
import {KTCard, KTIcon} from '../../../../../../_metronic/helpers'
import {Form, Row, Col, Button, Container, Stack} from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {PartnerContext} from '../../PartnerContexts'
import { Campos, DataTabla, Ficha, ProcesoFichas, Seccion, Tabla, tablaData } from '../../../../documentacion/MiFicha/FIchaInterface'
import { DraggableSectionList } from '../DraggableSectionList'
import { getRegistroFichaOne } from '../../../../../services/registroFicha'

const ObservacionModalForm = () => {
  const {idRegistroFicha,procesoFichaData, setProcesoFichaData} = useContext(PartnerContext)
  
  const [formLoading, setFormLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false);
  

  useEffect(() => {
    if(idRegistroFicha !== ""){      
      refetchData()
    }
    //handleGetSheet(id)
  },[])
  const refetchData = () => {
    setFormLoading(true)
    getRegistroFichaOne(idRegistroFicha)
      .then((response) => {
        if (response.success) {
          setFormLoading(false)
          
            const dataWithValuesAndFiles:ProcesoFichas[] = response?.data[0]?.procesoficha.map((procesoFicha:ProcesoFichas, item:any) => ({
              ...procesoFicha,
              fichas:procesoFicha.fichas.map((ficha:Ficha) => ({ 
                ...ficha,
                seccion:ficha.seccion.map((seccion:Seccion) => ({
                  ...seccion,
                  campos: seccion.campos.map((campo:Campos) => ({
                    ...campo,
                    values: campo.values?campo.values:'',
                    valuesFiles: campo.valuesFiles?campo.valuesFiles:[],
                    selectOpion:campo.selectOpion?campo.selectOpion:null,
                    hasError: campo.hasError?campo.hasError:false,
                    dependiente: campo.dependiente?campo.dependiente:false,
                    campoDependiente: campo.campoDependiente?campo.campoDependiente:"",
                    respuestaCampoDependiente: campo.respuestaCampoDependiente?campo.respuestaCampoDependiente:"",
                    mostrar: campo.mostrar?campo.mostrar:campo.dependiente?false:true,
                    tablaData: campo.tablaData.map((tablaData:tablaData) => ({
                      ...tablaData,
                      option: tablaData.value.map((val:any) => ({
                        value: val.id?val.id:'',
                        label: val.name?val.name:''
                      }))
                    }))
                  })),
                  
                  secciones:seccion.secciones?seccion.secciones.map((tabla:Tabla) => ({
                    ...tabla,
                    data: tabla.data.map((dattabla:DataTabla) => ({
                      ...dattabla,
                      values: dattabla.values?dattabla.values:'',
                      valuesFiles: dattabla.valuesFiles?dattabla.valuesFiles:[],
                      selectOpion:dattabla.selectOpion?dattabla.selectOpion:null,
                      hasError: dattabla.hasError?dattabla.hasError:false,
                      tablaData: dattabla.tablaData? dattabla.tablaData.map((dat:tablaData) => ({
                        ...dat,
                        option: dat.value.map((val:any) => ({
                          value: val.id?val.id:'',
                          label: val.name?val.name:''
                        }))
                      })) : []
                    }))
                  })):[],


                })),
              }))
              
            }));          
            setProcesoFichaData(dataWithValuesAndFiles)
          
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const validateForm = (e: any) => {
    const form = e.currentTarget;
    /* if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }else{
      handleSubmit(e)
    } */

    setValidated(true)
  }

  const handleChange = (e: any) => {
    //setProcesoFichaData({...sheetData})
  }


  return (
    <>
      <KTCard>
        {formLoading ? (
          <div className='pt-12 pb-6'>
            <Container>
              <Row className='mb-3'>
                <Col sm>
                  <Stack gap={2}>
                    <Skeleton height={20} width={52} duration={1} style={{borderRadius: '5px'}} />
                    <Skeleton height={44} duration={1} style={{borderRadius: '10px'}} />
                  </Stack>
                </Col>
                <Col sm>
                  <Stack gap={2}>
                    <Skeleton height={20} width={52} duration={1} style={{borderRadius: '5px'}} />
                    <Skeleton height={44} duration={1} style={{borderRadius: '10px'}} />
                  </Stack>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col sm>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-end'>
                      <Skeleton
                        height={20}
                        width={139}
                        duration={1}
                        style={{borderRadius: '5px'}}
                      />
                    </div>
                    <Skeleton height={44} width={120} duration={1} style={{borderRadius: '10px'}} />
                  </div>
                </Col>
              </Row>

              <Row className='mb-6'>
                <Col sm>
                  <Skeleton height={195} duration={1} style={{borderRadius: '10px'}} />
                </Col>
              </Row>

              <Row>
                <div className='d-flex justify-content-end'>
                  <Skeleton
                    height={44}
                    width={102}
                    duration={1}
                    className='mx-3'
                    style={{borderRadius: '10px'}}
                  />
                  <Skeleton height={44} width={96} duration={1} style={{borderRadius: '10px'}} />
                </div>
              </Row>
            </Container>
          </div>
        ) : (
          <>
            <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
            {procesoFichaData.map((procesoficha, key) => ( 
                <li className="nav-item">
                <a
                  className={`nav-link ${key===0?"active":""}`}
                  data-bs-toggle="tab"
                  href={`#kt_tab_pane_${key}`}
                >
                  {procesoficha.fichas[0].nombre}
                </a>
              </li>
            ))}
            </ul>
            <div className="tab-content" id="myTabContent">
              {procesoFichaData.map((procesoficha, key) => ( 
                <div
                  className={`tab-pane fade ${key===0?"show active":""}`}
                  id={`kt_tab_pane_${key}`}
                  role="tabpanel"
                >
                  <Form className='pt-12 pb-6' validated={validated} onSubmit={validateForm} noValidate>
                    <Container>
                      <Row className='mb-3'>
                        <Col sm>
                          <Form.Group controlId='formNombre'>
                            <Form.Label><b>Nombre Ficha</b></Form.Label><br></br>
                            <Form.Label>{procesoficha.fichas?.[0]?.nombre}</Form.Label>
                          </Form.Group>
                        </Col>

                        <Col sm>
                          <Form.Group controlId='formDescripcion'>
                            <Form.Label><b>Descripción Ficha</b></Form.Label>
                            <Form.Label>{procesoficha.fichas?.[0]?.descripcion}</Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className='mb-3'>
                        <Col sm>
                          <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-end'>
                              <Form.Label><b>Listado de Secciones</b></Form.Label>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row className='mb-6'>
                        <Col sm>
                          {/* <DraggableSectionList ind={key} /> */}
                        </Col>
                      </Row>

                      <Row>
                        <div className='d-flex justify-content-end'>
                          <Button
                            variant='light'
                            className='mx-3'
                            type='reset'
                            onClick={() => {
                              
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button variant='primary' type='submit'>
                            {!loading && 'Guardar'}
                            {loading && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                Guardando...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </Button>
                        </div>
                      </Row>
                    </Container>
                  </Form>
                </div>
              ))}
            </div>
            
          </>
          
        )}
      </KTCard>
    </>
  )
}

export {ObservacionModalForm}
