import { fetchConToken } from "../helpers/fetch"

const getAllExternalsPag = async (currentPage:any,numberData:any,options: any) => {
  const queryString = new URLSearchParams(options).toString();

  const resp = await fetchConToken(
    `external/${currentPage}/${numberData}?${queryString}`,
    {},
    'GET'
  );

  const body = await resp.json();

  return body;
};

const crearExternalData = async (data: any) => {
  const resp = await fetchConToken(
      `external/add`,
      data,
      'POST'
  );
  const body = await resp.json();

  return body;
}

const updateExternalData = async (id: string, updatedData: any) => {
  const resp = await fetchConToken(
      `external/${id}`,
      updatedData,
      'PUT'
  );
  const body = await resp.json();

  return body;
}

const getExternals = async (options: any) => {
  const queryString = new URLSearchParams(options).toString();
  const resp = await fetchConToken(
      `external?${queryString}`,
      {},
      'GET'
  );
  const body = await resp.json();

  return body;
}

const getExternalById = async (id:string) => {
  const resp = await fetchConToken(
      `external/get/findById/${id}`,
      {},
      'GET'
  );
  const body = await resp.json();

  return body;
}

const deleteExternal = async (id:string) => {
  const resp = await fetchConToken(`external/${id}`, {}, 'DELETE')

  const body = await resp.json()
  return body
}

const activarExternal = async (id:string) => {
  const resp = await fetchConToken(`external/activar/${id}`, {}, 'PUT')

  const body = await resp.json()
  return body
}
  
export {
    getAllExternalsPag,
    deleteExternal,
    activarExternal,
    crearExternalData,
    updateExternalData,
    getExternals,
    getExternalById
};