import React, {useContext, useEffect, useState} from 'react'
import {ListGroup, Badge, Row, Col, Form, Button} from 'react-bootstrap'
// import { ListTags } from '../documentInterface';
import { showInfoCopy, showMessage } from '../../../../../shared/Alerts';
import { Field, Section, Sheet } from '../../../fichas/Sheet'
import { ListFieldsMigracion } from '../../../documento/documentInterface';
import * as excelJs from 'exceljs';
import { PartnerContext } from '../../PartnerContexts';
import { number, string } from 'yup';
import { actualizarCampoCheckExcel, actualizarCampoCheckIntegracion } from '../../../../../services/campos';
import { group } from 'console';
import { formatDateToDMY } from '../../../../../helpers/dateFunctions';
import { useGlobalOverlayLoading } from '../../../../../shared/hooks/useGlobalOverlayLoading';
import { getUnidadesEmpresas } from '../../../../../services/companies';
import { useAuth2 } from '../../../../authv2/Auth2Provider';
import { PdfLoadingSpinner } from '../../../../generales_component/PdfLoadingSpinner';
import '../../style.css'

interface MyProps {
  List: ListFieldsMigracion[];
}

const TagsListGroup: React.FC<MyProps> = ({List}) => {
  const {selectedCompanyId, userData} = useAuth2()
  const [searchQuery, setSearchQuery] = useState('')
  const [inputList, setInputList] = useState(List)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()

  const {fichaData, idficha, setIdFicha, setSheetId, sheet, loadingFichaContent, setEdit} = useContext(PartnerContext)

  useEffect(()=>{
    console.log("List")
    console.log(List)
    setInputList(List)
  },[List])

  
  useEffect(()=>{
    changeDefault()
  },[inputList])
  
  const changeDefault = () => {
    const idSelected :string[] = []
    inputList.map(group => {
      group.fields.map(field => {
        if(field.check){
          idSelected.push(field.identifier)
        }
      })
    })
    
    setSelectedItems(idSelected)
  };
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleToggle = (id: string) => {
    const currentIndex = selectedItems.indexOf(id)
    const newSelected = [...selectedItems]
    if (currentIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelectedItems(newSelected)
  }

  const handleChangeAll = (event:any) => {
    if(event.target.checked){
      const arrSelect : string[] = []
      inputList.map(group => {
        group.fields.map(field => {
          arrSelect.push(field.identifier)
        })
      })
      setSelectedItems(arrSelect)
    }else{
      setSelectedItems([])
    }
    // console.log(event.target.checked)
  };
  
  const handleUpdateCampos = () => {
    setGlobalOverlayLoading(true)
    const notSelectItems:string[] = []
    sheet.sections?.map((section:Section)=>{
      if(!section.dynamic){
        section.fields?.map((field:Field)=>{
          let entro = false
          selectedItems.map(item => {
            if(String(field.id) === String(item)){
              entro = true
            }
          })
          if(!entro){
            notSelectItems.push(field.id)
          }
        })
      }
    })

    actualizarCampoCheckIntegracion({Selected:selectedItems,NotSelected:notSelectItems})
    .then((response) => {
      if (response.success) {
        setGlobalOverlayLoading(false)
        showMessage("success","Actualizacion Correctamente",response.message)
        setEdit(true)
      }else{
        setGlobalOverlayLoading(false)
        showMessage("error","Hubo un error",response.message)
      }
    })
    .catch((error) => {
      setGlobalOverlayLoading(false)
        console.log(error)
    })
  }

  function cleanName(name: string): string {
    // Reemplaza los caracteres especiales con '-'
    let cleanName = name.replace(/[^a-zA-Z0-9 áéíóúÁÉÍÓÚ]/g, '-')
    return cleanName
  }


  const getExcelLetter = (number:number) => {
    let result = '';
    while (number > 0) {
      const remainder = (number - 1) % 26;
      result = String.fromCharCode(65 + remainder) + result;
      number = Math.floor((number - 1) / 26);
    }
    return result;
  };

  const getRandomColorRGB = () =>{
    const r = 0; // Genera un valor aleatorio para el componente rojo (entre 0 y 255).
    const g = 0; // Genera un valor aleatorio para el componente verde (entre 0 y 255).
    const b = Math.floor(Math.random() * 256); // Genera un valor aleatorio para el componente azul (entre 0 y 255).
    return `${r}${g}${b})`; // Retorna el color en formato RGB.
  }

  const handleInputChangeFichas = (event: any) => {
    setIdFicha(event.target.value)
    setSheetId(event.target.value)
  }
  // Set a maximum height for the ListGroup container
  const maxListHeight = '300px'

  return (
    <>
      <Row>
        <Col sm>
          <Form.Group>
            <Form.Control
              type='text'
              placeholder='Buscar...'
              value={searchQuery}
              onChange={handleSearchChange}
              className='my-2 form-control-sm'
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <span className='fw-bold'>Ficha:</span>
          <select
            className={'form-select form-select-solid}'}
            aria-label='Select example'
            value={idficha}
            name='idfichas'
            onChange={(event) => handleInputChangeFichas(event)}
          >
            {fichaData.map((x: any) => (
              <option value={x.value} key={x.value}>
                {' '}
                {String(x.label)}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex align-self-end justify-content-end mt-2' sm>
          <Form.Check
            type='switch'
            id={`checkbox-all`}
            label={<span style={{color: 'black'}}>Seleccionar Todo</span>}
            onChange={handleChangeAll}
            className='hide-validation custom-switch'
          />
        </Col>
      </Row>
      <div style={{maxHeight: maxListHeight, overflowY: 'scroll'}}>
        {loadingFichaContent ? (
          <PdfLoadingSpinner />
        ) : (
          inputList.map((group) => {
            const filteredFields = group.fields.filter((item) =>
              item.label.toLowerCase().includes(searchQuery.toLowerCase())
            )

            if (filteredFields.length === 0) {
              return null
            }

            return (
              <>
                <h4 className='my-3 ms-2 fw-bold'>{group.label}</h4>
                <ListGroup style={{overflowY: 'auto'}}>
                  {filteredFields.map((item) => (
                    <div key={item.identifier}>
                      <ListGroup.Item key={item.identifier} className='d-flex'>
                        <Form.Check
                          type='checkbox'
                          id={`checkbox-dynamic-list-${item.identifier}`}
                          label={item.label}
                          checked={selectedItems.indexOf(item.identifier) !== -1}
                          onChange={(e) => handleToggle(item.identifier)}
                          className='hide-validation'
                        />
                        {item.obligatorio ? (
                          <span className='mx-3' style={{color: '#FF0000'}}>
                            *
                          </span>
                        ) : (
                          ''
                        )}
                      </ListGroup.Item>
                    </div>
                  ))}
                </ListGroup>
              </>
            )
          })
        )}
      </div>
      <Form.Group>
        {
          inputList.length>0 && (            
            <div className='d-flex justify-content-end'>
              <Button variant='primary' type='button' className='btn-sm' onClick={() => handleUpdateCampos()}>
                {!loading && 'Actualizar'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Actualizando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </Button>
            </div>
          )
        }
      </Form.Group>
    </>
  )
}

export default TagsListGroup
