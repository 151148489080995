import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import {addMonths} from '../generales_functions/dates'
import {CustomInputDatePicker} from './CustomInputDatePicker'
import es from 'date-fns/locale/es'

registerLocale('es', es)

interface Props {
  value: [Date | null, Date | null]
  onChange: (dates: [Date | null, Date | null]) => void
  monthsGap?: number
}

export const DatePickerBusiness: React.FC<Props> = ({value, onChange, monthsGap = 3}) => {
  return (
    <DatePicker
      startDate={value[0]}
      endDate={value[1]}
      onChange={(dates) => onChange(dates)}
      maxDate={addMonths(value[0] != null ? (value as Date[])[0] : new Date(Date.now()), monthsGap)}
      selectsRange
      locale='es'
      dateFormat='dd/MM/yyyy'
      isClearable
      customInput={<CustomInputDatePicker className='react-daterange-picker' />}
      placeholderText='Seleccione un rango de fechas'
      showMonthDropdown
      showYearDropdown
    />
  )
}
