import React, {useCallback, useEffect, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone';
import './styles.css';
import { KTSVG } from '../../../../../_metronic/helpers';
import DocViewer, { DocViewerRenderers,  } from "@cyntler/react-doc-viewer";
import ModalComponent from './modalPreview';
import { downloadFileS3 } from '../../../../services/files';
import { showMessage } from '../../../../shared/Alerts';

interface DropzoneExampleProps {
    onFilesSelected: (files: File[], index: number,infoImagen: {url:string,name:string,size:string}[]) => void;
    idx: string;
    titulo: string;
    filesImagen: File[] | [];
    infoImagen: {url:string,name:string,size:string,base64?:string}[] | [];
    disabled?: boolean;
}

const DropzoneImagen : React.FC<DropzoneExampleProps> = ({ onFilesSelected,idx,titulo,filesImagen,infoImagen,disabled = false }) =>{
    const [filesBackUp, setFilesBackUp] = useState<{url:string,name:string,size:string,base64?:string}[]|[]>(infoImagen);    
    const [files, setFiles] = useState<File[]>(filesImagen);
    const [entro, setEntro] = useState<boolean>(false);

    useEffect(() => {
        ActualizarImagen()
    }, [infoImagen]);
    
    useEffect(() => {
        ActualizarImagen()
    }, []);

    /* useEffect(() => {
        if(entro) ActualizarImagen()
    }, [entro]); */

    const ActualizarImagen = async () => {
        for await (const img of filesBackUp) {
            img.base64 = ""
            const partes = img.url.split('.');
            const extension = partes[partes.length - 1];
            
            downloadFileS3(img.url, extension)
            .then((response) => {
                if (response.success) {
                img.base64 = response.data;
                } 
            })
            .catch((error) => {
                console.log(error)
                showMessage('error', 'Hubo un error', error.message)
            })
        
        }
        setFilesBackUp(filesBackUp)
        setEntro(true)
    }
    

    

    const onDrop = (acceptedFiles: File[]) => {
        // Update the state with the accepted files
        const renamedFiles = Array.from(acceptedFiles).map((file, index) => {
            const partes = file.name.split('.');
            const extension = partes[partes.length - 1]; 
            
            const newName = index === 0?`${titulo}.${extension}`:`${titulo}_${index}.${extension}`
        
            // Creas un nuevo objeto File con el nombre modificado
            return new File([file], newName, { type: file.type });
        });

        setFiles([...files, ...renamedFiles]);
        onFilesSelected([...files, ...renamedFiles],0,filesBackUp);
    };

    const removeFile = (file: File) => {
        setFiles(files.filter((f) => f !== file));
        onFilesSelected(files.filter((f) => f !== file),0,filesBackUp);
    };

    const removeFileBackUp = (fileBackup:{name: string; size: string;}) => {
        setFilesBackUp(filesBackUp.filter((f) => f !== fileBackup));
        onFilesSelected(files,0,filesBackUp.filter((f) => f !== fileBackup));
    };
    
    const descargarArchivos = (archivo:File) => {
        const url = window.URL.createObjectURL(archivo);
        const link = document.createElement('a');
        link.href = url;
        link.download = archivo.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };
    
    
    const descargarArchivosS3 = (keyname:string) => {
        const partes = keyname.split('.');
        const extension = partes[partes.length - 1];
        
        const partesKey = keyname.split('/');
        const filename = partesKey[partesKey.length - 1]; 
        downloadFileS3(keyname, extension)
        .then((response) => {
            if (response.success) {
            const link = document.createElement('a');
            link.href = response.data;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            showMessage('success', '¡Buen trabajo!', response.message)
            } else {
            console.log(response)
            showMessage('error', 'Hubo un error', response.error)
            }
        })
        .catch((error) => {
            console.log(error)
            showMessage('error', 'Hubo un error', error.message)
        })
    };

    
    const seeFile = (keyname:string) => {
        const partes = keyname.split('.');
        const extension = partes[partes.length - 1];
        let base64 = ""
        downloadFileS3(keyname, extension)
        .then((response) => {
            if (response.success) {
                base64 =  response.data;
            }
            
        })
        .catch((error) => {
            console.log(error)
            showMessage('error', 'Hubo un error', error.message)
        })


        return base64;

    };

    /* const [fileUrl, setFileUrl] = useState('');
    const handlePreviewFile = (file: File) => {
        const blob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(blob);
        setFileUrl(url);
        
    }; */

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
        onDrop,
        accept: {
            "image/*": [".png", ".gif", ".jpeg", ".jpg"],
        }
    });

    return (
        <div>
            {
                disabled? 
                (<></>) : 
                <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                <input {...getInputProps()} />
                {isDragActive ? <p>Arrastra y suelta las imagenes aquí...</p> : <p>Arrastra las imagenes aquí o haz clic para seleccionar</p>}
            
            </div>
            }
            <div className="dropzone-preview-container">
                {filesBackUp.map((file) => (
                    file.url?                    
                    <div key={file.name} className="dropzone-preview">
                        <>
                            <img src={file.base64} alt={file.name} />
                            {disabled? (<></>) : <span className="badge badge-square badge-danger remove-btn" onClick={() => removeFileBackUp(file)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-1x"
                                />
                            </span>}                            
                            <button className="btn btn-icon btn-light pulse pulse-info me-10 mb-10 preview-btn btn-sm" 
                                onClick={() => descargarArchivosS3(file.url)}
                                /* onClick={() => handlePreviewFile(file)}
                                data-bs-toggle="modal" 
                                data-bs-target={`#kt_modal_1`+idx} */ >
                                <KTSVG
                                    path="/media/icons/duotune/general/gen004.svg"
                                    className="svg-icon svg-icon-1"
                                />
                                <span className="pulse-ring"></span>
                            </button>

                            {/* <a className="btn btn-icon btn-light pulse pulse-info me-10 mb-10 preview-btn btn-sm" 
                                href={file.url} download >
                                <KTSVG
                                    path="/media/icons/duotune/general/gen005.svg"
                                    className="svg-icon svg-icon-1"
                                />
                                <span className="pulse-ring"></span>
                            </a> */}
                        </>
                    </div>
                        
                    :""
                
                ))}

                {files.map((file) => (
                <div key={file.name} className="dropzone-preview">
                    {file.type.startsWith('image/') ? (
                    <>
                        <img src={URL.createObjectURL(file)} alt={file.name} />
                        <span className="badge badge-square badge-danger remove-btn" onClick={() => removeFile(file)}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-1x"
                            />
                        </span>
                        <button className="btn btn-icon btn-light pulse pulse-info me-10 mb-10 preview-btn btn-sm" 
                            onClick={() => descargarArchivos(file)}
                            /* onClick={() => handlePreviewFile(file)}
                            data-bs-toggle="modal" 
                            data-bs-target={`#kt_modal_1`+idx} */ >
                            <KTSVG
                                path="/media/icons/duotune/general/gen004.svg"
                                className="svg-icon svg-icon-1"
                            />
                            <span className="pulse-ring"></span>
                        </button>
                    </>
                    ) : (
                    <div className="file-info">
                        <p>{file.name}</p>
                        <p>Peso: {(file.size / 1024).toFixed(2)} KB</p>
                        <span className="badge badge-square badge-danger remove-btn" onClick={() => removeFile(file)}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-1x"
                            />
                        </span>
                        <button className="btn btn-icon btn-light pulse pulse-info me-10 mb-10 preview-btn btn-sm" 
                            onClick={() => descargarArchivos(file)}
                            /* onClick={() => handlePreviewFile(file)}
                            data-bs-toggle="modal" 
                            data-bs-target={`#kt_modal_1`+idx} */ >
                            <KTSVG
                                path="/media/icons/duotune/general/gen004.svg"
                                className="svg-icon svg-icon-1"
                            />
                            <span className="pulse-ring"></span>
                        </button>
                    </div>
                    )}
                </div>
                ))}
            </div>
            
            {/* <ModalComponent id={`kt_modal_1`+idx} title="PreVisualizacion">
                <DocViewer
                    documents={[{ uri: fileUrl }]}
                    pluginRenderers={DocViewerRenderers}
                />
            </ModalComponent> */}

            {files.length > 0 && (
                <div className="remove-btn-container">
                    <button className="remove-all-btn" onClick={() => setFiles([])}>
                        Eliminar todas las imagen
                    </button>
                </div>
            )}
        </div>
    );

};

export default DropzoneImagen;
