import React from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {MiLegajoContextProvider} from './MiLegajoPageContext'
import {MiLegajoHeader} from './components/MiLegajoHeader'
import {MiLegajoTable} from './components/MiLegajoTable'

const legajoBreadcrumbs = [
  {
    title: 'Mi Legajo',
    path: '/mi-legajo',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MiLegajoPage: React.FC = () => {
  return (
    <MiLegajoContextProvider>
      <PageTitle breadcrumbs={legajoBreadcrumbs}>Documentos</PageTitle>
      <KTCard className='min-h-700px'>
        <MiLegajoHeader />
        <MiLegajoTable />
      </KTCard>
    </MiLegajoContextProvider>
  )
}

export {MiLegajoPage}
