import DocViewer, {DocViewerRenderers} from '@cyntler/react-doc-viewer'
import {useContext, useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {PdfLoadingSpinner} from '../../../generales_component/PdfLoadingSpinner'
import {PartnerContext} from '../PartnerContexts'
import {Doc} from '../enviodocumentInterface'

const ShowFileModalForm = () => {
  const {viewDocumento, loadingNewPdf} = useContext(PartnerContext)
  const [docs, setDocs] = useState<Doc[]>([])

  useEffect(() => {
    // if (viewDocumento[0]?.keyName === '') {
    setDocs(viewDocumento)
    console.log('viewDocumento')
    console.log(viewDocumento)
    // }
  }, [viewDocumento])

  useEffect(() => {
    if (viewDocumento[0].keyName) {
      if (viewDocumento[0].keyName === '') {
      } else {
        setDocs(viewDocumento)
      }
    } else {
      setDocs(viewDocumento)
    }
  }, [])

  return (
    <>
      <Container className='mt-5'>
        <Row>
          <Col sm={12} style={{position: 'relative'}}>
            {loadingNewPdf ? (
              <div>
                <PdfLoadingSpinner />
              </div>
            ) : (
              <DocViewer
                documents={docs}
                pluginRenderers={DocViewerRenderers}
                style={{height: 850}}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export {ShowFileModalForm}
