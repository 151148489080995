import { useContext, useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import ReactJson from 'react-json-view'
import { getRoles } from '../../../../services/users'
import { showMessage, showValidatePassword } from '../../../../shared/Alerts'
import { PartnerContext } from '../PartnerContexts'


const InfoModalForm = () => {
  const {setIsInfoModalOpen,setUpdateUser,infoData, setSearchTerm} = useContext(PartnerContext)


  const [loading, setLoading] = useState(false)
  const [dataInfo, setDataInfo] = useState<any>(infoData)
  const [acceptExt, setAcceptExt] = useState('.docx'); 
  const [shouldClear, setShouldClear] = useState(false);
  const [cargandoFile, setCargandoFile] = useState(false);
  const [listRoles, setListRoles] = useState<any[]>()
  const [selected, setSelected] = useState<any[]>()
  const [listProcessValidationError, setListProcessValidationError] = useState(false)
  const [userRoleSelected, setUserRoleSelected] = useState<string[]>([]);

  useEffect(()=>{
    setDataInfo(dataInfo)
  },[dataInfo])

  useEffect(()=>{
    obtenerRoles()
  },[])

  const handleActualizarUser = () => {
    setLoading(true)
    const data = {
      rol: userRoleSelected
    }

    showValidatePassword().then(async (result) => {      
      if (result.isConfirmed) {

      }
    })
    .catch((error) => {
      console.log(error)
      setLoading(false)
    })
    
  }

  const obtenerRoles = () =>{
    getRoles()
        .then((response:any) => {
          if (response.success) {
            const rolesAll = response.data?.map((x: any) => ({ label: x.nombre_perfil, value: x.rol }))
            const select:any[] = rolesAll?.filter((x: any) => dataInfo.rol.includes(x.label))
            setSelected(select)
            setListRoles(rolesAll)
            setUserRoleSelected(select.map(s => s.value))
          } else {
            console.log(response)
            showMessage('error', 'Hubo un error', response.error)
          }
        })
        .catch((error:any) => {
          console.log(error)
        })
  }

  const limpiarCampos = ()=>{    
    setDataInfo({ _id:'',idproceso:'', name: '', file: '', filename: '', keyname: '', type:'PLANTILLA' })         
    setShouldClear(true);
  }


  const handleSelectChange = (e: any) => {
    setSelected(e)
    setUserRoleSelected(e.map((x: any) => x.value))
    processOwnersValidation(e.map((x: any) => x.value))
  }

  const processOwnersValidation = (owners: string[]) => {
    if (owners.length === 0) {
      setListProcessValidationError(true)
    } else {
      setListProcessValidationError(false)
    }
  }

  const showEnterpricesOptions = (
    rols: string[],
    rules: string[] = ['ADMINISTRADOR', 'MASTER']
  ): boolean => {
    return rols.some((rol) => rules.includes(rol))
  }

  return (
    <>
      <Form>
        <Container>
          <Row className='mb-3 pt-3 px-3'>
            <Form>
              <Col sm>
                <Row className='mb-3'>
                  <ReactJson src={dataInfo} />
                </Row>
                {/* <Row className='mb-3'>
                  <pre>{JSON.stringify(dataInfo, null, 2)}</pre>
                </Row> */}
              </Col>
            </Form>
          </Row>
        </Container>
      </Form>
    </>
  )
}

export { InfoModalForm }

