import { fetchConToken, fetchSinTokenStream } from "../helpers/fetch";

const url = `file/`
const file = async () => {
  const resp = await fetchConToken(
    `${url}`
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const getFileId = async (id) => {
  const resp = await fetchConToken(
    `${url}id/${id}`
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const getAllFilesCPD = async (idproceso,codUnid) => {
  console.log(`${url}cpd/${idproceso}/${codUnid}`)
  const resp = await fetchConToken(
      `${url}cpd/${idproceso}/${codUnid}`,
      {},
      'GET'
  );
  const body = await resp.json();

  return body;
}

const getFilexIdproceso = async (id, showUnid = false, codUnid) => {
  const resp = await fetchConToken(
    `${url}idproceso/${id}?showUnid=${showUnid}&codUnid=${codUnid}`
  )
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token) 
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const getSendDocumentFilexIdproceso = async (id, showUnid = false, codUnid) => {
  const resp = await fetchConToken(
    `${url}senddocument/idproceso/${id}?showUnid=${showUnid}&codUnid=${codUnid}`
  )
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token) 
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const getFilexIdprocesoxUnidad = async (id, showUnid = false, codUnid) => {
  const resp = await fetchConToken(
    `${url}idproceso/unidad/${id}?showUnid=${showUnid}&codUnid=${codUnid}`
  )
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const getFilexIdprocesoColaborador = async (id) => {
  const resp = await fetchConToken(
    `${url}colaborador/idproceso/${id}`
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const downloadFileS3 = async (keyname,extension) => {
  const resp = await fetchConToken(
    `${url}downloadS3`,
    {keyname:keyname,
      extension:extension},
    "POST"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};


const addFileProcess = async (data) => {
  const resp = await fetchConToken(
    `${url}add`,
    data,
    "POST"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const ConverDocx = async (data) => {
  const resp = await fetchSinTokenStream(
      `${url}convert_docx`,
      data,
      "POST"
    );
    const body = await resp.json();      
  
    if (body.success) {
      localStorage.setItem("token", body.token);
      localStorage.setItem("token-init-date", new Date().getTime());
    }
  
    return body;
};


const deleteFile = async (id) => {
  const resp = await fetchConToken(
    `${url}${id}`,
    {},
    "DELETE"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const getFileInPDF = async (keyname, extra = {}) => {
  const resp = await fetchConToken(
    `${url}getFile/PDF`,
    {keyname: keyname, ...extra},
    "POST"
  );

  const body = await resp.json();

  return body;
};


const getFileSigned = async (id,keyname) => {
  const resp = await fetchConToken(
    `${url}getFile/PDFSigned`,
    {keyname,id},
    "POST"
  );

  const body = await resp.json();

  return body;
};

const generatePDFWithFooter = async (documentSentId, keyname, userName) => {
  const resp = await fetchConToken(
    `${url}getFile/PDFWithFooter`,
    {
      documentSentId: documentSentId,
      keyname: keyname
    },
    "POST"
  );

  const body = await resp.json();

  return body;
};

export {
  file,
  getFileId,
  getFilexIdproceso,
  getFilexIdprocesoxUnidad,
  getFilexIdprocesoColaborador,
  getSendDocumentFilexIdproceso,
  addFileProcess,
  downloadFileS3,
  ConverDocx,
  deleteFile,
  getFileInPDF,
  getFileSigned,
  getAllFilesCPD,
  generatePDFWithFooter
};