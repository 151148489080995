import { useContext, useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { deleteFile, downloadFileS3, getFilexIdprocesoxUnidad } from '../../../../services/files'
import { showDeleteMessage, showMessage } from '../../../../shared/Alerts'
import { PdfLoadingSpinner } from '../../../generales_component/PdfLoadingSpinner'
import { PartnerContext } from '../PartnerContexts'
import { EditFileModal } from '../show-edit-file-modal/EditFileModal'


export const ListDocuments = ({ id, codUnid }) => {
  const { openEditFileModal, setFileData, updateFile, setUpdateFile, setDocuments, setDocumentsAux, documents } = useContext(PartnerContext)
  const [documento, setDocumento] = useState('')
  const [loading, setLoading] = useState(false)
  const [updated] = useState(false)

  useEffect(() => {
    refetchData()
  }, [])

  useEffect(() => {
    refetchData()
  }, [updated])

  useEffect(() => {
    setDocumento(documents)
  }, [documents])

  useEffect(() => {
    if (updateFile) {
      refetchData()
      setUpdateFile(false)
    }
  }, [updateFile])

  const refetchData = () => {
    setLoading(true)
    getFilexIdprocesoxUnidad(id, true, codUnid)
      .then((response) => {
        if (response.success) {
          setDocumentsAux(response?.data)
          setDocuments(response?.data)
        } else {
          console.log(response.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }


  const handleDownload = async (keyname, filename) => {
    const partes = filename.split('.')
    const extension = partes[partes.length - 1]

    downloadFileS3(keyname, extension)
      .then((response) => {
        if (response.success) {
          const link = document.createElement('a')
          link.href = response.data
          link.download = filename
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          console.log(response)
          showMessage('error', 'Hubo un error', response.error)
        }
      })
      .catch((error) => {
        console.log(error)
        showMessage('error', 'Hubo un error', error.message)
      })
  }

  const handleView = (id, keyname, filename) => {
    // if(extension==="pdf"){
    const url = `/configuracion/documentos/view/${id}`
    window.open(url, '_blank')
    /* }else{
      downloadFileS3(keyname,extension)
        .then((response) => {
            if (response.success) {
                const link = document.createElement('a');
                link.style.display = 'none'; 
                link.href = response.data;
                link.download = filename;
                document.body.appendChild(link);
                link.click();                
                document.body.removeChild(link);
                link.addEventListener('load', () => {
                  window.open(response.data, '_blank');
                });
            }

        })
        .catch((error) => {
            console.log(error)
        })
    } */
  }

  const handleActualizar = (file) => {
    setFileData({ ...file, type_document: file.type_document ? file.type_document : 'KIT DE INGRESO' })
    openEditFileModal()
  }

  const handleDelete = (id) => {
    showDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        deleteFile(id)
          .then((response) => {
            if (response.success) {
              refetchData()
              showMessage('success', '¡Buen trabajo!', response.message)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })


  }

  return (
    <>
      {documento && (
        <>
          {Object.keys(documento).map(key => (
            <div key={key} className='py-3'>
              <h3 className='text-dark fw-bold text-hover-primary my-2 fs-6'>
                {key}
              </h3>
              {documento[key].map((item, index) => (
                <section key={item._id} className='d-flex align-items-center justify-content-between mb-3'>
                  <div className='text-start d-flex align-items-center'>
                    {item.type === 'PLANTILLA' ?
                      <img
                        src={toAbsoluteUrl('/media/svg/files/doc.svg')}
                        className='h-20px mx-10'
                        alt='archivo word'
                      />
                      :
                      <img
                        src={toAbsoluteUrl('/media/svg/files/pdf.svg')}
                        className='h-20px mx-10'
                        alt='archivo pdf'
                      />
                    }
                    <p className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      {item.type} - {item.name}
                    </p>
                  </div>
                  <div className='text-end text-muted fw-semibold'>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <button
                        onClick={() => handleDownload(item.keyname, item.filename)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='cloud-download' className='fs-3' />
                      </button>
                      <button
                        onClick={() => handleView(item._id, item.keyname, item.filename)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='eye' className='fs-3' />
                      </button>
                      <button
                        onClick={() => handleActualizar(item)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </button>
                      <button
                        onClick={() => handleDelete(item._id)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </button>
                    </div>
                  </div>
                </section>
              ))}
            </div>
          ))}

          <EditFileModal />
        </>
      )}
      {
        loading && <PdfLoadingSpinner />
      }
    </>
  )
}
