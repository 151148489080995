
import format from 'date-fns/format';
export interface HistorialMessageModel {
    nameuser: string
    iduser: string
    text: string
    fecha: Date
}

const defaultMessages: Array<HistorialMessageModel> = [
    {
        nameuser: "Observación",
        iduser: '64b5c67e1809e0cf6116c9c2',
        text: 'Tu Proceso a sido Observado por favor revisarlo, fecha '+ format(new Date(), 'dd/MM/yyyy hh:mm a') ,
        fecha: new Date(),
    },
    {
        nameuser: "AprobadoConPendiente",
        iduser: '64b5c67e1809e0cf6116c9c2',
        text: 'Nos complace informarle de que su proceso ha sido aprobado con pendiente, fecha '+ format(new Date(), 'dd/MM/yyyy hh:mm a') ,
        fecha: new Date(),
    }
]


export { defaultMessages }