import { KTCard } from "../../../../_metronic/helpers"
import { PageTitle } from "../../../../_metronic/layout/core"
import { DocumentTrayContext } from "./DocumentTrayContexts"
import { DocumentTrayTabs } from "./main/DocumentTrayTabs"
import { DocumentTrayTableHeader } from "./main/DocumentTrayTableHeader"
import { useState } from "react"
import { PreviewDocumentModal } from "./preview-document-modal/PreviewDocumentModal"

const documentTrayBreadcrumbs = [
  {
    title: 'Documentos',
    path: '/documentos/bandeja',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DocumentTrayPage = () => {

  const [isPreviewDocumentModalOpen, setPreviewDocumentModalStatus] = useState(false)
  const [keyname, setKeyname] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [fromPreviewTab, setFromPreviewTab] = useState({
    showSignature: false,
    signCeo: false,
    idUserSign: '',
  })

  const openPreviewDocumentModal = () => {
    setPreviewDocumentModalStatus(true)
  }

  const closePreviewDocumentModal = () => {
    setPreviewDocumentModalStatus(false)
  }

  const documentTrayValue = {
    isPreviewDocumentModalOpen,
    openPreviewDocumentModal,
    closePreviewDocumentModal,
    keyname,
    setKeyname,
    searchTerm,
    setSearchTerm,
    fromPreviewTab,
    setFromPreviewTab
  }

  return (
    <>
      <DocumentTrayContext.Provider value={documentTrayValue}>
        <PageTitle breadcrumbs={documentTrayBreadcrumbs}>Bandeja de Documentos</PageTitle>
        <KTCard>
          <DocumentTrayTableHeader />
          <DocumentTrayTabs />
        </KTCard>
        <PreviewDocumentModal />
      </DocumentTrayContext.Provider>
    </>
  )
}

export { DocumentTrayPage }