import {useContext} from 'react'

import { EditFileModalFormWrapper } from './EditFileModalFormWrapper'
import { EditFileModalHeader } from './EditFileModalHeader'
import { PartnerContext } from '../PartnerContexts'

const EditFileModal = () => {

  const {isEditFileModalOpen} = useContext(PartnerContext)

  return (
    <>
      {isEditFileModalOpen && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered modal-lg'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <EditFileModalHeader />
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <EditFileModalFormWrapper />
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {EditFileModal}
