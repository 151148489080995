import { downloadFilePlantillaMatchS3 } from "../../services/documentos";
import { downloadFileS3 } from "../../services/files";
import { showMessage } from "../../shared/Alerts";

export const handleDownloadAsync = async (keyname:string,filename:string) => {
    const partes = filename.split('.')
    const extension = partes[partes.length - 1]

    try {
        const response = await downloadFileS3(keyname,extension)
        if (response.success) {
        const link = document.createElement('a')
        link.href = response.data
        link.download = filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        showMessage('success', '¡Buen trabajo!', response.message)
        } else {
        console.log(response)
        showMessage('error', 'Hubo un error', response.error)
        }
    } catch (error) {
        console.log(error)
        showMessage('error', 'Hubo un error', (error as Error).message ?? '')
    }
}

export const handleDownload = async (keyname:string,filename:string) => {    
    const partes = filename.split('.');
    const extension = partes[partes.length - 1];

    downloadFileS3(keyname,extension)
        .then((response) => {
            if (response.success) {
                const link = document.createElement('a');
                link.href = response.data;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                showMessage('success', '¡Buen trabajo!', response.message)
            } else {
                console.log(response)
                showMessage('error', 'Hubo un error', response.error)
            }
        })
        .catch((error) => {
            console.log(error)
            showMessage('error', 'Hubo un error', error.message)
        })
}

export const handleDownloadBase64 = async (data:string,filename:string) => {    
    const link = document.createElement('a');
    link.href = data;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

}

export const handleView = (id: any,keyname:string,filename:string) => {
    const partes = filename.split('.');
    const extension = partes[partes.length - 1];
    // if(extension==="pdf"){
        const url = `/configuracion/documentos/view/${id}`;
        window.open(url, '_blank');
    /* }else{
        downloadFileS3(keyname,extension)
        .then((response) => {
            if (response.success) {
                const link = document.createElement('a');
                link.style.display = 'none'; 
                link.href = response.data;
                link.download = filename;
                document.body.appendChild(link);
                link.click();                
                document.body.removeChild(link);
                link.addEventListener('load', () => {
                    window.open(response.data, '_blank');
                });
            }

        })
        .catch((error) => {
            console.log(error)
        })
    } */
}

export const handleS3Convert64 = async (keyname:string,filename:string) => {
    const partes = keyname.split('.');
    const extension = partes[partes.length - 1];

    try {
        const response = await downloadFileS3(keyname, extension);
        if (response.success) {
            return {
                uri:response.data,
                fileType:extension,
                fileName:filename
            }
        } else {
            throw new Error("Error en la respuesta de downloadFileS3");
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const handleS3PlantillaMatch = async (idUser:string,idFile:string,idProceso:string,keyname:string,filename:string) => {
    const partes = filename.split('.');
    const extension = partes[partes.length - 1];

    try {
        const data = {  
            idUser:idUser, 
            idFile:idFile,
            idProceso:idProceso,
            keyname:keyname,
            extension:extension
        }
        const response = await downloadFilePlantillaMatchS3(data);
        if (response.success) {
            return {
                uri:response.data,
                fileType:extension,
                fileName:filename
            }
        } else {
            throw new Error("Error en la respuesta de downloadFileS3");
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}