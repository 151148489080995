import { Switch } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { AiOutlineRight } from 'react-icons/ai'; // Importar el icono de flecha de react-icons
import { fetchMenuActionsByRole, fetchMenuDataWithActions, updateActionRole } from '../../../../services/menu';
import { PartnerContext } from '../PartnerContexts';

interface SubMenu {
  id_padre: string;
  id_hijo: string;
  titulo: string;
  url: string;
  fontIcon: string;
  order: number;
  acciones: string[];
  hasRole: boolean;
}

interface Menu {
  _id: string;  
  titulo: string;
  icono: string;
  order: number;
  hijos: SubMenu[];
  hasRole: boolean;
}

interface RoleAction {
  subMenuId: string;
  acciones: string[];
}

const ActionAsignacionForm = () => {
  const { role, setRole, reloadTable } = useContext(PartnerContext);
  const [menus, setMenus] = useState<Menu[]>([]);
  const [roleActions, setRoleActions] = useState<RoleAction[]>([]);
  const [visibleActions, setVisibleActions] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    fetchMenus();
  }, [role, reloadTable]);

  const fetchMenus = async () => {
    try {
      const menuResponse = await fetchMenuDataWithActions();
      const roleActionResponse = await fetchMenuActionsByRole(role);

      if (menuResponse.success && roleActionResponse.success) {
        setMenus(menuResponse.data);
        setRoleActions(roleActionResponse.data);
      }
    } catch (error) {
      console.error('Error fetching menus with actions:', error);
    }
  };

  const hasRole = (subMenuId: string, action: string): boolean => {
    const roleAction = roleActions.find((ra) => ra.subMenuId === subMenuId);
    return roleAction ? roleAction.acciones.includes(action) : false;
  };

  const handlePermissionToggle = async (menuId: string, subMenuId: string, actionTitle: string, isPermitted: boolean) => {
    try {
      setRoleActions((prevRoleActions) =>
        prevRoleActions.map((roleAction) =>
          roleAction.subMenuId === subMenuId
            ? {
                ...roleAction,
                acciones: isPermitted
                  ? [...roleAction.acciones, actionTitle]
                  : roleAction.acciones.filter((accion) => accion !== actionTitle),
              }
            : roleAction
        )
      );

      const payload = {
        role,
        subMenuId,
        actionTitle,
        isPermitted,
      };

      const response = await updateActionRole(payload);
      if (!response.success) {
        setRoleActions((prevRoleActions) =>
          prevRoleActions.map((roleAction) =>
            roleAction.subMenuId === subMenuId
              ? {
                  ...roleAction,
                  acciones: isPermitted
                    ? roleAction.acciones.filter((accion) => accion !== actionTitle)
                    : [...roleAction.acciones, actionTitle],
                }
              : roleAction
          )
        );
        console.error('Error updating action role:', response.message);
      }
    } catch (error) {
      console.error('Error updating action role:', error);
    }
  };

  const toggleActionsVisibility = (subMenuId: string) => {
    setVisibleActions((prev) => ({
      ...prev,
      [subMenuId]: !prev[subMenuId],
    }));
  };

  return (
    <Container>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Gestión de Permisos de Acciones</h3>
        </div>
        <div className="card-body">
          <Form.Group controlId="roleSelect" style={{ width: '200px', marginBottom: '20px' }}>
            <Form.Label>Seleccione el Rol</Form.Label>
            <Form.Control
              as="select"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              style={{ fontSize: '14px' }}
            >
              <option value="ADMIN">MASTER</option>
              <option value="SUPER">ADMINISTRADOR</option>
              <option value="POST">POST</option>
              <option value="INSPECTOR">INSPECTOR</option>
              <option value="CLIENTE">CLIENTE</option>
              <option value="APROBADOR">APROBADOR</option>
            </Form.Control>
          </Form.Group>
          <div style={{ border: '1px solid #ddd', borderRadius: '4px', padding: '10px' }}>
            {menus.map((menu) => (
              <div key={menu._id} style={{ marginBottom: '30px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px' }}>
                  <h5>{menu.titulo}</h5>
                </div>
                {menu.hijos &&
                  menu.hijos.length > 0 &&
                  menu.hijos.map((subMenu) => (
                    <div key={subMenu.id_hijo} style={{ marginLeft: '30px', marginBottom: '20px' }}>
                      <h6
                        style={{ marginBottom: '10px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                        onClick={() => toggleActionsVisibility(subMenu.id_hijo)}
                      >
                        {subMenu.acciones.length > 0 && (
                          <AiOutlineRight
                            style={{
                              marginRight: '5px',
                              transition: 'transform 0.3s',
                              transform: visibleActions[subMenu.id_hijo] ? 'rotate(90deg)' : 'rotate(0deg)',
                            }}
                          /> // Muestra la flecha si tiene acciones
                        )}
                        {subMenu.titulo}
                      </h6>
                      {visibleActions[subMenu.id_hijo] &&
                        subMenu.acciones.map((accion) => (
                          <div key={accion} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '30px' }}>
                            <span>{accion}</span>
                            <Switch
                              checked={hasRole(subMenu.id_hijo, accion)}
                              onChange={(e:any) => handlePermissionToggle(menu._id, subMenu.id_hijo, accion, e.target.checked)}
                              color="primary"
                            />
                          </div>
                        ))}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ActionAsignacionForm;
