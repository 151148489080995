import { useContext, useEffect, useMemo, useState } from 'react'
import { useTable } from 'react-table'
import { KTCardBody, KTIcon } from '../../../../../_metronic/helpers'
import Pagination from '../../../../shared/Pagination'
import { UsersListLoading } from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import { useQueryResponseData } from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import { usersColumns } from '../../../apps/user-management/users-list/table/columns/_columns'
/* import {ListFichas} from './ListFichas' */
import { Accordion } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from '../../../../services/menu'
import { getProcesosByCliente } from '../../../../services/procesos'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import { DataUser } from '../../../documentacion/MiFicha/DataUserInterface'
import { PartnerContext } from '../PartnerContexts'
import { Proceso } from '../documentInterface'
import { ListDocuments } from './ListDocuments'
import './table.css'

const PartnersTable = (props: any) => {
  const navigate = useNavigate()
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps} = useTable({
    columns,
    data,
  })

  const usuarioJSON = localStorage.getItem('userData')
  let usuario: DataUser | null = null

  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON)
  }

  const {
    searchUnidad,
    searchTerm,
    searchDocument,
    unidades,
    setDocuments,
    documentsAux,
    setProcesoData,
    openTagsModal,
    setProcessId,
    setUnidades,
  } = useContext(PartnerContext)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [isLoading, setLoadingStatus] = useState(false)
  const [documentoaux, setDocumentoaux] = useState(documentsAux)
  const [selecto, setSelecto] = useState('')
  const [procesosdata, setProcesosData] = useState<any[]>([])
  const [countDocs, setCountDocs] = useState<any[]>([])
  const [, setUpdated] = useState(false)
  const [, setEliminar] = useState('')
  const { selectedRole } = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])
  const location = useLocation()

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu = response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  useEffect(() => {
    setDocumentoaux(documentsAux)
  }, [documentsAux])

  useEffect(() => {
    setLoadingStatus(true)
    setTimeout(() => {
      refetchData({
        nombre: searchTerm,
        estado: 'A',
        responsables: usuario?.userId,
        unidad: searchUnidad.length === 0 ? '' : searchUnidad.join(','),
      })
    }, 1000)
  }, [currentPage, searchTerm, searchUnidad])

  useEffect(() => {
    const newDocument: {[type_document: string]: any[]} = documentoaux
    const newResultado: {[type_document: string]: any[]} = {}
    console.log('newDocument')
    console.log(newDocument)
    Object.keys(newDocument).forEach((key) => {
      newResultado[key] = []
      newDocument[key].forEach((item) => {
        if (
          item.name.toLowerCase().includes(searchDocument.toLowerCase()) ||
          item.type.toLowerCase().includes(searchDocument.toLowerCase())
        ) {
          newResultado[key].push(item)
        }
      })
    })
    console.log('newResultado')
    console.log(newResultado)
    /* const filteredFields:any[] = documentsAux.filter((item:any) =>
            item.name.toLowerCase().includes(searchDocument.toLowerCase()) || item.type.toLowerCase().includes(searchDocument.toLowerCase())
          ) */
    setDocuments(newResultado)
  }, [searchDocument])

  const refetchData = (options: any) => {
    setLoadingStatus(true)
    //options= searchTerm?options:{}
    getProcesosByCliente(currentPage, 10, options).then((response) => {
      if (response.success) {
        setLoadingStatus(false)
        const auxTotalPage = response.length ? Math.ceil(response.length / 10) : totalPages
        setTotalPages(auxTotalPage)
        setProcesosData(response.data)
        setUnidades(response.unidades)
        setCountDocs(response.docs)
        MenuComponent.reinitialization()
      } else {
        console.log(response.msg)
      }
    })
    setUpdated(false)
    setEliminar('')
  }

  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []
    // Generate "Previous" link if not on the first page
    if (currentPage > 1) {
      links.push({page: currentPage - 1, label: 'Anterior'})
    }

    // Generate numbered pages
    for (let i = 1; i <= totalPages; i++) {
      links.push({page: i, label: String(i)})
    }

    // Generate "Next" link if not on the last page
    if (currentPage < totalPages) {
      links.push({page: currentPage + 1, label: 'Siguiente'})
    }

    return links
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleSelecto = (opt: any) => {
    if (opt === selecto) {
      setSelecto('')
    } else {
      setSelecto(opt)
    }
  }

  const handleShowEtiquetas = (id: any) => {
    setProcessId(id)
    openTagsModal()
  }

  const handleNew = (data: Proceso, codUnid: string) => {
    setProcesoData(data)
    navigate(`/configuracion/documentos_por_cliente/edit/${data._id}/${codUnid}`)
  }

  const findCountDocs = (idproceso: string, codUnid: string): number => {
    return countDocs.find((doc) => doc.idproceso === idproceso && doc.codUnid === codUnid).count
  }

  return (
    <>
      <KTCardBody className='py-4'>
        {/* <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th></th>
                <th className='text-start'>Unidad</th>
                <th className='text-start'>Nombre del proceso</th>
                <th className='text-start'>Descripción del proceso</th>
                <th className='text-start'>N°/docs</th>
                <th className='text-end'>Accioness</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              <tr>
                <td colSpan={6}>
                  <Accordion defaultActiveKey='0' flush>
                    {procesosdata[0]?.unidad.map((u: any, i: number) => (
                      <Accordion.Item eventKey={i.toString()}>
                        <Accordion.Header>{u.DesUnid}</Accordion.Header>
                        <Accordion.Body>
                          <Accordion flush>
                            {procesosdata.map((item) => (
                              <Accordion.Item eventKey={item._id}>
                                <Accordion.Header
                                  onClick={() => handleSelecto(`${u.codUnid}/${item._id}`)}
                                >
                                  <div className='d-flex align-items-center justify-content-between w-100 px-2'>
                                    <div>
                                      <h3 className='font-weight-bold mb-0'>{item.nombre}</h3>
                                      <p className='mb-0 font-weight-bold'>
                                        Total docs: {findCountDocs(item._id, u.codUnid)}
                                      </p>
                                    </div>
                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                      <button
                                        onClick={() => handleShowEtiquetas(item._id)}
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      >
                                        <KTIcon
                                          iconName='text-align-justify-center'
                                          className='fs-3'
                                        />
                                      </button>
                                      <button
                                        onClick={() => handleNew(item, u.codUnid)}
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      >
                                        <KTIcon iconName='plus' className='fs-3' />
                                      </button>
                                    </div>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div>
                                    <p className='mb-0'>{item.descripcion}</p>
                                  </div>
                                  {selecto === `${u.codUnid}/${item._id}` && (
                                    <ListDocuments id={item._id} codUnid={selecto.split('/')[0]} />
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </td>
              </tr>
               {procesosdata.map((item: any, idx) => (
                <>
                  {item.unidad.map((u: any) => (
                    <>
                      <tr>
                        <td onClick={() => handleSelecto(`${u.codUnid}/${item._id}`)}>
                          <a href='# ' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {selecto === `${u.codUnid}/${item._id}` ? (
                              <i className='bi bi-arrow-up'></i>
                            ) : (
                              <i className='bi bi-arrow-right'></i>
                            )}
                          </a>
                        </td>
                        <td className='text-start min-w-150px'>{u.DesUnid}</td>
                        <td className='text-start min-w-200px'>{item.nombre}</td>
                        <td className='text-start min-w-200px'>{item.descripcion}</td>
                        <td className='text-start min-w-200px'>
                          {findCountDocs(item._id, u.codUnid)}
                        </td>
                        <td className='text-end'>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <button
                              onClick={() => handleShowEtiquetas(item._id)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTIcon iconName='text-align-justify-center' className='fs-3' />
                            </button>
                            <button
                              onClick={() => handleNew(item, u.codUnid)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTIcon iconName='plus' className='fs-3' />
                            </button>
                          </div>
                        </td>
                      </tr>
                      {selecto === `${u.codUnid}/${item._id}` && (
                        <ListDocuments id={item._id} codUnid={selecto.split('/')[0]} />
                      )}
                    </>
                  ))}
                </>
              ))}

            </tbody>
          </table>
        </div> 
        */}
        <Accordion defaultActiveKey='0'>
          {procesosdata[0]?.unidad.map((u: any, i: number) => (
            <Accordion.Item eventKey={i.toString()}>
              <Accordion.Header className='item-table-header'>{u.DesUnid}</Accordion.Header>
              <Accordion.Body>
                <Accordion>
                  {procesosdata.map((item) => (
                    <Accordion.Item eventKey={item._id}>
                      <Accordion.Header
                        as='div'
                        onClick={() => handleSelecto(`${u.codUnid}/${item._id}`)}
                      >
                        <div className='d-flex align-items-center justify-content-between w-100 px-2'>
                          <div>
                            <h3 className='font-weight-bold mb-0'>{item.nombre}</h3>
                            <p className='mb-0 font-weight-bold'>
                              Total docs: {findCountDocs(item._id, u.codUnid)}
                            </p>
                          </div>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                          {permissions.includes('Etiquetas Proceso') && (
                            <button
                              onClick={() => handleShowEtiquetas(item._id)}
                              className='btn btn-icon btn-bg-info btn-active-color-info btn-sm me-1'
                            >
                              <KTIcon
                                iconName='text-align-justify-center'
                                className='fs-3 text-light'
                              />
                            </button>
                          )}
                        {permissions.includes('Editar Proceso') && (
                            <button
                              onClick={() => handleNew(item, u.codUnid)}
                              className='btn btn-icon btn-bg-success btn-active-color-success btn-sm me-1'
                            >
                              <KTIcon iconName='plus' className='fs-3 text-light' />
                            </button>
                        )}
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <p className='mb-0'>{item.descripcion}</p>
                        </div>
                        {selecto === `${u.codUnid}/${item._id}` && (
                          <ListDocuments id={item._id} codUnid={selecto.split('/')[0]} />
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>

        {procesosdata.length === 0 && unidades.length === 0 && (
          <tr>
            <td colSpan={4}>
              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                No hay procesos resgistrados ni clientes asignados
              </div>
            </td>
          </tr>
        )}

        {procesosdata.length === 0 && unidades.length > 0 && (
          <tr>
            <td colSpan={4}>
              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                No hay procesos resgistrados pero si clientes asignados
              </div>
            </td>
          </tr>
        )}

        {procesosdata.length > 0 && unidades.length === 0 && (
          <tr>
            <td colSpan={4}>
              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                No clientes asignados pero si procesos resgistrados
              </div>
            </td>
          </tr>
        )}

        <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export { PartnersTable }

