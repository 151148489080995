import React, {useCallback, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone';
import './styles.css';
import { KTSVG , KTIcon} from '../../../../../_metronic/helpers';
import DocViewer, { DocViewerRenderers,  } from "@cyntler/react-doc-viewer";
import ModalComponent from './modalPreview';
import { downloadFileS3 } from '../../../../services/files';
import { showMessage } from '../../../../shared/Alerts';



interface DropzoneExampleProps {
    onFilesSelected: (files: File[], index: number,infoArchivo: {url:string,name:string,size:string}[]) => void;
    idx: string;
    titulo: string;
    filesArchivo: File[] | [];
    infoArchivo: {url:string,name:string,size:string,base64?:string}[] | [];
    disabled?: boolean;
}

const DropzoneArchivo : React.FC<DropzoneExampleProps> = ({ onFilesSelected,idx,titulo,filesArchivo,infoArchivo,disabled = false }) =>{
    const [filesBackUp, setFilesBackUp] = useState<{url:string,name:string,size:string,base64?:string}[]>(infoArchivo);
    const [files, setFiles] = useState<File[]>(filesArchivo);

    const onDrop = (acceptedFiles: File[]) => {
        const renamedFiles = Array.from(acceptedFiles).map((file, index) => {
            const partes = file.name.split('.');
            const extension = partes[partes.length - 1];            
            const newName = index === 0?`${titulo}.${extension}`:`${titulo}_${index}.${extension}`
            return new File([file], newName, { type: file.type });
        });

        // Update the state with the accepted files
        setFiles([...files, ...renamedFiles]);
        onFilesSelected([...files, ...renamedFiles],0,filesBackUp);
    };

    const removeFile = (file: File) => {
        setFiles(files.filter((f) => f !== file));
        onFilesSelected(files.filter((f) => f !== file),0,filesBackUp);
    };
    
    const removeFileBackUp = (fileBackup:{name: string; size: string;}) => {
        setFilesBackUp(filesBackUp.filter((f) => f !== fileBackup));
        onFilesSelected(files,0,filesBackUp.filter((f) => f !== fileBackup));
    };

    /* const [fileUrl, setFileUrl] = useState('');
    const handlePreviewFile = (file: File) => {
        const blob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(blob);
        setFileUrl(url);
        
    }; */

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
        onDrop,
        /* accept: {
            "application/*": [".pdf", ".doc", ".docx", ".xls", ".xlsx"],
        }, */
    });

    const descargarArchivos = (archivo:File) => {
        const url = window.URL.createObjectURL(archivo);
        const link = document.createElement('a');
        link.href = url;
        link.download = archivo.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const descargarArchivosS3 = (keyname:string) => {
        const partes = keyname.split('.');
        const extension = partes[partes.length - 1];
        
        const partesKey = keyname.split('/');
        const filename = partesKey[partesKey.length - 1]; 
        downloadFileS3(keyname, extension)
        .then((response) => {
            if (response.success) {
            const link = document.createElement('a');
            link.href = response.data;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            showMessage('success', '¡Buen trabajo!', response.message)
            } else {
            console.log(response)
            showMessage('error', 'Hubo un error', response.error)
            }
        })
        .catch((error) => {
            console.log(error)
            showMessage('error', 'Hubo un error', error.message)
        })
    };


    return (
        <div>
            {disabled? (<></>) : (<div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                <input {...getInputProps()} />
                {isDragActive ? <p>Arrastra y suelta los archivos aquí...</p> : <p>Arrastra archivos aquí o haz clic para seleccionar</p>}
            
            </div>)}
            <div className="dropzone-preview-container">
                {filesBackUp.map((file) => (
                file.url?
                    <>
                    {/* <div key={file.name} className="dropzone-preview">
                        <div className="file-info">
                            <p>{file.name}</p>
                            <p>Peso: {(Number(file.size) / 1024).toFixed(2)} KB</p>
                            {disabled? <></> : <span className="badge badge-square badge-danger remove-btn" onClick={() => removeFileBackUp(file)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-1x"
                                />
                            </span>}
                            <a className="btn btn-icon btn-light pulse pulse-info me-10 mb-10 preview-btn btn-sm" 
                                href={file.url} download >
                                <KTSVG
                                    path="/media/icons/duotune/general/gen005.svg"
                                    className="svg-icon svg-icon-1"
                                />
                                <span className="pulse-ring"></span>
                            </a>
                        </div>
                        
                    </div> */}
                    <div key={file.name} className="dropzone-preview2">
                        <div className="file-info2">
                            <p>{file.name}</p>
                            {/* <p>Peso: {(Number(file.size) / 1024).toFixed(2)} KB</p> */}
                            <span className="badge badge-square badge-danger remove-btn2" onClick={() => removeFileBackUp(file)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-1x"
                                />
                            </span>
                            {/* <button className="btn btn-icon btn-light pulse pulse-info me-10 mb-10 preview-btn2 btn-sm" 
                                onClick={() => descargarArchivosS3(file.url)}>
                                <KTSVG
                                    path="/media/icons/duotune/general/gen004.svg"
                                    className="svg-icon svg-icon-1"
                                />
                                <span className="pulse-ring"></span>
                            </button> */}

                            <button className="badge badge-square badge-danger preview-btn2 btn-sm" 
                                onClick={() => descargarArchivosS3(file.url)} >
                                
                                <KTIcon iconName='cloud-download' className='fs-3' />
                            </button>
                        </div>
                        
                    </div>
                    </>
                :""
                
                ))}
                {files.map((file) => (
                    <>
                        {/* <div key={file.name} className="dropzone-preview">
                            {file.type.startsWith('image/') ? (
                            <>
                                <img src={URL.createObjectURL(file)} alt={file.name} />
                                <span className="badge badge-square badge-danger remove-btn" onClick={() => removeFile(file)}>
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-1x"
                                    />
                                </span>
                                <button className="btn btn-icon btn-light pulse pulse-info me-10 mb-10 preview-btn btn-sm" 
                                    onClick={() => descargarArchivos(file)}>
                                    <KTSVG
                                        path="/media/icons/duotune/general/gen004.svg"
                                        className="svg-icon svg-icon-1"
                                    />
                                    <span className="pulse-ring"></span>
                                </button>
                            </>
                            ) : (
                            <div className="file-info">
                                <p>{file.name}</p>
                                <p>Peso: {(file.size / 1024).toFixed(2)} KB</p>
                                <span className="badge badge-square badge-danger remove-btn" onClick={() => removeFile(file)}>
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-1x"
                                    />
                                </span>
                                <button className="btn btn-icon btn-light pulse pulse-info me-10 mb-10 preview-btn btn-sm" 
                                    onClick={() => descargarArchivos(file)} >
                                    <KTSVG
                                        path="/media/icons/duotune/general/gen004.svg"
                                        className="svg-icon svg-icon-1"
                                    />
                                    <span className="pulse-ring"></span>
                                </button>
                            </div>
                            
                            )}
                        </div> */}
                        <div key={file.name} className="dropzone-preview2">
                            {/* {file.type.startsWith('image/') ? (
                            <>
                                <img src={URL.createObjectURL(file)} alt={file.name} />
                                <span className="badge badge-square badge-danger remove-btn2" onClick={() => removeFile(file)}>
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-1x"
                                    />
                                </span>
                                <button className="btn btn-icon btn-light pulse pulse-info me-10 mb-10 preview-btn2 btn-sm" 
                                    onClick={() => descargarArchivos(file)}>
                                    <KTSVG
                                        path="/media/icons/duotune/general/gen004.svg"
                                        className="svg-icon svg-icon-1"
                                    />
                                    <span className="pulse-ring"></span>
                                </button>
                            </>
                            ) : ( */}
                            <div className="file-info2">
                                <p>{file.name}</p>
                                <p>Peso: {(file.size / 1024).toFixed(2)} KB</p>
                                <span className="badge badge-square badge-danger remove-btn2" onClick={() => removeFile(file)}>
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-1x"
                                    />
                                </span>
                                <button className="badge badge-square badge-danger preview-btn2 btn-sm" 
                                    onClick={() => descargarArchivos(file)} >
                                    <KTIcon iconName='cloud-download' className='fs-3' />
                                    {/* <span className="pulse-ring"></span> */}
                                </button>
                            </div>
                            
                            {/* )} */}
                        </div>
                    </>
                    
                ))}
            </div>
            
            {/* <ModalComponent id={`kt_modal_3`+idx} title="PreVisualizacion">
                <DocViewer
                    documents={[{ uri: fileUrl }]}
                    pluginRenderers={DocViewerRenderers}
                />
            </ModalComponent> */}
            {files.length > 0 && (
                <div className="remove-btn-container">
                    <button className="remove-all-btn bg-danger" onClick={() => setFiles([])}>
                        Eliminar todos los archivos
                    </button>
                </div>
            )}
            
        </div>

        

    );

};

export default DropzoneArchivo;
