import { Fragment, useContext, useEffect, useState } from "react"
import { Button, Col, Dropdown, Form, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap"
import { KTCard, KTCardBody, KTIcon } from "../../../../../_metronic/helpers"
import FichaPlantillaGroup from './Custom1/FichaPlantillaGroup'
/* import { AsignacionTablaTable1 } from "./Empresa/AsignacionTablaTable1"
import { AsignacionTablaTable2 } from "./Empresa/AsignacionTablaTable2" */
import { getAllFindTabla } from "../../../../services/tablas"
import { asignarEmpresas, desasignarEmpresas, getAllCompaniesByPais } from "../../../../services/companies"
import { showMessage } from "../../../../shared/Alerts"
import Select from 'react-select'
import { DataUser } from "../../../documentacion/MiFicha/DataUserInterface"
import { getFichasProceso, getProceso } from "../../../../services/procesos"
import { getCompleteSheet } from "../../../../services/fichas"
import { PartnerContext } from "../PartnerContexts"
import { Field, Section, Sheet } from '../../fichas/Sheet'
import { FieldsMigracion, ListFieldsMigracion } from "../../documento/documentInterface"
import ProcessFieldExport from "./Custom1/ProcessFieldExport"
import { getExternalById, getExternals } from "../../../../services/external"


const WNominaTrayCustom1 = () => {
    const {edit,setEdit,setExternalData,setSheetId,sheet_id,sheet,setSheet,fichaData,setFichaData, setLoadingFichaContent} = useContext(PartnerContext)
    const [listProcesos, setListProcesos] = useState<any[]>([]);
    const [listService, setListService] = useState<any[]>([]);
    const [idSelectProceso,setIdSelectProceso] = useState("")
    const [idSelectService,setIdSelectService] = useState("")
    const [listDataApi1Aux, setListDataApi1Aux] = useState<any[]>([]);
    const [listDataApi1, setListDataApi1] = useState<any[]>([]);
    const [checkedItem1, setCheckedItem1] = useState<any[]>([]);
    const [listDataApi2, setListDataApi2] = useState<any[]>([])
    const [checkedItem2, setCheckedItem2] = useState<any[]>([]);
    
    const [actualizarData,setActualizarData] = useState(false)
    const [listFieldsMigracion,setListFieldsMigracion] = useState<ListFieldsMigracion[]>([])
    const usuarioJSON = localStorage.getItem('userData');
    let usuario: DataUser|null=null;
    
    if (usuarioJSON !== null) {
        usuario = JSON.parse(usuarioJSON);
    }

    useEffect(()=>{
        refetchData()
    },[])
    
    useEffect(()=>{
        setActualizarData(true)
        if(listDataApi1Aux.length>0 && listDataApi2.length>0 && actualizarData ){
            const filteredArray:any[] = listDataApi1Aux.filter(item1 =>
                !listDataApi2.some(item2 => item2._id === item1._id)
            );
            setActualizarData(false)
            setListDataApi1(filteredArray);
        }
    },[listDataApi1Aux,listDataApi2])
    
    const refetchData = async () => {
        const options = {
            estado: "A",
            responsables: usuario?.userId
        }
        await getProceso(1,100,options).then((response) => {
            if (response.success) {
                setListProcesos(response.data.map((x: any) => ({ label: x.nombre, value: x._id })))
            } else {
                console.log(response.msg)
            }
        })

        await getExternals({typedata:"NAME_SERVICES"})
        .then((response)=>{
            if (response.success) {
                setListService(response.data.map((x: any) => ({ label: x.service, value: x._id })))
            } else {
                console.log(response.msg)
            }
        })


    }

    useEffect(()=> {
        if(sheet_id){
            getProcessSheet(sheet_id)
        }
    },[sheet_id])

    
    useEffect(()=> {
        if(edit && idSelectProceso){
            setEdit(false)
            setFichaData([])
            getFichasxProceso(idSelectProceso,sheet_id)
        }
    },[edit])
    
    const setSearchData = () => {
        if(idSelectProceso && idSelectService){
            /* setFichaData([])
            setListFieldsMigracion([])
            setSheetId("") */
            getFichasxProceso(idSelectProceso,"")
            getExternalId(idSelectService)
        }else if(!idSelectProceso){
                showMessage("info","Seleccione una opción", "Seleccione Proceso")            
        }else if(!idSelectService){
            showMessage("info","Seleccione una opción", "Seleccione Servicio")
        }
    }

    const getFichasxProceso = async (id:string,idSheet:string) =>{
        await getFichasProceso(id)
        .then((response) => {
            setFichaData(response.data.map((x: any) => ({label: x.idficha?.nombre, value: x.idficha?._id})))            
            if(!idSheet){
                setSheetId(response.data[0].idficha._id)
            }
            
        })
        .catch((error) => console.log(error))
        .finally(() => setLoadingFichaContent(false))
    }
    
    const getExternalId = async (id:string) =>{
        await getExternalById(id)
        .then((response) => {
            setExternalData(response.data)           
        })
        .catch((error) => console.log(error))
        .finally(() => setLoadingFichaContent(false))
    }

    useEffect(()=>{        
        showTask()
    },[sheet])
    

    const getProcessSheet = async (id:string) =>{
        await getCompleteSheet(id)
        .then((response) => {
            setSheet(response.data)
        })
        .catch((error) => console.log(error))
        .finally(() => setLoadingFichaContent(false))
    }

    const showTask = () =>{
        const listFieldAux : ListFieldsMigracion[] = []    
        sheet.sections?.map((section:Section)=>{
            if(!section.dynamic){
                const fieldMigracion : FieldsMigracion[] = [] 
                section.fields?.map((field:Field)=>{
                fieldMigracion.push({identifier:field.id,check:field.checkIntegracion?field.checkIntegracion:false,label:field.name,type:"string",obligatorio:field.required})
                })
                listFieldAux.push({label:section.name,fields:fieldMigracion})
            }
        })
        setListFieldsMigracion(listFieldAux)
    }

    const handleChangeProceso = (e: any) => {
        setIdSelectProceso(e.value)
    }
    
    const handleChangeService = (e: any) => {
        setIdSelectService(e.value)
    }
    

    return (
        <>
        <KTCardBody>
            <Row>
                <Col xs={10} lg={4} xl={3} sm className='d-flex align-self-end justify-content-start'>
                    <Stack direction='vertical' gap={1}>
                    <span className='fw-bold'>Seleccione Proceso:</span>
                    <Select
                        styles={{
                            control: (provided) => ({
                            ...provided,
                            fontSize: '13px',
                            minHeight: '42px', // Reducción del alto
                            height: '42px',
                            }),
                        }}
                        placeholder='Seleccione el proceso'
                        options={listProcesos}
                        onChange={handleChangeProceso}
                    />
                    </Stack>
                </Col>
                <Col xs={10} lg={3} xl={2} sm className='d-flex align-self-end justify-content-start'>
                    <Stack direction='vertical' gap={1}>
                    <span className='fw-bold'>Seleccione Servicio:</span>
                    <Select
                        styles={{
                            control: (provided) => ({
                            ...provided,
                            fontSize: '13px',
                            minHeight: '42px', // Reducción del alto
                            height: '42px',
                            }),
                        }}
                        placeholder='Seleccione el servicio'
                        options={listService}
                        onChange={handleChangeService}
                    />
                    </Stack>
                </Col>
                <Col sm='1' className='pt-8'>
                    <button
                    type='button'
                    className='btn btn-primary btn-sm'
                    onClick={() => {
                        setSearchData()
                    }}
                    >
                    <KTIcon iconName='filter-search' className='fs-2' />
                    </button>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col xs={5} lg={5} xl={5} sm>
                    <KTCard className="bg-secondary">
                        <KTCardBody>
                            <Form>
                                <Row className='mb-3'>
                                    <FichaPlantillaGroup key={"idFichaPlantillaGroup1"} List={listFieldsMigracion} />
                                </Row>
                            </Form>
                        </KTCardBody>
                    </KTCard>
                </Col>
                <Col xs={7} lg={7} xl={7}  sm>
                    <KTCard className="bg-secondary">
                        <KTCardBody>
                            <Form>
                                <Row className='mb-3'>                                    
                                    <ProcessFieldExport key={"idProcesoField"} idProceso={idSelectProceso} processSheet={fichaData} />
                                </Row>
                            </Form>
                        </KTCardBody>
                    </KTCard>
                </Col>
            </Row>
        </KTCardBody>
            
        </>
    )
}

export { WNominaTrayCustom1 }