import { useMemo, useState, useContext, useEffect, Fragment, FC } from 'react'
import { Form, Stack } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import { KTCardBody, KTIcon } from '../../../../../_metronic/helpers'
import {
  showDeleteMessage,
  showMessage,
  showReactivationMessage,
} from '../../../../shared/Alerts'
import { UsersListLoading } from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import { useQueryResponseData } from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import { usersColumns } from '../../../apps/user-management/users-list/table/columns/_columns'
import Pagination from '../../../../shared/Pagination'
import { deletePartner, updatePartner } from '../../../../services/colaboradores'
import { PartnerContext } from '../PartnerContexts'
import React from 'react'
import { activarUser, deleteUser, getAllUsersPag } from '../../../../services/users'
import { activarVista, deleteVista, getAllVistasPag } from '../../../../services/vista'
import { getAllCompanies, getAllCompaniesByPais } from '../../../../services/companies'
import { PartnersTableGeneral } from '../../../generales_component/PartnersTableGeneral'
import { getRoles } from '../../../../services/rol'

const headerDataApi2 = [
  {
    type: 'check',
    field: 'check_selected',
    label: "Seleccionar",
  },
  {
    type: 'string',
    field: 'nombre',
    label: 'Nombre',
  },
  {
    type: 'array',
    field: 'rol',
    label: 'Rol',
  }
]

interface MyComponentProps {
  tbody:{[key: string]: any;}[];
  onCheckSelect: (Ids: string[]) => void;
}

const AsignacionTablaTable2 :FC<MyComponentProps> = (props) => {
  const {tbody,onCheckSelect} = props
  const users = useQueryResponseData()
  // const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const { getTableBodyProps } = useTable({
    columns,
    data,
  })

  const [listDataApi2, setListDataApi2] = useState<any[]>(tbody)
  const [dataRol, setDataRol] = useState<any[]>([]);
  const [checkedItemApi2, setCheckedItemApi2] = useState<any[]>([]);
  
  useEffect(()=>{
    refetchDataRol()
  },[])

  useEffect(() => {
    actualizarFiles()
  }, [checkedItemApi2]);

  useEffect(() => {
    setCheckedItemApi2([])
    setListDataApi2(tbody)
  }, [tbody]);
  
  const refetchDataRol = async () => {
    await getRoles()
      .then((response) => {
        if (response.success) {
          const DataRol = response?.data.map((ele:any) => {
            return {iconName:'',title:ele.nombre_perfil,state:ele.codigo_perfil}
          })
          setDataRol(DataRol)
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  const actualizarFiles  = () => {
    const newArray : any[]= listDataApi2.map((r:any) => {
      let exist = false
      checkedItemApi2.map(_id => { 
        if(String(r._id) === String(_id)){
          exist = true;
        }
      })
      if(exist){
        return {...r , check_selected:true }
      }else{
        return {...r , check_selected:false }
      }      
    })
    setListDataApi2(newArray)
  }

  const handleCheckSelectedApi =  (_ids: string[],check? :boolean ) => {
    const arraySelected : string[] = []
    checkedItemApi2.map(id => {
      arraySelected.push(id)
    })
    if(_ids.length === 1){
      if(arraySelected.includes(_ids[0])){
        const indiceAEliminar = arraySelected.indexOf(_ids[0]);
        arraySelected.splice(indiceAEliminar, 1);
        setCheckedItemApi2(arraySelected)
        onCheckSelect(arraySelected)
      }else{
        arraySelected.push(_ids[0])
        setCheckedItemApi2(arraySelected)
        onCheckSelect(arraySelected)
      }
    }else{
      const arrayBody : any[] = []
      if(check){
        setCheckedItemApi2(_ids)
        onCheckSelect(_ids)
      }else{
        setCheckedItemApi2([])
        onCheckSelect([])
      }
    }
  }


  return (
    <>
      <PartnersTableGeneral 
        key={"table_general2"}
        title={"Usuario Asignado"} 
        searchVisible={true}
        searchCol={8}
        searchHint={"Buscar Usuario ..."}
        theaderVisible={true} 
        theader={headerDataApi2}
        tbody={listDataApi2}
        tMaxHeight={"400px"}
        onCheckSelected={(_id:string[],check?:boolean) => handleCheckSelectedApi(_id,check)}
        classTable='table-rounded table-striped border' />
    </>
  )
}

export { AsignacionTablaTable2 }
