import React from 'react'
import { useForm } from 'react-hook-form';

export const TiposCampos = ({tipo, nombre}) => {
    const {register, setValue, handleSubmit, formState, clearErrors} = useForm();

    const tipos = {
        'Caja de Texto': 'text',
        'carga de documentos': 'file',
        'Calendario': 'date'
    }

  return (
        <input
          type={tipos[tipo]}
          className='form-control form-control-lg form-control-solid'
          name={nombre}
          placeholder=''
          {...register(nombre, {required: true})}
          autoComplete='off'
        />
      
  )
}
