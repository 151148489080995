import { Fragment, useContext, useEffect, useState } from "react"
import { Button, Col, Dropdown, Form, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap"
import { KTCard, KTCardBody, KTIcon } from "../../../../../_metronic/helpers"
import { AsignacionTablaTable1 } from "./Empresa/AsignacionTablaTable1"
import { AsignacionTablaTable2 } from "./Empresa/AsignacionTablaTable2"
import { getAllFindTabla } from "../../../../services/tablas"
import { asignarEmpresas, desasignarEmpresas, getAllCompaniesByPais } from "../../../../services/companies"
import { showMessage } from "../../../../shared/Alerts"


const DocumentTrayCustom1 = () => {
    const [listDataApi1Aux, setListDataApi1Aux] = useState<any[]>([]);
    const [listDataApi1, setListDataApi1] = useState<any[]>([]);
    const [checkedItem1, setCheckedItem1] = useState<any[]>([]);
    const [listDataApi2, setListDataApi2] = useState<any[]>([])
    const [checkedItem2, setCheckedItem2] = useState<any[]>([]);
    const [actualizarData,setActualizarData] = useState(false)

    useEffect(()=>{
        refetchData1()
        refetchData2()
    },[])
    
    useEffect(()=>{
        setActualizarData(true)
        if(listDataApi1Aux.length>0 && listDataApi2.length>0 && actualizarData ){
            const filteredArray:any[] = listDataApi1Aux.filter(item1 =>
                !listDataApi2.some(item2 => item2._id === item1._id)
            );
            setActualizarData(false)
            setListDataApi1(filteredArray);
        }
    },[listDataApi1Aux,listDataApi2])
    
    const refetchData1 = async () => {
        await getAllFindTabla('Empresas')
            .then((response) => {
            if (response.success) {
                const DataNew1 = response?.data.map((ele:any) => {
                return {...ele,check_selected:false,_id:String(ele.id)}
                })
                setListDataApi1Aux(DataNew1)
            } else {
                console.log(response)
            }
            })
            .catch((error) => {
            console.log(error)
            })
    }
    
    const refetchData2 = async () => {
        await getAllCompaniesByPais()
            .then((response) => {
                if (response.success) {
                const DataNew = response?.data.map((ele:any) => {
                    return {...ele,check_selected:false,_id:String(ele.idEmpresaInterna)}
                })
                setListDataApi2(DataNew)
                } else {
                console.log(response)
                }
            })
            .catch((error) => {
                console.log(error)
        })
    }
    
    const handleDesasignar = async () =>{
        await desasignarEmpresas(checkedItem2)
        .then(res => {
            if(res.success){
                refetchData1()
                refetchData2()
                showMessage("success","Se Desasigno Correctamente","todo correcto")
            }
        })
        .catch(er => console.log(er))
    }
    
    const handleAsignar = async () =>{
        await asignarEmpresas((checkedItem1))
        .then(res => {
            if(res.success){
                refetchData2()
                refetchData1()
                showMessage("success","Se Asigno Correctamente","todo correcto")
            }
    
        })
        .catch(er => console.log(er))
    }

    return (
        <>
        <KTCardBody>
            <Row className='mb-3'>
                <Col xs={5} lg={5} xl={5} sm>    
                    <KTCard>
                        <AsignacionTablaTable1 tbody={listDataApi1} onCheckSelect={(_id:string[]) => setCheckedItem1(_id)}  />
                    </KTCard>
                </Col>
                <Col className='d-flex flex-column ' xs={2} lg={2} xl={2} sm> 
                    <Button className='btn btn-primary mt-10 ' onClick={handleAsignar} >
                        Asignar 
                        <KTIcon iconName='arrow-right' className='fs-2 mx-3' />
                    </Button>
                    
                    <Button className='btn btn-secondary mt-3' onClick={handleDesasignar} >
                        <KTIcon iconName='arrow-left' className='fs-2 mx-3' />
                        Desasignar 
                    </Button>
                </Col>
                <Col xs={5} lg={5} xl={5}  sm>
                    <KTCard>
                        <AsignacionTablaTable2 tbody={listDataApi2} onCheckSelect={(_id:string[]) => setCheckedItem2(_id)} />
                    </KTCard>
                </Col>
            </Row>
        </KTCardBody>
            
        </>
    )
}

export { DocumentTrayCustom1 }