import {useContext, useEffect, useState} from 'react'
import {Row, Form, Button} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import AssigmentListGroup from './AssigmentListGroup'
import CheckboxTree from 'react-checkbox-tree'
import { v4 } from 'uuid'
import { PartnerContext } from '../../../PartnerContexts'


const AssignmentEditModalForm = () => {
  const {setOpenAssignmentModal,fieldData, setFieldData,externalData,setUpdateFieldAssing} = useContext(PartnerContext)
  // const {closeAssignmentModal, fieldData, setFieldData, sheetData, setData,sectionData} = useContext(SheetEditContext)

  const handleListChange = (name: string) => {
    setFieldData({...fieldData, tagIntegracion: name})
  }

  const handleSubmit = () => {
    // e.preventDefault()
    setUpdateFieldAssing(true)
    setOpenAssignmentModal(false)
  }

  return (
    <>
      <Form>
        <Form.Group className='mb-3' controlId='formServicio'>
          <Form.Label>Servicio : <b>{externalData.service}</b> </Form.Label>
        </Form.Group>

        <Row className='mb-3'>
          <AssigmentListGroup elementSelected={fieldData.tagIntegracion} onChange={(name: string)=>handleListChange(name)} />
        </Row>

        <Row>
          <div className='d-flex justify-content-end'>
            <Button
              variant='light'
              className='mx-3'
              type='reset'
              onClick={() => {
                setOpenAssignmentModal(false)
              }}
            >
              Cancelar
            </Button>
            <Button variant='primary' type='button' onClick={handleSubmit}>
              Guardar
            </Button>
          </div>
        </Row>
      </Form>
    </>
  )
}

export {AssignmentEditModalForm}
