import { fetchConToken } from "../helpers/fetch";

const url = `tabla/`

const getAllFindTabla = async (nombre = '',filter='') => {
    // const queryString = new URLSearchParams(options).toString();

    const resp = await fetchConToken(
        `${url}getAll/findTabla/${nombre}?filter=${filter}`,
        {},
        'GET'
    );

    const body = await resp.json();

    return body;
};

export {
  getAllFindTabla
};

