import {Fragment, useContext, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import {formatDateToDMY} from '../../../../helpers/dateFunctions'
import {downloadFileS3} from '../../../../services/files'
import {fetchMenuActionsByRole, fetchSubMenuByUrl} from '../../../../services/menu'
import Pagination from '../../../../shared/Pagination'
import SortableTableHeader from '../../../../shared/SortableTableHeader'
import {useGlobalOverlayLoading} from '../../../../shared/hooks/useGlobalOverlayLoading'
import {useUnidades} from '../../../../shared/hooks/useUnidades'
import {UsersListLoading} from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {PreviewDocumentModal} from '../../../generales_component/preview-document-modal/PreviewDocumentModal'
import {handleDownload} from '../../../generales_functions/files'
import {DocumentsReportContext} from '../DocumentsReportContext'

const DocumentsReportTable = () => {
  const [documentsList, setDocumentsList] = useState<any>([])
  const {
    data,
    setCurrentPage,
    currentPage,
    totalPages,
    isLoading,
    selectedDocuments,
    setSelectedDocuments,
    indexes,
    sortBy,
    setSortBy,
  } = useContext(DocumentsReportContext)
  const {misUnidades} = useUnidades()
  const location = useLocation()
  const {selectedRole} = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])
  const [isPreviewDocumentModalOpen, setIsPreviewDocumentModalOpen] = useState(false)
  const [previewDocument, setPreviewDocument] = useState<any>([])
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu =
            response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  console.log(misUnidades)
  useEffect(() => {
    setDocumentsList(data)
  }, [data])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    if (currentPage > 1) {
      links.push({page: currentPage - 1, label: 'Anterior'})
    }

    for (let i = 1; i <= totalPages; i++) {
      links.push({page: i, label: String(i)})
    }

    if (currentPage < totalPages) {
      links.push({page: currentPage + 1, label: 'Siguiente'})
    }

    return links
  }

  const handleMainToggle = () => {
    if (selectedDocuments.length === indexes.length) {
      setSelectedDocuments([])
    } else {
      setSelectedDocuments(indexes)
    }
  }

  const handleToggle = (id: string) => {
    const updatedCheckboxes = [...selectedDocuments]
    const index = updatedCheckboxes.indexOf(id)

    if (index === -1) {
      updatedCheckboxes.push(id)
    } else {
      updatedCheckboxes.splice(index, 1)
    }

    setSelectedDocuments(updatedCheckboxes)
  }

  const handleSort = (columnName: string, sortDirection: string) => {
    setSortBy({columnName, sortDirection})
  }

  const handleOpenPreviewDocumentModal = async (keyname: string, filename: string) => {
    const partes = filename.split('.')
    const extension = partes[partes.length - 1]

    setGlobalOverlayLoading(true)
    const response = await downloadFileS3(keyname, extension)
    if (response.success) {
      setPreviewDocument([
        {
          uri: response.data,
          fileType: extension.toLocaleLowerCase(),
          fileName: filename,
        },
      ])
    }
    setIsPreviewDocumentModalOpen(true)
    setGlobalOverlayLoading(false)
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th className='w-25px'>
                {documentsList.length > 0 && (
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id={'checkbox-documents-report-all'}
                      checked={selectedDocuments.length === indexes.length}
                      onChange={handleMainToggle}
                    />
                  </div>
                )}
              </th>
              {misUnidades.length > 0 && <th className='text-start'>Unidad</th>}
              <th className='text-start'>DNI</th>
              <SortableTableHeader
                label='Apellidos y Nombres'
                columnName='nombre'
                handleSort={handleSort}
                activeColumn={sortBy.columnName}
              />
              <SortableTableHeader
                label='Fecha de Envio'
                columnName='createdAt'
                handleSort={handleSort}
                activeColumn={sortBy.columnName}
              />
              <SortableTableHeader
                label='Nombre de Documento'
                columnName='name'
                handleSort={handleSort}
                activeColumn={sortBy.columnName}
              />
              <SortableTableHeader
                label='Estado firma'
                columnName='state'
                handleSort={handleSort}
                activeColumn={sortBy.columnName}
              />
              <th className='text-end'>Acciones</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {documentsList?.length > 0 ? (
              documentsList?.map((item: any, index: number) => (
                <Fragment key={item._id}>
                  <tr>
                    <td className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id={`checkbox-documents-report-${item._id}`}
                          checked={selectedDocuments.includes(item._id)}
                          onChange={(e) => handleToggle(item._id)}
                        />
                      </div>
                    </td>
                    {misUnidades.length > 0 && (
                      <td className='text-start min-w-100px'>
                        {item.unidad?.DesUnid ?? 'Sin unidad'}
                      </td>
                    )}
                    <td className='text-start min-w-100px'>{item.dni}</td>
                    <td className='text-start min-w-100px'>{item.userName}</td>
                    <td className='text-start min-w-100px'>{formatDateToDMY(item.sendDate)}</td>
                    <td className='text-start min-w-100px'>{item.name}</td>
                    <td className='text-start min-w-100px'>{item.state}</td>
                    <td className='text-end'>
                      {permissions.includes('Descargar documento') && (
                        <>
                          <button
                            type='button'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={() =>
                              handleOpenPreviewDocumentModal(
                                item.keyname,
                                item.keyname.split('/')[item.keyname.split('/').length - 1]
                              )
                            }
                            title='Previsualizar documento'
                          >
                            <KTIcon iconName='eye' className='fs-3' />
                          </button>
                          <button
                            type='button'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={() =>
                              handleDownload(
                                item.keyname,
                                item.keyname.split('/')[item.keyname.split('/').length - 1]
                              )
                            }
                            title='Descargar documento'
                          >
                            <KTIcon iconName='file-down' className='fs-3' />
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                </Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        links={generatePaginationLinks(currentPage, totalPages)}
        currentPage={currentPage}
        isLoading={isLoading}
        onPageChange={handlePageChange}
      />
      <PreviewDocumentModal
        isPreviewDocumentModalOpen={isPreviewDocumentModalOpen}
        setIsPreviewDocumentModalOpen={setIsPreviewDocumentModalOpen}
        title=''
        documentos={previewDocument}
      />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export {DocumentsReportTable}
