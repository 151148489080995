
import React, {useState, useEffect } from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import mammoth from 'mammoth';
import { Col, Container, Row } from 'react-bootstrap';
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {getFileId,downloadFileS3} from '../../../../services/files'
import Iframe from 'react-iframe';
import PDFViewer from './PDFViewer';
import OverlayLoading from '../../../generales_component/OverlayLoading';

interface Doc {
    uri:string;
    fileType:string;
    fileName: string;
}

function DocumentView() {
    const {id} = useParams()
    const [docs, setDocs] = useState<Doc[]>([])
    const [documento, setDocumento] = useState<Doc[]>([])
    const [base64, setBase64] = useState("");
    const [keyNameFile, setKeyNameFile] = useState("");
    const [extensionFile, setExtensionFile] = useState("");
    const [cargandoFile, setCargandoFile] = useState(false);
    let blob : any

    useEffect(() => {
        if (id) {
            handleGetDocument(id)
        }
    }, [])

    /* useEffect(() => {
        if (id) {
            handleGetDocument(id)
        }
    }, [id]) */

    const base64ToBlob = (base64:string) => {
        const binaryString = window.atob(base64);
        const arrayBuffer = new ArrayBuffer(binaryString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryString.length; i++) {
            uint8Array[i] = binaryString.charCodeAt(i);
        }
        return new Blob([uint8Array]);
    };

    const handleGetDocument = (id:string) => {
        setCargandoFile(true)
        getFileId(id)
        .then((response) => {
            console.log(response)
            if (response.success) {
                const keyname = response.data.keyname
                const filename = response.data.filename
                const partes = filename.split('.');
                const extension = partes[partes.length - 1];
                setKeyNameFile(keyname)
                setExtensionFile(extension)                
                setCargandoFile(false)
            } else {
            console.log(response)
            }
        })
        .catch((error) => {
            console.log(error)
        })

        /*  */
    };



    /* const docs = [
        { 
            uri: "https://s3.amazonaws.com/tawa.wdocs.bucket.des/Empresa/647c0d730b0325fc1a8215de/Documentos/Documentos/CV%20ACTUALIZADO/Sample.docx",
            fileType:"docx",
            fileName:"Sampe.docx"
        },
        { 
            uri: "https://s3.amazonaws.com/tawa.wdocs.bucket.des/Empresa/647c0d730b0325fc1a8215de/Documentos/Documentos/CV%20ACTUALIZADO/2.xlsx",
            fileType:"xlsx",
            fileName:"ElMejor.xlsx" 
        },
        { 
            uri: "https://s3.amazonaws.com/tawa.wdocs.bucket.des/Empresa/647c0d730b0325fc1a8215de/Documentos/Documentos/CV%20ACTUALIZADO/2.xlsx",
            fileType:"xlsx",
            fileName:"ElMejor.xlsx"
        }
    ]; */


    return(
        <>
            <Container className="mt-5">
                <Row>
                    {/* <Col>
                        <div>
                            <h1>Visor de documentos Word</h1>
                            <Iframe
                                url={base64}
                                width="100%"
                                height="500px" // Puedes ajustar la altura según tus necesidades
                            />
                        </div>
                    </Col> */}
                    <Col sm={12} >
                    {extensionFile === 'PDF'? 
                    <PDFViewer keyn={keyNameFile} /> 
                    :
                    <PDFViewer keyn={keyNameFile} /> 
                    }
                        {/* <DocViewer 
                            documents={docs} 
                            pluginRenderers={DocViewerRenderers} 
                            style={{height:850}}                            
                            theme={{
                                primary: "#ffffff",
                                secondary: "#5C5C9C",
                                tertiary: "#5296d899",
                                textPrimary: "#ffffff",
                                textSecondary: "#5296d8",
                                textTertiary: "#00000099",
                                disableThemeScrollbar: false,
                            }}
                            config={{ header: { disableHeader: true } }}
                        /> */}
                    </Col>
                    
                </Row>
            </Container>
            {cargandoFile && <OverlayLoading />}
        </>
    )
    
    
}


export default DocumentView;

