import {useContext} from 'react'

import { ShowDocumentModalFormWrapper } from './ShowDocumentModalFormWrapper'
import { ShowDocumentModalHeader } from './ShowDocumentModalHeader'
import { PartnerContext } from '../PartnerContexts'

const ShowDocumentModal = () => {

  const {showSelectDocumentModal} = useContext(PartnerContext)

  return (
    <>
      {showSelectDocumentModal && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered modal-xl'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <ShowDocumentModalHeader />
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <ShowDocumentModalFormWrapper />
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {ShowDocumentModal}
