import { createContext } from "react"
import { Permiso } from "./permisosInterface"

interface PartnerContextProps {
    searchTerm: string,
    setSearchTerm: (value: string) => void,
    permisoData:Permiso,
    setPermisoData: (value: Permiso) => void,
}

const PartnerContext = createContext<PartnerContextProps>({
    searchTerm: '',
    setSearchTerm: (value) => {},
    permisoData:{ _id:'', nombre: '', detalle: [] },
    setPermisoData: (value) => {},
})

export {PartnerContext}