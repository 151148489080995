import {useEffect, useState} from 'react'
import {useAuth2} from '../../modules/authv2/Auth2Provider'
import {
  getUnidadesEmpresas,
  getUnidadesEmpresasInspector,
  getUnidadesLikePartner,
} from '../../services/companies'

interface IUnidades {
  codUnidad: string
  DesUnid: string
  selected: boolean
}

interface IUnidadesPartner {
  codUnid: string
  DesUnid: string
  codEmpresa: string
}

export const useUnidades = () => {
  const [unidades, setUnidades] = useState<IUnidades[]>([])
  const [unidadesInspector, setUnidadesInspector] = useState<IUnidades[]>([])
  const [unidadesPartner, setUnidadesPartner] = useState<IUnidadesPartner[]>([])

  const {selectedCompanyId, userData} = useAuth2()
  const userId = userData.userId

  const misUnidades = unidades.filter((unidad) => unidad.selected)
  const misUnidadesInspector = unidadesInspector

  const toOptions = () => {
    const deafultOption = {
      DesUnid: 'Seleccione una unidad',
      codUnidad: '',
    }

    return [deafultOption, ...misUnidades].map((unidad) => ({
      label: unidad.DesUnid,
      value: unidad.codUnidad,
    }))
  }

  const toOptionsInspector = () => {
    const deafultOption = {
      DesUnid: 'Seleccione una unidad',
      codUnidad: '',
    }

    return [deafultOption, ...misUnidadesInspector].map((unidad) => ({
      label: unidad.DesUnid,
      value: unidad.codUnidad,
    }))
  }

  const toOptionsPartner = () => {
    const deafultOption = {
      DesUnid: 'Seleccione una unidad',
      codUnid: '',
    }

    return [deafultOption, ...unidadesPartner].map((unidad) => ({
      label: unidad.DesUnid,
      value: unidad.codUnid,
    }))
  }

  useEffect(() => {
    getUnidadesEmpresas([selectedCompanyId as string], userId)
      .then((response) => {
        if (response.success) setUnidades(response.data[0].unidad ?? [])
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    getUnidadesEmpresasInspector([selectedCompanyId as string])
      .then((response) => {
        if (response.success) setUnidadesInspector(response.data[0].unidad ?? [])
      })
      .catch((error) => {
        console.log(error)
      })

    getUnidadesLikePartner()
      .then((response) => {
        if (response.success) {
          setUnidadesPartner(response.data ?? [])
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return {
    unidades,
    misUnidades,
    misUnidadesInspector,
    unidadesPartner,
    toOptions,
    toOptionsInspector,
    toOptionsPartner,
  }
}
