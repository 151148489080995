import moment from 'moment'
import React, {useContext, useState} from 'react'
import {Button} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {downloadFileS3} from '../../../services/files'
import {enviarWNomina} from '../../../services/wnomina'
import {showInfoHtml} from '../../../shared/Alerts'
import {useGlobalOverlayLoading} from '../../../shared/hooks/useGlobalOverlayLoading'
import {GlobalModal} from '../../generales_component/modal/GloblaModal'
import {PdfLoadingSpinner} from '../../generales_component/PdfLoadingSpinner'
import {Doc} from '../../generales_component/preview-document-modal/PreviewDocument'
import {PreviewDocumentModal} from '../../generales_component/preview-document-modal/PreviewDocumentModal'
import {TableBusiness} from '../../generales_component/table/TableBusiness'
import {orderStrings} from '../../generales_functions/ordering'
import {AltaContext} from './AltaContext'

const states: Record<string, string> = {
  aprobadoConPendiente: 'Aprobado con pendiente',
  aprobadoPorAdmin: 'Aprobado por el administrador',
}

type SortState = {
  key: string
  order: 'asc' | 'desc'
}

const AltaBody: React.FC = () => {
  const {
    setFichasColaboradores,
    fichasColaboradores,
    loading,
    usersSelected,
    setUsersSelected,
    handleOpenFieldModal,
    fieldSelected,
    status,
    processId,
    fetchPartnersData,
  } = useContext(AltaContext)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [sort, setSort] = useState<SortState | null>(null)
  const [ficha, setFicha] = useState<Doc>({
    uri: '',
    fileType: '',
    fileName: '',
  })
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()

  const getIdRegistroFicha = (dni: string) => {
    return fichasColaboradores.find((ficha) => ficha.id_user_creador.dni === dni)?._id
  }

  const [openTableModal, setOpenTableModal] = useState(false)
  const [tables, setTables] = useState<any[]>([])
  const [icon, setIcon] = useState<'success' | 'warning'>('success')

  const handleSendNomina = async () => {
    const usersFilter = Object.keys(usersSelected).filter((key) => usersSelected[key])
    const toSend = usersFilter.map((user) => ({dni: user, registroFicha: getIdRegistroFicha(user)}))

    try {
      setGlobalOverlayLoading(true)
      const response = await enviarWNomina({
        users: toSend,
        processId,
      })

      await fetchPartnersData()

      const {
        data: {usersNotSent, usersInserted, usersRejected, exportedFields},
      } = response

      const tableNotSent = {
        title: 'Los siguientes usuarios no cumplen con los campos obligatorios',
        headers: [
          {key: 'dni', label: 'DNI'},
          {key: 'requiredFields', label: 'Observacion'},
        ],
        data: usersNotSent?.map((user: Record<string, string>) => ({
          dni: user.dni,
          requiredFields: user.requiredFields,
        })),
      }

      // const messageNeedRequiredFields =
      //   'Los siguientes usuarios necesitan campos que son obligatorios: <br />' +
      //   tableNotSent +
      //   '<br />'
      // const sizeUsersNotSent = usersNotSent?.length

      const tableInserted = {
        title: 'Los siguientes usuarios fueron enviados a Workin Nomina',
        headers: [{key: 'dni', label: 'DNI'}],
        data: usersInserted?.map((user: string) => ({dni: user})),
      }

      // const messageSucces =
      //   'Los siguientes usuarios fueron enviados correctamente: <br />' +
      //   usersInserted?.map((user: string) => `${user}`)?.join('<br />')
      // const sizeUsersInserted = usersInserted?.length

      const tableRejected = {
        id: 'Los siguientes usuarios tienen observaciones',
        headers: [
          {key: 'dni', label: 'DNI'},
          {key: 'error', label: 'Observacion'},
        ],
        data: usersRejected?.map((user: Record<string, string>) => ({
          dni: user.dni,
          error: user.error,
        })),
      }

      // const messageRejected =
      //   'Los siguientes usuarios no fueron enviados correctamente: <br />' +
      //   usersRejected?.map((user: Record<string, string>) => `${user.dni}`)?.join('<br />')
      // const sizeUsersRejected = usersRejected?.length

      // const messageFieldsExported =
      //   'Los siguientes campos fueron exportados: <br />' +
      //   exportedFields
      //     ?.map((field: any, idx: number) => `${idx + 1}.- ${field.name}`)
      //     ?.join('<br />')

      // const messages: string = [
      //   messageFieldsExported,
      //   sizeUsersNotSent > 0 ? messageNeedRequiredFields : '',
      //   sizeUsersInserted > 0 ? messageSucces : '',
      //   sizeUsersRejected > 0 ? messageRejected : '',
      // ].join('<br /> <br />')

      const icon = usersNotSent.length === 0 && usersRejected.length === 0 ? 'success' : 'warning'
      // const messageInfo =
      //   sizeUsersNotSent === 0 && sizeUsersRejected === 0
      //     ? 'Operacion exitosa'
      //     : 'Operacion con inconvenientes'

      // showInfoHtmlCenter(icon, messageInfo, messages)
      setIcon(icon)
      setOpenTableModal(true)
      setTables([tableNotSent, tableRejected, tableInserted])
    } catch (error) {
      const ex = error as Error
      showInfoHtml('error', 'Hubo un error al procesar la informacion', ex.message)
    } finally {
      setGlobalOverlayLoading(false)
    }
  }

  const handleCheckAll = (e: any) => {
    const copy = Object.assign({}, usersSelected)
    for (const key in copy) {
      const found = fichasColaboradores.find((colab) => colab.id_user_creador.dni === key)
      const criteria = found?.actualizable === 'Y' && found?.estado === 'aprobadoConPendiente'
      copy[key] = criteria ? false : e.target.checked
    }

    setUsersSelected(copy)
  }

  const handleCheckOne = (dni: string, checked: boolean) => {
    setUsersSelected((prev) => ({...prev, [dni]: checked}))
  }

  const handleViewFicha = async (ficha: Record<string, any>) => {
    // navigate(`/seguimiento/colaboradores/view/${ficha}`)
    const partes = ficha?.filename?.split('.') as string[]
    const extension = partes?.pop() ?? ''

    setGlobalOverlayLoading(true)
    const response = await downloadFileS3(ficha?.keyname, extension)
    setGlobalOverlayLoading(false)

    setFicha({
      uri: response.data,
      fileType: extension.toLocaleLowerCase(),
      fileName: ficha?.filename,
      keyName: ficha?.keyname,
    })
    setIsModalOpen(true)
  }

  const handleSort = (key: string, type: 'string' | 'date' | 'number') => {
    const exist = sort != null && sort.key === key
    const order = exist ? (sort.order === 'asc' ? 'desc' : 'asc') : 'asc'
    setSort({key, order})

    if (type === 'string') {
      setFichasColaboradores(orderStrings(fichasColaboradores, {key, order}))
      return
    }

    if (type === 'date') {
      setFichasColaboradores(orderStrings(fichasColaboradores, {key, order}))
      return
    }

    if (type === 'number') {
      return
    }
  }

  // const fieldSelectedLength = Object.entries(fieldSelected).reduce(
  //   (acc: number, [_, object]) => (acc += Object.keys(object).length),
  //   0
  // )
  const disabledButton = Object.values(usersSelected).every((value) => !value)
  const countUsers = Object.values(usersSelected).filter((value) => value).length
  const message = countUsers > 1 ? `${countUsers} usuarios` : '1 usuario'

  if (loading) {
    return (
      <div style={{minHeight: '100vh', maxHeight: '100vh'}}>
        <PdfLoadingSpinner />
      </div>
    )
  }

  return (
    <>
      <div className='table-responsive mt-5' style={{minHeight: '100vh', maxHeight: '100vh'}}>
        {status === 'pendiente' && (
          <div className='d-flex gap-3 justify-content-end mb-3'>
            {/* <Button onClick={handleOpenFieldModal}>Exportar {fieldSelectedLength} campos</Button> */}
            {/* {fichasColaboradores.length > 0 && (
              <OverlayTrigger
                overlay={
                  disabledButton ? (
                    <Tooltip id='tooltip'>
                      Seleccione campos y/o usuarios para enviar a nomina
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <div> */}
            <Button onClick={handleSendNomina} disabled={disabledButton}>
              Enviar {countUsers === 0 ? '' : `${message} `} a Workin Nomina
            </Button>
            {/* </div>
              </OverlayTrigger>
            )} */}
          </div>
        )}
        {fichasColaboradores.length === 0 ? (
          <div className='d-flex justify-content-center'>No se encontraron colaboradores</div>
        ) : (
          <TableBusiness
            checkbox={{include: status === 'pendiente', onChange: handleCheckAll}}
            headers={[
              {
                key: 'dni',
                value: 'Nro. de documento',
                icon:
                  sort?.key === 'dni'
                    ? sort.order === 'asc'
                      ? 'arrow-down'
                      : 'arrow-up'
                    : 'arrow-up-down',
                iconClass: 'text-light',
                onSort: (key) => handleSort(key, 'string'),
              },
              {
                key: 'fecha_ingreso',
                value: 'Fecha de ingreso',
                icon:
                  sort?.key === 'fecha_ingreso'
                    ? sort.order === 'asc'
                      ? 'arrow-down'
                      : 'arrow-up'
                    : 'arrow-up-down',
                iconClass: 'text-light',
                onSort: (key) => handleSort(key, 'date'),
              },
              {
                key: 'nombre',
                value: 'Nombres y apellidos',
                icon:
                  sort?.key === 'nombre'
                    ? sort.order === 'asc'
                      ? 'arrow-down'
                      : 'arrow-up'
                    : 'arrow-up-down',
                iconClass: 'text-light',
                onSort: (key) => handleSort(key, 'string'),
              },
              {key: 'estado', value: 'Estado OnBoarding'},
              {key: 'estadoEnvioWNomina', value: 'Estado envio'},
              {key: 'ver', value: 'Ver ficha'},
            ]}
          >
            {fichasColaboradores.map((colabs) => (
              <tr
                key={colabs._id}
                onClick={
                  status === 'pendiente' &&
                  !(colabs.actualizable === 'Y' && colabs.estado === 'aprobadoConPendiente')
                    ? () =>
                        handleCheckOne(
                          colabs.id_user_creador.dni,
                          !(usersSelected[colabs.id_user_creador.dni] ?? false)
                        )
                    : undefined
                }
              >
                {status === 'pendiente' && (
                  <td className='text-start' style={{paddingBlock: '5px', paddingLeft: '5px'}}>
                    <div className='form-check form-check-custom form-check-solid form-check-sm m-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={usersSelected[colabs.id_user_creador.dni] ?? false}
                        disabled={
                          colabs.actualizable === 'Y' && colabs.estado === 'aprobadoConPendiente'
                        }
                        id={colabs.id_user_creador.dni}
                        onChange={(e) =>
                          handleCheckOne(colabs.id_user_creador.dni, e.target.checked)
                        }
                      />
                    </div>
                  </td>
                )}
                <td className='text-start min-w-100px p-2'>{colabs.id_user_creador.dni}</td>
                <td className='text-start min-w-100px p-2'>
                  {moment(colabs.fecha_ingreso).format('DD/MM/YYYY')}
                </td>
                <td className='text-start min-w-100px p-2'>{colabs.id_user_creador.nombre}</td>
                <td className='text-start min-w-100px p-2'>{states[colabs.estado] ?? ''}</td>
                <td className='text-start min-w-100px p-2'>{colabs.estadoEnvioWNomina}</td>
                <td className='text-start min-w-100px p-2'>
                  <button
                    className='p-0 btn'
                    style={{margin: '0 25%'}}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation()
                      handleViewFicha(colabs.pdf)
                    }}
                  >
                    <KTIcon iconName='eye' />
                  </button>
                </td>
              </tr>
            ))}
          </TableBusiness>
        )}
      </div>
      <PreviewDocumentModal
        isPreviewDocumentModalOpen={isModalOpen}
        setIsPreviewDocumentModalOpen={setIsModalOpen}
        title=''
        documentos={[ficha]}
      />
      <GlobalModal
        tables={tables}
        handleClose={() => {
          setOpenTableModal(false)
        }}
        show={openTableModal}
        icon={icon}
      />
    </>
  )
}

export {AltaBody}
