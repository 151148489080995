import { useEffect, useState } from 'react'
import { KTCard } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Campos, DataTabla, Ficha, ProcesoFichas, Seccion, Tabla } from '../../documentacion/MiFicha/FIchaInterface'
import { PartnerContext } from './PartnerContexts'
import { HistorialDocumentsListHeader } from './components/HistorialDocumentsListHeader'
import { HistorialDocumentsTable } from './components/HistorialDocumentsTable'


const historialDocumentsBreadcrumbs = [
  {
    title: 'Seguimiento',
    path: '/seguimiento/historialdocumentos',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const HistorialDocumentsPage = () => {

  const [activeOnly, setActiveOnly] = useState(true)
  const [state, setState] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [entryDateFrom, setEntryDateFrom] = useState('')
  const [entryDateTo, setEntryDateTo] = useState('')
  const [idRegistroFicha, setIdRegistroFicha] = useState('')
  const [isObservacionModalOpen, setObservacionModalStatus] = useState(false)
  const [procesoFichaData, setProcesoFichaData] = useState<ProcesoFichas[]>([])
  const [fichas, setFichas] = useState<Ficha[]>([])
  const [seccion, setSeccion] = useState<Seccion[]>([])
  const [campos, setCampos] = useState<Campos[]>([])
  const [secciones, setSecciones] = useState<Tabla[]>([])
  const [dataSecciones, setDataSecciones] = useState<DataTabla[]>([])
  const [id_process, setId_Process] = useState('')
  const [unidad, setUnidad] = useState('') 
  const [Dates, setDates] = useState<[Date | null, Date | null]>([null, null])

  const openObservacionModal = (id:string) => {
    setIdRegistroFicha(id)
    setObservacionModalStatus(true)
  }

  const handleInputObservacionChange = (id:string) => {
  }

  const closObservacionModal = () => {
    setObservacionModalStatus(false)
    //clearSection()
  }

  useEffect(() => {
    setEntryDateFrom(Dates[0]?.toISOString().split('T')[0] ?? '')
    setEntryDateTo(Dates[1]?.toISOString().split('T')[0] ?? '')
  }, [Dates])

  return (
    <>
      <PartnerContext.Provider
      value={{
        activeOnly,
        setActiveOnly,
        state,
        setState,
        isObservacionModalOpen,openObservacionModal,
        closObservacionModal,idRegistroFicha,procesoFichaData,setProcesoFichaData,
        fichas,
        setFichas,
        seccion,
        setSeccion,
        campos,
        setCampos,
        secciones,
        setSecciones,
        dataSecciones,
        setDataSecciones,
        handleInputObservacionChange,
        searchTerm,
        setSearchTerm,
        entryDateFrom,
        setEntryDateFrom,
        entryDateTo,
        setEntryDateTo,
        id_process, 
        setId_Process,
        unidad,
        setUnidad,
        Dates,
        setDates
      }}>
        <PageTitle breadcrumbs={historialDocumentsBreadcrumbs}>Historial de Documentos</PageTitle>
        <KTCard>
          <HistorialDocumentsListHeader />
          <HistorialDocumentsTable />
        </KTCard>
      </PartnerContext.Provider>
    </>
  )
}

export { HistorialDocumentsPage }

