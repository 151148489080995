import { useEffect, useState } from "react"
import { useThemeMode } from "../../../_metronic/partials"
import { Link, useParams } from "react-router-dom"
import { getFileInPDF } from "../../services/files"
import { showMessage } from "../../shared/Alerts"
import { getDocumentSentById } from "../../services/documentSent"
import { getVistaById } from "../../services/vista"

const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat']

const VistaAdicionalPage = () => {
    const { url } = useParams();
    const [title, setTitle] = useState<string>('');
    const [contenido, setContenido] = useState<string>('');

    const { mode } = useThemeMode()
    useEffect(() => {
        BODY_CLASSES.forEach((c) => document.body.classList.add(c))
        /* document.body.style.backgroundImage =
            mode === 'dark'
                ? `url(${toAbsoluteUrl('/media/auth/bg7-dark.jpg')})`
                : `url(${toAbsoluteUrl('/media/auth/bg7.jpg')})` */

        return () => {
            BODY_CLASSES.forEach((c) => document.body.classList.remove(c))
            document.body.style.backgroundImage = 'none'
        }
    }, [mode])

    useEffect(() => {
        getVistaData()
    }, [])

    const getVistaData = () => {

        if (url) {
            console.log("url")
            console.log(url)
            getVistaById(url)
                .then((response) => {
                    if (response.success) {
                        setTitle(response.data.nombre)
                        setContenido(response.data.contenido)
                        // setData(response.data)
                    }
                    else {
                        console.log(response)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }

    

    return (<div className='d-flex flex-column flex-root'>
        <div className='d-flex flex-column flex-center flex-column-fluid'>
            <div className='d-flex flex-column flex-center text-center p-10'>
                <div className='card card-flush w-lg-950px  py-5'>
                    <div className='card-body py-15 py-lg-20'>
                        <div>
                            {/* <h1>{title}</h1> */}
                            <div dangerouslySetInnerHTML={{ __html: contenido }} />                         
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export { VistaAdicionalPage }