import debounce from 'just-debounce-it'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Col, Dropdown, Form, Row, Stack } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import Select, { SelectInstance } from 'react-select'
import { KTIcon } from '../../../../../_metronic/helpers'
import { getUnidadesEmpresas } from '../../../../services/companies'
import { fichasxid, generateExcel, generateSimplifiedExcel } from '../../../../services/fichas'
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from '../../../../services/menu'
import { getProceso } from '../../../../services/procesos'
import { TableSearch } from '../../../../shared/TableSearch'
import { useGlobalOverlayLoading } from '../../../../shared/hooks/useGlobalOverlayLoading'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import { DataUser } from '../../../documentacion/MiFicha/DataUserInterface'
import { PartnerContext } from '../PartnerContexts'
import { DatePickerBusiness } from '../../../generales_component/DatePickerBusiness'

interface Campo {
  _id: string
  titulo: string
  tabla: any
  idtipo: Record<string, string>
  opciones?: {id: string; name: string; parent_id: string}[]
}
interface Filtro {
  idseccion: string
  campos: Campo[]
}

interface FiltroState {
  titulo: string,
  tipo: Record<string, string>,
  opciones?: {id: string, name: string, parent_id: string}[]
}

interface FiltroProceso {
  idficha: string
  idproceso: string
  filtros: Filtro[]
}

const PartnersListHeader = () => {
  const buttonsData = [
    {
      iconName: 'time',
      title: 'Pendiente por Colaborador',
      stateName: 'pendientePorColaborador',
      color: '#3498db', // Blue
    },
    {
      iconName: 'check-square',
      title: 'Completado por Colaborador',
      stateName: 'completadoPorColaborador',
      color: '#9b59b6', // Purple
    },
    {
      iconName: 'verify',
      title: 'Aprobado con Pendiente',
      stateName: 'aprobadoConPendiente',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Aprobado por Administrador',
      stateName: 'aprobadoPorAdmin',
      color: '#2ecc71', // Green
    },
    {
      iconName: 'security-user',
      title: 'Historico',
      stateName: 'historico',
      color: '#2ecccc', // Green
    },
    {
      iconName: 'notepad-edit',
      title: 'Ficha Siendo Editada',
      stateName: 'fichaSiendoEditada',
      color: '#000000', // Purple
    },
    // {
    //   iconName: 'exclamation-triangle', // Adjust the icon name
    //   title: 'Proceso Anulado',
    //   stateName: 'procesoAnulado',
    //   color: '#e74c3c', // Red
    // },
    {
      iconName: 'eye',
      title: 'Ficha Observada',
      stateName: 'fichaObservada',
      color: '#d35400',
    },
  ]

  const [activeButtons, setActiveButtons] = useState(buttonsData.map(() => false))
  const [processData, setProcessData] = useState([])
  const [unidadData, setUnidadData] = useState([])
  // const [fichaData, setFichaData] = useState([])
  const [idproceso, setIdproceso] = useState('')
  const [idunidad, setIdunidad] = useState('')  
  const [idfichas, setIdfichas] = useState('')
  const [filtros, setFiltros] = useState<FiltroProceso[]>([])
  const usuarioJSON = localStorage.getItem('userData')
  const [options, setOptions] = useState<'equal' | 'distinct'>('equal')
  const [selectedOption, setSelectedOption] = useState('')
  const [filtro, setFiltro] = useState<FiltroState | null>(null)
  const selectRef = useRef<SelectInstance>(null)
  const {selectedCompanyId} = useAuth2()

  let usuario: DataUser | null = null

  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON)
  }

  const {
    activeOnly,
    setActiveOnly,
    state,
    setState,
    searchTerm,
    setSearchTerm,
    setSheetId,
    entryDateFrom,
    setEntryDateFrom,
    entryDateTo,
    setEntryDateTo,
    openFichaPlantillaModal,
    setShowCargaExcelModal,
    setProcessId,
    processId,
    setUnidadId,
    unidadId,
    setQuery,
    setFiltrosKeys,
    setFiltroData,
    filtroData,
    misUnidades,
    setMisUnidades,
    setCampoUnidad,
    campoUnidad,
    setFichaData,
    Dates,
    setDates
  } = useContext(PartnerContext)

  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()
  const location = useLocation()
  const { selectedRole } = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu = response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  useEffect(() => {
    const options = {
      responsables: usuario?.userId,
      estado: 'A'
    }

    Promise.all([
      getUnidadesEmpresas([String(selectedCompanyId)], String(usuario?.userId)),
      getProceso(1, 100, options),
    ]).then((responses) => {
      if (responses[0].success && responses[1].success && responses[1].data.length>0) {
        const misUnids = responses[0].data[0].unidad.filter((u: any) => u.selected)
        setMisUnidades(misUnids)
        setIdproceso(responses[1].data[0]._id)
        setProcessId(responses[1].data[0]._id)
        setIdunidad(misUnids[0].codUnidad)
        setUnidadId(misUnids[0].codUnidad)
        setProcessData(responses[1].data.map((x: any) => ({label: x.nombre, value: x._id})))
      }
    })

    // getUnidadesEmpresas([String(selectedCompanyId)], String(usuario?.userId))
    //   .then((response) => {
    //     if (response.success) {
    //       const misUnids = response.data[0].unidad.filter((u: any) => u.selected)
    //       setMisUnidades(misUnids)
    //     }
    //   })

    // getProceso(1, 100, options).then((response) => {
    //   if (response.success) {
    //     setIdproceso(response.data[0]._id)
    //     setProcessId(response.data[0]._id)
    //     setProcessData(response.data.map((x: any) => ({label: x.nombre, value: x._id})))
    //   } else {
    //     console.log(response.msg)
    //   }
    // })
  }, [])

  useEffect(() => {
    if (idproceso !== '') {
      getFichasxProceso(idproceso)
    }
  }, [idproceso])

  useEffect(() => {

    const filtrosByFicha = filtros.filter(
      (filtro) => filtro.idficha === idfichas && filtro.idproceso === idproceso
    )

    const misFiltros =
      filtrosByFicha.length > 0 ? filtrosByFicha[0].filtros[0].campos : ([] as Campo[])

    const filtrosKeys = misFiltros.map((f) => f.titulo.toLowerCase())

    const filtroData = misFiltros.map((f) => ({
      campo: f.titulo.toLowerCase(),
      isUnidad: (f.tabla?.toLowerCase() === 'unidades' && f.idtipo.nombre === 'Lista desplegable BD'),
      values:
        (f.tabla !== 'Unidades' && f.idtipo.nombre === 'Lista desplegable BD')
          ? f.opciones : undefined
    }))

    const campoUnidad = misFiltros.find(f => f.tabla === 'Unidades') ?? null

    setCampoUnidad(campoUnidad?.titulo ?? '')
    setFiltrosKeys(filtrosKeys)
    setFiltroData(filtroData)
    setFiltro(
      misFiltros[0] != null
        ? {
            titulo: misFiltros[0].titulo,
            tipo: misFiltros[0].idtipo,
            opciones: misFiltros[0].opciones,
          }
        : null
    )

  }, [filtros])

  useEffect(() => {
    if(filtro != null && options != null) {
      console.log(filtro, options, selectedOption)
      setQuery({
        match: options,
        campo: filtro.titulo,
        values: selectedOption
      })
    }
  }, [filtro, options, selectedOption])

  const getFichasxProceso = (value: string) => {
    fichasxid(value).then((response) => {
      console.log(response)
      if (response.success) {
        setIdfichas(response.data[0]?.idficha?._id)
        setSheetId(response.data[0]?.idficha?._id)
        setFichaData(
          response.data.map((x: any) => ({label: x.idficha?.nombre, value: x.idficha?._id}))
        )
        setFiltros(response.procesoFiltros ?? [])
      } else {
        console.log(response.msg)
      }
    })
  }

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }

  const handleInputChange = (event: any) => {
    setIdproceso(event.target.value)
    setProcessId(event.target.value)
    setQuery(null)
    setSelectedOption('')
    selectRef.current?.clearValue()
  }

  const handleInputChangeUnidad = (event: any) => {
    setIdunidad(event.target.value)
    setUnidadId(event.target.value)
    setQuery(null)
    setSelectedOption('')
    selectRef.current?.clearValue()
  }

  // const handleInputChangeFichas = (event: any) => {
  //   setIdfichas(event.target.value)
  //   setSheetId(event.target.value)
  //   setQuery(null)
  //   selectRef.current?.clearValue()
  // }

  const handleButtonClick = (index: number) => {
    const newActiveButtons = [...activeButtons]
    newActiveButtons[index] = !newActiveButtons[index]
    setActiveButtons(newActiveButtons)
    setState(
      buttonsData
        .map((x, i) => (newActiveButtons[i] ? x.stateName : ''))
        .filter((n) => n.length > 0)
    )
  }

  const handleChange = (e: any) => {
    // setFormData({...formData, [e.target.name]: e.target.value})
    setActiveOnly(e.target.value)
  }

  const handleStateChange = (e: any) => {
    setState(e.target.value)
  }

  const handleClearFilters = () => {
    // setActiveOnly(true)
    // setState([''])
    // setSearchTerm('')
    // setEntryDateFrom(null)
    // setEntryDateTo(null)
  }

  const handleExport = (id: any) => {
    const params = {
      activeOnly: activeOnly,
      sheetStates: state,
      searchQuery: searchTerm,
      entryDateFrom: entryDateFrom,
      entryDateTo: entryDateTo,
      processId: processId
    }

    setGlobalOverlayLoading(true)
    generateExcel(params)
      .then((response) => console.log(response))
      .catch((error) => console.log(error))
      .finally(() => setGlobalOverlayLoading(false))
  }

  const handleSimplifiedExport = (id: any) => {
    setGlobalOverlayLoading(true)
    generateSimplifiedExcel()
      .then((response) => console.log(response))
      .catch((error) => console.log(error))
      .finally(() => setGlobalOverlayLoading(false))
  }

  const handleShowFicha = () => {
    openFichaPlantillaModal()
  }
  const handleShowCargaExcel = () => {
    // setSheetId("647c0e1f0b0325fc1a8215f5")
    setShowCargaExcelModal(true)
  }

  const handleChangeOptions = (e: any) => {
    setOptions(e.target.value)
  }

  const handleChangeFilterType = (e: any) => {
    filtros.forEach((filtro) => {
      filtro.filtros.forEach((f) => {
        f.campos.forEach((c) => {
          if(c._id === e.target.value) {
            setFiltro({
              titulo: c.titulo,
              tipo: c.idtipo,
              opciones: c.opciones
            })
            selectRef.current?.clearValue()
          }
        })
      })
    })
  }

  const handleChangeOption = (e: any) => {
    console.log(e)
    if (Array.isArray(e)) {
      setSelectedOption(e.map((e) => e.value).join('@'))
    } else {
      setSelectedOption(e.value)
    }
  }

  const onChageText = useCallback(
    debounce((value: string) => {
      setSelectedOption(value)
    }, 500),
    []
  )

  const filtrosByFicha = filtros.filter(
    filtro => filtro.idficha === idfichas && filtro.idproceso === idproceso
  )

  const misFiltros = filtrosByFicha.length > 0 
    ? filtrosByFicha[0].filtros[0].campos 
    : ([] as Campo[])

  const optionsSelect = (filtro != null && filtro.opciones != null)
  ? filtroData.find(f => f.campo === filtro.titulo.toLowerCase())?.isUnidad
    ? misUnidades.map((u: any) => ({
        label: u.DesUnid,
        value: u.codUnidad
      }))
    : filtroData.find(f => f.campo === filtro.titulo.toLowerCase())?.values?.map((o: Record<string, string>) => ({
        label: o.name,
        value: o.id
      }))
  : []

  return (
    <>
      <div className='mx-8 mt-8 mb-2'>
        <Row className='mb-4'>
          <Col>
            <Stack direction='vertical' className='h-100'>
              <span className='fw-bold'>Buscar</span>
              <TableSearch onSearch={handleSearch} design={true} />
            </Stack>
          </Col>
          <Col>
            <Stack direction='vertical'>
              <span className='fw-bold'>Rangos de Fecha de ingreso</span>
              <DatePickerBusiness value={Dates} onChange={setDates} />
              {/* <Stack direction='horizontal' gap={3}>
                <Form.Control
                  type='date'
                  placeholder='Inicio'
                  onChange={(e) => setEntryDateFrom(e.target.value)}
                />
                <Form.Control
                  type='date'
                  placeholder='Fin'
                  onChange={(e) => setEntryDateTo(e.target.value)}
                />
              </Stack> */}
            </Stack>
          </Col>
        </Row>

        <Row className='align-items-center justify-content-between'>
          <Col md='6' sm='6'>
            <Dropdown autoClose={false} drop='end'>
              <Dropdown.Toggle variant='primary' id='dropdown-autoclose-false'>
                Filtros
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Stack direction='horizontal' gap={2}>

                  <Dropdown.Item>
                    {/* <Stack direction='horizontal' gap={1}>
                        <span className='fw-bold'>Estado:</span>
                        {buttonsData.map((button, index) => (
                          <a
                            key={index}
                            href='#'
                            className={`btn btn-icon btn-bg-light ${
                              activeButtons[index] ? 'btn-active-color-success' : ''
                            } btn-sm`}
                            title={button.title}
                            onClick={() => handleButtonClick(index)}
                          >
                            <KTIcon
                              iconName={button.iconName}
                              className={`fs-3 ${activeButtons[index] ? 'text-success' : ''}`}
                            />
                          </a>
                        ))}
                      </Stack> */}
                    <span className='fw-bold'>Estado del proceso:</span>
                    <Form.Control as='select' onChange={handleStateChange}>
                      <option value=''>Todos</option>
                      {buttonsData.map((option) => (
                        <option
                          key={option.iconName}
                          value={option.stateName}
                          style={{color: option.color}}
                        >
                          {option.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <span className='fw-bold'>Estado:</span>
                    <Form.Select onChange={handleChange}>
                      <option value='true'>Procesos activos</option>
                      <option value='false'>Procesos anulados</option>
                    </Form.Select>
                  </Dropdown.Item>

                </Stack>

                <Stack direction='horizontal' gap={2}>

                  <Dropdown.Item>
                    <span className='fw-bold'>Proceso:</span>
                    <select
                      className={'form-select form-select-solid}'}
                      aria-label='Select example'
                      value={idproceso}
                      name='idproceso'
                      onChange={(event) => handleInputChange(event)}
                    >
                      {processData.map((x: any) => (
                        <option value={x.value} key={x.value}>
                          {' '}
                          {String(x.label)}
                        </option>
                      ))}
                    </select>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <span className='fw-bold'>Unidad:</span>
                    <select
                      className={'form-select form-select-solid}'}
                      aria-label='Select example'
                      value={idunidad}
                      name='idunidad'
                      onChange={(event) => handleInputChangeUnidad(event)}
                    >
                      {misUnidades.map((x: any) => (
                        <option value={x.codUnidad} key={x.codUnidad}>
                          {' '}
                          {String(x.DesUnid)}
                        </option>
                      ))}
                    </select>
                  </Dropdown.Item>

                  {/* <Dropdown.Item>
                    <span className='fw-bold'>Ficha:</span>
                    <select
                      className={'form-select form-select-solid}'}
                      aria-label='Select example'
                      value={idfichas}
                      name='idfichas'
                      onChange={(event) => handleInputChangeFichas(event)}
                    >
                      {fichaData.map((x: any) => (
                        <option value={x.value} key={x.value}>
                          {' '}
                          {String(x.label)}
                        </option>
                      ))}
                    </select>
                  </Dropdown.Item> */}

                </Stack>

                {misFiltros.length > 0 && (
                  <>
                    <Stack direction='horizontal' gap={2}>

                      <Dropdown.Item>
                        <span className='fw-bold'>Personalizado:</span>
                        <Form.Select onChange={handleChangeFilterType}>
                          {misFiltros.map((filtro) => (
                            <option key={filtro._id} value={filtro._id} label={filtro.titulo} />
                          ))}
                        </Form.Select>
                      </Dropdown.Item>

                      <Dropdown.Item>
                        <Form.Select onChange={handleChangeOptions}>
                          <option value='equal'>Es</option>
                          <option value='distinct'>No es</option>
                        </Form.Select>
                      </Dropdown.Item>
                      
                    </Stack>

                    <Dropdown.Item>
                      {filtro != null && filtro?.tipo.nombre === 'Lista desplegable BD' ? (
                        <>
                          <Select
                            ref={selectRef}
                            onChange={handleChangeOption}
                            options={optionsSelect}
                            isMulti
                          />
                        </>
                      ) : (
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Buscar'
                          onChange={(e) => {onChageText(e.target.value)}}
                          // onChange={(e) => {setSelectedOption(e.target.value)}}
                        />
                      )}
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col md='auto'>
            <Stack direction='horizontal' gap={2}>
              <Stack direction='horizontal' gap={2}>
                {/* <button type='button' className='btn btn-light' onClick={handleClearFilters}>
                <KTIcon iconName='tablet-delete' className='fs-2' />
                Limpiar
              </button> */}
                {/* <button type='button' className='btn btn-primary' onClick={handleExport}>
                <KTIcon iconName='file-down' className='fs-2' />
                Exportar
              </button> */}
                <Dropdown>
                {permissions.includes('Exportar') && (
                  <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                    <KTIcon iconName='file-down' className='fs-2' />
                    Exportar
                  </Dropdown.Toggle>
                )}
                  <Dropdown.Menu>
                    <Dropdown.Item href='#' onClick={handleSimplifiedExport}>
                      Reporte simple
                    </Dropdown.Item>
                    <Dropdown.Item href='#' onClick={handleExport}>
                      Reporte detallado
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Stack>

              <Stack direction='horizontal' gap={2}>
                <Dropdown>
                {permissions.includes('Carga Masiva Colaboradores') && (
                  <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                    <KTIcon iconName='file-down' className='fs-2' />
                    Carga Masiva Colaboradores
                  </Dropdown.Toggle>
                )}
                  <Dropdown.Menu>
                    <Dropdown.Item href='#' onClick={handleShowFicha}>
                      Exportar Plantilla
                    </Dropdown.Item>
                    <Dropdown.Item href='#' onClick={handleShowCargaExcel}>
                      Cargar Plantilla
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Stack>
            </Stack>
          </Col>
        </Row>
      </div>
    </>
  )
}

export { PartnersListHeader }

