import {BlobReader, BlobWriter, ZipReader, ZipWriter} from '@zip.js/zip.js'
import JSZip from 'jszip'
import {showMessage} from './Alerts'

export const mimeTypes: Record<string, string> = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  jfif: 'image/jpeg',
  gif: 'image/gif',
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export function transformDependentType(param: string): string | string[] {
  console.log("param")
  console.log(param)
  const parts = param.split('@')

  if (parts.length === 1) {
    return param
  } else {
    return parts
  }
}

export async function UnzipFiles2(
  zipFiles: File[],
  allowedMimeTypes?: string[],
  startName: boolean = false
) {
  const uZip = new JSZip()
  try {
    const unzippedFiles = await Promise.all(
      zipFiles.map(async (zipFile) => {
        const zip = await uZip.loadAsync(zipFile, {createFolders: true})
        const files = zip.files
        console.log(files)
        const filePromises = Object.values(files).map(async (file) => {
          if (!file.dir) {
            const zipName = zipFile.name.split('_').shift()
            const start = startName ? `${zipName}_` : ''
            const ext = file.name.split('.').pop() ?? ''
            const type = mimeTypes[ext] ?? ''
            const content = await file.async('blob')

            if (type) {
              return new File([content], start + file.name.replaceAll('/', '_'), {type})
            }

            return new File([content], start + file.name.replaceAll('/', '_'))
          }
        })

        return Promise.all(filePromises)
      })
    )

    const unzippedFilesArray = unzippedFiles.flat()

    if (allowedMimeTypes) {
      const unzippedFilesFiltered = unzippedFilesArray.filter((file) => {
        const mimeType = file?.type
        return allowedMimeTypes.includes(mimeType ?? '')
      })

      return unzippedFilesFiltered
    }

    return unzippedFilesArray
  } catch (error) {
    const messages = `Revise los siguientes zips: ${zipFiles
      .map((zipFile) => zipFile.name)
      .join(', ')}`
    showMessage('error', 'Hubo un error', (error as Error).message + ' - ' + messages)
    return []
  }
}

export const UnzipFiles = async (
  zipFiles: File[],
  allowedMimeTypes?: string[],
  startName = false
) => {
  const unzippedFilesPromises = zipFiles.map(async (zipFile) => {
    const entries = await new ZipReader(new BlobReader(zipFile)).getEntries()

    return await Promise.all(
      entries.map(async (file) => {
        if (file.getData && !file.directory) {
          const zipName = zipFile.name.split('_').shift()
          const start = startName ? `${zipName}_` : ''
          const ext = file.filename.split('.').pop() ?? ''
          const type = mimeTypes[ext] ?? ''
          const data = await file.getData(new BlobWriter())

          if (type) {
            return new File([data], start + file.filename.replaceAll('/', '_'), {type})
          } else {
            return new File([data], start + file.filename.replaceAll('/', '_'))
          }
        }
      })
    )
  })

  const unzippedFiles = await Promise.all(unzippedFilesPromises)
  const files = unzippedFiles.flat()

  if (allowedMimeTypes) {
    const unzippedFilesFiltered = files.filter((file) => {
      const mimeType = file?.type ?? ''
      return allowedMimeTypes.includes(mimeType)
    })

    return unzippedFilesFiltered
  }

  return files
}

export const getFilesWhenStartsWith = (files: File[], startName: string) => {
  return files
    .map((file, idx) => ({file: file, index: idx}))
    .filter((file) => file.file.name.startsWith(startName))
}

export const createZipFile = async (files: File[]) => {
  const zipWriter = new ZipWriter(new BlobWriter('application/zip'), {bufferedWrite: true})

  files.forEach((file) => {
    zipWriter.add(file.name, new BlobReader(file))
  })

  return await zipWriter.close()
}
