import { fetchConToken, fetchSinToken } from "../helpers/fetch";

const get_Tables = async () => {
  const resp = await fetchConToken(
    `campo/table/get_tables`
  );
  const body = await resp.json();

  return body;
};

const campos = async () => {
  const resp = await fetchSinToken(
    `campo/`
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const camposxseccion = async (id) => {
  const resp = await fetchSinToken(
    `campo/camposxseccion/`,
    { idseccion: id },
    "POST"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const nuevoCampo = async (data) => {
  const resp = await fetchSinToken(
    `campo/add`,
    data,
    "POST"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const eliminarCampo = async (id) => {
  const resp = await fetchSinToken(
    `campo/${id}`,
    {},
    "DELETE"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const actualizarCampo = async (data, id) => {
  const resp = await fetchSinToken(
    `campo/editar/${id}`,
    data,
    "PUT"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const ordenarCampos = async (idcampo, orden) => {
  const resp = await fetchSinToken(
    `campo/ordenar`,
    { idcampo, orden },
    "POST"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const cantidadCampos = async (id) => {
  const resp = await fetchSinToken(
    `campo/cantidadcampos/`,
    { idseccion: id },
    "POST"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const getFieldsBySheetDropdown = async (id) => {
  const resp = await fetchSinToken(
    `campo/getFieldsBySheetDropdown/${id}`
  );
  const body = await resp.json();

  return body;
};

const getFieldById = async (id) => {
  const resp = await fetchSinToken(
    `campo/getField/${id}`
  );
  const body = await resp.json();

  return body;
}

const actualizarCampoCheckExcel = async (data) => {
  const resp = await fetchSinToken(
    `campo/editar/check/masivo`,
    data,
    "PUT"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const actualizarCampoCheckIntegracion = async (data) => {
  const resp = await fetchSinToken(
    `campo/editar/checkintegracion/masivo`,
    data,
    "PUT"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};


const actualizarCampoTagIntegracion = async (data) => {
  const resp = await fetchSinToken(
    `campo/editar/tagintegracion/masivo`,
    data,
    "PUT"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

export {
  campos,
  nuevoCampo,
  camposxseccion,
  eliminarCampo,
  actualizarCampo,
  ordenarCampos,
  cantidadCampos,
  get_Tables,
  getFieldsBySheetDropdown,
  getFieldById,
  actualizarCampoCheckExcel,
  actualizarCampoCheckIntegracion,
  actualizarCampoTagIntegracion
};