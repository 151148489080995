import { Badge, Button, Col, Container, Form, Row, Table } from 'react-bootstrap'

import { useContext, useEffect, useState } from 'react'
import { DocumentTrayContext } from '../DocumentTrayContexts'
import PDFViewer from './PDFViewer'

const PreviewDocumentModalForm = () => {

  const { closePreviewDocumentModal, setFromPreviewTab } = useContext(DocumentTrayContext)

  return (
    <>
      <div>
        <Container>
          <Row className='mb-3'>
            <Col sm>
              <PDFViewer />
            </Col>
          </Row>
          <Row>
            <div className='d-flex justify-content-end'>
              <Button
                variant='light'
                // className='mx-3'
                type='reset'
                onClick={() => {
                  closePreviewDocumentModal()
                  setFromPreviewTab({
                    showSignature: false,
                    signCeo: false,
                    idUserSign: ''
                  })
                }}
              >
                Cerrar
              </Button>
            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}

export { PreviewDocumentModalForm }
