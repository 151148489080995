import { useAuth2 } from "../authv2/Auth2Provider"
import { PoliticaYPrivacidadSelectorFormWrapper } from "./PoliticaYPrivacidadSelectorFormWrapper"
import { PoliticaYPrivacidadSelectorModalHeader } from "./PoliticaYPrivacidadSelectorModalHeader"

const PoliticaYPrivacidadSelectorModal = () => {
  const {userData, isPoliticaYPrivacidadSelectorModalOpen, setPoliticaYPrivacidadSelectorModalStatus} = useAuth2()


  const onClose = () => {
    setPoliticaYPrivacidadSelectorModalStatus(false)
  }

  return (
    <>
      {isPoliticaYPrivacidadSelectorModalOpen && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              <div className='modal-dialog modal-xl modal-dialog-centered mw-400px'>
                <div className='modal-content'>
                  <PoliticaYPrivacidadSelectorModalHeader />
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <PoliticaYPrivacidadSelectorFormWrapper userData={userData} onClose={() => onClose()} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  )
}

export {PoliticaYPrivacidadSelectorModal}
