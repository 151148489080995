import { createContext } from "react"
import { Field, initialProcessFiles, Proceso, ProcessFiles, ProcessSheet, Section, Sheet } from "./documentInterface"

interface PartnerContextProps {
    searchTerm: string,
    setSearchTerm: (value: string) => void,
    searchDocument: string,
    setSearchDocument: (value: string) => void,
    process_id: string,
    setProcessId: (value: string) => void,
    procesoData:Proceso,
    setProcesoData: (value: Proceso) => void,
    processSheetData: ProcessSheet[],
    setProcessSheetData: (value: ProcessSheet[]) => void,
    sheetData: Sheet[],
    setSheetData: (value: Sheet[]) => void,
    sectionData: Section[],
    setSectionData: (value: Section[]) => void,
    fieldData: Field[],
    setFieldData: (value: Field[]) => void,
    isTagsModalOpen: boolean,
    openTagsModal: () => void,
    closeTagsModal: () => void,
    fileData:ProcessFiles,
    setFileData: (value: ProcessFiles) => void,
    isEditFileModalOpen: boolean,
    openEditFileModal: () => void,
    closeEditFileModal: () => void,
    updateFile: boolean,
    setUpdateFile: (value: boolean) => void,
    documents: {},
    setDocuments: (value: {}) => void,
    documentsAux: {},
    setDocumentsAux: (value: {}) => void,
}

const PartnerContext = createContext<PartnerContextProps>({
    searchTerm: '',
    setSearchTerm: (value) => {},
    searchDocument: '',
    setSearchDocument: (value) => {},
    process_id: '',
    setProcessId: (value) => {},
    procesoData:{ _id:'', nombre: '', codigo: '', descripcion: '', estado:'' },
    setProcesoData: (value) => {},
    processSheetData: [],
    setProcessSheetData: (value) => {},
    sheetData: [],
    setSheetData: (value) => {},
    sectionData: [],
    setSectionData: (value) => {},
    fieldData: [],
    setFieldData: (value) => {},
    isTagsModalOpen: false,
    openTagsModal: () => {},
    closeTagsModal: () => {},
    fileData:initialProcessFiles,
    setFileData: (value) => {},
    isEditFileModalOpen: false,
    openEditFileModal: () => {},
    closeEditFileModal: () => {},
    updateFile: false,
    setUpdateFile: (value:boolean) => {},
    documents: {},
    setDocuments: (value) => {},
    documentsAux: {},
    setDocumentsAux: (value) => {},

})

export {PartnerContext}