import React, {useState} from 'react'
import {Pagination} from 'react-bootstrap'

interface Props {
  totalPages: number
  currentPage: number
  onPageChange: (page: number) => void
  isLoading: boolean
  siblings?: number
	divideSiblingsFrom?: number
}

const ModernPagination: React.FC<Props> = ({
  totalPages,
  currentPage,
  onPageChange,
  isLoading,
  siblings = 5,
	divideSiblingsFrom = 10
}) => {
  const [pageSiblings, setPageSiblings] = useState(0)
  const totalSiblingPages = Math.ceil(totalPages / siblings) - 1
  const fullpages = Array.from({length: totalPages}, (_, i) => i + 1)
  const divideWhen = fullpages.length >= divideSiblingsFrom

  const gap = totalPages - (pageSiblings + 1) * siblings

  const extraSiblings = pageSiblings > 0 ? 3 : 2
  const siblingsLength = siblings + extraSiblings
  const isLastPageSibling = totalSiblingPages === pageSiblings || gap === 0 || gap === 1
  const startSiblingFrom = pageSiblings * siblings + 1
  const lastLength = fullpages.length - startSiblingFrom + 1

  const pages = divideWhen
    ? Array.from({length: isLastPageSibling ? lastLength : siblingsLength}, (_, i) =>
        i === siblingsLength - 1 ? fullpages.length : i + 1 + siblings * pageSiblings
      )
    : fullpages

  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === totalPages
  const preLastPage = siblings * (pageSiblings + 1) + 1
  const isNotLastPageSibling = !isLastPageSibling

  return (
    <Pagination>
      <Pagination.First
        disabled={isFirstPage || isLoading}
        title='Primera página'
        onClick={() => {
          setPageSiblings(0)
          onPageChange(1)
        }}
      />
      <Pagination.Prev
        disabled={isFirstPage || isLoading}
        title='Anterior'
        onClick={() => {
          if (currentPage === siblings * (pageSiblings + 1) - siblings + 1) {
            setPageSiblings((prev) => prev - 1)
          }
          onPageChange(currentPage - 1)
        }}
      />
      {pages.map((page, index) => {
        if (divideWhen && page === preLastPage && isNotLastPageSibling) {
          return (
            <Pagination.Ellipsis
              key={page}
              onClick={() => setPageSiblings((prev) => prev + 1)}
              disabled={isLoading}
            />
          )
        }

        if (divideWhen && pageSiblings > 0 && index === 0) {
          return (
            <React.Fragment key={page}>
              <Pagination.Ellipsis
                onClick={() => setPageSiblings((prev) => prev - 1)}
                disabled={isLoading}
              />
              <Pagination.Item
                active={page === currentPage}
                disabled={isLoading}
                onClick={() => {
                  if (page === currentPage) return
                  onPageChange(page)
                }}
              >
                {page}
              </Pagination.Item>
            </React.Fragment>
          )
        }

        if (divideWhen && pageSiblings > 0 && index === siblingsLength - 2) {
          return null
        }

        return (
          <Pagination.Item
            key={page}
            active={page === currentPage}
            disabled={isLoading}
            onClick={() => {
              if (page === currentPage) return
              onPageChange(page)
            }}
          >
            {page}
          </Pagination.Item>
        )
      })}
      <Pagination.Next
        disabled={isLastPage || isLoading}
        title='Siguiente'
        onClick={() => {
          if (currentPage === siblings * (pageSiblings + 1) && isNotLastPageSibling) {
            setPageSiblings((prev) => prev + 1)
          }
          onPageChange(currentPage + 1)
        }}
      />
      <Pagination.Last
        disabled={isLastPage || isLoading}
        title='Última página'
        onClick={() => {
          setPageSiblings(totalSiblingPages)
          onPageChange(totalPages)
        }}
      />
    </Pagination>
  )
}

export {ModernPagination}
