import { Col, Container, Form, Row, Stack } from 'react-bootstrap'
import { KTIcon } from '../../../../../_metronic/helpers'
import { useContext, useEffect, useState } from 'react'
import { PartnerContext } from '../PartnerContexts'
import { useDebounce } from 'use-debounce'
import { TableSearch } from '../../../../shared/TableSearch'
import { generateExcel, generateSimplifiedExcel } from '../../../../services/fichas'
import { getAllPartnersPag } from '../../../../services/colaboradores'
import * as excelJs from 'exceljs';
import { formatDateToDMY, formatISODateToDMYHM } from '../../../../helpers/dateFunctions'
import { getAllUsersPag } from '../../../../services/users'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import { useGlobalOverlayLoading } from '../../../../shared/hooks/useGlobalOverlayLoading'

const SolicitudesListHeader = () => {
  const buttonsData = [
    {
      iconName: 'verify',
      title: 'Borrador',
      stateName: 'borrador',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Completado',
      stateName: 'completado',
      color: '#2ecc71', // Green
    }
  ]

  const [activeButtons, setActiveButtons] = useState(buttonsData.map(() => false))
  const { userData, selectedCompanyId } = useAuth2()
  const { setActiveOnly, setState, setSearchTerm, setEntryDateFrom, setEntryDateTo, activeOnly, state, searchTerm, entryDateFrom, entryDateTo } =
    useContext(PartnerContext)
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }

  const handleChange = (e: any) => {
    // setFormData({...formData, [e.target.name]: e.target.value})
    setActiveOnly(e.target.value)
  }

  return (
    <>
      <div className='mx-8 mt-8 mb-2'>
        <Row>
          {activeOnly === "APROBADO" && 
            <Col>
              <span className='fw-bold'>Buscar</span>
              <Stack className=' mt-3' direction='horizontal' gap={3}>
                <TableSearch onSearch={handleSearch} design={true} />
              </Stack>
            </Col>
          }
          {/* <Col>
            <span className='fw-bold'>Proceso</span>
            <Form.Select className=' mt-3'>
              <option value='1'>OnBoarding ed</option>
            </Form.Select>
          </Col> */}
          <Col>
            <Stack direction='vertical'>
              <span className='fw-bold'>Estado</span>
              <Stack className=' mt-3' direction='horizontal' gap={3}>
                <Form.Select onChange={handleChange}>
                  <option value='PENDIENTE'>PENDIENTE</option>
                  <option value='APROBADO'>APROBADO</option>
                </Form.Select>
              </Stack>
            </Stack>
          </Col>
          <Col>
            <Stack direction='vertical'>

              <Stack className=' mt-3' direction='horizontal' gap={3}>
              </Stack>
            </Stack>
          </Col>
        </Row>
      </div>
    </>
  )
}

export { SolicitudesListHeader }
