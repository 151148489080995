import {useContext, useEffect, useState} from 'react'
import {KTCard, KTIcon} from '../../../../../../_metronic/helpers'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {Form, Row, Col, Button, Container, Stack} from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ColaboradorObservacionContext } from './ColaboradorObservacionContexts'
import { getRegistroFichaOne, nuevaObservacionFicha, nuevoRegistroFichaTotal } from '../../../../../services/registroFicha'
import { Campos, DataTabla, Ficha, Historial, ProcesoFichas, Seccion, Tabla, tablaData } from '../../../../documentacion/MiFicha/FIchaInterface'
import { DraggableSectionList } from '../DraggableSectionList'
import { ChatInner } from '../ChatInner'
import { HistorialMessageModel,defaultMessages } from './HistorialMessageModel'
import { DataUser } from '../../../../documentacion/MiFicha/DataUserInterface'
import { showMessage, showResponseObservacionMessage } from '../../../../../shared/Alerts';
import { parseISO, format } from 'date-fns';
import { useAuth2 } from '../../../../authv2/Auth2Provider'
import { Doc } from '../../../enviodocumento/enviodocumentInterface'
import { GoBackButton } from '../../../../generales_component/GoBackButton'

const fichasBreadcrumbs = [
  {
    title: 'Configuración',
    path: '/seguimiento/colaboradores',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const ColaboradorObservacion = () => {
  const navigate = useNavigate()
  const {id,namecolaborador} = useParams()

  const [formLoading, setFormLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [validated, setValidated] = useState(false);
  const [title, setTitle] = useState('Observación');
  const [procesoFichaData, setProcesoFichaData] = useState<ProcesoFichas[]>([])
  const [historialData, setHistorialData] = useState<Historial[]>([])
  const [fichas, setFichas] = useState<Ficha[]>([])
  const [seccion, setSeccion] = useState<Seccion[]>([])
  const [campos, setCampos] = useState<Campos[]>([])
  const [secciones, setSecciones] = useState<Tabla[]>([])
  const [dataSecciones, setDataSecciones] = useState<DataTabla[]>([])
  const [historialMessage, setHistorialMessage] = useState<HistorialMessageModel[]>([])
  const [message, setMessage] = useState<string>('')
  const [idUsuarioRegistro, setIdUsuarioRegistro] = useState<string>('')
  const [showFileModal, setShowFileModal] = useState(false)
  const [viewDocumento, setViewDocumento] = useState<Doc[]>([])
  const [cargarDocumentModal, setCargarDocumentModal] = useState(false)
  const [filtro, setFiltro] = useState<Record<string, any> | null>(null)
  const [unidad, setUnidad] = useState<Record<string, any> | null>(null)
  
  const openCargarDocumentModal = () => {
    setCargarDocumentModal(true)
  }

  const closeCargarDocumentModal = () => {
    setCargarDocumentModal(false)
  }

  const usuarioJSON = localStorage.getItem('userData');
  let usuario: DataUser|null=null;
  
  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON);
  }
  const idusuario = usuario?.userId
  const nameusuario = usuario?.userName

  const { selectedRole } = useAuth2()
  const rolusuario = selectedRole


  useEffect(() => {
    if (id) {
      setTitle('Observación')
      refetchData(id)
    }
  }, [])

  const refetchData = (idRegistroFicha:string) => {
    setFormLoading(true)
    getRegistroFichaOne(idRegistroFicha)
      .then((response) => {
        if (response.success) {
          setFormLoading(false)
          
            const dataWithValuesAndFiles:ProcesoFichas[] = response?.data[0]?.procesoficha.map((procesoFicha:ProcesoFichas, item:any) => ({
              ...procesoFicha,
              fichas:procesoFicha.fichas.map((ficha:Ficha) => ({ 
                ...ficha,
                seccion:ficha.seccion.map((seccion:Seccion) => ({
                  ...seccion,
                  campos: seccion.campos.map((campo:Campos) => ({
                    ...campo,
                    values: campo.values?campo.values:'',
                    observacion: campo.observacion?campo.observacion:{},
                    observado: campo.observado?campo.observado:false,                                     
                    valuesFiles: campo.valuesFiles?campo.valuesFiles:[],
                    selectOpion:campo.selectOpion?campo.selectOpion:null,
                    hasError: campo.hasError?campo.hasError:false,
                    dependiente: campo.dependiente?campo.dependiente:false,
                    campoDependiente: campo.campoDependiente?campo.campoDependiente:"",
                    respuestaCampoDependiente: campo.respuestaCampoDependiente?campo.respuestaCampoDependiente:"",
                    mostrar: campo.mostrar?campo.mostrar:campo.dependiente?false:true,
                    tablaData: campo.tablaData.map((tablaData:tablaData) => ({
                      ...tablaData,
                      option: tablaData.value.map((val:any) => ({
                        value: val.id?val.id:'',
                        label: val.name?val.name:''
                      }))
                    }))
                  })),
                  
                  secciones:seccion.secciones?seccion.secciones.map((tabla:Tabla) => ({
                    ...tabla,
                    data: tabla.data.map((dattabla:DataTabla) => ({
                      ...dattabla,
                      values: dattabla.values?dattabla.values:'',
                      valuesFiles: dattabla.valuesFiles?dattabla.valuesFiles:[],
                      selectOpion:dattabla.selectOpion?dattabla.selectOpion:null,
                      hasError: dattabla.hasError?dattabla.hasError:false,
                      tablaData: dattabla.tablaData? dattabla.tablaData.map((dat:tablaData) => ({
                        ...dat,
                        option: dat.value.map((val:any) => ({
                          value: val.id?val.id:'',
                          label: val.name?val.name:''
                        }))
                      })) : []
                    }))
                  })):[],


                })),
              }))
              
            }));

            setIdUsuarioRegistro(response?.data[0]?.id_user_creador)
            
            const historial:Historial[] = response?.data[0]?.historial
            setFiltro(response?.data[0]?.filtro ?? null)
            setUnidad(response?.data[0]?.unidad ?? null)
            setHistorialData(historial)
            setProcesoFichaData(dataWithValuesAndFiles)

            const historialMensaje : HistorialMessageModel[] = []
            response?.data[0]?.historial?.map((dat:Historial) => {
              if(dat.motivo === 'OBSERVADO'  && dat.data){
                historialMensaje.push(dat.data)
              }
            })
            setHistorialMessage(historialMensaje)
          
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const goBack = () => {
    const link = selectedRole === 'POST' ? '/documentos/proceso' : '/seguimiento/colaboradores'
    navigate(link)
  }

  const handleFirstSubmit = (e:any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }else{
      handleSubmit(e,'fichaObservada')
    }
    setLoading(true)
    setValidated(true)
  };

  const handleSecondSubmit = (e:any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }else{
      handleSubmit(e,'aprobadoConPendiente')
    }

    setLoading2(true)
    setValidated(true)
  };

  
  const handleThreeSubmit = (e:any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }else{
      handleSubmit(e,'enviarRespuesta')
    }

    setLoading3(true)
    setValidated(true)
  };


  const handleSubmit = (e: any,estado:string) => {
    e.preventDefault()

    handleUpdateSheet(procesoFichaData,historialData,estado)

  }

  const handleUpdateSheet = (dataProcesos: ProcesoFichas[], dataHistorial: Historial[], estado:string) => {
    
    dataHistorial.push({
        createdAt: new Date(),
        motivo: 'OBSERVADO',
        id_user_creador: String(idusuario),
        id_user_receptor: String(idUsuarioRegistro),
        data:{
          nameuser: String(nameusuario),
          iduser: String(idusuario),
          text: message?message:estado==='fichaObservada'?defaultMessages[0].text:defaultMessages[1].text,
          fecha: new Date()
        },
        camposModificados:[]
    })

    const camposObservados: { [ficha: string]:{[seccion: string]:any[]}  } = {};
    const updatedFormData = dataProcesos.map((procesoFicha) => {
      procesoFicha.fichas.map((ficha) => {
        if (!camposObservados[ficha.nombre]) {
          camposObservados[ficha.nombre] = {};
        }
        ficha.seccion.map((seccion) => {
          if (!camposObservados[ficha.nombre][seccion.nombre]) {
            camposObservados[ficha.nombre][seccion.nombre] = [];
          }
          seccion.campos.map((cam) => {
            if(!seccion.dinamico){
              if (cam.observado) {            
                camposObservados[ficha.nombre][seccion.nombre].push({titulo:cam.titulo, orden:cam.orden})
              } 
            }            
            return cam;
          })
        })
      })
    });

    
    const camposObservadosNew: { [ficha: string]:string  } = {};
    for (const key of Object.keys(camposObservados)) {
      let cont = 0;
      for (const key1 of Object.keys(camposObservados[key])) { 
        if(camposObservados[key][key1].length > 0){
          if (!camposObservadosNew[key]) {
            camposObservadosNew[key] = "";
          }
          cont = cont + camposObservados[key][key1].length
        }
      }
      if(cont>0){
        if (!camposObservadosNew[key]) {
          camposObservadosNew[key] = String(cont);
        }
      }
      
    }

    if(estado === 'enviarRespuesta'){
      const data = {
        _id:id,
        id_user_creador:idUsuarioRegistro,
        procesoficha:dataProcesos,
        historial:dataHistorial,
        rol: rolusuario,
        vista:"observaciones",
        filtro,
        unidad
      }
      nuevaObservacionFicha(data)
      .then((response) => {
        if (response?.success) {  
          setLoading3(false)          
          const historialMensaje : HistorialMessageModel[] = []
          response.data.historial.map((dat:Historial) => {              
              if(dat.motivo === 'OBSERVADO' && dat.data){
                historialMensaje.push(dat.data)
              }
          })
          setMessage('')
          setHistorialMessage(historialMensaje)
          showMessage('success', '¡Buen trabajo!', 'Se actualizo la información correctamente')  
          
        } else {
          setLoading3(false)
          showMessage('error', 'Hubo un error', response.message)
          console.log(response?.message)
        }
      })
      .catch((error) => {
        setLoading3(false)
        showMessage('error', 'Hubo un error', error)
        console.log(error)
      })
    }else{
      const data = {
        _id:id,
        id_user_creador:idUsuarioRegistro,
        estado:estado,
        procesoficha:dataProcesos,
        historial:dataHistorial,
        camposObservados:camposObservadosNew,
        rol: rolusuario,
        vista:"observaciones",
        filtro,
        unidad
      }

      nuevoRegistroFichaTotal(data)
      .then((response) => {
        if (response?.success) {  
          if(estado === 'fichaObservada'){
            setLoading(false)
          }else if(estado === 'aprobadoConPendiente'){
            setLoading2(false)
          }
          const historialMensaje : HistorialMessageModel[] = []
          response.data.historial.map((dat:Historial) => {              
              if(dat.motivo === 'OBSERVADO' && dat.data){
                historialMensaje.push(dat.data)
              }
          })
          setMessage('')
          setHistorialMessage(historialMensaje)
          showMessage('success', '¡Buen trabajo!', 'Se actualizo la información correctamente')  
          
        } else {
          
          if(estado === 'fichaObservada'){
            setLoading(false)
          }else if(estado === 'aprobadoConPendiente'){
            setLoading2(false)
          }
          showMessage('error', 'Hubo un error', response.message)
          console.log(response?.message)
        }
      })
      .catch((error) => {
        
        if(estado === 'fichaObservada'){
          setLoading(false)
        }else if(estado === 'aprobadoConPendiente'){
          setLoading2(false)
        }
        showMessage('error', 'Hubo un error', error)
        console.log(error)
      })
    }
    

    
  }

  const handleInputObservacionChange = (indProcesoFicha:number,indSeccion:number,newSeccion:Seccion) => {    

  }

  const handleUpdateSeccion = (ind:number,updatedData:Seccion) => {
    let indSeccion = 0
    const newProcesoFichas = [...procesoFichaData];
      newProcesoFichas[ind].fichas[0].seccion.map((seccion,ind) => {
          if (seccion._id === updatedData._id) {
            indSeccion = ind        
          } 
      })
      newProcesoFichas[ind].fichas[0].seccion[indSeccion] = updatedData
      setProcesoFichaData(newProcesoFichas)
  };

  const valueContextProvider = {
    procesoFichaData,
    setProcesoFichaData,
    fichas,
    setFichas,
    seccion,
    setSeccion,
    campos,
    setCampos,
    secciones,
    setSecciones,
    dataSecciones,
    setDataSecciones,
    message,
    setMessage,
    handleInputObservacionChange,
    showFileModal,
    setShowFileModal,
    viewDocumento,
    setViewDocumento,
    cargarDocumentModal,
    openCargarDocumentModal,
    closeCargarDocumentModal
  } 



  return (
    <ColaboradorObservacionContext.Provider value={valueContextProvider}>
      <PageTitle breadcrumbs={fichasBreadcrumbs}>{title}</PageTitle>
      <GoBackButton goBack={goBack} />
      <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
        <KTCard>
          {formLoading ? (
            <div className='pt-12 pb-6'>
              <Container>
                <Row className='mb-3'>
                  <Col sm>
                    <Stack gap={2}>
                      <Skeleton height={20} width={52} duration={1} style={{borderRadius: '5px'}} />
                      <Skeleton height={44} duration={1} style={{borderRadius: '10px'}} />
                    </Stack>
                  </Col>
                  <Col sm>
                    <Stack gap={2}>
                      <Skeleton height={20} width={52} duration={1} style={{borderRadius: '5px'}} />
                      <Skeleton height={44} duration={1} style={{borderRadius: '10px'}} />
                    </Stack>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col sm>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex align-items-end'>
                        <Skeleton
                          height={20}
                          width={139}
                          duration={1}
                          style={{borderRadius: '5px'}}
                        />
                      </div>
                      <Skeleton
                        height={44}
                        width={120}
                        duration={1}
                        style={{borderRadius: '10px'}}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className='mb-6'>
                  <Col sm>
                    <Skeleton height={195} duration={1} style={{borderRadius: '10px'}} />
                  </Col>
                </Row>

                <Row>
                  <div className='d-flex justify-content-end'>
                    <Skeleton
                      height={44}
                      width={102}
                      duration={1}
                      className='mx-3'
                      style={{borderRadius: '10px'}}
                    />
                    <Skeleton height={44} width={96} duration={1} style={{borderRadius: '10px'}} />
                  </div>
                </Row>
              </Container>
            </div>
          ) : (
            <>
              <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                {procesoFichaData.map((procesoficha, key) => (
                  <li className='nav-item'>
                    <a
                      className={`nav-link ${key === 0 ? 'active' : ''}`}
                      data-bs-toggle='tab'
                      href={`#kt_tab_pane_${key}`}
                    >
                      {procesoficha.fichas[0].nombre}
                    </a>
                  </li>
                ))}
              </ul>
              <div className='tab-content' id='myTabContent'>
                {procesoFichaData.map((procesoficha, key) => (
                  <div
                    className={`tab-pane fade ${key === 0 ? 'show active' : ''}`}
                    id={`kt_tab_pane_${key}`}
                    role='tabpanel'
                  >
                    <Form className='pt-12 pb-6'>
                      <Container>
                        <Row className='mb-3'>
                          <Col sm>
                            <Form.Group controlId='formNombre'>
                              <Form.Label>
                                <b>Nombre Ficha</b>
                              </Form.Label>
                              <br></br>
                              <Form.Label>{procesoficha.fichas?.[0]?.nombre}</Form.Label>
                            </Form.Group>
                          </Col>

                          <Col sm>
                            <Form.Group controlId='formDescripcion'>
                              <Form.Label>
                                <b>Descripción Ficha</b>
                              </Form.Label>
                              <Form.Label>{procesoficha.fichas?.[0]?.descripcion}</Form.Label>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className='mb-3'>
                          <Col sm>
                            <div className='d-flex justify-content-between'>
                              <div className='d-flex align-items-end'>
                                <Form.Label>
                                  <b>Listado de Secciones</b>
                                </Form.Label>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row className='mb-6'>
                          <Col sm>
                            <DraggableSectionList
                              key={procesoficha._id}
                              procesoficha={procesoficha}
                              ind={key}
                              onUpdate={(updatedData) => handleUpdateSeccion(key, updatedData)}
                            />
                          </Col>
                        </Row>

                        <div className='flex-lg-row-fluid '>
                          <div className='card' id='kt_chat_messenger'>
                            <div className='card-header bg-secondary' id='kt_chat_messenger_header'>
                              <div className='card-title'>
                                <div className='symbol-group symbol-hover'></div>
                                <div className='d-flex justify-content-center flex-column me-3'>
                                  <a href='#' className='fs-4 fw-bolder  me-1 mb-2 lh-1'>
                                    Historial Observación
                                  </a>

                                  <div className='mb-0 lh-1'>
                                    <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                                    <span className='fs-7 fw-bold text-gray-400'>
                                      {namecolaborador}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className='card-toolbar'></div>
                            </div>
                            <ChatInner
                              idUsuario={String(idusuario)}
                              idNameUsuario={String(nameusuario)}
                              historialMessage={historialMessage}
                            />
                          </div>
                        </div>

                        <Row>
                          <div className='d-flex justify-content-end mt-4'>
                            <Button
                              variant='light'
                              className='mx-3'
                              type='reset'
                              onClick={() => {}}
                            >
                              Cancelar
                            </Button>
                            <Button
                              variant='danger'
                              type='submit'
                              onClick={handleFirstSubmit}
                              className='mx-3'
                            >
                              {!loading && 'Observar'}
                              {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                  Guardando...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </Button>
                            <Button
                              variant='warning'
                              type='submit'
                              onClick={handleSecondSubmit}
                              className='mx-3'
                            >
                              {!loading2 && 'Aprobar con Pendiente'}
                              {loading2 && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                  Guardando...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </Button>
                            <Button
                              variant='primary'
                              type='submit'
                              onClick={handleThreeSubmit}
                              className='mx-3'
                            >
                              {!loading3 && 'Enviar Respuesta'}
                              {loading3 && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                  Enviando...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </Button>
                          </div>
                        </Row>
                      </Container>
                    </Form>
                  </div>
                ))}
              </div>
              {/* <pre>{JSON.stringify(procesoFichaData, null, 2)}</pre> */}
            </>
          )}
        </KTCard>
      </div>
    </ColaboradorObservacionContext.Provider>
  )
}

export {ColaboradorObservacion}
