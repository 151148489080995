import { fetchSinToken } from "../helpers/fetch";

const tipos = async () => {
  const resp = await fetchSinToken(
    `tipo/`
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

 const tipoxid = async (id) => {
    const resp = await fetchSinToken(
        `tipo/tipoxid/`,
        {idtipo: id},
        "POST"
      );
      const body = await resp.json();      
    
      if (body.success) {
        localStorage.setItem("token", body.token);
        localStorage.setItem("token-init-date", new Date().getTime());
      }
    
      return body;
};

export {
    tipos,
    tipoxid
};