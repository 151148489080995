import { useEffect, useState } from 'react'
import {KTCard, KTIcon} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {PartnerContext} from './PartnerContexts'
import { Vista } from './asignaiconprocesoInterface'
import { DocumentTrayCustom2 } from './components/DocumentTrayCustom2'


const Breadcrumbs = [
  {
    title: 'Asignación de Proceso',
    path: '/seguimiento/asignacionproceso',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const DataVista:Vista = {
  _id:'', 
  nombre: '',
  url: '',
  contenido: '',
  id_empresa: '',
  estado: true
}


const AsignacionProcesoPage = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [vistaData, setVistaData] = useState<Vista>(DataVista)
  const [edit,setEdit] = useState(false)
  const [reloadTable,setReloadTable] = useState(false)

  

  return (
    <>
      <PartnerContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        vistaData, 
        setVistaData,
        edit,
        setEdit,
        reloadTable,
        setReloadTable
      }}>
        <PageTitle breadcrumbs={Breadcrumbs}>Asignaciones Procesos</PageTitle>
          <DocumentTrayCustom2 />         
      </PartnerContext.Provider>
    </>
  )
}

export {AsignacionProcesoPage}
