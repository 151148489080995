import { useState } from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {Enterprice, PartnerContext} from './PartnerContexts'
import {UsersListHeader} from './components/UsersListHeader'
import {UsersTable} from './components/UsersTable'
import { Campos, DataTabla, Ficha, ProcesoFichas, Seccion, Tabla } from '../../documentacion/MiFicha/FIchaInterface'
import { EditUserModal } from './show-edit-user-modal/EditUserModal'


const historialDocumentsBreadcrumbs = [
  {
    title: 'Seguridad',
    path: '/seguridad/user',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UsersPage = () => {

  const [activeOnly, setActiveOnly] = useState(true)
  const [state, setState] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [entryDateFrom, setEntryDateFrom] = useState()
  const [entryDateTo, setEntryDateTo] = useState()
  const [idRegistroFicha, setIdRegistroFicha] = useState('')
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false)
  const [userData, setUserData] = useState<{}>({})
  const [updateUser, setUpdateUser] = useState(false)
  const [enterprices, setEnterprices] = useState<Enterprice[]>([])

  const openEditUserModal = () => {
    setIsEditUserModalOpen(true)
  }

  const closeEditUserModal = () => {
    setIsEditUserModalOpen(false)
  }
  return (
    <>
      <PartnerContext.Provider
      value={{
        activeOnly,
        setActiveOnly,
        state,
        setState,
        searchTerm,
        setSearchTerm,
        entryDateFrom,
        setEntryDateFrom,
        entryDateTo,
        setEntryDateTo,
        isEditUserModalOpen,
        openEditUserModal,
        closeEditUserModal,
        userData, 
        setUserData,
        updateUser,
        setUpdateUser,
        enterprices,
        setEnterprices
      }}>
        <PageTitle breadcrumbs={historialDocumentsBreadcrumbs}>Usuarios</PageTitle>
        <KTCard>
          <UsersListHeader />
          <UsersTable />
        </KTCard>
          <EditUserModal />
      </PartnerContext.Provider>
    </>
  )
}

export {UsersPage}
