import React from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import styled from "styled-components";
import { TiposCampos } from '../../../../../../shared/TiposCampos';

export const ValidatioError =  styled.p`
  color: red;
  margin-top: 5px;
`;

const SearchWrapper = styled.div`
  padding: 30px;
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
`

const Formulario = () => {
  const {register, setValue, handleSubmit, formState, clearErrors} = useForm();

  const {errors} = formState;
  return (
    <SearchWrapper>
    <div className='modal-header'>
            <h2>Formulario</h2>
          </div>
          <Form /* onSubmit={handleSubmit(onSubmit)} */>
          <div className='modal-body py-lg-10 px-lg-10'>
          <div className='current' data-kt-stepper-element='content'>
              <div className='w-100'>
                {/*begin::Form Group */}
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Nombre de Ficha</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Titulo del campo a llenar'
                    ></i>
                  </label>
                  <TiposCampos tipo={'Calendario'} nombre={'Fecha de Nacimiento'} />
{/*                   <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='titulo'
                    placeholder=''
                    {...register("nombre", {required: true})}
                    autoComplete='off'
                  /> */}
                  {errors?.nombre && (
                    <ValidatioError>Nombre requerido</ValidatioError>
                  )}
                </div>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Descripción</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Tipo de campo'
                    ></i>
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='titulo'
                    placeholder=''
                    {...register("descripcion", {required: true})}
                    autoComplete='off'
                  />
                  {errors?.descripcion && (
                    <ValidatioError>Descripcion requerida</ValidatioError>
                  )}
                </div>                
              </div>
            </div>
          </div>
          </Form>
          </SearchWrapper>
  )
}

export default Formulario
