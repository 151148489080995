import React, { useEffect, useState } from 'react';
import { downloadFileS3 } from '../../../../../services/files';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Spinner } from 'react-bootstrap';

import './PDFViewer.css';
import { Doc, PDFViewerProps } from '../Document.interface';

const PDFViewer: React.FC<PDFViewerProps> = ({ keyn = '', filen = '' }) => {
  const [pdfData, setPdfData] = useState<Doc[]>([]);
  const [loading, setLoading] = useState<boolean>(true); 

  const fetchPdfData = () => {
    const keyname = keyn;
    const filename = filen;
    const partes = filename.split('.');
    const extension = partes[partes.length - 1];

    downloadFileS3(keyname, extension)
      .then((response) => {
        if (response.success) {
          const doc = [
            {
              uri: response.data,
              fileType: extension,
              fileName: filename,
            },
          ];
          setPdfData(doc);
        } else {
          console.error(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPdfData();
  }, [keyn, filen]);

  return (
    <div className="pdf-viewer-container">
      {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" role="status">
            <span className="sr-only">Cargando...</span>
          </Spinner>
        </div>
      ) : (
        <DocViewer
          documents={pdfData}
          pluginRenderers={DocViewerRenderers}
          style={{ height: 850 }}
          theme={{
            primary: "#ffffff",
            secondary: "#5C5C9C",
            tertiary: "#5296d899",
            textPrimary: "#ffffff",
            textSecondary: "#5296d8",
            textTertiary: "#00000099",
            disableThemeScrollbar: false,
          }}
          config={{ header: { disableHeader: true } }}
        />
      )}
    </div>
  );
};

export default PDFViewer;
