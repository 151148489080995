import { useContext, useState } from 'react'
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd'
import Collapsible from 'react-collapsible'
import { CollapsibleSectionItem } from './CollapsibleSectionItem'
import { SheetEditContext } from '../SheetContexts'

// Fake data generator
const getItems = (count: number) =>
  Array.from({length: count}, (_, k) => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }))

// A little function to help with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#c7fff0' : '#EFEFEF',
  padding: grid,
  width: '100%',
  borderRadius: 10,
})

const DraggableSectionList = () => {
  const [items, setItems] = useState(() => getItems(4))
  const {sheetData, setData} = useContext(SheetEditContext)

  const onDragEnd = (result: DropResult) => {
    // Dropped outside the list
    if (!result.destination) {
      return
    }

    const updatedItems = reorder(sheetData.sections || [], result.source.index, result.destination.index)

    setData({...sheetData, sections: updatedItems})
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {(sheetData.sections || []).map((item, index) => (
              <CollapsibleSectionItem key={item.id} item={item} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export {DraggableSectionList}
