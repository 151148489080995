import {useContext, useEffect, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import { PartnerContext } from '../PartnerContexts'

const EditUserModalHeader = () => {
  const {closeEditUserModal} = useContext(PartnerContext)
  const [title, setTitle] = useState('')

  useEffect(() => {
    setTitle('Agregar')
  }, [])

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>Editar Usuario</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        onClick={() => closeEditUserModal()}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
    </div>
  )
}

export {EditUserModalHeader}
