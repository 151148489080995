import React, { ReactNode } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';

interface ModalProps {
  id: string;
  title: string;
  children: ReactNode;
}

const ModalComponent: React.FC<ModalProps> = ({ id, title, children }) => {
    return (
        <div className="modal fade" tabIndex={-1} id={id}>
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
                </button>
            </div>
            <div className="modal-body">
                {children}
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                Close
                </button>
            </div>
            </div>
        </div>
        </div>
    );
};

export default ModalComponent;
