import {useContext} from 'react'
import { AssignmentEditModalFormWrapper } from './AssignmentEditModalFormWrapper'
import { AssignmentEditModalHeader } from './AssignmentEditModalHeader'
import { PartnerContext } from '../../../PartnerContexts'

const AssignmentEditModal = () => {

  const {openAssignmentModal} = useContext(PartnerContext)

  return (
    <>
      {openAssignmentModal && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered mw-400px'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <AssignmentEditModalHeader />
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <AssignmentEditModalFormWrapper />
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {AssignmentEditModal}
