import React from 'react'
import {Accordion} from 'react-bootstrap'
import {Tabla} from '../../FIchaInterface'
import {Section} from './Section'

interface DynamicSectionProps {
  title: string
  eventKey: string
  secciones: Tabla[]
}

const DynamicSection: React.FC<DynamicSectionProps> = ({title, eventKey, secciones}) => {
  return (
    <Accordion.Item eventKey={eventKey} key={eventKey}>
      <Accordion.Header>{title}</Accordion.Header>
      <Accordion.Body>
        <Accordion alwaysOpen>
          {secciones.length > 0 &&
            secciones.map((sd) => (
              <Section
                title='Sección'
                eventKey={sd.indice.toString()}
                campos={sd.data}
                key={sd.indice.toString()}
              />
            ))}
        </Accordion>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export {DynamicSection}
