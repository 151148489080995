import {useContext} from 'react'

import {PartnerContext} from '../PartnerContexts'
import {ShowCargaMasivaModalFormWrapper} from './ShowCargaMasivaModalFormWrapper'
import {ShowCargaMasivaModalHeader} from './ShowCargaMasivaModalHeader'

const ShowCargaMasiva = () => {
  const {openCargaMasiva} = useContext(PartnerContext)

  return (
    <>
      {openCargaMasiva && (
        <>
          <div>
            <div
              className='modal fade show d-block'
              role='dialog'
              tabIndex={-1}
              aria-modal='true'
              style={{zIndex: 1060}}
            >
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered modal-xl'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <ShowCargaMasivaModalHeader />
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y  my-2'>
                    <ShowCargaMasivaModalFormWrapper />
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {ShowCargaMasiva}
