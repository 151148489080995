import {useContext} from 'react'

import { ShowFile2ModalFormWrapper } from './ShowFile2ModalFormWrapper'
import { ShowFile2ModalHeader } from './ShowFile2ModalHeader'
import { DocumentLoadDetailContext } from '../DocumentLoadDetailContext'

const ShowFile2Modal = () => {

  const {showFile2Modal} = useContext(DocumentLoadDetailContext)

  return (
    <>
      {showFile2Modal && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered modal-xl'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <ShowFile2ModalHeader />
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <ShowFile2ModalFormWrapper />
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {ShowFile2Modal}
