import {useContext, useEffect, useState} from 'react'
import {Row, Form, Button, Container, Col} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { PartnersTableGeneral } from '../../../../generales_component/PartnersTableGeneral';
import { getProceso, getProcessUsers } from '../../../../../services/procesos';
import { DocumentLoadDetailContext } from '../DocumentLoadDetailContext'
import { array } from 'yup'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Doc } from '../Document.interface';
import { isObject } from 'formik';


const ShowFileModalForm = () => {
    const {viewDocumento,indiceDocumento,setIndiceDocumento } = useContext(DocumentLoadDetailContext)
    const [docs, setDocs] = useState<Doc[]>(viewDocumento)


    useEffect(() => {
        setDocs(viewDocumento)
    }, [viewDocumento])

    useEffect(() => {
        setDocs(viewDocumento)
    }, [])

    return(
        <>
            <Container className="mt-5">
                <Row>
                    <Col sm={12} >
                        <DocViewer 
                            documents={docs} 
                            initialActiveDocument={docs[indiceDocumento]}
                            pluginRenderers={DocViewerRenderers} 
                            style={{height:850}}                            
                            /* theme={{
                                primary: "#ffffff",
                                secondary: "#5C5C9C",
                                tertiary: "#5296d899",
                                textPrimary: "#ffffff",
                                textSecondary: "#5296d8",
                                textTertiary: "#00000099",
                                disableThemeScrollbar: false,
                            }} */
                        />
                    </Col>
                    
                </Row>
            </Container>
        </>
    )
}

export {ShowFileModalForm}
