import { Fragment, useContext, useEffect, useState } from "react"
import { Button, Col, Dropdown, Form, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap"
import { KTCard, KTCardBody, KTIcon } from "../../../../../_metronic/helpers"

import { getAllFindTabla } from "../../../../services/tablas"
import { asignarEmpresas, asignarUnidadByEmpresa, desasignarEmpresas, desasignarUnidadByEmpresa, getAllCompaniesByPais, getUnidadesByEmpresaAsignadas } from "../../../../services/companies"
import { showMessage } from "../../../../shared/Alerts"
import { AsignacionTablaTable1 } from "./Unidad/AsignacionTablaTable1"
import { AsignacionTablaTable2 } from "./Unidad/AsignacionTablaTable2"
import Select from 'react-select'


const DocumentTrayCustom2 = () => {
    const [listDataEmpresas, setListDataEmpresas] = useState<any[]>([]);
    const [listDataApi1Aux, setListDataApi1Aux] = useState<any[]>([]);
    const [selectEmpresa, setSelectEmpresa] = useState('')
    const [listDataApi1, setListDataApi1] = useState<any[]>([]);
    const [checkedItem1, setCheckedItem1] = useState<any[]>([]);
    const [listDataApi2, setListDataApi2] = useState<any[]>([])
    const [checkedItem2, setCheckedItem2] = useState<any[]>([]);
    const [actualizarData,setActualizarData] = useState(false)

    useEffect(()=>{
        refetchDataEmpresas()
        /*  */
    },[])
    
    useEffect(()=>{
        setActualizarData(true)
        if(listDataApi1Aux.length>0 && listDataApi2.length>0 ){
            const filteredArray:any[] = listDataApi1Aux.filter(item1 =>
                !listDataApi2.some(item2 => item2._id === item1._id)
            );
            setActualizarData(false)
            setListDataApi1(filteredArray);
        }else if(listDataApi1Aux.length>0 && actualizarData){
            setActualizarData(false)
            setListDataApi1(listDataApi1Aux);

        }
    },[listDataApi1Aux,listDataApi2])

    const handleChangeEmpresa = (e: any) => {
        setSelectEmpresa(e.value)
        refetchDataUnidad1(e.value)
        refetchDataUnidad2(e.value)
        //refetchDataUnidad2(e.value)
        // setUnidad()
    }
    
    const refetchDataEmpresas = async () => {
        await getAllCompaniesByPais()
            .then((response) => {
                if (response.success) {
                const DataNew = response?.data.map((ele:any) => {
                    return {...ele,check_selected:false,_id:String(ele.idEmpresaInterna)}
                })
                
                const EmpresasNew = response?.data.map((ele:any) => ({ 
                    label: ele.nombre,
                    value: ele.idEmpresaWorkin
                }))
                setListDataEmpresas(EmpresasNew)

                } else {
                console.log(response)
                }
            })
            .catch((error) => {
                console.log(error)
        })
    }

    const refetchDataUnidad1 = async (id_empresa:string) => {
        await getAllFindTabla('Unidades',id_empresa)
            .then((response) => {
            if (response.success) {
                const DataNew1 = response?.data.map((ele:any) => {
                return {...ele,check_selected:false,_id:String(ele.id)}
                })
                setListDataApi1Aux(DataNew1)
            } else {
                console.log(response)
            }
            })
            .catch((error) => {
            console.log(error)
            })
    }
    
    const refetchDataUnidad2 = async (id_empresas:string) => {
        await getUnidadesByEmpresaAsignadas(id_empresas)
            .then((response) => {
                if (response.success) {
                    const DataNew2 = response?.data[0].unidad.map((ele:any) => {
                    return {...ele,check_selected:false,_id:String(ele.id)}
                    })
                    console.log("DataNew2")
                    console.log(DataNew2)
                    setListDataApi2(DataNew2)
                } else {
                console.log(response)
                }
            })
            .catch((error) => {
                console.log(error)
        })
    }
    
    const handleDesasignar = async () =>{
        await desasignarUnidadByEmpresa({
            id_empresa:selectEmpresa,
            ids_unidad:checkedItem2
        } )
        .then(res => {
            if(res.success){
                refetchDataUnidad1(selectEmpresa)
                refetchDataUnidad2(selectEmpresa)
                showMessage("success","Se Desasigno Correctamente","todo correcto")
            }
        })
        .catch(er => console.log(er))
    }
    
    const handleAsignar = async () =>{
        await asignarUnidadByEmpresa({
            id_empresa:selectEmpresa,
            ids_unidad:checkedItem1
        } )
        .then(res => {
            if(res.success){
                refetchDataUnidad1(selectEmpresa)
                refetchDataUnidad2(selectEmpresa)
                showMessage("success","Se Asigno Correctamente","todo correcto")
            }
    
        })
        .catch(er => console.log(er))
    }

    return (
        <>
            <KTCard>
                <KTCardBody>                
                    <Row>
                        <Col xs={10} lg={4} xl={3} sm className='d-flex align-self-end justify-content-start'>
                            <Stack direction='vertical' gap={1}>
                            <span className='fw-bold'>Seleccione Empresa:</span>
                            <Select
                                styles={{
                                    control: (provided) => ({
                                    ...provided,
                                    fontSize: '13px',
                                    minHeight: '42px', // Reducción del alto
                                    height: '42px',
                                    }),
                                }}
                                options={listDataEmpresas}
                                onChange={handleChangeEmpresa}
                            />
                            </Stack>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col xs={5} lg={5} xl={5} sm>    
                            <KTCard>
                                <AsignacionTablaTable1 tbody={listDataApi1} onCheckSelect={(_id:string[]) => setCheckedItem1(_id)}  />
                            </KTCard>
                        </Col>
                        <Col className='d-flex flex-column ' xs={2} lg={2} xl={2} sm> 
                            <Button className='btn btn-primary mt-10 ' onClick={handleAsignar} >
                                Asignar 
                                <KTIcon iconName='arrow-right' className='fs-2 mx-3' />
                            </Button>
                            
                            <Button className='btn btn-secondary mt-3' onClick={handleDesasignar} >
                                <KTIcon iconName='arrow-left' className='fs-2 mx-3' />
                                Desasignar 
                            </Button>
                        </Col>
                        <Col xs={5} lg={5} xl={5}  sm>
                            <KTCard>
                                <AsignacionTablaTable2 tbody={listDataApi2} onCheckSelect={(_id:string[]) => setCheckedItem2(_id)} />
                            </KTCard>
                        </Col>
                    </Row>
                </KTCardBody>            
            </KTCard>
        </>
        
    )
}

export { DocumentTrayCustom2 }