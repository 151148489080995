import {useContext} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {PartnerContext} from '../PartnerContexts'

const ShowCargaMasivaModalHeader = () => {
  const {setOpenCargaMasiva} = useContext(PartnerContext)

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>Carga masiva de documentos</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        onClick={() => setOpenCargaMasiva(false)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
    </div>
  )
}

export {ShowCargaMasivaModalHeader}
