import { fetchConToken } from "../helpers/fetch"

const getAllRolsPag = async (currentPage:any,numberData:any,options: any) => {
  const queryString = new URLSearchParams(options).toString();

  const resp = await fetchConToken(
    `rol/${currentPage}/${numberData}?${queryString}`,
    {},
    'GET'
  );

  const body = await resp.json();

  return body;
};

const crearRolData = async (data: any) => {
  const resp = await fetchConToken(
      `rol/add`,
      data,
      'POST'
  );
  const body = await resp.json();

  return body;
}

const updateRolData = async (id: string, updatedData: any) => {
  const resp = await fetchConToken(
      `rol/${id}`,
      updatedData,
      'PUT'
  );
  const body = await resp.json();

  return body;
}

const getRoles = async () => {
  const resp = await fetchConToken(
      `rol/getroles/`,
      {},
      'GET'
  );
  const body = await resp.json();

  return body;
}

const deleteRol = async (id:string) => {
  const resp = await fetchConToken(`rol/${id}`, {}, 'DELETE')

  const body = await resp.json()
  return body
}

const activarRol = async (id:string) => {
  const resp = await fetchConToken(`rol/activar/${id}`, {}, 'PUT')

  const body = await resp.json()
  return body
}
  
export {
    getAllRolsPag,
    deleteRol,
    activarRol,
    crearRolData,
    updateRolData,
    getRoles
};