import { createContext } from "react"
import { GrupoAprobacion, valuesGrupoAprobacion } from "./GrupoAprobacionInterface"

interface PartnerContextProps {
    searchTerm: string,
    setSearchTerm: (value: string) => void,
    grupoAprobacionData:GrupoAprobacion,
    setGrupoAprobacionData: (value: GrupoAprobacion) => void,
    openShowModal: boolean,
    setOpenShowModal: (value: boolean) => void,
    editOpenShowModal: boolean,
    setEditOpenShowModal: (value: boolean) => void,
    reloadTable: boolean,
    setReloadTable: (value: boolean) => void
}

const PartnerContext = createContext<PartnerContextProps>({
    searchTerm: '',
    setSearchTerm: (value) => {},
    grupoAprobacionData:valuesGrupoAprobacion,
    setGrupoAprobacionData: (value) => {},
    openShowModal: false,
    setOpenShowModal: (value) => {},
    editOpenShowModal: false,
    setEditOpenShowModal: (value) => {},
    reloadTable: false,
    setReloadTable: (value) => {},
})

export {PartnerContext}