import {Form} from 'react-bootstrap'
import {DraggableArea} from 'react-draggable-tags'
import {KTIcon} from '../../../../../_metronic/helpers/components/KTIcon'
import {useEffect, useState} from 'react'
import {v4} from 'uuid'

interface Tag {
  id: string
  label: string
  value: boolean
}

const DraggableTags = ({data,onChange} : any) => {
  const [tags, setTags] = useState<Tag[]>([])
  const [newElement, setNewElement] = useState<string>('')

  useEffect(() => {
    setTags(data);
  }, [])

  useEffect(() => {
    onChange(tags);
  },[tags])

  const handleClickAdd = () => {
    setTags([...tags, {id: v4(), label: newElement, value:false}])
    setNewElement('');
    // onChange(tags);
  }

  const handleClickDelete = (id: string) => {
    setTags(tags.filter((tag) => tag.id !== id));
    // onChange(tags);
  }

  return (
    <>
      <div style={{display: 'flex'}}>
        <div style={{width: '60%'}}>
          <Form.Control
            className='mb-3'
            type='text'
            placeholder='Ingrese un elemento'
            size='sm'
            id='inputNewElement'
            value={newElement}
            onChange={(e) => setNewElement(e.target.value)}
          />
        </div>
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mx-3'
          title='Agregar elemento'
          onClick={handleClickAdd}
        >
          <KTIcon iconName='plus-square' className='fs-2' />
        </a>
      </div>
      {tags.length > 0 && (
        <div
          style={{
            borderColor: '#E1E3EA',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '10px',
            padding: '5px',
          }}
        >
          <DraggableArea
            tags={tags}
            render={({tag, index}) => (
              <div
                style={{
                  display: 'flex',
                  backgroundColor: '#7E8299',
                  color: 'white',
                  padding: '5px',
                  margin: '3px',
                  borderRadius: '5px',
                  cursor: 'move',
                }}
              >
                {tag.label}

                <a
                  href='#'
                  className='ms-1'
                  title='Agregar elemento'
                  onClick={() => handleClickDelete(tag.id)}
                >
                  <KTIcon iconName='minus-square' className='fs-4 text-white' />
                </a>
              </div>
            )}
            onChange={newTags => setTags(newTags)}
          />
        </div>
      )}
    </>
  )
}

export {DraggableTags}
