import { createContext } from "react"
import { Campos, DataTabla, Ficha, ProcesoFichas, Seccion, Tabla } from "../../documentacion/MiFicha/FIchaInterface"
import { Doc } from "../enviodocumento/enviodocumentInterface"


interface PartnerContextProps {
    activeOnly: boolean,
    setActiveOnly: (value: boolean) => void,
    selectIdProceso: string,
    setSelectIdProceso: (value: string) => void,
    handleInputObservacionChange: (value:string) => void,
    searchSend: boolean,
    setSearchSend: (value: boolean) => void,
    searchTerm: string,
    setSearchTerm: (value: string) => void,
    entryDateFrom: any,
    setEntryDateFrom: (value: any) => void,
    entryDateTo: any,
    setEntryDateTo: (value: any) => void,   
    documents: any[],
    setDocuments: (value: any[]) => void,
    documentsAux: {},
    setDocumentsAux: (value: {}) => void,
    userSelected: any[],
    setUserSelected: (value: any[]) => void,
    listProcess: any[],
    setListProcess: (value: any[]) => void,
    listProcessAll: any[],
    setListProcessAll: (value: any[]) => void,
    processSelected: any[],
    setProcessSelected: (value: any[]) => void,
    searchDocumento: string,
    setSearchDocumento: (value: string) => void,
    showFileModal: boolean,
    setShowFileModal: (value: boolean) => void,
    viewDocumento: Doc[],
    setViewDocumento: (value: Doc[]) => void,
    cargarDocumentModal: boolean,
    openCargarDocumentModal: () => void,
    closeCargarDocumentModal: () => void,
}

const PartnerContext = createContext<PartnerContextProps>({
    activeOnly: true,
    setActiveOnly: (value) => {},
    selectIdProceso: '',
    setSelectIdProceso: (value) => {},
    handleInputObservacionChange: (value) => {},
    searchSend: true,
    setSearchSend: (value) => {},
    searchTerm: '',
    setSearchTerm: (value) => {},
    entryDateFrom: '',
    setEntryDateFrom: (value: any) => {},
    entryDateTo: '',
    setEntryDateTo: (value: any) => {},
    documents: [],
    setDocuments: (value) => {},
    documentsAux: {},
    setDocumentsAux: (value) => {},
    userSelected: [],
    setUserSelected: (value) => {},
    listProcess: [],
    setListProcess: (value) => {},
    listProcessAll: [],
    setListProcessAll: (value) => {},
    processSelected: [],
    setProcessSelected: (value) => {},
    searchDocumento: '',
    setSearchDocumento: (value) => {},
    showFileModal: false,
    setShowFileModal: (value) => {},
    viewDocumento: [],
    setViewDocumento: (value) => {},
    cargarDocumentModal: false,
    openCargarDocumentModal: () => {},
    closeCargarDocumentModal: () => {},
})

export {PartnerContext}