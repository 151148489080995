import {useContext, useEffect, useMemo, useRef, useState} from 'react'
import {Row, Form, Button, Col, Stack, Accordion} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { v4 } from 'uuid'
import Select, { MultiValue, SelectInstance, SingleValue } from 'react-select'
import { PartnerEditContext } from '../PartnerEditContext'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { getAllGrupoAprobacións } from '../../../../../services/grupoAprobacion'
import { PartnersTableGeneral } from '../../../../generales_component/PartnersTableGeneral'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { GroupAprobacion, Niveles } from '../../documentInterface'
import { showMessage } from '../../../../../shared/Alerts'

const headerGroup = [
  {
    type: 'string',
    field: 'grupoaprobacion',
    label: 'Grupo',
  },
  {
    type: 'string',
    field: 'cantidadaprobaciones',
    label: 'N° aprobadores',
  },
  {
    type: 'boolean',
    field: 'firma',
    label: 'Firma PDF',
    icon: 'check',
    btn: {
      true:'btn-light-success',
      false:'btn-light-secondary',
    },
    variantTooltip: {
      true:'success',
      false:'secondary',
    },
    title: {
      true:'SI',
      false:'NO',
    },
  },
  {
    type: 'button',
    field: 'delete',
    icon: 'cross',
    label: 'Opciones',
  }
]


const headerNivel = [
  {
    type: 'string',
    field: 'grupoaprobacion',
    label: 'Nivel',
  },
  {
    type: 'string',
    field: 'cantidadaprobaciones',
    label: 'Envío a usuario',
  },
  {
    type: 'boolean',
    field: 'firma',
    label: 'Firma PDF',
    icon: 'check',
    btn: {
      true:'btn-light-success',
      false:'btn-light-secondary',
    },
    variantTooltip: {
      true:'success',
      false:'secondary',
    },
    title: {
      true:'SI',
      false:'NO',
    },
  },
  {
    type: 'button',
    field: 'delete',
    icon: 'cross',
    label: 'Opciones',
  }
]

const InitialGroupAprobacion : GroupAprobacion = {idgrupoaprobacion:'',grupoaprobacion:'',cantidadaprobaciones:0,firma:false}
const InitialNivel : Niveles = {envio_usuario:false,notificacion_usuario:false,detalle:InitialGroupAprobacion}

const DetailAprobacionModalForm = () => {
  const {processFile,setProcessFile,setShowDetailAprobacion} = useContext(PartnerEditContext)
  const [dataGrupoAprobacion,setDataGrupoAprobacion] = useState<any[]>([])
  const [dataGrupoAprobacionAux,setDataGrupoAprobacionAux] = useState<any[]>([])
  const [dataGrupoAprobacionAll,setDataGrupoAprobacionAll] = useState<any[]>([])
  const [groupAprobacion,setGroupAprobacion] = useState<GroupAprobacion>(InitialGroupAprobacion)
  const [nivel,setNivel] = useState(InitialNivel)
  const [bodyGroup,setBodyGroup] = useState<any[]>([])
  const [bodyNivel,setBodyNivel] = useState<any[]>([])
  const btnGuardarRef = useRef<HTMLButtonElement | null>(null);
  
  const [loadingAddGrupo,setLoadingAddGrupo] = useState(false)
  const [loadingTerminarNivel,setLoadingTerminarNivel] = useState(false)
  const [grupoApropbacionValidationError, setGrupoAprobacionValidationError] = useState(false)

  useEffect(()=> {
    getGrupoAprobacion()
  },[])
  
  // const usersOptions = users.map((x) => ({label: `${x.nombre} - ${x.dni}`, value: x.dni}))

  const getGrupoAprobacion = () =>{
    getAllGrupoAprobacións()
    .then((response) => {
      const grupoOptions = response.data.map((x:any) => ({label: `${x.nombre}`, value: x._id}))
      setDataGrupoAprobacionAll(response.data)
      setDataGrupoAprobacion(grupoOptions)
      setDataGrupoAprobacionAux(grupoOptions)
    })
    .catch((error) => console.log(error))
  }
  
  const handleSelectChange = (e: any) => {
    setGroupAprobacion({ ...groupAprobacion, idgrupoaprobacion: e.value , grupoaprobacion: e.label, cantidadaprobaciones: 0 })
    processOwnersValidation(e.value)
  }
  
  const handleChangeCantidad = (e: any) => {
    let numberAprobadores = Number(e.target.value) 
    
    if(groupAprobacion.idgrupoaprobacion){
      const groupFilter = dataGrupoAprobacionAll.filter(datGroup => String(datGroup._id) === String(groupAprobacion.idgrupoaprobacion) )

      if(groupFilter.length>0){
        if(numberAprobadores <= groupFilter[0].responsables.length ){
          setGroupAprobacion({ ...groupAprobacion, cantidadaprobaciones: numberAprobadores})
        }else {
          setGroupAprobacion({ ...groupAprobacion, cantidadaprobaciones: groupFilter[0].responsables.length})
        } 
      }
    }
  }

  const processOwnersValidation = (owners: string[]) => {
    if (owners.length === 0) {
      setGrupoAprobacionValidationError(true)
    } else {
      setGrupoAprobacionValidationError(false)
    }
  }

  const handleChangeFirme = (e:any) => {
    setGroupAprobacion({ ...groupAprobacion, firma: e.target.checked })
  };
  
  const handleChangeCheck = (e:any,name:string) => {
    setNivel({ ...nivel, [name]: e.target.checked })
  };

  const handleAddGroup = () => {
    setBodyGroup([...bodyGroup,groupAprobacion])
    setGroupAprobacion({...InitialGroupAprobacion})
  }

  const handleAddNivel = () => {
    // setBodyNivel([...bodyNivel,{...nivel,detalle:bodyGroup[0]}])
    setBodyGroup([])
    setGroupAprobacion({...InitialGroupAprobacion})
    setNivel({...InitialNivel})
    setProcessFile({...processFile,detalle_niveles:[...processFile.detalle_niveles,{...nivel,detalle:bodyGroup[0]}]})

    const detalleNivelesArray = [...processFile.detalle_niveles,{...nivel,detalle:bodyGroup[0]}]
    const idGroup = detalleNivelesArray.map(det => det.detalle.idgrupoaprobacion)
    const auxDataGrupoAprobacion = dataGrupoAprobacionAux.filter(datGroup => !idGroup.includes(datGroup.value) )
    setDataGrupoAprobacion(auxDataGrupoAprobacion)
  }

  const onGuardar = () => {
    if (processFile.number_aprobacion == processFile.detalle_niveles.length ) {
      setShowDetailAprobacion(false)
    } else {
      showMessage('info', '¡Faltan Datos!', 'Aun Faltan completar los niveles de aprobacion')
    }
  }


  const handleBtnSelected = async (item: any, array:any[], accion:string, propList:string ) => {
    const arrayNew : any[] = []
    let TypeSelected = ""
    if(accion === 'delete'){
      array.map((dat:any) => {
        let entro = false
        if(dat['idgrupoaprobacion'] === item.idgrupoaprobacion){          
          entro = true
        }
        if(!entro){
          arrayNew.push(dat)
        }
      })
      setBodyGroup(arrayNew)
    }else if(accion === 'edit'){
      if(item.typeSelected){
        TypeSelected = item.typeSelected
      }
    }
  };

  const handleDeleteNivel = (idx:number) => {
    const detalle_niveles_aux = processFile.detalle_niveles.filter((det,i) => i !== idx )
    setProcessFile({...processFile,detalle_niveles:detalle_niveles_aux})

    const idGroup = detalle_niveles_aux.map(det => det.detalle.idgrupoaprobacion)
    const auxDataGrupoAprobacion = dataGrupoAprobacionAux.filter(datGroup => !idGroup.includes(datGroup.value) )
    setDataGrupoAprobacion(auxDataGrupoAprobacion)
  }

  return (
    <>
      <Form >
        <Row className='mb-3'>
          <Col sm>
            <Stack direction='vertical' gap={2}>  
              <div className='d-flex justify-content-between'>
                <div className='d-flex'>
                  <Form.Label style={{ fontSize: '16px', fontWeight:'bold' }} >Configurando nivel 1</Form.Label>
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='d-flex'>
                  <Form.Label>Puede añadir grupos de aprobación a los niveles. Seleccione el grupo, cantidad de aprobadores y presione 'Añadir Grupo'</Form.Label>
                </div>
              </div>
            </Stack>
          </Col>          
        </Row>
        <Row className='mb-3' >
          <Col xs={4} lg={4} xl={4} sm>
            <span className='fw-bold'>Grupo Aprobación</span>
            <Select
              name='groupaprobacion'
              value={dataGrupoAprobacion.filter((x:any) => groupAprobacion.idgrupoaprobacion?.includes(x.value))}
              options={dataGrupoAprobacion}
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: '12px',
                  minHeight: '34px', // Reducción del alto
                  height: '34px',
                }),
              }}
              onChange={handleSelectChange}
              placeholder='Seleccione Grupo Aprobación'
            />
            {grupoApropbacionValidationError && (
              <p style={{color: '#B73A47', marginTop: '10px'}}>
                Seleccione al menos un processo.
              </p>
            )}
          </Col>
          <Col xs={3} lg={3} xl={3} sm>
            <span className='fw-bold'>Cantidad de aprobadores</span>
            <Form.Control
              type='number'
              value={groupAprobacion.cantidadaprobaciones}
              onChange={handleChangeCantidad}
              size='sm'
              required
              placeholder='Cantidad de aprobadores'
              name='cantidad_aprobacion'
            />
          </Col>
          <Col xs={2} lg={2} xl={2} sm className='d-flex align-self-center justify-content-center mt-2' >
            <Form.Check
              type='checkbox'
              id={`checkbox1`}
              checked={groupAprobacion.firma}
              label={<span style={{color: 'black'}}>Incluir firma en PDF</span>}
              onChange={handleChangeFirme}
              className='hide-validation'
            />
          </Col>
          {
            processFile.number_aprobacion > processFile.detalle_niveles.length && (
              <Col xs={2} lg={2} xl={2} sm className='d-flex align-self-center justify-content-center mt-2' >
                <Button variant='primary' disabled={(groupAprobacion.idgrupoaprobacion === '')?true:false} data-tooltip-id={`my-tooltip-add`} type='button' className='btn-sm' onClick={() => handleAddGroup()}>
                  {!loadingAddGrupo && 'Añadir Grupo'}
                  {loadingAddGrupo && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Añadiendo...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </Button>
                <ReactTooltip
                  id={`my-tooltip-add`}
                  place="bottom"
                  variant={`info`}
                  content={`Añade grupo de aprobacion seleccionado al nivel actual`}
                />
              </Col>
            )
          }
          

        </Row>

        { 
          bodyGroup.length > 0 ? (
            <>
              <Row>
                <div className="separator border-3 my-4"></div>
              </Row>
              <Row style={{ maxHeight: '600px', overflowY: 'auto' }} >          
                <PartnersTableGeneral 
                  title={""} 
                  theaderVisible={true}  
                  theader={headerGroup} 
                  tbody={bodyGroup} 
                  pagination={false} 
                  searchVisible={false} 
                  searchHint={""}
                  onBtnSelected={(Item:any,accion:string) => handleBtnSelected(Item,bodyGroup,accion,"Users")}
                  
                  />
              </Row>
              <Row>
                <div className="separator border-3 my-4"></div>
              </Row>
              <Row>
                { processFile.number_aprobacion >= processFile.detalle_niveles.length &&
                  <Col xs={12} lg={12} xl={12} sm className='d-flex align-self-left justify-content-left mt-2' >
                    <Form.Check
                      type='checkbox'
                      id={`checkboxenviodocumento`}
                      name={`envio_usuario`}
                      checked={nivel.envio_usuario}
                      label={<span style={{color: 'black'}}>¿Este nivel debe enviar los documentos al Usuario al ser aprobado?</span>}
                      onChange={ (e) => {handleChangeCheck(e,"envio_usuario")} }
                      className='hide-validation'
                    />
                  </Col>
                }

                { processFile.number_aprobacion - 1 > processFile.detalle_niveles.length &&
                  <Col xs={12} lg={12} xl={12} sm className='d-flex align-self-left justify-content-left mt-2' >
                    <Form.Check
                      type='checkbox'
                      id={`checkboxfirmar`}
                      name={`notificacion_usuario`}
                      checked={nivel.notificacion_usuario}
                      label={<span style={{color: 'black'}}>¿Al finalizar el nivel de aprobación notificar a usuarios con permisos para firmar el siguiente nivel?</span>}
                      onChange={ (e) => {handleChangeCheck(e,"notificacion_usuario")} }
                      className='hide-validation'
                    />
                  </Col>
                }

                { processFile.number_aprobacion > processFile.detalle_niveles.length &&
                  <Col xs={12} lg={12} xl={12} sm className='d-flex align-self-center justify-content-center mt-2' >
                    <Button variant='success'  type='button' className='btn-sm' onClick={() => handleAddNivel()}>
                      {!loadingTerminarNivel && <> Terminar Nivel <KTIcon iconName='check' className='fs-3' /></>}
                      {loadingTerminarNivel && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Terminando...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </Button>
                  </Col>
                }
                
                
              </Row>
            </>
          )
          : <div style={{marginTop:'150px'}}></div>
        }
          

        <Row> 
        <Accordion defaultActiveKey='0' style={{marginTop:'20px'}}>
            <Accordion.Item eventKey={'key1'}>
              <Accordion.Header className='item-table-header'>Niveles configurados {processFile.detalle_niveles.length} de {processFile.number_aprobacion}</Accordion.Header>
              <Accordion.Body>
                <Accordion>
                  <div className='table-responsive'>
                    <table
                      id='kt_table_users'
                      className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    >
                      <thead style={{padding: '5px'}}>
                        <tr className='text-start text-muted fw-bolder fs-6 text-uppercase gs-0'>
                          <th className='min-w-100px'>Nivel</th>
                          <th className='text-start'>Envío</th>
                          <th className='text-start'>Notifica a usuario</th>
                          <th className='text-start'>Detalle grupo de aprobación</th>
                          <th className='text-end'>Opciones</th>
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-bold' >
                        { processFile.detalle_niveles.map((niv,idx) => (
                            <tr>
                              <td>
                                  <div className="symbol symbol-25px symbol-circle ">
                                    <div className="symbol-label fs-2 bg-primary text-white">
                                      {idx+1}
                                    </div>
                                  </div>
                              </td>
                              <td className='text-start min-w-100px'>
                                  {
                                    niv.envio_usuario?
                                      <>
                                        <div className="symbol symbol-25px symbol-circle me-2">
                                          <div className="symbol-label fs-2 fw-bold bg-success pt-1">
                                            <i className="bi bi-check2 fs-2x text-white"></i>
                                          </div>
                                        </div>
                                      </>
                                    :
                                      <>
                                        <div className="symbol symbol-25px symbol-circle me-2">
                                          <div className="symbol-label fs-2 fw-bold bg-opacity-50 bg-dark pt-1">
                                              <i className="bi bi-x fs-2x text-white"></i>
                                          </div>
                                        </div> 
                                      </>
                                      
                                  }                               
                              </td>
                              <td className='text-start min-w-100px'>
                                  {
                                    niv.notificacion_usuario?
                                    <>
                                      <div className="symbol symbol-25px symbol-circle me-2">
                                        <div className="symbol-label fs-2 fw-bold bg-success pt-1">
                                        <i className="bi bi-envelope fs-1x text-white"></i>
                                        </div>
                                      </div>
                                    </>
                                  :
                                    <>
                                      <div className="symbol symbol-25px symbol-circle me-2">
                                        <div className="symbol-label fs-2 fw-bold bg-opacity-50 bg-dark pt-1">
                                        <i className="bi bi-envelope fs-1x text-white"></i>
                                        </div>
                                      </div> 
                                    </>
                                  }
                              </td>
                              <td className='text-start min-w-100px'>
                                <Row>
                                  <Stack direction='horizontal'>
                                    <div className="symbol symbol-25px symbol-circle" style={{marginRight:"5px"}}>
                                      <div className="symbol-label fs-2 bg-success text-white">
                                        { niv.detalle.cantidadaprobaciones }
                                      </div>
                                    </div>
                                    { niv.detalle.firma ?
                                        <div className="symbol symbol-25px symbol-circle">
                                          <div className="symbol-label fs-2 fw-bold bg-success pt-1">
                                            <i className="bi bi-check2 fs-2x text-white"></i>
                                          </div>
                                        </div>
                                      :
                                        <div className="symbol symbol-25px symbol-circle" >
                                          <div className="symbol-label fs-2 fw-bold bg-opacity-50 bg-dark pt-1">
                                              <i className="bi bi-x fs-2x text-white"></i>
                                          </div>
                                        </div>
                                    }
                                    <div style={{marginLeft:"5px"}} >
                                      { niv.detalle.grupoaprobacion }
                                    </div>
                                  </Stack>
                                </Row>                                
                              </td>                              
                              <td className='text-end d-flex align-self-end justify-content-end'>
                                <div>
                                  <button
                                    data-tooltip-id={`my-tooltip-${idx+1}`}
                                    onClick={() => handleDeleteNivel(idx)}
                                    title={`Titulo ${idx+1}`}
                                    className={`btn btn-icon btn-light-danger btn-sm me-1`}
                                  >
                                    <KTIcon
                                      iconName={'cross'}
                                      className='fs-3'
                                    />
                                  </button>
                                  <ReactTooltip
                                    id={`my-tooltip-${idx+1}`}
                                    place='bottom'
                                    variant={'error'}
                                    content={`Eliminar Nivel de Aprobación`}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        }
                        
                      </tbody>
                    </table>
                  </div>

                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
        </Accordion>
                        
            
        </Row>
        <Row>
          <Col className='d-flex align-self-end justify-content-end py-2'>
            <button ref={btnGuardarRef} onClick={onGuardar} type="button" className="btn btn-primary btn-sm me-10" id="kt_button_Guardar">
              <span className="indicator-label">Guardar Nivel de Aprobación</span>
              <span className="indicator-progress">Cargando
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
            </button>
          </Col>
        </Row>
        
      </Form>
    </>
  )
}

export {DetailAprobacionModalForm}
