
export interface GrupoAprobacion {
  _id:string;
  nombre: string;
  descripcion: string;
  responsables: string[];
  estado: boolean;
}

export const valuesGrupoAprobacion:GrupoAprobacion = {
  _id:'', 
  nombre: '',
  descripcion: '',
  responsables: [],
  estado: true
}