import { useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row, Stack } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import Select, { SelectInstance } from 'react-select'
import { KTIcon } from '../../../../../_metronic/helpers'
import { getAllPartnersSelect } from '../../../../services/colaboradores'
import { downloadMultipleDocumentsKeyName } from '../../../../services/documentSent'
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from '../../../../services/menu'
import { showMessage } from '../../../../shared/Alerts'
import { useGlobalOverlayLoading } from '../../../../shared/hooks/useGlobalOverlayLoading'
import { useUnidades } from '../../../../shared/hooks/useUnidades'
import { TableSearch } from '../../../../shared/TableSearch'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import OverlayLoading from '../../../generales_component/OverlayLoading'
import { PartnerContext } from '../PartnerContexts'
import { DatePickerBusiness } from '../../../generales_component/DatePickerBusiness'

type Dates = [Date | null, Date | null]

const LegajoDocumentsListHeader = () => {
  const buttonsData = [
    {
      iconName: 'verify',
      title: 'Borrador',
      stateName: 'borrador',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Completado',
      stateName: 'completado',
      color: '#2ecc71', // Green
    }
  ]

  const [activeButtons, setActiveButtons] = useState(buttonsData.map(() => false))
  
  const [listProcessValidationError, setListProcessValidationError] = useState(false)
  

  const {setActiveOnly,setSearchTerm,setSearchDocumento, listUnidadProcess, setEntryDateFrom, setEntryDateTo,setListUnidad,setListUnidadAll, listProcessAll, setUnidadSelected,setProcessSelected, setSearchSend,setOpenCargaMasiva, entryDateFrom, entryDateTo, setTree, setListUnidadProcess, reset, setDates, Dates} =
    useContext(PartnerContext)
  const [loadingUnidades, setLoadingUnidades] = useState(true)
  const {toOptions, misUnidades} = useUnidades()
  const [listUser, setProcessOwners] = useState([])
  const [listProceso, setListProceso] = useState<any[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [unidad, setUnidad] = useState('')
  const [documents, setDocuments] = useState<{ [unidad:string]:{[process:string]:{[año: string]: { [mes: string]:{ [user: string]: any[] }}}} }>(listUnidadProcess)
  const [cargandoFile, setCargandoFile] = useState(false)
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()
  const location = useLocation()
  const { selectedRole } = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])
  const procesoMultiRef = useRef<SelectInstance<any | null>>(null)
  const [clearValue, setClearValue] = useState(false)
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu = response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

    useEffect(()=>{
      getUsersSelect()
    },[])

    useEffect(()=>{
      if(loadingUnidades && misUnidades.length>0){
        setListUnidad(misUnidades)
        setListUnidadAll(misUnidades)
        setLoadingUnidades(false)
      }
    },[misUnidades])
    

    useEffect(()=>{
      setDocuments(listUnidadProcess)
    },[listUnidadProcess])

    useEffect(()=>{
      actualizarProceso()
    },[listProcessAll])


    

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }
  const handleChangeUnidad = (e: any) => {
    setUnidad(e.value)
    setUnidadSelected(e.value)
  }

  const handleExport = async (id: any) => {
    console.log(documents)
    const documentExport: { [fecha: string]:{  [user: string]: {  [info: string]: string[]  }  }  } = {};
    Object.keys(documents).forEach((unid:any) => {
      Object.keys(documents[unid]).map((proc:any,ind0) => {            
        Object.keys(documents[unid][proc]).map((key:any,ind) => {
          Object.keys(documents[unid][proc][key]).map((key1:any,ind1) => {                
            Object.keys(documents[unid][proc][key][key1]).map((key2:any,ind1) => {                
              documents[unid][proc][key][key1][key2].map(key3 => {
                if(key3.checkSelected){
                  if (!documentExport[`${key}_${key1}`]) {
                    documentExport[`${key}_${key1}`] = {};
                  }
                  if (!documentExport[`${key}_${key1}`][`${key2}`]) {
                    documentExport[`${key}_${key1}`][`${key2}`] = {};
                    documentExport[`${key}_${key1}`][`${key2}`]['keyname'] = [];
                    documentExport[`${key}_${key1}`][`${key2}`]['user']  = [];
                    documentExport[`${key}_${key1}`][`${key2}`]['date']  = [];
                  }

                  documentExport[`${key}_${key1}`][`${key2}`]['keyname'].push(key3.keyname)
                  documentExport[`${key}_${key1}`][`${key2}`]['user'].push(key2)
                  documentExport[`${key}_${key1}`][`${key2}`]['date'].push(key3.createdAt)
                }
              })
            })
          })
        })
      })
    })


    Object.keys(documentExport).map(async (key:any) => {
      Object.keys(documentExport[key]).map(async (key1:any,ind0) => {
          setCargandoFile(true)
          setGlobalOverlayLoading(true)
          await downloadMultipleDocumentsKeyName({data:documentExport[key][key1]})
            .then((response) => {
              setCargandoFile(false)
                if (response.success) {
                    const link = document.createElement('a');
                    link.href = response.data;
                    link.download = `${key}_${key1}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    showMessage('success', '¡Buen trabajo!', response.message)
                } else {
                    console.log(response)
                    showMessage('error', 'Hubo un error', response.error)
                }
            })
            .catch((error) => {
              setCargandoFile(false)
                console.log(error)
                showMessage('error', 'Hubo un error', error.message)
            })
          setGlobalOverlayLoading(false)
      }) 
    })

  }

  const getUsersSelect = () => {
    getAllPartnersSelect({})
      .then((response) => {
        setProcessOwners(response.data.map((x: any) => ({ label: x.dni+' - '+x.apellidos+' '+x.nombres+' ', value: x.id_user })))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  

  const actualizarProceso = () => {
    setListProceso(listProcessAll?.map((x: any) => ({ label: x.nombre, value: x._id })))
  }

  const handleSelectChange = (e: any) => {
    setProcessSelected(e.map((x: any) => x.value))
    // setFormData({ ...formData, managers: e.map((x: any) => x.value) })
    processOwnersValidation(e.map((x: any) => x.value))
  }

  const processOwnersValidation = (owners: string[]) => {
    if (owners.length === 0) {
      setListProcessValidationError(true)
    } else {
      setListProcessValidationError(false)
    }
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
    setSearchDocumento(event.target.value)    
  }

  const cargaMasiva = () => {
    setOpenCargaMasiva(true)
  }

  return (
    <>
      <div className='mx-8 mt-8 mb-2'>
        <Row>
          <Col sm='4' className='mb-3'>
            <Form.Label>Rango de Fecha de Búsqueda</Form.Label>

            <Stack className='mt-2' direction='vertical' gap={3}>
              <DatePickerBusiness value={Dates} onChange={setDates} />
              {/* <Stack direction='horizontal'>
                <span className='fw-bold'>Inicio </span>
                <Form.Control
                  className='mt-2'
                  type='date'
                  size='sm'
                  placeholder='Inicio'
                  onChange={(e) => setEntryDateFrom(e.target.value)}
                  value={entryDateFrom}
                />
              </Stack>
              <Stack direction='horizontal'>
                <span className='fw-bold'>Fin </span>
                <Form.Control
                  className='mt-2'
                  type='date'
                  placeholder='Fin'
                  size='sm'
                  onChange={(e) => setEntryDateTo(e.target.value)}
                  value={entryDateTo}
                />
              </Stack> */}
            </Stack>
          </Col>
          <Col sm='7'>
            <Stack className='mt-2' direction='horizontal' gap={3}>
              <Stack direction='vertical'>
                <Form.Label>Colaboradores</Form.Label>
                <TableSearch
                  placeholderSearch='DNI o Apellidos'
                  typeBusqueda='Proceso'
                  onSearch={handleSearch}
                  design={true}
                  clearValue={clearValue}
                  setClearValue={setClearValue}
                />
              </Stack>
              {misUnidades.length > 0 && (
                <Stack direction='vertical' gap={1}>
                  <span className='fw-bold'>Unidad:</span>
                  <Select
                    options={toOptions()}
                    onChange={handleChangeUnidad}
                    value={{
                      label: toOptions().find(x => x.value === unidad)?.label ?? '',
                      value: unidad
                    }}
                  />
                </Stack>
              )}
              <Stack direction='vertical'>
                <Form.Label>Procesos</Form.Label>
                <Select
                  // defaultValue={listUser?.filter((x: any) => formData?.managers?.includes(x.value))}
                  isMulti
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      fontSize: '12px',
                      minHeight: '34px', // Reducción del alto
                      height: '34px',
                    }),
                  }}
                  ref={procesoMultiRef}
                  name='managers'
                  options={listProceso}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  placeholder='Seleccione el proceso'
                  onChange={handleSelectChange}
                />
                {listProcessValidationError && (
                  <p style={{color: '#B73A47', marginTop: '10px'}}>
                    Seleccione al menos un processo.
                  </p>
                )}
              </Stack>
            </Stack>
          </Col>

          <Col sm='1' className='pt-8'>
            <button
              type='button'
              className='btn btn-primary btn-sm'
              onClick={() => {
                setSearchSend(true)
              }}
            >
              <KTIcon iconName='filter-search' className='fs-2' />
            </button>
          </Col>
        </Row>
        <Row>
          <Col className='mb-3'>
            <span className='fw-bold'>Buscar Documento</span>
            <Form.Control
              size='sm'
              type='text'
              placeholder='buscar por nombre del documento'
              value={searchQuery}
              onChange={handleSearchChange}
              className='form-control-sm my-2 '
            />
          </Col>
          <Col className='mb-5 d-flex align-self-end justify-content-end gap-2'>
            <Button
              variant='primary'
              onClick={() => {
                procesoMultiRef.current?.clearValue()
                setSearchTerm('')
                setUnidad('')
                setUnidadSelected([])
                setEntryDateFrom('')
                setEntryDateTo('')
                setProcessSelected([])
                setSearchDocumento('')
                setSearchQuery('')
                setSearchSend(true)
                setClearValue(true)
                reset()
              }}
              >
              Limpiar filtros
            </Button>
            <button type='button' className='btn btn-primary btn-sm' onClick={cargaMasiva}>
              Carga masiva
            </button>
            {permissions.includes('Exportar Archivos') && (            
            <button type='button' className='btn btn-primary btn-sm' onClick={handleExport}>
              <KTIcon iconName='file-down' className='fs-2' />
              Exportar Archivos
            </button>)}
          </Col>
        </Row>
      </div>

      {cargandoFile && <OverlayLoading />}
    </>
  )
}

export { LegajoDocumentsListHeader }

