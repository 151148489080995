import React, {useContext, useEffect, useState} from 'react'
import {ListGroup, Badge, Row, Col, Form, Button} from 'react-bootstrap'
// import { ListTags } from '../documentInterface';
import { showInfoCopy, showMessage } from '../../../../../shared/Alerts';
import { Field, Section, Sheet } from '../../../fichas/Sheet'
import { ListFieldsExport, ProcessSheet } from '../../../documento/documentInterface';
import * as excelJs from 'exceljs';
import { PartnerContext } from '../../PartnerContexts';
import { number, string } from 'yup';
import { actualizarCampoCheckExcel, actualizarCampoTagIntegracion } from '../../../../../services/campos';
import { group } from 'console';
import { formatDateToDMY } from '../../../../../helpers/dateFunctions';
import { useGlobalOverlayLoading } from '../../../../../shared/hooks/useGlobalOverlayLoading';
import { getUnidadesEmpresas } from '../../../../../services/companies';
import { useAuth2 } from '../../../../authv2/Auth2Provider';
import { PdfLoadingSpinner } from '../../../../generales_component/PdfLoadingSpinner';
import '../../style.css'
import { DraggableSheetList } from './Draggable/DraggableSheetList';
import { AssignmentEditModal } from './assignment-edit-modal/AssignmentEditModal';

interface MyProps {
  idProceso:string;
  processSheet: {label: string, value: string}[];
}

const ProcessFieldExport: React.FC<MyProps> = ({idProceso,processSheet}) => {
  const {updateFieldMigration,setUpdateFieldMigration,setEdit} = useContext(PartnerContext)
  const [loading, setLoading] = useState(false)
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()
  
  useEffect(()=>{
    if(updateFieldMigration){
      setUpdateFieldMigration({id_proceso: idProceso,fichas:[]})
    }
  },[idProceso])
  
  /* useEffect(()=>{
    console.log("updateFieldMigration")
    console.log(updateFieldMigration)
  },[updateFieldMigration]) */

  const handleUpdateTagCampos = () => {
    setGlobalOverlayLoading(true)

    actualizarCampoTagIntegracion(updateFieldMigration)
    .then((response) => {
      if (response.success) {
        setGlobalOverlayLoading(false)
        showMessage("success","Actualizacion Correctamente",response.message)
        setEdit(true)
      }else{
        setGlobalOverlayLoading(false)
        showMessage("error","Hubo un Error",response.message)
      }
    })
    .catch((error) => {
      setGlobalOverlayLoading(false)
        console.log(error)
    })
  }
  
  return (
    <>
      <DraggableSheetList
        key={"DraggableSheetList"}
        procesoficha={processSheet}
        ind={1}
      />
      <Form.Group>
        {
          processSheet.length>0 && (
            <div className='d-flex justify-content-end'>
              <Button variant='primary' type='button' className='btn-sm' onClick={() => handleUpdateTagCampos()}>
                {!loading && 'Actualizar Etiqueta'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Actualizando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </Button>
            </div>
          )
        }
        
      </Form.Group>
      <AssignmentEditModal />
    </>
  )
}

export default ProcessFieldExport
