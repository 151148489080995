import {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {Col, Dropdown, Row} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Select, {SelectInstance} from 'react-select'
import {bulkLoadMany} from '../../../../services/documentos'
import {getProcessUsersConUnidad} from '../../../../services/procesos'
import {showInfoHtml, showMessage} from '../../../../shared/Alerts'
import {useGlobalOverlayLoading} from '../../../../shared/hooks/useGlobalOverlayLoading'
import {useUnidades} from '../../../../shared/hooks/useUnidades'
import DropzoneArchivo2 from '../../../generales_component/DropzoneArchivo2'
import {PartnerContext} from '../PartnerContexts'

const ShowCargaMasivaModalForm = () => {
  const {listProcessAll, setShowFileModal, setViewDocumento, setSearchSend, setOpenCargaMasiva, setHandleChecks, handleChecks, setTree, tree} =
    useContext(PartnerContext)
  const {globalOverlayLoading, setGlobalOverlayLoading} = useGlobalOverlayLoading()
  const [processSelected, setProcessSelected] = useState('')
  const [users, setUsers] = useState<Record<string, any>[]>([])
  const [usersSelected, setUsersSelected] = useState<string[]>([])
  const [rawFiles, setRawFiles] = useState<Record<string, any>[]>([])
  const [loadName, setLoadName] = useState('')
  const userRef = useRef<SelectInstance>(null)
  const [selectedUnidad, setSelectedUnidad] = useState('')
  const {toOptions} = useUnidades()
  const [userCheck, setUserCheck] = useState<Record<string, boolean>>({})

  useEffect(() => {
    if (processSelected) {
      getProcessUsersConUnidad(processSelected, selectedUnidad)
        .then((response) => {
          if (response.success) {
            setUsers(response.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [processSelected, selectedUnidad])

  const processOptions = listProcessAll.map((x) => ({label: x.nombre, value: x._id}))

  const usersOptions = users.map((x) => ({label: `${x.nombre} - ${x.dni}`, value: x.dni}))

  const handleChangeProcess = (e: any) => {
    setProcessSelected(e.value)
    userRef.current?.clearValue()
    setUsersSelected([])
  }

  const handleChangeUsers = (e: any) => {
    setUsersSelected(e.map((option: any) => option.value))
    setUserCheck(
      e.reduce(
        (acc: any, option: any) => ({...acc, [option.value]: userCheck[option.value] ?? false}),
        {}
      )
    )
  }

  const fileToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        resolve(reader.result)
      }

      reader.onerror = () => {
        reject(new Error('Error al leer el archivo.'))
      }

      reader.readAsDataURL(file)
    })
  }

  const handleFilesSelected = useCallback(async (fileAuxSec: any[]) => {
    try {
      const resp = (await Promise.all(
        fileAuxSec.map(async (file) => {
          if (!file.raw) {
            file.raw = await fileToBase64(file.file)
          }

          return {
            raw: file.raw,
            name: file.filename,
            visibleToPartner: file.visibleToPartner,
            ext: file.ext,
          }
        })
      )) as Record<string, any>[]

      setRawFiles(resp)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const handleFileValidation = (file: File) => {
    const [dni, ...parts] = file.name.split('_')
    const filename = parts.join('_')
    console.log(dni, filename)

    if (!dni || !usersSelected.includes(dni)) {
      return {
        code: 'invalid',
        message: 'Verifique que el archivo cuente con el formato correcto',
      }
    }

    return null
  }

  const handleCheckboxChange = (checked: boolean, user: string) => {
    console.log(checked, user)
    setHandleChecks(true)

    if (user === 'all') {
      const allUsers = usersSelected.reduce((acc, user) => {
        return {
          ...acc,
          [user]: checked,
        }
      }, {})

      setUserCheck({
        ...allUsers,
        all: checked,
      })
      return
    }

    setUserCheck((prev) => ({
      ...prev,
      [user]: checked,
    }))
  }

  const onSuccess = () => {
    if (!loadName) {
      showMessage('error', 'Debe indicar un nombre de carga', '')
      return
    }

    if (!selectedUnidad) {
      showMessage('error', 'Debe seleccionar una unidad', '')
      return
    }

    if (usersSelected.length === 0) {
      showMessage('error', 'Debe seleccionar al menos un usuario', '')
      return
    }

    const groupFiles = usersSelected.map((user) => {
      const userFiles = rawFiles.filter((file) => {
        return file.name.startsWith(user + '_')
      })

      const userFilesToUppercase = userFiles.map((file) => {
        const [, ...fullname] = file.name.split('_')
        return {
          ...file,
          name: fullname.join('_').toUpperCase(),
        }
      })

      return {
        user,
        files: userFilesToUppercase,
      }
    })

    const filesNotMatch = rawFiles.filter((file) => {
      return !usersSelected.some((user) => file.name.startsWith(user + '_'))
    })

    const params = {
      processId: processSelected,
      users: usersSelected,
      loadName,
      files: groupFiles,
      unidad: selectedUnidad,
    }

    const emptyFiles = groupFiles.filter((file) => file.files.length === 0)

    const countRepeatedFiles: Record<string, any>[] = []
    groupFiles.forEach((file) => {
      file.files.forEach((f) => {
        const countFile = countRepeatedFiles.find((x) => x.key === f.name && file.user === x.user)
        if (countFile == null) {
          countRepeatedFiles.push({key: f.name, value: 1, user: file.user})
        } else {
          countRepeatedFiles.push({key: f.name, value: countFile.value + 1, user: file.user})
        }
      })
    })

    const existRepeatedFiles = countRepeatedFiles.filter(file => file.value > 1)

    if (existRepeatedFiles.length > 0) {
      showInfoHtml(
        'error',
        'Existen archivos repetidos, por favor, elimine o cambie el nombre uno de los archivos seleccionados',
        existRepeatedFiles.map((file) => `${file.user} - ${file.key}`).join('<br/>')
      )
      return
    }
    console.log(params)

    if (emptyFiles.length > 0) {
      showMessage(
        'error',
        'Los siguientes usuarios con DNI no tienen archivos',
        emptyFiles.map((file) => file.user).join(', ')
      )
      return
    }

    if (filesNotMatch.length > 0) {
      showInfoHtml(
        'error',
        'Los siguientes archivos no coinciden con los DNI de los usuarios seleccionados',
        filesNotMatch.map((file, idx) => `${idx + 1}.- ${file.name}`).join('<br/>')
      )
      return
    }

    setGlobalOverlayLoading(true)
    bulkLoadMany(params)
      .then(async (res) => {
        if (res.success) {
          if (res.messages.length > 0) {
            setGlobalOverlayLoading(false)
            await showInfoHtml(
              'error',
              'Archivos repetidos no cargados',
              res.messages.join('<br/>')
            )
          }
          
          setOpenCargaMasiva(false)
          setSearchSend(true)
          showMessage('success', res.message, '')
        } else {
          throw new Error(res.message)
        }
      })
      .catch((err) => {
        showMessage('error', 'Hubo un error', err.message)
      })
      .finally(() => {
        setGlobalOverlayLoading(false)
      })
  }

  return (
    <>
      <Row>
        <Col sm={8} md={8}>
          <div className='alert alert-primary d-flex align-items-center p-5 mb-10'>
            <span className='svg-icon svg-icon-2hx svg-icon-primary me-3'>
              <i className='bi bi-info-circle fs-3x'></i>
            </span>

            <div className='d-flex flex-column'>
              <h5 className='mb-1'>Información</h5>
              <span>
                Se cargará archivos por usuario, el formato del archivo debe ser:{' '}
                <b>DNI_nombre de documento.pdf / DNI_MI_ZIP.zip</b>
                <br />
                <b>Nota:</b> No debe borrar el DNI de los archivos, caso contrario no se adjuntaran
                a la carga del colaborador. Ademas si renombra el archivo con un DNI y no existe el
                usuario asociado no se tomara en cuenta.
              </span>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm={4} md={4}>
          <input
            type='text'
            placeholder='Nombre de la carga'
            className='form-control'
            value={loadName}
            onChange={(e) => setLoadName(e.target.value)}
          />
        </Col>
        <Col sm={4} md={4}>
          <Select
            placeholder='Seleccione unidad'
            options={toOptions()}
            onChange={(e: any) => {
              setSelectedUnidad(e.value)
              userRef.current?.clearValue()
              setUsersSelected([])
            }}
          />
        </Col>
        <Col sm={4} md={4}>
          <Select
            options={processOptions}
            onChange={handleChangeProcess}
            placeholder='Seleccione el proceso'
          />
        </Col>
      </Row>

      <Row className='mt-5 mb-5'>
        <Col>
          {processSelected && (
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={usersOptions}
              ref={userRef}
              onChange={handleChangeUsers}
              placeholder='Seleccione usuarios'
            />
          )}
        </Col>
      </Row>

      <Row className='mt-5 mb-5'>
        {usersSelected.length > 0 && (
          <Col>
            <Dropdown autoClose={false}>
              <Dropdown.Toggle variant='success' id='dropdown-basic'>
                Marcar todos los documentos visibles para
              </Dropdown.Toggle>
              <Dropdown.Menu>

                <Dropdown.Item as='button' key='all'>
                  <input
                    type='checkbox'
                    checked={userCheck['all'] ?? false}
                    id='visible-all'
                    onChange={(e) => handleCheckboxChange(e.target.checked, 'all')}
                  />{' '}
                  <label htmlFor='visible-all'>Todos los usuarios</label>
                </Dropdown.Item>

                {usersSelected.map((u) => (
                  <Dropdown.Item as='button' key={u}>
                    <input
                      type='checkbox'
                      checked={userCheck[u] ?? false}
                      id={`visible-${u}`}
                      onChange={(e) => handleCheckboxChange(e.target.checked, u)}
                    />{' '}
                    <label htmlFor={`visible-${u}`}>
                      {usersOptions.find((o) => o.value === u)?.label.replace(` - ${u}`, '')}
                    </label>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        )}
      </Row>

      <Row className='mb-5 min-h-200px'>
        <DropzoneArchivo2
          key='bulk-load'
          onFilesSelected={(fileAuxSec) => {
            handleFilesSelected(fileAuxSec)
          }}
          idx='bulk-load'
          startName={true}
          setShowFileModal={setShowFileModal}
          setViewDocumento={setViewDocumento}
          fileValidation={handleFileValidation}
          onSuccess={onSuccess}
          loading={globalOverlayLoading}
          whenChangeUpdateFiles={usersSelected}
          checkRelatedUsers={Object.keys(userCheck).map((u) => ({u, check: userCheck[u]}))}
          handleChecks={handleChecks}
          setHandleChecks={setHandleChecks}
        />
      </Row>
    </>
  )
}

export {ShowCargaMasivaModalForm}
