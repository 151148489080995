import { createContext } from "react";

interface AlertsContextProps {
    searchTerm: string,
    setSearchTerm: (term: string) => void,
}

const AlertsContext = createContext<AlertsContextProps>({
    searchTerm: '',
    setSearchTerm: () => {}
})

export { AlertsContext }