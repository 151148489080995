import React, { useContext, useState, useEffect, FC } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { CollapsibleSectionItem } from './CollapsibleSectionItem'
import { Campos, ProcesoFichas, Seccion } from '../../../documentacion/MiFicha/FIchaInterface'

const grid = 8

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#c7fff0' : '#EFEFEF',
  padding: grid,
  width: '100%',
  borderRadius: 10,
})

interface MyComponentProps {
  procesoficha: ProcesoFichas;
  ind: number;
  onUpdate: (updatedData: any) => void;
}


const DraggableSectionList: FC<MyComponentProps> = (props) => {
  const { procesoficha, ind, onUpdate } = props

  const [loading, setLoading] = useState(true)
  const [formDataFicha, setFormDataFicha] = useState(procesoficha)

  useEffect(() => {
    const filteredFichas = procesoficha.fichas
      .map((ficha) => ({
        ...ficha,
        seccion: ficha.seccion
          .map((seccion) => ({
            ...seccion,
            // campos: seccion.campos.filter((campo) => campo.visible === true && campo.editable === true)
          }))
          .filter((seccion) => seccion.campos.length > 0)
      }))
      .filter((ficha) => ficha.seccion.length > 0)

    const filteredProcesoFicha: ProcesoFichas = {
      ...procesoficha,
      fichas: filteredFichas
    }

    setFormDataFicha(filteredProcesoFicha)
    setLoading(false)
  }, [procesoficha])


  const handleUpdateSeccion = (ind: number, updatedData: Seccion) => {
    onUpdate(updatedData);
  };


  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Droppable droppableId={`droppable` + ind}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {(formDataFicha.fichas[0].seccion || []).map((item: Seccion, index) => (
                <CollapsibleSectionItem
                  key={item._id}
                  ind={ind}
                  item={item}
                  index={index}
                  onUpdate={(updatedData) => handleUpdateSeccion(ind, updatedData)}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </DragDropContext>
  )
}

export { DraggableSectionList }
