import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { eliminarCampo } from '../../../../../../services/campos';

export const ModalEliminar = ({show, setShow, data, setUpdated, updated, setLimpiar }) => {

    const {register, setValue, handleSubmit} = useForm();
    
    const handleGuardar = () => {    
    eliminarCampo(data).then((response) => {
            if(response?.success){
                console.log("Campo Eliminado")
                setUpdated(!updated) 
            } else {
                console.log(response?.message)
            }
        }).catch(error => {
            console.log(error)
        })
        setShow(false)
    }

    const handleCerrar = () => {
        setShow(false)
        setLimpiar('')
    }

  return (
    <Modal
          id='kt_modal_create_app'
          tabIndex={-2}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-900px'
          show={show}
          onHide={() => handleCerrar()}
        >
          <div className='modal-header'>
            <h2>Eliminar Campo</h2>
            {/* begin::Close */}
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => handleCerrar()}
            >
              X
            </div>
            {/* end::Close */}
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            <div className='current' data-kt-stepper-element='content'>
              <div className='w-100'>
                {/*begin::Form Group */}
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>¿Seguro que deseas eliminar este Campo?</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Eliminar proceso'
                    ></i>
                  </label>
                </div>                             
              </div>
            </div>
            <button
              type='button'
              className='btn btn-lg btn-danger mr-2'
              data-kt-stepper-action='next'
              onClick={() => handleCerrar()}
              style={{marginRight: 20}}
            >
              Cancelar              
            </button>
            <button
              type='button'
              className='btn btn-lg btn-primary'
              data-kt-stepper-action='next'
              onClick={() =>
                handleGuardar()
              }
            >
              Eliminar              
            </button>
          </div>
        </Modal>
  )
}
