import DocViewer, {DocViewerRenderers} from '@cyntler/react-doc-viewer'
import {useContext, useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {Doc} from '../../../../enviodocumento/enviodocumentInterface'
import {ColaboradorObservacionContext} from '../ColaboradorObservacionContexts'

const ShowFileModalForm = () => {
  const {viewDocumento} = useContext(ColaboradorObservacionContext)
  const [docs, setDocs] = useState<Doc[]>([])

  useEffect(() => {
    console.log(viewDocumento)
    setDocs(viewDocumento)
  }, [viewDocumento])

  return (
    <>
      <Container className='mt-5'>
        <Row>
          <Col sm={12}>
            {docs.length > 0 &&
              (docs[0].fileType === 'pdf' ? (
                <DocViewer
                  documents={docs}
                  pluginRenderers={DocViewerRenderers}
                  style={{height: 850}}
                />
              ) : (
                <>
                  <div
                    style={{
                      height: '75vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={docs[0].uri}
                      alt={docs[0].fileName}
                      style={{objectFit: 'contain', maxWidth: '100%', maxHeight: '100%'}}
                    />
                  </div>
                </>
              ))}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export {ShowFileModalForm}
