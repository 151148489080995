import { useState } from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {PartnerContext} from './PartnerContexts'
import {LegajoDocumentsListHeader} from './components/LegajoDocumentsListHeader'
import {LegajoDocumentsTable} from './components/LegajoDocumentsTable'
import { Doc } from '../enviodocumento/enviodocumentInterface'


const historialDocumentsBreadcrumbs = [
  {
    title: 'Seguimiento',
    path: '/seguimiento/historialdocumentos',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const FiscalizadorLegajoDocumentsPage = () => {

  const [activeOnly, setActiveOnly] = useState(true)
  const [selectIdProceso, setSelectIdProceso] = useState<string>("")
  const [searchTerm, setSearchTerm] = useState('')
  const [searchDocumento, setSearchDocumento] = useState('')
  const [searchSend, setSearchSend] = useState(true)
  const [entryDateFrom, setEntryDateFrom] = useState('')
  const [entryDateTo, setEntryDateTo] = useState('')
  const [userSelected, setUserSelected] = useState<any[]>([])
  const [documents, setDocuments] = useState<any[]>([])
  const [documentsAux, setDocumentsAux] = useState<{}>({})
  const [listProcess, setListProcess] = useState<any[]>([])
  const [listProcessAll, setListProcessAll] = useState<any[]>([])
  const [processSelected, setProcessSelected] = useState<any[]>([])
  const [showFileModal,setShowFileModal] = useState(false)
  const [viewDocumento, setViewDocumento] = useState<Doc[]>([])
  const [cargarDocumentModal, setCargarDocumentModal] = useState(false)

  const handleInputObservacionChange = (id:string) => {
  }

  const openCargarDocumentModal = () => {
    setCargarDocumentModal(true)
  }

  const closeCargarDocumentModal = () => {
    setCargarDocumentModal(false)
  }


  return (
    <>
      <PartnerContext.Provider
      value={{
        activeOnly,
        setActiveOnly,
        selectIdProceso,
        setSelectIdProceso,
        handleInputObservacionChange,
        searchSend,
        setSearchSend,
        searchTerm,
        setSearchTerm,
        entryDateFrom,
        setEntryDateFrom,
        entryDateTo,
        setEntryDateTo,
        documents,
        setDocuments,
        documentsAux,
        setDocumentsAux,
        userSelected,
        setUserSelected,
        listProcess, 
        setListProcess,
        listProcessAll,
        setListProcessAll,
        processSelected, 
        setProcessSelected,
        searchDocumento,
        setSearchDocumento,
        showFileModal,
        setShowFileModal,
        viewDocumento,
        setViewDocumento,
        cargarDocumentModal,
        openCargarDocumentModal,
        closeCargarDocumentModal

      }}>
        <PageTitle breadcrumbs={historialDocumentsBreadcrumbs}>Legajo</PageTitle>
        <KTCard>
          <LegajoDocumentsListHeader />
          <LegajoDocumentsTable />
        </KTCard>
      </PartnerContext.Provider>
    </>
  )
}

export {FiscalizadorLegajoDocumentsPage}
