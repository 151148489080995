import React, {useContext} from 'react'
import {Accordion, Stack} from 'react-bootstrap'
import {KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import {formatDateToDMY} from '../../../../helpers/dateFunctions'
import {getFileInPDF} from '../../../../services/files'
import {showMessage} from '../../../../shared/Alerts'
import {useGlobalOverlayLoading} from '../../../../shared/hooks/useGlobalOverlayLoading'
import {PdfLoadingSpinner} from '../../../generales_component/PdfLoadingSpinner'
import {MiLegajoContext} from '../MiLegajoPageContext'

const typeToPrettyName: any = {
  Configurado: 'Documento Firmado',
  MASIVO: 'Documento cargado por el administrador',
  Personalizado: 'Documento Firmado'
}

const MiLegajoTable: React.FC = () => {
  const {documents, setPreviewDoc, setOpenPreviewModal, loadingDocs} = useContext(MiLegajoContext)
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()
  const unidades = Object.keys(documents)

  const handleDownload = (
    keyname: string,
    id: string,
    name: string,
    includeSign: boolean,
    idUserSign: string
  ) => {
    setGlobalOverlayLoading(true)
    const partes = keyname.split('.')
    const extension = partes[partes.length - 1]
    const partesname = name.split('.')
    let filename = ''
    let exist = false
    partesname.forEach((par) => {
      filename = filename + '.' + par
      if (String(extension) === String(par)) {
        exist = true
      }
    })

    getFileInPDF(keyname, {showSignature: false, signCeo: includeSign, idUserSign})
      .then((response) => {
        if (response.success) {
          const link = document.createElement('a')
          link.href = response.data
          link.download = filename
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          showMessage('success', '¡Buen trabajo!', response.message)
          // viewDocument(id)
          //   .then((response) => {
          //     getEmployeeDocuments(userData.userId, state)
          //     console.log(response)
          //   })
          //   .catch((error) => {
          //     console.log(error)
          //   })
        } else {
          showMessage('error', 'Hubo un error', response.error)
        }
      })
      .catch((error) => {
        showMessage('error', 'Hubo un error', error.message)
      })
      .finally(() => {
        setGlobalOverlayLoading(false)
      })
  }

  const handleOpenPreviewModal = async (keyname: string, fileName: string) => {
    setGlobalOverlayLoading(true)
    const uri = await getFileInPDF(keyname, {showSignature: false, signCeo: false})
    setPreviewDoc([
      {
        uri: uri.data,
        fileType: 'pdf',
        fileName,
      },
    ])
    setOpenPreviewModal(true)
    setGlobalOverlayLoading(false)
  }

  return (
    <KTCardBody>
      {loadingDocs ? (
        <PdfLoadingSpinner />
      ) : (
        <Accordion defaultActiveKey='0'>
          {unidades.map((unidad) => {
            const procesos = Object.keys(documents[unidad])
            return (
              <Accordion.Item eventKey={unidad} key={unidad}>
                <Accordion.Header>{unidad}</Accordion.Header>
                <Accordion.Body>
                  {procesos.length === 0 ? (
                    <div className='fs-4 text-center w-100'>
                      Oooops! No hay datos para mostrar, prueba buscando por otro criterio
                    </div>
                  ) : (
                    procesos.map((proceso) => (
                      <Accordion key={proceso}>
                        <Accordion.Item eventKey={proceso}>
                          <Accordion.Header>{proceso}</Accordion.Header>
                          <Accordion.Body>
                            <div className='table-responsive'>
                              <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                <thead>
                                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                    <th className='text-start'>Nombre</th>
                                    <th className='text-start'>Fecha ultima acción</th>
                                    <th className='text-start'>Tipo</th>
                                    <th className='text-end'>Acciones</th>
                                  </tr>
                                </thead>
                                <tbody className='text-gray-600 fw-bold'>
                                  {documents[unidad][proceso].map((item) => (
                                    <tr key={item._id}>
                                      <td className='text-start min-w-100px'>{item.name}</td>
                                      <td className='text-start min-w-100px'>
                                        {formatDateToDMY(item.updatedAt)}
                                      </td>
                                      <td className='text-start min-w-100px'>
                                        {typeToPrettyName[item.typeSelected] ?? ''}
                                      </td>
                                      <td className='text-end d-flex align-self-end justify-content-end'>
                                        <Stack direction='horizontal' gap={1}>
                                          <button
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            title='Descargar documento'
                                            onClick={() =>
                                              handleDownload(
                                                item.keyname,
                                                item.name,
                                                item._id,
                                                item.includeSign ?? false,
                                                item?.signMetadata?.signFrom
                                              )
                                            }
                                          >
                                            <KTIcon iconName='cloud-download' className='fs-3' />
                                          </button>
                                          <button
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            title='Previsualizar documento'
                                            onClick={() =>
                                              handleOpenPreviewModal(item.keyname, item.name)
                                            }
                                          >
                                            <KTIcon iconName='document' className='fs-3' />
                                          </button>
                                        </Stack>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    ))
                  )}
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
        </Accordion>
      )}
      {unidades.length === 0 && !loadingDocs && (
        <div className='fs-4 text-center w-100'>
          Oooops! No hay datos para mostrar, prueba buscando por otro criterio
        </div>
      )}
    </KTCardBody>
  )
}

export {MiLegajoTable}
