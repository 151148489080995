import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { toPng } from 'html-to-image';

interface TextToStyleGeneratorProps {
    onFirmaSelected: (Image:string, index: number) => void;
    shouldClearFirma: boolean;
    onClearFirma: () => void;
}

const TextToStyleGenerator : React.FC<TextToStyleGeneratorProps> = ({ onFirmaSelected, shouldClearFirma, onClearFirma}) => {
    const [text, setText] = useState('');
    const [fontFamily, setFontFamily] = useState('Arial');
    const [enabled, setEnabled] = useState(false);

    const firmaRef = useRef(null);

    const handleSave = () => {
        const element = firmaRef.current;    
        if (element) {
            if (element) {
                toPng(element)
                .then(function (dataUrl) {
                    onFirmaSelected(String(dataUrl),0);
                    setEnabled(true)
                })
                .catch(function (error) {
                console.error('Error al generar la imagen:', error);
                });
            }
        }
    };

    React.useEffect(() => {
        if (shouldClearFirma) {
            handleClear();
        }
    }, [shouldClearFirma]);


    const handleTextChange = (event:any) => {
        setText(event.target.value);
    };

    const handleFontChange = (event:any) => {
        setFontFamily(event.target.value);
    };

    const handleClear = () => {
        setText("");
        onFirmaSelected(String(""),0);
        onClearFirma();
        setEnabled(false)
    };

    const StyledText = styled.div`
        font-family: ${fontFamily}, sans-serif;
        font-size: 24px;
        color: #000000;
        background-color: #ffffff;
        padding: 10px;
        display: flex;
        justify-content: center;
    `;

    const fontOptions = [
        "Helvetica",
        "Arial",
        "Futura",
        "Century Gothic",
        "Gotham",
        "Calibri",
        "Proxima Nova",
        "Roboto",
        "Montserrat",
        "Lato",
        "Open Sans",
        "Raleway",
        "Source Sans Pro",
        "Oswald",
        "Bebas Neue"
    ];

    return (
        <div>
            <div className='mb-3' >
                <input className='form-control form-control-sm form-control-solid' placeholder='Escribe tu Firma' type="text" value={text} onChange={handleTextChange} />
            </div>
            <div>
                <select 
                    className="form-select form-select-solid" 
                    aria-label="Select example"
                    value={fontFamily}                    
                    onChange={handleFontChange}>
                    <option value="">Selecciona una fuente</option>
                    {fontOptions.map((font) => (
                    <option  value={font}> {font} </option>
                    ))}
                
                </select>
            </div>
                <StyledText ref={firmaRef} >{text}</StyledText>
            <div className='button-container' >
                <button className="btn btn-light-danger btn-sm" onClick={handleClear}>Limpiar</button>
                <button className={`btn btn-light-success btn-sm ${enabled? 'disabled': ''}` } onClick={handleSave}>Guardar firma</button>
            </div>
        </div>
    );
};

export default TextToStyleGenerator;
