import React, { useEffect, useState } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Spinner } from 'react-bootstrap';

import './PDFViewer.css';
import { getFileInPDF } from '../../../../services/files';

export interface PDFViewerProps {
  keyn: string;
}

export interface Doc {
  uri: string;
  fileType: string;
  fileName: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ keyn = '' }) => {
  const [pdfData, setPdfData] = useState<Doc[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchPdfData = () => {
    getFileInPDF(keyn)
      .then((response) => {
        if (response.success) {

          const doc = [
            {
              uri: response.data,
              fileType: 'pdf',
              fileName: 'test',
            },
          ];

          setPdfData(doc)
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  };

  useEffect(() => {
    fetchPdfData();
  }, [keyn]);

  return (
    <div className="pdf-viewer-container">
      {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" role="status">
            <span className="sr-only">Cargando...</span>
          </Spinner>
        </div>
      ) : (
        <DocViewer
          documents={pdfData}
          pluginRenderers={DocViewerRenderers}
          style={{ height: 850 }}
          theme={{
            primary: "#ffffff",
            secondary: "#5C5C9C",
            tertiary: "#5296d899",
            textPrimary: "#ffffff",
            textSecondary: "#5296d8",
            textTertiary: "#00000099",
            disableThemeScrollbar: false,
          }}
          config={{ header: { disableHeader: true } }}
        />
      )}
    </div>
  );
};

export default PDFViewer;
