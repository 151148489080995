import {format, parseISO} from 'date-fns'
import {FC, useContext, useEffect, useState} from 'react'
import {DragDropContext, Draggable, DropResult, Droppable} from 'react-beautiful-dnd'
import {downloadFileS3, getFileInPDF} from '../../../../services/files'
import {DataUser} from '../../../documentacion/MiFicha/DataUserInterface'
import {DataTabla, Seccion, Tabla} from '../../../documentacion/MiFicha/FIchaInterface'
import './styles.css'

import {KTSVG} from '../../../../../_metronic/helpers'
import {showMessage} from '../../../../shared/Alerts'
import OverlayLoading from '../../documento/componente/OverlayLoading'
import {ColaboradorObservacionContext} from './observacion/ColaboradorObservacionContexts'
import {ShowFileModal} from './observacion/show-file-modal/ShowFileModal'

const grid2 = 8

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  padding: grid2 * 2,
  margin: `0 0 ${grid2}px 0`,

  background: isDragging ? '#18c073' : 'white',
  borderRadius: 10,
  ...draggableStyle,
})

const getItems = (count: number) =>
  Array.from({length: count}, (_, k) => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }))

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const grid = 8

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#c7fff0' : '#EFEFEF',
  padding: grid,
  width: '100%',
  borderRadius: 10,
})

const tiposCampos = [
  'Caja de Texto',
  'Lista Desplegable',
  'checkbox',
  'radiobutton',
  'Numero',
  'Fecha',
]

interface MyComponentProps {
  indProcesoFicha: number
  sectionItem: Seccion
  onUpdate: (updatedData: any) => void
}

const DraggableFieldList: FC<MyComponentProps> = (props) => {
  const {sectionItem, onUpdate} = props
  const [formDataSeccion, setFormDataSeccion] = useState(sectionItem)
  const [formDataCampos, setFormDataCampos] = useState(sectionItem.campos)
  const [cargandoFile, setCargandoFile] = useState(false)
  const {setShowFileModal, setViewDocumento} = useContext(ColaboradorObservacionContext)

  const usuarioJSON = localStorage.getItem('userData')
  let usuario: DataUser | null = null

  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON)
  }
  // const idusuario = usuario?.userId
  const nameusuario = usuario?.userName

  useEffect(() => {
    onUpdate(formDataSeccion)
  }, [sectionItem, formDataSeccion])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
  }

  const handleInputChange = (event: any, index: any) => {
    const {value} = event.target
    let fieldValue = value
    let hasObservado = false
    let observaciones: any = []
    let observacion = {}

    formDataSeccion?.campos?.[index]?.observacion?.forEach((obs: any) => {
      if (obs.estado_abierto !== true) {
        observaciones.push(obs)
      }
    })

    if (fieldValue) {
      hasObservado = true
      observacion = {
        Responsable: nameusuario,
        value: fieldValue,
        fecha: new Date(),
        data_observada: formDataSeccion.campos[index].values,
        data_correguida: '',
        estado_abierto: true,
      }
      observaciones.push(observacion)
    }

    setFormDataSeccion((formDataSeccion) => {
      const newCampo = [...formDataSeccion.campos]
      newCampo[index] = {
        ...newCampo[index],
        observacion: observaciones,
        observado: hasObservado,
      }
      setFormDataCampos(newCampo)
      return {...formDataSeccion, campos: newCampo}
    })
  }
  const handleInputChangeSelect = (event: any, index: any) => {
    const {value} = event.target
    let fieldValue = value
    let hasObservado = false
    let observaciones: any = []
    let observacion = {}
    formDataSeccion?.campos?.[index]?.observacion?.forEach((obs: any) => {
      if (obs.estado_abierto !== true) {
        observaciones.push(obs)
      }
    })

    if (fieldValue) {
      hasObservado = true
      observacion = {
        Responsable: 'OR',
        value: fieldValue,
        fecha: new Date(),
        data_observada: formDataSeccion.campos[index].selectOpion?.label
          ? formDataSeccion.campos[index].selectOpion?.label
          : '',
        data_correguida: '',
        estado_abierto: true,
      }
      observaciones.push(observacion)
    }

    setFormDataSeccion((formDataSeccion) => {
      const newCampo = [...formDataSeccion.campos]
      newCampo[index] = {
        ...newCampo[index],
        observacion: observaciones,
        observado: hasObservado,
      }
      setFormDataCampos(newCampo)
      return {...formDataSeccion, campos: newCampo}
    })
  }

  const handleFilesSelected = (event: any, index: any) => {
    const {value} = event.target
    let fieldValue = value
    let hasObservado = false
    let observaciones: any = []
    let observacion = {}
    formDataSeccion?.campos?.[index]?.observacion?.forEach((obs: any) => {
      if (obs.estado_abierto !== true) {
        observaciones.push(obs)
      }
    })

    if (fieldValue) {
      hasObservado = true
      observacion = {
        Responsable: 'OR',
        value: fieldValue,
        fecha: new Date(),
        data_observada: formDataSeccion.campos[index].valuesFiles.join(';'),
        data_correguida: '',
        estado_abierto: true,
      }
      observaciones.push(observacion)
    }

    setFormDataSeccion((formDataSeccion) => {
      const newCampo = [...formDataSeccion.campos]
      newCampo[index] = {
        ...newCampo[index],
        observacion: observaciones,
        observado: hasObservado,
      }
      setFormDataCampos(newCampo)
      return {...formDataSeccion, campos: newCampo}
    })
  }

  const handleInputChange2 = (event: any, index: any, index2: any) => {
    const {value} = event.target
    let fieldValue = value
    let hasObservado = false
    let observaciones: any = []
    let observacion = {}

    formDataSeccion?.secciones?.[index].data?.[index2]?.observacion?.forEach((obs: any) => {
      if (obs.estado_abierto !== true) {
        observaciones.push(obs)
      }
    })

    if (fieldValue) {
      hasObservado = true
      observacion = {
        Responsable: nameusuario,
        value: fieldValue,
        fecha: new Date(),
        data_observada: formDataSeccion?.secciones?.[index].data[index2].values,
        data_correguida: '',
        estado_abierto: true,
      }
      observaciones.push(observacion)
    }

    setFormDataSeccion((formDataSeccion) => {
      const secciones: Tabla[] = formDataSeccion.secciones || []
      const seccionInd = secciones[index] || {data: []}
      const data = seccionInd.data || []
      const newSeccion: DataTabla[] = [...data]
      newSeccion[index2] = {
        ...newSeccion[index2],
        observacion: observaciones,
        observado: hasObservado,
      }
      secciones[index] = {...seccionInd, data: newSeccion}
      return {...formDataSeccion, secciones: secciones}

      /* const newCampo = [...formDataSeccion.campos];
      newCampo[index] = {
        ...newCampo[index],
        'observacion': observaciones,
        'observado':hasObservado
      };
      setFormDataCampos(newCampo)
      return { ...formDataSeccion, campos: newCampo }; */
    })
  }
  const handleInputChangeSelect2 = (event: any, index: any, index2: any) => {
    const {value} = event.target
    let fieldValue = value
    let hasObservado = false
    let observaciones: any = []
    let observacion = {}
    formDataSeccion?.secciones?.[index].data?.[index2]?.observacion?.forEach((obs: any) => {
      if (obs.estado_abierto !== true) {
        observaciones.push(obs)
      }
    })

    if (fieldValue) {
      hasObservado = true
      observacion = {
        Responsable: 'OR',
        value: fieldValue,
        fecha: new Date(),
        data_observada: formDataSeccion.secciones?.[index].data?.[index2].selectOpion?.label
          ? formDataSeccion.secciones?.[index].data?.[index2].selectOpion?.label
          : '',
        data_correguida: '',
        estado_abierto: true,
      }
      observaciones.push(observacion)
    }

    setFormDataSeccion((formDataSeccion) => {
      const secciones: Tabla[] = formDataSeccion.secciones || []
      const seccionInd = secciones[index] || {data: []}
      const data = seccionInd.data || []
      const newSeccion: DataTabla[] = [...data]
      newSeccion[index2] = {
        ...newSeccion[index2],
        observacion: observaciones,
        observado: hasObservado,
      }
      secciones[index] = {...seccionInd, data: newSeccion}
      return {...formDataSeccion, secciones: secciones}

      /* const newCampo = [...formDataSeccion.campos];
      newCampo[index] = {
        ...newCampo[index],
        'observacion': observaciones,
        'observado':hasObservado
      };
      setFormDataCampos(newCampo)
      return { ...formDataSeccion, campos: newCampo }; */
    })
  }
  const handleFilesSelected2 = (event: any, index: any, index2: any) => {
    const {value} = event.target
    let fieldValue = value
    let hasObservado = false
    let observaciones: any = []
    let observacion = {}
    formDataSeccion?.secciones?.[index].data?.[index2]?.observacion?.forEach((obs: any) => {
      if (obs.estado_abierto !== true) {
        observaciones.push(obs)
      }
    })

    if (fieldValue) {
      hasObservado = true
      observacion = {
        Responsable: 'OR',
        value: fieldValue,
        fecha: new Date(),
        data_observada: formDataSeccion?.secciones?.[index].data?.[index2]?.valuesFiles.join(';'),
        data_correguida: '',
        estado_abierto: true,
      }
      observaciones.push(observacion)
    }

    setFormDataSeccion((formDataSeccion) => {
      const secciones: Tabla[] = formDataSeccion.secciones || []
      const seccionInd = secciones[index] || {data: []}
      const data = seccionInd.data || []
      const newSeccion: DataTabla[] = [...data]
      newSeccion[index2] = {
        ...newSeccion[index2],
        observacion: observaciones,
        observado: hasObservado,
      }
      secciones[index] = {...seccionInd, data: newSeccion}
      return {...formDataSeccion, secciones: secciones}

      /* const newCampo = [...formDataSeccion.campos];
      newCampo[index] = {
        ...newCampo[index],
        'observacion': observaciones,
        'observado':hasObservado
      };
      setFormDataCampos(newCampo)
      return { ...formDataSeccion, campos: newCampo }; */
    })
  }

  const handleViewModal = async (keyname: string, name: string, ext: string) => {
    setCargandoFile(true)
    const newViewDocument: any[] = []

    if (ext === 'pdf' || ext === 'docx') {
      await getFileInPDF(keyname)
        .then((response) => {
          console.log(response)
          if (response.success) {
            newViewDocument.push({
              uri: response.data,
              fileType: 'pdf',
              fileName: name,
            })
          } else {
            console.log(response)
          }
        })
        .catch((error) => {
          console.log(error)
        })
      setCargandoFile(false)
      setViewDocumento(newViewDocument)
      setShowFileModal(true)
    } else {
      await downloadFileS3(keyname, ext)
        .then((response) => {
          if (response.success) {
            newViewDocument.push({
              uri: response.data,
              fileType: ext,
              fileName: name,
            })
          } else {
            console.log(response)
            showMessage('error', 'Hubo un error', response.error)
          }
          setCargandoFile(false)
        })
        .catch((error) => {
          console.log(error)
        })
      setCargandoFile(false)
      setViewDocumento(newViewDocument)
      setShowFileModal(true)
    }
  }

  return (
    <>
      {!formDataSeccion.dinamico ? (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {(formDataCampos || []).map((item, index) => (
                      <>
                        {item.visible === true && item.editable === true && 
                          <Draggable draggableId={`Cam` + item._id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                <div className='grid-container-obs' style={{position: 'relative'}}>
                                  <div
                                    className='grid-item'
                                    style={{marginLeft: '10px', marginTop: '8px', fontWeight: 'bold'}}
                                  >
                                    {item.titulo} {item.obligatorio ? '*' : ''}
                                  </div>
                                  <div
                                    className='grid-item'
                                    style={{marginLeft: '5px', marginTop: '8px', fontWeight: 'normal'}}
                                  >
                                    {tiposCampos.includes(item.tipo[0].nombre) ? (
                                      <label> {item.values} </label>
                                    ) : item.tipo[0].nombre === 'Lista desplegable BD' ? (
                                      <label>
                                        {' '}
                                        {item.selectOpion?.label ? item.selectOpion.label : ''}{' '}
                                      </label>
                                    ) : item.tipo[0].nombre === 'Imagen' ||
                                      item.tipo[0].nombre === 'carga de documentos' ? (
                                      item.infoFiles.map((value, idx) => (
                                        <>
                                          <b>
                                            *{' '}
                                            <a
                                              className='link-primary ml-2'
                                              href='#see'
                                              onClick={(e) => {
                                                const sep = value.name.split('.')
                                                const ext = sep[sep.length - 1]
                                                e.preventDefault()
                                                handleViewModal(value.url, value.name, ext)
                                              }}
                                            >
                                              {item.infoFiles[idx].name}
                                            </a>
                                          </b>{' '}
                                          <br></br>
                                        </>
                                      ))
                                    ) : item.tipo[0].nombre === 'Firma' ? (
                                      <>
                                        <b>
                                          *{' '}
                                          <a
                                            className='link-primary ml-2'
                                            href={`${item.values}`}
                                            target='_blank'
                                            rel='noreferrer'
                                          >
                                            Firma
                                          </a>
                                        </b>
                                      </>
                                    ) : (
                                      item.values
                                    )}
                                  </div>
                                  <div className='grid-item' style={{marginLeft: '5px'}}>
                                    {item.tipo[0].nombre === 'Lista desplegable BD' ? (
                                      <input
                                        type='text'
                                        style={{border: '1px solid orange'}}
                                        className={`form-control form-control-sm form-control-solid`}
                                        name={item._id}
                                        value={
                                          item.observacion?.[Number(item.observacion.length) - 1]
                                            ?.estado_abierto
                                            ? String(
                                                item.observacion[Number(item.observacion.length) - 1]
                                                  .value
                                              )
                                            : ''
                                        }
                                        onChange={(event) => handleInputChangeSelect(event, index)}
                                        placeholder='Ingrese Observación'
                                      />
                                    ) : item.tipo[0].nombre === 'Imagen' ||
                                      item.tipo[0].nombre === 'carga de documentos' ? (
                                      <input
                                        type='text'
                                        style={{border: '1px solid orange'}}
                                        className={`form-control form-control-sm form-control-solid`}
                                        name={item._id}
                                        value={
                                          item.observacion?.[Number(item.observacion.length) - 1]
                                            ?.estado_abierto
                                            ? String(
                                                item.observacion[Number(item.observacion.length) - 1]
                                                  .value
                                              )
                                            : ''
                                        }
                                        onChange={(event) => handleFilesSelected(event, index)}
                                        placeholder='Ingrese Observación'
                                      />
                                    ) : (
                                      <input
                                        type='text'
                                        style={{border: '1px solid orange'}}
                                        className={`form-control form-control-sm form-control-solid`}
                                        name={item._id}
                                        value={
                                          item.observacion?.[Number(item.observacion.length) - 1]
                                            ?.estado_abierto
                                            ? String(
                                                item.observacion[Number(item.observacion.length) - 1]
                                                  .value
                                              )
                                            : ''
                                        }
                                        onChange={(event) => handleInputChange(event, index)}
                                        placeholder='Ingrese Observación'
                                      />
                                    )}
                                  </div>
                                </div>
                                {item.observacion &&
                                  !item.observacion?.[0]?.estado_abierto &&
                                  item.observacion.length > 0 && (
                                    <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
                                      <div className='mb-0'>
                                        <div
                                          className='accordion-header py-3 d-flex collapsed'
                                          data-bs-toggle='collapse'
                                          data-bs-target={`#kt_accordion_2_item_${item._id}`}
                                        >
                                          <span className='accordion-icon'>
                                            <KTSVG
                                              className='svg-icon svg-icon-4'
                                              path='/media/icons/duotune/arrows/arr064.svg'
                                            />
                                          </span>
                                          <h6 className='fs-6 text-primary fw-bold mb-0 ms-4'>
                                            Historial
                                          </h6>
                                        </div>
                                        <div
                                          id={`kt_accordion_2_item_${item._id}`}
                                          className='fs-6 collapse ps-10'
                                          data-bs-parent='#kt_accordion_2'
                                        >
                                          <div
                                            className='grid-container-obs'
                                            style={{position: 'relative'}}
                                          >
                                            <div
                                              className='grid-item'
                                              style={{
                                                marginLeft: '10px',
                                                marginTop: '8px',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Fecha
                                            </div>
                                            <div
                                              className='grid-item'
                                              style={{
                                                marginLeft: '10px',
                                                marginTop: '8px',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Antes
                                            </div>
                                            <div
                                              className='grid-item'
                                              style={{
                                                marginLeft: '10px',
                                                marginTop: '8px',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              Actual
                                            </div>
                                          </div>
                                          {item.observacion.map(
                                            (obs) =>
                                              !obs.estado_abierto && (
                                                <div
                                                  className='grid-container-obs'
                                                  style={{position: 'relative'}}
                                                >
                                                  <div
                                                    className='grid-item'
                                                    style={{
                                                      marginLeft: '10px',
                                                      marginTop: '8px',
                                                      fontWeight: 'normal',
                                                    }}
                                                  >
                                                    {format(
                                                      parseISO(String(obs.fecha)),
                                                      'dd/MM/yyyy hh:mm a'
                                                    )}
                                                  </div>
                                                  <div
                                                    className='grid-item'
                                                    style={{
                                                      marginLeft: '5px',
                                                      marginTop: '8px',
                                                      fontWeight: 'normal',
                                                    }}
                                                  >
                                                    {obs.data_observada}
                                                  </div>
                                                  <div
                                                    className='grid-item'
                                                    style={{
                                                      marginLeft: '5px',
                                                      marginTop: '8px',
                                                      fontWeight: 'normal',
                                                    }}
                                                  >
                                                    {obs.data_correguida}
                                                  </div>
                                                </div>
                                              )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            )}
                          </Draggable>                      
                        }
                      </>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          
        </>
      ) : (
        <>
          {formDataSeccion.secciones?.map((secciones, item) => (
            <div className='mt-2'>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {(secciones.data || []).map((datSec, index) => (
                        <Draggable draggableId={`Cam` + datSec._id + '-' + index} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className='grid-container-obs' style={{position: 'relative'}}>
                                <div
                                  className='grid-item'
                                  style={{marginLeft: '10px', marginTop: '8px', fontWeight: 'bold'}}
                                >
                                  {datSec.titulo}
                                </div>
                                <div
                                  className='grid-item'
                                  style={{
                                    marginLeft: '5px',
                                    marginTop: '8px',
                                    fontWeight: 'normal',
                                  }}
                                >
                                  {tiposCampos.includes(datSec.tipo[0].nombre) ? (
                                    <label> {datSec.values} </label>
                                  ) : datSec.tipo[0].nombre === 'Lista desplegable BD' ? (
                                    <label>
                                      {' '}
                                      {datSec.selectOpion?.label
                                        ? datSec.selectOpion.label
                                        : ''}{' '}
                                    </label>
                                  ) : datSec.tipo[0].nombre === 'Imagen' ||
                                    datSec.tipo[0].nombre === 'carga de documentos' ? (
                                    datSec.valuesFiles.map((value: any, idx: string | number) => (
                                      <>
                                        <b>
                                          *{' '}
                                          <a
                                            className='link-primary ml-2'
                                            href={`${value}`}
                                            target='_blank'
                                            rel='noreferrer'
                                            onClick={(e) => {
                                              const sep = value.name.split('.')
                                              const ext = sep[sep.length - 1]
                                              e.preventDefault()
                                              handleViewModal(value.url, value.name, ext)
                                            }}
                                          >
                                            {datSec.infoFiles[Number(idx)].name}
                                          </a>
                                        </b>{' '}
                                        <br></br>
                                      </>
                                    ))
                                  ) : datSec.tipo[0].nombre === 'Firma' ? (
                                    <>
                                      <b>
                                        *{' '}
                                        <a
                                          className='link-primary ml-2'
                                          href={`${datSec.values}`}
                                          target='_blank'
                                          rel='noreferrer'
                                        >
                                          Firma
                                        </a>
                                      </b>
                                    </>
                                  ) : (
                                    datSec.values
                                  )}
                                </div>
                                <div className='grid-item' style={{marginLeft: '5px'}}>
                                  {datSec.tipo[0].nombre === 'Lista desplegable BD' ? (
                                    <input
                                      type='text'
                                      style={{border: '1px solid orange'}}
                                      className={`form-control form-control-sm form-control-solid`}
                                      name={datSec._id}
                                      value={
                                        datSec.observacion?.[Number(datSec.observacion.length) - 1]
                                          ?.estado_abierto
                                          ? String(
                                              datSec.observacion[
                                                Number(datSec.observacion.length) - 1
                                              ].value
                                            )
                                          : ''
                                      }
                                      onChange={(event) =>
                                        handleInputChangeSelect2(event, item, index)
                                      }
                                      placeholder='Ingrese Observación'
                                    />
                                  ) : datSec.tipo[0].nombre === 'Imagen' ||
                                    datSec.tipo[0].nombre === 'carga de documentos' ? (
                                    <input
                                      type='text'
                                      style={{border: '1px solid orange'}}
                                      className={`form-control form-control-sm form-control-solid`}
                                      name={datSec._id}
                                      value={
                                        datSec.observacion?.[Number(datSec.observacion.length) - 1]
                                          ?.estado_abierto
                                          ? String(
                                              datSec.observacion[
                                                Number(datSec.observacion.length) - 1
                                              ].value
                                            )
                                          : ''
                                      }
                                      onChange={(event) => handleFilesSelected2(event, item, index)}
                                      placeholder='Ingrese Observación'
                                    />
                                  ) : (
                                    <input
                                      type='text'
                                      style={{border: '1px solid orange'}}
                                      className={`form-control form-control-sm form-control-solid`}
                                      name={datSec._id}
                                      value={
                                        datSec.observacion?.[Number(datSec.observacion.length) - 1]
                                          ?.estado_abierto
                                          ? String(
                                              datSec.observacion[
                                                Number(datSec.observacion.length) - 1
                                              ].value
                                            )
                                          : ''
                                      }
                                      onChange={(event) => handleInputChange2(event, item, index)}
                                      placeholder='Ingrese Observación'
                                    />
                                  )}
                                </div>
                              </div>
                              {datSec.observacion &&
                                !datSec.observacion?.[0]?.estado_abierto &&
                                datSec.observacion.length > 0 && (
                                  <div
                                    className='accordion accordion-icon-toggle'
                                    id='kt_accordion_2'
                                  >
                                    <div className='mb-0'>
                                      <div
                                        className='accordion-header py-3 d-flex collapsed'
                                        data-bs-toggle='collapse'
                                        data-bs-target={`#kt_accordion_2_item_${datSec._id}`}
                                      >
                                        <span className='accordion-icon'>
                                          <KTSVG
                                            className='svg-icon svg-icon-4'
                                            path='/media/icons/duotune/arrows/arr064.svg'
                                          />
                                        </span>
                                        <h6 className='fs-6 text-primary fw-bold mb-0 ms-4'>
                                          Historial
                                        </h6>
                                      </div>
                                      <div
                                        id={`kt_accordion_2_item_${datSec._id}`}
                                        className='fs-6 collapse ps-10'
                                        data-bs-parent='#kt_accordion_2'
                                      >
                                        <div
                                          className='grid-container-obs'
                                          style={{position: 'relative'}}
                                        >
                                          <div
                                            className='grid-item'
                                            style={{
                                              marginLeft: '10px',
                                              marginTop: '8px',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            Fecha
                                          </div>
                                          <div
                                            className='grid-item'
                                            style={{
                                              marginLeft: '10px',
                                              marginTop: '8px',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            Antes
                                          </div>
                                          <div
                                            className='grid-item'
                                            style={{
                                              marginLeft: '10px',
                                              marginTop: '8px',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            Actual
                                          </div>
                                        </div>
                                        {datSec.observacion.map(
                                          (obs) =>
                                            !obs.estado_abierto && (
                                              <div
                                                className='grid-container-obs'
                                                style={{position: 'relative'}}
                                              >
                                                <div
                                                  className='grid-item'
                                                  style={{
                                                    marginLeft: '10px',
                                                    marginTop: '8px',
                                                    fontWeight: 'normal',
                                                  }}
                                                >
                                                  {format(
                                                    parseISO(String(obs.fecha)),
                                                    'dd/MM/yyyy hh:mm a'
                                                  )}
                                                </div>
                                                <div
                                                  className='grid-item'
                                                  style={{
                                                    marginLeft: '5px',
                                                    marginTop: '8px',
                                                    fontWeight: 'normal',
                                                  }}
                                                >
                                                  {obs.data_observada}
                                                </div>
                                                <div
                                                  className='grid-item'
                                                  style={{
                                                    marginLeft: '5px',
                                                    marginTop: '8px',
                                                    fontWeight: 'normal',
                                                  }}
                                                >
                                                  {obs.data_correguida}
                                                </div>
                                              </div>
                                            )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ))}
        </>
      )}

      {cargandoFile && <OverlayLoading />}
      <ShowFileModal />
    </>
  )
}

export {DraggableFieldList}
