import { useMemo, useState, useContext, useEffect, Fragment } from 'react'
import { Form, Stack } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import { KTCardBody, KTIcon } from '../../../../../_metronic/helpers'
import {
  showDeleteMessage,
  showMessage,
  showReactivationMessage,
} from '../../../../shared/Alerts'
import { UsersListLoading } from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import { useQueryResponseData } from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import { usersColumns } from '../../../apps/user-management/users-list/table/columns/_columns'
import Pagination from '../../../../shared/Pagination'
import { deletePartner, updatePartner } from '../../../../services/colaboradores'
import { PartnerContext } from '../PartnerContexts'
import React from 'react'
import { activarUser, deleteUser, getAllUsersPag } from '../../../../services/users'
import { activarRol, deleteRol, getAllRolsPag } from '../../../../services/rol'

const PerfilTable = () => {
  const users = useQueryResponseData()
  // const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const { getTableBodyProps } = useTable({
    columns,
    data,
  })

  const [isLoading, setLoadingStatus] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [lista, setLista] = useState([])
  const { searchTerm, setPerfilData, setEdit, reloadTable, setReloadTable} =
    useContext(PartnerContext)

  useEffect(() => {
    setLoadingStatus(true)
    refetchData({
      searchQuery: searchTerm
    })
  }, [])

  useEffect(() => {
    setLoadingStatus(true)
    refetchData({
      searchQuery: searchTerm,
    })
  }, [searchTerm])

  useEffect(() => {
    setLoadingStatus(true)
    if(reloadTable){
      refetchData({
        searchQuery: searchTerm,
      })
      setReloadTable(false)
    }
  }, [reloadTable])



  const refetchData = (options: any) => {
    setLoadingStatus(true)
    getAllRolsPag(currentPage,10,options)
      .then((response) => {
        if (response.success) {
          console.log(response)
          setLoadingStatus(false)
          const auxTotalPage = response.length? Math.ceil(response.length/10): totalPages
          setTotalPages(auxTotalPage)
          setLista(response?.data)
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // Utility function to generate pagination links based on the current page and totalPages values
  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    // Generate "Previous" link if not on the first page
    if (currentPage > 1) {
      links.push({ page: currentPage - 1, label: 'Anterior' })
    }

    // Generate numbered pages
    for (let i = 1; i <= totalPages; i++) {
      links.push({ page: i, label: String(i) })
    }

    // Generate "Next" link if not on the last page
    if (currentPage < totalPages) {
      links.push({ page: currentPage + 1, label: 'Siguiente' })
    }

    return links
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    
  }

  const handleDelete = (id: string) => {
    showDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        deleteRol(id)
          .then((response) => {
            if (response.success) {
              console.log(response)
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              console.log(response)
              showMessage('error', 'Hubo un error', response.error)
            }
            refetchData({
              searchQuery: searchTerm,
            })
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message))
      }
    })
  }


  const handleReactivation = (id: string) => {
    showReactivationMessage().then((result) => {
      if (result.isConfirmed) {
        activarRol(id)
          .then((response) => {
            if (response.success) {
              console.log(response)
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              console.log(response)
              showMessage('error', 'Hubo un error', response.error)
            }
            refetchData({
              searchQuery: searchTerm,
            })
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message))
      }
    })
  }

  const handleActualizar = (item:any) => {
    setEdit(true)
    setPerfilData(item)
  }

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th className=''>Cod</th>
                <th className='text-start'>Nombre</th>
                <th className='text-start'>Rol</th>
                <th className='text-end'>Opciones</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {lista.length > 0 ? (
                lista?.map((item: any, index: any) => (
                  <Fragment key={item._id}>
                    <tr>
                      <td>{item.codigo_perfil}</td>
                      <td className='text-start min-w-100px'>{item.nombre_perfil}</td>
                      <td className='text-start min-w-100px'>{item.rol}</td>

                      <td className='text-end d-flex align-self-end justify-content-end'>
                        <Stack direction='horizontal' gap={1}>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-light-info btn-sm'
                            title='Editar'
                            onClick={() => handleActualizar(item)}
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </a>
                          { item.estado? 
                              <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-light-danger btn-sm'
                                title='Eliminar'
                                onClick={() => handleDelete(item._id)}
                              >
                                <KTIcon iconName='cross' className='fs-3' />
                              </a>
                            :
                            <a
                              href='#'
                              className='btn btn-icon btn-bg-light btn-light-success btn-sm'
                              title='Activar'
                              onClick={() => handleReactivation(item._id)}
                            >
                              <KTIcon iconName='check' className='fs-3' />
                            </a>
                            
                          }
                          
                        </Stack>
                      </td>
                    </tr>
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export { PerfilTable }
