import { Badge, ListGroup } from "react-bootstrap"
import { KTIcon } from "../../../../../_metronic/helpers"
import { getFieldById } from "../../../../services/campos"
import { useEffect, useState } from "react"

const FilterListItem = ({ item, index, onEditClick, onDeleteClick }: any) => {

    const [field, setField] = useState({
        _id: '',
        titulo: ''
    })

    useEffect(() => {
        getField()
    }, [item])

    const getField = () => {
        getFieldById(item.field)
            .then((response) => {
                if (response.success) {
                    setField(response.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const toFriendlyOperator = (operator: string) => {

        let response = ''

        switch (operator) {
            case 'equals':
                response = "Es igual a"
                break;
            case 'notEquals':
                response = "Es diferente a"
                break;
            case 'contains':
                response = "Contiene"
                break;
            default:
                response = ''
                break;
        }

        return response
    }

    return (<>
        <ListGroup.Item key={item.id} className="d-flex align-items-center justify-content-between">
            <Badge
                bg='success-active'
                className='me-2'
            >
                {index}
            </Badge>
            <div>
                <span className="fw-bold">{field.titulo}</span>
            </div>
            <div>
                <span className="fw-bolder text-primary">{toFriendlyOperator(item.operator)}</span>
            </div>
            <div>
                <span className="fw-bold">{item.value}</span>
            </div>
            <div>
                <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    title='Editar Filtro'
                    onClick={() => onEditClick(item.id)}
                >
                    <KTIcon iconName='pencil' className='fs-3' />
                </a>
                <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    title='Eliminar Filtro'
                    onClick={() => onDeleteClick(item.id)}
                >
                    <KTIcon iconName='trash' className='fs-3' />
                </a>
            </div>
        </ListGroup.Item>
    </>)
}

export { FilterListItem }