import { createContext } from "react"
import { Campos, DataTabla, Ficha, ProcesoFichas, Seccion, Tabla } from "../../../../documentacion/MiFicha/FIchaInterface"
import { Doc } from "../../../enviodocumento/enviodocumentInterface"

interface ColaboradorObservacionContextProps {
    procesoFichaData: ProcesoFichas[],
    setProcesoFichaData: (value: ProcesoFichas[]) => void,
    fichas: Ficha[],
    setFichas: (value: Ficha[]) => void,
    seccion: Seccion[],
    setSeccion: (value: Seccion[]) => void,
    campos: Campos[],
    setCampos: (value: Campos[]) => void,
    secciones: Tabla[],
    setSecciones: (value: Tabla[]) => void,
    dataSecciones: DataTabla[],
    setDataSecciones: (value: DataTabla[]) => void,
    message: string,
    setMessage: (value: string) => void,
    handleInputObservacionChange: (value0:number,value1:number,value3:Seccion) => void,

    showFileModal: boolean,
    setShowFileModal: (value: boolean) => void,
    viewDocumento: Doc[],
    setViewDocumento: (value: Doc[]) => void,
    cargarDocumentModal: boolean,
    openCargarDocumentModal: () => void,
    closeCargarDocumentModal: () => void,
}

const ColaboradorObservacionContext = createContext<ColaboradorObservacionContextProps>({
    procesoFichaData: [],
    setProcesoFichaData: (value) => {},
    fichas: [],
    setFichas: (value) => {},
    seccion: [],
    setSeccion: (value) => {},
    campos: [],
    setCampos: (value) => {},
    secciones: [],
    setSecciones: (value) => {},
    dataSecciones: [],
    setDataSecciones: (value) => {},
    message: '',
    setMessage: (value) => {},
    handleInputObservacionChange: (value) => {},
    showFileModal: false,
    setShowFileModal: (value) => { },
    viewDocumento: [],
    setViewDocumento: (value) => { },
    cargarDocumentModal: false,
    openCargarDocumentModal: () => { },
    closeCargarDocumentModal: () => { },
})

export {ColaboradorObservacionContext}