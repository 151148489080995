import React, { useContext, useEffect, useState } from 'react';

import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Spinner } from 'react-bootstrap';

import './PDFViewer.css';
import { Doc, PDFViewerProps } from '../DocumentTray.interface';
import { downloadFileS3, getFileInPDF } from '../../../../services/files';
import { DocumentTrayContext } from '../DocumentTrayContexts';


const PDFViewer = () => {
  const [pdfData, setPdfData] = useState<Doc[]>([]);
  const [loading, setLoading] = useState<boolean>(true); 

  const { keyname, fromPreviewTab } = useContext(DocumentTrayContext)

  const fetchPdfData = () => {
    getFileInPDF(keyname, fromPreviewTab)
    .then((response) => {
      if (response.success) {

        const doc = [
          {
            uri: response.data,
            fileType: 'pdf',
            fileName: 'test',
          },
        ];

        setPdfData(doc)
      } else {
        console.log(response)
      }
    })
    .catch((error) => {
      console.log(error)
    })
    .finally(() => {
      setLoading(false)
    })
  };

  useEffect(() => {
    fetchPdfData();
  }, []);

  return (
    <div className="pdf-viewer-container">
      {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" role="status">
            <span className="sr-only">Cargando...</span>
          </Spinner>
        </div>
      ) : (
        <DocViewer
          documents={pdfData}
          pluginRenderers={DocViewerRenderers}
          style={{ height: 850 }}
          theme={{
            primary: "#ffffff",
            secondary: "#5C5C9C",
            tertiary: "#5296d899",
            textPrimary: "#ffffff",
            textSecondary: "#5296d8",
            textTertiary: "#00000099",
            disableThemeScrollbar: false,
          }}
          config={{ header: { disableHeader: true } }}
        />
      )}
    </div>
  );
};

export default PDFViewer;
