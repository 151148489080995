import React from 'react'
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import { IconName } from '../../shared/KTIIconKeys'
import { Placement } from 'react-bootstrap/esm/types'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: React.ReactNode
  children?: React.ReactNode
  icon?: IconName
  iconClass?: string,
  placement?: Placement
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'link' | 'outline-dark' | 'outline-light' | 'outline-primary' | 'outline-secondary' | 'outline-success' | 'outline-danger' | 'outline-warning' | 'outline-info' | 'outline-dark'
}

export const CustomTooltip: React.FC<Props> = ({
  title,
  children,
  label,
  icon,
  placement = 'right',
  onClick,
  className,
  iconClass,
  disabled,
  variant = 'primary',
  ...props
}) => {
  const renderTooltip = (props: any) => (
    <Tooltip id='button-tooltip' {...props}>
      {children}
    </Tooltip>
  )

  return (
    <OverlayTrigger placement={placement} delay={{show: 250, hide: 400}} overlay={renderTooltip}>
      <Button
        disabled={disabled}
        title={title}
        onClick={onClick}
        className={className}
        variant={variant}
        {...props}
      >
        {label} {icon && <KTIcon iconName={icon} className={iconClass} />}
      </Button>
    </OverlayTrigger>
  )
}
