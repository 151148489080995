import { fetchSinToken } from "../helpers/fetch"

const generarAuthenticacionData = async (data: any) => {
  const resp = await fetchSinToken(
      `authenticacion/generate`,
      data,
      'POST'
  );
  const body = await resp.json();

  return body;
}

const validarCodigo = async (data: any) => {
  const resp = await fetchSinToken(
      `authenticacion/validar`,
      data,
      'POST'
  );
  const body = await resp.json();

  return body;
}

  
export {
    generarAuthenticacionData,
    validarCodigo
};