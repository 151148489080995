type Options = {
  key: string
  order: 'asc' | 'desc'
}

function getValue(obj: Record<string, any>, key: string): any {
  if (obj.hasOwnProperty(key)) {
    return obj[key]
  }

  for (let prop in obj) {
    if (typeof (obj as any)[prop] === 'object' && obj[prop] !== null) {
      let result = getValue(obj[prop], key)

      if (result !== undefined) {
        return result
      }
    }
  }

  return undefined
}

export const orderStrings = (array: Record<string, any>[], options: Options) => {
  const {key, order} = options
  const copyArray = JSON.parse(JSON.stringify(array)) as Record<string, any>[]

  const length = copyArray.length > 0
  const existInAllPropertyA = copyArray.every((value) => getValue(value, key) !== undefined)

  if (!existInAllPropertyA && length)
    throw Error('asegurece que la key exista en todos los objetos')

  return copyArray.sort((a, b) =>
    order === 'asc'
      ? getValue(a, key)?.localeCompare(getValue(b, key))
      : getValue(b, key)?.localeCompare(getValue(a, key))
  )
}

export const orderDates = (array: Record<string, any>[], options: Options) => {
  const {key, order} = options
  const copyArray = JSON.parse(JSON.stringify(array)) as Record<string, any>[]

  const length = copyArray.length > 0
  const existInAllPropertyA = copyArray.every((value) => getValue(value, key) !== undefined)

  if (!existInAllPropertyA && length)
    throw Error('asegurece que la key exista en todos los objetos')

  return copyArray.sort((a, b) =>
    order === 'asc'
      ? getValue(a, key)?.getTime() - getValue(b, key)?.getTime()
      : getValue(b, key)?.getTime() - getValue(a, key)?.getTime()
  )
}
