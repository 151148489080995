import { createContext } from "react"
import { Campos, DataTabla, Ficha, ProcesoFichas, Seccion, Tabla } from "../../documentacion/MiFicha/FIchaInterface"

interface PartnerContextProps {
    activeOnly: boolean,
    state: string[],
    stateSigned: string,
    searchTerm: string,
    entryDateFrom: any,
    entryDateTo: any,
    setActiveOnly: (value: boolean) => void,
    isObservacionModalOpen: boolean,
    openObservacionModal: (value: string) => void,
    closObservacionModal: () => void,
    idRegistroFicha: string,
    procesoFichaData: ProcesoFichas[],
    setProcesoFichaData: (value: ProcesoFichas[]) => void,
    fichas: Ficha[],
    setFichas: (value: Ficha[]) => void,
    seccion: Seccion[],
    setSeccion: (value: Seccion[]) => void,
    campos: Campos[],
    setCampos: (value: Campos[]) => void,
    secciones: Tabla[],
    setSecciones: (value: Tabla[]) => void,
    dataSecciones: DataTabla[],
    setDataSecciones: (value: DataTabla[]) => void,
    handleInputObservacionChange: (value: string) => void,
    setState: (value: string[]) => void,
    setStateSigned: (value: string) => void,
    setSearchTerm: (value: string) => void,
    setEntryDateFrom: (value: any) => void,
    setEntryDateTo: (value: any) => void,
    idProceso: string,
    setIdProceso: (value: string) => void,
    unidad: string,
    setUnidad: (value: string) => void,
    Dates: [Date | null, Date | null],
    setDates: (value: [Date | null, Date | null]) => void
}

const PartnerContext = createContext<PartnerContextProps>({
    activeOnly: true,
    state: [],
    stateSigned: '',
    searchTerm: '',
    entryDateFrom: null,
    entryDateTo: null,
    setActiveOnly: (value) => {},
    setState: (value) => {},
    setStateSigned: (value) => {},
    isObservacionModalOpen: false,
    openObservacionModal: (value) => {},
    closObservacionModal: () => {},
    idRegistroFicha: '',
    procesoFichaData: [],
    setProcesoFichaData: (value) => {},
    fichas: [],
    setFichas: (value) => {},
    seccion: [],
    setSeccion: (value) => {},
    campos: [],
    setCampos: (value) => {},
    secciones: [],
    setSecciones: (value) => {},
    dataSecciones: [],
    setDataSecciones: (value) => {},
    handleInputObservacionChange: (value) => {},
    setSearchTerm: (value) => {},
    setEntryDateFrom: (value: any) => {},
    setEntryDateTo: (value: any) => {},
    idProceso: "",
    setIdProceso: (value) => {},
    unidad: "", 
    setUnidad: (value) => {}, 
    Dates: [null, null],
    setDates: (value) => {}
})

export { PartnerContext }

