import React from 'react'
import {DetalleNivelContent} from './DetalleNivelContent'
import {DetalleNivelHeader} from './DetalleNivelHeader'

interface Props {
  filename: string
  historial: Record<string, any>[]
  grupos: Record<string, any>[]
  closeModal: () => void
}

const DetalleNivelModal: React.FC<Props> = (props) => {
  return (
    <>
      <div>
        <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
          <div className='modal-dialog modal-xl modal-dialog-centered mw-800px'>
            <div className='modal-content'>
              <DetalleNivelHeader filename={props.filename} closeModal={props.closeModal} />
              <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                <DetalleNivelContent grupos={props.grupos} historial={props.historial} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {DetalleNivelModal}
