import {useContext} from 'react'

import {PartnerContext} from '../PartnerContexts'
import {TagsEditModalFormWrapper} from './TagsEditModalFormWrapper'
import {TagsEditModalHeader} from './TagsEditModalHeader'

const TagsEditModal = () => {
  const {isTagsModalOpen} = useContext(PartnerContext)

  return (
    <>
      {isTagsModalOpen && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered modal-lg'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <TagsEditModalHeader />
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <TagsEditModalFormWrapper />
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {TagsEditModal}
