import React, {useContext, useEffect, useMemo, useState} from 'react'
import {Button, Col, Form, ListGroup, Row} from 'react-bootstrap'
import Select from 'react-select'
import {getProcesoFichas} from '../../../../services/procesos'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {PdfLoadingSpinner} from '../../../generales_component/PdfLoadingSpinner'
import {AltaContext} from '../AltaContext'
import {Ficha, FieldsSelected} from '../types/GenTypes'

const ExportFieldBody: React.FC = () => {
  const {
    onCloseFieldModal,
    processId,
    sheetFields,
    setSheetFields,
    fieldSelected,
    setFieldSelected,
    sheetId,
    setSheetId,
  } = useContext(AltaContext)
  const [sheets, setSheets] = useState<Ficha[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [fieldsSelectedAux, setFieldsSelectedAux] = useState<FieldsSelected>(fieldSelected)
  const {userData} = useAuth2()

  const sheetsOptions = sheets.map((x) => ({value: x.id, label: x.nombre}))
  const sheetSelected = useMemo(() => {
    return sheetFields.find((x) => x.id === sheetId)
  }, [sheetId])

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchQuery(event.target.value)
  }

  const handleCheckOne = (sec: string, field: string, checked: boolean) => {
    setFieldsSelectedAux((prev) => ({
      ...prev,
      [sec]: {
        ...prev[sec],
        [field]: checked,
      },
    }))
  }

  const checkIfHasKeys = (key1: string, key2: string) => {
    if (Object.keys(fieldsSelectedAux).length > 0) {
      if (Object.hasOwn(fieldsSelectedAux, key1)) {
        if (Object.hasOwn(fieldsSelectedAux[key1], key2)) {
          return true
        } else {
          return false
        }
      }

      return false
    }

    return false
  }

  const onSaveFields = () => {
    const selected = Object.entries(fieldsSelectedAux)
      .map(([outerKey, objectValue]) => {
        const filtereds = Object.entries(objectValue)
          .filter(([_, value]) => value)
          .reduce((acc: any, [key, val]) => {
            acc[key] = val
            return acc
          }, {})

        return [outerKey, filtereds]
      })
      .reduce((acc: any, [key, val]) => {
        if (Object.keys(val).length > 0) {
          acc[key] = val
        }

        return acc
      }, {})

    setFieldSelected(selected)
    onCloseFieldModal()
  }

  useEffect(() => {
    setIsLoading(true)
    getProcesoFichas(processId, userData?.userId)
      .then((response) => {
        if (response.success) {
          const sheetsFormat = response.data.map((x: any) => ({
            id: x.fichas[0]?._id,
            nombre: x.fichas[0]?.nombre,
          }))

          setSheets(sheetsFormat)
          setSheetId(sheetId ? sheetId : sheetsFormat[0].id)
          const sheetFieldsFormat = response.data.map((x: any) => ({
            id: x.fichas[0]?._id,
            nombre: x.fichas[0]?.nombre,
            seccion: x.fichas[0]?.seccion
              ?.map((sec: any) => {
                if (!sec.dinamico) {
                  return {
                    id: sec._id,
                    nombre: sec.nombre,
                    campos:
                      sec.campos?.map((cam: any) => ({
                        id: cam._id,
                        check: cam.checkExport ? cam.checkExport : false,
                        label: cam.titulo,
                        type: 'string',
                        obligatorio: cam.obligatorio,
                      })) ?? [],
                  }
                } else {
                  return null
                }
              })
              ?.filter((sec: any) => sec !== null),
          }))

          setSheetFields(sheetFieldsFormat)
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (sheetSelected) {
      setFieldsSelectedAux(
        sheetSelected.seccion.reduce((ac: FieldsSelected, sec) => {
          ac[sec.id] = sec.campos.reduce((ac: Record<string, boolean>, campo) => {
            ac[campo.id] = checkIfHasKeys(sec.id, campo.id)
              ? fieldsSelectedAux[sec.id][campo.id]
              : false
            return ac
          }, {})
          return ac
        }, {})
      )
    }
  }, [sheetSelected])

  if (isLoading) {
    return <PdfLoadingSpinner />
  }

  return (
    <>
      <Row>
        <Col sm>
          <Form.Group>
            <Form.Control
              type='text'
              placeholder='Buscar...'
              value={searchQuery}
              onChange={handleSearchChange}
              className='my-2'
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <span className='fw-bold'>Ficha:</span>
          <Select
            options={sheetsOptions}
            value={sheetsOptions.find((x) => x.value === sheetId)}
            onChange={(e) => setSheetId(e?.value ?? '')}
          />
        </Col>
      </Row>
      <Row>
        <Col sm>
          <div style={{maxHeight: '300px', overflowY: 'scroll'}}>
            {sheetSelected?.seccion?.map((group) => {
              const filteredFields = group.campos.filter((item) =>
                item.label.toLowerCase().includes(searchQuery.toLowerCase())
              )

              if (filteredFields.length === 0) {
                return null
              }

              return (
                <section key={group.id}>
                  <h4 className='my-3 ms-2 fw-bold'>{group.nombre}</h4>
                  <ListGroup style={{maxHeight: '200px', overflowY: 'auto'}}>
                    {filteredFields.map((item) => (
                      <div key={item.id}>
                        <ListGroup.Item key={item.id} className='d-flex'>
                          <Form.Check
                            type='checkbox'
                            id={`checkbox-dynamic-list-${item.id}`}
                            label={item.label}
                            checked={
                              checkIfHasKeys(group.id, item.id)
                                ? fieldsSelectedAux[group.id][item.id]
                                : false
                            }
                            onChange={(e) => handleCheckOne(group.id, item.id, e.target.checked)}
                            className='hide-validation'
                          />
                          {item.obligatorio ? (
                            <span className='mx-3' style={{color: '#FF0000'}}>
                              *
                            </span>
                          ) : (
                            ''
                          )}
                        </ListGroup.Item>
                      </div>
                    ))}
                  </ListGroup>
                </section>
              )
            })}
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <Button variant='primary' type='button' className='btn-sm my-3' onClick={onSaveFields}>
            Guardar campos seleccionados
          </Button>
        </Col>
      </Row>
    </>
  )
}

export {ExportFieldBody}
