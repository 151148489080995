import { Dispatch, FC, createContext, useState, useContext, useEffect } from "react";
import { WithChildren } from "../../../_metronic/helpers";

type AuthContextProps = {
  selectedCompanyId: string | undefined,
  setSelectedCompanyId: Dispatch<string>,
  selectedPoliticaYPrivacidad: boolean,
  setSelectedPoliticaYPrivacidad: Dispatch<boolean>,
  selectedRole: string | undefined,
  setSelectedRole: Dispatch<string>,
  userData: any,
  setUserData: Dispatch<any>,
  isCompanySelectorModalOpen: boolean,
  setCompanySelectorModalStatus: Dispatch<boolean>,
  isPoliticaYPrivacidadSelectorModalOpen: boolean,
  setPoliticaYPrivacidadSelectorModalStatus: Dispatch<boolean>
}

const getUserData = (): AuthContextProps => {
  const storedSelectedCompanyId = localStorage.getItem('selectedCompanyId') || undefined;
  const storedSelectedPoliticaYPrivacidad = Boolean(localStorage.getItem('selectedPoliticaYPrivacidad')) ;
  const storedSelectedRole = localStorage.getItem('selectedRole') || undefined;
  const storedUserData = JSON.parse(localStorage.getItem('userData') || '{}');

  return {
    selectedCompanyId: storedSelectedCompanyId,
    setSelectedCompanyId: () => { },
    selectedPoliticaYPrivacidad: storedSelectedPoliticaYPrivacidad,
    setSelectedPoliticaYPrivacidad: () => { },
    selectedRole: storedSelectedRole,
    setSelectedRole: () => { },
    userData: storedUserData,
    setUserData: () => { },
    isCompanySelectorModalOpen: false,
    setCompanySelectorModalStatus: () => { },
    isPoliticaYPrivacidadSelectorModalOpen: false,
    setPoliticaYPrivacidadSelectorModalStatus: () => { }
  };
}

const AuthContext = createContext<AuthContextProps>(getUserData());

const useAuth2 = () => {
  return useContext(AuthContext);
}

const Auth2Provider: FC<WithChildren> = ({ children }) => {

  const [selectedCompanyId, setSelectedCompanyId] = useState<string>(() => getUserData().selectedCompanyId || '');
  const [selectedPoliticaYPrivacidad, setSelectedPoliticaYPrivacidad] = useState<boolean>(() => Boolean(getUserData().selectedPoliticaYPrivacidad ) || false);
  const [selectedRole, setSelectedRole] = useState<string>(() => getUserData().selectedRole || '');
  const [userData, setUserData] = useState<any>(() => getUserData().userData);
  const [isCompanySelectorModalOpen, setCompanySelectorModalStatus] = useState<boolean>(false);
  const [isPoliticaYPrivacidadSelectorModalOpen, setPoliticaYPrivacidadSelectorModalStatus] = useState<boolean>(false);
  
  useEffect(() => {
    localStorage.setItem('selectedCompanyId', selectedCompanyId);
    localStorage.setItem('selectedRole', selectedRole);
    localStorage.setItem('selectedPoliticaPrivacidad', String(selectedPoliticaYPrivacidad));
    const terminoycondicion = userData.userTerminosYCondiciones===false?[]:userData.userTerminosYCondiciones
    const existingUserData = getUserData().userData;
    const updatedUserData = {
      ...existingUserData
    };

    localStorage.setItem('userData', JSON.stringify(updatedUserData));

    /* if(terminoycondicion.length > 0){
      if(selectedCompanyId !== ''){
        let accept = false
        terminoycondicion.map((tyc:any) => {
          if(tyc.id_empresa === selectedCompanyId){
            accept = tyc.accept
          }
        })
        if(!accept){
          setSelectedPoliticaYPrivacidad(true)
        }
      }
    }else{
      if(selectedCompanyId !== '') setSelectedPoliticaYPrivacidad(true)
    } */
  
    if(userData.userCompany.length > 0){
      if(userData.userCompany.length === 1){
        setSelectedCompanyId(userData.userCompany[0].id)
      }
  
      if (userData.userRole.length === 1) {
        setSelectedRole(userData.userRole[0])
      }
  
      if (selectedCompanyId === '' || selectedRole === '' ) {
        setCompanySelectorModalStatus(true)
      }else{
        setCompanySelectorModalStatus(false)
      }
  
      if (selectedPoliticaYPrivacidad) {
        setPoliticaYPrivacidadSelectorModalStatus(true)
      }else{
        setPoliticaYPrivacidadSelectorModalStatus(false)
      }
    }else{
      if (userData.userRole.length === 1) {
        setSelectedRole(userData.userRole[0])
      }
    }
    
    

    

    /* if (selectedCompanyId === '' || selectedRole === '' ) {
      setCompanySelectorModalStatus(true)
    }else{
      setCompanySelectorModalStatus(false)
    } */

  }, [selectedCompanyId,selectedPoliticaYPrivacidad, selectedRole, userData]);

  return (
    <AuthContext.Provider value={{ 
      selectedCompanyId, 
      setSelectedCompanyId,
      selectedPoliticaYPrivacidad, 
      setSelectedPoliticaYPrivacidad,
      userData, 
      setUserData, 
      selectedRole, 
      setSelectedRole, 
      isCompanySelectorModalOpen, 
      setCompanySelectorModalStatus, 
      isPoliticaYPrivacidadSelectorModalOpen, 
      setPoliticaYPrivacidadSelectorModalStatus }}>
      {children}
    </AuthContext.Provider>
  )
}

export { Auth2Provider, useAuth2 };