import { fetchConToken } from "../helpers/fetch";

const url = `registroficha/`
const fichas = async () => {
  const resp = await fetchConToken(
    url
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};


const getAllMisRegistroProcesos = async (data) => {
  const resp = await fetchConToken(
      `${url}misprocesos/`,
      {idusuario: data.id},
      "POST"
    );
    const body = await resp.json();
  
    /* if (body.success) {
      localStorage.setItem("token", body.token);
      localStorage.setItem("token-init-date", new Date().getTime());
    } */
  
    return body;
};

const ficha = async (id) => {
  const resp = await fetchConToken(
      `${url}ficha/`,
      {idficha: id},
      "POST"
    );
    const body = await resp.json();
  
    if (body.success) {
      localStorage.setItem("token", body.token);
      localStorage.setItem("token-init-date", new Date().getTime());
    }
  
    return body;
};


const nuevoRegistroFicha = async (data) => {
  const resp = await fetchConToken(
    `${url}add`,
    data,
    "POST"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const nuevoRegistroFichaTotal = async (data) => {
  const resp = await fetchConToken(
    `${url}add/completa`,
    data,
    "POST"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const nuevaObservacionFicha = async (data) => {
  const resp = await fetchConToken(
    `${url}add/newobservacion`,
    data,
    "POST"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const eliminarRegistroficha = async (id) => {
  const resp = await fetchConToken(
    `${url}${id}`,
    {},
    "DELETE"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const actualizarRegistroficha = async (data,id) => {
  const resp = await fetchConToken(
    `${url}editar/${id}`,
    data,
    "PUT"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const getRegistroFichaOne = async (idprocesoficha) => {
  const resp = await fetchConToken(
      `${url}find/${idprocesoficha}`
    );
    const body = await resp.json();
  
    if (body.success) {
      localStorage.setItem("token", body.token);
      localStorage.setItem("token-init-date", new Date().getTime());
    }
  
    return body;
};

const changeStatusFicha = async (id, updatedData) => {
  const resp = await fetchConToken(
      `${url}estado/${id}`,
      updatedData,
      'PUT'
  );
  const body = await resp.json();

  return body;
}


export {
  fichas,
  ficha,
  nuevoRegistroFicha,
  nuevoRegistroFichaTotal,
  nuevaObservacionFicha,
  eliminarRegistroficha,
  actualizarRegistroficha,
  getRegistroFichaOne,
  getAllMisRegistroProcesos,
  changeStatusFicha
};