import {useContext, useEffect, useState} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {PartnerContext} from '../../PartnerContexts'

const ObservacionModalHeader = () => {
  const {closObservacionModal} = useContext(PartnerContext)
  const [title, setTitle] = useState('')

  /* useEffect(() => {
    setTitle('Nueva')
    if (sectionData.id.length > 0) setTitle('Editar')
  }, []) */

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>Observación</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        onClick={() => closObservacionModal()}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {ObservacionModalHeader}
