import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Select from 'react-select';
import { getUnidadesEmpresas } from '../../../../services/companies';
import { getRoles, updateUser } from '../../../../services/users';
import { showMessage } from '../../../../shared/Alerts';
import { useAuth2 } from '../../../authv2/Auth2Provider';
import { PartnerContext } from '../PartnerContexts';

interface Unidad {
  codUnidad: string;
  DesUnid: string;
  selected?: boolean;
}

interface EmpresaDetails {
  empresaNombre: string;
  empresaId: string;
  unidad: Unidad[];
}

const EditUserModalForm = () => {
  const { userData: dataAuth } = useAuth2();
  const { closeEditUserModal, setUpdateUser, userData, enterprices } = useContext(PartnerContext);

  const getDefaultEnterprices = (ids: string[]): { label: string, value: string }[] => {
    return enterprices.filter((enterprice) => ids.includes(enterprice._id))
      .map((e) => ({ value: e._id, label: e.nombre }));
  };

  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState<any>(userData);
  const [acceptExt, setAcceptExt] = useState('.docx');
  const [shouldClear, setShouldClear] = useState(false);
  const [cargandoFile, setCargandoFile] = useState(false);
  const [listRoles, setListRoles] = useState<any[]>();
  const [selected, setSelected] = useState<any[]>();
  const [listProcessValidationError, setListProcessValidationError] = useState(false);
  const [userRoleSelected, setUserRoleSelected] = useState<string[]>([]);
  const [enterpricesSelected, setEnterpricesSelected] = useState<{ label: string, value: string }[]>(getDefaultEnterprices(userData.empresa));
  const [enterpriseDetails, setEnterpriseDetails] = useState<EmpresaDetails[]>([]);
  const [selectedUnits, setSelectedUnits] = useState<{ empresaId: string, unidad: Unidad }[]>([]);

  useEffect(() => {
    setDataUser(dataUser);
  }, [dataUser]);

  useEffect(() => {
    obtenerRoles();
  }, []);

  useEffect(() => {
    setDataUser(dataUser);
    fetchEnterpriseDetails(dataUser.empresa, dataUser._id);
  }, [dataUser]);

  const fetchEnterpriseDetails = (empresaIds: string[], userId: string) => {
    getUnidadesEmpresas(empresaIds, userId)
      .then((response) => {
        if (response.success) {
          console.log('Detalles de empresas:', response.data);
          const filteredData = response.data.filter((empresa: EmpresaDetails) => empresa.unidad.length > 0);
          setEnterpriseDetails(filteredData);
          const initialSelectedUnits = filteredData.flatMap((empresa: EmpresaDetails) => 
            empresa.unidad.filter(unidad => unidad.selected).map(unidad => ({
              empresaId: empresa.empresaId,
              unidad: unidad
            }))
          );
          setSelectedUnits(initialSelectedUnits);
        } else {
          showMessage('error', 'Hubo un error al obtener los detalles de las empresas', response.error);
        }
      })
      .catch((error) => {
        console.error('Error al obtener los detalles de las empresas:', error);
        showMessage('error', 'Hubo un error al obtener los detalles de las empresas', error.message);
      });
  };

  const handleActualizarUser = () => {
    setLoading(true);
    const data = {
      unidades: selectedUnits.map(unit => ({
        empresaId: unit.empresaId,
        codUnidad: unit.unidad.codUnidad,
        DesUnid: unit.unidad.DesUnid
      }))
    };
    updateUser(dataUser._id, data)
      .then((response) => {
        if (response.success) {
          limpiarCampos();
          showMessage('success', 'exitoso', response.message);
          setUpdateUser(true);
          closeEditUserModal();
        } else {
          console.log(response);
          showMessage('error', 'Hubo un error', response.error);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        limpiarCampos();
        setLoading(false);
        showMessage('error', 'Hubo un error', error.message);
      });
  };

  const obtenerRoles = () => {
    getRoles()
      .then((response: any) => {
        if (response.success) {
          const rolesAll = response.data?.map((x: any) => ({ label: x.nombre_perfil, value: x.rol }));
          const select: any[] = rolesAll?.filter((x: any) => dataUser.rol.includes(x.label));
          setSelected(select);
          setListRoles(rolesAll);
          setUserRoleSelected(select.map(s => s.value));
        } else {
          console.log(response);
          showMessage('error', 'Hubo un error', response.error);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const limpiarCampos = () => {
    setDataUser({ _id: '', idproceso: '', name: '', file: '', filename: '', keyname: '', type: 'PLANTILLA' });
    setShouldClear(true);
  };

  const handleUnitChange = (selectedOptions: any, empresaId: string) => {
    const newSelectedUnits = selectedOptions.map((option: any) => ({
      empresaId,
      unidad: {
        codUnidad: option.value,
        DesUnid: option.label
      }
    }));
    setSelectedUnits((prevUnits) => [
      ...prevUnits.filter(unit => unit.empresaId !== empresaId),
      ...newSelectedUnits
    ]);
  };

  const processOwnersValidation = (owners: string[]) => {
    if (owners.length === 0) {
      setListProcessValidationError(true);
    } else {
      setListProcessValidationError(false);
    }
  };

 
  return (
    <>
      <Form>
        <Container>
          <Row className='custom-row mb-3 pt-3 px-3'>
            <Form>
              <Col sm>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex align-items-end'>
                    <Form.Label style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      Modificar Unidades
                    </Form.Label>
                  </div>
                </div>
              </Col>
              <Row className='mb-3'>
                <Col sm>
                  <Form.Group controlId='formNombre'>
                    <Form.Label>Usuario</Form.Label>
                    <Form.Control
                      type='text'
                      disabled
                      placeholder='Ingrese un nombre'
                      name='name'
                      value={dataUser.nombre}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {enterpriseDetails.map((empresa, index) => (
                <div key={index}>
                  <Form.Group className='mb-3' controlId={`formEmpresas-${index}`}>
                    <Form.Label>{empresa.empresaNombre}</Form.Label>
                    <Select
                      isMulti
                      name={`unidades-${index}`}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      placeholder='Seleccione'
                      options={empresa.unidad.map(unidadPorId => ({ value: unidadPorId.codUnidad, label: unidadPorId.DesUnid }))}
                      defaultValue={empresa.unidad.filter(unidadPorId => unidadPorId.selected).map(unidadPorId => ({ value: unidadPorId.codUnidad, label: unidadPorId.DesUnid }))}
                      onChange={(selectedOptions) => handleUnitChange(selectedOptions, empresa.empresaId)}
                    />
                  </Form.Group>
                </div>
              ))}

              <Row>
                <div className='d-flex justify-content-end my-3'>
                  <Button
                    variant='light'
                    className='mx-3'
                    type='reset'
                    onClick={() => {
                      closeEditUserModal();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant='primary'
                    type='button'
                    disabled={enterpricesSelected.length === 0 || userRoleSelected.length === 0}
                    onClick={() => {
                      handleActualizarUser();
                    }}
                  >
                    {!loading && 'Actualizar'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Subiendo...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </Button>
                </div>
              </Row>
            </Form>
          </Row>
        </Container>
      </Form>
    </>
  );
};

export { EditUserModalForm };

