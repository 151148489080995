import { JsonTable } from 'react-json-to-html';

const JsonToTableViewer = (data: any) => {

    return (
        <JsonTable json={data} />
    )

}

export { JsonToTableViewer }