import moment from 'moment'
import React from 'react'
import {Container, Row} from 'react-bootstrap'

interface Props {
  grupos: Record<string, any>[]
  historial: Record<string, any>[]
}

const DetalleNivelContent: React.FC<Props> = (props) => {
  return (
    <Container>
      <Row>
        <h1>Grupos</h1>
        <div>
          <div className='d-grid gap-2' style={{gridTemplateColumns: '1fr 1fr'}}>
            {props.grupos.map((grupo) => (
              <div key={grupo._id}>
                <h4>{grupo.detalle.grupoaprobacion.toUpperCase()}</h4>
                <p>
                  <ul>
                    {grupo.detalle.responsables.map((user: any) => (
                      <li key={user.id}>{user.nombre}</li>
                    ))}
                  </ul>
                </p>
              </div>
            ))}
          </div>
        </div>
      </Row>
      <Row>
        <h1>Historial</h1>
        {props.historial != null
          ? props.historial.length > 0
            ? props.historial.map((historial: any, index: number) => (
                <div
                  key={index}
                  className='p-3 border-bottom border-black d-flex flex-column gap-1 align-items-start'
                  style={{fontSize: '1rem'}}
                >
                  <p className='mb-0 text-start'>
                    Nivel {props.grupos.findIndex((n: any) => n._id === historial.idLevel) + 1}
                  </p>
                  <p
                    className={`mb-0 text-start ${
                      historial.estado === 'rechazado' ? 'text-danger' : 'text-success'
                    }`}
                  >
                    Estado: {historial.estado}
                  </p>
                  <p className='mb-0 text-start'>
                    {historial.estado === 'rechazado' ? 'Rechazado por: ' : 'Aprobado por: '}{' '}
                    {historial.username}
                  </p>
                  <p className='mb-0 text-start'>
                    Fecha: {moment(historial.fecha).format('DD/MM/YYYY H:mm')}
                  </p>
                  {historial.motivo != null && (
                    <p className='mb-0 text-start'>{historial.motivo}</p>
                  )}
                </div>
              ))
            : 'Sin historial'
          : 'Sin historial'}
      </Row>
    </Container>
  )
}

export {DetalleNivelContent}
