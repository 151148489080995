import { Tabs, Tab, Row, Col, Button } from "react-bootstrap"
import { DocumentTrayCustom1 } from "./DocumentTrayCustom1"
import { useState } from "react"
import { DocumentTrayCustom2 } from "./DocumentTrayCustom2";

const DocumentTrayTabs = () => {

    const [reloadKey, setReloadKey] = useState(0);

    const handleChange = () => {
        setReloadKey(reloadKey + 1)
    }

    return (
        <>
            <Tabs
                defaultActiveKey="pending"
                className="mb-3"
            >
                <Tab eventKey="pending" title="Asignacion Empresa">
                    <DocumentTrayCustom1 />
                </Tab>
                <Tab eventKey="signed" title="Asignacion Unidad">
                    <DocumentTrayCustom2 />
                </Tab>

            </Tabs>
        </>
    )
}

export { DocumentTrayTabs }