import { Col, Container, Form, Row, Stack } from 'react-bootstrap'
import { KTIcon } from '../../../../../_metronic/helpers'
import { useContext, useEffect, useState } from 'react'
import { PartnerContext } from '../PartnerContexts'
import { useDebounce } from 'use-debounce'
import { TableSearch } from '../../../../shared/TableSearch'
import { generateExcel, generateSimplifiedExcel } from '../../../../services/fichas'
import { getAllPartnersPag } from '../../../../services/colaboradores'
import * as excelJs from 'exceljs';
import { formatDateToDMY, formatISODateToDMYHM } from '../../../../helpers/dateFunctions'
import { getAllUsersPag } from '../../../../services/users'
import { useAuth2 } from '../../../authv2/Auth2Provider'

const GrupoAprobacionListHeader = () => {
  const buttonsData = [
    {
      iconName: 'verify',
      title: 'Borrador',
      stateName: 'borrador',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Completado',
      stateName: 'completado',
      color: '#2ecc71', // Green
    }
  ]

  const [activeButtons, setActiveButtons] = useState(buttonsData.map(() => false))
  const { userData, selectedCompanyId } = useAuth2()
  const { searchTerm, setSearchTerm, setOpenShowModal} =
    useContext(PartnerContext)

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }


  return (
    <>
      <div className='mx-8 mt-8 mb-2'>
        <Row>
          <Col>
            <span className='fw-bold'>Buscar</span>
            <Stack className=' mt-3' direction='horizontal' gap={3}>
              <TableSearch onSearch={handleSearch} design={true} />
            </Stack>
          </Col>
          {/* <Col>
            <button type='button' className='btn btn-primary' onClick={()=>setOpenShowModal(true)}>
              <KTIcon iconName='plus' className='fs-2' />
              Agregar
            </button>
          </Col> */}
          <Col md='auto'>
            <Stack direction='horizontal' gap={2}>
              <Stack direction='horizontal' gap={2}>
                <button type='button' className='btn btn-primary' onClick={()=>setOpenShowModal(true)}>
                  <KTIcon iconName='plus' className='fs-2' />
                  Agregar
                </button>
              </Stack>
            </Stack>
          </Col>
        </Row>
      </div>
    </>
  )
}

export { GrupoAprobacionListHeader }
