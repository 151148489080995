const formatDateToDMY = (isoDate: any) => {
  const dateObject = new Date(isoDate)

  const day = String(dateObject.getUTCDate()).padStart(2, '0')
  const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0')
  const year = dateObject.getUTCFullYear()

  return `${day}/${month}/${year}`
}

const formatISODateToDMYHM = (isoDate: any) => {
  const dateObject = new Date(isoDate);

  const day = String(dateObject.getDate()).padStart(2, '0');
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const year = dateObject.getFullYear();
  const hour = String(dateObject.getHours()).padStart(2, '0');
  const minute = String(dateObject.getMinutes()).padStart(2, '0');

  const formattedDate = `${day}/${month}/${year} ${hour}:${minute}`;

  return formattedDate;
};

const formatISODateDetalleToDMYHM = (isoDate:any) => {
  const dateObject = new Date(isoDate);

  const day = dateObject.getDate();
  const monthNames = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
  const month = monthNames[dateObject.getMonth()];
  const year = dateObject.getFullYear();

  let hour = dateObject.getHours();
  const minute = String(dateObject.getMinutes()).padStart(2, '0');
  const ampm = hour >= 12 ? 'pm' : 'am';
  hour = hour % 12;
  hour = hour ? hour : 12; // el 0 se convierte en 12
  const formattedHour = String(hour).padStart(2, '0');

  const formattedDate = `${day} de ${month} del ${year} a las ${formattedHour}:${minute} ${ampm}`;

  return formattedDate;
};

export {
    formatDateToDMY,
    formatISODateToDMYHM,
    formatISODateDetalleToDMYHM
}
