import React, {useContext, useEffect, useState} from 'react'
import {ListGroup, Badge, Row, Col, Form, Button} from 'react-bootstrap'
// import { ListTags } from '../documentInterface';
import { showInfoCopy, showMessage } from '../../../../shared/Alerts';
import { Field, Section, Sheet } from '../../fichas/Sheet'
import { ListFieldsExport } from '../../documento/documentInterface';
import * as excelJs from 'exceljs';
import { PartnerContext } from '../PartnerContexts';
import { number, string } from 'yup';
import { actualizarCampoCheckExcel } from '../../../../services/campos';
import { group } from 'console';
import { formatDateToDMY } from '../../../../helpers/dateFunctions';
import { useGlobalOverlayLoading } from '../../../../shared/hooks/useGlobalOverlayLoading';
import { getUnidadesEmpresas } from '../../../../services/companies';
import { useAuth2 } from '../../../authv2/Auth2Provider';
import { PdfLoadingSpinner } from '../../../generales_component/PdfLoadingSpinner';

interface MyProps {
  List: ListFieldsExport[];
}

type Columns = {Ficha: string; Seccion: string; Campo: string; Type: string; Value: string}[]

const TagsListGroup: React.FC<MyProps> = ({List}) => {
  const {selectedCompanyId, userData} = useAuth2()
  const [searchQuery, setSearchQuery] = useState('')
  const [inputList, setInputList] = useState(List)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()

  const {fichaData, idficha, setIdFicha, setSheetId, sheet, loadingFichaContent} = useContext(PartnerContext)

  useEffect(()=>{
    setInputList(List)
  },[List])

  
  useEffect(()=>{
    changeDefault()
  },[inputList])
  
  const changeDefault = () => {
    const idSelected :string[] = []
    inputList.map(group => {
      group.fields.map(field => {
        if(field.check){
          idSelected.push(field.identifier)
        }
      })
    })
    setSelectedItems(idSelected)
  };
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleToggle = (id: string) => {
    const currentIndex = selectedItems.indexOf(id)
    const newSelected = [...selectedItems]
    if (currentIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelectedItems(newSelected)
  }

  const handleChangeAll = (event:any) => {
    if(event.target.checked){
      const arrSelect : string[] = []
      inputList.map(group => {
        group.fields.map(field => {
          arrSelect.push(field.identifier)
        })
      })
      setSelectedItems(arrSelect)
    }else{
      setSelectedItems([])
    }
    console.log(event.target.checked)
  };

  const handleExport = () => {
    setGlobalOverlayLoading(true)
    const sectionsFilters: Section[] = []
    const notSelectItems:string[] = []
    const columns : {Ficha:string;Seccion:string,Campo: string,Type: string,Value:string}[] = []
    sheet.sections?.map((section:Section)=>{

      if(section.filter) {
        sectionsFilters.push(section)
      }

      if(!section.dynamic){
        section.fields?.map((field:Field)=>{
          let entro = false
          selectedItems.map(item => {
            if(String(field.id) === String(item)){
              entro = true 
              if(String(field.type?.[0]?.identificador) === "dropdownlist"){
                let CadenaValue = "ComboList : [" 
                field.options?.map((e,it) => {
                  it === 0? CadenaValue += e.label : CadenaValue += ", "+e.label
                })
                CadenaValue += ']'
                columns.push({
                  Ficha:sheet.name,
                  Seccion:section.name,
                  Campo:field.name,
                  Type:field.type?.[0].identificador?String(field.type?.[0].identificador):"textbox",
                  Value:CadenaValue
                })
              }else{
                columns.push({
                  Ficha:sheet.name,
                  Seccion:section.name,
                  Campo:field.name,
                  Type:field.type?.[0].identificador?String(field.type?.[0].identificador):"textbox",
                  Value:field.type?.[0].identificador?String(field.type?.[0].identificador):"textbox"
                })
              }
            }
          })
          if(!entro){
            notSelectItems.push(field.id)
          }
        })
      }
    })
    
    columns.push({Ficha:"Fecha",Seccion:"Fecha Solicitud",Campo:"Fecha Solicitud",Type:"date",Value:"date"})
    
    actualizarCampoCheckExcel({Selected:selectedItems,NotSelected:notSelectItems})
    .then((response) => {
      if (response.success) {
        showMessage("success","Actualizacion Correctamente",response.message)
        console.log(response)
      }
    })
    .catch((error) => {
        console.log(error)
    })
    exportExcelJS(columns, sectionsFilters)
  }

  function cleanName(name: string): string {
    // Reemplaza los caracteres especiales con '-'
    let cleanName = name.replace(/[^a-zA-Z0-9 áéíóúÁÉÍÓÚ]/g, '-')
    return cleanName
  }

  const exportExcelJS = async (columns: Columns, sectionsFilters:Section[]) => {
    const workbook = new excelJs.Workbook()
    const worksheet = workbook.addWorksheet()
    const res = await getUnidadesEmpresas([selectedCompanyId as string], userData?.userId)
    const misUnidades = res.data[0]?.unidad.filter((u: any) => u.selected)

    sectionsFilters.forEach(section => {
      section.fields?.forEach(field => {
        const fieldExportExisting = columns.find(col => col.Campo === field.name) ?? null
        if (fieldExportExisting != null && fieldExportExisting.Type === 'dropdownlist_db') {
          const worksheetLeyend = workbook.addWorksheet(`Leyenda-${cleanName(field.name)}`)
          field.tablaData?.forEach((data) => {
            if (field.collectionName === 'Unidades') {
              const unidadesByUser = data.value?.filter((e) =>
                misUnidades?.some((u: any) => u.codUnidad === e.id)
              )

              unidadesByUser?.forEach((u: any) => {
                worksheetLeyend.columns = [
                  {header: field.name.toUpperCase(), key: field.id, width: 20},
                  {header: 'ID', key: u.codUnidad, width: 20},
                ]

                worksheetLeyend.addRow([u.name, u.id])
              })
            } else {
              data.value?.forEach((val) => {
                worksheetLeyend.columns = [
                  {header: field.name.toUpperCase(), key: field.id, width: 20},
                  {header: 'ID', key: val.id, width: 20},
                ]

                if (field.collectionName?.toLowerCase() === 'nominas') {
                  worksheetLeyend.addRow([val.name, val.id])
                } else {
                  worksheetLeyend.addRow([val.name, val.id])
                }
              })
            }
          })

          worksheetLeyend.getRow(1).eachCell((cell) => {
            cell.font = {
              name: 'Calibri',
              size: 14,
              bold: true,
              color: {argb: 'FFFFFF'},
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: '0A58CA'},
            }
          })
        }

      })
    })

    const objectSeccion : { [seccion: string] : number} = {}

    columns.forEach(col => {
      if(!objectSeccion[col.Seccion]){
        objectSeccion[col.Seccion] = 1
      }else{
        objectSeccion[col.Seccion] = objectSeccion[col.Seccion] + 1
      }
    })

      worksheet.mergeCells(`A1:${getExcelLetter(columns.length)}1`);
      const fichaCell = worksheet.getCell('A1');
      fichaCell.value = columns[0].Ficha;
      fichaCell.font = {
        name: 'Calibri',
        size: 14,
        bold: true,
        color: { argb: 'FFFFFF' },
      };
      fichaCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0A58CA' },
        bgColor: { argb: '' },
      };
      fichaCell.alignment = { vertical: 'middle', horizontal: 'center' };
      let Ini = 1
      let fin = 0
      Object.keys(objectSeccion).map((key,ind) => {
        fin = fin + objectSeccion[key]
        worksheet.mergeCells(`${getExcelLetter(Ini)}2:${getExcelLetter(fin)}2`)
        
        const fichaCell = worksheet.getCell(`${getExcelLetter(Ini)}2`);
        fichaCell.value = key;
        fichaCell.font = {
          name: 'Calibri',
          size: 13,
          bold: true,
          color: { argb: 'FFFFFF' },
        };
        fichaCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: getRandomColorRGB() },
          bgColor: { argb: '' },
        };
        
        fichaCell.alignment = { vertical: 'middle', horizontal: 'center' };  
        
        Ini = Ini + objectSeccion[key]      
      })


      // Adding Header Row
      const header = columns.map((el) => el.Campo);
      const headerKeys = columns.map((el) => ({ key: el.Campo }));
      const types = columns.map((el) => el.Type);
      const value = columns.map((el) => el.Value);
      const headerRow = worksheet.addRow(header);
      const contentRow = worksheet.addRow(value);
      worksheet.columns = headerKeys;
      headerRow.eachCell((cell) => {
        cell.alignment = {
          horizontal: 'center',
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DCE0F7' },
          bgColor: { argb: '' },
        };
        cell.font = {
          color: { argb: '45494E' },
          size: 12,
        };
      });
      
      contentRow.eachCell((cell) => {
        cell.alignment = {
          horizontal: 'center',
        };
        cell.font = {
          color: { argb: '45494E' },
          size: 12,
        };
        if(cell.value === "date"){
          cell.value = formatDateToDMY(new Date());
          cell.numFmt = 'dd/mm/yyyy';
        }
      });
      
      
      if (worksheet.columns) {
        worksheet.columns.forEach(function (column) {
          if (column) {
            let dataMax = 0;
            column.eachCell && column.eachCell({ includeEmpty: true }, function (cell, idx) {
              const columnLength = cell.value ? String(cell.value).length : 0;
              if (columnLength > dataMax) {
                dataMax = columnLength;
              }
            });
            if (column) {
              column.width = dataMax < 15 ? 15 : dataMax;
            }
          }
        });
      }

      worksheet.addRows([{}, {}, {}]);
      
      const rowToLock = worksheet.getRow(5);
      rowToLock.eachCell((cell) => {
        cell.protection = {
          locked: true,
        };
      });




      workbook.xlsx.writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'mi_archivo_excel.xlsx';
        a.click();
      })
      .finally(() => setGlobalOverlayLoading(false))

  }

  const getExcelLetter = (number:number) => {
    let result = '';
    while (number > 0) {
      const remainder = (number - 1) % 26;
      result = String.fromCharCode(65 + remainder) + result;
      number = Math.floor((number - 1) / 26);
    }
    return result;
  };

  const getRandomColorRGB = () =>{
    const r = 0; // Genera un valor aleatorio para el componente rojo (entre 0 y 255).
    const g = 0; // Genera un valor aleatorio para el componente verde (entre 0 y 255).
    const b = Math.floor(Math.random() * 256); // Genera un valor aleatorio para el componente azul (entre 0 y 255).
    return `${r}${g}${b})`; // Retorna el color en formato RGB.
  }

  const handleInputChangeFichas = (event: any) => {
    setIdFicha(event.target.value)
    setSheetId(event.target.value)
  }
  // Set a maximum height for the ListGroup container
  const maxListHeight = '300px'

  return (
    <>
      <Row>
        <Col sm>
          <Form.Group>
            <Form.Control
              type='text'
              placeholder='Buscar...'
              value={searchQuery}
              onChange={handleSearchChange}
              className='my-2'
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <span className='fw-bold'>Ficha:</span>
          <select
            className={'form-select form-select-solid}'}
            aria-label='Select example'
            value={idficha}
            name='idfichas'
            onChange={(event) => handleInputChangeFichas(event)}
          >
            {fichaData.map((x: any) => (
              <option value={x.value} key={x.value}>
                {' '}
                {String(x.label)}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex align-self-end justify-content-end mt-2' sm>
          <Form.Check
            type='checkbox'
            id={`checkbox-all`}
            label={<span style={{color: 'black'}}>Seleccionar Todo</span>}
            onChange={handleChangeAll}
            className='hide-validation'
          />
        </Col>
      </Row>
      <div style={{maxHeight: maxListHeight, overflowY: 'scroll'}}>
        {loadingFichaContent ? (
          <PdfLoadingSpinner />
        ) : (
          inputList.map((group) => {
            const filteredFields = group.fields.filter((item) =>
              item.label.toLowerCase().includes(searchQuery.toLowerCase())
            )

            if (filteredFields.length === 0) {
              return null
            }

            return (
              <>
                <h4 className='my-3 ms-2 fw-bold'>{group.label}</h4>
                <ListGroup style={{maxHeight: '200px', overflowY: 'auto'}}>
                  {filteredFields.map((item) => (
                    <div key={item.identifier}>
                      <ListGroup.Item key={item.identifier} className='d-flex'>
                        <Form.Check
                          type='checkbox'
                          id={`checkbox-dynamic-list-${item.identifier}`}
                          label={item.label}
                          checked={selectedItems.indexOf(item.identifier) !== -1}
                          onChange={(e) => handleToggle(item.identifier)}
                          className='hide-validation'
                        />
                        {item.obligatorio ? (
                          <span className='mx-3' style={{color: '#FF0000'}}>
                            *
                          </span>
                        ) : (
                          ''
                        )}
                      </ListGroup.Item>
                    </div>
                  ))}
                </ListGroup>
              </>
            )
          })
        )}
      </div>

      <Form.Group>
        <div className='d-flex justify-content-end'>
          <Button variant='primary' type='button' className='btn-sm' onClick={() => handleExport()}>
            {!loading && 'Descargar Plantilla'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Descargando...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </div>
      </Form.Group>
    </>
  )
}

export default TagsListGroup
