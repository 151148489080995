import { createContext } from "react"

interface PartnerContextProps {
    activeOnly: string,
    state: string[],
    searchTerm: string,
    entryDateFrom: any,
    entryDateTo: any,
    setActiveOnly: (value: string) => void, 
    setState: (value: string[]) => void,
    setSearchTerm: (value: string) => void,
    setEntryDateFrom: (value: any) => void,
    setEntryDateTo: (value: any) => void,
    isInfoModalOpen: boolean,
    setIsInfoModalOpen: (value: boolean) => void,
    infoData: Record<string, any>,
    setInfoData: (value: {}) => void,
    updateUser: boolean,
    setUpdateUser: (value: boolean) => void
}

const PartnerContext = createContext<PartnerContextProps>({
    activeOnly: '',
    state: [],
    searchTerm: '',
    entryDateFrom: null,
    entryDateTo: null,
    setActiveOnly: (value) => {},
    setState: (value) => {},
    setSearchTerm: (value) => {},
    setEntryDateFrom: (value: any) => {},
    setEntryDateTo: (value: any) => {},
    isInfoModalOpen: false,
    setIsInfoModalOpen: (value: boolean) => {},
    infoData:{},
    setInfoData: (value) => {},
    updateUser: false,
    setUpdateUser: (value:boolean) => {}
})

export {PartnerContext}