import {PageTitle} from '../../../../_metronic/layout/core'
import {useState} from 'react'
import {KTCard, KTIcon} from '../../../../_metronic/helpers'
import { PartnersListHeader } from './componente/PartnersListHeader'
import { PartnersTable } from './componente/PartnersTable'
import { PartnerContext } from './PartnerContexts'
import { Field, Proceso, ProcessFiles, ProcessSheet, Section, Sheet } from './documentInterface'

const fichasBreadcrumbs = [
  {
    title: 'Mis Documentacion',
    path: '/documentos/kit_documentos',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export default function FichasPage() {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchDocument, setSearchDocument] = useState('')
  const [process_id, setProcessId] = useState('')
  const [procesoData, setProcesoData] = useState<Proceso>({ _id:'', nombre: '', codigo: '', descripcion: '', estado:'' })
  const [isTagsModalOpen, setTagsModalStatus] = useState(false)
  const [isEditFileModalOpen, setIsEditFileModalOpen] = useState(false)
  const [updateFile, setUpdateFile] = useState(false)
  const [processSheetData, setProcessSheetData] = useState<ProcessSheet[]>([])
  const [sheetData, setSheetData] = useState<Sheet[]>([])
  const [sectionData, setSectionData] = useState<Section[]>([])
  const [fieldData, setFieldData] = useState<Field[]>([])
  const [fileData, setFileData] = useState<ProcessFiles>({ _id:'', idproceso: '', name: '', file: '', filename:'', keyname:'', type:'', type_document: '' })
  const [documents, setDocuments] = useState<{}>({})
  const [documentsAux, setDocumentsAux] = useState<{}>({})
  


  const openTagsModal = () => {
    setTagsModalStatus(true)
  }

  const closeTagsModal = () => {
    setTagsModalStatus(false)
  }

  const openEditFileModal = () => {
    setIsEditFileModalOpen(true)
  }

  const closeEditFileModal = () => {
    setIsEditFileModalOpen(false)
  }

  const valueContext ={
    searchTerm,
    setSearchTerm,
    searchDocument, 
    setSearchDocument,
    process_id,
    setProcessId,
    procesoData,
    setProcesoData,
    processSheetData,
    setProcessSheetData,
    sheetData,
    setSheetData,
    sectionData,
    setSectionData,
    fieldData,
    setFieldData,
    isTagsModalOpen,
    openTagsModal,
    closeTagsModal,
    fileData,
    setFileData,
    isEditFileModalOpen,
    openEditFileModal,
    closeEditFileModal,
    updateFile,
    setUpdateFile,
    documents,
    setDocuments,
    documentsAux,
    setDocumentsAux,
  }
  return (
    <>
      <PartnerContext.Provider value={valueContext}>
          <PageTitle breadcrumbs={fichasBreadcrumbs}>Kit Documentos</PageTitle>
          <KTCard>
            <PartnersListHeader />
            <PartnersTable />
          </KTCard>
      </PartnerContext.Provider>
    </>
  )
}
