import DocViewer, {DocViewerRenderers} from '@cyntler/react-doc-viewer'
import {useContext, useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {PdfLoadingSpinner} from '../../../../generales_component/PdfLoadingSpinner'
import {PartnerContext} from '../../PartnerContexts'
import { DocumentLoadDetailContext } from '../DocumentLoadDetailContext'
import { Doc } from '../../../enviodocumento/enviodocumentInterface'

const ShowFile2ModalForm = () => {
  const {viewDocumentoArray, loadingNewPdf} = useContext(DocumentLoadDetailContext)
  const [docs, setDocs] = useState<Doc[]>([])

  useEffect(() => {
    // if (viewDocumentoArray[0]?.keyName === '') {
    setDocs(viewDocumentoArray)
    console.log('viewDocumentoArray')
    console.log(viewDocumentoArray)
    // }
  }, [viewDocumentoArray])

  useEffect(() => {
    if (viewDocumentoArray[0].keyName) {
      if (viewDocumentoArray[0].keyName === '') {
      } else {
        setDocs(viewDocumentoArray)
      }
    } else {
      setDocs(viewDocumentoArray)
    }
  }, [])

  return (
    <>
      <Container className='mt-5'>
        <Row>
          <Col sm={12} style={{position: 'relative'}}>
            {loadingNewPdf ? (
              <div>
                <PdfLoadingSpinner />
              </div>
            ) : (
              <DocViewer
                documents={docs}
                pluginRenderers={DocViewerRenderers}
                style={{height: 850}}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export {ShowFile2ModalForm}
