import { Dispatch, createContext } from "react";

interface UsersReportContextProps {
    processId: string,
    setProcessId: Dispatch<string>,
    dateRange: any[],
    setDateRange: Dispatch<any[]>,
    documentNumber: string,
    setDocumentNumber: Dispatch<string>,
    userName: string,
    setUserName: Dispatch<string>,
    data: any,
    setData: Dispatch<any>,
    isLoading: boolean,
    setLoadingStatus: Dispatch<boolean>,
    currentPage: number,
    setCurrentPage: Dispatch<number>,
    totalPages: number,
    setTotalPages: Dispatch<number>,
    selectedUsers: string[],
    setSelectedUsers: Dispatch<string[]>,
    sortBy: { columnName: string, sortDirection: string }, 
    setSortBy: Dispatch<{ columnName: string, sortDirection: string }> 
}

const UsersReportContext = createContext<UsersReportContextProps>({
    processId: '',
    setProcessId: () => { },
    dateRange: [],
    setDateRange: () => { },
    documentNumber: '',
    setDocumentNumber: () => { },
    userName: '',
    setUserName: () => { },
    data: {},
    setData: () => { },
    isLoading: false,
    setLoadingStatus: () => { },
    currentPage: 1,
    setCurrentPage: () => { },
    totalPages: 0,
    setTotalPages: () => { },
    selectedUsers: [],
    setSelectedUsers: () => { },
    sortBy: { columnName: '', sortDirection: '' }, // Añadido
    setSortBy: () => { } // Añadido
})

export { UsersReportContext }
