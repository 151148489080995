import { useEffect, useState } from 'react'
import { KTCard } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Campos, DataTabla, Ficha, ProcesoFichas, Seccion, Tabla } from '../../documentacion/MiFicha/FIchaInterface'
import { PartnerContext } from './PartnerContexts'
import { DocumentsListHeader } from './components/DocumentsListHeader'
import { DocumentsTable } from './components/DocumentsTable'
import { ObservacionModal } from './components/observacion/ObservacionModal'

const documentsBreadcrumbs = [
  {
    title: 'Seguimiento',
    path: '/seguimiento/cpd-documentos',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DocumentsCPDPage = () => {
  const [activeOnly, setActiveOnly] = useState(true)
  const [state, setState] = useState<string[]>([])
  const [stateSigned, setStateSigned] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState('')
  const [idProceso, setIdProceso] = useState('')
  const [entryDateFrom, setEntryDateFrom] = useState('')
  const [entryDateTo, setEntryDateTo] = useState('')
  const [idRegistroFicha, setIdRegistroFicha] = useState('')
  const [isObservacionModalOpen, setObservacionModalStatus] = useState(false)
  const [procesoFichaData, setProcesoFichaData] = useState<ProcesoFichas[]>([])
  const [fichas, setFichas] = useState<Ficha[]>([])
  const [seccion, setSeccion] = useState<Seccion[]>([])
  const [campos, setCampos] = useState<Campos[]>([])
  const [secciones, setSecciones] = useState<Tabla[]>([])
  const [dataSecciones, setDataSecciones] = useState<DataTabla[]>([])
  const [unidad, setUnidad] = useState('')
  const [Dates, setDates] = useState<[Date | null, Date | null]>([null, null])

  const openObservacionModal = (id: string) => {
    setIdRegistroFicha(id)
    setObservacionModalStatus(true)
  }

  const handleInputObservacionChange = (id: string) => {
  }

  const closObservacionModal = () => {
    setObservacionModalStatus(false)
    //clearSection()
  }

  useEffect(()=> {
    setEntryDateFrom(Dates[0]?.toISOString().split('T')[0] ?? '')
    setEntryDateTo(Dates[1]?.toISOString().split('T')[0] ?? '')
  }, [Dates])

  return (
    <>
      <PartnerContext.Provider
        value={{
          activeOnly,
          setActiveOnly,
          state,
          setState,
          stateSigned,
          setStateSigned,
          isObservacionModalOpen,
          openObservacionModal,
          closObservacionModal,
          idRegistroFicha,
          procesoFichaData,
          setProcesoFichaData,
          fichas,
          setFichas,
          seccion,
          setSeccion,
          campos,
          setCampos,
          secciones,
          setSecciones,
          dataSecciones,
          setDataSecciones,
          handleInputObservacionChange,
          searchTerm,
          setSearchTerm,
          entryDateFrom,
          setEntryDateFrom,
          entryDateTo,
          setEntryDateTo,
          idProceso,
          setIdProceso,
          unidad,
          setUnidad,
          Dates,
          setDates
        }}
      >
        <PageTitle breadcrumbs={documentsBreadcrumbs}>Administración de Documentos CPD</PageTitle>
        <KTCard>
          <DocumentsListHeader />
          <DocumentsTable />
        </KTCard>
        <ObservacionModal />
      </PartnerContext.Provider>
    </>
  )
}

export { DocumentsCPDPage }

