import React, {useState} from 'react'
import {Accept, useDropzone} from 'react-dropzone'
import {KTSVG} from '../../../../../_metronic/helpers'
import '../../../documentacion/MiFicha/components/styles.css'

interface DropzoneExampleProps {
  onSelected: (Image: File | null) => void
  shouldClear: boolean
  onClear: () => void
  idx: string
  acceptType: string
  infoArchivo: {url: string; name: string} | null
}

const DropzoneArchivoUnico: React.FC<DropzoneExampleProps> = ({
  onSelected,
  shouldClear,
  onClear,
  idx,
  acceptType,
  infoArchivo,
}) => {
  const [fileBackUp, setFileBackUp] = useState<{url: string; name: string} | null>(infoArchivo)
  const [file, setFile] = useState<File | null>(null)
  const onDrop = async (acceptedFiles: File[]) => {
    // Update the state with the accepted file (only the first file will be considered)
    setFile(acceptedFiles[0] || null)
    // const base64 = await fileToBase64(acceptedFiles[0]);
    onSelected(acceptedFiles[0])
    removeFileBackUp()
  }

  const removeFile = () => {
    setFile(null)
    onSelected(null)
    onClear()
  }

  const removeFileBackUp = () => {
    setFileBackUp(null)
    onSelected(null)
    onClear()
  }

  React.useEffect(() => {
    if (shouldClear) {
      removeFile()
    }
  }, [shouldClear])

  const fileToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        resolve(reader.result)
      }

      reader.onerror = () => {
        reject(new Error('Error al leer el archivo.'))
      }

      reader.readAsDataURL(file)
    })
  }

  const [fileUrl, setFileUrl] = useState<string | null>(null)

  const handlePreviewFile = (file: File) => {
    const blob = new Blob([file], {type: file.type})
    const url = URL.createObjectURL(blob)
    setFileUrl(url)
  }

  function getAcceptFromExtension(extension: string): Accept {
    const accept: Accept = {}
    switch (extension) {
      case '.jpg':
      case '.jpeg':
        accept['image/jpeg'] = [extension]
        break
      case '.png':
        accept['image/png'] = [extension]
        break
      case '.pdf':
        accept['application/pdf'] = [extension]
        break
      case '.docx':
        accept['application/vnd.openxmlformats-officedocument.wordprocessingml.document'] = [
          extension,
        ]
        break
      case '.doc':
        accept['application/msword'] = [extension]
        break
      case '.xlsx':
        accept['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] = [extension]
        break
      case '.xls':
        accept['application/vnd.ms-excel'] = [extension]
        break
      // Agrega más extensiones y tipos MIME según tus necesidades
      default:
        // Manejo de casos para extensiones desconocidas o no compatibles
        break
    }

    return accept
  }

  const acceptAux = getAcceptFromExtension(acceptType)

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: acceptAux ? acceptAux : {'image/jpeg': []},
    maxFiles: 1, // Limitar a una sola imagen
  })

  return (
    <div>
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Arrastra y suelta el archivo aquí...</p>
        ) : (
          <p>Arrastra el archivo aquí o haz clic para seleccionar</p>
        )}
      </div>
      <div className='dropzone-preview-container'>
        {fileBackUp?.url ? (
          <>
            <div key={fileBackUp.name} className='dropzone-preview2'>
              <div className='file-info2'>
                <p>{fileBackUp.name}</p>
                <span
                  className='badge badge-square badge-danger remove-btn2'
                  onClick={() => removeFileBackUp()}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-1x'
                  />
                </span>
              </div>
            </div>
          </>
        ) : (
          ''
        )}

        {file ? (
          <div key={file.name} className='dropzone-preview2'>
            {file.type.startsWith('image/') ? (
              <>
                <img src={URL.createObjectURL(file)} alt={file.name} />
                <span className='badge badge-square badge-danger remove-btn' onClick={removeFile}>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-1x'
                  />
                </span>
                <button
                  className='btn btn-icon btn-light pulse pulse-info me-10 mb-10 preview-btn btn-sm'
                  onClick={() => handlePreviewFile(file)}
                  data-bs-toggle='modal'
                  data-bs-target={'#kt_modal_2' + idx}
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen004.svg'
                    className='svg-icon svg-icon-1'
                  />
                  <span className='pulse-ring'></span>
                </button>
              </>
            ) : (
              <div className='file-info2'>
                <p>{file.name} </p>
                <p>Peso: {(file.size / 1024).toFixed(2)} KB</p>
                <span
                  className='badge badge-square badge-danger remove-btn2'
                  onClick={() => removeFile()}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-1x'
                  />
                </span>
              </div>
            )}
          </div>
        ) : fileBackUp?.url === '' && file === null ? (
          <p>No se ha seleccionado ningun archivo.</p>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default DropzoneArchivoUnico
