import React from 'react'
import {ModernDefaultField} from './ModernDefaultField'
import {ModernDownloadable} from './ModernDownloadable'
import {ModernFieldTable} from './ModernFieldTable'
import {ModernOptionField} from './ModernOptionField'

export type FieldType = 'carga de documentos' | 'Imagen' | 'Tabla' | 'Lista desplegable BD' | 'otro'

interface ModernFieldProps {
  type: FieldType
  visible: boolean
  title: string
  infoValue?: {url: string; name: string; size: string}[]
  objectValue?: any
  value?: string
}

const ModernField: React.FC<ModernFieldProps> = ({
  type,
  title,
  visible,
  infoValue = [],
  objectValue,
  value = '',
}) => {
  const componentToRender = () => {
    switch (type) {
      case 'carga de documentos':
        return <ModernDownloadable title={title} infoValue={infoValue} />
      case 'Imagen':
        return <ModernDownloadable title={title} infoValue={infoValue} />
      case 'Tabla':
        return <ModernFieldTable title={title} object={objectValue} />
      case 'Lista desplegable BD':
        return <ModernOptionField title={title} label={value} />
      default:
        return <ModernDefaultField title={title} label={value} />
    }
  }
  return <>{visible && componentToRender()}</>
}

export {ModernField}
