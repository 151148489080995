class ApiClass {
  constructor() {
    
    let hostProject = window.location.host;
    let portProject = window.location.port;

    this.base_url_back = process.env.REACT_APP_API_BASE_URL;

    if (portProject === "3000" || portProject === "3011") {
      this.base_url_back = process.env.REACT_APP_LOCAL_API_BASE_URL;
    } else {
      // this.base_url_back = `https://${hostProject}/api`;
      this.base_url_back = process.env.REACT_APP_API_BASE_URL;
    }
  }
}
export default ApiClass;