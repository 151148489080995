import React from 'react';
import { useAuth2 } from '../modules/authv2/Auth2Provider';

const GetToken = () => {
  const { userData } = useAuth2();

  // Verifica si userData existe y contiene el token de acceso
  if (userData && userData.access_token) {
    return userData.access_token;
  } else {
    // En caso de que userData o el token de acceso no estén disponibles, retorna null o un valor predeterminado
    return null; // O puedes retornar un valor predeterminado como una cadena vacía ('')
  }
};

export default GetToken;
