import { useState } from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {PartnerContext} from './PartnerContexts'
import {PermisoListHeader} from './components/PermisoListHeader'
import {PermisoTable} from './components/PermisoTable'

import {Row, Form, Button, Container, Col} from 'react-bootstrap'
import { PermisoFomulario } from './components/PermisoFormulario'
import { Permiso } from './permisosInterface'


const historialDocumentsBreadcrumbs = [
  {
    title: 'Seguridad',
    path: '/seguridad/permiso',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DataPermiso = {
  _id:'', 
  nombre: '', 
  detalle: [
    {
      _id:'', 
      url: '',
      descripcion: ''
    },
    {
      _id:'', 
      url: '',
      descripcion: ''
    },
    {
      _id:'', 
      url: '',
      descripcion: ''
    },
    {
      _id:'', 
      url: '',
      descripcion: ''
    },
    {
      _id:'', 
      url: '',
      descripcion: ''
    }
  ] 
}
const PermisoPage = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [permisoData, setPermisoData] = useState<Permiso>(DataPermiso)

  return (
    <>
      <PartnerContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        permisoData, 
        setPermisoData,
      }}>
        <PageTitle breadcrumbs={historialDocumentsBreadcrumbs}>Permisos</PageTitle>
        
          <Row className='mb-3'>
            <Col xs={6} sm>              
              <KTCard>
                <PermisoFomulario />
              </KTCard>
            </Col>
            <Col xs={6}  sm>
              <KTCard>
                <PermisoListHeader />
                <PermisoTable />
              </KTCard>
            </Col>
          </Row>
      </PartnerContext.Provider>
    </>
  )
}

export {PermisoPage}
