import { KTIcon } from "../../../_metronic/helpers/components/KTIcon"

const CompanySelectorModalHeader = () => {

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>Empresa</h2>

      {/* <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div> */}

    </div>
  )
}

export {CompanySelectorModalHeader}
