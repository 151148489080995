import {useState} from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {MissingTags, PartnerContext} from './PartnerContexts'
import {DocumentUploadForm} from './component/DocumentUploadForm'
import {Doc, Personalizado} from './enviodocumentInterface'
import { GoBackButton } from '../../generales_component/GoBackButton'
import { useNavigate, useParams } from 'react-router-dom'

const fichasBreadcrumbs = [
  {
    title: 'Configuración',
    path: '/seguimiento/documentos',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export default function FichasPage() {
  const [searchTerm, setSearchTerm] = useState('')
  const [showSelectUserModal, setShowSelectUserModal] = useState(false)
  const [showSelectDocumentModal, setShowSelectDocumentModal] = useState(false)
  const [showSelectDocumentPersonalizadoModal, setShowSelectDocumentPersonalizadoModal] =
    useState(false)
  const [showFileModal, setShowFileModal] = useState(false)
  const [showCargaExcelModal, setShowCargaExcelModal] = useState(false)
  const [idProcess, setIdProcess] = useState('')
  const [listUsers, setListUsers] = useState<any[]>([])
  const [listDocuments, setListDocuments] = useState<any[]>([])
  const [listDocumentsPersonalizado, setListDocumentsPersonalizado] = useState<Personalizado>({
    _id: '',
    name: '',
    type: '',
    typeSelected: '',
    edit: false,
    children: [],
  })
  const [viewDocumento, setViewDocumento] = useState<Doc[]>([])
  const [dataDni, setDataDni] = useState<string[]>([])
  const [selectedPlantillaId, setSelectedPlantillaId] = useState('')
  const [loadingNewPdf, setLoadingNewPdf] = useState(false)
  const [unidad, setUnidad] = useState('')
  const [missingTags, setMissingTags] = useState<MissingTags[]>([])
  const navigate = useNavigate()
  const {id} = useParams()

  const goBack = () => {
    navigate('/seguimiento/documentos')
  }

  const valueContext = {
    searchTerm,
    setSearchTerm,
    showSelectUserModal,
    setShowSelectUserModal,
    showSelectDocumentModal,
    setShowSelectDocumentModal,
    showSelectDocumentPersonalizadoModal,
    setShowSelectDocumentPersonalizadoModal,
    showFileModal,
    setShowFileModal,
    showCargaExcelModal,
    setShowCargaExcelModal,
    idProcess,
    setIdProcess,
    listUsers,
    setListUsers,
    listDocuments,
    setListDocuments,
    listDocumentsPersonalizado,
    setListDocumentsPersonalizado,
    viewDocumento,
    setViewDocumento,
    dataDni,
    setDataDni,
    selectedPlantillaId,
    setSelectedPlantillaId,
    loadingNewPdf,
    setLoadingNewPdf,
    unidad,
    setUnidad,
    missingTags,
    setMissingTags,
  }
  return (
    <>
      <PartnerContext.Provider value={valueContext}>
        <PageTitle breadcrumbs={fichasBreadcrumbs}>Generar Carga de Documentos</PageTitle>
        {
          id != null
          ? <GoBackButton goBack={goBack} />
          : null
        }
        <KTCard>
          <DocumentUploadForm />
        </KTCard>
      </PartnerContext.Provider>
    </>
  )
}
