import { FormEvent, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { createAction, createHuerfano, createOrUpdateMenu, deleteMenu, fetchMenuData, updateMenu } from '../../../../services/menu';
import { showMessage } from '../../../../shared/Alerts';
import { PartnerContext } from '../PartnerContexts';
import { Menu } from './MenuInterface';

const DataMenu: Menu = {
  _id: '',
  titulo: '',
  url: '',
  icono: '',
  tipo: 'submenu',
  order: 0,
  id_padre: '',
  id_hijo: '',
  hijos: []
};

const MenuForm = () => {
  const { menuData, setMenuData, setReloadTable, edit, setEdit } = useContext(PartnerContext);
  const [menu, setMenu] = useState<Menu>(DataMenu);
  const [isOrphan, setIsOrphan] = useState<boolean>(false);
  const [nameAccion, setNameAccion] = useState<string>('');
  const [isAccion, setIsAccion] = useState<boolean>(false);

  useEffect(() => {
    if (menuData) {
      setMenu(menuData);
      setIsOrphan(menuData.id_padre === '');
      setIsAccion(menuData.tipo === 'accion');
    } else {
      setMenu(DataMenu);
      setIsAccion(false);
    }
  }, [menuData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setMenu({ ...menu, [name]: value });

    if (name === 'tipo') {
      setIsAccion(value === 'accion');
    }
  };
  
  const handleChangeNameAccion = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNameAccion(value);
  };

  const clearForm = () => {
    setEdit(false);
    setMenuData(DataMenu);
    setIsOrphan(false);
    setIsAccion(false);
  };

  const handleOrphanClick = () => {
    setIsOrphan(true);
    setIsAccion(false);
    setMenu({
      ...DataMenu,
      tipo: '',
      titulo: '',
      icono: '',
      order: 0,
    });
  };

  const eliminarMenu = async () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Deseas eliminar este menú/submenú?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Eliminando...',
          text: 'Por favor espere',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        try {
          const response = await deleteMenu(menu);

          if (response.success) {
            showMessage('success', '¡Buen trabajo!', 'Menú eliminado correctamente');
            reloadMenuData(); // Recargar datos del menú
            clearForm();
          } else {
            showMessage('error', 'Hubo un error', response.message);
          }
        } catch (error) {
          console.error('Error deleting menu', error);
          showMessage('error', 'Hubo un error', 'Hubo un error al eliminar el menú');
        }
      }
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isOrphan) {
      saveOrphanMenu();
    } else if (isAccion) {
      saveAction();
    } else {
      saveMenu();
    }
  };

  const saveMenu = async () => {
    Swal.fire({
      title: 'Guardando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const response = await createOrUpdateMenu(menu);

      if (response.success) {
        showMessage('success', '¡Buen trabajo!', 'Menú guardado correctamente');
        reloadMenuData(); // Recargar datos del menú
        clearForm();
      } else {
        showMessage('error', 'Hubo un error', response.message);
      }
    } catch (error) {
      console.error('Error saving menu', error);
      showMessage('error', 'Hubo un error', 'Hubo un error al guardar el menú');
    }
  };

  const saveAction = async () => {
    Swal.fire({
      title: 'Guardando Acción...',
      text: 'Por favor espere',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const response = await createAction(menu);

      if (response.success) {
        showMessage('success', '¡Buen trabajo!', 'Acción guardada correctamente');
        reloadMenuData(); // Recargar datos del menú
        clearForm();
      } else {
        showMessage('error', 'Hubo un error', response.message);
      }
    } catch (error) {
      console.error('Error saving action', error);
      showMessage('error', 'Hubo un error', 'Hubo un error al guardar la acción');
    }
  };

  const saveOrphanMenu = async () => {
    Swal.fire({
      title: 'Guardando Huérfano...',
      text: 'Por favor espere',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const response = await createHuerfano({ ...menu, id_padre: '' });

      if (response.success) {
        showMessage('success', '¡Buen trabajo!', 'Menú huérfano guardado correctamente');
        reloadMenuData(); 
        clearForm();
      } else {
        showMessage('error', 'Hubo un error', response.message);
      }
    } catch (error) {
      console.error('Error saving orphan menu', error);
      showMessage('error', 'Hubo un error', 'Hubo un error al guardar el menú huérfano');
    }
  };

  const actualizarMenu = async () => {
    Swal.fire({
      title: 'Actualizando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      if (isAccion) {
        const response = await createAction({...menu,name_accion:nameAccion});

        if (response.success) {
          showMessage('success', '¡Buen trabajo!', 'Acción actualizada correctamente');
          reloadMenuData(); // Recargar datos del menú
          clearForm();
        } else {
          showMessage('error', 'Hubo un error', response.message);
        }
      } else {
        const response = await updateMenu(menu);

        if (response.success) {
          showMessage('success', '¡Buen trabajo!', 'Menú actualizado correctamente');
          reloadMenuData(); // Recargar datos del menú
          clearForm();
        } else {
          showMessage('error', 'Hubo un error', response.message);
        }
      }
    } catch (error) {
      console.error('Error updating menu', error);
      showMessage('error', 'Hubo un error', 'Hubo un error al actualizar el menú');
    }
  };

  const reloadMenuData = async () => {
    try {
      const response = await fetchMenuData();
      if (response.success) {
        setMenuData(response.data);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error('Error fetching menu data:', error);
    }
  };

  return (
    <Container>
      <div className='card'>
        <div className="card-header">
          <h3 className="card-title">Datos</h3>
        </div>
        <Container>
          <Form onSubmit={handleSubmit}>
            {!isOrphan && menu.id_padre && (
              <Row className='mb-3 pt-3 px-3'>
                <Col sm className='mb-2'>
                  <Form.Group controlId='formParent'>
                    <Form.Label>Padre</Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-sm'
                      value={menu.id_padre || ''}
                      readOnly
                      style={{ backgroundColor: '#f0f0f0' }}
                      name='id_padre'
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            {!isOrphan && (
              <Row className='mb-3 px-3'>
                <Col sm className='mb-2'>
                  <Form.Group controlId='formId'>
                    <Form.Label>ID</Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-sm'
                      value={menu.id_hijo || menu._id || ''}
                      readOnly
                      style={{ backgroundColor: '#f0f0f0' }}
                      name='_id'
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row className='mb-3 px-3'>
              <Col sm className='mb-2'>
                <Form.Group controlId='formTitle'>
                  <Form.Label>{isAccion ? 'Nombre Acción' : 'Título'}</Form.Label>
                  <Form.Control
                    type='text'
                    className='form-control-sm'
                    required
                    value={menu.titulo || ''}
                    placeholder={isAccion ? 'Ingrese el nombre de la acción' : 'Ingrese el título del menú'}
                    onChange={handleChange}
                    name='titulo'
                  />
                </Form.Group>
              </Col>
            </Row>
            {!isOrphan && (
              <Row className='mb-3 px-3'>
                <Col sm className='mb-2'>
                  <Form.Group controlId='formType'>
                    <Form.Label>Tipo</Form.Label>
                    <Form.Control
                      as='select'
                      className='form-control-sm custom-select-arrow'
                      required
                      value={menu.tipo}
                      onChange={handleChange}
                      name='tipo'
                    >
                      <option value='submenu'>Submenú</option>
                      <option value='accion'>Acción</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                {!isAccion ? (
                  <>
                    <Col sm className='mb-2'>
                      <Form.Group controlId='formUrl'>
                        <Form.Label>Url</Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control-sm'
                          required
                          value={menu.url || ''}
                          placeholder='Ingrese la URL del menú'
                          onChange={handleChange}
                          name='url'
                        />
                      </Form.Group>
                    </Col>
                    <Col sm className='mb-2'>
                      <Form.Group controlId='formIcon'>
                        <Form.Label>Icono</Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control-sm'
                          value={menu.icono || ''}
                          placeholder='Ingrese el icono del menú'
                          onChange={handleChange}
                          name='icono'
                        />
                      </Form.Group>
                    </Col>
                    <Col sm className='mb-2'>
                      <Form.Group controlId='formOrder'>
                        <Form.Label>Orden</Form.Label>
                        <Form.Control
                          type='number'
                          className='form-control-sm'
                          required
                          value={menu.order || 0}
                          placeholder='Ingrese el orden del menú'
                          onChange={handleChange}
                          name='order'
                        />
                      </Form.Group>
                    </Col>
                  </>
                )
                :
                (
                  <Col sm className='mb-2'>
                    <Form.Group controlId='formUrl'>
                      <Form.Label>Nombre Acción</Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-sm'
                        required
                        value={nameAccion}
                        placeholder='Ingrese Nombre de la acción'
                        onChange={handleChangeNameAccion}
                        name='nameAccion'
                      />
                    </Form.Group>
                  </Col>
                  
                )
              }
              </Row>
            )}
            {isOrphan && (
              <Row className='mb-3 px-3'>
                <Col sm className='mb-2'>
                  <Form.Group controlId='formIcon'>
                    <Form.Label>Icono</Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-sm'
                      required
                      value={menu.icono || ''}
                      placeholder='Ingrese el icono del menú'
                      onChange={handleChange}
                      name='icono'
                    />
                  </Form.Group>
                </Col>
                <Col sm className='mb-2'>
                  <Form.Group controlId='formOrder'>
                    <Form.Label>Orden</Form.Label>
                    <Form.Control
                      type='number'
                      className='form-control-sm'
                      required
                      value={menu.order || 0}
                      placeholder='Ingrese el orden del menú'
                      onChange={handleChange}
                      name='order'
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <div className='d-flex justify-content-end'>
                  <Button
                    className='mx-2'
                    variant='success'
                    type='button'
                    onClick={handleOrphanClick}
                  >
                    Huérfano
                  </Button>
                  {!menu.id_padre && (
                    <Button variant='success' type='submit'>
                      {isOrphan ? 'Guardar Huérfano' : 'Guardar'}
                    </Button>
                  )}
                  <Button
                    className='mx-2'
                    variant='danger'
                    type='button'
                    onClick={eliminarMenu}
                  >
                    Eliminar
                  </Button>
                  <Button
                    className='mx-2'
                    variant='warning'
                    type='button'
                    onClick={actualizarMenu}
                  >
                    Actualizar
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </Container>
  );
};

export { MenuForm };

