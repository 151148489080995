import React, {createContext} from 'react'
import {Doc, Personalizado} from './enviodocumentInterface'

export interface MissingTags {
  idFile: string
  missingTags: string[]
  keyname: string
  filename: string
}

interface PartnerContextProps {
  searchTerm: string
  setSearchTerm: (value: string) => void
  showSelectUserModal: boolean
  setShowSelectUserModal: (value: boolean) => void
  showSelectDocumentModal: boolean
  setShowSelectDocumentModal: (value: boolean) => void
  showSelectDocumentPersonalizadoModal: boolean
  setShowSelectDocumentPersonalizadoModal: (value: boolean) => void
  showFileModal: boolean
  setShowFileModal: (value: boolean) => void
  showCargaExcelModal: boolean
  setShowCargaExcelModal: (value: boolean) => void
  idProcess: string
  setIdProcess: (value: string) => void
  unidad: string
  setUnidad: (value: string) => void
  listUsers: any[]
  setListUsers: (value: any[]) => void
  listDocuments: any[]
  setListDocuments: (value: any[]) => void
  listDocumentsPersonalizado: Personalizado
  setListDocumentsPersonalizado: (value: Personalizado) => void
  viewDocumento: Doc[]
  setViewDocumento: React.Dispatch<React.SetStateAction<Doc[]>>
  dataDni: string[]
  setDataDni: (value: string[]) => void
  selectedPlantillaId: string
  setSelectedPlantillaId: (value: string) => void
  loadingNewPdf: boolean
  setLoadingNewPdf: (value: boolean) => void
  missingTags: MissingTags[]
  setMissingTags: (value: MissingTags[]) => void
}

const PartnerContext = createContext<PartnerContextProps>({
  searchTerm: '',
  setSearchTerm: (value) => {},
  showSelectUserModal: false,
  setShowSelectUserModal: (value) => {},
  showSelectDocumentModal: false,
  setShowSelectDocumentModal: (value) => {},
  showSelectDocumentPersonalizadoModal: false,
  setShowSelectDocumentPersonalizadoModal: (value) => {},
  showFileModal: false,
  setShowFileModal: (value) => {},
  showCargaExcelModal: false,
  setShowCargaExcelModal: (value) => {},
  idProcess: '',
  setIdProcess: (value) => {},
  unidad: '',
  setUnidad: (value) => {},
  listUsers: [],
  setListUsers: (value) => {},
  listDocuments: [],
  setListDocuments: (value) => {},
  listDocumentsPersonalizado: {
    _id: '',
    name: '',
    type: '',
    typeSelected: '',
    edit: false,
    children: [],
  },
  setListDocumentsPersonalizado: (value) => {},
  viewDocumento: [],
  setViewDocumento: (value) => {},
  dataDni: [],
  setDataDni: (value) => {},
  selectedPlantillaId: '',
  setSelectedPlantillaId: (value) => {},
  loadingNewPdf: false,
  setLoadingNewPdf: (value) => {},
  missingTags: [],
  setMissingTags: (value) => {},
})

export {PartnerContext}
