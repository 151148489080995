import { useEffect, useState } from "react"
import { toAbsoluteUrl } from "../../../_metronic/helpers"
import { useThemeMode } from "../../../_metronic/partials"
import { Link, useParams } from "react-router-dom"
import { getDocumentSentById } from "../../services/documentSent"
import { formatISODateToDMYHM } from "../../helpers/dateFunctions"
import { Badge } from "react-bootstrap"
import { getFileInPDF } from "../../services/files"
import { showMessage } from "../../shared/Alerts"
import { useAuth2 } from "../authv2/Auth2Provider"

const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat']

const DocumentStatusPage = () => {

    const { id } = useParams();
    const [data, setData] = useState<any>({});
    
    const { userData } = useAuth2()

    const { mode } = useThemeMode()
    useEffect(() => {
        BODY_CLASSES.forEach((c) => document.body.classList.add(c))
        document.body.style.backgroundImage =
            mode === 'dark'
                ? `url(${toAbsoluteUrl('/media/auth/bg7-dark.jpg')})`
                : `url(${toAbsoluteUrl('/media/auth/bg7.jpg')})`

        return () => {
            BODY_CLASSES.forEach((c) => document.body.classList.remove(c))
            document.body.style.backgroundImage = 'none'
        }
    }, [mode])

    useEffect(() => {
        getDocumentData()
    }, [])

    const getDocumentData = () => {

        if (id) {
            getDocumentSentById(id)
                .then((response) => {
                    if (response.success) {
                        setData(response.data)
                    }
                    else {
                        console.log(response)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }

    const getFriendlyState = (value: string): string => {
        switch (value) {
            case 'pending':
                return 'Pendiente'
            case 'signed':
                return 'Firmado'
            case 'rejected':
                return 'Rechazado'
            default:
                return value
        }

    }

    const handleDownload = (keyname: string) => {
        getFileInPDF(keyname)
            .then((response) => {
                if (response.success) {
                    const link = document.createElement('a');
                    link.href = response.data;
                    link.download = 'download';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    showMessage('success', '¡Buen trabajo!', response.message)
                } else {
                    showMessage('error', 'Hubo un error', response.error)
                }
            })
            .catch((error) => {
                showMessage('error', 'Hubo un error', error.message)
            })
    }

    return (<div className='d-flex flex-column flex-root'>
        <div className='d-flex flex-column flex-center flex-column-fluid'>
            <div className='d-flex flex-column flex-center text-center p-10'>
                <div className='card card-flush  w-lg-650px py-5'>
                    <div className='card-body py-15 py-lg-20'>
                        <div>
                            <h1>Validación de Documentos</h1>
                            <div className="d-flex justify-content-between">
                                <p className="fw-bolder">Documento:</p>
                                <p className="flex-grow-1">{data.name}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="fw-bolder">Creación:</p>
                                <p className="flex-grow-1">{formatISODateToDMYHM(data.createdAt)}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-bolder">Estado Documento</p>
                                    <p className="flex-grow-1"><Badge bg="success">{getFriendlyState(data.state)}</Badge></p>
                                </div>
                                <div>
                                    <p>Descarga tu documento digital aquí</p>
                                    <Link to='#' className='btn btn-sm btn-success' onClick={() => handleDownload(data.keyname)}>
                                        Descargar
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <p className="d-flex justify-content-start fw-bolder">Usuario</p>
                                <div className="d-flex justify-content-between bg-light p-5 rounded-3">
                                    <div>
                                        <p className="fw-bold">{data?.userName}</p>
                                        <p>{data?.userDni}</p>
                                    </div>
                                    <div>
                                        <p className="fw-bold">{formatISODateToDMYHM(data.updatedAt)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export { DocumentStatusPage }