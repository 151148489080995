import { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { getAllFilesCPD, getSendDocumentFilexIdproceso } from '../../../../services/files'
import { PartnerContext } from '../PartnerContexts'
import { PartnersTableGeneral } from './../../../generales_component/PartnersTableGeneral'
import ExcelJS from 'exceljs'
import { useGlobalOverlayLoading } from '../../../../shared/hooks/useGlobalOverlayLoading'
import Select from 'react-select';


const ShowDocumentModalForm = () => {
  const {
    idProcess,
    setListDocuments,
    listDocuments,
    listUsers,
    setShowSelectDocumentModal,
    unidad,
    setMissingTags,
    missingTags,
  } = useContext(PartnerContext)
  const [usersData, setUsersData] = useState([])
  const [bodyDocument, setBodyDocument] = useState<any[]>([])
  const [bodyDocumentSelect, setBodyDocumentSelect] = useState<any[]>([])
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()
  const [loadingTags, setLoadingTags] = useState(false)
  const [successTags, setSuccessTags] = useState(false)

  const [checkCPD, setCheckCPD] = useState(false)
  const [dataDocumentCPD, setDataDocumentCPD] = useState<any[]>([])
  const [selectedCPD, setSelectedCPD] = useState<any>(null)
  const [optionDocumentCPD, setOptionDocumentCPD] = useState<{label: string; value: string}[]>([])

  useEffect(()=>{
    getDocumentProcess()
  },[])

  const getDocumentProcess = () => {
    getSendDocumentFilexIdproceso(idProcess, true, unidad)
      .then((response) => {
        if (response.success) {
          const allDocuments = Object.values(response.data).flat()

          const filteredArray = allDocuments.filter((item1:any) => 
            !listDocuments.some((item2:any) => item1._id === item2._id)
          )
          const filteredSelectedArrayConfig = listDocuments.filter((item:any) => 
            ['Configurado', 'CPD'].includes(item.typeSelected)
          )

          const filterCPD = listDocuments.find((item:any) => item.typeSelected === 'CPD')
          
          if (filterCPD) {
            setCheckCPD(true)
            setSelectedCPD({
              label: filterCPD.name,
              value: filterCPD._id
            })
          }
  
          setBodyDocument(filteredArray)
          setBodyDocumentSelect(filteredSelectedArrayConfig)
        } else {
          console.error('Error fetching documents:', response.message)
        }
      })
      .catch((error) => {
        console.error('Error fetching documents:', error)
      })
  }
  
  const headerUser = [
    {
      type: 'string',
      field: 'name',
      label: 'Nombre',
    },
    {
      type: 'string',
      field: 'type',
      label: 'Tipo',
    },
    {
      type: 'check',
      field: 'selected',
      label: "Seleccionar",
    }
  ]

  const handleCheckSelected =  (_ids: string[], array1:any[], array2:any[], NormalFlow:boolean ) => {
    const arrayNew1 : any[] = []
    const arrayNew2 : any[] = []
    
    if(NormalFlow){
      array1.map(user => {
        let entro = false
        _ids.map(id => {
          if(user['_id'] === id){
            entro = true
          }
        })
        if(entro){
          arrayNew2.push({...user,typeSelected:"Configurado",edit:false})          
        }else{
          arrayNew1.push(user)
        }
      })
      setBodyDocument(arrayNew1)      
      setBodyDocumentSelect([ ...arrayNew2, ...array2 ])
    }else{
      array2.map((user:any) => {
        let entro = false
        _ids.map(id => {
          if(user['_id'] === id){
            entro = true
          }
        })
        if(entro){
          arrayNew1.push(user)
        }else{
          arrayNew2.push(user)
        }
      })
      setBodyDocumentSelect(arrayNew2)
      setBodyDocument([...arrayNew1, ...array1 ])
    }
  }

  const onAddUsers = () => {
    const arrayPersonalizado = listDocuments.filter((item1:any) => item1.typeSelected === "Personalizado");
    if(arrayPersonalizado.length > 0){
      setListDocuments([...bodyDocumentSelect,...arrayPersonalizado])
    }else{
      setListDocuments(bodyDocumentSelect)
    }
    setShowSelectDocumentModal(false)
    // setMissingTags([])
  }

  const handleDownloadTags = async () => {
    const allTags = new Set(missingTags.flatMap((file) => file.missingTags))
    const allTagsArray = Array.from(allTags)

    try {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet()
      worksheet.columns = [
        {
          header: 'NRO DOCUMENTO',
          key: 'nroDocumento',
          width: 20,
          style: {
            font: {
              bold: true,
              color: {argb: '45494E'},
              size: 12,
            },
          },
        },
        ...allTagsArray.map((tag) => ({
          header: `{${tag}}`,
          key: 'etiqueta-' + tag,
          width: 20,
          style: {
            font: {
              bold: true,
              color: {argb: '45494E'},
              size: 12,
            },
          },
        })),
      ]

      listUsers.forEach((user) => {
        worksheet.addRow([user.dni])
      })

      worksheet.getRow(1).eachCell((cell, colNumber) => {
        cell.font = {
          bold: true,
          color: {argb: 'FFFFFF'},
          size: 12,
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: '0000FF'},
        }
      })

      setGlobalOverlayLoading(true)
      const buffer = await workbook.xlsx.writeBuffer()

      const url = window.URL.createObjectURL(
        new Blob([buffer],
        {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
      ))

      const a = document.createElement('a')
      a.href = url
      a.download = 'etiquetas.xlsx'
      a.click()
    } catch (error) {
      console.log(error)
    } finally {
      setGlobalOverlayLoading(false)
    }
  }

  const disabledByTags = (missingTags.length > 0 && missingTags.some(tag => tag.missingTags.length > 0)) || loadingTags
  const isSubsaned = missingTags.every((file) =>
    file.missingTags.every((tagR) =>
      listDocuments.some((doc) =>
        doc.tags?.some((tag: any) => {
          const tagKeys = Object.keys(tag.tags)
          return tagKeys.includes(`{${tagR}}`)
        })
      )
    )
  )

  const getDocumentCPD = () => {
      getAllFilesCPD(idProcess, unidad)
        .then((response) => {
          console.log(response)
          setDataDocumentCPD(response.data)
          const grupoCPD = response.data.map((x: any) => ({label: `${x.name}`, value: x._id}))
          setOptionDocumentCPD(grupoCPD)
        })
        .catch((error) => console.log(error))
  }

  const handleSelectChange = (e: any) => {
    const datValue = dataDocumentCPD.filter((doc) => doc._id === e.value)[0]
    const noCPDs = bodyDocumentSelect.filter((doc) => doc.typeSelected !== 'CPD')
    const CPD = {...datValue, typeSelected: 'CPD', edit: false}
    setBodyDocumentSelect([...noCPDs, CPD])
  }

  const onChangeCheckCPD = (e: any) => {
    if (!e.target.checked) {
      const noCPDs = bodyDocumentSelect.filter((doc) => doc.typeSelected !== 'CPD')
      setSelectedCPD(null)
      setBodyDocumentSelect([...noCPDs])
    }

    setCheckCPD(e.target.checked)
  }

  useEffect(() => {
    setSuccessTags(isSubsaned)
  }, [isSubsaned])

  useEffect(() => {
    getDocumentCPD()
  }, [idProcess, unidad])

  return (
    <>
      <Container>
        <Row className='mb-3 pt-3 px-3'>
          <Row>
            <Col sm>
              <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-start'>
                  <Form.Label>
                    Selecciona los documento que requieres enviarle a los usuarios
                  </Form.Label>
                </div>
              </div>
            </Col>
            <Col sm>
              <Row className='mt-4'>
                <Col sm={4}>
                  <Form.Group controlId='formCheckInclude'>
                    <div className='form-check form-switch form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        disabled={listUsers.length > 0 ? false : true}
                        onChange={(e: any) => onChangeCheckCPD(e)}
                        checked={checkCPD}
                        id='flexSwitchDefault'
                      />
                      <label className='form-check-label'>
                        <b>¿INCLUYE CPD?</b>
                      </label>
                    </div>
                  </Form.Group>
                </Col>
                <Col sm={8}>
                  {checkCPD && (
                    <Select
                      name='documentCPD'
                      options={optionDocumentCPD}
                      styles={{
                        control: (provided: any) => ({
                          ...provided,
                          fontSize: '12px',
                          minHeight: '34px', // Reducción del alto
                          height: '34px',
                        }),
                      }}
                      onChange={handleSelectChange}
                      placeholder='Seleccione CPD'
                      value={selectedCPD}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <PartnersTableGeneral
                title={'Seleccionar Documentos'}
                theader={headerUser}
                tbody={bodyDocument}
                pagination={false}
                searchVisible={true}
                searchHint={'Buscar Documentos ...'}
                checksAll={true}
                onCheckSelected={(_id: string[]) =>
                  handleCheckSelected(_id, bodyDocument, bodyDocumentSelect, true)
                }
              />
            </Col>
            <Col>
              <PartnersTableGeneral
                title={'Documentos Seleccionados'}
                theader={headerUser}
                tbody={bodyDocumentSelect}
                pagination={false}
                searchVisible={true}
                searchHint={'Buscar Documentos ...'}
                checksAll={true}
                onCheckSelected={(_id: string[]) =>
                  handleCheckSelected(_id, bodyDocument, bodyDocumentSelect, false)
                }
                fetchTags={true}
                processId={idProcess}
                usersSelected={listUsers}
                setGlobalMisingTags={setMissingTags}
                downloadTags={handleDownloadTags}
                setDocs={(docs) => {
                  const copyBodyDoc = bodyDocumentSelect.map((doc) => {
                    const tags = docs.get(doc._id) ?? null

                    if (tags) {
                      const newDoc = {
                        ...doc,
                        tags: doc.tags
                          ? [
                              ...doc.tags.map((file: any) => {
                                const newTags = tags.find((tag2) => tag2.user === file.user) ?? {}
                                return {
                                  ...file,
                                  tags: {...file.tags, ...newTags.tags},
                                }
                              }),
                            ]
                          : tags,
                      }

                      return newDoc
                    }

                    return {
                      ...doc,
                      tags: null,
                    }
                  })
                  setBodyDocumentSelect(copyBodyDoc)
                  setSuccessTags(true)
                }}
                setLoadingTags={setLoadingTags}
              />
            </Col>
          </Row>
          <Row>
            <div className='d-flex justify-content-end'>
              <Button
                variant='light'
                className='mx-3'
                onClick={() => {
                  setShowSelectDocumentModal(false)
                }}
                type='reset'
              >
                Cerrar
              </Button>
              <Button
                variant='primary'
                onClick={onAddUsers}
                disabled={(!successTags && disabledByTags) || (!successTags && !isSubsaned)}
                type='button'
              >
                Aceptar
              </Button>
            </div>
          </Row>
        </Row>
      </Container>
    </>
  )
}

export { ShowDocumentModalForm }

