import {useContext, useEffect} from 'react'

import { PartnerContext} from '../../PartnerContexts'
import { ObservacionModalHeader } from './ObservacionModalHeader'
import { ObservacionModalFormWrapper } from './ObservacionModalFormWrapper'

const ObservacionModal = () => {

  const {isObservacionModalOpen} = useContext(PartnerContext)

  return (
    <>
      {isObservacionModalOpen && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              <div className='modal-dialog modal-xl modal-dialog-centered'>
                <div className='modal-content'>
                  <ObservacionModalHeader />
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <ObservacionModalFormWrapper />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  )
}

export {ObservacionModal}
