import {useContext} from 'react'
import {GlobalOverlayContext} from '../../modules/generales_component/GlobalOverlayProvider'

export const useGlobalOverlayLoading = () => {
  const {globalOverlayLoading, setGlobalOverlayLoading} = useContext(GlobalOverlayContext)

  return {
    globalOverlayLoading,
    setGlobalOverlayLoading,
  }
}
