import { useMemo, useState, useContext, useEffect, Fragment } from 'react'
import { Badge, Button, Col, Container, Dropdown, Form, Row, Stack } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import { KTCardBody, KTIcon } from '../../../../../_metronic/helpers'
import { useQueryResponseData } from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import { usersColumns } from '../../../apps/user-management/users-list/table/columns/_columns'
import { showMessage } from '../../../../shared/Alerts'
import { PartnerContext } from '../PartnerContexts'
import { Permiso, Detalle } from '../permisosInterface'

const PermisoFomulario = () => {
  const {permisoData} = useContext(PartnerContext)
  const [loading, setLoading] = useState(false)
  const [editButton, setEditButton] = useState(false); 
  const [permiso, setPermiso] = useState<Permiso>(permisoData)

  const handleChange = (e: any) => {
    setPermiso({...permiso, [e.target.name]: e.target.value})
  }

  const handleChangeDetalle = (e: any) => {
    let detalle:any[] = permiso.detalle
    detalle[Number(e.target.id)][e.target.name] = e.target.value
    setPermiso({...permiso, detalle: detalle})
  }


  const handleSubmit = (e: any) => {
    e.preventDefault()
    /* if (id) {
      if(processFile.file !== ''){
        // handleSaveProcessFile(processFile)
      }else{
        showMessage('error', 'Hubo un error', "Debe Seleccionar un Archivo")
      }
    } */
  }

  return (
    <>
      <KTCardBody className='py-4'>
        <div className="card-header">
            <h3 className="card-title">Permisos</h3>
        </div>
        <Container>          
          <Row className='row mb-3 pt-3 px-3' >
            <Form onSubmit={handleSubmit}>                
                <Col sm className='mb-2'>
                  <Form.Group controlId='formNombre'>
                    <Form.Label>Nombre Modulo</Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-sm'                      
                      required
                      value={permiso.nombre}
                      placeholder='Ingrese un nombre'
                      onChange={handleChange}  
                      name='nombre'
                    />
                  </Form.Group>
                </Col>
                
                <Row className='mb-2'>                  
                  <div className='d-flex align-items-end'>
                    <Form.Label style={{ fontSize: '14px', fontWeight:'bold' }} >Vista Principal</Form.Label>
                  </div>
                  <Col sm>
                    <Form.Group controlId='formNombre'>
                      <Form.Label>Url</Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-sm'
                        value={permiso.detalle[0].url}
                        onChange={handleChangeDetalle}                      
                        required
                        placeholder='Ingrese Url'
                        id="0"
                        name='url'
                      />
                    </Form.Group>
                  </Col>
                  <Col sm>
                    <Form.Group controlId='formNombre'>
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-sm'  
                        value={permiso.detalle[0].descripcion}
                        onChange={handleChangeDetalle}                     
                        required
                        placeholder='Ingrese descripción'
                        id="0"
                        name='descripcion'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-2'>                  
                  <div className='d-flex align-items-end'>
                    <Form.Label style={{ fontSize: '14px', fontWeight:'bold' }} >Vista Detalle</Form.Label>
                  </div>
                  <Col sm>
                    <Form.Group controlId='formNombre'>
                      <Form.Label>Url</Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-sm' 
                        value={permiso.detalle[1].url}
                        onChange={handleChangeDetalle}                       
                        required
                        placeholder='Ingrese Url'
                        id="1"
                        name='url'
                      />
                    </Form.Group>
                  </Col>
                  <Col sm>
                    <Form.Group controlId='formNombre'>
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-sm'   
                        value={permiso.detalle[1].descripcion}
                        onChange={handleChangeDetalle}                    
                        required
                        placeholder='Ingrese descripción'
                        id="1"
                        name='descripcion'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-2'>                  
                  <div className='d-flex align-items-end'>
                    <Form.Label style={{ fontSize: '14px', fontWeight:'bold' }} >Vista Crear</Form.Label>
                  </div>
                  <Col sm>
                    <Form.Group controlId='formNombre'>
                      <Form.Label>Url</Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-sm'
                        value={permiso.detalle[2].url}
                        onChange={handleChangeDetalle}                      
                        required
                        placeholder='Ingrese Url'
                        id="2"
                        name='url'
                      />
                    </Form.Group>
                  </Col>
                  <Col sm>
                    <Form.Group controlId='formNombre'>
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-sm'                      
                        required
                        placeholder='Ingrese descripción'
                        value={permiso.detalle[2].descripcion}
                        onChange={handleChangeDetalle}  
                        id="2"
                        name='descripcion'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-2'>                  
                  <div className='d-flex align-items-end'>
                    <Form.Label style={{ fontSize: '14px', fontWeight:'bold' }} >Vista Editar</Form.Label>
                  </div>
                  <Col sm>
                    <Form.Group controlId='formNombre'>
                      <Form.Label>Url</Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-sm'  
                        value={permiso.detalle[3].url}
                        onChange={handleChangeDetalle}                     
                        required
                        placeholder='Ingrese Url'
                        id="3"
                        name='url'
                      />
                    </Form.Group>
                  </Col>
                  <Col sm>
                    <Form.Group controlId='formNombre'>
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-sm'     
                        value={permiso.detalle[3].descripcion}
                        onChange={handleChangeDetalle}                   
                        required
                        placeholder='Ingrese descripción'
                        id="3"
                        name='descripcion'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-2'>                  
                  <div className='d-flex align-items-end'>
                    <Form.Label style={{ fontSize: '14px', fontWeight:'bold' }} >Vista Eliminar</Form.Label>
                  </div>
                  <Col sm>
                    <Form.Group controlId='formNombre'>
                      <Form.Label>Url</Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-sm'     
                        value={permiso.detalle[4].url}
                        onChange={handleChangeDetalle}
                        required
                        placeholder='Ingrese Url'
                        id="4"
                        name='url'
                      />
                    </Form.Group>
                  </Col>
                  <Col sm>
                    <Form.Group controlId='formNombre'>
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-sm'     
                        value={permiso.detalle[4].descripcion}
                        onChange={handleChangeDetalle}                  
                        required
                        placeholder='Ingrese descripción'
                        id="4"
                        name='descripcion'
                      />
                    </Form.Group>
                  </Col>
                </Row>
              <Row>                
                <Col>
                  <div className='d-flex justify-content-end'>
                    <Button variant='primary' type='submit'>
                      {!loading && !editButton && 'Subir Archivo'}
                      {!loading && editButton && 'Actualizar Archivo'}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Subiendo...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>


            </Form>
          </Row>

        </Container>
      </KTCardBody>
    </>
  )
}

export { PermisoFomulario }
