import { Col, Row } from "react-bootstrap"
import { TableSearch } from "../../../../shared/TableSearch"
import { KTIcon } from '../../../../../_metronic/helpers'
import { useContext } from "react"
import { DocumentTrayContext } from "../DocumentTrayContexts"
import { generateDocumentsExcel } from "../../../../services/documentSent"

const DocumentTrayTableHeader = () => {

    const { setSearchTerm } = useContext(DocumentTrayContext)

    const handleSearch = (e: string) => {
        setSearchTerm(e)
    }

    const handleExport = () => {
        generateDocumentsExcel()
            .then((response) => console.log(response))
            .catch((error) => console.log(error))
    }

    return (
        <>
            <div className='mx-8 mt-8 mb-6'>
                <Row>
                    <Col>
                        <TableSearch onSearch={handleSearch} design={true} placeholderSearch={'Buscar por nombre documento'} />
                    </Col>
                    <Col></Col>
                    <Col className='d-flex align-self-end justify-content-end'>
                        <button type='button' className='btn btn-primary' onClick={() => handleExport()}>
                            <KTIcon iconName='file-down' className='fs-2' />
                            Exportar
                        </button>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export { DocumentTrayTableHeader }