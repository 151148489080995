import { useMemo, useState, useContext, useEffect, Fragment } from 'react'
import { Badge, Dropdown, Form, Stack } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import { KTCardBody, KTIcon } from '../../../../../_metronic/helpers'
import {
  getSheets,
  deleteCompleteSheet,
  cloneCompleteSheet,
  ficha,
  busquedaficha,
  generatePDF,
} from '../../../../services/fichas'
import {
  showDeleteMessage,
  showMessage,
  showCloneMessage,
  showCancellationMessage,
  showReactivationMessage,
} from '../../../../shared/Alerts'
import { UsersListLoading } from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import { useQueryResponseData } from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import { usersColumns } from '../../../apps/user-management/users-list/table/columns/_columns'
import { SheetContext } from '../../fichas/SheetContexts'
import { ListaSecciones } from '../../usuario/pages/secciones/ListaSecciones'
import Pagination from '../../../../shared/Pagination'
import { deletePartner, getAllPartners, getAllPartnersPag, updatePartner } from '../../../../services/colaboradores'
import { boolean } from 'yup'
import { formatDateToDMY } from '../../../../helpers/dateFunctions'
import { PartnerContext } from '../PartnerContexts'
import React from 'react'
import { changeStatusFicha } from '../../../../services/registroFicha'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import { deleteDocument, downloadAsZip, getDocuments } from '../../../../services/documentos'
import { ListDocuments } from './ListDocuments'
import { DataUser } from '../../../documentacion/MiFicha/DataUserInterface'
import { getProceso } from '../../../../services/procesos'

const LegajoDocumentsTable = () => {
  const users = useQueryResponseData()
  // const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  
  const usuarioJSON = localStorage.getItem('userData');
  let usuario: DataUser|null=null;
  
  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON);
  }

  const [isLoading, setLoadingStatus] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [procesosdata, setProcesosData] = useState<any[]>([])

  const [lista, setLista] = useState([])
  const [selecto, setSelecto] = useState(0)
  const [show, setShow] = useState(false)
  const [meliminar, setMeliminar] = useState(false)
  const [eliminar, setEliminar] = useState('')
  const [mactualizar, setMactualizar] = useState(false)
  const [actualizar, setActualizar] = useState('')
  const [mnuevo, setMnuevo] = useState(false)
  const [nuevo, setNuevo] = useState('')

  // const {searchTerm} = useContext(SheetContext)
  const { searchTerm, userSelected,selectIdProceso,documents, setSelectIdProceso,setListProcess,setListProcessAll,listProcess, searchSend, setSearchSend } =
    useContext(PartnerContext)

  const navigate = useNavigate()

  const iconsData = [
    {
      iconName: 'verify',
      title: 'Borrador',
      stateName: 'borrador',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Completado',
      stateName: 'completado',
      color: '#2ecc71', // Green
    }
  ]

  useEffect(() => {
    setLoadingStatus(true);
    setTimeout(() => {
      refetchData({
        estado: "A",
        responsables: usuario?.userId
      })
    }, 1000)
  }, [currentPage])

  useEffect(() => {
    if(searchSend){
      setSearchSend(false)
      setLoadingStatus(true);
      refetchData({
        estado: "A",
        responsables: usuario?.userId
      })
      setSelecto(procesosdata[0]?.["_id"])
      setSelectIdProceso(procesosdata[0]?.["_id"])
    }
  }, [searchSend])

  useEffect(() => {  
    setProcesosData(listProcess)
  }, [listProcess])

  const refetchData = (options: any) => {
    setLoadingStatus(true);
    //options= searchTerm?options:{}
    getProceso(currentPage,10,options).then((response) => {
      if (response.success) {
        setLoadingStatus(false);
        const auxTotalPage = response.length? Math.ceil(response.length/10): totalPages
        setTotalPages(auxTotalPage)
        setListProcess(response.data)
        MenuComponent.reinitialization()
      } else {
        console.log(response.msg)
      }
    })
    getProceso(1,50,options).then((response) => {
      if (response.success) {
        setListProcessAll(response.data)
      } else {
        console.log(response.msg)
      }
    })
    setEliminar('')
  }

  // Utility function to generate pagination links based on the current page and totalPages values
  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    // Generate "Previous" link if not on the first page
    if (currentPage > 1) {
      links.push({ page: currentPage - 1, label: 'Anterior' })
    }

    // Generate numbered pages
    for (let i = 1; i <= totalPages; i++) {
      links.push({ page: i, label: String(i) })
    }

    // Generate "Next" link if not on the last page
    if (currentPage < totalPages) {
      links.push({ page: currentPage + 1, label: 'Siguiente' })
    }

    return links
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    
  }

  const handleSelecto = (opt: any) => {
    if (opt === selecto) {
      setSelectIdProceso(opt)
      setSelecto(0)
    } else {
      setSelecto(opt)
      setSelectIdProceso(opt)
    }
  }


  // Custom Toggle Component
  const CustomToggle = React.forwardRef(({ onClick }: any, ref: any) => (
    <a
      ref={ref}
      href='#'
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
      title='Visualizar ficha'
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <KTIcon iconName='dots-square-vertical' className='fs-3' />
    </a>
  ))

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th className=''></th>
                <th className='text-start'>Nombre</th>
                <th className='text-start'></th>
                <th className='text-start'></th>
                <th className='text-end'>Accioness</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              { procesosdata.length > 0 ? (
                procesosdata.map((item: any) => (
                  <Fragment key={item._id} >
                    { Object.keys(documents).length > 0 && (
                      <tr >
                        <td onClick={() => handleSelecto(item._id)}>
                          <a href='# ' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {
                              selecto === item._id ? 
                                <i className="bi bi-arrow-up"></i>                            
                              :
                                <i className="bi bi-arrow-right"></i>
                            }
                            
                          </a>
                        </td>
                        <td className='text-start min-w-200px'>{item.nombre}</td>
                        <td className='text-start min-w-200px'></td>
                        <td className='text-start min-w-200px'></td>
                        <td className='text-end'></td>
                      </tr>

                    )}
                    {selecto === item._id && <ListDocuments id={selecto} />}

                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        
        <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export { LegajoDocumentsTable }
