import React from 'react'
import {Button, Modal, Table} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'

const icons = {
  warning: 'information-2',
  success: 'check',
}
interface TableProps {
  title: string
  headers: {key: string; label: string}[]
  data: Record<string, string>[]
}

interface ModalProps {
  show: boolean
  handleClose: () => void
  tables: TableProps[]
  icon: 'success' | 'warning'
}

const DataTable: React.FC<TableProps> = ({title, headers, data}) => {
  if (data.length === 0) return null

  return (
    <>
      <div>
        <h2>{title}</h2>
      </div>
      <Table responsive hover>
        <thead>
          <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-primary text-white'>
            {headers.map((header) => (
              <th key={header.key}>{header.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header) => (
                <td key={header.key}>{row[header.key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

const GlobalModal: React.FC<ModalProps> = ({icon, show, handleClose, tables}) => {
  return (
    <Modal show={show} onHide={handleClose} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>
          <KTIcon
            iconName={icons[icon]}
            className={`fs-5x ${icon === 'success' ? 'text-success' : 'text-warning'}`}
          />
          <span>{icon === 'success' ? 'Operacion exitosa' : 'Operacion con observaciones'}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {tables.map((tableProps, index) => (
          <div key={index} className='mb-4'>
            <DataTable {...tableProps} />
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export {GlobalModal}
