import { fetchSinToken } from "../helpers/fetch";

const secciones = async () => {
  const resp = await fetchSinToken(
    `seccion/`
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

 const seccionxficha = async (id) => {
    const resp = await fetchSinToken(
        `seccion/seccionesxficha/`,
        {idficha: id},
        "POST"
      );
      const body = await resp.json();      
    
      if (body.success) {
        localStorage.setItem("token", body.token);
        localStorage.setItem("token-init-date", new Date().getTime());
      }
    
      return body;
};

const nuevaSeccion = async (data) => {
  const resp = await fetchSinToken(
    `seccion/add`,
    data,
    "POST"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const actualizarSeccion = async (data,id) => {
  const resp = await fetchSinToken(
    `seccion/editar/${id}`,
    data,
    "PUT"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const eliminarSeccion = async (id) => {
  const resp = await fetchSinToken(
    `seccion/${id}`,
    {},
    "DELETE"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

const ordenarSecciones = async (idseccion, orden) => {
  const resp = await fetchSinToken(
    `seccion/ordenar`,
    {idseccion, orden},
    "POST"
  );
  const body = await resp.json();

  if (body.success) {
    localStorage.setItem("token", body.token);
    localStorage.setItem("token-init-date", new Date().getTime());
  }

  return body;
};

export {
  secciones,
  nuevaSeccion,
  seccionxficha,
  actualizarSeccion,
  eliminarSeccion,
  ordenarSecciones
};
