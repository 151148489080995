import {Badge, Button, Col, Container, Form, Row, Table} from 'react-bootstrap'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { useContext, useEffect, useState } from 'react'
import { DocumentLoadDetailContext } from '../DocumentLoadDetailContext'
import PDFViewer from './PDFViewer'

const DocumentQuickViewModalForm = () => {

  const {closeDocumentQuickViewModal, quickViewModalData} = useContext(DocumentLoadDetailContext)

  // const keyname = 'Empresa/647c0d730b0325fc1a8215de/kit/2023/09/exploring inkas times 5d-4n.pdf';
  // const filename = 'exploring inkas times 5d-4n.pdf';

  return (
    <>
      <div>
        <Container>
          <Row className='mb-5'>
            <Col sm>
              <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-end'>
                  <h5>{quickViewModalData.name}</h5>
                </div>
                {
                  quickViewModalData.type === 'PDF'? <></> : <h6>Test 1</h6>
                }
              </div>
            </Col>
          </Row>
          <Row className='mb-3'>
              <Col sm>
                {
                  quickViewModalData.type === 'PDF'? <></> : <div className='d-flex justify-content-start mb-5'>
                  <Button
                    variant='primary'
                    className='mx-3'
                    type='button'
                    // onClick={() => {
                    //   closeDocumentQuickViewModal()
                    // }}
                  >
                    Anterior
                  </Button>
                  <Button
                    variant='primary'
                    type='button'
                    // onClick={() => {
                    //   closeDocumentQuickViewModal()
                    // }}
                  >
                    Siguiente
                  </Button>
                </div>
                }
                <PDFViewer keyn={quickViewModalData.keyname} filen={quickViewModalData.filename} />
              </Col>
            </Row>
            <Row>
              <div className='d-flex justify-content-end'>
                <Button
                  variant='light'
                  // className='mx-3'
                  type='reset'
                  onClick={() => {
                    closeDocumentQuickViewModal()
                  }}
                >
                  Cerrar
                </Button>
                {/* <Button variant='primary' type='submit'>
                  {!false && 'Guardar'}
                  {false && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Guardando...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </Button> */}
              </div>
            </Row>
        </Container>
      </div>
    </>
  )
}

export {DocumentQuickViewModalForm}
