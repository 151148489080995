import React, {useContext} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {AltaContext} from '../AltaContext'

const ExportFieldHeader: React.FC = () => {
  const {onCloseFieldModal} = useContext(AltaContext)

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>Campos de Ficha</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        onClick={() => onCloseFieldModal()}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
    </div>
  )
}

export {ExportFieldHeader}
