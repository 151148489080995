import {useContext, useEffect, useState} from 'react'
import {Row, Form, Button} from 'react-bootstrap'
import {SheetEditContext} from '../SheetContexts'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import AssigmentListGroup from './AssigmentListGroup'
import CheckboxTree from 'react-checkbox-tree'
import { v4 } from 'uuid'
import { Field } from '../Sheet'


const AssignmentEditModalForm = () => {
  const {closeAssignmentModal, fieldData, setFieldData, sheetData, setData,sectionData} = useContext(SheetEditContext)

  // const [formData, setFormData] = useState<Field>({
  //   id: v4(),
  //   name: '',
  //   isDependent: false,
  //   responseType: '',
  //   comment: '',
  //   visible: true,
  //   editable: false,
  //   required: false,
  //   collectionName: '',
  //   options: [],
  //   assignedTo: ''
  // })

  // useEffect(() => {

  //   console.log(fieldData)

  //   if (fieldData.id.length > 0) {
  //     setFormData(fieldData)
  //     console.log(formData)
  //   } 
  // }, [])

  const transformedData = [
    {
      value: 'CL_RazonSocial',
      label: 'CL_RazonSocial: TAWA CONSULTING',
      children: [
        {
          value: 'CL_Gerencia',
          label: 'CL_Gerencia: OUTSOURCING REMUNERACIONES',
          children: [
            {
              value: 'CL_Area',
              label: 'CL_Area: OUTSOURCING REMUNERACIONES',
              children: [
                {
                  value: 'CL_SubArea',
                  label: 'CL_SubArea: OUTSOURCING REMUNERACIONES\r\n',
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      value: 'CentroCosto',
      label: 'CentroCosto: ',
      children: [],
    },
    {
      value: 'RQ_BusinessPartner',
      label: 'RQ_BusinessPartner: GHIBELLINI BELLIDO CHIARA',
      children: [],
    },
    {
      value: 'RQ_Solicitante',
      label: 'RQ_Solicitante: null',
      children: [],
    },
    {
      value: 'RQ_Puesto',
      label: 'RQ_Puesto: Practicante profesional',
      children: [],
    },
    // Continue adding other properties and nested children...
    {
      value: 'CandidatosSeleccionados',
      label: 'CandidatosSeleccionados: ',
      children: [
        {
          value: 'CS_ApPaterno',
          label: 'CS_ApPaterno: GOMERO',
          children: [],
        },
        {
          value: 'CS_ApMaterno',
          label: 'CS_ApMaterno: PEREZ',
          children: [],
        },
        {
          value: 'CS_Nombres',
          label: 'CS_Nombres: KATJUSKA ARAIZA',
          children: [],
        },
        {
          value: 'CS_TipoDocumento',
          label: 'CS_TipoDocumento: Dni',
          children: [],
        },
        {
          value: 'CS_Documento',
          label: 'CS_Documento: 73511867',
          children: [],
        },
        // Continue adding other properties and leaf nodes...
      ],
    },
  ]

  const handleListChange = (name: string) => {
    setFieldData({...fieldData, assignedTo: name})
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    setData({
      ...sheetData,
      sections: (sheetData.sections || []).map((x) =>
        x.id === sectionData.id
          ? {
              ...sectionData,
              fields: (sectionData.fields || []).map((y) =>
                y.id === fieldData.id ? fieldData : y
              ),
            }
          : x
      ),
    })

    console.log(sheetData)
    closeAssignmentModal()
  }

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group className='mb-3' controlId='formServicio'>
          <Form.Label>Servicio</Form.Label>
          <Form.Control as='select' name='type'>
            <option value='independent'>Workin Selección</option>
            {/* <option value='dependent'>Dependiente</option> */}
          </Form.Control>
        </Form.Group>

        <Row className='mb-3'>
          <AssigmentListGroup elementSelected={fieldData.assignedTo} onChange={(name: string)=>handleListChange(name)} />
          {/* <CheckboxTree
            nodes={transformedData}
            checked={[]} // An array of checked node values (if any)
            expanded={[]} // An array of expanded node values (if any)
            onCheck={(checked) => console.log('Checked nodes: ', checked)}
            onExpand={(expanded) => console.log('Expanded nodes: ', expanded)}
          /> */}
        </Row>

        <Row>
          <div className='d-flex justify-content-end'>
            <Button
              variant='light'
              className='mx-3'
              type='reset'
              onClick={() => {
                closeAssignmentModal()
              }}
            >
              Cancelar
            </Button>
            <Button variant='primary' type='submit'>
              Guardar
            </Button>
          </div>
        </Row>
      </Form>
    </>
  )
}

export {AssignmentEditModalForm}
