import React, {useEffect, useState} from 'react'
import {Col, Form, Modal, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {tipos} from '../../../../../../services/tipos'
import {nuevoCampo} from '../../../../../../services/campos'
import {SeccionOpciones} from '../secciones/SeccionOpciones'

export const ValidatioError = styled.p`
  color: red;
  margin-top: 5px;
`

export const ModalNuevo = ({show, setShow, setUpdated, updated, idseccion}) => {
  const [datatipos, setDataTipos] = useState('')
  const [showopc, setShowOpc] = useState(false)
  const [opciones, setOpciones] = useState([])
  const {register, setValue, handleSubmit, formState, clearErrors} = useForm()

  const {errors} = formState

  useEffect(() => {
    tipos().then((response) => {
      if (response?.success) {
        setDataTipos(response?.data)
      } else {
        console.log('error al obtener los tipos')
      }
    })
  }, [])

  const onSubmit = (datasend) => {
    const {titulo, tipo, obligatorio, editable, comentario, visible} = datasend
    const dataO = {
      idseccion: idseccion,
      idtipo: tipo,
      titulo: titulo,
      obligatorio: obligatorio,
      orden: 0,
      tabla: '',
      estado: 'A',
      editable: editable,
      comentario: comentario,
      visible: visible,
    }

    nuevoCampo(dataO)
      .then((response) => {
        if (response?.success) {
          setUpdated(!updated)
        } else {
          console.log(response?.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
    setShow(false)
  }

  const handleCerrar = () => {
    setShow(false)
  }

  const handleOpciones = (e) => {
    if (e.target.value === '647c0db70b0325fc1a8215e5') {
      console.log('llego aqui')
      setShowOpc(true)
    } else {
      setShowOpc(false)
      console.log('aqui')
    }
  }

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-2}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={() => handleCerrar()}
    >
      <div className='modal-header'>
        <h2>Nuevo Campo</h2>
        {/* begin::Close */}
        <div
          className='btn btn-sm btn-icon btn-active-color-primary'
          onClick={() => handleCerrar()}
        >
          X
        </div>
        {/* end::Close */}
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='current' data-kt-stepper-element='content'>
            <div className='w-100'>
              {/*begin::Form Group */}
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Nombre de Campo</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Titulo del campo a llenar'
                  ></i>
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='titulo'
                  placeholder=''
                  {...register('titulo', {required: true})}
                  autoComplete='off'
                />
                {errors?.nombre && <ValidatioError>Nombre requerido</ValidatioError>}
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Comentario</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Tipo de campo'
                  ></i>
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='comentario'
                  placeholder=''
                  {...register('comentario', {required: true})}
                  autoComplete='off'
                />
                {errors?.descripcion && <ValidatioError>Comentario requerido</ValidatioError>}
              </div>
              <Row>
                <Col>
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                      <span className='required'>Tipo</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Indica el tipo'
                      ></i>
                    </label>
                    <select
                      className='form-select form-select-sm form-select-solid'
                      data-control='select2'
                      data-placeholder='Latest'
                      data-hide-search='true'
                      {...register('tipo', {required: true})}
                      onChange={(e) => handleOpciones(e)}
                    >
                      {/* <option value="646d44cbc0a8ddf55dadeae3"></option> */}
                      {datatipos && (
                        <>
                          {datatipos?.map((item) => (
                            <option value={item._id} key={item._id}>
                              {item.nombre}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>
                </Col>
                <SeccionOpciones show={showopc} setOpciones={setOpciones} opciones={opciones}/>
              </Row>
              <Row>
                <Col>
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                      <span className='required'>¿Requerido?</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Indica si es campo requerido'
                      ></i>
                    </label>
                    <select
                      className='form-select form-select-sm form-select-solid'
                      data-control='select2'
                      data-placeholder='Latest'
                      data-hide-search='true'
                      {...register('obligatorio', {required: true})}
                    >
                      <option value={false}></option>
                      <option value={true}>Si</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </Col>
                <Col>
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                      <span className='required'>¿Es Editable?</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Indica si es campo editable'
                      ></i>
                    </label>
                    <select
                      className='form-select form-select-sm form-select-solid'
                      data-control='select2'
                      data-placeholder='Latest'
                      data-hide-search='true'
                      {...register('editable', {required: true})}
                    >
                      <option value={false}></option>
                      <option value={true}>Si</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </Col>
                <Col>
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                      <span className='required'>¿Es Visible?</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Indica si es campo visible'
                      ></i>
                    </label>
                    <select
                      className='form-select form-select-sm form-select-solid'
                      data-control='select2'
                      data-placeholder='Latest'
                      data-hide-search='true'
                      {...register('visible', {required: true})}
                    >
                      <option value={false}></option>
                      <option value={true}>Si</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <button
            type='button'
            className='btn btn-lg btn-danger mr-5'
            data-kt-stepper-action='next'
            onClick={() => handleCerrar()}
            style={{marginRight: 20}}
          >
            Cancelar
          </button>
          <button type='submit' className='btn btn-lg btn-primary' data-kt-stepper-action='next'>
            Crear
          </button>
        </div>
      </Form>
    </Modal>
  )
}
