import {Fragment, useContext, useEffect, useState} from 'react'
import {OverlayTrigger, Stack, Tooltip} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import Swal from 'sweetalert2'
import {KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import {formatDateToDMY} from '../../../../helpers/dateFunctions'
import {generarAuthenticacionData} from '../../../../services/authenticacion'
import {getCompany} from '../../../../services/companies'
import {
  getAllDocumentsSent,
  rejectDocument,
  signDocument,
  signMultipleDocuments,
  viewDocument,
} from '../../../../services/documentSent'
import {getFileInPDF} from '../../../../services/files'
import {fetchMenuActionsByRole, fetchSubMenuByUrl} from '../../../../services/menu'
import {
  showMessage,
  showRejectionMessage,
  showResponseValidacionMessage,
  showSignatureMessage,
} from '../../../../shared/Alerts'
import Pagination from '../../../../shared/Pagination'
import {UsersListLoading} from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import {useAuth2} from '../../../authv2/Auth2Provider'
import OverlayLoading from '../../../generales_component/OverlayLoading'
import {DocumentTrayCustomizableTableProps} from '../DocumentTray.interface'
import {DocumentTrayContext} from '../DocumentTrayContexts'

const DocumentTrayCustomizableTable: React.FC<DocumentTrayCustomizableTableProps> = ({
  state,
  onChange,
}) => {
  const [lista, setLista] = useState<any>([])
  const [userData, setUserData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [isLoading, setLoadingStatus] = useState(false)
  const [cargandoFile, setCargandoFirma] = useState(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [multifactorEmpresa, setMultifactorEmpresa] = useState(true)
  const [permissions, setPermissions] = useState<string[]>([])
  const {selectedRole} = useAuth2()
  const location = useLocation()

  const {openPreviewDocumentModal, setKeyname, searchTerm, setFromPreviewTab} =
    useContext(DocumentTrayContext)

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu =
            response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  useEffect(() => {
    getUserData()
    getCompany()
      .then((response) => {
        if (response.success) {
          const empresa = response.data
          setMultifactorEmpresa(empresa?.multifactorCode ?? true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (userData.userId) {
      getEmployeeDocuments(userData.userId, state)
    }
  }, [userData])

  useEffect(() => {
    if (userData.userId) {
      getEmployeeDocuments(userData.userId, state, currentPage)
    }
  }, [searchTerm])

  const getEmployeeDocuments = (userId: string, state: string, currentPage = 1) => {
    const query = {
      userId: userId,
      state: state,
      name: searchTerm,
      currentPage: currentPage,
      limit: 10,
    }

    getAllDocumentsSent(query)
      .then((response) => {
        setLoadingStatus(false)
        setLista(response.data)
        setTotalPages(response.totalPages)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getUserData = () => {
    const data = localStorage.getItem('userData')
    if (data) {
      setUserData(JSON.parse(data))
    }
  }

  const handleDocumentSignature = (documentSent: any) => {
    showSignatureMessage().then((result) => {
      if (result.isConfirmed) {
        setCargandoFirma(true)
        if (multifactorEmpresa) {
          generarAuthenticacionData({
            id_documentSent: [documentSent._id],
            id_usuario: userData.userId,
            motivo: 'signed',
          })
            .then((response) => {
              setCargandoFirma(false)
              const detalle =
                'Comprueba tu bandeja de entrada, te hemos enviado un código a tu correo.'
              showResponseValidacionMessage(detalle, {
                codigo: '',
                id_documentSent: [documentSent._id],
                id_usuario: userData.userId,
                motivo: 'signed',
              }).then((result1) => {
                if (result1.isConfirmed) {
                  Swal.fire({
                    title: 'Codigo Valido!',
                    text: 'Firmando Documento ...',
                    icon: 'success',
                  })
                  signDocument(documentSent._id)
                    .then((response) => {
                      if (response.success) {
                        Swal.close()
                        const link = document.createElement('a')
                        link.href = response.data
                        link.download = 'signed'
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)

                        onChange()
                        getEmployeeDocuments(userData.userId, state)

                        showMessage('success', '¡Buen trabajo!', response.message)
                      } else {
                        console.log(response)
                        showMessage('error', 'Hubo un error', response.error)
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                      showMessage('error', 'Hubo un error', error.message)
                    })
                }
              })
            })
            .catch((error) => {
              setCargandoFirma(false)
              console.log(error)
              showMessage('error', 'Hubo un error', error.message)
            })
        } else {
          signDocument(documentSent._id)
            .then((response) => {
              if (response.success) {
                Swal.close()
                const link = document.createElement('a')
                link.href = response.data
                link.download = 'signed'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                onChange()
                getEmployeeDocuments(userData.userId, state)

                showMessage('success', '¡Buen trabajo!', response.message)
              } else {
                console.log(response)
                showMessage('error', 'Hubo un error', response.error)
              }
            })
            .catch((error) => {
              console.log(error)
              showMessage('error', 'Hubo un error', error.message)
            })
        }
      }
    })
  }

  const handleRejection = (documentSent: any) => {
    showRejectionMessage().then((result) => {
      if (result.isConfirmed) {
        rejectDocument(documentSent._id, result.value)
          .then((response) => {
            if (response.success) {
              onChange()
              getEmployeeDocuments(userData.userId, state)

              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              showMessage('error', 'Hubo un error', response.error)
            }
          })
          .catch((error) => {
            showMessage('error', 'Hubo un error', error.message)
          })
      }
    })
  }

  const handleOpenPreviewModal = (
    keyname: string,
    id: string,
    includeSign: boolean,
    idUserSign: string
  ) => {
    setKeyname(keyname)
    setFromPreviewTab({
      showSignature: state === 'pending',
      signCeo: includeSign,
      idUserSign,
    })
    openPreviewDocumentModal()

    viewDocument(id)
      .then((response) => {
        console.log(response)
        getEmployeeDocuments(userData.userId, state, currentPage)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    if (currentPage > 1) {
      links.push({page: currentPage - 1, label: 'Anterior'})
    }

    for (let i = 1; i <= totalPages; i++) {
      links.push({page: i, label: String(i)})
    }

    if (currentPage < totalPages) {
      links.push({page: currentPage + 1, label: 'Siguiente'})
    }

    return links
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    getEmployeeDocuments(userData.userId, state, page)
  }

  const handleToggle = (id: string) => {
    const currentIndex = selectedItems.indexOf(id)
    const newSelected = [...selectedItems]

    if (currentIndex === -1) {
      newSelected.push(id)
    } else {
      newSelected.splice(currentIndex, 1)
    }
    setSelectedItems(newSelected)
  }

  const handleMultipleSignatures = () => {
    showSignatureMessage().then((result) => {
      if (result.isConfirmed) {
        setCargandoFirma(true)

        if (multifactorEmpresa) {
          generarAuthenticacionData({
            id_documentSent: selectedItems,
            id_usuario: userData.userId,
            motivo: 'signed',
          })
            .then((response) => {
              setCargandoFirma(false)
              const detalle =
                'Comprueba tu bandeja de entrada, te hemos enviado un código a tu correo.'
              showResponseValidacionMessage(detalle, {
                codigo: '',
                id_documentSent: selectedItems,
                id_usuario: userData.userId,
                motivo: 'signed',
              }).then((result1) => {
                if (result1.isConfirmed) {
                  Swal.fire({
                    title: 'Codigo Valido!',
                    text: 'Firmando Documentos ...',
                    icon: 'success',
                  })

                  setCargandoFirma(true)
                  signMultipleDocuments(selectedItems)
                    .then((response) => {
                      if (response.success) {
                        const link = document.createElement('a')
                        link.href = response.data
                        link.download = 'signed_multiple'
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)

                        onChange()
                        getEmployeeDocuments(userData.userId, state)

                        showMessage('success', '¡Buen trabajo!', response.message)
                      } else {
                        console.log(response)
                        showMessage('error', 'Hubo un error', response.error)
                      }
                      setCargandoFirma(false)
                    })
                    .catch((error) => {
                      console.log(error)
                      setCargandoFirma(false)
                      showMessage('error', 'Hubo un error', error.message)
                    })
                }
              })
            })
            .catch((error) => {
              setCargandoFirma(false)
              console.log(error)
              showMessage('error', 'Hubo un error', error.message)
            })
        } else {
          signMultipleDocuments(selectedItems)
            .then((response) => {
              if (response.success) {
                const link = document.createElement('a')
                link.href = response.data
                link.download = 'signed_multiple'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                onChange()
                getEmployeeDocuments(userData.userId, state)

                showMessage('success', '¡Buen trabajo!', response.message)
              } else {
                console.log(response)
                showMessage('error', 'Hubo un error', response.error)
              }
              setCargandoFirma(false)
            })
            .catch((error) => {
              console.log(error)
              setCargandoFirma(false)
              showMessage('error', 'Hubo un error', error.message)
            })
        }
      }
    })
  }

  const handleDownload = (
    keyname: string,
    id: string,
    name: string,
    includeSign: boolean,
    idUserSign: string
  ) => {
    const partes = keyname.split('.')
    const extension = partes[partes.length - 1]
    const partesname = name.split('.')
    let filename = ''
    let exist = false
    partesname.forEach((par) => {
      filename = filename + '.' + par
      if (String(extension) === String(par)) {
        exist = true
      }
    })

    getFileInPDF(keyname, {showSignature: state === 'pending', signCeo: includeSign, idUserSign})
      .then((response) => {
        if (response.success) {
          const link = document.createElement('a')
          link.href = response.data
          link.download = filename
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          showMessage('success', '¡Buen trabajo!', response.message)

          viewDocument(id)
            .then((response) => {
              getEmployeeDocuments(userData.userId, state, currentPage)
              console.log(response)
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          showMessage('error', 'Hubo un error', response.error)
        }
      })
      .catch((error) => {
        showMessage('error', 'Hubo un error', error.message)
      })
  }

  return (
    <>
      <KTCardBody>
        {permissions.includes('Firmar Documento') && (
          <div
            className={'d-flex align-self-end justify-content-end '}
            title='Seleccionar mas 1 documento a firmar'
          >
            <button
              type='button'
              disabled={selectedItems.length > 1 ? false : true}
              className='btn btn-primary mb-3'
              onClick={() => handleMultipleSignatures()}
            >
              Firmar documentos seleccionados
            </button>
          </div>
        )}
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {state === 'pending' && <th className='text-start'></th>}
                <th className='text-start'>Proceso</th>
                <th className='text-start'>Fecha</th>
                <th className='text-start'>Nombre</th>
                <th className='text-end'>Acciones</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>
              {lista?.length > 0 ? (
                lista?.map((item: any, index: any) => (
                  <Fragment key={item._id}>
                    <tr>
                      {state === 'pending' &&
                        (item.isVisualised ? (
                          <th className='w-25px'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id={`checkbox-document-tray-${item._id}`}
                                onChange={(e) => handleToggle(item._id)}
                              />
                            </div>
                          </th>
                        ) : (
                          <OverlayTrigger
                            placement='right'
                            overlay={
                              <Tooltip id='disabled-tooltip'>
                                Esta casilla está desactivada porque el documento no se ha
                                visualizado o descargado.
                              </Tooltip>
                            }
                          >
                            <th className='w-25px'>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id={`checkbox-document-tray-${item._id}`}
                                  disabled
                                />
                              </div>
                            </th>
                          </OverlayTrigger>
                        ))}

                      <td className='text-start min-w-100px'>{item.processName}</td>
                      <td className='text-start min-w-100px'>
                        {formatDateToDMY(
                          ['signed', 'rejected'].includes(state) ? item.updatedAt : item.createdAt
                        )}
                      </td>

                      <td className='text-start min-w-100px'>{item.name}</td>

                      <td className='text-end d-flex align-self-end justify-content-end'>
                        {state === 'pending' && permissions.includes('Firmar Documento') && (
                          <>
                            {item.isVisualised ? (
                              <button
                                type='button'
                                className='btn btn-success mx-8'
                                onClick={() => handleDocumentSignature(item)}
                              >
                                Firmar documento
                              </button>
                            ) : (
                              <OverlayTrigger
                                placement='left'
                                overlay={
                                  <Tooltip id='disabled-tooltip'>
                                    Este botón está desactivado porque el documento no se ha
                                    visualizado o descargado
                                  </Tooltip>
                                }
                              >
                                <span>
                                  <button
                                    type='button'
                                    className='btn btn-success mx-8'
                                    onClick={() => handleDocumentSignature(item)}
                                    disabled
                                  >
                                    Firmar documento
                                  </button>
                                </span>
                              </OverlayTrigger>
                            )}
                          </>
                        )}
                        <Stack direction='horizontal' gap={1}>
                          {permissions.includes('Descargar Documento') && (
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              title='Descargar documento'
                              onClick={() =>
                                handleDownload(
                                  item.keyname,
                                  item._id,
                                  item.name,
                                  item.includeSign,
                                  item?.signMetadata?.signFrom
                                )
                              }
                            >
                              <KTIcon iconName='cloud-download' className='fs-3' />
                            </button>
                          )}

                          {permissions.includes('Visualizar Documento') && (
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              title='Previsualizar documento'
                              onClick={() =>
                                handleOpenPreviewModal(
                                  item.keyname,
                                  item._id,
                                  item.includeSign,
                                  item?.signMetadata?.signFrom
                                )
                              }
                            >
                              <KTIcon iconName='document' className='fs-3' />
                            </button>
                          )}

                          {state === 'pending' && permissions.includes('Rechazar Documento') && (
                            <>
                              {item.isVisualised ? (
                                <button
                                  type='button'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                  title='Rechazar documento'
                                  onClick={() => handleRejection(item)}
                                >
                                  <KTIcon iconName='file-deleted' className='fs-3' />
                                </button>
                              ) : (
                                <OverlayTrigger
                                  placement='left'
                                  overlay={
                                    <Tooltip id='reject-tooltip'>
                                      Este botón está desactivado porque el documento no se ha
                                      visualizado
                                    </Tooltip>
                                  }
                                >
                                  <span>
                                    <button
                                      type='button'
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                      onClick={() => handleRejection(item)}
                                      disabled
                                    >
                                      <KTIcon iconName='file-deleted' className='fs-3' />
                                    </button>
                                  </span>
                                </OverlayTrigger>
                              )}
                            </>
                          )}
                        </Stack>
                      </td>
                    </tr>
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <UsersListPagination /> */}
        <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
        {cargandoFile && <OverlayLoading />}
      </KTCardBody>
    </>
  )
}

export {DocumentTrayCustomizableTable}
