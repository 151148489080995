import { fetchConToken } from "../helpers/fetch";

const getDocuments = async (options: any) => {
    const queryString = new URLSearchParams(options).toString();

    const resp = await fetchConToken(
        `documento?${queryString}`,
        {},
        'GET'
    );

    const body = await resp.json();

    return body;
};

const getDocumentById = async (id: string) => {
    const resp = await fetchConToken(
        `documento/${id}`,
        {},
        'GET'
    );
    const body = await resp.json();

    return body;
}

const getDocumentByIdUsuario = async (id: string, options: any) => {
    const queryString = new URLSearchParams(options).toString();
    const resp = await fetchConToken(
        `documento/user/${id}?${queryString}`,
        {},
        'GET'
    );
    const body = await resp.json();

    return body;
}

const getDocumentsByIdUsuarios = async (options: any) => {
    const resp = await fetchConToken(
        `documento/documents_user`,
        options,
        'POST'
    );
    const body = await resp.json();

    return body;
}

const downloadFilePlantillaMatchS3 = async (data: any) => {
    const resp = await fetchConToken(
        `documento/downloadS3Plantilla`,
        data,
        "POST"
    );
    const body = await resp.json();
    return body;
};

const showFilePlantillaMatchS3 = async (data: any) => {
    const resp = await fetchConToken(
        `documento/showS3Plantilla`,
        data,
        "POST"
    );
    const body = await resp.json();
    return body;
};

const deleteDocument = async (id: string) => {
    const resp = await fetchConToken(
        `documento/${id}`,
        {},
        'DELETE'
    );
    const body = await resp.json();

    return body;
}
const saveDocument = async (data: any) => {
    const resp = await fetchConToken(
        `documento`,
        data,
        'POST'
    );
    const body = await resp.json();

    return body;
}

const updateDocument = async (id: string, updatedData: any) => {
    const resp = await fetchConToken(
        `documento/${id}`,
        updatedData,
        'PUT'
    );
    const body = await resp.json();

    return body;
}

const downloadAsZip = async (id: string) => {
    const resp = await fetchConToken(
        `documento/downloadAsZip/${id}`,
        {},
        "GET"
    );
    const body = await resp.json();

    return body;
};

const generateLoadExcel = async (params: any) => {
    try {
        const resp = await fetchConToken(`documento/generate_load_excel`, params, 'POST')
        const responseData = await resp.json()
        console.log("response data " + JSON.stringify(responseData))
        const uint8Array = new Uint8Array(responseData.data)

        const excelBlob = new Blob([uint8Array], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        const anchor = document.createElement('a')

        anchor.href = URL.createObjectURL(excelBlob)

        const currentDate = new Date();
        const dd = String(currentDate.getDate()).padStart(2, '0');
        const mm = String(currentDate.getMonth() + 1).padStart(2, '0');
        const yyyy = currentDate.getFullYear();
        anchor.download = `reporte_cargas_${dd}-${mm}-${yyyy}.xlsx`;


        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
    } catch (error) {
        console.error('Error generating Excel:', error)
    }
}


interface BulkLoadData {
  processId: string
  dni: string
//   loadId: string
  loadName: string
  files: Record<string, any>[]
}

const bulkLoad = async (data: BulkLoadData) => {
    const resp = await fetchConToken(
        'documento/bulk_load',
        data,
        'POST'
    )

    return await resp.json()
}

const deleteDocumentsBulk = async (id: string) => {
    const resp = await fetchConToken(`documento/delete_documents_bulk/${id}`, {}, 'DELETE')
    return await resp.json()
}

interface BulkLoadDataMany {
  processId: string
  users: string[]
  loadName: string
  files: Record<string, any>[]
}

const bulkLoadMany = async (data: BulkLoadDataMany) => {
  const resp = await fetchConToken('documento/bulk_load_many', data, 'POST')

  return await resp.json()
}

const getMissingTags = async (data: any) => {
  const resp = await fetchConToken('documento/missing_tags', data, 'POST')

  return await resp.json()
}

const changeLevelStatus = async (data: any) => {
  const resp = await fetchConToken('documento/change_level_status', data, 'POST')

  return await resp.json()
}

const sendDocumentsWhenApprove = async (data: any) => {
  const resp = await fetchConToken('documento/send_docs_approve', data, 'POST')

  return await resp.json()
}

export {
    deleteDocument, downloadAsZip, downloadFilePlantillaMatchS3, generateLoadExcel, getDocumentById,
    getDocumentByIdUsuario, getDocuments, getDocumentsByIdUsuarios, saveDocument, showFilePlantillaMatchS3, updateDocument,
    bulkLoad, deleteDocumentsBulk, bulkLoadMany, getMissingTags, changeLevelStatus,
    sendDocumentsWhenApprove
}

