import { auto } from '@popperjs/core';
import React, { useRef,useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

interface SignaturePadProps {
    onFirmaSelected: (Image:string, index: number) => void;
    shouldClearFirma: boolean;
    onClearFirma: () => void;
}

const SignaturePad : React.FC<SignaturePadProps> = ({ onFirmaSelected, shouldClearFirma, onClearFirma}) => {
    const signatureRef = useRef<SignatureCanvas>(null);
    const [enabled, setEnabled] = useState(false);
    const handleClear = () => {
        signatureRef.current?.clear();
        onFirmaSelected(String(""),0);
        onClearFirma();
        setEnabled(false)
    };

    React.useEffect(() => {
        if (shouldClearFirma) {
            handleClear();
        }
    }, [shouldClearFirma]);

    const handleSave = () => {
        const signatureImage = signatureRef.current?.toDataURL();
        onFirmaSelected(String(signatureImage),0);
        setEnabled(true)
    };

    return (
        <div>
        {/* Componente SignatureCanvas */}
        <SignatureCanvas
            ref={signatureRef}
            canvasProps={{ 
                width: auto, 
                height: 150, 
                className: 'signature-canvas',
                style: { backgroundColor: '#f2f2f2' } 
            }}
        />

        {/* Botones para limpiar y guardar */}
        <div className='button-container' >
            <button className="btn btn-light-danger btn-sm" onClick={handleClear}>Limpiar</button>
            <button className={`btn btn-light-success btn-sm ${enabled? 'disabled': ''}` } onClick={handleSave}>Guardar firma</button>
        </div>
        </div>
    );
};

export default SignaturePad;
