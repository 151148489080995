import { Badge, Button, Col, Container, Form, OverlayTrigger, Popover, Row, Table, Tooltip } from 'react-bootstrap'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { Fragment, useContext, useEffect, useState } from 'react'
import { DocumentLoadDetailContext } from '../DocumentLoadDetailContext'
import { handleDownload, handleDownloadBase64, handleS3Convert64, handleS3PlantillaMatch, handleView } from '../../../../generales_functions/files'
import OverlayLoading from './../../../../generales_component/OverlayLoading';
import { file } from '../../../../../services/files'
import { getDocumentDetail, signDocumentCPD } from '../../../../../services/documentSent'
import { showMessage, showResponseValidacionMessage, showSignatureMessage } from '../../../../../shared/Alerts'
import { getCompany } from '../../../../../services/companies'
import { generarAuthenticacionData } from '../../../../../services/authenticacion'
import Swal from "sweetalert2"

const DocumentDetailModalForm = () => {

  const buttonsData = [
    {
      iconName: 'verify',
      title: 'Pendiente',
      stateName: 'pending',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Firmado',
      stateName: 'signed',
      color: '#2ecc71', // Green
    },

    {
      iconName: 'exclamation-triangle', // Adjust the icon name
      title: 'Rechazado',
      stateName: 'rejected',
      color: '#e74c3c', // Red
    },
  ]

  const { closeDocumentDetailModal,openDocumentQuickViewModal,openDocumentDetailModal, quickViewModalData, data, setViewDocumento, setShowFileModal } = useContext(DocumentLoadDetailContext)
  const [users, setUsers] = useState([])
  const [userData, setUserData] = useState<any>([])
  const [cargandoFile, setCargandoFile] = useState(false)
  const [updateData, setUpdateData] = useState(false)
  const [documentDetail, setDocumentDetail] = useState<any>([])
  const [cargandoFirma, setCargandoFirma] = useState(false);
  const [multifactorEmpresa, setMultifactorEmpresa] = useState(true)

  useEffect(() => {

    getDetail(
      {
        loadId: data._id,
        processId: quickViewModalData.idproceso,
        fileId: quickViewModalData._id
      }
    )
    getUsers()
    getUserData();
    getCompany()
        .then(response => {
            if (response.success) {
                const empresa = response.data
                setMultifactorEmpresa(empresa?.multifactorCode ?? true)
            }
        })
        .catch((error) => {
            console.log(error)
        })
  }, []);

  
  useEffect(() => {
    if(updateData){
      setUpdateData(false)
      getDetail(
        {
          loadId: data._id,
          processId: quickViewModalData.idproceso,
          fileId: quickViewModalData._id
        }
      )
      getUsers()
      getUserData();
      getCompany()
          .then(response => {
              if (response.success) {
                  const empresa = response.data
                  setMultifactorEmpresa(empresa?.multifactorCode ?? true)
              }
          })
          .catch((error) => {
              console.log(error)
          })
    }
    
  }, [updateData]);

  useEffect(() => {

    getDetail(
      {
        loadId: data._id,
        processId: quickViewModalData.idproceso,
        fileId: quickViewModalData._id
      }
    )
    getUsers()
    getUserData();
    getCompany()
        .then(response => {
            if (response.success) {
                const empresa = response.data
                setMultifactorEmpresa(empresa?.multifactorCode ?? true)
            }
        })
        .catch((error) => {
            console.log(error)
        })
  }, []);

  const getUserData = () => {

    const data = localStorage.getItem('userData')

    if (data) {
        setUserData(JSON.parse(data))
    }
}

  const getUsers = () => {
    if (quickViewModalData.typeSelected === 'Personalizado') {
      // setUsers(data?.filesChildren?.map((x: any) => ({ dni: x.dni, nombre: data?.idUsers.filter((n: any) => n.dni === x.dni)[0]?.nombre })))
      setUsers(data?.idUsers)
    } else {
      setUsers(data?.idUsers)
      console.log("data?.idUsers")
      console.log(data?.idUsers)
    }
  }

  const getDetail = (params: any) => {

    getDocumentDetail(params)
      .then((response) => {
        if (response.success) {
          setDocumentDetail(response.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getDocumentByUser = (userId: string) => {
    return documentDetail.find((x: any) => x.userId === userId)
  }

  const handleDocumentSignature = (documentSent: any) => {
    showSignatureMessage().then((result) => {
        if (result.isConfirmed) {
            setCargandoFirma(true)
            if (multifactorEmpresa) {
                generarAuthenticacionData({
                  id_documentSent: [documentSent._id],
                  id_usuario: userData.userId,
                  motivo: 'signed',
                })
                  .then((response) => {
                    setCargandoFirma(false)
                    const detalle =
                      'Comprueba tu bandeja de entrada, te hemos enviado un código a tu correo.'
                    showResponseValidacionMessage(detalle, {
                      codigo: '',
                      id_documentSent: [documentSent._id],
                      id_usuario: userData.userId,
                      motivo: 'signed',
                    }).then((result1) => {
                      if (result1.isConfirmed) {
                        Swal.fire({
                          title: 'Codigo Valido!',
                          text: 'Firmando Documento ...',
                          icon: 'success',
                        })
                        signDocumentCPD(documentSent._id)
                          .then((response) => {
                            if (response.success) {
                              Swal.close()
                              const link = document.createElement('a')
                              link.href = response.data
                              link.download = 'signed'
                              document.body.appendChild(link)
                              link.click()
                              document.body.removeChild(link)
                              setCargandoFirma(false)
                              setUpdateData(true)
                              showMessage('success', '¡Buen trabajo!', response.message)
                            } else {
                              console.log(response)
                              showMessage('error', 'Hubo un error', response.error)
                            }
                          })
                          .catch((error) => {
                            console.log(error)
                            showMessage('error', 'Hubo un error', error.message)
                          })
                      }
                    })
                  })
                  .catch((error) => {
                    setCargandoFirma(false)
                    console.log(error)
                    showMessage('error', 'Hubo un error', error.message)
                  })
            } else {
                signDocumentCPD(documentSent._id)
                    .then((response) => {
                    if (response.success) {
                        Swal.close()
                        const link = document.createElement('a')
                        link.href = response.data
                        link.download = 'signed'
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        setCargandoFirma(false)
                        setUpdateData(true)
                        showMessage('success', '¡Buen trabajo!', response.message)
                    } else {
                        console.log(response)
                        showMessage('error', 'Hubo un error', response.error)
                    }
                    })
                    .catch((error) => {
                    console.log(error)
                    showMessage('error', 'Hubo un error', error.message)
                    })
            }
        }
    })
  }

  const showPreview = async (item: any) => {
    if(item){
      setCargandoFile(true)
      setViewDocumento(
        {
            uri:"",
            fileType:"PDF",
            fileName:item.name,
            keyName:item.keyname
        }
      )
      setShowFileModal(true)
      setCargandoFile(false)
    }
    
    /* const result = await handleS3Convert64(item.keyname, quickViewModalData.name)
      if (result) {
        setViewDocumento(result)
        setShowFileModal(true)
        setCargandoFile(false)
      } else {
        setCargandoFile(false)
      } */
    /* if (quickViewModalData.type === "PLANTILLA") {
      const result = await handleS3PlantillaMatch(idUser, quickViewModalData._id, quickViewModalData.idproceso, quickViewModalData.keyname, quickViewModalData.filename)
      if (result) {
        handleDownloadBase64(result.uri, quickViewModalData.filename)
        setCargandoFile(false)
      } else {
        setCargandoFile(false)
      }
    } else {
      const result = await handleS3Convert64(quickViewModalData.keyname, quickViewModalData.filename)
      if (result) {
        setViewDocumento(result)
        setShowFileModal(true)
        setCargandoFile(false)
      } else {
        setCargandoFile(false)
      }
    } */
  }

  const manejarDescarga = (userId: string) => {

    const document = getDocumentByUser(userId)
    if(document)
    handleDownload(document.keyname, document.keyname.split('/')[document.keyname.split('/').length - 1])
  }


  const getStateByUserId = (userId: string) => {
    const document = getDocumentByUser(userId)

    if (document) {

      const color = buttonsData.find(n => n.stateName === document.state)?.color

      return (<span
        style={{
          backgroundColor: color,
          color: 'white',
          padding: '0.2em 0.5em',
          borderRadius: '0.25rem',
          marginRight: '0.5em',
          fontSize: '0.8em',
        }}
      >
        {getFriendlyState(document.state)}
      </span>)
    } else {
      return (<span
        style={{
          backgroundColor: '#f39c12',
          color: 'white',
          padding: '0.2em 0.5em',
          borderRadius: '0.25rem',
          marginRight: '0.5em',
          fontSize: '0.8em',
        }}
      >
        {getFriendlyState('pending')}
      </span>)
    }

  }

  const getFriendlyState = (value: string): string => {
    switch (value) {
      case 'pending':
        return 'Pendiente'
      case 'signed':
        return 'Firmado'
      case 'rejected':
        return 'Rechazado'
      default:
        return value
    }

  }

  return (
    <>
      <div>
        <Container>
          <Row className='mb-5'>
            <Col sm>
              <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-end'>
                  <h5>{quickViewModalData.name}</h5>
                </div>
                <Form.Control as='select' className='w-50'>
                  <option value=''>Todos</option>
                  {buttonsData.map((option) => (
                    <option
                      key={option.iconName}
                      value={option.stateName}
                      style={{ color: option.color }}
                    >
                      {option.title}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </Col>
          </Row>
          {/* <div className={`d-flex align-self-end justify-content-end `} title="Seleccionar mas 1 documento a firmar" ><button type='button'  className='btn btn-primary mb-3' 
                // onClick={() => handleMultipleSignatures()}
                >
                  
              Firmar documentos seleccionados
              </button>
          </div> */}
          <Row className='mb-3'>
            <Col sm>
              <Table bordered size='sm'>
                <thead>
                  <tr>
                    <th className='text-start'>DNI</th>
                    <th className='text-start'>Colaborador</th>
                    <th className='text-start'>Estado</th>
                    {
                      quickViewModalData.authorizationSigned && 
                      <th className='text-start'>Firmar</th>
                    }
                    
                    <th className='text-end'>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((item: any) => (
                    <Fragment key={item._id}>
                      <tr>
                        <td className='text-start fw-bold'>{item.dni}</td>
                        <td className='text-start fw-bold'>{item.nombre}</td>
                        <td className='text-start fw-bold'>
                          {getStateByUserId(item._id)}
                        </td>
                        {
                          quickViewModalData.authorizationSigned && 
                          <td className='text-start'>
                            { getDocumentByUser(item._id)?.isVisualised ? (
                              <button
                                  type='button'
                                  className='btn btn-success mx-8'
                                  onClick={() => handleDocumentSignature(getDocumentByUser(item._id))}
                              >
                                  Firmar documento
                              </button>
                          ) : (
                              <OverlayTrigger
                                  placement="left"
                                  overlay={<Tooltip id="disabled-tooltip">Este botón está desactivado porque el documento no se ha visualizado</Tooltip>}
                              >
                                  <span>
                                      <button
                                          type='button'
                                          className='btn btn-success btn-sm mx-8'
                                          onClick={() => handleDocumentSignature(getDocumentByUser(item._id))}
                                          
                                      >
                                          Firmar documento
                                      </button>
                                  </span>
                              </OverlayTrigger>
                          )
                          }
                        </td>
                        }
                        <td className='text-end'>
                          {
                            getDocumentByUser(item._id)?.state === 'rejected' &&

                            <OverlayTrigger
                              trigger="click"
                              placement="left"
                              overlay={
                                <Popover id="rejected-message-popober">
                                  <Popover.Header as="h3">Motivo de Rechazo</Popover.Header>
                                  <Popover.Body>
                                    {
                                      getDocumentByUser(item._id).rejectionMessage
                                    }
                                  </Popover.Body>
                                </Popover>
                              }>

                              <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                title='Motivo de Rechazo'
                              >
                                <KTIcon iconName='message-text' className='fs-3' />
                              </a>
                            </OverlayTrigger>

                          }
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            title='Visualización'
                            onClick={() => showPreview(getDocumentByUser(item._id))}
                          >
                            <KTIcon iconName='eye' className='fs-3' />
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            title='Descargar'
                            onClick={() => manejarDescarga(item._id)}
                          >
                            <KTIcon iconName='arrow-down' className='fs-3' />
                          </a>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </Table>

              {(cargandoFile || cargandoFirma) && <OverlayLoading />}
              {/* <pre>{JSON.stringify(quickViewModalData, null, 2)}</pre>
              <pre>{JSON.stringify(users, null, 2)}</pre> */}
            </Col>
          </Row>
          <Row>
            <div className='d-flex justify-content-end'>
              <Button
                variant='light'
                className='mx-3'
                type='reset'
                onClick={() => {
                  closeDocumentDetailModal()
                }}
              >
                Cancelar
              </Button>
              {/* <Button variant='primary' type='submit'>
                {!false && 'Guardar'}
                {false && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </Button> */}
            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}

export { DocumentDetailModalForm }
