import {useContext, useEffect} from 'react'

import {SheetContext, SheetEditContext} from '../SheetContexts'
import { SectionEditModalFormWrapper } from './SectionEditModalFormWrapper'
import { SectionEditModalHeader } from './SectionEditModalHeader'

const SectionEditModal = () => {

  const {isSectionModalOpen} = useContext(SheetEditContext)

  return (
    <>
      {isSectionModalOpen && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered mw-400px'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <SectionEditModalHeader />
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <SectionEditModalFormWrapper />
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {SectionEditModal}
