import {useContext, useState,useEffect, FC} from 'react'
import {Droppable, Draggable} from 'react-beautiful-dnd'
import {useAccordionButton} from 'react-bootstrap/AccordionButton'
import {Card, Accordion, Badge} from 'react-bootstrap'
import {title} from 'process'
import Collapsible from 'react-collapsible'
import {KTIcon} from '../../../../../_metronic/helpers'
import {PartnerContext} from '../PartnerContexts'
import { ColaboradorObservacionContext } from '../components/observacion/ColaboradorObservacionContexts'
import {DraggableFieldList} from './DraggableFieldList'
import { Seccion } from '../../../documentacion/MiFicha/FIchaInterface'
import { seccion } from '../../../documentacion/helpers/DataFetching'

const grid = 8

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // Some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // Change background color if dragging
  background: isDragging ? '#18c073' : 'white',
  borderRadius: 10,
  // Styles we need to apply on draggables
  ...draggableStyle,
})

interface MyComponentProps {
  item: Seccion;
  ind:number;
  index:number;
  onUpdate: (updatedData: any) => void;
}

const CollapsibleSectionItem : FC<MyComponentProps> = (props) => {
  let {item,ind,index,onUpdate} = props
  const [formDataSeccion, setFormDataSeccion] = useState(item);

  useEffect(() => {
    setFormDataSeccion(item)
  }, [formDataSeccion])

  const handleUpdateSeccion = (updatedData:Seccion) => {
    /* console.log("Seccion")
    console.log(updatedData) */

    /* const newFormDataSeccion = updatedData
    setFormDataSeccion(newFormDataSeccion) */

    onUpdate(updatedData)
  };

  return (
    <>
      {
        formDataSeccion.campos.filter(cam => cam.visible  && cam.editable).length>0 && !formDataSeccion.dinamico &&   (
        <Draggable draggableId={`Sec`+formDataSeccion._id} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            >
              <div style={{position: 'relative'}}>
                <div {...provided.dragHandleProps} style={{position: 'absolute', top: '0', left: '0'}}>
                  <Badge
                    bg='success-active'
                    className='me-2'

                    style={{transform: 'translateY(-2px)', color: 'white'}}
                  >
                    {index + 1}
                  </Badge>
                </div>
                
                <Collapsible transitionTime={200} easing='ease' trigger={formDataSeccion.nombre} triggerStyle={{marginLeft: 30, fontWeight:'bolder'}}>
                  <div className='mt-6 mx-10'>
                    <DraggableFieldList 
                    key={`FiL`+formDataSeccion._id} 
                    indProcesoFicha={ind} 
                    sectionItem={item}                 
                    onUpdate={(updatedData) => handleUpdateSeccion(updatedData)} 

                    />
                  </div>
                </Collapsible>


                {/* <div style={{position: 'absolute', top: '0', right: '0'}}>
                  <Badge
                    bg='primary-active'
                    className='me-2'
                    style={{transform: 'translateY(-8px)', color: 'white'}}
                  >
                    {item.campos.fields?.length || 0}
                  </Badge>
                </div> */}
              </div>
            </div>
          )}
        </Draggable>
      )

    }
    </>
    
    
  )
}

export {CollapsibleSectionItem}
