import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { KTCard } from '../../../../_metronic/helpers';
import { PageTitle } from '../../../../_metronic/layout/core';
import MenuTree from './MenuTree';
import { PartnerContext } from './PartnerContexts';
import { MenuForm } from './components/MenuForm';
import { Menu } from './components/MenuInterface';

const Breadcrumbs = [
  {
    title: 'Seguridad',
    path: '/seguridad/creacionmenu',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const DataMenu: Menu = {
  _id: '',
  titulo: '',
  url: '',
  icono: '',
  tipo: '',
  order: 0,
  id_padre: '',
  id_hijo: '',
  hijos: []
};

const AddMenuPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [menuData, setMenuData] = useState<Menu>(DataMenu);
  const [edit, setEdit] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);

  return (
    <>
      <PartnerContext.Provider
        value={{
          searchTerm,
          setSearchTerm,
          menuData,
          setMenuData,
          edit,
          setEdit,
          reloadTable,
          setReloadTable,
        }}
      >
        <PageTitle breadcrumbs={Breadcrumbs}>Creación de Menús</PageTitle>
        <Container fluid>
          <Row>
            <Col xs={12} md={4}>
              <KTCard>
                <MenuTree />
              </KTCard>
            </Col>
            <Col xs={12} md={8}>
              <KTCard>
                <MenuForm />
              </KTCard>
            </Col>
          </Row>
        </Container>
      </PartnerContext.Provider>
    </>
  );
};

export { AddMenuPage };

