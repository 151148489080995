import { useContext, useEffect, useState } from 'react'
import { KTCard, KTIcon } from '../../../../../_metronic/helpers'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { SheetContext, SheetEditContext } from '../SheetContexts'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Form, Row, Col, Button, Container, Stack } from 'react-bootstrap'
import { FieldEditModal } from '../field-edit-modal/FieldEditModal'
import { SectionEditModal } from '../section-edit-modal/SectionEditModal'
import { DraggableSectionList } from './DraggableSectionList'
import { Field, Section, Sheet } from '../Sheet'
import {
  createCompleteSheet,
  getCompleteSheet,
  updateCompleteSheet,
} from '../../../../services/fichas'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Swal from 'sweetalert2'
import { showMessage } from '../../../../shared/Alerts'
import { AssignmentEditModal } from '../assignment-edit-modal/AssignmentEditModal'
import { PreviewModal } from '../preview-modal/PreviewModal'
import { GoBackButton } from '../../../generales_component/GoBackButton'

const fichasBreadcrumbs = [
  {
    title: 'Configuración',
    path: '/configuracion/fichas',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SheetEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      setTitle('Editar Ficha')
      handleGetSheet(id)
    }
  }, [])

  const goBack = () => {
    navigate('/configuracion/fichas')
  }

  const [isSectionModalOpen, setSectioModalStatus] = useState(false)
  const [isFieldModalOpen, setFieldModalStatus] = useState(false)
  const [isAssignmentModalOpen, setAssignmentModalStatus] = useState(false)
  const [isPreviewModalOpen, setPreviewModalStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [title, setTitle] = useState('Nueva Ficha')
  const [validated, setValidated] = useState(false);
  const [sheetData, setSheetData] = useState<Sheet>({
    name: '',
    type: 'write',
    description: '',
    sections: [],
  })

  const [sectionData, setSection] = useState<Section>({
    id: '',
    name: '',
    description: '',
    dynamic: false,
    fields: [],
    filter: false
  })

  const [fieldData, setField] = useState<Field>({
    id: '',
    name: '',
    tag: '',
    isDependent: false,
    responseType: '',
    comment: '',
    visible: true,
    editable: false,
    required: false,
    actualizable:false,
    collectionName: '',
    options: [],
    tablaData: [],
    assignedTo: '',
    dependentField: '',
    dependentFieldResponse: '',
    variable: false
  })
  const openSectionModal = () => {
    setSectioModalStatus(true)
  }

  const clearSection = () => {
    setSection({
      id: '',
      name: '',
      description: '',
      dynamic: false,
      filter: false
    })
  }

  const clearField = () => {
    setField({
      id: '',
      name: '',
      tag: '',
      isDependent: false,
      responseType: '',
      comment: '',
      visible: true,
      editable: false,
      required: false,
      actualizable:false,
      collectionName: '',
      options: [],
      tablaData: [],
      assignedTo: '',
      dependentField: '',
      dependentFieldResponse: '',
      variable: false
    })
  }

  const closeSectionModal = () => {
    setSectioModalStatus(false)
    clearSection()
  }

  const openFieldModal = () => {
    setFieldModalStatus(true)
  }

  const closeFieldModal = () => {
    setFieldModalStatus(false)
    clearSection()
    clearField()
  }

  const openAssignmentModal = () => {
    setAssignmentModalStatus(true)
  }

  const closeAssignmentModal = () => {
    setAssignmentModalStatus(false)
    clearSection()
    clearField()
  }

  const openPreviewModal = () => {
    setPreviewModalStatus(true)
  }

  const closePreviewModal = () => {
    setPreviewModalStatus(false)
  }

  const setData = (data: Sheet) => {
    setSheetData(data)
  }

  const setSectionData = (data: Section) => {
    setSection(data)
  }

  const setFieldData = (data: Field) => {
    setField(data)
  }

  const valueContextProvider = {
    isSectionModalOpen,
    openSectionModal,
    closeSectionModal,
    isFieldModalOpen,
    openFieldModal,
    closeFieldModal,
    isAssignmentModalOpen,
    openAssignmentModal,
    closeAssignmentModal,
    sheetData,
    setData,
    sectionData,
    setSectionData,
    fieldData,
    setFieldData,
    isPreviewModalOpen,
    openPreviewModal,
    closePreviewModal
  }

  const handleChange = (e: any) => {
    setSheetData({ ...sheetData, [e.target.name]: e.target.value })
  }

  const validateForm = (e: any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      handleSubmit(e)
    }

    setValidated(true)
  }

  const handleSubmit = (e: any) => {

    e.preventDefault()
    console.log(sheetData)
    setLoading(true)

    if (id) {
      console.log('Actualizando')
      handleUpdateSheet(id, sheetData)
    } else {
      console.log('Creando')
      createCompleteSheet(sheetData)
        .then((response) => {
          if (response.success) {
            console.log(response)
            showMessage('success', '¡Buen trabajo!', response.message)
            goBack()
          } else {
            console.log(response)
            showMessage('error', 'Hubo un error', response.error)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          showMessage('error', 'Hubo un error', error.message)
          setLoading(false)
        })
    }
  }

  const handleGetSheet = (id: string) => {
    console.log(id)
    setFormLoading(true)

    getCompleteSheet(id)
      .then((response) => {
        console.log(response)
        setSheetData(response.data)
        setFormLoading(false)
      })
      .catch((error) => console.log(error))
  }

  const handleUpdateSheet = (id: string, data: Sheet) => {
    updateCompleteSheet(id, data)
      .then((response) => {
        if (response.success) {
          console.log(response)
          showMessage('success', '¡Buen trabajo!', response.message)
          goBack()
        } else {
          console.log(response)
          showMessage('error', 'Hubo un error', response.error)
        }

        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        showMessage('error', 'Hubo un error', error.message)
        setLoading(false)
      })
  }

  return (
    <SheetEditContext.Provider value={valueContextProvider}>
      <PageTitle breadcrumbs={fichasBreadcrumbs}>{title}</PageTitle>
      <GoBackButton goBack={goBack} />
      <KTCard>
        {/* <div className='card-header border-0 pt-6'>
          <div className='card-toolbar'>
            <button type='button' className='btn btn-primary' onClick={() => goBack()}>
              <KTIcon iconName='arrow-left' className='fs-2' />
              Regresar
            </button>
          </div>
        </div> */}
        {formLoading ? (
          <div className='pt-12 pb-6'>
            <Container>
              <Row className='mb-3'>
                <Col sm>
                  <Stack gap={2}>
                    <Skeleton height={20} width={52} duration={1} style={{ borderRadius: '5px' }} />
                    <Skeleton height={44} duration={1} style={{ borderRadius: '10px' }} />
                  </Stack>
                </Col>
                <Col sm>
                  <Stack gap={2}>
                    <Skeleton height={20} width={52} duration={1} style={{ borderRadius: '5px' }} />
                    <Skeleton height={44} duration={1} style={{ borderRadius: '10px' }} />
                  </Stack>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col sm>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-end'>
                      <Skeleton
                        height={20}
                        width={139}
                        duration={1}
                        style={{ borderRadius: '5px' }}
                      />
                    </div>
                    <div className='d-flex'>
                      <Skeleton height={44} width={152} duration={1} style={{ borderRadius: '10px', marginRight: '7px'}} />
                      <Skeleton height={44} width={120} duration={1} style={{ borderRadius: '10px' }} />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className='mb-6'>
                <Col sm>
                  <Skeleton height={195} duration={1} style={{ borderRadius: '10px' }} />
                </Col>
              </Row>

              <Row>
                <div className='d-flex justify-content-end'>
                  <Skeleton
                    height={44}
                    width={102}
                    duration={1}
                    className='mx-3'
                    style={{ borderRadius: '10px' }}
                  />
                  <Skeleton height={44} width={96} duration={1} style={{ borderRadius: '10px' }} />
                </div>
              </Row>
            </Container>
          </div>
        ) : (
          <Form className='pt-12 pb-6' validated={validated} onSubmit={validateForm} noValidate>
            <Container>
              <Row className='mb-3'>
                <Col sm>
                  <Form.Group controlId='formNombre'>
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      type='text'
                      required
                      placeholder='Ingrese un nombre'
                      name='name'
                      value={sheetData.name}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Escriba un nombre para la ficha.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm>
                  <Form.Group controlId='formDescripcion'>
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Ingrese una descripción'
                      name='description'
                      value={sheetData.description}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                {/* <Col sm>
                <Form.Group controlId='formTipo'>
                  <Form.Label>Tipo</Form.Label>
                  <Form.Control
                    as='select'
                    value={sheetData.type}
                    name='type'
                    onChange={handleChange}
                  >
                    <option value='write'>Escritura</option>
                    <option value='upload'>Carga</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}
              </Row>

              {/*<Row className='mb-3'>
              <Col sm>
                <Form.Group controlId='formDescripcion'>
                  <Form.Label>Descripción</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Ingrese una descripción'
                    name='description'
                    value={sheetData.description}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col sm></Col>
            </Row> */}

              <Row className='mb-3'>
                <Col sm>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-end'>
                      <Form.Label>Listado de Secciones</Form.Label>
                    </div>
                    <div>
                      <Button variant='primary' className='me-2' onClick={() => openPreviewModal()}>
                        <KTIcon iconName='eye' className='fs-2' />
                        Previsualizar
                      </Button>

                      <Button variant='success' onClick={() => openSectionModal()}>
                        <KTIcon iconName='plus' className='fs-2' />
                        Sección
                      </Button>
                    </div>
                  </div>
                </Col>
                {/* <Col sm></Col> */}
              </Row>

              <Row className='mb-6'>
                <Col sm>
                  <DraggableSectionList />
                </Col>
                {/* <Col sm></Col> */}
              </Row>

              <Row>
                <div className='d-flex justify-content-end'>
                  <Button
                    variant='light'
                    className='mx-3'
                    type='reset'
                    onClick={() => {
                      goBack()
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button variant='primary' type='submit'>
                    {!loading && 'Guardar'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Guardando...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </Button>
                </div>
              </Row>
            </Container>
          </Form>
        )}
      </KTCard>

      <SectionEditModal />
      <FieldEditModal />
      <AssignmentEditModal />
      <PreviewModal />
    </SheetEditContext.Provider>
  )
}

export { SheetEdit }
