import React, { useContext, useState, useEffect, FC } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { CollapsibleSheetItem } from './CollapsibleSheetItem'
import { Campos, ProcesoFichas, Seccion } from '../../../../../documentacion/MiFicha/FIchaInterface'
import { ProcessSheet, Sheet } from '../../../../documento/documentInterface'
import { PartnerContext } from '../../../PartnerContexts'

const grid = 3

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#c7fff0' : '#EFEFEF',
  padding: grid,
  width: '100%',
  borderRadius: 10,
})

interface FichaAux {label: string, value: string}

interface MyComponentProps {
  procesoficha: FichaAux[];
  ind: number;
}



const DraggableSheetList: FC<MyComponentProps> = (props) => {
  const {updateFieldMigration,setUpdateFieldMigration} = useContext(PartnerContext)
  const { procesoficha, ind } = props
  const [loading, setLoading] = useState(true)
  const [formDataFicha, setFormDataFicha] = useState(procesoficha)

  useEffect(() => {
    setFormDataFicha(procesoficha)
    setLoading(false)
  }, [procesoficha])

  useEffect(() => {
    HandleUpdateFieldMigration()
  }, [formDataFicha])
  
  const HandleUpdateFieldMigration = () => {
    if(formDataFicha.length>0){
      const fichaNew:any = []
      formDataFicha.map(datFicha => {
        fichaNew.push({id_ficha:datFicha.value,name:datFicha.label,seccion:[]})
      })      
      setUpdateFieldMigration({...updateFieldMigration,fichas:fichaNew})
    }
  };
  
  const handleUpdateSheet = (ind:number,updatedData:Sheet) => {
    let indSeccion = 0
    //AQUI HARE MI LOGICA;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {procesoficha.length === 0 &&  loading? (
        <div>Loading...</div>
      ) : (
        <Droppable droppableId={`droppable` + ind}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {(formDataFicha || []).map((item: FichaAux , index) => (
                <CollapsibleSheetItem
                  key={item.value}
                  ind={ind}
                  item={item}
                  index={index}
                  onUpdate={(updatedData) => handleUpdateSheet(index, updatedData)}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </DragDropContext>
  )
}

export { DraggableSheetList }
