import React, { useState } from 'react'
import { KTCard } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import { PartnerContext, Unidad } from './PartnerContexts'
import { PartnersListHeader } from './components/PartnersListHeader'
import { PartnersTable } from './components/PartnersTable'
import { Field, Proceso, ProcessFiles, ProcessSheet, Section, Sheet } from './documentInterface'
import { TagsEditModal } from './show-search-tag-modal/TagsEditModal'

const fichasBreadcrumbs = [
  {
    title: 'Configuración',
    path: '/seguimiento/documentos',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DocumentoClientePage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchDocument, setSearchDocument] = useState('')
  const [process_id, setProcessId] = useState('')
  const [procesoData, setProcesoData] = useState<Proceso>({
    _id: '',
    nombre: '',
    codigo: '',
    descripcion: '',
    estado: '',
    codUnid: '',
  })
  const [isTagsModalOpen, setTagsModalStatus] = useState(false)
  const [isEditFileModalOpen, setIsEditFileModalOpen] = useState(false)
  const [updateFile, setUpdateFile] = useState(false)
  const [processSheetData, setProcessSheetData] = useState<ProcessSheet[]>([])
  const [sheetData, setSheetData] = useState<Sheet[]>([])
  const [sectionData, setSectionData] = useState<Section[]>([])
  const [fieldData, setFieldData] = useState<Field[]>([])
  const [fileData, setFileData] = useState<ProcessFiles>({
    _id: '',
    idproceso: '',
    name: '',
    file: '',
    filename: '',
    keyname: '',
    number_aprobacion:0 ,
    type: '',
    type_document: '',
    codUnid: '',
    detalle_niveles:[] 
  })
  const [documents, setDocuments] = useState<{}>({})
  const [documentsAux, setDocumentsAux] = useState<{}>({})
  const [unidades, setUnidades] = useState<Unidad[]>([])
  const [searchUnidad, setSearchUnidad] = useState<string[]>([])

  const openTagsModal = () => {
    setTagsModalStatus(true)
  }

  const closeTagsModal = () => {
    setTagsModalStatus(false)
  }

  const openEditFileModal = () => {
    setIsEditFileModalOpen(true)
  }

  const closeEditFileModal = () => {
    setIsEditFileModalOpen(false)
  }

  const valueContext = {
    searchTerm,
    setSearchTerm,
    searchDocument,
    setSearchDocument,
    process_id,
    setProcessId,
    procesoData,
    setProcesoData,
    processSheetData,
    setProcessSheetData,
    sheetData,
    setSheetData,
    sectionData,
    setSectionData,
    fieldData,
    setFieldData,
    isTagsModalOpen,
    openTagsModal,
    closeTagsModal,
    fileData,
    setFileData,
    isEditFileModalOpen,
    openEditFileModal,
    closeEditFileModal,
    updateFile,
    setUpdateFile,
    documents,
    setDocuments,
    documentsAux,
    setDocumentsAux,
    unidades,
    setUnidades,
    searchUnidad,
    setSearchUnidad,
  }

  return (
    <PartnerContext.Provider value={valueContext}>
      <PageTitle breadcrumbs={fichasBreadcrumbs}>Documentos por unidad</PageTitle>
      <KTCard>
        <PartnersListHeader />
        <PartnersTable />
      </KTCard>
      <TagsEditModal />
    </PartnerContext.Provider>
  )
}

export { DocumentoClientePage }

