import ApiClass from "../config/ApiClass";

// const fetchSinToken = (endpoint, data = {}, metodo = "GET") => {
//   const apiclass = new ApiClass();

//   const url = `${apiclass.base_url_back}/${endpoint}`;

//   if (metodo === "GET") {
//     return fetch(url, {
//       method: metodo,
//       mode: "cors",
//       headers: { "Content-Type": "application/json" },
//     });
//   } else {
//       return fetch(url, {
//         method: metodo,
//         mode: "cors",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(data),
//       });
//   }
// };

const fetchSinToken = (endpoint, data = {}, metodo = "GET") => {
  const apiclass = new ApiClass();

  const url = `${apiclass.base_url_back}/${endpoint}`;

  const companyId = localStorage.getItem('selectedCompanyId');
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const headers = {
    "Content-Type": "application/json",
    'Client-Timezone': clientTimeZone,
  };

  if (companyId) {
    headers['Company-ID'] = companyId;
  }

  const requestOptions = {
    method: metodo,
    mode: "cors",
    headers: headers,
  };

  if (metodo !== "GET") {
    requestOptions.body = JSON.stringify(data);
  }

  return fetch(url, requestOptions);
};

const fetchConToken = (endpoint, data, metodo = "GET",token='') => {
  
  const apiclass = new ApiClass();
  const url = `${apiclass.base_url_back}/${endpoint}`;

  const userData = JSON.parse(localStorage.getItem('userData'));
  const companyId = localStorage.getItem('selectedCompanyId');
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  let newtoken = ''
  if(token === ''){
    userData?.access_token?newtoken=userData.access_token:token=''
  }else{
    newtoken = token
  }
  
  const headers = {
    "Content-Type": "application/json",
    'Client-Timezone': clientTimeZone,
    "Authorization": `Bearer ${newtoken}`
  };

  if (companyId) {
    headers['Company-ID'] = companyId;
  }

  if (metodo === "GET") {
    return fetch(url, {
      method: metodo,
      mode: "cors",
      headers: headers
    });
  } else {
    return fetch(url, {
      method: metodo,
      mode: "cors",
      headers: headers,
      body: JSON.stringify(data),
    });
  }
};



const fetchSinTokenStream = (endpoint, data = {}, metodo = "GET") => {
  const apiclass = new ApiClass();

  const url = `${apiclass.base_url_back}/${endpoint}`;

  if (metodo === "GET") {
    return fetch(url, {
      method: metodo,
      mode: "cors",
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } else {
    return fetch(url, {
      method: metodo,
      mode: "cors",
      // headers: { 'Content-Type': 'application/octet-stream' },
      body: data,
    });
  }
};


export { fetchConToken, fetchSinToken, fetchSinTokenStream };
