import { useContext, useEffect, useState } from 'react';
import { Button, Collapse, ListGroup, Spinner } from 'react-bootstrap';
import { ChevronDown, ChevronRight } from 'react-bootstrap-icons';
import styled from 'styled-components';
import { KTIcon } from '../../../../_metronic/helpers';
import { fetchMenuData } from '../../../services/menu';
import { PartnerContext } from './PartnerContexts';

const MenuTree = () => {
  const { setMenuData, setEdit } = useContext(PartnerContext);
  const [menuData, setMenuDataState] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetchMenuData();
        if (response.success) {
          setMenuDataState(response.data);
        } else {
          setError(response.message);
        }
      } catch (error) {
        setError('Error fetching menu data');
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const toggleItem = (id: string) => {
    setOpenItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleMenuClick = (menu: any) => {
    setMenuData(menu);
    setEdit(true);
  };

  const renderMenu = (menu: any, level: number = 1) => (
    <StyledListGroupItem key={menu.id_hijo || menu._id} level={level}>
      <Button
        variant="link"
        onClick={() => toggleItem(menu.id_hijo || menu._id)}
        className="d-flex align-items-center"
      >
        {menu.hijos && menu.hijos.length > 0 && (
          openItems[menu.id_hijo || menu._id] ? <ChevronDown /> : <ChevronRight />
        )}
        <span className="ml-2" onClick={() => handleMenuClick(menu)}>
          <KTIcon iconName={menu.icono} className='fs-2' /> {menu.titulo}
        </span>
      </Button>
      {menu.hijos && menu.hijos.length > 0 && (
        <Collapse in={openItems[menu.id_hijo || menu._id]}>
          <div>
            <StyledListGroup>
              {menu.hijos.map((child: any) => renderMenu(child, level + 1))}
            </StyledListGroup>
          </div>
        </Collapse>
      )}
    </StyledListGroupItem>
  );

  if (loading) return <div className="d-flex justify-content-center mt-3"><Spinner animation="border" /></div>;
  if (error) return <div className="text-danger text-center mt-3">{error}</div>;

  return (
    <StyledListGroup>
      {menuData.map((menu) => renderMenu(menu))}
    </StyledListGroup>
  );
};

const getColorByLevel = (level: number) => {
  switch (level) {
    case 1:
      return '#007bff'; 
    case 2:
      return '#28a745'; 
    case 3:
      return '#dc3545'; 
    default:
      return '#6c757d';
  }
};

const StyledListGroup = styled(ListGroup)`
  .list-group-item {
    border: none;
  }
`;

const StyledListGroupItem = styled(ListGroup.Item)<{ level: number }>`
  .btn-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: ${({ level }) => getColorByLevel(level)};
    &:hover {
      color: darken(${({ level }) => getColorByLevel(level)}, 10%);
    }
  }
  margin-left: ${({ level }) => level * 10}px;
`;

export default MenuTree;
