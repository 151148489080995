import { createContext } from "react";

interface DocumentTrayContextProps {
    isPreviewDocumentModalOpen: boolean,
    openPreviewDocumentModal: () => void,
    closePreviewDocumentModal: () => void,
    keyname: string,
    setKeyname: (value: string) => void,
    searchTerm: string,
    setSearchTerm: (value: string) => void,
    fromPreviewTab: {
        showSignature: boolean,
        signCeo: boolean,
        idUserSign: string,
    },
    setFromPreviewTab: (value: {
        showSignature: boolean,
        signCeo: boolean,
        idUserSign: string,
    }) => void,
}

const DocumentTrayContext = createContext<DocumentTrayContextProps>({
    isPreviewDocumentModalOpen: false,
    openPreviewDocumentModal: () => {},
    closePreviewDocumentModal: () => {},
    keyname: '',
    setKeyname: () => {},
    searchTerm: '',
    setSearchTerm: (value) => {},
    fromPreviewTab: {
        showSignature: false,
        signCeo: false,
        idUserSign: '',
    },
    setFromPreviewTab: () => {},
})

export { DocumentTrayContext }