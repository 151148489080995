import {useContext} from 'react'
import { PartnerEditContext } from '../PartnerEditContext'
import { DetailAprobacionModalHeader } from './DetailAprobacionModalHeader'
import { DetailAprobacionModalFormWrapper } from './DetailAprobacionModalFormWrapper'

const DetailAprobacionModal = () => {

  const {showDetailAprobacion} = useContext(PartnerEditContext)

  return (
    <>
      {showDetailAprobacion && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered modal-xl'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <DetailAprobacionModalHeader />
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <DetailAprobacionModalFormWrapper />
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {DetailAprobacionModal}
