import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './styles.css';
import { KTSVG } from '../../../../../_metronic/helpers';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ModalComponent from './modalPreview';

interface DropzoneExampleProps {
    onFirmaSelected: (Image:string, index: number) => void;
    shouldClearFirma: boolean;
    onClearFirma: () => void;
    idx: string;
}

const DropzoneImagenUnico: React.FC<DropzoneExampleProps> = ({ onFirmaSelected, shouldClearFirma, onClearFirma,idx }) => {
    const [file, setFile] = useState<File | null>(null);

    const onDrop = async (acceptedFiles: File[]) => {
        // Update the state with the accepted file (only the first file will be considered)
        setFile(acceptedFiles[0] || null);
        const base64 = await fileToBase64(acceptedFiles[0]);
        onFirmaSelected(String(base64), 0);
    };

    const removeFile = () => {
        setFile(null);
        onFirmaSelected("", 0);
        onClearFirma();
    };

    React.useEffect(() => {
        if (shouldClearFirma) {
            removeFile();
        }
    }, [shouldClearFirma]);

    const fileToBase64 = (file:any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
        
            reader.onloadend = () => {
                resolve(reader.result);
            };
        
            reader.onerror = () => {
                reject(new Error('Error al leer el archivo.'));
            };
        
            reader.readAsDataURL(file);
        });
    };

    const [fileUrl, setFileUrl] = useState<string | null>(null);

    const handlePreviewFile = (file: File) => {
        const blob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(blob);
        setFileUrl(url);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"],
        },
        maxFiles: 1, // Limitar a una sola imagen
    });

    return (
        <div>
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
            <input {...getInputProps()} />
            {isDragActive ? <p>Arrastra y suelta la imagen aquí...</p> : <p>Arrastra la imagen aquí o haz clic para seleccionar</p>}
        </div>
        <div className="dropzone-preview-container">
            {file ? (
            <div key={file.name} className="dropzone-preview">
                {file.type.startsWith('image/') ? (
                <>
                    <img src={URL.createObjectURL(file)} alt={file.name} />
                    <span className="badge badge-square badge-danger remove-btn" onClick={removeFile}>
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-1x"
                    />
                    </span>
                    <button
                    className="btn btn-icon btn-light pulse pulse-info me-10 mb-10 preview-btn btn-sm"
                    onClick={() => handlePreviewFile(file)}
                    data-bs-toggle="modal"
                    data-bs-target={`#kt_modal_2`+idx}
                    >
                    <KTSVG
                        path="/media/icons/duotune/general/gen004.svg"
                        className="svg-icon svg-icon-1"
                    />
                    <span className="pulse-ring"></span>
                    </button>
                </>
                ) : (
                <div className="file-info">
                    <p>{file.name}</p>
                    <p>Peso: {(file.size / 1024).toFixed(2)} KB</p>
                    <span className="badge badge-square badge-danger remove-btn" onClick={removeFile}>
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-1x"
                    />
                    </span>
                    <button
                    className="btn btn-icon btn-light pulse pulse-info me-10 mb-10 preview-btn btn-sm"
                    onClick={() => handlePreviewFile(file)}
                    data-bs-toggle="modal"
                    data-bs-target={`#kt_modal_2`+idx}
                    >
                    <KTSVG
                        path="/media/icons/duotune/general/gen004.svg"
                        className="svg-icon svg-icon-1"
                    />
                    <span className="pulse-ring"></span>
                    </button>
                </div>
                )}
            </div>
            ) : (
            <p>No se ha seleccionado ninguna imagen.</p>
            )}
        </div>

        <ModalComponent id={`kt_modal_2`+idx} title="PreVisualizacion">
            {fileUrl && (
            <DocViewer
                documents={[{ uri: fileUrl }]}
                pluginRenderers={DocViewerRenderers}
            />
            )}
        </ModalComponent>
        </div>
    );
};

export default DropzoneImagenUnico;
