import {useContext} from 'react'
import { DocumentTrayContext } from '../DocumentTrayContexts'
import { PreviewDocumentModalHeader } from './PreviewDocumentModalHeader'
import { PreviewDocumentModalFormWrapper } from './PreviewDocumentModalFormWrapper'

const PreviewDocumentModal = () => {
  const {isPreviewDocumentModalOpen} = useContext(DocumentTrayContext)
  return (
    <>
      {isPreviewDocumentModalOpen && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              <div className='modal-dialog modal-xl modal-dialog-centered mw-800px'>
                <div className='modal-content'>
                  <PreviewDocumentModalHeader />
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <PreviewDocumentModalFormWrapper />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  )
}

export {PreviewDocumentModal}
