import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { Stack } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import { KTCardBody, KTIcon } from '../../../../../_metronic/helpers'
import { formatISODateToDMYHM } from '../../../../helpers/dateFunctions'
import { deletePartner, updatePartner } from '../../../../services/colaboradores'
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from '../../../../services/menu'
import { changeStatusFicha } from '../../../../services/registroFicha'
import { activarUser, deleteUser } from '../../../../services/users'
import { addWSeleccionId, getAllWSeleccion } from '../../../../services/wseleccion'
import { showDeleteMessage, showMessage, showReactivationMessage, showValidatePassword } from '../../../../shared/Alerts'
import Pagination from '../../../../shared/Pagination'
import { UsersListLoading } from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import { useQueryResponseData } from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import { usersColumns } from '../../../apps/user-management/users-list/table/columns/_columns'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import { PartnerContext } from '../PartnerContexts'

const SolicitudesTable = () => {
  const users = useQueryResponseData()
  const {selectedCompanyId} = useAuth2()
  // const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const [isLoading, setLoadingStatus] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [fetchStatus, setFechStatus] = useState('')
  const [fetchMessage, setFetchMessage] = useState('')

  const [lista, setLista] = useState([])
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  // const {searchTerm} = useContext(SheetContext)
  const {
    activeOnly,
    state,
    searchTerm,
    entryDateFrom,
    entryDateTo,
    updateUser,
    setUpdateUser,
    setInfoData,
    setIsInfoModalOpen
  } = useContext(PartnerContext)

  const navigate = useNavigate()

  const iconsData = [
    {
      iconName: 'verify',
      title: 'Borrador',
      stateName: 'borrador',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Completado',
      stateName: 'completado',
      color: '#2ecc71', // Green
    },
  ]
  const { selectedRole } = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])
  const location = useLocation()

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu = response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])
  useEffect(() => {
    setLoadingStatus(true)
    refetchData({
      activeOnly: activeOnly,
      sheetStates: state,
      searchQuery: searchTerm,
      entryDateFrom: entryDateFrom,
      entryDateTo: entryDateTo,
    })
  }, [])

  useEffect(() => {
    setLoadingStatus(true)
    refetchData({
      activeOnly: activeOnly,
      sheetStates: state,
      searchQuery: searchTerm,
    })
  }, [searchTerm])

  useEffect(() => {
    if (updateUser) {
      setUpdateUser(false)
      setLoadingStatus(true)
      refetchData({
        activeOnly: activeOnly,
        sheetStates: state,
        searchQuery: searchTerm,
      })
    }
  }, [updateUser])

  useEffect(() => {
    setLoadingStatus(true)
    refetchData({
      activeOnly: activeOnly,
      sheetStates: state,
      searchQuery: searchTerm,
      entryDateFrom: entryDateFrom,
      entryDateTo: entryDateTo,
    })
  }, [activeOnly, state, entryDateFrom, entryDateTo, currentPage])

  const refetchData = (options: any) => {
    setLoadingStatus(true)
    getAllWSeleccion(currentPage, 10, options)
      .then((response) => {
        if (response.success) {
          setLoadingStatus(false)
          const auxTotalPage = response.length ? Math.ceil(response.length / 10) : totalPages
          if(currentPage>auxTotalPage)setCurrentPage(1)
          setTotalPages(auxTotalPage)
          setLista(response?.data)
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleObservar = (id: any, namecolaborador: any) => {
    navigate(`/seguimiento/colaborador/observacion/${id}/${namecolaborador}`)
  }

  const handleValidation = (id: any, namecolaborador: any) => {
    navigate(`/configuracion/validar/actions/${id}/${namecolaborador}`)
  }

  const changePartnerStatus = (id: string, activeState: boolean) => {
    if (activeState === false) {
      showDeleteMessage().then((result) => {
        if (result.isConfirmed) {
          deletePartner(id)
            .then((response) => {
              if (response.success) {
                showMessage('success', '¡Buen trabajo!', response.message)
              } else {
                showMessage('error', 'Hubo un error', response.error)
              }
              refetchData({
                activeOnly: activeOnly,
                sheetStates: state,
                searchQuery: searchTerm,
                entryDateFrom: entryDateFrom,
                entryDateTo: entryDateTo,
              })
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    } else {
      updatePartner(id, {estado: true})
        .then((response) => {
          refetchData({
            activeOnly: activeOnly,
            sheetStates: state,
            searchQuery: searchTerm,
            entryDateFrom: entryDateFrom,
            entryDateTo: entryDateTo,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  // Utility function to generate pagination links based on the current page and totalPages values
  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    // Generate "Previous" link if not on the first page
    if (currentPage > 1) {
      links.push({page: currentPage - 1, label: 'Anterior'})
    }

    // Generate numbered pages
    for (let i = 1; i <= totalPages; i++) {
      links.push({page: i, label: String(i)})
    }

    // Generate "Next" link if not on the last page
    if (currentPage < totalPages) {
      links.push({page: currentPage + 1, label: 'Siguiente'})
    }

    return links
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleDelete = (id: string) => {
    showDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        deleteUser(id)
          .then((response) => {
            if (response.success) {
              console.log(response)
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              console.log(response)
              showMessage('error', 'Hubo un error', response.error)
            }
            refetchData({
              activeOnly: activeOnly,
              searchQuery: searchTerm,
            })
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message))
      }
    })
  }

  const handleReactivation = (id: string) => {
    showReactivationMessage().then((result) => {
      if (result.isConfirmed) {
        activarUser(id)
          .then((response) => {
            if (response.success) {
              console.log(response)
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              console.log(response)
              showMessage('error', 'Hubo un error', response.error)
            }
            refetchData({
              activeOnly: activeOnly,
              searchQuery: searchTerm,
            })
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message))
      }
    })
  }

  const changeStatusRegistroFicha = (id: string, estado: string) => {
    changeStatusFicha(id, {estado})
      .then((response) => {
        if (response.success) {
          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          showMessage('error', 'Hubo un error', response.error)
        }
        refetchData({
          activeOnly: activeOnly,
          sheetStates: state,
          searchQuery: searchTerm,
          entryDateFrom: entryDateFrom,
          entryDateTo: entryDateTo,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleShowDetalle = (show:boolean,userData: any) => {
    setInfoData(userData)
    setIsInfoModalOpen(show)
  }

  const handleSendMultipleWSeleccion = () => {
    showValidatePassword().then(async (result) => { 
      setLoadingStatus(true)
      if (result.isConfirmed) {
        await addWSeleccionId({ids_wseleccion:selectedItems,password:result.value})
        .then((response) => {
          if (response.success) {
            showMessage("success","Registro Correctamente",response.message)
          }else{
            showMessage("error","Hubo un Error",response.message)
          }
          refetchData({
            activeOnly: activeOnly,
            sheetStates: state,
            searchQuery: searchTerm,
            entryDateFrom: entryDateFrom,
            entryDateTo: entryDateTo,
          })
          setLoadingStatus(false)
        })
        .catch((error) => {
            console.log(error)
            setLoadingStatus(false)
        }) 
      }else{
        setLoadingStatus(false)
      }
    })
    .catch((error) => {
      console.log(error)
      setLoadingStatus(false)
    })
  }

  const handleSendWSeleccion = (id:string) => {
    showValidatePassword().then(async (result) => { 
      setLoadingStatus(true)
      if (result.isConfirmed) {
        await addWSeleccionId({ids_wseleccion:[id],password:result.value})
        .then((response) => {
          if (response.success) {
            showMessage("success","Registro Correctamente",response.message)
          }else{            
            showMessage("error","Hubo un Error",response.message)
          }
          refetchData({
            activeOnly: activeOnly,
            sheetStates: state,
            searchQuery: searchTerm,
            entryDateFrom: entryDateFrom,
            entryDateTo: entryDateTo,
          })
          setLoadingStatus(false)
        })
        .catch((error) => {
            console.log(error)
            setLoadingStatus(false)
        }) 
      }else{        
        setLoadingStatus(false)
      }
    })
    .catch((error) => {
      console.log(error)
      setLoadingStatus(false)
    })
  }


  const handleToggle = (id: string) => {
    const currentIndex = selectedItems.indexOf(id)
    const newSelected = [...selectedItems]

    if (currentIndex === -1) {
        newSelected.push(id);
    } else {
        newSelected.splice(currentIndex, 1);
    }

    setSelectedItems(newSelected)
  };

  const handleMainToggle = () => {
    if (selectedItems.length === lista.length) {
        setSelectedItems([]);
    } else {
      console.log(lista)
        setSelectedItems(lista.map((item: any) => item._id))
    }
  }

  // Custom Toggle Component
  const CustomToggle = React.forwardRef(({onClick}: any, ref: any) => (
    <a
      ref={ref}
      href='#'
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
      title='Visualizar ficha'
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <KTIcon iconName='dots-square-vertical' className='fs-3' />
    </a>
  ))

  return (
    <>
      <KTCardBody className='py-4'>
        {
          <div className={`d-flex align-self-end justify-content-end `} title="Seleccionar mas 1 documento a firmar" >
         {permissions.includes('Aprobar Colaboradores') && (
            <button type='button' disabled={(selectedItems.length > 1 || !isLoading)?false:true}  className='btn btn-primary mb-3' onClick={() => handleSendMultipleWSeleccion()}>
              Aprobar Colaboradores
          </button>)}
          </div>
        }
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th className='text-start'>
                  {
                      lista.length > 0 && 
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                              className='form-check-input'
                              type='checkbox'
                              id={`checkbox-documents-report-all`}
                              checked={selectedItems.length === lista.length}
                              onChange={handleMainToggle}
                          />
                      </div>
                  }
                </th>
                <th>ID Requerimiento</th>
                <th className='text-start'>Unidad</th>
                <th className='text-start'>Proceso</th>
                <th className='text-start'>Num Documento</th>
                <th className='text-start'>Fecha Creacion</th>
                <th className='text-end'>Opciones</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {lista.length > 0 ? (
                lista?.map((item: any) => (
                  <Fragment key={item._id}>
                    <tr>
                      <th className='w-25px'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id={`checkbox-document-tray-${item._id}`}
                                checked={selectedItems.includes(item._id)}
                                onChange={(e) => handleToggle(item._id)}
                            />
                        </div>
                      </th>
                      <td>{item.idRequerimiento}</td>
                      <td className='text-start min-w-100px'>{item.cod_unidad}</td>
                      <td className='text-start min-w-100px'>{item.idProceso}</td>
                      <td className='text-start min-w-100px'>{item.numeroDocumento}</td>
                      <td className='text-start min-w-100px'>{ formatISODateToDMYHM(item.createdAt) }</td>
                      <td className='text-end d-flex align-self-end justify-content-end'>
                        <Stack direction='horizontal' gap={1}>
                          {item.estado === "PENDIENTE" ? (
                            <>
                         {permissions.includes('Ver Detalle') && (
                              <a
                                href='#up'
                                className='btn btn-icon btn-bg-light btn-light-info btn-sm'
                                title='Ver Detalle'
                                onClick={() => handleShowDetalle(true,item.info)}
                              >
                                <KTIcon iconName='eye' className='fs-3' />
                              </a>
                         )}
                         {permissions.includes('Aprobar') && (
                              <a
                                href='#rea'
                                className='btn btn-icon btn-bg-light btn-light-success btn-sm'
                                title='Aprobar'
                                onClick={() => handleSendWSeleccion(item._id)}
                              >
                                <KTIcon iconName='check' className='fs-3' />
                              </a>
                         )}
                            </>
                          ):
                          
                          <>
                                {permissions.includes('Ver Detalle') && (
                            <a
                                href='#up'
                                className='btn btn-icon btn-bg-light btn-light-info btn-sm'
                                title='Ver Detalle'
                                onClick={() => handleShowDetalle(true,item.info)}
                              >
                                <KTIcon iconName='eye' className='fs-3' />
                              </a>
                                )}
                          </>
                          }
                          
                        </Stack>
                      </td>
                    </tr>
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export { SolicitudesTable }

