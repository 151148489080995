import { useMemo, useState, useContext, useEffect, Fragment } from 'react'
import { Badge, Dropdown, Form, Stack } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import { KTCardBody, KTIcon } from '../../../../../_metronic/helpers'
import {
  getSheets,
  deleteCompleteSheet,
  cloneCompleteSheet,
  ficha,
  busquedaficha,
  generatePDF,
} from '../../../../services/fichas'
import {
  showDeleteMessage,
  showMessage,
  showCloneMessage,
  showCancellationMessage,
  showReactivationMessage,
} from '../../../../shared/Alerts'
import { UsersListLoading } from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import { useQueryResponseData } from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import { usersColumns } from '../../../apps/user-management/users-list/table/columns/_columns'
import { SheetContext } from '../../fichas/SheetContexts'
import { ListaSecciones } from '../../usuario/pages/secciones/ListaSecciones'
import Pagination from '../../../../shared/Pagination'
import { deletePartner, getAllPartners, getAllPartnersPag, updatePartner } from '../../../../services/colaboradores'
import { boolean } from 'yup'
import { formatDateToDMY } from '../../../../helpers/dateFunctions'
import { PartnerContext } from '../PartnerContexts'
import React from 'react'
import { changeStatusFicha } from '../../../../services/registroFicha'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import { deleteDocument, downloadAsZip, getDocuments, getDocumentsByIdUsuarios } from '../../../../services/documentos'
import { ListDocuments } from './ListDocuments'
import { DataUser } from '../../../documentacion/MiFicha/DataUserInterface'
import { getProceso } from '../../../../services/procesos'

const LegajoDocumentsTable = () => {
  const users = useQueryResponseData()
  // const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  
  const usuarioJSON = localStorage.getItem('userData');
  let usuario: DataUser|null=null;
  
  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON);
  }

  const [isLoading, setLoadingStatus] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [unidaddata, setUnidadData] = useState<any[]>([])
  const [procesosdata, setProcesosData] = useState<any[]>([])

  const [lista, setLista] = useState([])
  const [selecto, setSelecto] = useState<any>(0)
  const [show, setShow] = useState(false)
  const [meliminar, setMeliminar] = useState(false)
  const [eliminar, setEliminar] = useState('')
  const [mactualizar, setMactualizar] = useState(false)
  const [actualizar, setActualizar] = useState('')
  const [mnuevo, setMnuevo] = useState(false)
  const [nuevo, setNuevo] = useState('')

  // const {searchTerm} = useContext(SheetContext)
  const { searchTerm, unidadSelected,selectIdProceso,documents,setTree,setCheck, processSelected ,entryDateFrom,setDocumentsAux,setDocuments,
    entryDateTo,listUnidadAll,setListUnidad,setListUnidadProcess,listProcessAll, setSelectIdUnidad, setSelectIdProceso,setListProcess,setListProcessAll,listUnidad,listProcess, searchSend, setSearchSend, selectIdUnidad } =
    useContext(PartnerContext)

  const navigate = useNavigate()

  const iconsData = [
    {
      iconName: 'verify',
      title: 'Borrador',
      stateName: 'borrador',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Completado',
      stateName: 'completado',
      color: '#2ecc71', // Green
    }
  ]

  useEffect(() => {
    setLoadingStatus(true);
    setTimeout(() => {
      refetchDataProcess({
        estado: "A",
        responsables: usuario?.userId
      })
    }, 1000)
  }, [currentPage])

  useEffect(() => {
    if(searchSend){
      setSearchSend(false)
      setLoadingStatus(true);
      refetchDataProcess({
        estado: "A",
        responsables: usuario?.userId
      })
      setSelecto(procesosdata[0]?.["_id"])
      setSelectIdProceso(procesosdata[0]?.["_id"])
    }
  }, [searchSend])

  useEffect(() => {
    if(searchSend){
      setSearchSend(false)
      refetchData()
    }
  }, [searchSend])

  useEffect(() => {  
    setProcesosData(listProcess)
  }, [listProcess])

  useEffect(() => {
    setUnidadData(listUnidad)
  }, [listUnidad])

  const refetchDataProcess = (options: any) => {
    setLoadingStatus(true);
    //options= searchTerm?options:{}
    getProceso(currentPage,100,options).then((response) => {
      if (response.success) {
        setLoadingStatus(false);
        const auxTotalPage = response.length? Math.ceil(response.length/10): totalPages
        setTotalPages(auxTotalPage)
        setListProcessAll(response.data)
        MenuComponent.reinitialization()
      } else {
        console.log(response.msg)
      }
    })
    /* getProceso(1,50,options).then((response) => {
      if (response.success) {
      } else {
        console.log(response.msg)
      }
    }) */
    setEliminar('')
  }

  const refetchData = () => {
    if((entryDateFrom !== '' && entryDateTo !== '') || searchTerm.length > 3 || searchTerm.length > 3){
      const option = {
        fec_ini:entryDateFrom,
        fec_fin:entryDateTo,
        id_proceso:processSelected,
        cod_unidad:unidadSelected,
        user_dni_nombre:searchTerm
      }
      
      const unidadAux:any[] = []
      const processAux:any[] = []
      getDocumentsByIdUsuarios(option)
        .then((response) => {
          if (response.success) {
            const unidadProcessAux:{[unidad:string]:{[process:string]:{[año: string]: { [mes: string]:{ [user: string]: any[] }}}}} = {}
            const newObjectArbol:{[key:string]:boolean} = {};
            const newObjectCheck:{[key:string]:boolean}  = {};
            Object.keys(response?.data).map(key => {
              Object.keys(response?.data[key]).map((key1,ind0) => {
                Object.keys(response?.data[key][key1]).map((key_unidad,ind) => {                   
                  if(!unidadProcessAux[key_unidad]) unidadProcessAux[key_unidad] = {}
                  newObjectArbol[key_unidad] = false; 
                  newObjectCheck[key_unidad] = false;
                  
                  listUnidadAll.map(undAux => {
                    if(String(key_unidad).toUpperCase() === String(undAux.DesUnid).toUpperCase()){
                      unidadAux.push(undAux)
                    }
                  })         
                  Object.keys(response?.data[key][key1][key_unidad]).map((key_idproceso,ind) => {
                    listProcessAll.map(procAux => {
                      if(String(key_idproceso) === String(procAux._id)){
                        processAux.push(procAux)
                      }
                    })
                    if(!unidadProcessAux[key_unidad][key_idproceso]) unidadProcessAux[key_unidad][key_idproceso] = {}                  
                    if(!unidadProcessAux[key_unidad][key_idproceso][key]) unidadProcessAux[key_unidad][key_idproceso][key] = {}
                    if(!unidadProcessAux[key_unidad][key_idproceso][key][key1]) unidadProcessAux[key_unidad][key_idproceso][key][key1] = {}
                    newObjectArbol[`${key_unidad}_${key_idproceso}`] = false; 
                    newObjectCheck[`${key_unidad}_${key_idproceso}`] = false;
                    newObjectArbol[`${key_unidad}_${key_idproceso}_${key}_${key1}`] = false; 
                    newObjectCheck[`${key_unidad}_${key_idproceso}_${key}_${key1}`] = false;

                    Object.keys(response?.data[key][key1][key_unidad][key_idproceso]).map((key2,ind1) => {
                      if(!unidadProcessAux[key_unidad][key_idproceso][key][key1][key2]) unidadProcessAux[key_unidad][key_idproceso][key][key1][key2] = []

                      newObjectArbol[`${key_unidad}_${key_idproceso}_${key}_${key1}_${key2}`] = false;
                      newObjectCheck[`${key_unidad}_${key_idproceso}_${key}_${key1}_${key2}`] = false;
                      
                      response?.data[key][key1][key_unidad][key_idproceso][key2].map((key3:any) => {
                        key3.checkSelected = false
                        unidadProcessAux[key_unidad][key_idproceso][key][key1][key2].push(key3)
                      })
                    })
                    
                    
                  })
                })
              })
            })
            const resultado = processAux.filter((obj, index, self) =>
              self.findIndex((o) => o._id === obj._id) === index
            );
            const resultadoUnidad = unidadAux.filter((obj, index, self) =>
              self.findIndex((o) => o.DesUnid.toUpperCase() === obj.DesUnid.toUpperCase()) === index
            );
            
            setListProcess(resultado)
            setListUnidad(resultadoUnidad)
            setListUnidadProcess(unidadProcessAux)
            
            setTree(newObjectArbol)
            setCheck(newObjectCheck)
          
            setDocuments(response?.data)
          } else {
            console.log(response.msg)
          }
        })
        .catch((error) => {
          console.log(error)
        })

    }else{
      if((entryDateFrom === '' && entryDateTo === '') && searchTerm.length <= 3){
        showMessage('info', 'Hubo un inconveniente', "Seleccione rango de fecha o buscar por colaborador")
      }else if(entryDateFrom === '' && entryDateTo === ''){
        showMessage('info', 'Hubo un inconveniente', "Seleccione rango de fecha")
      }else if(searchTerm.length <= 3){
        showMessage('info', 'Hubo un inconveniente', "Excribir por lo menos 4 letras para buscar por colaborador")
      }
    }
  }

  // Utility function to generate pagination links based on the current page and totalPages values
  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    // Generate "Previous" link if not on the first page
    if (currentPage > 1) {
      links.push({ page: currentPage - 1, label: 'Anterior' })
    }

    // Generate numbered pages
    for (let i = 1; i <= totalPages; i++) {
      links.push({ page: i, label: String(i) })
    }

    // Generate "Next" link if not on the last page
    if (currentPage < totalPages) {
      links.push({ page: currentPage + 1, label: 'Siguiente' })
    }

    return links
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    
  }

  const handleSelecto = (opt: any) => {
    if (opt === selecto) {
      setSelectIdUnidad(opt)
      setSelecto(0)
    } else {
      setSelecto(opt)
      setSelectIdUnidad(opt)
    }
  }

  useEffect(()=> {
    setSelecto(selectIdUnidad)
  }, [selectIdUnidad])


  // Custom Toggle Component
  const CustomToggle = React.forwardRef(({ onClick }: any, ref: any) => (
    <a
      ref={ref}
      href='#'
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
      title='Visualizar ficha'
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <KTIcon iconName='dots-square-vertical' className='fs-3' />
    </a>
  ))

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th className='text-start w-35px'></th>
                <th className='text-start'>Nombre</th>
                <th className='text-start'></th>
                <th className='text-start'></th>
                <th className='text-start'></th>
                <th className='text-end'>Acciones</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {/* { Object.keys(documents).length > 0 && ( 
                  <Fragment key={"SIN UNIDAD"} >
                    <tr className='text-start w-35px' >
                      <td onClick={() => handleSelecto("SIN UNIDAD")}>
                        <a href='# ' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {
                            selecto === "SIN UNIDAD"? 
                              <i className="bi bi-arrow-up"></i>                            
                            :
                              <i className="bi bi-arrow-right"></i>
                          }
                          
                        </a>
                      </td>
                      <td className='text-start min-w-200px'>SIN UNIDAD</td>
                      <td className='text-start min-w-200px'></td>
                      <td className='text-start min-w-200px'></td>
                      <td className='text-end'></td>
                  </tr>
                  {selecto === "SIN UNIDAD" && <ListDocuments id={selecto} />}
                </Fragment>
              )} */}
              { unidaddata.length > 0 ? (
                unidaddata.map((item: any) => (
                  <Fragment key={item.DesUnid.toUpperCase()} >
                    { Object.keys(documents).length > 0 && (
                      <tr >
                        <td className='w-35px' onClick={() => handleSelecto(item.DesUnid.toUpperCase())}>
                          <a href='# ' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {
                              selecto === item.DesUnid.toUpperCase() ? 
                                <i className="bi bi-arrow-up"></i>                            
                              :
                                <i className="bi bi-arrow-right"></i>
                            }
                            
                          </a>
                        </td>
                        <td className='text-start min-w-200px'>{item.DesUnid.toUpperCase()}</td>
                        <td className='text-start min-w-200px'></td>
                        <td className='text-start min-w-200px'></td>
                        <td className='text-end'></td>
                      </tr>

                    )}
                    {selecto === item.DesUnid.toUpperCase() && <ListDocuments id={selecto} />}

                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        
        <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export { LegajoDocumentsTable }
