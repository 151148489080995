import { createContext } from "react"
import { Perfil } from "./perfilInterface"

interface PartnerContextProps {
    searchTerm: string,
    setSearchTerm: (value: string) => void,
    perfilData:Perfil,
    setPerfilData: (value: Perfil) => void,
    edit: boolean,
    setEdit: (value: boolean) => void,
    reloadTable: boolean,
    setReloadTable: (value: boolean) => void
}

const PartnerContext = createContext<PartnerContextProps>({
    searchTerm: '',
    setSearchTerm: (value) => {},
    perfilData:{_id:'',codigo_perfil: '',nombre_perfil: '',rol: '',estado: true},
    setPerfilData: (value) => {},
    edit: false,
    setEdit: (value) => {},
    reloadTable: false,
    setReloadTable: (value) => {},
})

export {PartnerContext}