import {Button, Col, Container, Row} from 'react-bootstrap'

import React from 'react'
import {Doc, PreviewDocument} from './PreviewDocument'

interface PreviewDocumentModalFormProps {
  setIsPreviewDocumentModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  documentos: Doc[]
}

const PreviewDocumentModalForm: React.FC<PreviewDocumentModalFormProps> = ({
  setIsPreviewDocumentModalOpen,
  documentos,
}) => {
  return (
    <>
      <div>
        <Container>
          <Row className='mb-3'>
            <Col sm>
              <PreviewDocument documentos={documentos} />
            </Col>
          </Row>
          <Row>
            <div className='d-flex justify-content-end'>
              <Button
                variant='light'
                type='reset'
                onClick={() => {
                  setIsPreviewDocumentModalOpen(false)
                }}
              >
                Cerrar
              </Button>
            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}

export {PreviewDocumentModalForm}
