import debounce from 'just-debounce-it'
import { useCallback, useContext, useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import Select, { MultiValue, SelectInstance, SingleValue } from 'react-select'
import makeAnimated from 'react-select/animated'
import { TableSearch } from '../../../../shared/TableSearch'
import { PartnerContext } from '../PartnerContexts'

interface IOption {
  value: any
  label: string
}

type SelectChangeValues = SingleValue<IOption | null> | MultiValue<IOption | null>

const animateComponents = makeAnimated()

const PartnersListHeader = () => {
  const {setSearchTerm, setSearchDocument, setSearchUnidad, unidades} = useContext(PartnerContext)
  const usersSelectedRef = useRef<SelectInstance<IOption | null>>(null)

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }
  const handleSearchDocument = (value: string) => {
    setSearchDocument(value)
  }

  const filterByClients = useCallback(
    debounce((unidades: string[]) => {
      console.log('debounce should be call and filter by clients after 500ms', unidades)
      setSearchUnidad(unidades)
    }, 500),
    []
  )

  const onChangeSelect = (selectedOptions: SelectChangeValues) => {
    const unidades = (selectedOptions as IOption[]).map((option) => option?.value)
    filterByClients(unidades)
  }

  const options = unidades.map((unidad) => ({value: unidad.codUnid, label: unidad.DesUnid}))

  return (
    <>
      <div className='mx-8 mt-8 mb-2'>
        <Row>
          <Col>
            <Select
              options={options}
              isMulti
              onChange={onChangeSelect}
              ref={usersSelectedRef}
              components={animateComponents}
              closeMenuOnSelect={false}
              placeholder='Seleccionar unidad'
              noOptionsMessage={() => 'No hay unidades'}
            />
          </Col>
          <Col>
            <TableSearch
              placeholderSearch='Buscar por proceso'
              typeBusqueda='Proceso'
              onSearch={handleSearch}
              design={true}
            />
          </Col>
          <Col>
            <TableSearch
              placeholderSearch='Buscar por documento'
              typeBusqueda='documento'
              onSearch={handleSearchDocument}
              design={true}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export { PartnersListHeader }

