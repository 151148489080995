import {useContext, useEffect, useState} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {PartnerEditContext} from '../PartnerEditContext'

const TagsEditModalHeader = () => {
  const {closeTagsModal} = useContext(PartnerEditContext)
  const [title, setTitle] = useState('')

  useEffect(() => {
    setTitle('Agregar')
  }, [])

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>Etiquetas del proceso</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        onClick={() => closeTagsModal()}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
    </div>
  )
}

export {TagsEditModalHeader}
