import { useMemo, useState, useContext, useEffect, Fragment } from 'react'
import { Badge, Button, Col, Container, Dropdown, Form, Row, Stack } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import { KTCardBody, KTIcon } from '../../../../../_metronic/helpers'
import { useQueryResponseData } from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import { usersColumns } from '../../../apps/user-management/users-list/table/columns/_columns'
import { showMessage } from '../../../../shared/Alerts'
import { PartnerContext } from '../PartnerContexts'
import { Vista } from '../vistasadicionalesInterface'
import { crearRolData, updateRolData } from '../../../../services/rol'
import ReactQuill from 'react-quill'
import { crearVistaData, updateVistaData } from '../../../../services/vista'

const DataVista:Vista = {
  _id:'', 
  nombre: '',
  url: '',
  contenido: '',
  id_empresa: '',
  estado: true
}

const VistasAdicionalesFomulario = () => {
  const {vistaData,setVistaData,edit,setEdit,setReloadTable} = useContext(PartnerContext)
  const [loading, setLoading] = useState(false)
  const [editButton, setEditButton] = useState(false); 
  const [vista, setVista] = useState<Vista>(vistaData)
  const [bodyValue, setBodyValue] = useState('')

  useEffect(() => {
    setVista(vistaData)
    setBodyValue(vistaData.contenido)
  }, [vistaData])

  
  useEffect(() => {
    setEditButton(edit)
  }, [edit])
  
  

  const handleChange = (e: any) => {
      setVista({...vista, [e.target.name]: e.target.value})
  }

  const clear = ()=>{
    setEdit(false)
    setVistaData(DataVista)
    setBodyValue("")
  }


  const handleSubmit = (e: any) => {
    e.preventDefault()
    const data = {...vista, contenido: bodyValue}
    if (data._id === "") {      
      setLoading(true)
      crearVistaData(data)
      .then((response) => {
        if (response.success) {          
          setReloadTable(true)
          showMessage("success","Agregado",response.message)
          setVistaData(DataVista)
          setLoading(false)
        } else {
          setLoading(false)
          console.log(response)
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
    }else{
      setLoading(true)
      updateVistaData(data._id,data)
      .then((response) => {
        if (response.success) {
          setReloadTable(true)
          showMessage("success","Actualizado",response.message)
          setVistaData(DataVista)
          setEdit(false)
          setLoading(false)
        } else {
          setLoading(false)
          console.log(response)
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
    }
  }

  return (
    <>
      <KTCardBody className='py-4'>
        <div className="card-header">
            <h3 className="card-title">Pantalla Adicional</h3>
        </div>
        <Container>          
          <Row className='row mb-3 pt-3 px-3' >
            <Form onSubmit={handleSubmit}>
              <Col sm className='mb-2'>
                <Form.Group controlId='formNombre'>
                  <Form.Label>Titulo</Form.Label>
                  <Form.Control
                    type='text'
                    className='form-control-sm'                      
                    required
                    value={vista.nombre}
                    placeholder='Ingrese titulo'
                    onChange={handleChange}  
                    name='nombre'
                  />
                </Form.Group>
              </Col>
              <Col sm className='mb-2'>
                <Form.Group controlId='formNombre'>
                  <Form.Label>Url</Form.Label>
                  <Form.Control
                    type='text'
                    className='form-control-sm'                      
                    required
                    value={vista.url}
                    placeholder='Ingrese url'
                    onChange={handleChange}  
                    name='url'
                  />
                </Form.Group>
              </Col>
              <Col sm className='mb-2'>
                <Stack direction="vertical">
                    <h6>Contenido</h6>
                    <ReactQuill
                        theme="snow"
                        value={bodyValue}
                        onChange={setBodyValue}
                        placeholder="Escriba un Texto..."
                        style={{ height: '230px', marginBottom: '20px' }}
                    />
                </Stack>
              </Col>
              <Row style={{marginTop:'30px'}}>                
                <Col>
                  <div className='d-flex justify-content-end'>
                    {!loading && editButton &&                       
                      <Button className='mx-2' variant='light' 
                      type='reset' 
                      onClick={() => {
                        clear()
                      }}>
                        Cancelar
                      </Button>
                    }
                    <Button variant='success' type='submit'>
                      {!loading && !editButton && 'Registrar'}
                      {!loading && editButton && 'Actualizar'}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Subiendo...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </KTCardBody>
    </>
  )
}

export { VistasAdicionalesFomulario }
