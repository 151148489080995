import { fetchConToken } from '../helpers/fetch';

const url = `colaborador`;

const createMenu = async (menu) => {
  const resp = await fetchConToken(`${url}`, menu, 'POST');
  const body = await resp.json();
  return body;
};

const createOrUpdateMenu = async (subMenuData) => {
  try {
    const resp = await fetchConToken(`${url}/submenus`, subMenuData, 'POST');
    if (!resp.ok) {
      throw new Error('Error en la solicitud');
    }
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error creating submenu', error);
    return { success: false, message: 'Error creating submenu' };
  }
};

const createHuerfano = async (menuHuerfano) => {
  try {
    const resp = await fetchConToken(`${url}/createhuerfano`, menuHuerfano, 'POST');
    if (!resp.ok) {
      throw new Error('Error en la solicitud');
    }
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error creating submenu', error);
    return { success: false, message: 'Error creating submenu' };
  }
};

const deleteMenu = async (menuData) => {
  try {
    const resp = await fetchConToken(`${url}/deleteMenu`, menuData, 'POST');
    if (!resp.ok) {
      throw new Error('Error en la solicitud');
    }
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error creating submenu', error);
    return { success: false, message: 'Error creating submenu' };
  }
};

const fetchMenus = async () => {
  const resp = await fetchConToken(`${url}/menus`, {}, 'GET');
  const body = await resp.json();
  return body;
};

const fetchSubMenus = async (parentId) => {
  const resp = await fetchConToken(`${url}/submenus/${parentId}`, {}, 'GET');
  const body = await resp.json();
  return body;
};

const fetchParentMenus = async () => {
  const resp = await fetchConToken(`${url}/parent`, {}, 'GET');
  const body = await resp.json();
  return body;
};

const fetchMenuData = async () => {
  try {
    const resp = await fetchConToken(`${url}/menu`, {}, 'GET');
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error fetching menu data', error);
    return { success: false, message: 'Error fetching menu data' };
  }
};

const fetchActions = async () => {
  try {
    const resp = await fetchConToken(`${url}/actions`, {}, 'GET');
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error fetching actions', error);
    return { success: false, message: 'Error fetching actions' };
  }
};
const fetchMenuDataByRole = async (userRole) => {
  try {
    const resp = await fetchConToken(`${url}/menuRoles?role=${userRole}`, {}, 'GET');
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error fetching menu data by role', error);
    return { success: false, message: 'Error fetching menu data by role' };
  }
};

const createAction = async (actionData) => {
  try {
    const resp = await fetchConToken(`${url}/createAction`, actionData, 'POST');
    if (!resp.ok) {
      throw new Error('Error en la solicitud');
    }
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error creating action', error);
    return { success: false, message: 'Error creating action' };
  }
};
const fetchMenuDataWithActions = async () => {
  try {
    const resp = await fetchConToken(`${url}/menuWithActions`, {}, 'GET');
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error fetching menu data with actions', error);
    return { success: false, message: 'Error fetching menu data with actions' };
  }
};

 const fetchMenuActionsByRole = async (role) => {
  try {
    const resp = await fetchConToken(`${url}/menuActionsByRole?role=${role}`, {}, 'GET');
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error fetching menu actions by role', error);
    return { success: false, message: 'Error fetching menu actions by role' };
  }
};

 const updateActionRole = async (payload) => {
  try {
    const resp = await fetchConToken(`${url}/updateActionRole`, payload, 'POST');
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error updating action role', error);
    return { success: false, message: 'Error updating action role' };
  }
};
const updateMenu = async (menuData) => {
  try {
    const resp = await fetchConToken(`${url}/updateMenu`, menuData, 'POST');
    if (!resp.ok) {
      throw new Error('Error en la solicitud');
    }
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error updating menu', error);
    return { success: false, message: 'Error updating menu' };
  }
};

const updateMenuRole = async (payload) => {
  try {
    const resp = await fetchConToken(`${url}/role-menus/update`, payload, 'POST');
    if (!resp.ok) {
      throw new Error('Error en la solicitud');
    }
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error updating menu role', error);
    return { success: false, message: 'Error updating menu role' };
  }
};
const fetchActionsByRole = async (role) => {
  try {
    const resp = await fetchConToken(`${url}/actionsByRole?role=${role}`, {}, 'GET');
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error fetching actions by role', error);
    return { success: false, message: 'Error fetching actions by role' };
  }
};
const fetchSubMenuByUrl = async (urlSubMenu) => {
  try {
    const resp = await fetchConToken(`${url}/submenu?url=${urlSubMenu}`, {}, 'GET');
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error fetching submenu by URL', error);
    return { success: false, message: 'Error fetching submenu by URL' };
  }
};
const fetchAllowedUrls = async (userRol) => {
  try {
    const resp = await fetchConToken(`${url}/urls/${userRol}`, {}, 'GET');
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error('Error fetching allowed URLs', error);
    return { success: false, message: 'Error fetching allowed URLs' };
  }
};

export {
  createAction,
  createHuerfano,
  createMenu,
  createOrUpdateMenu,
  deleteMenu, fetchActions, fetchActionsByRole, fetchAllowedUrls, fetchMenuActionsByRole, fetchMenuData,
  fetchMenuDataByRole, fetchMenuDataWithActions, fetchMenus,
  fetchParentMenus, fetchSubMenuByUrl, fetchSubMenus, updateActionRole, updateMenu,
  updateMenuRole
};

