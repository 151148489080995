import React, {useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import {FileError, useDropzone} from 'react-dropzone'
import {v4} from 'uuid'
import {KTIcon, KTSVG} from '../../../_metronic/helpers'
import {showMessage} from '../../shared/Alerts'
import {UnzipFiles} from '../../shared/functions'
import {Doc} from '../configuracion/documento/document-edit/PDFViewer'
import './styles_general.css'

interface FileAuxSec {
  id: string
  file: File
  visibleToPartner: boolean
  ext: string
  filename: string
}

interface DropzoneExampleProps {
  onFilesSelected: (fileAuxSec: FileAuxSec[]) => void
  idx: string
  disabled?: boolean
  accept?: Record<string, string[]>
  setShowFileModal?: (value: boolean) => void
  setViewDocumento?: (doc: Doc[]) => void
  onSuccess?: () => void
  loading?: boolean
  fileValidation?: (file: File) => null | FileError | FileError[]
  startName?: boolean
  whenChangeUpdateFiles?: string[]
  checkRelatedUsers?: {u: string; check: boolean}[]
  handleChecks?: boolean
  setHandleChecks?: (value: boolean) => void
}

const acceptedMimeTypes = [
  'image/png',
  'image/jpeg',
  // 'image/gif',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

const extensionPreviewAllowed = ['png', 'jpeg', 'jpg', 'pdf', 'jfif']

const DropzoneArchivo2: React.FC<DropzoneExampleProps> = ({
  onFilesSelected,
  idx,
  disabled = false,
  setShowFileModal,
  setViewDocumento,
  accept = {},
  onSuccess = () => {},
  loading = false,
  fileValidation,
  startName = false,
  whenChangeUpdateFiles,
  checkRelatedUsers,
  handleChecks = false,
  setHandleChecks = () => {},
}) => {
  const [filesSelected, setFilesSelected] = useState<FileAuxSec[]>([])
  const [checkSelectAux, setCheckSelectAux] = useState(handleChecks)

  useEffect(() => {
    onFilesSelected(filesSelected)
  }, [filesSelected])

  useEffect(() => {
    setCheckSelectAux(handleChecks)
  }, [handleChecks])

  useEffect(() => {
    if (
      checkSelectAux &&
      checkRelatedUsers &&
      filesSelected.length > 0 &&
      checkRelatedUsers.length > 0
    ) {
      const aux = filesSelected.map((file) => {
        const aux = checkRelatedUsers.find((u) => file.filename.startsWith(u.u + '_'))
        if (aux) {
          return {
            ...file,
            visibleToPartner: aux.check,
          }
        }

        return file
      })

      setFilesSelected(aux)
      setCheckSelectAux(false)
      setHandleChecks(false)
    }
  }, [checkRelatedUsers, checkSelectAux])

  // useEffect(() => {
  //   if (whenChangeUpdateFiles) {

  //     whenChangeUpdateFiles.forEach((pattern) => {
  //       const filesWhenStartsWith = getFilesWhenStartsWith(files, pattern)

  //       let filesNew: any[] = []
  //       let filesAuxNew: any[] = []
  //       let fileAuxSecNew: any[] = []
  //       filesWhenStartsWith.forEach((file) => {
  //         const {index} = file

  //         const fileNew = files.find((f, idx) => idx === index)
  //         const fileAuxNew = filesAux.find((f, idx) => idx === index)
  //         const fileAuxSecNewFile = fileAuxSec.find((f, idx) => idx === index)

  //         filesNew.push(fileNew)
  //         filesAuxNew.push(fileAuxNew)
  //         fileAuxSecNew.push(fileAuxSecNewFile)

  //       })

  //       setFiles(filesNew)
  //       setFilesAux(filesAuxNew)
  //       setFileAuxSec(fileAuxSecNew)
  //     })
  //   }
  // }, [whenChangeUpdateFiles])

  const removeDuplicateFiles = (files: FileAuxSec[]) => {
    let filesWithOutDuplicates: FileAuxSec[] = []

    files.forEach((file) => {
      filesWithOutDuplicates = filesWithOutDuplicates.filter(f => f.file.name !== file.file.name)
      filesWithOutDuplicates.push(file)
    })

    return filesWithOutDuplicates
  }

  const onDrop = async (acceptedFiles: File[]) => {
    const zips = acceptedFiles.filter((file) =>
      ['application/zip', 'application/x-zip-compressed'].includes(file.type)
    )
    console.log(zips, acceptedFiles)
    const unzippedFiles = (await UnzipFiles(zips, acceptedMimeTypes, startName)) as File[]

    acceptedFiles = acceptedFiles.filter((file) =>
      !['application/zip', 'application/x-zip-compressed'].includes(file.type)
    )
    const toAuxSec = unzippedFiles.map((file) => ({
      file,
      visibleToPartner: false,
      ext: file.name.split('.').pop() ?? '',
      filename: file.name,
      id: v4(),
    }))

    const filesSelectedState = [
      ...filesSelected,
      ...toAuxSec,
      ...acceptedFiles.map((file) => ({
        file,
        visibleToPartner: false,
        ext: file.name.split('.').pop() ?? '',
        filename: file.name,
        id: v4(),
      })),
    ]
    const toFilesSelected = [...filesSelected, ...toAuxSec]

    onFilesSelected(toFilesSelected)
    setFilesSelected(filesSelectedState)
  }

  const removeFile = (id: string) => {
    setFilesSelected(filesSelected.filter((f) => f.id !== id))
  }

  const {getRootProps, getInputProps, isDragActive, fileRejections} = useDropzone({
    onDrop,
    validator: fileValidation,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg', '.jfif'],
      'application/zip': ['.zip'],
      'application/x-zip-compressed': ['.zip'],
    },
  })

  const handleInputChange = (value: string, id: string) => {
    const updatedFiles = filesSelected.map((file) => {
      if (file.id === id) {
        return {
          ...file,
          filename: value,
        }
      }

      return file
    })

    setFilesSelected(updatedFiles)
  }

  const vizualizarArchivo = async (archivo: File) => {
    const docs: Doc[] = []
    try {
      const url = URL.createObjectURL(archivo)
      if (setShowFileModal != null && setViewDocumento != null) {
        setShowFileModal(true)
        docs.push({
          fileName: archivo.name,
          fileType: archivo.type.split('/')[1],
          uri: url,
        })
        setViewDocumento(docs)
      }
    } catch (error) {
      showMessage('error', 'Hubo un error', (error as Error).message ?? '')
    }
  }

  const isPreviewable = (filename: string): boolean => {
    console.log(filename)
    let parts = filename.split('.')
    const ext = parts.length > 1 ? parts[parts.length - 1] : ''

    return extensionPreviewAllowed.includes(ext)
  }

  const handleCheck = (value: boolean, id: string) => {
    const aux = filesSelected.map((f) => {
      if (f.id === id) {
        return {...f, visibleToPartner: value}
      } else {
        return f
      }
    })

    setFilesSelected(aux)
  }

  useEffect(() => {
    if (fileRejections.length > 0) {
      showMessage('info', 'Archivos rechazados', fileRejections.map((f) => f.file.name).join(', '))
    }
  }, [fileRejections])

  return (
    <div>
      {disabled ? (
        <></>
      ) : (
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Arrastra y suelta los archivos aquí...</p>
          ) : (
            <p>Arrastra archivos aquí o haz clic para seleccionar</p>
          )}
        </div>
      )}
      <div className='g-dropzone-preview-container'>
        {filesSelected.map((file) => (
          <>
            <div key={file.id} className='g-dropzone-preview2'>
              <div className='g-file-info2'>
                <input
                  type='text'
                  className='form-control form-control-sm mr-6 text-uppercase'
                  style={{width: '60%'}}
                  onChange={(event) => handleInputChange(event.target.value, file.id)}
                  value={file.filename}
                  placeholder=''
                />

                <div className='div-options'>
                  <label htmlFor={`visible${idx}`}>Visible</label>
                  <Form.Check
                    style={{border: '1px solid #000000'}}
                    type='checkbox'
                    id={`visible${idx}`}
                    title='permitir vizualización'
                    checked={file.visibleToPartner}
                    onChange={(e) => handleCheck(e.target.checked, file.id)}
                  />
                  {isPreviewable(file.file.name) && (
                    <button
                      title='ver'
                      onClick={(e) => {
                        e.preventDefault()
                        vizualizarArchivo(file.file)
                      }}
                      className='btn btn-icon btn-light-primary btn-sm'
                    >
                      <KTIcon iconName='eye' className='fs-3' />
                    </button>
                  )}
                  <button
                    title={'eliminar'}
                    onClick={() => removeFile(file.id)}
                    className='btn btn-icon btn-light-danger btn-sm'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-1x'
                    />
                  </button>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>

      {filesSelected.length > 0 && (
        <div className='options-container'>
          <div>
            <button
              className='remove-all-btn bg-danger'
              onClick={() => {
                setFilesSelected([])
              }}
            >
              Eliminar todos los archivos
            </button>
          </div>
          <div>
            <button
              className='btn btn-light-success btn-sm'
              onClick={(e: any) => {
                e.preventDefault()
                onSuccess()
              }}
              disabled={loading}
            >
              Guardar documentos <KTIcon iconName={loading ? 'loading' : 'file-up'} className='fs-3' />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default DropzoneArchivo2
