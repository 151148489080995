import {useContext, useEffect, useState} from 'react'
import {Row, Form, Button, Col} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { v4 } from 'uuid'
import { PartnerEditContext } from '../PartnerEditContext'
import { getProcessSheetTags } from '../../../../../services/procesos'
import { Field, ListTags, ProcessSheet, Section, Sheet, Tag } from '../../documentInterface'
import { KTIcon } from '../../../../../../_metronic/helpers'


const LeyendaModalForm = () => {
  const {process_id,processSheetData,setProcessSheetData} = useContext(PartnerEditContext)
  const [listTags,setListTags] = useState<ListTags[]>([])
  useEffect(()=> {
    if(process_id){
      getProcessSheet(process_id)
    }
  },[])

  useEffect(()=>{
    showTask()
  },[processSheetData])

  const getProcessSheet = (id:string) =>{
    getProcessSheetTags(id)
    .then((response) => {
      setProcessSheetData(response.data)
    })
    .catch((error) => console.log(error))
  }
  
  const showTask = () =>{
    const listTagsAux : ListTags[] = [] 
    
    processSheetData.map((process_sheet:ProcessSheet) => {
      process_sheet.fichas.map((sheet:Sheet)=>{
        sheet.seccion.map((section:Section)=>{
          const tags : Tag[] = [] 
          section.campos.map((field:Field)=>{
            tags.push({identifier:field._id,tags:field.tag?field.tag:"",label:field.titulo,type:"string"})
          })
          listTagsAux.push({label:section.nombre,fields:tags})
        })
      })
    })
    setListTags(listTagsAux)
  }

  return (
    <>
      <Form >
        <Row className='mb-3'>
          
        <Col xs={12} lg={12} xl={12} sm> 
          <div className='d-flex align-items-center bg-light-primary rounded p-4 mb-3'>
            <span className=' text-primary'>
                <KTIcon iconName='document' className='text-primary fs-1 me-5' />
            </span>
            <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-7'>
                  KIT DE INGRESO
                </a>
                <span className='text-muted fw-semibold d-block fs-7'>
                  Estos documento será visualizado por el colaborador para que pueda usarlo como informativo o para el uso del llenado de su ficha. No necesita ninguna Firma 
                </span>
            </div>
          </div>  
        </Col>
        
        <Col xs={12} lg={12} xl={12} sm>  
          <div className='d-flex align-items-center bg-light-primary rounded p-4 mb-3'>
            <span className=' text-primary'>
                <KTIcon iconName='document' className='text-primary fs-1 me-5' />
            </span>
            <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-7'>
                  DOCUMENTOS GENERALES
                </a>
                <span className='text-muted fw-semibold d-block fs-7'>
                  Los documentos serán firmado tanto por los usuarios como los representantes legales de la empresa
                </span>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={12} xl={12} sm>  
          <div className='d-flex align-items-center bg-light-primary rounded p-4 mb-3'>
            <span className=' text-primary'>
                <KTIcon iconName='document' className='text-primary fs-1 me-5' />
            </span>
            <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-7'>
                  CPD
                </a>
                <span className='text-muted fw-semibold d-block fs-7'>
                  Los documentos serán firmado tanto por los clientes, como los representantes legales de la empresa
                </span>
            </div>
          </div>
        </Col>
        {/* <Col xs={6} lg={6} xl={6} sm>  
          <div className='d-flex align-items-center bg-light-primary rounded p-4 mb-3'>
            <span className=' text-primary'>
                <KTIcon iconName='document' className='text-primary fs-1 me-5' />
            </span>
            <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-7'>
                  Usuarios con firma de Representante Legal con archivo externo
                </a>
                <span className='text-muted fw-semibold d-block fs-7'>
                  Los documentos serán firmado tanto por los usuarios como los representantes legales de la empresa, usando un PDF externo
                </span>
            </div>
          </div>
        </Col>
        <Col xs={6} lg={6} xl={6} sm> 
          <div className='d-flex align-items-center bg-light-primary rounded p-4 mb-3'>
            <span className=' text-primary'>
                <KTIcon iconName='document' className='text-primary fs-1 me-5' />
            </span>
            <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-7'>
                  Usuarios informativo
                </a>
                <span className='text-muted fw-semibold d-block fs-7'>
                  Los documentos serán firmado solo por los usuarios, usando plantilla del sistema
                </span>
            </div>
          </div> 
        </Col>
        <Col xs={6} lg={6} xl={6} sm> 
          <div className='d-flex align-items-center bg-light-primary rounded p-4 mb-3'>
            <span className=' text-primary'>
                <KTIcon iconName='document' className='text-primary fs-1 me-5' />
            </span>
            <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-7'>
                  Usuarios informativo con archivo externo
                </a>
                <span className='text-muted fw-semibold d-block fs-7'>
                  Los documentos serán firmado solo por los usuarios, usando un PDF externo
                </span>
            </div>
          </div>
        </Col>
        <Col xs={6} lg={6} xl={6} sm> 
          <div className='d-flex align-items-center bg-light-primary rounded p-4 mb-3'>
            <span className=' text-primary'>
                <KTIcon iconName='document' className='text-primary fs-1 me-5' />
            </span>
            <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-7'>
                  Documento entre Empresas
                </a>
                <span className='text-muted fw-semibold d-block fs-7'>
                  Los documentos serán firmado solo por los usuarios, usando plantilla del sistema
                </span>
            </div>
          </div>
        </Col>
        <Col xs={6} lg={6} xl={6} sm> 
          <div className='d-flex align-items-center bg-light-primary rounded p-4 mb-3'>
            <span className=' text-primary'>
                <KTIcon iconName='document' className='text-primary fs-1 me-5' />
            </span>
            <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-7'>
                  Documento entre Empresas con archivo externo
                </a>
                <span className='text-muted fw-semibold d-block fs-7'>
                  Los documentos serán firmado solo por los usuarios, usando plantilla del sistema
                </span>
            </div>
          </div>
        </Col> */}
          

          

          

          

          

          

          


        </Row>
      </Form>
    </>
  )
}

export {LeyendaModalForm}
