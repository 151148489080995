import { useContext } from 'react'
import { Col, Row, Stack } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { TableSearch } from '../../../../shared/TableSearch'
import { PartnerContext } from '../PartnerContexts'

const PartnersListHeader = () => {
  const location = useLocation()
  const currentPath = location.pathname
  const relativeRoute = `${currentPath}/create`
  const { setSearchTerm, setSearchDocument} = useContext(PartnerContext)

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }
  const handleSearchDocument = (value: string) => {
    setSearchDocument(value)
  }


  return (
    <>
      <div className='mx-8 mt-8 mb-2'>
        <Row>
          <Col>
            <TableSearch placeholderSearch="Buscar por proceso" typeBusqueda="Proceso" onSearch={handleSearch} design={true} />
          </Col>
          <Col>
            <TableSearch placeholderSearch="Buscar por documento" typeBusqueda="documento" onSearch={handleSearchDocument} design={true} />
          </Col>
          <Col className='d-flex align-self-end justify-content-end'>
            <Stack direction='horizontal' gap={2}>
              {/* <Link to={relativeRoute}>
                <button type='button' className='btn btn-primary'>
                  <KTIcon iconName='plus' className='fs-2' />
                  Nuevo
                </button>
              </Link> */}
              {/* <button type='button' className='btn btn-light'>
                <KTIcon iconName='tablet-delete' className='fs-2' />
                Nuevo
              </button> */}
            </Stack>
          </Col>
        </Row>
      </div>
    </>
  )
}

export { PartnersListHeader }

