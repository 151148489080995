import { useContext, useState, useEffect, useRef } from 'react'
import { Row, Form, Button, FormGroup } from 'react-bootstrap'
import { SheetEditContext } from '../SheetContexts'
import { Field } from '../Sheet'
import { v4 } from 'uuid'
import { tipos } from '../../../../services/tipos'
import { get_Tables } from '../../../../services/campos'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Select from 'react-select'
import { DraggableTags } from './DraggableTags'
import { transformDependentType } from '../../../../shared/functions'

interface Type {
  _id: string
  nombre: string
  identificador: string
}

interface Table_Item {
  _id: string
  label: string
}

interface FieldExtended extends Field {
  isMultiple?: boolean
}

const FieldEditModalForm = () => {
  const {
    closeFieldModal,
    sheetData,
    setData,
    fieldData,
    setFieldData,
    sectionData,
    setSectionData,
  } = useContext(SheetEditContext)
  const [formData, setFormData] = useState<FieldExtended>({
    id: v4(),
    name: '',
    tag: '',
    isDependent: false,
    responseType: '',
    comment: '',
    visible: true,
    editable: false,
    required: false,
    actualizable: false,    
    collectionName: '',
    options: [],
    tablaData: [],
    assignedTo: '',
    dependentField: '',
    dependentFieldResponse: '',
    isMultiple: false,
    variable: false
  })

  const [types, setTypes] = useState<Type[]>([])
  const [tables, setTables] = useState<Table_Item[]>([])
  const [validated, setValidated] = useState(false)
  // const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [groupedOptions, setGroupedOptions] = useState<any>([])
  const [dependentFieldResponses, setDependentFieldResponses] = useState<any>([])

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && event.target.value) {
      event.preventDefault() // Prevent the default Enter key behavior (e.g., form submission)
      event.stopPropagation() // Stop the event propagation to prevent the modal from closing
    }
  }

  const handleTypeahead = (e: any) => {
    console.log(e)
    setFormData({
      ...formData,
      options: e,
    })
  }

  const CustomSelections = () => (
    // <Typeahead
    //   allowNew
    //   id='custom-selections'
    //   multiple
    //   newSelectionPrefix='Añade un nuevo elemento: '
    //   options={[]}
    //   defaultSelected={formData.options}
    //   placeholder='Escribe...'
    //   emptyLabel='Añade un nuevo elemento: '
    //   onKeyDown={handleKeyDown}
    //   onChange={handleTypeahead}
    // />
    <DraggableTags data={formData.options} onChange={handleTypeahead} />
  )

  const ListDB = () => (
    <FormGroup>
      <Form.Control
        as='select'
        name='collectionName'
        value={formData.collectionName}
        onChange={handleChange}
        placeholder='Seleccione el nombre de la tabla'
        size='sm'
        required
      >
        <option value=''>Seleccione una opción</option>
        {tables.map((table, index) => (
          <option key={index} value={table.label}>
            {table.label}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type='invalid'>Seleccione una coleccion.</Form.Control.Feedback>
    </FormGroup>
  )

  useEffect(() => {
    if (groupedOptions) {
      const concatenatedOptions = groupedOptions.reduce(
        (accumulator: any, current: any) => {
          return { options: accumulator.options.concat(current.options) }
        },
        { options: [] }
      ).options

      const filteredResponses = concatenatedOptions.filter(
        (option: any) => option.value === formData.dependentField
      )

      const dependentFieldResponses = filteredResponses.map((x: any) => x.responses)
      const isArray = Array.isArray(transformDependentType(formData.dependentFieldResponse ?? ''))
      setFormData({...formData, isMultiple: isArray})
      setDependentFieldResponses(dependentFieldResponses)
    }
  }, [formData.dependentField, groupedOptions])



  useEffect(() => {
    getTypes()
    getTables()
    console.log(fieldData)
    getDependentData()
    if (fieldData.id.length > 0) {
      setFormData(fieldData)
    } else {
      setFormData({
        id: v4(),
        name: '',
        tag: '',
        isDependent: false,
        responseType: '',
        comment: '',
        visible: true,
        editable: false,
        required: false,
        actualizable:false,
        collectionName: '',
        options: [],
        tablaData: [],        
        assignedTo: '',
        dependentField: '',
        dependentFieldResponse: '',
        variable: false
      })
    }
  }, [])

  // useEffect(() => {
  //   if (types.length > 0 && formData.responseType === '') {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       responseType: types[0]._id,
  //     }))
  //   }
  // }, [types])

  // useEffect(() => {
  //   if (tables.length > 0 && formData.collectionName === '') {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       responseType: tables[0].label,
  //     }))
  //   }
  // }, [tables])

  const getTypes = () => {
    tipos()
      .then((response) => {
        setTypes(response.data)
      })
      .catch((error) => console.log(error))
  }

  const getTables = () => {
    get_Tables()
      .then((response) => {
        setTables(response.data)
      })
      .catch((error) => console.log(error))
  }

  const handleChange = (e: any) => {
    if (e.target.name === 'name') {
      const tag = "{" + e.target.value.toUpperCase().replace(/\s/g, '_') + "}";
      formData.tag = tag
    }
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const validateForm = (e: any) => {
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      handleSubmit(e)
    }

    setValidated(true)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (fieldData.id.length > 0) {
      console.log(fieldData)
      console.log(sectionData)
      setData({
        ...sheetData,
        sections: (sheetData.sections || []).map((x) =>
          x.id === sectionData.id
            ? {
              ...sectionData,
              fields: (sectionData.fields || []).map((y) =>
                y.id === fieldData.id ? formData : y
              ),
            }
            : x
        ),
      })
    } else {
      setData({
        ...sheetData,
        sections: (sheetData.sections || []).map((x) =>
          x.id === sectionData.id
            ? { ...sectionData, fields: [...(sectionData.fields || []), formData] }
            : x
        ),
      })
    }

    closeFieldModal()
  }

  const handleDependent = (event: any) => {

    if (event.target.checked) {
      setFormData({ ...formData, isDependent: event.target.checked, required: false, dependentField: '', dependentFieldResponse: '' })
    } else {
      setFormData({ ...formData, isDependent: event.target.checked })
    }

    getDependentData()
  }

  const handleChangeMultiple = (e: any) => {
    setFormData({ ...formData, isMultiple: e.target.checked })
  }

  const getDependentData = () => {
    const output = sheetData.sections
      ?.map((section) => {
        if (!section.fields || section.fields.length === 0) return null

        const fieldsWithOptions = section.fields.map((field) => {
          const { id, name, options } = field
          const optionsArray = options?.map((option) => ({
            value: option.value,
            label: option.label,
            id: option.id,
          }))
          return { id, label: name, options: optionsArray }
        })

        return { id: section.id, label: section.name, options: fieldsWithOptions.filter(Boolean) }
      })
      .filter(Boolean)

    const dependentFields = output?.map((x) => ({
      label: x?.label,
      options: x?.options?.map((y) => ({
        value: y?.id,
        label: y?.label,
        responses: y.options?.map((response) => ({
          value: response.id,
          label: response.label,
        })),
      })),
    }))
    console.log(dependentFields)
    setGroupedOptions(dependentFields)
  }

  const handleDependentSelect = (e: any) => {
    if (groupedOptions) {
      const concatenatedOptions = groupedOptions.reduce(
        (accumulator: any, current: any) => {
          return accumulator.concat(current.options)
        },
        []
      )

      const filteredResponses = concatenatedOptions.filter(
        (option: any) => option.value === e.target.value
      )

      const dependentFieldResponses = filteredResponses.map((x: any) => x.responses)
      setDependentFieldResponses(dependentFieldResponses)
    }

    handleChange(e)
  }

  const handleRequiredCheckboxChange = (e: any) => {
    if (e.target.checked) {
      setFormData({ ...formData, required: e.target.checked, visible: e.target.checked, isDependent: false, dependentField: '', dependentFieldResponse: '' })
    } else {
      setFormData({ ...formData, required: e.target.checked })
    }
  }
  
  const handleActualizableCheckboxChange = (e: any) => {
    setFormData({ ...formData, actualizable: e.target.checked })
    /* if (e.target.checked) {
      setFormData({ ...formData, required: e.target.checked, visible: e.target.checked, isDependent: false, dependentField: '', dependentFieldResponse: '' })
    } else {
      setFormData({ ...formData, required: e.target.checked })
    } */
  }

  const handleVisibleCheckboxChange = (e: any) => {
    if (formData.required) {
      setFormData({ ...formData, visible: e.target.checked, required: e.target.checked })
    } else {
      setFormData({ ...formData, visible: e.target.checked })
    }
  }

  const handleChangeMulti = (e: any) => {
    const values = e.map((e: any) => e.label)
    setFormData({ ...formData, dependentFieldResponse: values.join('@') })
  }

  return (
    <>
      <Form validated={validated} onSubmit={validateForm} noValidate>
        <Form.Group className='mb-3' controlId='formNombre'>
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type='text'
            required
            placeholder='Ingrese un nombre'
            name='name'
            value={formData.name}
            onChange={handleChange}
          />
          <Form.Control.Feedback type='invalid'>
            Escriba un nombre para el campo.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Check // prettier-ignore
          type='checkbox'
          id='dependentCheckbox'
          label='Dependiente'
          className='mb-3'
          checked={formData.isDependent}
          onChange={(event) => handleDependent(event)}
        />

        {formData.isDependent ? (
          <>
            <Form.Group className='mb-3' controlId='formCampoDependiente'>
              <Form.Label>Campo Dependiente</Form.Label>
              <Form.Control
                as='select'
                name='dependentField'
                value={formData.dependentField}
                onChange={handleDependentSelect}
                required
              >
                <option value=''>Seleccione un campo</option>
                {groupedOptions.map((section: any, index: number) => (
                  <optgroup key={index} label={section.label}>
                    {section.options.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className='mb-3' controlId='formRespuestaCampoDependiente'>
              <Form.Check
                type='checkbox'
                id='multipleCheckbox'
                label='Múltiple'
                className='mb-3'
                checked={formData.isMultiple ?? false}
                onChange={(event) => handleChangeMultiple(event)}
              />

              <Form.Label>Respuesta de Campo Dependiente</Form.Label>
              {formData.isMultiple === true ? (
                <Select
                  options={dependentFieldResponses[0]?.map((x: any) => ({
                    label: x.label,
                    value: x.label,
                  }))}
                  isMulti
                  onChange={handleChangeMulti}
                  defaultValue={
                    formData.dependentFieldResponse
                      ? formData.dependentFieldResponse
                          .split('@')
                          .map((x: any) => ({label: x, value: x}))
                      : null
                  }
                />
              ) : (
                <Form.Control
                  as='select'
                  name='dependentFieldResponse'
                  value={formData.dependentFieldResponse}
                  onChange={handleChange}
                >
                  <option value=''>Seleccione un campo</option>
                  {dependentFieldResponses
                    ? dependentFieldResponses[0]?.map((response: any) => (
                        <option key={response.value} value={response.label}>
                          {response.label}
                        </option>
                      ))
                    : null}
                </Form.Control>
              )}
            </Form.Group>
          </>
        ) : null}

        <Form.Group className='mb-3' controlId='formTipoRespuesta'>
          <Form.Label>Tipo de Respuesta</Form.Label>
          <Form.Control
            as='select'
            name='responseType'
            value={formData.responseType}
            onChange={handleChange}
            required
          >
            <option value=''>Seleccione una opción</option>
            {types.map((type, index) => (
              <option key={index} value={type._id}>
                {type.nombre}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type='invalid'>
            Seleccione un tipo de respuesta para el campo.
          </Form.Control.Feedback>
        </Form.Group>

        <div className='mb-3'>
          {types.find((type) => type.identificador === 'dropdownlist')?._id ===
          formData.responseType
            ? CustomSelections()
            : null}
          {types.find((type) => type.identificador === 'dropdownlist_db')?._id ===
          formData.responseType
            ? ListDB()
            : null}
          {types.find((type) => type.identificador === 'checkbox')?._id === formData.responseType
            ? CustomSelections()
            : null}
        </div>

        <Form.Group className='mb-3' controlId='formComentario'>
          <Form.Label>Comentario</Form.Label>
          <Form.Control
            type='text'
            placeholder='Ingrese un comentario'
            name='comment'
            value={formData.comment}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Check // prettier-ignore
          type='checkbox'
          id='visibleCheckbox'
          label='Visible'
          className='mb-3'
          checked={formData.visible}
          onChange={(event) => handleVisibleCheckboxChange(event)}
        />
        <Form.Check // prettier-ignore
          type='checkbox'
          id='editableCheckbox'
          label='Editable'
          className='mb-3'
          checked={formData.editable}
          onChange={(event) => setFormData({...formData, editable: event.target.checked})}
        />
        <Form.Check // prettier-ignore
          type='checkbox'
          id='requiredCheckbox'
          label='Obligatorio'
          className='mb-3'
          checked={formData.required}
          onChange={(event) => handleRequiredCheckboxChange(event)}
        />

        <Form.Check
          type='checkbox'
          id='variableCheckbox'
          label='Replicable'
          className='mb-3'
          checked={formData.variable}
          onChange={(e) => {setFormData({...formData, variable: e.target.checked})}}
        />
        {/* <Form.Check
          type='checkbox'
          id='actualizableCheckbox'
          label='Actualizable'
          className='mb-3'
          checked={formData.actualizable}
          onChange={(event) => handleActualizableCheckboxChange(event)}
        /> */}

        <Row>
          <div className='d-flex justify-content-end'>
            <Button
              variant='light'
              className='mx-3'
              type='reset'
              onClick={() => {
                closeFieldModal()
              }}
            >
              Cancelar
            </Button>
            <Button variant='primary' type='submit'>
              Guardar
            </Button>
          </div>
        </Row>
      </Form>
    </>
  )
}

export { FieldEditModalForm }
