import moment from 'moment'
import {Fragment, useEffect, useState} from 'react'
import { Accordion, Badge, Button, Col, Container, Form, OverlayTrigger, Popover, Row, Table, Tooltip } from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import {KTCard, KTIcon} from '../../../../../_metronic/helpers'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {
  changeLevelStatus,
  getDocumentById,
  sendDocumentsWhenApprove,
  showFilePlantillaMatchS3,
} from '../../../../services/documentos'
import {downloadFileS3, getFileId, getFileInPDF} from '../../../../services/files'
import {showMessage, showRejectionMessage, showResponseValidacionMessage, showSignatureMessage} from '../../../../shared/Alerts'
import {useGlobalOverlayLoading} from '../../../../shared/hooks/useGlobalOverlayLoading'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {CustomTooltip} from '../../../generales_component/CustomTooltip'
import {PdfLoadingSpinner} from '../../../generales_component/PdfLoadingSpinner'
import {Doc} from '../../enviodocumento/enviodocumentInterface'
import {ShowFileModal} from '../show-file-modal/ShowFileModal'
import {DetalleNivelModal} from './detalle-nivel-modal/DetalleNivelModal'
import {DocumentDetailModal} from './DocumentDetailModal'
import {DocumentLoadDetailContext} from './DocumentLoadDetailContext'
import {DocumentQuickViewModal} from './DocumentQuickViewModal'
import {ShowFile2Modal} from './show-file2-modal/ShowFile2Modal'
import { LoadingSpinner } from '../../../../shared/LoadingSpinner'
import OverlayLoading from '../../../generales_component/OverlayLoading'
import { getDocumentDetail, signDocumentCPD, signMultipleDocumentsCPD } from '../../../../services/documentSent'
import { generarAuthenticacionData } from '../../../../services/authenticacion'
import { getCompany } from '../../../../services/companies'

const documentosBreadcrumbs = [
  {
    title: 'Seguimiento',
    path: '/seguimiento/cpd-documentos',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DocumentCPDDetail = () => {
  const [isDocumentDetailModalOpen, setDocumentDetailModalStatus] = useState(false)
  const [isDocumentQuickViewModalOpen, setDocumentQuickViewModalStatus] = useState(false)
  const [quickViewModalData, setQuickViewModalData] = useState<any>()
  const [selectedPlantillaId, setSelectedPlantillaId] = useState('')
  const [viewDocumentoArray, setViewDocumentoArray] = useState<Doc[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<any>()
  const [showFileModal, setShowFileModal] = useState(false)
  const [showFile2Modal, setShowFile2Modal] = useState(false)
  const [loadingNewPdf, setLoadingNewPdf] = useState(false)
  const [viewDocumento, setViewDocumento] = useState<Doc>({
    uri: '',
    fileType: '',
    fileName: '',
    keyName: '',
  })
  const [pdfData, setPdfData] = useState<Doc[]>([])
  const {userData} = useAuth2()
  const [fileStatus, setFileStatus] = useState<any>(null)
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()
  const [levelModalStatus, setLevelModalStatus] = useState(false)
  const [levelModalData, setLevelModalData] = useState<any>(null)
  const [users, setUsers] = useState([])
  const [cargandoFile, setCargandoFile] = useState(false)
  const [updateData, setUpdateData] = useState(false)
  const [userData1, setUserData1] = useState<any>([])
  const [documentDetail, setDocumentDetail] = useState<any>([])
  const [cargandoFirma, setCargandoFirma] = useState(false);
  const [multifactorEmpresa, setMultifactorEmpresa] = useState(true)
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const buttonsData = [
    {
      iconName: 'verify',
      title: 'Pendiente',
      stateName: 'pending',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Firmado',
      stateName: 'signed',
      color: '#2ecc71', // Green
    },

    {
      iconName: 'exclamation-triangle', // Adjust the icon name
      title: 'Rechazado',
      stateName: 'rejected',
      color: '#e74c3c', // Red
    },
  ]

  useEffect(()=>{
    console.log("selectedItems")
    console.log(selectedItems)
  },[selectedItems])

  const openDocumentDetailModal = () => {
    setDocumentDetailModalStatus(true)
  }

  const closeDocumentDetailModal = () => {
    setDocumentDetailModalStatus(false)
  }

  const openDocumentQuickViewModal = () => {
    setDocumentQuickViewModalStatus(true)
  }

  const closeDocumentQuickViewModal = () => {
    setDocumentQuickViewModalStatus(false)
  }

  const navigate = useNavigate()

  const {id} = useParams()

  const goBack = () => {
    navigate('/seguimiento/cpd-documentos')
  }

  useEffect(() => {
    if (id) {
      getDocument(id)
    }
  }, [])

  useEffect(() => {
    if (data && quickViewModalData) {
      getDetail(
        {
          loadId: data._id,
          processId: quickViewModalData.idproceso,
          fileId: quickViewModalData._id
        }
      )
      getUsers()
      getUserData();
      getCompany()
          .then(response => {
              if (response.success) {
                  const empresa = response.data
                  setMultifactorEmpresa(empresa?.multifactorCode ?? true)
              }
          })
          .catch((error) => {
              console.log(error)
          })

    }
  }, [data, quickViewModalData])
  
  const getDetail = (params: any) => {

    getDocumentDetail(params)
      .then((response) => {
        if (response.success) {
          setDocumentDetail(response.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  const getUserData = () => {

    const data = localStorage.getItem('userData')

    if (data) {
        setUserData1(JSON.parse(data))
    }
  }

  const getUsers = () => {
    if (quickViewModalData.typeSelected === 'Personalizado') {
      // setUsers(data?.filesChildren?.map((x: any) => ({ dni: x.dni, nombre: data?.idUsers.filter((n: any) => n.dni === x.dni)[0]?.nombre })))
      setUsers(data?.idUsers)
    } else {
      setUsers(data?.idUsers)
      console.log("data?.idUsers")
      console.log(data?.idUsers)
    }
  }

  const getDocument = (id: string) => {
    getDocumentById(id)
      .then((response) => {
        if (response.success) {
          const filesCPD = response.data.idFiles.filter((fil:any) => fil.type_document === "CPD" )?.[0]
          handleDocumentDetailModal(filesCPD,filesCPD.metadata.signFrom.includes(userData?.userId) )
          setData(response.data)
        } else {
          console.error(response.message)
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleDocumentDetailModal = (file: any, authorizationSigned: boolean) => {
    
    setQuickViewModalData({...file,authorizationSigned})
    // openDocumentDetailModal()
  }

  const handleDownload = async (keyname: string, filename: string) => {
    console.log(keyname)
    console.log(filename)
    const partes = filename.split('.')
    const extension = partes[partes.length - 1]

    downloadFileS3(keyname, extension)
      .then((response) => {
        if (response.success) {
          const link = document.createElement('a')
          link.href = response.data
          link.download = filename
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          console.log(response)
          showMessage('error', 'Hubo un error', response.error)
        }
      })
      .catch((error) => {
        console.log(error)
        showMessage('error', 'Hubo un error', error.message)
      })
  }

  const handleDocumentSignature = (documentSent: any) => {
    showSignatureMessage().then((result) => {
        if (result.isConfirmed) {
            setCargandoFirma(true)
            if (multifactorEmpresa) {
                generarAuthenticacionData({
                  id_documentSent: [documentSent._id],
                  id_usuario: userData1.userId,
                  motivo: 'signed',
                })
                  .then((response) => {
                    setCargandoFirma(false)
                    const detalle =
                      'Comprueba tu bandeja de entrada, te hemos enviado un código a tu correo.'
                    showResponseValidacionMessage(detalle, {
                      codigo: '',
                      id_documentSent: [documentSent._id],
                      id_usuario: userData1.userId,
                      motivo: 'signed',
                    }).then((result1) => {
                      if (result1.isConfirmed) {
                        Swal.fire({
                          title: 'Codigo Valido!',
                          text: 'Firmando Documento ...',
                          icon: 'success',
                        })
                        signDocumentCPD(documentSent._id)
                          .then((response) => {
                            if (response.success) {
                              Swal.close()
                              const link = document.createElement('a')
                              link.href = response.data
                              link.download = 'signed'
                              document.body.appendChild(link)
                              link.click()
                              document.body.removeChild(link)
                              setCargandoFirma(false)
                              setUpdateData(true)
                              getDocument(id as string)
                              showMessage('success', '¡Buen trabajo!', response.message)
                            } else {
                              setCargandoFirma(false)
                              console.log(response)
                              showMessage('error', 'Hubo un error', response.error)
                            }
                          })
                          .catch((error) => {
                            setCargandoFirma(false)
                            console.log(error)
                            showMessage('error', 'Hubo un error', error.message)
                          })
                      }
                    })
                  })
                  .catch((error) => {
                    setCargandoFirma(false)
                    console.log(error)
                    showMessage('error', 'Hubo un error', error.message)
                  })
            } else {
                signDocumentCPD(documentSent._id)
                    .then((response) => {
                    if (response.success) {
                        Swal.close()
                        const link = document.createElement('a')
                        link.href = response.data
                        link.download = 'signed'
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        setCargandoFirma(false)
                        setUpdateData(true)
                        getDocument(id as string)
                        showMessage('success', '¡Buen trabajo!', response.message)
                    } else {
                        setCargandoFirma(false)
                        console.log(response)
                        showMessage('error', 'Hubo un error', response.error)
                    }
                    })
                    .catch((error) => {
                      setCargandoFirma(false)
                      console.log(error)
                      showMessage('error', 'Hubo un error', error.message)
                    })
            }
        }
    })
  }

  const handleMultipleSignatures = () => {
    showSignatureMessage().then((result) => {
        if (result.isConfirmed) {
            setCargandoFirma(true)

            if (multifactorEmpresa) {
                generarAuthenticacionData({id_documentSent:selectedItems,id_usuario:userData.userId,motivo:"signed"})
                .then((response) => {
                    setCargandoFirma(false)
                    const detalle = "Comprueba tu bandeja de entrada, te hemos enviado un código a tu correo."
                    showResponseValidacionMessage(detalle,{codigo:"",id_documentSent:selectedItems,id_usuario:userData.userId,motivo:"signed"}).then((result1) => {
                        if (result1.isConfirmed) {
                            Swal.fire({
                                title: "Codigo Valido!",
                                text: "Firmando Documentos ...",
                                icon: "success"
                            });
                            
                            setCargandoFirma(true)
                            signMultipleDocumentsCPD(selectedItems)
                            .then((response) => {
                                if (response.success) {
                                    const link = document.createElement('a');
                                    link.href = response.data;
                                    link.download = 'signed_multiple';
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);

                                    showMessage('success', '¡Buen trabajo!', response.message)
                                } else {
                                    console.log(response)
                                    showMessage('error', 'Hubo un error', response.error)
                                }
                                setCargandoFirma(false)
                                setUpdateData(true)
                                getDocument(id as string)
                            })
                            .catch((error) => {
                                console.log(error)
                                setCargandoFirma(false)
                                showMessage('error', 'Hubo un error', error.message)
                            })
                        }
                    })
                })
                .catch((error) => {                            
                    setCargandoFirma(false)
                    console.log(error)
                    showMessage('error', 'Hubo un error', error.message)
                })
            } else {
                signMultipleDocumentsCPD(selectedItems)
                .then((response) => {
                    if (response.success) {
                    const link = document.createElement('a')
                    link.href = response.data
                    link.download = 'signed_multiple'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    setUpdateData(true)
                    getDocument(id as string)
                    showMessage('success', '¡Buen trabajo!', response.message)
                    } else {
                    console.log(response)
                    showMessage('error', 'Hubo un error', response.error)
                    }
                    setCargandoFirma(false)
                })
                .catch((error) => {
                    console.log(error)
                    setCargandoFirma(false)
                    showMessage('error', 'Hubo un error', error.message)
                })
            }

            
        }
    })
  }

  const getDocumentByUser = (userId: string) => {
    return documentDetail.find((x: any) => x.userId === userId)
  }

  const getStateByUserId = (userId: string) => {
    const document = getDocumentByUser(userId)

    if (document) {

      const color = buttonsData.find(n => n.stateName === document.state)?.color

      return (<span
        style={{
          backgroundColor: color,
          color: 'white',
          padding: '0.2em 0.5em',
          borderRadius: '0.25rem',
          marginRight: '0.5em',
          fontSize: '0.8em',
        }}
      >
        {getFriendlyState(document.state)}
      </span>)
    } else {
      return (<span
        style={{
          backgroundColor: '#f39c12',
          color: 'white',
          padding: '0.2em 0.5em',
          borderRadius: '0.25rem',
          marginRight: '0.5em',
          fontSize: '0.8em',
        }}
      >
        {getFriendlyState('pending')}
      </span>)
    }

  }

  const getFriendlyState = (value: string): string => {
    switch (value) {
      case 'pending':
        return 'Pendiente'
      case 'signed':
        return 'Firmado'
      case 'rejected':
        return 'Rechazado'
      default:
        return value
    }
  }

  const validateFirmedState = () : boolean => {
    if(documentDetail && documentDetail.length>0){
      return users.filter((item:any) => getDocumentByUser(item._id).state === "pending" ).length > 0
    }
    return false
  }
  
  const handleCheckChange = (selectOpion: any, id: string, all?: boolean) => {
    let fieldValue = selectOpion
    if (all) {
      if (fieldValue.target.checked) {
        const arrayUserIds = users.filter((item:any) => getDocumentByUser(item._id).state === "pending" )
        const arrayIds = arrayUserIds.map((item:any) => getDocumentByUser(item._id)._id )
        setSelectedItems(arrayIds)
      }else{
        setSelectedItems([])
      }
    }
  }

  const handleToggle = (id: string) => {
    const currentIndex = selectedItems.indexOf(id)
    const newSelected = [...selectedItems]

    if (currentIndex === -1) {
        newSelected.push(id);
    } else {
        newSelected.splice(currentIndex, 1);
    }
    setSelectedItems(newSelected)
  };

  const enableSendDocuments =
    data?.idFiles?.every((file: any) => {
      if (file.detalle_niveles == null || file.detalle_niveles?.length === 0) {
        return true
      } else {
        return file.detalle_niveles?.every((level: any) => level.detalle?.estado === 'aprobado')
      }
    }) ?? false


  const showPreview = async (item: any) => {
    if(item){
      setCargandoFile(true)
      setViewDocumento(
        {
            uri:"",
            fileType:"PDF",
            fileName:item.name,
            keyName:item.keyname
        }
      )
      setShowFileModal(true)
      setCargandoFile(false)
    }
  }

  const manejarDescarga = (userId: string) => {

    const document = getDocumentByUser(userId)
    if(document)
    handleDownload(document.keyname, document.keyname.split('/')[document.keyname.split('/').length - 1])
  }

  return (
    <DocumentLoadDetailContext.Provider
      value={{
        isDocumentDetailModalOpen,
        openDocumentDetailModal,
        closeDocumentDetailModal,
        isDocumentQuickViewModalOpen,
        openDocumentQuickViewModal,
        closeDocumentQuickViewModal,
        quickViewModalData,
        data,
        showFileModal,
        setShowFileModal,
        viewDocumento,
        setViewDocumento,
        selectedPlantillaId,
        setSelectedPlantillaId,
        viewDocumentoArray,
        setViewDocumentoArray,
        showFile2Modal,
        setShowFile2Modal,
        loadingNewPdf,
        setLoadingNewPdf,
      }}
    >
      <PageTitle breadcrumbs={documentosBreadcrumbs}>Detalle de Carga CPD</PageTitle>
      <KTCard>
        {loading ? (
          <PdfLoadingSpinner />
        ) : (
          <div className='pt-12 pb-6'>
            {
              users.length >0 && documentDetail && (
                <Container>
                  <Row className='mb-5'>
                    <Col sm>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-end'>
                          <h5>{quickViewModalData.name}</h5>
                        </div>
                        <div className={`d-flex align-self-end justify-content-end `} title="Seleccionar mas 1 documento a firmar" >
                          {
                            quickViewModalData.authorizationSigned && validateFirmedState() && 
                            <button type='button'  className='btn btn-primary mb-3' 
                            onClick={() => handleMultipleSignatures()}
                            >
                            Firmar documentos seleccionados
                          </button>
                          }
                          
                          
                        </div>
                       {/*  <Form.Control as='select' className='w-50'>
                          <option value=''>Todos</option>
                          {buttonsData.map((option) => (
                            <option
                              key={option.iconName}
                              value={option.stateName}
                              style={{ color: option.color }}
                            >
                              {option.title}
                            </option>
                          ))}
                        </Form.Control> */}
                      </div>
                    </Col>
                  </Row>
                  
                  <Row className='mb-3'>
                    <Col sm>
                      <Table bordered size='sm'>
                        <thead>
                          <tr>
                              {
                                quickViewModalData.authorizationSigned && validateFirmedState() &&
                                
                                <th className='text-start'>
                                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input
                                      className={'form-check-input  mx-2'}
                                      onChange={(event) => handleCheckChange(event, '', true)}
                                      type='checkbox'
                                      id={'flexRadioLgAll'}
                                    />
                                  </div>
                                </th>
                              }
                              
                            <th className='text-start'>DNI</th>
                            <th className='text-start'>Colaborador</th>
                            <th className='text-start'>Estado</th>
                            {
                              quickViewModalData.authorizationSigned && validateFirmedState() && 
                              <th className='text-start'>Firmar</th>
                            }
                            
                            <th className='text-end'>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.map((item: any) => (
                            <Fragment key={item._id}>
                              
                              <tr>
                                {
                                  quickViewModalData.authorizationSigned && validateFirmedState() && (
                                    getDocumentByUser(item._id)?.state === "pending" ? (
                                      <th className='w-25px'>
                                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                              <input
                                                  className='form-check-input'
                                                  type='checkbox'                                          
                                                  checked={selectedItems.includes(getDocumentByUser(item._id)?._id)}
                                                  id={`checkbox-document-tray-${getDocumentByUser(item._id)?._id}`}
                                                  onChange={(e) => handleToggle(getDocumentByUser(item._id)?._id)}
                                              />
                                          </div>
                                      </th>
                                      ) 
                                      : (<th className='w-25px'></th>)
                                  )
                                }
                                <td className='text-start fw-bold'>{item.dni}</td>
                                <td className='text-start fw-bold'>{item.nombre}</td>
                                <td className='text-start fw-bold'>
                                  {getStateByUserId(item._id)}
                                </td>
                                {
                                  quickViewModalData.authorizationSigned && validateFirmedState() && 
                                  <td className='text-start'>
                                    { getDocumentByUser(item._id)?.state === "pending" && (
                                      <button
                                          type='button'
                                          className='btn btn-sm btn-success mx-8'
                                          onClick={() => handleDocumentSignature(getDocumentByUser(item._id))}
                                      >
                                          Firmar documento
                                      </button>
                                  )
                                  }
                                </td>
                                }
                                <td className='text-end'>
                                  {
                                    getDocumentByUser(item._id)?.state === 'rejected' &&
    
                                    <OverlayTrigger
                                      trigger="click"
                                      placement="left"
                                      overlay={
                                        <Popover id="rejected-message-popober">
                                          <Popover.Header as="h3">Motivo de Rechazo</Popover.Header>
                                          <Popover.Body>
                                            {
                                              getDocumentByUser(item._id).rejectionMessage
                                            }
                                          </Popover.Body>
                                        </Popover>
                                      }>
    
                                      <a
                                        href='#'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        title='Motivo de Rechazo'
                                      >
                                        <KTIcon iconName='message-text' className='fs-3' />
                                      </a>
                                    </OverlayTrigger>
    
                                  }
                                  <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    title='Visualización'
                                    onClick={() => showPreview(getDocumentByUser(item._id))}
                                  >
                                    <KTIcon iconName='eye' className='fs-3' />
                                  </a>
                                  <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    title='Descargar'
                                    onClick={() => manejarDescarga(item._id)}
                                  >
                                    <KTIcon iconName='arrow-down' className='fs-3' />
                                  </a>
                                </td>
                              </tr>
                            </Fragment>
                          ))}
                        </tbody>
                      </Table>
    
                      {(cargandoFile || cargandoFirma) && <OverlayLoading />}
                      {/* <pre>{JSON.stringify(quickViewModalData, null, 2)}</pre>
                      <pre>{JSON.stringify(users, null, 2)}</pre> */}
                    </Col>
                  </Row>
                  <Row>
                    <div className='d-flex justify-content-start'>
                      <Button
                        variant='warning'
                        className='mx-3'
                        type='reset'
                        onClick={() => {
                          goBack()
                        }}
                      >
                        Regresar
                      </Button>
                      {/* <Button variant='primary' type='submit'>
                        {!false && 'Guardar'}
                        {false && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Guardando...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </Button> */}
                    </div>
                  </Row>
                </Container>

              )
            }
          </div>
        )}
      </KTCard>
      <DocumentDetailModal />
      <DocumentQuickViewModal />
      <ShowFileModal />
      <ShowFile2Modal />
      { loadingNewPdf && <OverlayLoading />}
      {levelModalStatus && (
        <DetalleNivelModal
          grupos={levelModalData.detalle_niveles}
          filename={levelModalData.name}
          historial={levelModalData.historial_niveles}
          closeModal={() => setLevelModalStatus(false)}
        />
      )}
    </DocumentLoadDetailContext.Provider>
  )
}

export {DocumentCPDDetail}
