import {createContext} from 'react'
import {ProcessFiles, ProcessSheet} from '../documentInterface'

interface PartnerEditContextProps {
  process_id: string
  setProcessId: (value: string) => void
  processSheetData: ProcessSheet[]
  setProcessSheetData: (value: ProcessSheet[]) => void
  processFile: ProcessFiles,
  setProcessFile: (value: ProcessFiles) => void,
  isTagsModalOpen: boolean
  openTagsModal: () => void
  closeTagsModal: () => void
  showLeyenda: boolean,
  setShowLeyenda: (value: boolean) => void,
  showDetailAprobacion: boolean,
  setShowDetailAprobacion: (value: boolean) => void
}

const PartnerEditContext = createContext<PartnerEditContextProps>({
  process_id: '',
  setProcessId: (value) => {},
  processSheetData: [],
  setProcessSheetData: (value) => {},
  processFile: {
    _id: '',
    idproceso: '',
    name: '',
    file: '',
    filename: '',
    keyname: '',
    number_aprobacion:0 ,
    type: 'PLANTILLA',
    type_document: 'KIT DE INGRESO',
    codUnid: '',
    detalle_niveles:[]
  },
  setProcessFile: (value) => {},
  isTagsModalOpen: false,
  openTagsModal: () => {},
  closeTagsModal: () => {},
  showLeyenda: false,
  setShowLeyenda: (value) => {},
  showDetailAprobacion: false,
  setShowDetailAprobacion: (value) => {}
})

export {PartnerEditContext}
