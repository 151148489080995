import React from 'react'
import {KTCard, KTCardBody} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {AltaBody} from './AltaBody'
import {AltaContextProvider} from './AltaContext'
import {AltaHeader} from './AltaHeader'
import {ExportFiledModal} from './export/ExportFiledModal'

const AltaBreadcrumbs = [
  {
    title: 'Alta colaboradores',
    path: '/seguimiento/alta',
    isSeparator: false,
    isActive: false,
  },
]

const AltaPage: React.FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={AltaBreadcrumbs}>Alta colaboradores</PageTitle>
      <KTCard>
        <KTCardBody>
          <AltaContextProvider>
            <AltaHeader />
            <AltaBody />
            <ExportFiledModal />
          </AltaContextProvider>
        </KTCardBody>
      </KTCard>
    </>
  )
}

export default AltaPage
