import { useState } from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {PartnerContext} from './PartnerContexts'
import {GrupoAprobacionListHeader} from './components/GrupoAprobacionListHeader'
import {GrupoAprobacionTable} from './components/GrupoAprobacionTable'

import {Row, Form, Button, Container, Col} from 'react-bootstrap'
import { GrupoAprobacion, valuesGrupoAprobacion } from './GrupoAprobacionInterface'
import { CreateGrupoModal } from './create-show-modal/CreateGrupoModal'
import { EditGrupoModal } from './edit-show-modal/EditGrupoModal'


const historialDocumentsBreadcrumbs = [
  {
    title: 'Seguridad',
    path: '/seguridad/perfil',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const GrupoAprobacionPage = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [grupoAprobacionData, setGrupoAprobacionData] = useState<GrupoAprobacion>(valuesGrupoAprobacion)
  const [openShowModal,setOpenShowModal] = useState(false)
  const [editOpenShowModal,setEditOpenShowModal] = useState(false)
  const [reloadTable,setReloadTable] = useState(false)
  

  return (
    <>
      <PartnerContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        grupoAprobacionData, 
        setGrupoAprobacionData,
        openShowModal,
        setOpenShowModal,
        editOpenShowModal,
        setEditOpenShowModal,
        reloadTable,
        setReloadTable
      }}>
        <PageTitle breadcrumbs={historialDocumentsBreadcrumbs}>Grupo Aprobaciones</PageTitle>
          <Row className='mb-3'>
            <KTCard>
              <GrupoAprobacionListHeader />
              <GrupoAprobacionTable />
            </KTCard>
            <CreateGrupoModal />
            <EditGrupoModal />
          </Row>
      </PartnerContext.Provider>
    </>
  )
}

export {GrupoAprobacionPage}
