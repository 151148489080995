import { useEffect, useState } from 'react'
import { Badge, Button, Col, Container, Form } from 'react-bootstrap'
import { useAuth2 } from '../authv2/Auth2Provider'
import { useNavigate } from 'react-router-dom'
import { acceptTerminosCondicionesUser, getTerminosUserId } from '../../services/users'
import { fetchConToken } from '../../helpers/fetch'

const PoliticaYPrivacidadSelectorModalForm = ({ userData, onClose }: any) => {

  const { setUserData } = useAuth2()
  const [selectedPoliticaYPrivacidadData, setSelectedPoliticaYPrivacidadData] = useState<boolean>(userData.userPoliticaYPrivacidad)
  const [acceptCheck, setAcceptCheck] = useState<boolean>(false)
  const [selectedRoleId, setSelectedRoleId] = useState<string>(userData.userRole[0])
  const { setSelectedPoliticaYPrivacidad, setSelectedRole, selectedRole, selectedPoliticaYPrivacidad } = useAuth2()
  const navigate = useNavigate()

  useEffect(() => {
    if (selectedPoliticaYPrivacidad) {
      setSelectedPoliticaYPrivacidadData(selectedPoliticaYPrivacidad)
    }

    if (selectedRole && selectedRole !== '') {
      setSelectedRoleId(selectedRole)
    }
  }, [])

  const handleCheckboxChange = (e: any) => {
    setAcceptCheck(e.target.checked)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    await acceptTerminosCondicionesUser(userData.userId,{accept:acceptCheck})
    .then(async (response) => {
      if (response.success) {
        localStorage.setItem('userData',JSON.stringify({...userData,userTerminosYCondiciones:response.data.accept_terminosycondiciones}) )
        setUserData(JSON.stringify({...userData,userTerminosYCondiciones:response.data.accept_terminosycondiciones}))
        window.location.reload();
        /* await getTerminosUserId(userData.userId)
        .then((response) => {
          console.log()
          
        })
        .catch((error) => {
          console.log(error)
        }) */
        onClose()
      }
      else {
        localStorage.clear();
        window.location.replace('http://10.10.1.50:8001/Seguridad/CerrarSesion');
      }
    })
    .catch((error) => {
        console.log(error)      
    })    
  }


  return (
    <div>
      <Container>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formPoliticaYPrivacidad">
            

            <Form.Text className="text-muted">
              <Form.Label>Revisar los terminos y condiciones, dando click en el enlace siguiente</Form.Label>
              
              <a href='/vista/terminosycondiciones' target='_blank' style={{ textDecoration: 'underline' }}>
                Ver Términos y condiciones
              </a>
            </Form.Text>
          </Form.Group>
          <Form.Check // prettier-ignore
            type='checkbox'
            id='requiredCheckbox'
            checked={acceptCheck}
            label='Acepto Términos y Condiciones'
            className='mb-3'
            onChange={(event) => handleCheckboxChange(event)}
          />
          <div className='d-flex justify-content-end'>
            <Button disabled={!acceptCheck} variant="primary" type="submit">
              Aceptar
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  )
}

export { PoliticaYPrivacidadSelectorModalForm }
