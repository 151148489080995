import React, {createContext} from 'react'

interface IGlobalOverlayContext {
  globalOverlayLoading: boolean
  setGlobalOverlayLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const GlobalOverlayContext = createContext<IGlobalOverlayContext>({
  globalOverlayLoading: false,
  setGlobalOverlayLoading: () => {},
})

interface GlobalOverlayProviderProps {
  children: React.ReactNode
}

const GlobalOverlayProvider: React.FC<GlobalOverlayProviderProps> = ({children}) => {
  const [globalOverlayLoading, setGlobalOverlayLoading] = React.useState(false)

  return (
    <GlobalOverlayContext.Provider value={{globalOverlayLoading, setGlobalOverlayLoading}}>
      {children}
    </GlobalOverlayContext.Provider>
  )
}

export {GlobalOverlayProvider}
