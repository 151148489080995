import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {IconName} from '../../../shared/KTIIconKeys'

interface HeaderEntry {
  key: string
  value: string
  icon?: IconName
  iconClass?: string
  onSort?: (key: string) => void
}

interface Props {
  checkbox: {
    include: boolean
    onChange?: React.ChangeEventHandler<HTMLInputElement>
  }
  headers: HeaderEntry[]
  children: React.ReactNode
}

const TableBusiness: React.FC<Props> = ({checkbox, headers, children}) => {
  return (
    <table className='table align-middle table-row-dashed fs-6 table-hover'>
      <thead>
        <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-primary text-white'>
          {checkbox.include && (
            <th className='text-start' style={{paddingBlock: '5px', paddingLeft: '5px'}}>
              <div className='form-check form-check-custom form-check-solid form-check-sm m-2'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='check-all'
                  onChange={checkbox.onChange}
                />
              </div>
            </th>
          )}
          {headers.map((header) => (
            <td
              className='text-start'
              style={{minWidth: `${header.value.length * 12}px`}}
              key={header.key}
              onClick={() => header.onSort && header.onSort(header.key)}
            >
              <span className='d-flex gap-1 align-items-center justify-content-start'>
                {header.value}
                {header.icon && <KTIcon iconName={header.icon} className={header.iconClass} />}
              </span>
            </td>
          ))}
        </tr>
      </thead>
      <tbody className='text-gray-600 fw-bold'>{children}</tbody>
    </table>
  )
}

export {TableBusiness}
