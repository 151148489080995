import { useContext, useEffect } from 'react'
import { Row, Form, Button } from 'react-bootstrap'
import { SheetEditContext } from '../SheetContexts'
import { InfoRequerida } from '../../../documentacion/MiFicha/components/InfoRequerida'
import { ProcesoFichas } from '../../../documentacion/MiFicha/FIchaInterface'
import { Sheet } from '../Sheet'

const PreviewModalForm = () => {
  const { sheetData, closePreviewModal } = useContext(SheetEditContext)

  useEffect(() => {
    console.log('hola', sheetData)
  }, [])

  const transformDataToPreview = (original: any) => {
    const transformed = {
      "_id": original.sections[0].id,
      "nombre": original.name,
      "seccion": original.sections.map((section: any) => ({
        "_id": section.id,
        "dinamico": section.dynamic,
        "nombre": section.name,
        "descripcion": section.description,
        "orden": 0, // Assuming this is a default value
        "campos": section.fields.map((field: any) => ({
          "valuesFiles": [],
          "observacion": [],
          "_id": field.id,
          "estado": field.type[0].estado,
          "opciones": [],
          "id_user_creador": null,
          "id_user_modificador": null,
          "titulo": field.name,
          "tag": field.tag,
          "comentario": field.comment,
          "editable": field.editable,
          "visible": field.visible,
          "obligatorio": field.required,
          "orden": 0, // Assuming this is a default value
          "idseccion": section.id,
          "idtipo": field.responseType,
          "dependiente": field.isDependent,
          "tabla": "",
          "campoDependiente": field.dependentField,
          "respuestaCampoDependiente": field.dependentFieldResponse,
          "tipo": [{
            "_id": field.type[0]._id,
            "nombre": field.type[0].nombre,
            "estado": field.type[0].estado
          }],
          "tablaData": [],
          "infoFiles": []
        })),
        "secciones": [] // Assuming no nested sections in the original object
      }))
    };
    return [
      {
        _id: '',
        idproceso: '',
        fichas: [
          transformed
        ]
      } as ProcesoFichas
    ];
  }

  return (
    <>
      <Form noValidate>

        <InfoRequerida urlRef={"/seguimiento/colaboradores"} procesoFicha={transformDataToPreview(sheetData)} isInvisible={true} idRegistroFicha={''} historial={[]} num={0} estatusFicha={''} onUpdate={function (updatedData: any): void {
          throw new Error('Function not implemented.')
        }} />

        <Row>
          <div className='d-flex justify-content-end'>
            <Button
              variant='light'
              type='reset'
              onClick={() => {
                closePreviewModal()
              }}
            >
              Salir
            </Button>
          </div>
        </Row>
      </Form>
    </>
  )
}

export { PreviewModalForm }
