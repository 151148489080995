
import React, {useState, useEffect } from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import mammoth from 'mammoth';
import { Col, Container, Row } from 'react-bootstrap';
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {getFileId,downloadFileS3} from '../../../../services/files'
import Iframe from 'react-iframe';
import PDFViewer from './PDFViewer';
import OverlayLoading from '../../../generales_component/OverlayLoading';
import { showFilePlantillaMatchS3 } from '../../../../services/documentos';
import { showMessage } from '../../../../shared/Alerts';

interface Doc {
    uri:string;
    fileType:string;
    fileName: string;
}

function DocumentViewPlantilla() {
    const {idfile,iduser} = useParams()
    const [docs, setDocs] = useState<Doc[]>([])
    const [documento, setDocumento] = useState<Doc[]>([])
    const [base64, setBase64] = useState("");
    const [keyNameFile, setKeyNameFile] = useState("");
    const [extensionFile, setExtensionFile] = useState("");
    const [cargandoFile, setCargandoFile] = useState(false);
    let blob : any

    useEffect(() => {
        if (idfile && iduser) {
            handleGetDocument(idfile,iduser)
        }
    }, [])

    /* useEffect(() => {
        if (id) {
            handleGetDocument(id)
        }
    }, [id]) */

    const base64ToBlob = (base64:string) => {
        const binaryString = window.atob(base64);
        const arrayBuffer = new ArrayBuffer(binaryString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryString.length; i++) {
            uint8Array[i] = binaryString.charCodeAt(i);
        }
        return new Blob([uint8Array]);
    };

    const handleGetDocument = async (idfile:string,iduser:string) => {
        setCargandoFile(true)
        const data = {  
            idUser:iduser, 
            idFile:idfile
        }
        const response = await showFilePlantillaMatchS3(data);
        console.log(response)
        if (response.success) {
            const keyname = response.data.keyname
            const partes = response.data.filename.split('.');
            const extension = partes[partes.length - 1];
            let filename = ''
            let exist = false
            partes.map((par:any) => {
                console.log("extension")
                console.log(extension)
                console.log("par")
                console.log(par)
                if(String(extension) === String(par)){
                    filename = filename + ".pdf"
                }else{                    
                    filename = filename + "."+par
                }
            })

            const doc = [
                { 
                    uri: response.data.data,
                    fileType:"pdf",
                    fileName:filename
                }]
            setCargandoFile(false)
            setDocs(doc)
        } else {
            showMessage('error', 'Hubo un error al obtener el archivo',"Contactar con el area de TI" )
            console.log(response.error)
            setCargandoFile(false)
            // throw new Error("Error en la respuesta de downloadFileS3");
        }

    };



    /* const docs = [
        { 
            uri: "https://s3.amazonaws.com/tawa.wdocs.bucket.des/Empresa/647c0d730b0325fc1a8215de/Documentos/Documentos/CV%20ACTUALIZADO/Sample.docx",
            fileType:"docx",
            fileName:"Sampe.docx"
        },
        { 
            uri: "https://s3.amazonaws.com/tawa.wdocs.bucket.des/Empresa/647c0d730b0325fc1a8215de/Documentos/Documentos/CV%20ACTUALIZADO/2.xlsx",
            fileType:"xlsx",
            fileName:"ElMejor.xlsx" 
        },
        { 
            uri: "https://s3.amazonaws.com/tawa.wdocs.bucket.des/Empresa/647c0d730b0325fc1a8215de/Documentos/Documentos/CV%20ACTUALIZADO/2.xlsx",
            fileType:"xlsx",
            fileName:"ElMejor.xlsx"
        }
    ]; */


    return(
        <>
            <Container className="mt-5">
                <Row>
                    {/* <Col>
                        <div>
                            <h1>Visor de documentos Word</h1>
                            <Iframe
                                url={base64}
                                width="100%"
                                height="500px" // Puedes ajustar la altura según tus necesidades
                            />
                        </div>
                    </Col> */}
                    <Col sm={12} >
                    <DocViewer
                        documents={docs}
                        pluginRenderers={DocViewerRenderers}
                        style={{ height: 850 }}
                        theme={{
                            primary: "#ffffff",
                            secondary: "#5C5C9C",
                            tertiary: "#5296d899",
                            textPrimary: "#ffffff",
                            textSecondary: "#5296d8",
                            textTertiary: "#00000099",
                            disableThemeScrollbar: false,
                        }}
                        config={{ header: { disableHeader: true } }}
                        />
                    </Col>
                    
                </Row>
            </Container>
            {cargandoFile && <OverlayLoading />}
        </>
    )
    
    
}


export default DocumentViewPlantilla;

