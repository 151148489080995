import {useContext, useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {showFilePlantillaMatchS3} from '../../../../../services/documentos'
import { DocumentLoadDetailContext } from '../DocumentLoadDetailContext'

const ShowFile2ModalHeader = () => {
  const {
    setShowFile2Modal,
    data,
    setViewDocumentoArray,
    selectedPlantillaId,
    setSelectedPlantillaId,
    loadingNewPdf,
    setLoadingNewPdf
  } = useContext(DocumentLoadDetailContext)

  const defaultUserSelected = data.idUsers[0]
  const [selectedUserId, setSelectedUserId] = useState(defaultUserSelected._id)
  const isFirstUser = selectedUserId === defaultUserSelected._id
  const nextIndex = data.idUsers.findIndex((user:any) => user._id === selectedUserId) + 1
  const prevIndex = data.idUsers.findIndex((user:any) => user._id === selectedUserId) - 1

  const changePlantilla = async (idUser: string) => {
    setSelectedUserId(idUser)
    setViewDocumentoArray([])
    setLoadingNewPdf(true)
    const user = data.idUsers.find((user:any) => user._id === idUser)
    const tags = data.idFiles.find((doc:any) => doc._id === selectedPlantillaId)?.tags?? []
    const userTags = tags.find((tag: any) => tag.user === user.dni) ?? null
    
    const response = await showFilePlantillaMatchS3({
      idUser,
      idFile: selectedPlantillaId,
      tagsRelated: userTags?.tags 
    })

    const username = user?.nombre

    setViewDocumentoArray([
      {
        uri: response.data.data,
        fileType: 'pdf',
        fileName: `${username} - ${response.data.filename}`,
        // keyName: response.data.keyname,
        keyName: '',
      },
    ])
    setLoadingNewPdf(false)
  }

  const handleNextUser = async () => {
    const nextUser = data.idUsers[nextIndex]
    setSelectedUserId(nextUser._id)
    await changePlantilla(nextUser._id)
  }

  const handlePrevUser = async () => {
    if (isFirstUser) {
      return
    }

    const prevUser = data.idUsers[prevIndex]
    setSelectedUserId(prevUser._id)
    await changePlantilla(prevUser._id)
  }

  useEffect(() => {
    return () => {
      setSelectedPlantillaId('')
    }
  }, [])

  return (
    <>
      <div className='modal-header'>
        <h2 className='fw-bolder'>Usuarios</h2>
        <div
          className='btn btn-icon btn-sm btn-active-icon-primary'
          onClick={() => setShowFile2Modal(false)}
          style={{cursor: 'pointer'}}
        >
          <KTIcon iconName='cross' className='fs-1' />
        </div>
      </div>
      {selectedPlantillaId && (
        <div style={{padding: '1.75rem', width: '40%'}}>
          <h4>Seleccionar usuario</h4>
          <section className='d-flex justify-content-between align-items-center gap-3'>
            <Form.Select
              value={selectedUserId}
              onChange={(e) => {
                changePlantilla(e.target.value)
              }}
            >
              {data.idUsers.map((user:any) => (
                <option
                  key={user._id}
                  title={user.nombre}
                  value={user._id}
                  label={user.nombre}
                  onClick={() => {
                    console.log(user)
                  }}
                />
              ))}
            </Form.Select>
            <div className='d-flex justify-content-between gap-3'>
              <button
                title='Anterior'
                className='btn btn-icon btn-sm bg-primary'
                disabled={isFirstUser || loadingNewPdf}
                onClick={handlePrevUser}
              >
                <KTIcon iconName='arrow-left' className='fs-1' />
              </button>
              <button
                title='Siguiente'
                className='btn btn-icon btn-sm bg-primary'
                disabled={nextIndex === data.idUsers.length || loadingNewPdf}
                onClick={handleNextUser}
              >
                <KTIcon iconName='arrow-right' className='fs-1' />
              </button>
            </div>
          </section>
        </div>
      )}
    </>
  )
}

export {ShowFile2ModalHeader}
