import React from 'react'

interface FieldValueProps {
  children: React.ReactNode
}

const FieldValue: React.FC<FieldValueProps> = ({children}) => {
  return <p className='mb-0 fs-7'>{children}</p>
}

export {FieldValue}
