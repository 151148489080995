import { useEffect, useState } from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {PartnerContext} from './PartnerContexts'
import {PartnersListHeader} from './components/PartnersListHeader'
import {PartnersTable} from './components/PartnersTable'
import { ObservacionModal } from './components/observacion/ObservacionModal'
import { Campos, DataTabla, Ficha, ProcesoFichas, Seccion, Tabla } from '../../documentacion/MiFicha/FIchaInterface'
import { FichaPlantillaEditModal } from './show-ficha-plantilla-modal/FichaPlantillaModal'
import { Sheet } from "../fichas/Sheet"
import { ShowCargaExcelModal } from './show-carga_excel-modal/ShowCargaExcelModal'

const partnersBreadcrumbs = [
  {
    title: 'Seguimiento',
    path: '/seguimiento/colaboradores',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PartnersPage = () => {

  const [activeOnly, setActiveOnly] = useState(true)
  const [state, setState] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [entryDateFrom, setEntryDateFrom] = useState('')
  const [entryDateTo, setEntryDateTo] = useState('')
  const [idRegistroFicha, setIdRegistroFicha] = useState('')
  const [isObservacionModalOpen, setObservacionModalStatus] = useState(false)
  const [procesoFichaData, setProcesoFichaData] = useState<ProcesoFichas[]>([])
  const [sheet, setSheet] = useState<Sheet>({name: "",type: "",description: "",sections: []})
  const [fichas, setFichas] = useState<Ficha[]>([])
  const [seccion, setSeccion] = useState<Seccion[]>([])
  const [campos, setCampos] = useState<Campos[]>([])
  const [secciones, setSecciones] = useState<Tabla[]>([])
  const [dataSecciones, setDataSecciones] = useState<DataTabla[]>([])
  const [isFichaPlantillaModalOpen, setFichaPlantillaModalStatus] = useState(false)
  const [sheet_id, setSheetId] = useState('')
  const [showCargaExcelModal,setShowCargaExcelModal] = useState(false)
  const [refresh,setRefresh] = useState(false)
  const [processId, setProcessId] = useState('')
  const [unidadId, setUnidadId] = useState('')
  const [query, setQuery] = useState<Record<string, string> | null>(null)
  const [filtrosKeys, setFiltrosKeys] = useState<string[]>([])
  const [filtroData, setFiltroData] = useState<{campo: string; isUnidad: boolean, values?: any[]}[]>([])
  const [misUnidades, setMisUnidades] = useState<Record<string, any>[]>([])
  const [campoUnidad, setCampoUnidad] = useState('')
  const [idficha, setIdFicha] = useState('')
  const [fichaData, setFichaData] = useState<{labe: string, value: string}[]>([])
  const [loadingFichaContent, setLoadingFichaContent] = useState(false)
  const [Dates, setDates] = useState<[Date | null, Date | null]>([null, null])

  const openFichaPlantillaModal = () => {
    setFichaPlantillaModalStatus(true)
  }

  const closeFichaPlantillaModal = () => {
    setFichaPlantillaModalStatus(false)
  }

  const openObservacionModal = (id:string) => {
    setIdRegistroFicha(id)
    setObservacionModalStatus(true)
  }

  const handleInputObservacionChange = (id:string) => {
  }

  const closObservacionModal = () => {
    setObservacionModalStatus(false)
    //clearSection()
  }

  useEffect(() => {
    setEntryDateFrom(Dates[0]?.toISOString().split('T')[0] ?? '')
    setEntryDateTo(Dates[1]?.toISOString().split('T')[0] ?? '')
  }, [Dates])

  return (
    <>
      <PartnerContext.Provider
      value={{
        activeOnly,
        setActiveOnly,
        state,
        setState,
        isObservacionModalOpen,openObservacionModal,
        closObservacionModal,idRegistroFicha,procesoFichaData,setProcesoFichaData,
        sheet, 
        setSheet,
        fichas,
        setFichas,
        seccion,
        setSeccion,
        campos,
        setCampos,
        secciones,
        setSecciones,
        dataSecciones,
        setDataSecciones,
        handleInputObservacionChange,
        searchTerm,
        setSearchTerm,
        entryDateFrom,
        setEntryDateFrom,
        entryDateTo,
        setEntryDateTo,
        isFichaPlantillaModalOpen,
        openFichaPlantillaModal,
        closeFichaPlantillaModal,
        sheet_id,
        setSheetId,
        showCargaExcelModal,
        setShowCargaExcelModal,
        refresh,
        setRefresh,
        processId,
        setProcessId,
        unidadId,
        setUnidadId,
        query,
        setQuery,
        filtrosKeys,
        setFiltrosKeys,
        filtroData,
        setFiltroData,
        misUnidades,
        setMisUnidades,
        campoUnidad,
        setCampoUnidad,
        idficha,
        setIdFicha,
        fichaData,
        setFichaData,
        loadingFichaContent,
        setLoadingFichaContent,
        Dates,
        setDates
      }}>
        <PageTitle breadcrumbs={partnersBreadcrumbs}>Colaboradores</PageTitle>
        <KTCard className='min-vh-100 overflow-auto'>
          <PartnersListHeader />
          <PartnersTable />
        </KTCard>
      <ObservacionModal />
      <ShowCargaExcelModal />
      <FichaPlantillaEditModal />
      </PartnerContext.Provider>
    </>
  )
}

export {PartnersPage}
