import {useContext, useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import {showFilePlantillaMatchS3} from '../../../../services/documentos'
import {PartnerContext} from '../PartnerContexts'

const ShowFileModalHeader = () => {
  const {
    setShowFileModal,
    listUsers,
    setViewDocumento,
    selectedPlantillaId,
    setSelectedPlantillaId,
    loadingNewPdf,
    setLoadingNewPdf,
    listDocuments
  } = useContext(PartnerContext)

  const defaultUserSelected = listUsers[0]
  const [selectedUserId, setSelectedUserId] = useState(defaultUserSelected._id)
  const isFirstUser = selectedUserId === defaultUserSelected._id
  const nextIndex = listUsers.findIndex((user) => user._id === selectedUserId) + 1
  const prevIndex = listUsers.findIndex((user) => user._id === selectedUserId) - 1

  const changePlantilla = async (idUser: string) => {
    setSelectedUserId(idUser)
    setViewDocumento([])
    setLoadingNewPdf(true)
    const user = listUsers.find((user) => user._id === idUser)
    const tags = listDocuments.find(doc => doc._id === selectedPlantillaId)?.tags?? []
    const userTags = tags.find((tag: any) => tag.user === user.dni) ?? null
    
    const response = await showFilePlantillaMatchS3({
      idUser,
      idFile: selectedPlantillaId,
      tagsRelated: userTags?.tags 
    })

    const username = user?.nombre

    setViewDocumento([
      {
        uri: response.data.data,
        fileType: 'pdf',
        fileName: `${username} - ${response.data.filename}`,
        // keyName: response.data.keyname,
        keyName: '',
      },
    ])
    setLoadingNewPdf(false)
  }

  const handleNextUser = async () => {
    const nextUser = listUsers[nextIndex]
    setSelectedUserId(nextUser._id)
    await changePlantilla(nextUser._id)
  }

  const handlePrevUser = async () => {
    if (isFirstUser) {
      return
    }

    const prevUser = listUsers[prevIndex]
    setSelectedUserId(prevUser._id)
    await changePlantilla(prevUser._id)
  }

  useEffect(() => {
    return () => {
      setSelectedPlantillaId('')
    }
  }, [])

  return (
    <>
      <div className='modal-header'>
        <h2 className='fw-bolder'>Usuarios</h2>
        <div
          className='btn btn-icon btn-sm btn-active-icon-primary'
          onClick={() => setShowFileModal(false)}
          style={{cursor: 'pointer'}}
        >
          <KTIcon iconName='cross' className='fs-1' />
        </div>
      </div>
      {selectedPlantillaId && (
        <div style={{padding: '1.75rem', width: '40%'}}>
          <h4>Seleccionar usuario</h4>
          <section className='d-flex justify-content-between align-items-center gap-3'>
            <Form.Select
              value={selectedUserId}
              onChange={(e) => {
                changePlantilla(e.target.value)
              }}
            >
              {listUsers.map((user) => (
                <option
                  key={user._id}
                  title={user.nombre}
                  value={user._id}
                  label={user.nombre}
                  onClick={() => {
                    console.log(user)
                  }}
                />
              ))}
            </Form.Select>
            <div className='d-flex justify-content-between gap-3'>
              <button
                title='Anterior'
                className='btn btn-icon btn-sm bg-primary'
                disabled={isFirstUser || loadingNewPdf}
                onClick={handlePrevUser}
              >
                <KTIcon iconName='arrow-left' className='fs-1' />
              </button>
              <button
                title='Siguiente'
                className='btn btn-icon btn-sm bg-primary'
                disabled={nextIndex === listUsers.length || loadingNewPdf}
                onClick={handleNextUser}
              >
                <KTIcon iconName='arrow-right' className='fs-1' />
              </button>
            </div>
          </section>
        </div>
      )}
    </>
  )
}

export {ShowFileModalHeader}
