import React, {Fragment, useEffect, useRef, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ficha, fichas} from '../../../../../services/fichas'
import {ordenarSecciones, seccionxficha} from '../../../../../services/secciones'
import {ListaCampos} from '../campos/ListaCampos'
import {ModalNuevo} from './modals/ModalNuevo'
import {ModalEliminar} from './modals/ModalEliminar'
import {ModalActualizar} from './modals/ModalActualizar'
import {ListaCamposTest} from '../campos/ListaCamposTest'
import {CantidadCampos} from './sections/CantidadCampos'

export const ListaSecciones = ({id, mnuevo, setMnuevo, nuevo}) => {
  const [lista, setLista] = useState('')
  const [selecto, setSelecto] = useState(0)
  const [show, setShow] = useState(false)
  const [updated, setUpdated] = useState(false)
  const [meliminar, setMeliminar] = useState(false)
  const [eliminar, setEliminar] = useState('')
  const [mactualizar, setMactualizar] = useState(false)
  const [actualizar, setActualizar] = useState('')
  const [xmnuevo, setXmnuevo] = useState(false)
  const [xnuevo, setXnuevo] = useState('')
  let itemDrag = useRef()
  let itemDragOver = useRef()

  useEffect(() => {
    refetchData()
  }, [])

  useEffect(() => {
    refetchData()
  }, [updated])

  const refetchData = () => {
    seccionxficha(id)
      .then((response) => {
        if (response.success) {
          setLista(response?.data)
        } else {
          console.log(response.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleSelecto = (opt) => {
    if (opt === selecto) {
      setSelecto(0)
    } else {
      setSelecto(opt)
    }
  }

  const handleEliminar = (id) => {
    setMeliminar(!meliminar)
    setEliminar(id)
  }

  const handleActualizar = (id) => {
    setMactualizar(!mactualizar)
    setActualizar(id)
  }

  const handleNuevoCampo = (id) => {
    setXnuevo(id)
    setXmnuevo(!xmnuevo)
    setSelecto(id)
  }

  const D_start = (e, index) => {
    itemDrag.current = index
  }

  const D_Enter = (e, index) => {
    itemDragOver.current = index
    const cpArr = [...lista]
    let finalArr = []

    cpArr.forEach((item) => {
      finalArr.push(item)
    })

    finalArr[index].isDragging = true
    setLista(finalArr)
  }

  const D_End = (e, index) => {
    const arr1 = [...lista]
    const todo_item_main = arr1[itemDrag.current]
    arr1.splice(itemDrag.current, 1)
    arr1.splice(itemDragOver.current, 0, todo_item_main)

    itemDrag.current = null
    itemDragOver.current = null

    let f_arr = []

    arr1.forEach((item, index) => {
      ordenarSecciones(item._id, index)
        .then((response) => {
          if (response.success) {
            console.log(response.data)
          } else {
            console.log(response.message)
          }
        })
        .catch((error) => {
          console.log(error)
        })
      f_arr.push({...item, isDragging: false})
    })

    setLista(f_arr)
  }

  return (
    <>
      {lista && (
        <>
          {lista?.map((item, index) => (
            <Fragment key={item._id}>
              <tr
                draggable
                droppable
                onDragStart={(e) => D_start(e, index)}
                onDragEnter={(e) => D_Enter(e, index)}
                onDragEnd={(e) => D_End(e, index)}
              >
                <td>
                  <div className='symbol symbol-45px me-2'>
                    <span className='symbol-label'>
                      <img
                        src={toAbsoluteUrl('/media/svg/brand-logos/grupo.svg')}
                        className='h-50 align-self-center'
                        alt=''
                      />
                    </span>
                  </div>
                </td>
                <td onClick={() => handleSelecto(item._id)}>
                  <a href='# ' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    {item.nombre}
                  </a>
                </td>
                <td className='text-end text-muted fw-semibold'>{'SECC' + (index + 1)}</td>
                <td></td>
                <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                  {item?.descripcion}
                </td>
                <td></td>
                <td>
                  <CantidadCampos idseccion={item?._id}/>
                  <div
                    className='symbol symbol-45px me-2 pr-2'
                    onClick={() => handleNuevoCampo(item._id)}
                  >
                    <span className='symbol-label' style={{cursor: 'pointer'}}>
                      <img
                        src={toAbsoluteUrl('/media/svg/brand-logos/nuevo.png')}
                        className='h-50 align-self-center'
                        alt='Agregar'
                        title='Agregar Campo'
                      />
                    </span>
                  </div>
                  <div
                    className='symbol symbol-45px me-2 pr-2'
                    onClick={() => handleActualizar(item)}
                  >
                    <span className='symbol-label' style={{cursor: 'pointer'}}>
                      <img
                        src={toAbsoluteUrl('/media/svg/brand-logos/editar.png')}
                        className='h-50 align-self-center'
                        alt='Editar'
                        title='Editar'
                      />
                    </span>
                  </div>
                  <div className='symbol symbol-45px me-2' onClick={() => handleEliminar(item._id)}>
                    <span className='symbol-label' style={{cursor: 'pointer'}}>
                      <img
                        src={toAbsoluteUrl('/media/svg/brand-logos/eliminar.png')}
                        className='h-50 align-self-center'
                        alt='Eliminar'
                        title='Eliminar'
                      />
                    </span>
                  </div>
                </td>
              </tr>
              {item._id === selecto ? (
                <ListaCamposTest
                  id={selecto}
                  showcampo={xmnuevo}
                  setShowCampo={setXmnuevo}
                  setUpdatedsecc={setUpdated}
                  updatedsecc={updated}
                  idseccion={xnuevo}
                />
              ) : null}
            </Fragment>
          ))}
        </>
      )}
      {/* Modal Agregar Nueva Seccion */}
      <ModalNuevo
        show={mnuevo}
        setShow={setMnuevo}
        setUpdated={setUpdated}
        updated={updated}
        datanuevo={nuevo}
      />
      {/* Modal Eliminar Ficha */}
      <ModalEliminar
        show={meliminar}
        setShow={setMeliminar}
        data={eliminar}
        setUpdated={setUpdated}
        updated={updated}
        setLimpiar={setEliminar}
      />
      {/* Modal Eliminar Ficha */}
      <ModalActualizar
        show={mactualizar}
        setShow={setMactualizar}
        data={actualizar}
        setUpdated={setUpdated}
        updated={updated}
        setLimpiar={setActualizar}
      />
    </>
  )
}
