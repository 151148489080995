import {useEffect, useState} from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {Doc} from '../enviodocumento/enviodocumentInterface'
import {MasiveIndividualData, PartnerContext, detDocument} from './PartnerContexts'
import {LegajoDocumentsListHeader} from './components/LegajoDocumentsListHeader'
import {LegajoDocumentsTable} from './components/LegajoDocumentsTable'
import {ShowCargaMasiva} from './show-carga-masiva/ShowCargaMasiva'
import {CargarDocumentEditModal} from './show-cargar-document-modal/CargarDocumentModal'

const legajoBreadcrumbs = [
  {
    title: 'Seguimiento',
    path: '/seguimiento/legajo',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const LegajoDocumentsPage = () => {
  const [activeOnly, setActiveOnly] = useState(true)
  const [selectIdUnidad, setSelectIdUnidad] = useState<string>('')
  const [selectIdProceso, setSelectIdProceso] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState('')
  const [searchDocumento, setSearchDocumento] = useState('')
  const [searchSend, setSearchSend] = useState(true)
  const [entryDateFrom, setEntryDateFrom] = useState('')
  const [entryDateTo, setEntryDateTo] = useState('')
  const [userSelected, setUserSelected] = useState<any[]>([])
  const [documents, setDocuments] = useState<any[]>([])
  const [documentsAux, setDocumentsAux] = useState<{}>({})
  const [listProcess, setListProcess] = useState<any[]>([])
  const [listProcessAll, setListProcessAll] = useState<any[]>([])
  const [listUnidad, setListUnidad] = useState<any[]>([])
  const [tree, setTree] = useState<{[key: string]: boolean}>({})
  const [check, setCheck] = useState<{[key: string]: boolean}>({})
  const [listUnidadProcess, setListUnidadProcess] = useState<{
    [unidad: string]: {[process: string]: {[año: string]: {[mes: string]: {[user: string]: any[]}}}}
  }>({})
  const [listUnidadAll, setListUnidadAll] = useState<any[]>([])
  const [unidadSelected, setUnidadSelected] = useState<any[]>([])
  const [processSelected, setProcessSelected] = useState<any[]>([])
  const [showFileModal, setShowFileModal] = useState(false)
  const [viewDocumento, setViewDocumento] = useState<Doc[]>([])
  const [cargarDocumentModal, setCargarDocumentModal] = useState(false)
  const [detalleDocument, setDetalleDocument] = useState<detDocument[]>([])
  const [individualData, setIndividualData] = useState<MasiveIndividualData>({
    dni: '',
    origin: '',
    loadId: '',
    userId: '',
    idproceso: '',
    username: '',
    unidad: '',
  })
  const [openCargaMasiva, setOpenCargaMasiva] = useState(false)
  const [handleChecks, setHandleChecks] = useState(false)
  const [Dates, setDates] = useState<[Date | null, Date | null]>([null, null])

  const handleInputObservacionChange = (id: string) => {}

  const openCargarDocumentModal = () => {
    setCargarDocumentModal(true)
  }

  const closeCargarDocumentModal = () => {
    setCargarDocumentModal(false)
  }

  const reset = () => {
    setSearchSend(false)
    setDocumentsAux({})
    setDocuments([])
    setTree({})
    setCheck({})
    setListUnidadProcess({})
    setUnidadSelected([])
    setProcessSelected([])
    setDates([null, null])
  }

  useEffect(() => {
    setEntryDateFrom(Dates[0]?.toISOString().split('T')[0] ?? '')
    setEntryDateTo(Dates[1]?.toISOString().split('T')[0] ?? '')
  }, [Dates])

  return (
    <>
      <PartnerContext.Provider
        value={{
          activeOnly,
          setActiveOnly,
          selectIdUnidad,
          setSelectIdUnidad,
          selectIdProceso,
          setSelectIdProceso,
          handleInputObservacionChange,
          searchSend,
          setSearchSend,
          searchTerm,
          setSearchTerm,
          entryDateFrom,
          setEntryDateFrom,
          entryDateTo,
          setEntryDateTo,
          documents,
          setDocuments,
          documentsAux,
          setDocumentsAux,
          userSelected,
          setUserSelected,
          tree,
          setTree,
          check,
          setCheck,
          listUnidad,
          setListUnidad,
          listUnidadProcess,
          setListUnidadProcess,
          listUnidadAll,
          setListUnidadAll,
          listProcess,
          setListProcess,
          listProcessAll,
          setListProcessAll,
          unidadSelected,
          setUnidadSelected,
          processSelected,
          setProcessSelected,
          searchDocumento,
          setSearchDocumento,
          showFileModal,
          setShowFileModal,
          viewDocumento,
          setViewDocumento,
          cargarDocumentModal,
          openCargarDocumentModal,
          closeCargarDocumentModal,
          detalleDocument,
          setDetalleDocument,
          individualData,
          setIndividualData,
          openCargaMasiva,
          setOpenCargaMasiva,
          handleChecks,
          setHandleChecks,
          reset,
          Dates,
          setDates
        }}
      >
        <PageTitle breadcrumbs={legajoBreadcrumbs}>Legajo</PageTitle>
        <KTCard>
          <LegajoDocumentsListHeader />
          <LegajoDocumentsTable />
        </KTCard>
        <CargarDocumentEditModal />
        <ShowCargaMasiva />
      </PartnerContext.Provider>
    </>
  )
}

export {LegajoDocumentsPage}
