import {useContext, useEffect, useState} from 'react'
import {Row, Form, Button, Container, Col} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import CheckboxTree from 'react-checkbox-tree'
import { v4 } from 'uuid'
import { PartnersTableGeneral } from '../../../generales_component/PartnersTableGeneral';
import { getProceso, getProcessUsers } from '../../../../services/procesos';
import { PartnerContext } from '../PartnerContexts'
import { array } from 'yup'
import { getFilexIdproceso } from '../../../../services/files'
import { isObject } from 'formik'
import { useDropzone } from 'react-dropzone'
import './../component/style.css'
import { showDeleteMessage, showInfoCopy, showInfoHtml } from '../../../../shared/Alerts'
import { handleS3Convert64, handleView } from '../../../generales_functions/files'
import OverlayLoading from './../../../generales_component/OverlayLoading';


const ShowDocumentPersonalizadoModalForm = () => {
  const {idProcess,setListDocuments,listDocuments, listUsers, listDocumentsPersonalizado, setViewDocumento, 
      setListDocumentsPersonalizado,setShowSelectDocumentPersonalizadoModal,setShowFileModal} = useContext(PartnerContext)
  const [bodyDocumentPersonalizado, setBodyDocumentPersonalizado] = useState<any[]>([])
  const [name, setName] = useState<string>("")
  const [hasErrorName, setHasErrorName] = useState<boolean>(false)
  const [cargandoFile, setCargandoFile] = useState(false);

  useEffect(()=>{
    getDocumentProcess()
    if(listDocumentsPersonalizado.children.length > 0){
      setName(listDocumentsPersonalizado.name)
      setBodyDocumentPersonalizado(listDocumentsPersonalizado.children)
    }
  },[])

  useEffect(()=>{
    if(listDocumentsPersonalizado.children.length > 0){
      setName(listDocumentsPersonalizado.name)
      setBodyDocumentPersonalizado(listDocumentsPersonalizado.children)
    }
    
  },[listDocumentsPersonalizado])

  /* useEffect(()=>{
    setListDocuments(bodyDocumentPersonalizado)
  },[bodyDocumentPersonalizadoSelect]) */

  const getDocumentProcess = () => {

    /* getFilexIdproceso(idProcess)
      .then((response) => {
        if (response.success) {
          if(listDocuments.length > 0){
            const filteredArray = response.data.filter((item1:any) => !listDocuments.some((item2:any) => item1._id === item2._id));
            setBodyDocument(filteredArray)            
            setBodyDocumentPersonalizadoSelect(listDocuments)
          }else{
            setBodyDocument(response.data)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      }) */
  }

  const headerUser = [
    {
      type: 'string',
      field: 'name',
      label: 'Nombre',
    },
    {
      type: 'button',
      field: 'show_url',
      label: "Ver",
      icon: "eye",
    },
    {
      type: 'button',
      field: 'delete',
      label: "Eliminar",
    }
  ]

  const fileToBase64 = (file:any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        resolve(reader.result);
      };
  
      reader.onerror = () => {
        reject(new Error('Error al leer el archivo.'));
      };

      reader.readAsDataURL(file);
    });
  };

  const filesToBase64 = async (files:any) => {
    const base64Array = [];

    for (const file of files) {
      try {
        const base64 = await fileToBase64(file);
        base64Array.push(base64);
      } catch (error) {
        console.error(error);
      }
    }

    return base64Array;
  };


  const handleBtnSelected = async  (item: any, array:any[], accion:string, propList:string ) => {
    const arrayNew : any[] = []
    
    if(accion === 'delete'){
      showDeleteMessage().then((result) => {
        if (result.isConfirmed) {
          array.map((dat:any) => {
            let entro = false
            if(dat['name'] === item.name){
              entro = true
            }
            if(!entro){
              arrayNew.push(dat)
            }
          })
          if(propList === "Documents"){
            setBodyDocumentPersonalizado(arrayNew)
          }
        }
      })

      
    }else if(accion === 'show_url'){      
      setCargandoFile(true)
      if(item.keyname){
        const result = await handleS3Convert64(item.keyname,item.name)
        if(result){
          setViewDocumento([{
            uri:result.uri,
            fileType:item.extension,
            fileName:item.name,
            keyName:item.keyname
          }])
          setShowFileModal(true)
          setCargandoFile(false)
        }else{
          setCargandoFile(false)
        }     
      } else{
        setViewDocumento([{
          uri:item.url,
          fileType:item.extension,
          fileName:item.name
        }])
        setShowFileModal(true)
        setCargandoFile(false)
      }
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    const infoFiless:{url:string; name: string; dni: string; extension: string}[] = []
    const newAcceptedFiles1 : any[] = []
    const newAcceptedFiles2 : any[] = []
    const RejectedFiles : any[] = []
    //Validamos que sea correcto el archivo subido (Usuario Seleccionado)
    acceptedFiles.forEach(res => {
      const cadenaArray = res.name.split('.')
      const nameArray = cadenaArray[0].split('_')
      let fount = false
      // document name filter  in list users
      listUsers.map(user => {
        if(nameArray[0] === user.dni){
          fount = true
        }
      })

      if(fount){
        newAcceptedFiles1.push(res)
      }else{
        RejectedFiles.push({
          'motivo':"Nombre Archivo",
          'name':res.name
        })
      }
    })
    
    // Validamos repetidos
    newAcceptedFiles1.forEach(res => { 
      let fount = false
      // document name filter  repeated
      bodyDocumentPersonalizado.map(documentPer => {
        if(documentPer.name === res.name){
          fount = true
        }
      })
      if(fount){
        RejectedFiles.push({
          'motivo':"Ya existe",
          'name':res.name
        })
      }else{
        const cadenaArray = res.name.split('.')
        const nameArray = cadenaArray[0].split('_')
        newAcceptedFiles2.push(res)
        infoFiless.push({
          'url':"",
          'name':res.name,
          'dni':nameArray[0],
          'extension':cadenaArray[1]
        })
      }

    })

    if(RejectedFiles.length>0){
      let codigoHTML = ''
      codigoHTML += `No se añadieron los siguientes documentos <b> Revisar si cumplen con el requisito Previo</b><br/>
                          <div class="centrar-tabla" >
                          <table border="1">
                          <thead>
                              <tr>
                                  <th>Motivo</th>
                                  <th>Nombre del archivo</th>
                              </tr>
                          </thead>
                          <tbody> `;
        for (const key of RejectedFiles) {            
          codigoHTML += '<tr><td>' + key.motivo  + '</td><td>' + key.name  + '</td></tr>';
        }
        codigoHTML += `</tbody> </table> </div>`
      showInfoHtml("info","Información",codigoHTML)

    }

    //we add only files accepted
    if(newAcceptedFiles2.length>0){
      filesToBase64(newAcceptedFiles2)
      .then((base64Array) => {
        const valuesFiles = base64Array.filter((file) => typeof file === 'string') as string[]        
        valuesFiles.map( (res,i) => {
          infoFiless[i].url = res
        })
        
      })
      setBodyDocumentPersonalizado([...bodyDocumentPersonalizado, ...infoFiless]);
    }
  };

  const handleInputChange = (event: any) => {
    setName(event.target.value)
    if(event.target.value === ""){
      setHasErrorName(false)
    }
  }
  
  const addFileToDocument = () => {
    if(name !== ""){
      const documentNew = {
        _id:"",
        name:name,
        type:"PDF",
        typeSelected:"Personalizado",
        edit:true,
        number_aprobacion: 0
      }

      const documentoPersonalizadoNew = {        
        _id:"",
        name:name,
        type:"PDF",
        typeSelected:"Personalizado",
        edit:false,
        number_aprobacion: 0,
        children:bodyDocumentPersonalizado
      }

      let existDocument = false
      let ind = 0
      listDocuments.map((doc, it) => {
        if(doc.typeSelected === "Personalizado"){
          existDocument = true
          ind = it
        }        
      })

      if(existDocument){
        listDocuments[ind] = documentNew
        setListDocuments(listDocuments)
      }else{
        setListDocuments([...listDocuments, documentNew])
      }
      

      setListDocumentsPersonalizado(documentoPersonalizadoNew)
      setShowSelectDocumentPersonalizadoModal(false)
    }else{
      setHasErrorName(true)
    }
    
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept: {
        "application/pdf": [".pdf"],
    },
  });

  return (
    <>
        <Container>
          <Row className='mb-3 pt-3 px-3' >
            <Row>
              <Col>
                <Form.Group controlId='formNombre'>
                  <Form.Label style={{paddingBottom:"3px"}}>Nombre de carga</Form.Label>
                  <Form.Control
                    type='text'
                    required
                    name="name"
                    value={name}
                    onChange={(event) => handleInputChange(event)}
                    className={`form-control-sm ${hasErrorName ? 'is-invalid' : ''}`}
                    placeholder='Ingrese un nombre'
                  />
                </Form.Group>
                <PartnersTableGeneral title={"Documento añadidos"} theader={headerUser} tbody={bodyDocumentPersonalizado} pagination={false} searchVisible={true} searchHint={"Buscar Documentos ..."} onBtnSelected={(Item:any,accion:string) => handleBtnSelected(Item,bodyDocumentPersonalizado,accion,"Documents")} />
              </Col>
              <Col>
                <Row>
                  <Col sm>
                  <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
                    <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                      <i className="bi bi-info-circle fs-3x"></i>
                    </span>
                    
                    <div className="d-flex flex-column">
                      <h5 className="mb-1">Información</h5>
                      <span>Se cargará un archivo PDF por usuario, el formato del archivo debe ser: <b>DNI_nombre de documento.pdf </b></span>
                      
                    </div>
                  </div>

                  </Col>
                </Row>
                <Row>
                  <div className='' >
                    { (
                      <div {...getRootProps()} className={`elevator-with-shadow dropzone ${isDragActive ? 'active' : ''}`}>
                        <input {...getInputProps()} />
                        <i className="bi bi-cloud-upload fs-4x"></i>
                        {isDragActive ? <p>Arrastra y suelta los archivos aquí...</p> : <p>Arrastra archivos aquí o haz clic para seleccionar</p>
                        }
                        <button className="btn btn-sm btn-outline btn-outline-dashed btn-outline-info btn-active-light-info">Seleccionar</button>
                      </div>
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <div className='d-flex justify-content-end my-5'>
                <Button
                  variant='light'
                  className='mx-3'
                  onClick={() => {setShowSelectDocumentPersonalizadoModal(false)}}
                  type='reset'
                >
                  Cancelar
                </Button>
                <Button 
                  variant='primary' 
                  type='button'                  
                  onClick={()=>{ bodyDocumentPersonalizado.length > 0 ? addFileToDocument() : showInfoCopy("info","Añadir un archivo","debe seleccionar un archivo antes de añador a Documentos") }}
                  >
                  Añadir
                </Button>
              </div>
            </Row>
          </Row>
          {cargandoFile && <OverlayLoading />}
        </Container>
    </>
  )
}

export {ShowDocumentPersonalizadoModalForm}
