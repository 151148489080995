import { createContext } from "react"
import { Campos, DataTabla, Ficha, ProcesoFichas, Seccion, Tabla } from "../../documentacion/MiFicha/FIchaInterface"
import { Doc } from "../enviodocumento/enviodocumentInterface"

export interface detDocument {
    createdAt: string;
    status: string;
    motivo: string;
    id_user_creador: string;
    name: string;
    email: string;
    token: string;
    ip: string;
    userAgent: string;
}

export const initialDetDocument = {
    createdAt: '',
    status: '',
    motivo: '',
    id_user_creador: '',
    name: '',
    email: '',
    token: '',
    ip: '',
    userAgent: ''
}

export interface MasiveIndividualData {
    dni: string,
    origin: string,
    loadId: string,
    userId: string,
    idproceso: string
    username: string
    unidad: string,
    tree?: any
}

interface PartnerContextProps {
    activeOnly: boolean,
    setActiveOnly: (value: boolean) => void,
    selectIdUnidad: string,
    setSelectIdUnidad: (value: string) => void,
    selectIdProceso: string,
    setSelectIdProceso: (value: string) => void,
    handleInputObservacionChange: (value:string) => void,
    searchSend: boolean,
    setSearchSend: (value: boolean) => void,
    searchTerm: string,
    setSearchTerm: (value: string) => void,
    entryDateFrom: any,
    setEntryDateFrom: (value: any) => void,
    entryDateTo: any,
    setEntryDateTo: (value: any) => void,   
    documents: any[],
    setDocuments: (value: any[]) => void,
    documentsAux: {},
    setDocumentsAux: (value: {}) => void,
    userSelected: any[],
    setUserSelected: (value: any[]) => void,
    tree: {[key:string]:boolean},
    setTree: (value: {}) => void, 
    check: {[key:string]:boolean},
    setCheck: (value: {}) => void, 
    listUnidad: any[],
    setListUnidad: (value: any[]) => void,
    listUnidadProcess: {[unidad:string]:{[process:string]:{[año: string]: { [mes: string]:{ [user: string]: any[] }}}}},
    setListUnidadProcess: (value: {}) => void,    
    listUnidadAll: any[],
    setListUnidadAll: (value: any[]) => void,
    listProcess: any[],
    setListProcess: (value: any[]) => void,
    listProcessAll: any[],
    setListProcessAll: (value: any[]) => void,
    processSelected: any[],
    setProcessSelected: (value: any[]) => void,
    unidadSelected: any[],
    setUnidadSelected: (value: any[]) => void,    
    searchDocumento: string,
    setSearchDocumento: (value: string) => void,
    showFileModal: boolean,
    setShowFileModal: (value: boolean) => void,
    viewDocumento: Doc[],
    setViewDocumento: (value: Doc[]) => void,
    cargarDocumentModal: boolean,
    openCargarDocumentModal: () => void,
    closeCargarDocumentModal: () => void,
    detalleDocument: detDocument[],
    setDetalleDocument: (value: detDocument[]) => void
    individualData: MasiveIndividualData,
    setIndividualData: (value: MasiveIndividualData) => void,
    openCargaMasiva: boolean,
    setOpenCargaMasiva: (value: boolean) => void,
    handleChecks: boolean,
    setHandleChecks: (value: boolean) => void
    reset: () => void,
    Dates: [Date | null, Date | null],
    setDates: (value: [Date | null, Date | null]) => void
}

const PartnerContext = createContext<PartnerContextProps>({
    activeOnly: true,
    setActiveOnly: (value) => {},
    selectIdUnidad: '',
    setSelectIdUnidad: (value) => {},
    selectIdProceso: '',
    setSelectIdProceso: (value) => {},
    handleInputObservacionChange: (value) => {},
    searchSend: true,
    setSearchSend: (value) => {},
    searchTerm: '',
    setSearchTerm: (value) => {},
    entryDateFrom: '',
    setEntryDateFrom: (value: any) => {},
    entryDateTo: '',
    setEntryDateTo: (value: any) => {},
    documents: [],
    setDocuments: (value) => {},
    documentsAux: {},
    setDocumentsAux: (value) => {},
    userSelected: [],
    setUserSelected: (value) => {},
    tree: {},
    setTree: (value) => {},
    check: {},
    setCheck: (value) => {},
    listUnidad: [],
    setListUnidad: (value) => {},
    listUnidadProcess: {},
    setListUnidadProcess: (value) => {},
    listUnidadAll: [],
    setListUnidadAll: (value) => {},
    listProcess: [],
    setListProcess: (value) => {},
    listProcessAll: [],
    setListProcessAll: (value) => {},
    processSelected: [],
    setProcessSelected: (value) => {},
    unidadSelected: [],
    setUnidadSelected: (value) => {},
    searchDocumento: '',
    setSearchDocumento: (value) => {},
    showFileModal: false,
    setShowFileModal: (value) => {},
    viewDocumento: [],
    setViewDocumento: (value) => {},
    cargarDocumentModal: false,
    openCargarDocumentModal: () => {},
    closeCargarDocumentModal: () => {},
    detalleDocument: [],
    setDetalleDocument: (value) => {},
    individualData: {
        dni: '',
        origin: '',
        loadId: '',
        userId: '',
        idproceso: '',
        username: '',
        unidad: '',
    },
    setIndividualData: (value) => {},
    openCargaMasiva: false,
    setOpenCargaMasiva: (value) => {},
    handleChecks: false,
    setHandleChecks: (value) => {},
    reset: () => {},
    Dates: [null, null],
    setDates: (value) => {}
})

export {PartnerContext}