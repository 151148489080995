import React, {useState} from 'react'
import {downloadFileS3} from '../../../../../services/files'
import {showMessage} from '../../../../../shared/Alerts'
import {useGlobalOverlayLoading} from '../../../../../shared/hooks/useGlobalOverlayLoading'
import {CustomTooltip} from '../../../../generales_component/CustomTooltip'
import {PreviewDocumentModal} from '../../../../generales_component/preview-document-modal/PreviewDocumentModal'
import {handleDownloadAsync} from '../../../../generales_functions/files'
import {FieldTitle} from './FieldTitle'
import {FieldValue} from './FieldValue'

interface ModernDownloadableProps {
  title: string
  infoValue: {url: string; name: string; size: string}[]
}

const ModernDownloadable: React.FC<ModernDownloadableProps> = ({title, infoValue}) => {
  const [isPreviewDocumentModalOpen, setIsPreviewDocumentModalOpen] = useState(false)
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()
  const [documentos, setDocumentos] = useState<any[]>([])

  const openModal = async (url: string, name: string) => {
    try {
      const fileType = name.split('.').pop()
      setGlobalOverlayLoading(true)
      const response = await downloadFileS3(url, fileType)
      setIsPreviewDocumentModalOpen(true)
      setDocumentos([
        {
          uri: response.data,
          fileType,
          fileName: name,
        },
      ])
    } catch (error) {
      showMessage('error', 'Hubo un error', (error as Error).message ?? '')
    } finally {
      setGlobalOverlayLoading(false)
    }
  }

  const handleMultipleActions = async (e: any, {url, name}: {url: string; name: string}) => {
    if (e.shiftKey) {
      setGlobalOverlayLoading(true)
      await handleDownloadAsync(url, name)
      setGlobalOverlayLoading(false)
    } else {
      await openModal(url, name)
    }
  }

  return (
    <>
      <div className='d-flex flex-column gap-3 p-5'>
        <FieldTitle>{title.toLocaleUpperCase()}</FieldTitle>
        <div>
          {infoValue?.length > 0 ? (
            infoValue.map((value, i) => (
              <CustomTooltip
                className='text-decoration-underline text-primary p-0 fs-8 fw-normal'
                style={{textUnderlineOffset: '8px'}}
                key={i}
                onClick={(e) => handleMultipleActions(e, {url: value.url, name: value.name})}
                variant='link'
                label={value.name}
              >
                Click para ver
                <br />
                <p className='mb-0 mt-2'>
                  <span className='border rounded border-dark p-2'> &#x2191; Shift + Click</span>{' '}
                  para descargar
                </p>
              </CustomTooltip>
            ))
          ) : (
            <FieldValue>Sin valor</FieldValue>
          )}
        </div>
      </div>
      <PreviewDocumentModal
        isPreviewDocumentModalOpen={isPreviewDocumentModalOpen}
        setIsPreviewDocumentModalOpen={setIsPreviewDocumentModalOpen}
        title={''}
        documentos={documentos}
      />
    </>
  )
}

export {ModernDownloadable}
