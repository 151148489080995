import React from 'react'
import {Accordion} from 'react-bootstrap'
import {useAuth2} from '../../../../authv2/Auth2Provider'
import {Campos} from '../../FIchaInterface'
import {FieldType, ModernField} from '../fields/ModernField'

interface SectionProps {
  title: string
  eventKey: string
  campos: Campos[]
}

const Section: React.FC<SectionProps> = ({title, eventKey, campos}) => {
  const validTypes = ['carga de documentos', 'Imagen', 'Tabla', 'Lista desplegable BD']
  const {selectedRole} = useAuth2()
  const allFieldAreNotVisible = campos.every((campo) => !campo.visible)

  return (
    <>
      {(selectedRole !== 'POST' || !allFieldAreNotVisible) && (
        <Accordion.Item eventKey={eventKey} key={eventKey}>
          <Accordion.Header>{title.toLocaleUpperCase()}</Accordion.Header>
          <Accordion.Body className='d-grid gap-3' style={{gridTemplateColumns: '1fr 1fr'}}>
            {campos.map((campo) => (
              <ModernField
                key={campo._id}
                title={campo.titulo}
                value={campo.values || campo.selectOpion?.label}
                type={
                  validTypes.includes(campo.tipo[0].nombre)
                    ? (campo.tipo[0].nombre as FieldType)
                    : 'otro'
                }
                infoValue={campo.infoFiles}
                visible={selectedRole !== 'POST' || campo.visible === true}
                objectValue={campo.objectValue}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
      )}
    </>
  )
}

export {Section}
