import { useContext, useEffect } from 'react'

import { SheetContext, SheetEditContext } from '../SheetContexts'
import { PreviewModalFormWrapper } from './PreviewModalFormWrapper'
import { PreviewModalHeader } from './PreviewModalHeader'

const PreviewModal = () => {

  const { isPreviewModalOpen } = useContext(SheetEditContext)

  return (
    <>
      {isPreviewModalOpen && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-fullscreen'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <PreviewModalHeader />
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <PreviewModalFormWrapper />
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export { PreviewModal }
