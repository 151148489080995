import {useContext, useEffect, useState} from 'react'
import {Row, Form, Button, Container, Col} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { PartnersTableGeneral } from '../../../generales_component/PartnersTableGeneral';
import { getProceso, getProcessUsers } from '../../../../services/procesos';
import { PartnerContext } from '../PartnerContexts'
import { array } from 'yup'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Doc } from "../../enviodocumento/enviodocumentInterface"
import { isObject } from 'formik';
import { DocumentLoadDetailContext } from '../document-detail/DocumentLoadDetailContext';
import PDFViewer from '../document-detail/document-quick-view/PDFViewer';


const ShowFileModalForm = () => {
  const {viewDocumento } = useContext(DocumentLoadDetailContext)
  const [docs, setDocs] = useState<Doc[]>([])
  console.log("viewDocumento")
  console.log(viewDocumento)
  /* useEffect(() => {
    if (viewDocumento.keyName === "") {
      setDocs([viewDocumento])
    }
  }, [viewDocumento])

  useEffect(() => {
    if ( isObject(viewDocumento.keyName) ) {
      if(viewDocumento.keyName === ""){
        
      }else{
        setDocs([viewDocumento])
      }
    }else{
      setDocs([viewDocumento])
    }
  }, []) */

  return(
    <>
        <Container className="mt-5">
            <Row>
              { viewDocumento?.historial ?
                <Col sm={12} >
                  <PDFViewer keyn={viewDocumento?.keyName?viewDocumento?.keyName:''} idn=''  />
                </Col>
              :
                <Col sm={12} >
                  <PDFViewer keyn={viewDocumento?.keyName?viewDocumento?.keyName:''} idn=''  />
                </Col>
              }
                
                
                  
                    {/* <DocViewer 
                        documents={docs} 
                        pluginRenderers={DocViewerRenderers} 
                        style={{height:850}}                            
                        theme={{
                            primary: "#ffffff",
                            secondary: "#5C5C9C",
                            tertiary: "#5296d899",
                            textPrimary: "#ffffff",
                            textSecondary: "#5296d8",
                            textTertiary: "#00000099",
                            disableThemeScrollbar: false,
                        }}
                        config={{ header: { disableHeader: true } }}
                    /> */}
                
                
            </Row>
        </Container>
    </>
)
}

export {ShowFileModalForm}
