import { useState } from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {PartnerContext} from './PartnerContexts'
import {PerfilListHeader} from './components/PerfilListHeader'
import {PerfilTable} from './components/PerfilTable'

import {Row, Form, Button, Container, Col} from 'react-bootstrap'
import { PerfilFomulario } from './components/PerfilFormulario'
import { Perfil } from './perfilInterface'


const historialDocumentsBreadcrumbs = [
  {
    title: 'Seguridad',
    path: '/seguridad/perfil',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DataPerfil:Perfil = {
  _id:'', 
  codigo_perfil: '',
  nombre_perfil: '',
  rol: '',
  estado: true
}
const PerfilPage = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [perfilData, setPerfilData] = useState<Perfil>(DataPerfil)
  const [edit,setEdit] = useState(false)
  const [reloadTable,setReloadTable] = useState(false)
  

  return (
    <>
      <PartnerContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        perfilData, 
        setPerfilData,
        edit,
        setEdit,
        reloadTable,
        setReloadTable
      }}>
        <PageTitle breadcrumbs={historialDocumentsBreadcrumbs}>Perfiles</PageTitle>
        
          <Row className='mb-3'>
            <Col xs={6} sm>              
              <KTCard>
                <PerfilFomulario />
              </KTCard>
            </Col>
            <Col xs={6}  sm>
              <KTCard>
                <PerfilListHeader />
                <PerfilTable />
              </KTCard>
            </Col>
          </Row>
      </PartnerContext.Provider>
    </>
  )
}

export {PerfilPage}
