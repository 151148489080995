import { fetchSinToken, fetchSinTokenStream } from "../helpers/fetch";

const url = `alert/`

const getAllAlerts = async (options: any) => {
    const queryString = new URLSearchParams(options).toString();

    const resp = await fetchSinToken(
        `${url}getAll?${queryString}`,
        {},
        'GET'
    );

    const body = await resp.json();

    return body;
};

const getAlertById = async (id: string) => {
    const resp = await fetchSinToken(
        `${url}getById/${id}`,
        {},
        'GET'
    );

    const body = await resp.json();

    return body;
};

const createAlert = async (data: any) => {

    const resp = await fetchSinToken(
        `${url}create`,
        data,
        'POST'
    );

    const body = await resp.json();

    return body;
};

const updateAlert = async (id: string, data: any) => {
    const resp = await fetchSinToken(
        `${url}update/${id}`,
        data,
        'POST'
    );

    const body = await resp.json();

    return body;
};

const deleteAlert = async (id: string) => {
    const resp = await fetchSinToken(
        `${url}delete/${id}`,
        {},
        'DELETE'
    );

    const body = await resp.json();

    return body;
};

export {
    getAllAlerts,
    createAlert,
    getAlertById,
    updateAlert,
    deleteAlert
};