import { createContext } from "react"
import { Campos, DataTabla, Ficha, ProcesoFichas, Seccion, Tabla } from "../../../documentacion/MiFicha/FIchaInterface"

interface ColaboradorObservacionContextProps {
    procesoFichaData: ProcesoFichas[],
    setProcesoFichaData: (value: ProcesoFichas[]) => void,
    message: string,
    setMessage: (value: string) => void,
}

const ColaboradorObservacionContext = createContext<ColaboradorObservacionContextProps>({
    procesoFichaData: [],
    setProcesoFichaData: (value) => {},
    message: '',
    setMessage: (value) => {}
})

export {ColaboradorObservacionContext}