import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { PageTitle } from "../../../../../_metronic/layout/core"
import { Button, Col, Container, Form, Row, Stack, Tab, Tabs } from "react-bootstrap"
import { KTCard, KTIcon } from "../../../../../_metronic/helpers"
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Select from 'react-select';
import ReactQuill from "react-quill";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-quill/dist/quill.snow.css';
import './custom.css'
import { FilterList } from "./FilterList"
import { getProcessesForDropdown } from "../../../../services/procesos"
import { getSheetsByProcessForDropdown } from "../../../../services/fichas"
import { getFieldsBySheetDropdown } from "../../../../services/campos"
import { AlertDropdown } from "./Alert.interface"
import { getAdminUsers } from "../../../../services/users"
import { createAlert, getAlertById, updateAlert } from "../../../../services/alerts"
import { showMessage } from "../../../../shared/Alerts"

const fichasBreadcrumbs = [
    {
        title: 'Configuración',
        path: '/configuracion/alertas',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const AlertEdit = () => {

    const navigate = useNavigate()
    const { id } = useParams()
    const [title, setTitle] = useState('Nueva Alerta')
    const [value, onChange] = useState<Value>([new Date(), new Date()]);
    const [selectedDays, setSelectedDays] = useState<any>([]);
    const [selectedEmails, setSelectedEmails] = useState<any>([]);
    const [listProcessValidationError, setListProcessValidationError] = useState(false);
    const [bodyValue, setBodyValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [processList, setProcessList] = useState<AlertDropdown[]>([])
    const [sheetList, setSheetList] = useState<AlertDropdown[]>([])
    const [fieldList, setFieldList] = useState<AlertDropdown[]>([])
    const [processValue, setProcessValue] = useState()
    const [sheetValue, setSheetValue] = useState()
    const [processOwners, setProcessOwners] = useState([])
    const [description, setDescription] = useState<string>('')
    const [filters, setFilters] = useState<any>([])
    const [validationMessage, setValidationMessage] = useState('')
    const [isNewEmailButtonEnable, setNewEmailButtonStatus] = useState(false)
    const [newEmail, setNewEmail] = useState('')

    const dayList = [
        { value: 'lunes', label: 'Lunes' },
        { value: 'martes', label: 'Martes' },
        { value: 'miércoles', label: 'Miércoles' },
        { value: 'jueves', label: 'Jueves' },
        { value: 'viernes', label: 'Viernes' },
        { value: 'sábado', label: 'Sábado' },
        { value: 'domingo', label: 'Domingo' },
    ];

    useEffect(() => {
        if (id) {
            setTitle('Editar Alerta')
            getAlert(id)
        }

        getProcesses()
        getAdministrativeUsers()
    }, [])

    const getAlert = (id: string) => {
        getAlertById(id)
            .then((response) => {
                console.log(response)
                if (response.success) {
                    const data = response.data
                    setDescription(data.description)
                    setProcessValue(data.processId)
                    setSheetValue(data.sheetId)
                    setFilters(data.filters)
                    setBodyValue(data.bodyMessage)
                    setSelectedDays(data.days)
                    setSelectedEmails(data.emails)
                    onChange(data.dateRange)
                    getSheetsByProcess(data.processId)
                    getFieldsBySheet(data.sheetId)
                }
            })
            .catch((error) => console.log(error))
    }

    const goBack = () => {
        navigate('/configuracion/alertas')
    }

    const handleDaysChange = (selectedOptions: any) => {
        setSelectedDays(selectedOptions);
        setListProcessValidationError(selectedOptions.length === 0);
    };

    const handleEmailsChange = (selectedOptions: any) => {
        setSelectedEmails(selectedOptions);
    };

    const getProcesses = () => {
        setLoading(true)
        getProcessesForDropdown()
            .then((response) => {
                if (response.success) {
                    setProcessList(response.data)
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getAdministrativeUsers = () => {
        getAdminUsers()
            .then((response) => {
                setProcessOwners(response.data.map((x: any) => ({ label: x.nombre, value: x._id })))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getSheetsByProcess = (id: string) => {
        getSheetsByProcessForDropdown(id)
            .then((response) => {
                if (response.success) {
                    setSheetList(response.data)
                }
            })
            .catch((error) => { console.log(error) })
    }

    const getFieldsBySheet = (id: string) => {
        getFieldsBySheetDropdown(id)
            .then((response) => {
                if (response.success) {
                    setFieldList(response.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleProcessChange = (e: any) => {
        setProcessValue(e.target.value)
        getSheetsByProcess(e.target.value)
    }

    const handleSheetChange = (e: any) => {
        setSheetValue(e.target.value)
        getFieldsBySheet(e.target.value)
    }

    const handleDescriptionChange = (e: any) => {
        setDescription(e.target.value)
    }

    const handleSubmit = (e: any) => {

        e.preventDefault()

        const data = {
            description: description,
            processId: processValue,
            sheetId: sheetValue,
            filters: filters,
            bodyMessage: bodyValue,
            days: selectedDays,
            emails: selectedEmails,
            dateRange: value
        }

        if (id) {
            updateAlert(id, data)
                .then((response) => {
                    if (response.success) {
                        showMessage('success', '¡Buen trabajo!', response.message)
                        goBack()
                    } else {
                        showMessage('error', 'Hubo un error', response.error)
                    }
                })
                .catch((error) => {
                    showMessage('error', 'Hubo un error', error.message)
                })
        } else {
            createAlert(data)
                .then((response) => {
                    if (response.success) {
                        showMessage('success', '¡Buen trabajo!', response.message)
                        goBack()
                    } else {
                        showMessage('error', 'Hubo un error', response.error)
                    }
                })
                .catch((error) => {
                    showMessage('error', 'Hubo un error', error.message)
                })
        }

    }

    const handleFilterListChange = (e: any) => {
        setFilters(e)
    }

    const handleNewEmailInputChange = (e: any) => {
        const enteredEmail = e.target.value
        setNewEmail(enteredEmail)
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

        if (enteredEmail.trim() === '') {
            setValidationMessage('')
            setNewEmailButtonStatus(false)
        } else if (!emailRegex.test(enteredEmail)) {
            setValidationMessage('El email no es válido')
            setNewEmailButtonStatus(false)
        } else {
            setValidationMessage('')
            setNewEmailButtonStatus(true)
        }
    };

    const handleNewEmail = () => {
        setSelectedEmails([...selectedEmails, { value: newEmail, label: newEmail }]);
        setNewEmailButtonStatus(false)
        setNewEmail('')
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <>
            <PageTitle breadcrumbs={fichasBreadcrumbs}>{title}</PageTitle>
            <KTCard className="overflow-visible">
                {
                    loading ? <></> : <Form className='pt-12 pb-6' onSubmit={handleSubmit}>
                        <Container>
                            <Row>
                                <Col sm>
                                    <Form.Group controlId='formDescription'>
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control
                                            type='text'
                                            value={description}
                                            required
                                            placeholder='Ingrese un nombre'
                                            name='description'
                                            onChange={handleDescriptionChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Escriba un nombre para la alerta.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm>
                                    <Form.Group controlId='formProcess'>
                                        <Form.Label>Proceso</Form.Label>
                                        <Form.Control
                                            as='select'
                                            required
                                            name='process'
                                            value={processValue}
                                            onChange={handleProcessChange}
                                        >
                                            <option value=''>Seleccione un proceso</option>
                                            {
                                                processList.map(process => (
                                                    <option value={process.id} key={process.id}>
                                                        {process.name}
                                                    </option>))
                                            }
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Seleccione un proceso.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm>
                                    <Form.Group controlId='formDocument'>
                                        <Form.Label>Ficha</Form.Label>
                                        <Form.Control
                                            as='select'
                                            required
                                            name='document'
                                            value={sheetValue}
                                            onChange={handleSheetChange}
                                        >
                                            <option value=''>Seleccione una ficha</option>
                                            {
                                                sheetList.map(sheet => (
                                                    <option value={sheet.id} key={sheet.id}>
                                                        {sheet.name}
                                                    </option>))
                                            }
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Seleccione un documento.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <FilterList list={fieldList} data={filters} onChange={handleFilterListChange} />

                            <Row className='mt-10'>
                                <Col>
                                    <Stack direction="vertical">
                                        <h6>Datos</h6>
                                        <div>
                                            <Form.Label>Rango de Fechas</Form.Label>
                                            <DateRangePicker className={"h-40px custom-date-picker "} onChange={onChange} value={value} locale="es-ES" />
                                        </div>
                                        <Form.Group controlId='formDays' className="mt-3">
                                            <Form.Label>Días</Form.Label>
                                            <Select
                                                value={selectedDays}
                                                menuPortalTarget={document.body}
                                                menuPosition="fixed"
                                                isMulti
                                                name='days'
                                                options={dayList}
                                                className='basic-multi-select'
                                                classNamePrefix='select'
                                                placeholder='Seleccione'
                                                onChange={handleDaysChange}
                                            />
                                            {listProcessValidationError && <p style={{ color: '#B73A47', marginTop: '10px' }}>Seleccione al menos un día.</p>}
                                        </Form.Group>
                                    </Stack>
                                </Col>
                                <Col lg={{ span: 1 }}>
                                    <div className="vl"></div>
                                </Col>
                                <Col>
                                    <Stack direction="vertical">
                                        <h6>Remitente</h6>
                                        <div className="d-flex flex-row align-items-center">
                                            <Form.Control
                                                type='text'
                                                value={newEmail}
                                                placeholder='Nuevo correo'
                                                name='value'
                                                className="w-300px"
                                                onChange={handleNewEmailInputChange}
                                                onKeyDown={handleKeyDown}
                                            />
                                            <button
                                                type='button'
                                                className='btn btn-success ms-4'
                                                disabled={!isNewEmailButtonEnable}
                                                onClick={() => handleNewEmail()}
                                            >
                                                <KTIcon iconName='plus' className='fs-2' />
                                            </button>
                                        </div>
                                        {validationMessage && <div style={{ color: 'red' }}>{validationMessage}</div>}
                                        <Form.Group controlId='formEmail' className="mt-2">
                                            <Form.Label>Correos</Form.Label>
                                            <Select
                                                value={selectedEmails}
                                                menuPortalTarget={document.body}
                                                menuPosition="fixed"
                                                isMulti
                                                name='emails'
                                                options={processOwners}
                                                className='basic-multi-select'
                                                classNamePrefix='select'
                                                placeholder='Seleccione'
                                                onChange={handleEmailsChange}
                                            />
                                            {listProcessValidationError && <p style={{ color: '#B73A47', marginTop: '10px' }}>Seleccione al menos un email.</p>}
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Stack direction="vertical">
                                    <h6>Cuerpo</h6>
                                    <Form.Label>Mensaje</Form.Label>
                                    <ReactQuill
                                        theme="snow"
                                        value={bodyValue}
                                        onChange={setBodyValue}
                                        placeholder="Escriba un mensaje..."
                                        style={{ height: '150px', marginBottom: '20px' }}
                                    />
                                </Stack>
                            </Row>
                            <Row className="mt-13">
                                <div className='d-flex justify-content-end'>
                                    <Button
                                        variant='light'
                                        className='mx-3'
                                        type='reset'
                                        onClick={() => {
                                            goBack()
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button variant='primary' type='submit'>
                                        {!loading && 'Guardar'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Guardando...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </Button>
                                </div>
                            </Row>
                        </Container>
                    </Form>
                }
            </KTCard>
        </>
    )
}

export { AlertEdit }