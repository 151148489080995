import { useContext } from "react"
import { TableSearch } from "../../../../shared/TableSearch"
import { SheetContext } from "../../fichas/SheetContexts"



const ValidationListHeader = () => {
  const {setSearchTerm} = useContext(SheetContext)

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }

  return (
    <div className='card-header border-0 pt-6'>

      <TableSearch onSearch={handleSearch}/>
    
    </div>
  )
}

export {ValidationListHeader}
