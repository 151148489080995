import React from 'react'
import {JsonToTableViewer} from '../JsonToTableViewer'
import {FieldTitle} from './FieldTitle'
import {FieldValue} from './FieldValue'

interface ModernFieldTableProps {
  title: string
  object: any
}

const ModernFieldTable: React.FC<ModernFieldTableProps> = ({title, object}) => {
  return (
    <div className='d-flex flex-column gap-3 p-5'>
      <FieldTitle>{title.toLocaleUpperCase()}</FieldTitle>
      <div>
        {object != null ? <JsonToTableViewer data={object} /> : <FieldValue>Sin valor</FieldValue>}
      </div>
    </div>
  )
}

export {ModernFieldTable}
