/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchConToken } from "../../helpers/fetch";
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import { DataUser } from '../../modules/documentacion/MiFicha/DataUserInterface';
import { useAuth2 } from '../../modules/authv2/Auth2Provider';
import { ReactComponent as BannerSvg } from '../../../assets/banner.svg'
import './Banner.css';

const DashboardPage: FC = () => (
  <div className="banner-container">
    <BannerSvg className="banner-svg" />
  </div>
)

interface MyData {
  token: string;
  captcha: string;
  // Otros campos que se esperan recibir en la solicitud POST
}

const DashboardWrapper: FC = () => {

  /* const { setUserData } = useAuth2()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    console.log("vist / dasboard")
    if (token) {
      localStorage.setItem('authToken', token);
      searchParams.delete('token');
      const newUrl = `${location.pathname}`;

      window.history.replaceState({}, '', newUrl);

      fetchProfile(token)
        .then((response) => {
          localStorage.setItem('userData', JSON.stringify(response))
          setUserData(response)
          window.location.reload();
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  const fetchProfile = async (token: string) => {
    const url = "auth/profile"
    const resp = await fetchConToken(url, {}, 'GET', token)
    const body = await resp.json();

    return body;
  } */

  const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle> */}
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
