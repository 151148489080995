import React, { useEffect, useState } from 'react'
import { tipoxid } from '../../../../../../services/tipos'

export const SeccionTipo = ({tipo}) => {

    const [nombre, setNombre] = useState('')

    useEffect(() => {
        tipoxid(tipo).then((response) => {
            if(response?.success){
                setNombre(response?.data[0].nombre)
            } else {
                console.log("error al traer Tipos")
            }
                        
        }).catch(error => {
            console.log(error)
        })
    }, [])
    
  return (
    <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>{nombre}</td>
  )
}
