import { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Select from 'react-select'
import { getAllUsersPag, getAllUsersAsignacionSinPag, getRoles, updateUserData, updateUserEnterpricesAndRols } from '../../../../services/users'
import { showMessage, showValidatePassword } from '../../../../shared/Alerts'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import { PartnerContext } from '../PartnerContexts'
import { GrupoAprobacion, valuesGrupoAprobacion } from '../GrupoAprobacionInterface'
import { KTCard, KTIcon } from '../../../../../_metronic/helpers'
import { AsignacionTablaTable1 } from '../components/AsignacionTablaTable1'
import { AsignacionTablaTable2 } from '../components/AsignacionTablaTable2'
import { getUsers } from '../../../apps/user-management/users-list/core/_requests'
import { crearGrupoAprobaciónData, updateGrupoAprobaciónData } from '../../../../services/grupoAprobacion'


const EditGrupoModalForm = () => {
  const {userData : dataAuth} = useAuth2()
  const {setEditOpenShowModal,setReloadTable, grupoAprobacionData} = useContext(PartnerContext)

  const [dataGrupoAprobacion, setDataGrupoAprobacion] = useState<GrupoAprobacion>(grupoAprobacionData)
  const [loading, setLoading] = useState(false)
  const [listDataApi1Aux, setListDataApi1Aux] = useState<any[]>([]);
  const [listDataApi1, setListDataApi1] = useState<any[]>([]);
  const [checkedItem1, setCheckedItem1] = useState<any[]>([]);
  const [listDataApi2, setListDataApi2] = useState<any[]>([])
  const [checkedItem2, setCheckedItem2] = useState<any[]>([]);
  const [actualizarData,setActualizarData] = useState(false)
  let cont = 0

  useEffect(()=>{
    refetchData1()
  },[])


  useEffect(()=>{
    setActualizarData(true)
    if(listDataApi1Aux.length>0 && listDataApi2.length>0 && actualizarData ){
      const filteredArray:any[] = listDataApi1Aux.filter(item1 =>
        !listDataApi2.some(item2 => item2._id === item1._id)
      );
      // esto es para asegurarse de que entre 5 veces a actualizar, por cuestion de time en los state
      cont++
      if(cont > 5){
        setActualizarData(false)
      }
      
      setListDataApi1([...filteredArray]);
    }

    if(listDataApi2.length === 0 && dataGrupoAprobacion.responsables.length>0){
      asignarDefault()
    }
  },[listDataApi1Aux,listDataApi2])

  const asignarDefault = () =>{
    const DataNew2 = listDataApi1Aux.filter((ele:any) => {
      if(dataGrupoAprobacion.responsables.includes(ele._id)){
        return {...ele,check_selected:false}
      }
    })
    setListDataApi2([...listDataApi2,...DataNew2])
  }

  const refetchData1 = async () => {
    await getAllUsersAsignacionSinPag()
      .then((response) => {
        if (response.success) {
          const DataNew1 = response?.data.map((ele:any) => {
            return {...ele,check_selected:false}
          })          

          setListDataApi1(DataNew1)
          setListDataApi1Aux(DataNew1)


        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleChange = (e:any)=>{
    setDataGrupoAprobacion({...dataGrupoAprobacion,[e.target.name]: e.target.value})
  }
  const handleDesasignar = async () =>{
    const DataNew2 = listDataApi2.filter((ele:any) => {
      if(!checkedItem2.includes(ele._id)){
        return {...ele,check_selected:false}
      }
    })
    setDataGrupoAprobacion({...dataGrupoAprobacion,responsables: DataNew2.map(r => (r._id))})
    setListDataApi2([...DataNew2])
  }
  
  const handleAsignar = async () =>{
    const DataNew2 = listDataApi1.filter((ele:any) => {
      if(checkedItem1.includes(ele._id)){
        return {...ele,check_selected:false}
      }
    })
    setDataGrupoAprobacion({...dataGrupoAprobacion,responsables: [...listDataApi2,...DataNew2].map(r => (r._id))})
    setListDataApi2([...listDataApi2,...DataNew2])
  }

  const handleActualizar = async() => {
    setLoading(true)
    updateGrupoAprobaciónData(dataGrupoAprobacion._id,dataGrupoAprobacion)
      .then((response) => {
        if (response.success) {
          showMessage("success","Actualizado",response.message)
          setEditOpenShowModal(false)
          setReloadTable(true)
        } else {
          console.log(response)
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  return (
    <>
      <Form>
        <Container>
          <Row className='mb-3'>
            <Col sm>
              <Form.Group controlId='formNombre'>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Ingrese un nombre'
                  name='nombre'
                  onChange={handleChange}
                  value={dataGrupoAprobacion.nombre}
                />
              </Form.Group>
            </Col>
            <Col sm>
              <Form.Group controlId='formNombre'>
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Ingrese un descripcion'
                  name='descripcion'
                  onChange={handleChange}
                  value={dataGrupoAprobacion.descripcion}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs={5} lg={5} xl={5} sm>    
              <KTCard>
                <AsignacionTablaTable1 tbody={listDataApi1} onCheckSelect={(_id:string[]) => setCheckedItem1(_id)}  />
              </KTCard>
            </Col>
            <Col className='d-flex flex-column ' xs={2} lg={2} xl={2} sm> 
              <Button className='btn btn-primary mt-10 ' onClick={handleAsignar} >
                Asignar 
                <KTIcon iconName='arrow-right' className='fs-2 mx-1' />
              </Button>
              
              <Button className='btn btn-secondary mt-3' onClick={handleDesasignar} >
                <KTIcon iconName='arrow-left' className='fs-2 mx-1' />
                Desasignar 
              </Button>
            </Col>
            <Col xs={5} lg={5} xl={5}  sm>
              <KTCard>
                <AsignacionTablaTable2 tbody={listDataApi2} onCheckSelect={(_id:string[]) => setCheckedItem2(_id)} />
              </KTCard>
            </Col>
          </Row>

          <Row>
            <div className='d-flex justify-content-end my-3'>
              <Button
                variant='light'
                className='mx-3'
                type='reset'
                onClick={() => {
                  setEditOpenShowModal(false)
                }}
              >
                Cancelar
              </Button>
              <Button
                variant='primary'
                type='button'
                onClick={() => {
                handleActualizar()
                }}
              >
                {!loading && 'Actualizar'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Subiendo...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </Button>
            </div>
          </Row>
        </Container>
      </Form>
    </>
  )
}

export { EditGrupoModalForm }

