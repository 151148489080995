import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useTable } from 'react-table'
import { KTCardBody, KTIcon } from '../../../../../_metronic/helpers'
import Pagination from '../../../../shared/Pagination'
import { UsersListLoading } from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import {
  useQueryResponseData,
} from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import { usersColumns } from '../../../apps/user-management/users-list/table/columns/_columns'
/* import {ListFichas} from './ListFichas' */
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from '../../../../services/menu'
import { getProceso } from '../../../../services/procesos'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import { DataUser } from '../../../documentacion/MiFicha/DataUserInterface'
import { Proceso } from '../documentInterface'
import { PartnerContext } from '../PartnerContexts'
import { ListDocuments } from './ListDocuments'

const PartnersTable = (props: any) => {
  const navigate = useNavigate()
  const users = useQueryResponseData()
  // const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  
  const usuarioJSON = localStorage.getItem('userData');
  let usuario: DataUser|null=null;
  
  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON);
  }

  const { searchTerm,searchDocument,setDocuments,documentsAux,setProcesoData,openTagsModal,setProcessId } = useContext(PartnerContext)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [isLoading, setLoadingStatus] = useState(false)
  const [documentoaux, setDocumentoaux] = useState(documentsAux)

  const [show, setShow] = useState(false)
  const [selecto, setSelecto] = useState(0)
  const [procesosdata, setProcesosData] = useState([])
  const [updated, setUpdated] = useState(false)
  const [meliminar, setMeliminar] = useState(false)
  const [eliminar, setEliminar] = useState('')
  const [mactualizar, setMactualizar] = useState(false)
  const [actualizar, setActualizar] = useState('')
  const [listafichas, setListaFichas] = useState(0)
  const { selectedRole } = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])
  const location = useLocation()

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu = response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  
  useEffect(() => {
    setDocumentoaux(documentsAux)
  }, [documentsAux])

  useEffect(() => {
    setLoadingStatus(true);
    setTimeout(() => {
      refetchData({
        nombre: searchTerm,
        estado: "A",
        responsables: usuario?.userId
      })
    }, 1000)
  }, [currentPage,searchTerm])

  useEffect(() => {
    const newDocument : { [type_document: string]: any[] } = documentoaux
    const newResultado: { [type_document: string]: any[] } = {};
    console.log("newDocument")
    console.log(newDocument)
    Object.keys(newDocument).map(key => {
      newResultado[key] = []; 
      newDocument[key].map((item) => {
        if(item.name.toLowerCase().includes(searchDocument.toLowerCase()) || item.type.toLowerCase().includes(searchDocument.toLowerCase())){
          newResultado[key].push(item)
        }
      })

    })
    console.log("newResultado")
    console.log(newResultado)
    /* const filteredFields:any[] = documentsAux.filter((item:any) =>
            item.name.toLowerCase().includes(searchDocument.toLowerCase()) || item.type.toLowerCase().includes(searchDocument.toLowerCase())
          ) */
    setDocuments(newResultado)
  }, [searchDocument])


  const refetchData = (options: any) => {
    setLoadingStatus(true);
    //options= searchTerm?options:{}
    getProceso(currentPage,10,options).then((response) => {
      if (response.success) {
        setLoadingStatus(false);
        const auxTotalPage = response.length? Math.ceil(response.length/10): totalPages
        setTotalPages(auxTotalPage)
        setProcesosData(response.data)
        MenuComponent.reinitialization()
      } else {
        console.log(response.msg)
      }
    })
    setUpdated(false)
    setEliminar('')
  }

  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []
    // Generate "Previous" link if not on the first page
    if (currentPage > 1) {
      links.push({page: currentPage - 1, label: 'Anterior'})
    }

    // Generate numbered pages
    for (let i = 1; i <= totalPages; i++) {
      links.push({page: i, label: String(i)})
    }

    // Generate "Next" link if not on the last page
    if (currentPage < totalPages) {
      links.push({page: currentPage + 1, label: 'Siguiente'})
    }

    return links
  }
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }


  const handleSelecto = (opt: any) => {
    if (opt === selecto) {
      setSelecto(0)
    } else {
      setSelecto(opt)
    }
  }

  const handleEliminar = (id: any) => {
    setMeliminar(!meliminar)
    setEliminar(id)
  }

  const handleActualizar = (dataup: any) => {
    setMactualizar(!mactualizar)
    setActualizar(dataup)
  }

  const handleShowEtiquetas = (id:any) =>{
    setProcessId(id)
    openTagsModal()
  }

  const handleNew = (id: any,data:Proceso) => {
    setProcesoData(data)
    navigate(`/configuracion/config_documentos/edit/${id}`)
  }

  const handleAsignarFichas = (id: any) => {
    if (listafichas === id) {
      setListaFichas(0)
    } else {
      setListaFichas(id)
    }
  }

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th className=''></th>
                <th className='text-start'>Nombre</th>
                <th className='text-start'>Descripción</th>
                <th className='text-start'></th>
                <th className='text-end'>Accioness</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {/* {rows.length > 0 ? (
              rows.map((row: Row<User>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )} */}
              {procesosdata.length > 0 ? (
                procesosdata.map((item: any) => (
                  <Fragment key={item._id}>
                    <tr>
                      <td onClick={() => handleSelecto(item._id)}>
                        <a href='# ' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {
                            selecto === item._id ? 
                              <i className="bi bi-arrow-up"></i>                            
                            :
                              <i className="bi bi-arrow-right"></i>
                          }
                          
                        </a>
                      </td>
                      <td className='text-start min-w-200px'>{item.nombre}</td>
                      <td className='text-start min-w-200px'>{item.descripcion}</td>
                      <td className='text-start min-w-200px'></td>
                      <td className='text-end'>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                        {permissions.includes('Etiquetas Proceso') && (
                        <a
                          href='#'
                          onClick={() => handleShowEtiquetas(item._id)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTIcon iconName='text-align-justify-center' className='fs-3' />
                        </a>
                        )}
                        {permissions.includes('Editar Proceso') && (
                                     <a
                          href='#'
                          onClick={() => handleNew(item._id,item)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTIcon iconName='plus' className='fs-3' />
                        </a>
                        )}
                        {/* <a
                          href='#'
                          onClick={() => handleActualizar(item)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTIcon iconName='pencil' className='fs-3' />
                        </a>
                        <a
                          href='#'
                          onClick={() => handleEliminar(item)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTIcon iconName='trash' className='fs-3' />
                        </a> */}
                      </div>
                      </td>
                    </tr>
                    {selecto === item._id && <ListDocuments id={selecto} />}

                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        
        <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export { PartnersTable }

