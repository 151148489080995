import React, {useEffect, useState} from 'react'
import {Form, ListGroup} from 'react-bootstrap'
import {showInfoCopy} from '../../../../shared/Alerts'
import {ListTags} from '../documentInterface'

interface MyProps {
  List: ListTags[]
}

const TagsListGroup: React.FC<MyProps> = ({List}) => {
  const [searchQuery, setSearchQuery] = useState('')

  const [inputList, setInputList] = useState(List)

  useEffect(() => {
    setInputList(List)
  }, [List])
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleItemClick = (textToCopy: string) => {
    const textField = document.createElement('textarea')
    textField.innerText = textToCopy
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    showInfoCopy('info', 'Etiqueta copiado al portapapeles: ', textToCopy)
  }

  // Set a maximum height for the ListGroup container
  const maxListHeight = '300px'

  return (
    <>
      <Form.Group>
        <Form.Control
          type='text'
          placeholder='Buscar...'
          value={searchQuery}
          onChange={handleSearchChange}
          className='my-2'
        />
      </Form.Group>
      <div style={{maxHeight: maxListHeight, overflowY: 'scroll'}}>
        {inputList.map((group) => {
          const filteredFields = group.fields.filter((item) =>
            item.label.toLowerCase().includes(searchQuery.toLowerCase())
          )

          if (filteredFields.length === 0) {
            return null
          }

          return (
            <>
              <h4 className='my-3 ms-2 fw-bold'>{group.label}</h4>
              <ListGroup>
                {filteredFields.map((item) => (
                  <>
                    <ListGroup.Item
                      key={item.identifier}
                      style={{cursor: 'pointer'}}
                      onClick={() => handleItemClick(item.tags)}
                    >
                      <div className='row'>
                        <div className='col-md-6'>
                          <p>{item.tags}</p>
                        </div>
                        <div className='col-md-6'>
                          <p>{item.label}</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                  </>
                ))}
              </ListGroup>
            </>
          )
        })}
      </div>
    </>
  )
}

export default TagsListGroup
