import { useEffect, useState } from 'react'
import { KTCard, KTIcon } from '../../../../../_metronic/helpers'
import { PageTitle } from '../../../../../_metronic/layout/core'
import {
  Campos,
  DataTabla,
  Ficha,
  Historial,
  ProcesoFichas,
  Seccion,
  Tabla,
  tablaData,
} from '../../MiFicha/FIchaInterface'
import RegistroFichaPage from '../../MiFicha/FichaPage'
import { InfoRequerida } from '../../MiFicha/components/InfoRequerida'
import { getProcesoFichas } from '../../../../services/procesos'
import { PartnerSheet } from '../../MiFicha/components/PartnerSheet'
import { UsuarioHeader } from '../../MiFicha/FichaHeader'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getRegistroFichaOne, nuevaObservacionFicha, nuevoRegistroFichaTotal } from '../../../../services/registroFicha'
import { PartnerViewer } from '../../MiFicha/components/PartnerViewer'
import { HistorialMessageModel, defaultMessages } from '../../../configuracion/colaboradores/components/observacion/HistorialMessageModel'
import { ChatInner } from '../components/ChatInner'
import { Button, Row } from 'react-bootstrap'
import { DataUser } from '../../MiFicha/DataUserInterface'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import { ColaboradorObservacionContext } from '../components/ColaboradorObservacionContexts'
import { showMessage } from '../../../../shared/Alerts'
import { GoBackButton } from '../../../generales_component/GoBackButton'

const fichasBreadcrumbs = [
  {
    title: 'Documentos',
    path: '/documentos/procesos',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProcessEdit = ({ editable }: any) => {
  const [isLoading, setLoadingStatus] = useState(false)
  const [procesos, setProcesos] = useState<ProcesoFichas[]>([])
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [activeTab, setActiveTab] = useState(0)
  const [historialData, setHistorialData] = useState<Historial[]>([])
  const [historialMessage, setHistorialMessage] = useState<HistorialMessageModel[]>([])
  const [idUsuarioRegistro, setIdUsuarioRegistro] = useState<string>('')
  const [procesoFichaData, setProcesoFichaData] = useState<ProcesoFichas[]>([])
  const [estatusFicha, setEstatusFicha] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [filtro, setFiltro] = useState<Record<string, any> | null>(null)

  const {id} = useParams()
  const usuarioJSON = localStorage.getItem('userData');
  let usuario: DataUser | null = null;

  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON);
  }
  const { selectedRole } = useAuth2()
  const idusuario = usuario?.userId
  const nameusuario = usuario?.userName
  const rolusuario = selectedRole
  const navigate = useNavigate()

  const Ruta = [
    {
      path: 'inforequerida',
      link: '/documentos/ficha/inforequerida',
    },
    {
      path: 'documentacionrequerida',
      link: '/documentos/ficha/documentacionrequerida',
    },
  ]

  useEffect(() => {
    setLoadingStatus(true)
    if (id) {
      refetchData(id)
    }
  }, [])

  useEffect(() => {
    console.log(activeTab)
  }, [activeTab])

  const refetchData = (idRegistroFicha:string) => {
    setLoadingStatus(true)
    getRegistroFichaOne(idRegistroFicha)
      .then((response) => {
        if (response.success) {
          setLoadingStatus(false)
          
            const dataWithValuesAndFiles:ProcesoFichas[] = response?.data[0]?.procesoficha.map((procesoFicha:ProcesoFichas, item:any) => ({
              ...procesoFicha,
              fichas:procesoFicha.fichas.map((ficha:Ficha) => ({ 
                ...ficha,
                seccion:ficha.seccion.map((seccion:Seccion) => ({
                  ...seccion,
                  campos: seccion.campos.map((campo:Campos) => ({
                    ...campo,
                    values: campo.values?campo.values:'',
                    valuesAux: campo.values?campo.values:'',
                    observacion: campo.observacion?campo.observacion:{},
                    observado: campo.observado?campo.observado:false,
                    observadoAux: campo.observado?campo.observado:false,                                     
                    valuesFiles: campo.valuesFiles?campo.valuesFiles:[],
                    selectOpion:campo.selectOpion?campo.selectOpion:null,
                    hasError: campo.hasError?campo.hasError:false,
                    dependiente: campo.dependiente?campo.dependiente:false,
                    campoDependiente: campo.campoDependiente?campo.campoDependiente:"",
                    respuestaCampoDependiente: campo.respuestaCampoDependiente?campo.respuestaCampoDependiente:"",
                    mostrar: campo.mostrar?campo.mostrar:campo.dependiente?false:true,
                    tablaData: campo.tablaData.map((tablaData:tablaData) => ({
                      ...tablaData,
                      option: tablaData.value.map((val:any) => ({
                        value: val.id?val.id:'',
                        label: val.name?val.name:''
                      }))
                    }))
                  })),
                  
                  secciones:seccion.secciones?seccion.secciones.map((tabla:Tabla) => ({
                    ...tabla,
                    data: tabla.data.map((dattabla:DataTabla) => ({
                      ...dattabla,
                      values: dattabla.values?dattabla.values:'',
                      valuesFiles: dattabla.valuesFiles?dattabla.valuesFiles:[],
                      selectOpion:dattabla.selectOpion?dattabla.selectOpion:null,
                      hasError: dattabla.hasError?dattabla.hasError:false,
                      tablaData: dattabla.tablaData? dattabla.tablaData.map((dat:tablaData) => ({
                        ...dat,
                        option: dat.value.map((val:any) => ({
                          value: val.id?val.id:'',
                          label: val.name?val.name:''
                        }))
                      })) : []
                    }))
                  })):[],


                })),
              })),
              proceso:response?.data[0]?.proceso
              
            }));
            dataWithValuesAndFiles.map((procesoFicha:ProcesoFichas, item:any) => {
              procesoFicha.fichas.map((ficha:Ficha) => { 
                ficha.seccion.map((seccion:Seccion) => {
                  let visible = false
                  seccion.campos.map((campo:Campos) => { 
                    if(campo.visible){
                      visible = true
                    }
                  })
                  seccion.visible = visible
                })
              })
            })
            setIdUsuarioRegistro(response?.data[0]?.id_user_creador)
            
            const historial:Historial[] = response?.data[0]?.historial
            setFiltro(response?.data[0]?.filtro ?? null)
            setHistorialData(historial)
            setProcesoFichaData(dataWithValuesAndFiles)
            setEstatusFicha(response?.data[0]?.estado)

            const historialMensaje : HistorialMessageModel[] = []
            response?.data[0]?.historial?.map((dat:Historial) => {
              if(dat.motivo === 'OBSERVADO'  && dat.data){
                historialMensaje.push(dat.data)
              }
            })
            setHistorialMessage(historialMensaje)
          
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleFirstSubmit = (e:any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }else{
      handleSubmit(e,'fichaObservada')
    }
    setLoading(true)
    setValidated(true)
  };

  const handleSubmit = (e: any,estado:string) => {
    e.preventDefault()

    handleUpdateSheet(procesoFichaData,historialData,estado)

  }

  const handleUpdateSheet = (dataProcesos: ProcesoFichas[], dataHistorial: Historial[], estado:string) => {
    
    dataHistorial.push({
        createdAt: new Date(),
        motivo: 'OBSERVADO',
        id_user_creador: String(idusuario),
        id_user_receptor: String(dataProcesos[0].proceso?.[0].responsables.join(',')),
        data:{
          nameuser: String(nameusuario),
          iduser: String(idusuario),
          text: message?message:"-",
          fecha: new Date()
        },
        camposModificados:[]
    })

    const data = {
      _id:id,
      id_user_creador:idUsuarioRegistro,
      procesoficha:dataProcesos,
      historial:dataHistorial,
      rol: rolusuario,
      vista:"preenrolamiento",
      filtro
    }
    

    nuevaObservacionFicha(data)
      .then((response) => {
        if (response?.success) {  
          setLoading(false)          
          const historialMensaje : HistorialMessageModel[] = []
          response.data.historial.map((dat:Historial) => {              
              if(dat.motivo === 'OBSERVADO' && dat.data){
                historialMensaje.push(dat.data)
              }
          })
          setMessage('')
          setHistorialMessage(historialMensaje)
          showMessage('success', '¡Buen trabajo!', 'Se actualizo la información correctamente')  
          
        } else {
          setLoading(false)
          showMessage('error', 'Hubo un error', response.message)
          console.log(response?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        showMessage('error', 'Hubo un error', error)
        console.log(error)
      })
  }

  const valueContextProvider = {
    procesoFichaData,
    setProcesoFichaData,
    message,
    setMessage
  }

  const goBack = () => {
    const link = selectedRole === 'POST' ? '/documentos/proceso' : '/seguimiento/colaboradores'
    navigate(link)
  }

  return (
    <ColaboradorObservacionContext.Provider value={valueContextProvider}>
      <PageTitle breadcrumbs={fichasBreadcrumbs}>
        {editable ? 'Editar Ficha' : 'Visualizar Ficha'}
      </PageTitle>
      <GoBackButton goBack={goBack} />
      <>
        {/* <RegistroFichaPage /> */}

        <div className='card mb-5 '>
          <div className='card-body pt-5 pb-0'>
            <div className='d-flex overflow-auto h-20px mb-2'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                {procesoFichaData.map((procesoficha, i) => (
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` + (i === activeTab && 'active')
                      }
                      to={``}
                      onClick={() => setActiveTab(i)}
                    >
                      {procesoficha.fichas[0].nombre}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {editable ? (
          <>
            <InfoRequerida
              num={activeTab}
              urlRef={'/documentos/proceso'}
              idRegistroFicha={String(id ? id : '')}
              procesoFicha={procesoFichaData}
              estatusFicha={estatusFicha}
              historial={historialData}
              onUpdate={function (updatedData: any): void {
                setActiveTab(updatedData)
              }}
              filtro={filtro}
            />

            <div className='flex-lg-row-fluid mt-2'>
              <div className='card' id='kt_chat_messenger'>
                <div className='card-header bg-secondary' id='kt_chat_messenger_header'>
                  <div className='card-title'>
                    <div className='symbol-group symbol-hover'></div>
                    <div className='d-flex justify-content-center flex-column me-3'>
                      <div className='mb-0 lh-1'>
                        <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                        <a href='#' className='fs-4 fw-bolder  me-1 mb-2 lh-1'>
                          Historial Observación
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='card-toolbar'></div>
                </div>
                <ChatInner
                  idUsuario={String(idusuario)}
                  idNameUsuario={String(nameusuario)}
                  historialMessage={historialMessage}
                />
              </div>
            </div>

            <Row>
              <div className='d-flex justify-content-end mt-4'>
                <Button variant='light' className='mx-3' type='reset' onClick={() => {}}>
                  Cancelar
                </Button>
                <Button
                  variant='primary'
                  type='submit'
                  onClick={handleFirstSubmit}
                  className='mx-3'
                >
                  {!loading && 'Enviar Observación'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Guardando...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </Button>
              </div>
            </Row>
          </>
        ) : (
          // <PartnerSheet
          //   num={activeTab}
          //   procesoFicha={procesoFichaData}
          //   estatusFicha={''}
          //   historial={[]}
          //   onUpdate={function (updatedData: any): void {
          //     setActiveTab(updatedData)
          //   }}
          // />
          <PartnerViewer data={procesoFichaData} activeTab={activeTab} />
        )}
      </>
    </ColaboradorObservacionContext.Provider>
  )
}

export { ProcessEdit }
