import {fetchConToken, fetchSinToken} from '../helpers/fetch'

const getAllPartners = async (options: any, token: string) => {
  const queryString = new URLSearchParams(options).toString()

  const resp = await fetchConToken(`colaborador?${queryString}`, {}, 'GET', token)

  const body = await resp.json()

  return body
}

const getAllPartnersSelect = async (options: any) => {
  const queryString = new URLSearchParams(options).toString()

  const resp = await fetchSinToken(`colaborador/list/user_dni?${queryString}`, {}, 'GET')

  const body = await resp.json()

  return body
}

const getAllPartnersPag = async (currentPage: any, numberData: any, options: any) => {
  const queryString = new URLSearchParams(options).toString()

  const resp = await fetchConToken(
    `colaborador/historialdocument/${currentPage}/${numberData}?${queryString}`,
    {},
    'GET'
  )

  const body = await resp.json()

  return body
}

const deletePartner = async (id: string) => {
  const resp = await fetchSinToken(`colaborador/${id}`, {}, 'DELETE')
  const body = await resp.json()

  return body
}

const updatePartner = async (id: string, updatedData: any) => {
  const resp = await fetchSinToken(`colaborador/${id}`, updatedData, 'PUT')
  const body = await resp.json()

  return body
}

const getPartnersForValidation = async () => {
  const resp = await fetchSinToken('colaborador/validationList', {}, 'GET')

  const body = await resp.json()

  return body
}

const approvePartner = async (options: any) => {
  const queryString = new URLSearchParams(options).toString()

  const resp = await fetchSinToken(`colaborador/approve?${queryString}`, {}, 'POST')

  const body = await resp.json()

  return body
}

const getAllAprovedPartners = async (options: any) => {
  const qs = new URLSearchParams(options).toString()
  const resp = await fetchConToken(`colaborador/approved_collaborators?${qs}`, {}, 'GET')
  return await resp.json()
}

export {
  approvePartner,
  deletePartner,
  getAllAprovedPartners,
  getAllPartners,
  getAllPartnersPag,
  getAllPartnersSelect,
  getPartnersForValidation,
  updatePartner,
}
