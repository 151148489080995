import React, {useEffect, useState} from 'react'
import {Col} from 'react-bootstrap'

export const SeccionOpciones = ({show, setOpciones, opciones}) => {
    const [currentopc, setCurrentOpc] = useState([])

  useEffect(() => {
    console.log('look', show)
    console.log(opciones)
  }, [show])

  const handleOpcion = () => {
    const index = opciones?.length
    setOpciones([...opciones, {id: index, nombre: currentopc}])
  }

  return (
    <>
      {show && (
        <>
          <Col>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>Opciones</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Ingresa una opcion para la Lista Desplegable'
                ></i>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='opcion'
                placeholder='Ingresa una opcion'
                autoComplete='off'
                onChange={(e) => setCurrentOpc(e.target.value)}
              />
              <button
                type='submit'
                className='btn btn-sm btn-primary'
                data-kt-stepper-action='next'
                style={{marginRight: 5}}
                onClick={() => handleOpcion()}
              >
                +
              </button>
              <button type='submit' className='btn btn-sm btn-danger' data-kt-stepper-action='next'>
                -
              </button>
            </div>
          </Col>
        </>
      )}
    </>
  )
}
