import {useContext, useState,useEffect, FC} from 'react'
import {Droppable, Draggable} from 'react-beautiful-dnd'
import {useAccordionButton} from 'react-bootstrap/AccordionButton'
import {Card, Accordion, Badge} from 'react-bootstrap'
import {title} from 'process'
import Collapsible from 'react-collapsible'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {PartnerContext} from '../../../PartnerContexts'
import {DraggableFieldList} from './DraggableFieldList'
import { Seccion } from '../../../../../documentacion/MiFicha/FIchaInterface'
import { DraggableSectionList } from './DraggableSectionList'
import { getCompleteSheet } from '../../../../../../services/fichas'
import { Field, Section, Sheet } from '../../../../fichas/Sheet'
import { FieldsIngracion, ListFieldsIngracion } from '../../../../documento/documentInterface'

const grid = 3

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // Some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // Change background color if dragging
  background: isDragging ? '#18c073' : 'white',
  borderRadius: 10,
  // Styles we need to apply on draggables
  ...draggableStyle,
})

interface FichaAux {label: string, value: string}

interface MyComponentProps {
  item: FichaAux;
  ind:number;
  index:number;
  onUpdate: (updatedData: any) => void;
}


const CollapsibleSheetItem : FC<MyComponentProps> = (props) => {
  let {item,ind,index,onUpdate} = props
  const [formDataSheet, setFormDataSheet] = useState(item);
  const [sheet, setSheet] = useState<Sheet>({name:"",type: "",description: "",sections: []});
  const [listFieldsIngracion,setListFieldsIngracion] = useState<ListFieldsIngracion[]>([])


  useEffect(() => {
    setFormDataSheet(item)
    getProcessSheet(item.value)
  }, [item])

  useEffect(() => {
    // getSheet()
  }, [sheet])

  const getProcessSheet = async (id:string) =>{
    await getCompleteSheet(id)
    .then((response) => {
        setSheet(response.data)
    })
    .catch((error) => console.log(error))
}

const handleUpdateSeccion = (ind:number,updatedData:Section) => {
  let indSeccion = 0
  onUpdate(updatedData);
};
  

  return (
    <>
      {
        <Draggable draggableId={`She`+formDataSheet.value} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            >
              <div style={{position: 'relative'}}>
                <div {...provided.dragHandleProps} style={{position: 'absolute', top: '0', left: '0'}}>
                  <Badge
                    bg='success-active'
                    className='me-2'

                    style={{transform: 'translateY(-2px)', color: 'white'}}
                  >
                    {index + 1}
                  </Badge>
                </div>
                
                <Collapsible transitionTime={200} open={true} easing='ease' trigger={formDataSheet.label} triggerStyle={{marginLeft: 30, fontWeight:'bolder'}}>
                  <div className='mt-3 mx-10'>
                    <DraggableSectionList
                        key={formDataSheet.value}
                        sheet={sheet}
                        ind={ind}
                        index={index}
                        onUpdate={(updatedData) => handleUpdateSeccion(index, updatedData)}
                      />
                  </div>
                </Collapsible>

              </div>
            </div>
          )}
        </Draggable>

    }
    </>
    
    
  )
}

export {CollapsibleSheetItem}
