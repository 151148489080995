import React from 'react'
import {Button} from 'react-bootstrap'
import {ButtonVariant} from 'react-bootstrap/esm/types'
import {KTIcon} from '../../../_metronic/helpers'

interface Props {
  goBack: () => void
  variant?: ButtonVariant
}

const GoBackButton: React.FC<Props> = ({goBack, variant = 'link'}) => {
  return (
    <div className='my-2'>
      <Button variant={variant} onClick={goBack}>
        <KTIcon className='fs-4' iconName='arrow-left' />
        Regresar
      </Button>
    </div>
  )
}

export {GoBackButton}
