import React, { useState, useEffect } from 'react';

interface HeaderProps {
  label: string;
  columnName: string;
  handleSort: (columnName: string, sortDirection: string) => void;
  activeColumn: string;
  style?: React.CSSProperties; 
}

const ArrowIcon = ({ sortDirection }: { sortDirection: string }) => {
  if (sortDirection === 'asc') {
    return <i className="fa-solid fa-arrow-up-short-wide"></i>;
  } else if (sortDirection === 'desc') {
    return <i className="fa-solid fa-arrow-up-wide-short"></i>;
  } else {
    return <i className="fa-solid fa-arrows-up-down"></i>;
  }
};

const SortableTableHeader: React.FC<HeaderProps> = ({ label, columnName, handleSort, activeColumn, style }) => {
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | 'normal'>('normal');

  useEffect(() => {
    if (activeColumn !== columnName) {
      setSortDirection('normal');
    }
  }, [activeColumn, columnName]);

  const handleClick = () => {
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newSortDirection);
    handleSort(columnName, newSortDirection);
  };

  return (
    <th className='text-start' onClick={handleClick} style={style}>
      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-7'>
        <ArrowIcon sortDirection={sortDirection} />
        <span className='mx-2'>{label}</span>
      </a>
    </th>
  );
};

export default SortableTableHeader;
