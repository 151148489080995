import DocViewer, {DocViewerRenderers} from '@cyntler/react-doc-viewer'
import React, {useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'

export interface Doc {
  uri: string
  fileType: string
  fileName: string
  keyName?: string
}

interface PreviewDocumentProps {
  documentos: Doc[]
}

const allowedPreviewTypes = ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'jfif', 'webp']
const PreviewDocument: React.FC<PreviewDocumentProps> = ({documentos}) => {
  const [docs, setDocs] = useState<Doc[]>(documentos)

  if (docs.length > 0 && !allowedPreviewTypes.includes(docs[0].fileType)) {
    return <p>Tipo de archivo no permitido</p>
  }

  return (
    <>
      <Container>
        <Row>
          <>
            <Col sm={12}>
              {docs.length > 0 &&
                (docs[0].fileType === 'pdf' ? (
                  <DocViewer
                    documents={docs}
                    pluginRenderers={DocViewerRenderers}
                    style={{height: 650}}
                  />
                ) : (
                  <>
                    <div
                      style={{
                        height: '75vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={docs[0].uri}
                        alt={docs[0].fileName}
                        style={{objectFit: 'contain', maxWidth: '100%', maxHeight: '100%'}}
                        onMouseOver={(e) => {
                          const target = e.target as HTMLImageElement
                          target.style.cursor = 'zoom-in'

                          target.style.transform = 'scale(2.5)'
                          target.style.webkitTransform = 'scale(2.5)'
                          target.style.transition = 'transform 0.2s ease-in-out'
                        }}
                        onMouseOut={(e) => {
                          const target = e.target as HTMLImageElement
                          target.style.cursor = 'zoom-out'

                          target.style.transform = 'scale(1)'
                          target.style.webkitTransform = 'scale(1)'
                          target.style.transition = 'transform 0.2s ease-in-out'
                        }}
                      />
                    </div>
                  </>
                ))}
            </Col>
          </>
        </Row>
      </Container>
    </>
  )
}

export {PreviewDocument}
