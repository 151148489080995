import { useAuth2 } from "../authv2/Auth2Provider"
import { CompanySelectorFormWrapper } from "./CompanySelectorFormWrapper"
import { CompanySelectorModalHeader } from "./CompanySelectorModalHeader"

const CompanySelectorModal = () => {

  const {userData, isCompanySelectorModalOpen, setCompanySelectorModalStatus} = useAuth2()


  const onClose = () => {
    setCompanySelectorModalStatus(false)
  }

  return (
    <>
      {isCompanySelectorModalOpen && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              <div className='modal-dialog modal-xl modal-dialog-centered mw-400px'>
                <div className='modal-content'>
                  <CompanySelectorModalHeader />
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <CompanySelectorFormWrapper userData={userData} onClose={() => onClose()} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  )
}

export {CompanySelectorModal}
